/**
 * @brief   Shared ClearingModule zur Freigabe der ClearingDisplayComponent.
 * @details Um die ClearingDisplayComponent in Feature Modulen verwenden zu
 *          können, wird dieses Shared Module eingebunden.
 *          Die Einbindung erfolgt im Feature Module (z.B. people.module.ts)
 *          und nicht in den einzelnen Komponenten des Feature Moduls.
 *
 *          // Shared Modules einbinden
 *          import {ClearingModule} from '../../shared/clearing/clearing.module';
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */

// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
// Komponenten
import {ClearingDisplayComponent} from './clearing-display/clearing-display.component';
import {ClearingDuplicatesPanelComponent} from './clearing-duplicates-panel/clearing-duplicates-panel.component';
import {ClearingMergePopupComponent} from './clearing-merge-popup/clearing-merge-popup.component';
import {ClearingNavigationButtonComponent} from './clearing-navigation-button/clearing-navigation-button.component';
// Shared Modules einbinden
import {GridModule} from '@shared/grid/grid.module';
import {InputModule} from '@shared/input/input.module';
import {ToolbarModule} from '@shared/toolbar/toolbar.module';

@NgModule({
    // Importierte Module
    imports: [CommonModule, MatButtonModule, MatExpansionModule, GridModule, ToolbarModule, InputModule],
    // Komponenten dieses Shared Modules
    declarations: [
        ClearingDisplayComponent,
        ClearingMergePopupComponent,
        ClearingDuplicatesPanelComponent,
        ClearingNavigationButtonComponent,
    ],
    // Komponenten, die dieses Shared Module freigeben soll
    exports: [
        ClearingDisplayComponent,
        ClearingMergePopupComponent,
        ClearingDuplicatesPanelComponent,
        ClearingNavigationButtonComponent,
    ],
})
export class ClearingModule {}
