// Angular-Core
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Globale Services einbinden
import {GlobalMenuService} from '@global/components/global-menu/global-menu.service';
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';
import {UserSettingsService} from '@global/services/user-settings.service';
// Shared Services importieren
import {GlobalSettingsPopupComponent} from '@global/components/global-settings-popup/global-settings-popup.component';
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
import {SubmodulesMenuService} from '@shared/submodules-menu/submodules-menu.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {LooseObject} from '@shared/loose-object';

// Moment.js
import * as _moment from 'moment';

const moment = _moment;

@Component({
    selector: 'phscw-layout-main',
    templateUrl: './layout-main.component.html',
    styleUrls: ['./layout-main.component.scss'],
    /**
     * 2019-09-27, PhS(MFe):
     * ChangeDetection darf in dieser übergeordneten Komponente (noch) nicht
     * generell auf OnPush umgestellt werden, da dies Auswirkungen auf sämtliche
     * untergeordneten C-World Module / Komponenten hat.
     *
     * 2019-10-09, Phs(TH):
     * Manuelle ChangeDetection in der Methode onMenuToggleClicked()
     * ist notwendig um einen Fehler in der Konsole zu verhindern. Kann wieder
     * auskommentiert werden, sobald ChangeDetection auf onPush umgestellt wurde.
     */
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutMainComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();
    // Flags definieren, ob die Menüs ausgeklappt sind (true)
    globalMenuExpanded = false;
    submodulesMenuExpanded = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param changeDetector
     * @param translate
     * @param userSettingsService
     * @param storageService
     * @param globalMenuService
     * @param initService
     * @param submodulesMenuService
     * @param dialog
     */
    constructor(
        private changeDetector: ChangeDetectorRef,
        private translate: TranslateService,
        private userSettingsService: UserSettingsService,
        private storageService: StorageService,
        private globalMenuService: GlobalMenuService,
        private initService: InitService,
        private submodulesMenuService: SubmodulesMenuService,
        private dialog: MatDialog,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Sprache
        this.checkLanguage();

        // Nachtmodus
        this.checkDarkmode();

        // Prüfen ob Password zurückgesetzt werden muss
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            if (result) {
                this.checkIfPasswordIsExpired();
            }
        });

        /*
         * wenn die seite neugeladen wird, ist der nutzer initilisiert und kann dann geprüft werden,
         * beim initialen eonloggen ist der nutzer noch nicht gesatzt, deswegen zwei mal die methode
         */
        this.checkIfPasswordIsExpired();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeEventSubscriptions() {
        // Event "eventGlobalMenuToggleClicked" von "globalMenuService"
        this._subscriptions.add(
            this.globalMenuService.eventGlobalMenuToggleClicked.subscribe((result) => {
                this.onMenuToggleClicked(result);
            }),
        );
        // Event "eventSubmoduleMenuToggleClicked" von "submodulesMenuService"
        this._subscriptions.add(
            this.submodulesMenuService.eventSubmoduleMenuToggleClicked.subscribe((result) => {
                this.onMenuToggleClicked(result);
            }),
        );
    }

    /**
     * @param event
     * @brief   Klasse setzen wenn sich State eines Menüs ändert
     * @details ChangeDetection muss manuell ausgelöst werden um Fehler 'ExpressionChangedAfterItHasBeenCheckedError'
     *          zu vermeiden. Siehe https://github.com/angular/angular/issues/6005#issuecomment-165905348
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onMenuToggleClicked(event: CWEvent) {
        if (event.sender == 'globalMenu') {
            this.globalMenuExpanded = event.data.menuExpanded;
        } else if (event.sender == 'submodulesMenu') {
            this.submodulesMenuExpanded = event.data.menuExpanded;
        }

        // ChangeDetection auslösen
        this.changeDetector.detectChanges();
    }

    /**
     * @brief   Daten aus dem eigenen Benutzer abfragen
     * @details Lädt Einstellungen des Benutzers und aktiviert die Sprache
     *          global. Eventuell gibt es für diesen Code eine bessere Stelle.
     * @todo    Prüfen ob Code an andere Stelle ausgeführt werden sollte
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkLanguage(): void {
        // Daten über Service anfordern
        const promise = this.storageService.getItem('ownUser');
        promise.then((val: LooseObject) => {
            // Wert prüfen
            if (
                val !== null &&
                typeof val !== 'undefined' &&
                Object.prototype.hasOwnProperty.call(val, 'language_key') &&
                val.language_key !== null
            ) {
                // Sprache des Benutzers einstellen --> NGX-Translate
                this.translate.use(val.language_key);
                // Sprache des Benutzers einstellen --> moment.js
                moment.locale(val.language_key.substring(0, 2));
            } else {
                this.translate.use('deu');
                moment.locale('de');
            }
        });
    }

    /**
     * @brief   Daten aus dem eigenen Benutzer abfragen
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    checkIfPasswordIsExpired(): void {
        // Daten über Service anfordern
        const promise = this.storageService.getItem('ownUser');
        promise.then((val: LooseObject) => {
            // erst hier das Passwort prüfen, damit der Dialog mit der richtigen Sprache öffnet
            if (
                val !== null &&
                typeof val !== 'undefined' &&
                Object.prototype.hasOwnProperty.call(val, 'force_password_change') &&
                val.force_password_change === true
            ) {
                this.openPasswordResetDialog();
            }
        });
    }

    /**
     * @brief   Einstellung des Nachtmodus laden
     * @details Lädt Einstellungen des Benutzers und aktiviert gegebenenfalls
     *          den Nachtmodus. Sobald umgesetzt muss auch das Theme generiert
     *          werden. Eventuell gibt es für diesen Code eine bessere Stelle.
     * @todo    Prüfen ob Code an andere Stelle ausgeführt werden sollte
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkDarkmode(): void {
        const themeDarkmodeEnabled = this.userSettingsService.getValue('themeDarkmodeEnabled');
        if (themeDarkmodeEnabled === true) {
            // Darkmode aktivieren
            const body = document.body;
            body.classList.add('darkmode');
        }
    }

    /**
     * @brief   Dialog zum Zurücksetzen des Passworts öffnen
     * @returns  void
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openPasswordResetDialog(): void {
        // Dialog konfigurieren und öffnen
        const dialogMessageRef = this.dialog.open(PopupMessageComponent, {
            disableClose: true,
            width: '250px',
            data: {
                title: this.translate.instant('GLOBAL.CHANGEPASSWORD.HEADER'),
                message: this.translate.instant('GLOBAL.CHANGEPASSWORD.FORCEDPASSWORDRESET'),
            },
        });
        // Auf das Schließen des Dialogs reagieren
        dialogMessageRef.afterClosed().subscribe(() => {
            // Dialog-Konfiguration initialisieren
            const dialogPasswordResetConfig = new MatDialogConfig();
            dialogPasswordResetConfig.disableClose = true;
            dialogPasswordResetConfig.data = {
                selectedSetting: 'password',
                closeAll: true,
            };

            // Dialog öffnen
            const dialogPasswordResetRef = this.dialog.open(GlobalSettingsPopupComponent, dialogPasswordResetConfig);
            // Auf das Schließen des Dialogs reagieren
            dialogPasswordResetRef.afterClosed().subscribe();
        });
    }
}
