// Angular-Module
import {Component, OnDestroy, OnInit} from '@angular/core';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service des übergeordneten Feature-Moduls
import {InstitutionsService} from '../institutions.service';
// Eigenen Service einbinden
import {InstitutionsDiscountsService} from './institutions-discounts.service';
// Service von Shared Modules
import {GridService} from '@shared/grid/grid.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {Discount} from '@shared/discount';
import {LooseObject} from '@shared/loose-object';

@Component({
    selector: 'phscw-institutions-discounts',
    templateUrl: './institutions-discounts.component.html',
    styleUrls: ['./institutions-discounts.component.scss'],
})
export class InstitutionsDiscountsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;
    // ID des Grids
    gridId = 'institutionsDiscounts';

    // Modul-Daten (Notizen der Einrichtung)
    data: Discount[] = [];

    // "Mehr laden..."
    loadMoreEnabled = false;
    loadMoreVisible = true;
    gridPage = 1;

    // Sort
    customSort: LooseObject = {};

    // Spaltendefinitionen für Grid
    gridColumns = [
        {
            columnDef: 'product-erp-number',
            header: 'Artikel-Nr.',
            cell: (element: Discount) => `${element.product_erp_number || ''}`,
            formatWidth: '100px',
            tooltip: true,
        },
        {
            columnDef: 'product-label',
            header: 'Bezeichnung',
            cell: (element: Discount) => `${element.product_label || ''}`,
            formatWidth: '200px',
            tooltip: true,
        },
        {
            columnDef: 'min_amount',
            header: 'Menge',
            cell: (element: Discount) => `${element.min_amount}`,
            formatTemplate: 'integer',
            formatWidth: '75px',
        },
        {
            columnDef: 'flat_discount',
            header: 'Rabatt €',
            cell: (element: Discount) => `${element.flat_discount || ''}`,
            formatTemplate: 'currency',
            formatWidth: '75px',
        },
        {
            columnDef: 'percentage-discount',
            header: 'Rabatt %',
            cell: (element: Discount) => `${element.discount || ''}`,
            formatTemplate: 'percent',
            formatWidth: '75px',
        },
        {
            columnDef: 'valid_from',
            header: 'Gültig ab',
            cell: (element: Discount) => `${element.valid_from}`,
            formatTemplate: 'date',
            formatWidth: '100px',
        },
        {
            columnDef: 'valid_to',
            header: 'Gültig bis',
            cell: (element: Discount) => `${element.valid_to}`,
            formatTemplate: 'date',
            formatWidth: '100px',
        },
    ];

    // Anzuzeigende Spalten für Grid
    gridDisplayedColumns = [
        'product-erp-number',
        'product-label',
        'valid_from',
        'valid_to',
        'min_amount',
        'flat_discount',
        'percentage-discount',
    ];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param translateService
     * @param institutionsService
     * @param institutionsDiscountsService
     * @param gridService
     */
    constructor(
        private translateService: TranslateService,
        private institutionsService: InstitutionsService,
        private institutionsDiscountsService: InstitutionsDiscountsService,
        private gridService: GridService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // In der E-Liste wird eine Einrichtung angeklickt
        this.institutionsService.selectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: number) => {
                this.onSelectionChanged(result);
            });

        // Es wurde auf "Mehr laden..." geklickt
        this.gridService.eventGridPageCounterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: CWEvent) => {
                if (event.target !== this.gridId) {
                    return;
                }
                this.onEventGridPageCounterChanged(event);
            });

        this.gridService.eventGridLoadAllClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: CWEvent) => {
                if (event.target !== this.gridId) {
                    return;
                }
                this.onEventGridLoadAllClicked();
            });

        this.gridService.eventColumnHeaderClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: CWEvent) => {
                if (event.target !== this.gridId) {
                    return;
                }
                this.onEventColumnHeaderClicked(event);
            });
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     * @author  Min Hye Park     <m.park@pharmakon.software>
     */
    initializeTranslateSubscriptions(): void {
        this.translateService
            .stream([
                'GENERAL.ERP',
                'GENERAL.PRODUCT',
                'MODULES.INSTITUTIONS.DISCOUNTS.AMOUNT',
                'MODULES.INSTITUTIONS.DISCOUNTS.FLATDISCOUNT',
                'MODULES.INSTITUTIONS.DISCOUNTS.PERCENTAGEDISCOUNT',
                'MODULES.INSTITUTIONS.DISCOUNTS.VALIDFROM',
                'MODULES.INSTITUTIONS.DISCOUNTS.VALIDUNTIL',
            ])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: any) => {
                this.gridColumns.find((column: any) => column.columnDef === 'product-erp-number').header =
                    translation['GENERAL.ERP'];
                this.gridColumns.find((column: any) => column.columnDef === 'product-label').header =
                    translation['GENERAL.PRODUCT'];
                this.gridColumns.find((column: any) => column.columnDef === 'min_amount').header =
                    translation['MODULES.INSTITUTIONS.DISCOUNTS.AMOUNT'];
                this.gridColumns.find((column: any) => column.columnDef === 'flat_discount').header =
                    translation['MODULES.INSTITUTIONS.DISCOUNTS.FLATDISCOUNT'];
                this.gridColumns.find((column: any) => column.columnDef === 'percentage-discount').header =
                    translation['MODULES.INSTITUTIONS.DISCOUNTS.PERCENTAGEDISCOUNT'];
                this.gridColumns.find((column: any) => column.columnDef === 'valid_from').header =
                    translation['MODULES.INSTITUTIONS.DISCOUNTS.VALIDFROM'];
                this.gridColumns.find((column: any) => column.columnDef === 'valid_to').header =
                    translation['MODULES.INSTITUTIONS.DISCOUNTS.VALIDUNTIL'];
            });
    }

    /**
     * Auf geänderte Auswahl (Einrichtung) reagieren
     * @param id
     */
    onSelectionChanged(id: number): void {
        // Bereits vorhandene Daten (einer anderen Einrichtung) entfernen
        this.resetData();

        // ID der aktuellen Einrichtung merken
        this.institutionId = id;

        // Falls eine existente Einrichtung ausgewählt wurde
        if (this.institutionId > 0) {
            // ... Daten laden
            this.loadData();
        }
    }

    /**
     * Es wurde auf "Mehr laden..." geklickt
     * @param event
     */
    onEventGridPageCounterChanged(event: CWEvent): void {
        this.gridPage = event.data['gridPageCounter'];
        this.loadData();
    }

    /**
     * Es wurde auf "Alle laden..." geklickt
     */
    onEventGridLoadAllClicked(): void {
        this.resetData();
        this.loadData(true, 1000);
    }

    /**
     * Es wurde auf einen Spaltenkopf geklickt
     * @param event
     */
    onEventColumnHeaderClicked(event: CWEvent): void {
        this.resetData();

        // reset previous sort
        this.customSort = {};
        // save new sort
        this.customSort.key = event.data.columnName;
        this.customSort.direction = event.data.direction;

        this.loadData();
    }

    /**
     * Alle Notizen der Einrichtung laden
     * @param loadAll
     * @param pageSize
     */
    loadData(loadAll = false, pageSize: number = null): void {
        // Funktion "Mehr laden..." wird wieder deaktiviert
        this.loadMoreEnabled = false;

        const requestBody: LooseObject = {};
        if (this.customSort !== null) {
            requestBody.customSort = true;
            requestBody.sort = this.customSort;
        }

        // Daten laden
        const serviceRequest$ = this.institutionsDiscountsService.loadData(
            this.institutionId,
            this.gridPage,
            pageSize,
            requestBody,
        );
        serviceRequest$.subscribe(
            (result: CWResult) => {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zur aktuell ausgewählten Einrichtung passen. Durch asynchrone
                 * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                 * bereits die Einrichtung gewechselt wurde und die Antwort
                 * eines Requests verspätet eintrifft und dadurch die
                 * korrekten Daten wieder überschreibt.
                 */
                if (this.institutionsService.selectedInstitution.id != result.data['id']) {
                    // Ladeanimation beenden und ausgeblendet
                    this.loadMoreEnabled = true;
                    this.loadMoreVisible = false;
                    return;
                }

                // Falls Daten geladen wurden
                if (result.data['data'].length > 0) {
                    // Falls sich die vorhandenen Grid-Daten von den neu geladenen Daten unterscheiden...
                    if (JSON.stringify(this.data) != JSON.stringify(result.data['data'])) {
                        // Vorhandene Grid-Daten mit neu geladenen Daten erweitern
                        this.data = this.data.concat(result.data['data']);

                        // recursive loading of all pages
                        if (loadAll === true) {
                            this.gridPage++;
                            this.loadData(loadAll, pageSize);
                        }
                    }

                    // Funktion "Mehr laden..." wird wieder aktiviert
                    this.loadMoreEnabled = true;
                } else {
                    // Funktion "Mehr laden..." wird ausgeblendet, da es keine weiteren Daten mehr gibt
                    this.loadMoreVisible = false;
                }
            },
            (error: any) => {
                // Funktion "Mehr laden..." wird wieder aktiviert
                this.loadMoreEnabled = true;
            },
        );
    }

    /**
     * Daten zurücksetzen (z.B. wenn Einrichtung gewechselt wird)
     */
    resetData(): void {
        this.loadMoreEnabled = false;
        this.loadMoreVisible = true;
        this.gridPage = 1;
        this.data = [];
    }
}
