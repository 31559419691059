// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Service der übergeordneten Komponente
import {ToolbarService} from '../toolbar.service';

@Component({
    selector: 'phscw-toolbar-event-status',
    templateUrl: './toolbar-event-status.component.html',
    styleUrls: ['./toolbar-event-status.component.scss'],
})
export class ToolbarEventStatusComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;
    // Tooltip
    @Input() tooltip: string = null;
    // Icon
    @Input() icon = 'icon-meetings-list';
    // Parameter
    @Input() parameter: any;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param toolbarService
     */
    constructor(private toolbarService: ToolbarService) {}

    /*
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /*
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeEventSubscriptions(): void {}

    /*
     * Daten einfügen
     */
    clickEventStatus() {
        this.toolbarService.clickEventStatus(this.componentIdentifier);
    }
}
