import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {environment} from '@environment';
import {OrderDto, OrderItemDto} from '@modules/institutions/institutions-orders2/institutions-orders2.model';
import {CWEvent} from '@shared/cw-event';
import {Institution} from '@shared/institution';
import {OrderData} from '@shared/order-data';
import {User} from '@shared/user';
import {distinctValues, hasOwn, notEmpty, notEmptyObject, toSum} from '@shared/utils';
import {map, tap} from 'rxjs/operators';
import * as _moment from 'moment';
import {CWResult} from '@shared/cw-result';

const moment = _moment;
/**
 * @brief   Service, der Funktionen für die Aufträge einer Einrichtung bietet.
 * @author  Xiao-Ou Wang <x.wang@pharmakons.software>
 * @author  Tristan Krakau <t.krakau@pharmakon.software>
 */
@Injectable({providedIn: 'root'})
export class OrderFormService {
    // Ausgewählter Auftrag
    selectedOrder: OrderData;

    // Subject (Observable) definieren "Es wurde eine andere Einrichtung ausgewählt"
    public selectionChanged = new Subject<number>();

    // Subject (Observable) definieren "Es wurde im Formular auf Abbrechen geklickt"
    public cancelButtonClicked = new Subject<CWEvent>();

    // Subject (Observable) definieren "Es wurde im Formular auf Speichern geklickt"
    public submitButtonClicked = new Subject<CWEvent>();

    // Observable für momentan ausgewählte Auftragsart
    public selectedOrderType = new Subject<string>();

    // Property-Speicher für zuletzt gewählten Order-Type
    public currentOrderType = null;

    // Liefert die aktuell ausgewählten Lieferdaten
    public deliveryDatesChanged = new Subject<Date[]>();

    // Observable für das Rabattfeld im Header, wenn auf den Button geklickt wird
    public discountToBeApplied = new Subject<number>();

    // Rabatt der aktuell ausgewählten Einrichtung
    public institutionDiscount = 0;

    public selectedInstitution: Institution = null;

    // Maximale Anzahl von Lieferdaten je Order
    private _maxDeliveryDates = 1;
    set maxDeliveryDates(value) {
        this._maxDeliveryDates = value;
    }

    get maxDeliveryDates() {
        return this._maxDeliveryDates;
    }

    // Anzahl der Lieferdaten, in abhängigkeit des Auftragstyps
    private _allMaxDeliveryDates = {default: 1};
    get allMaxDeliveryDates() {
        return this._allMaxDeliveryDates;
    }

    set allMaxDeliveryDates(value: any) {
        this._allMaxDeliveryDates = value;
    }

    // Default werte, wird aus dem backend überschrieben
    orderAccessRights = {
        order_name: {default: 'invisible'},
        order_type: {default: 'invisible'},
        order_status: {default: 'invisible'},
        id: {default: 'invisible'},
        erp_number: {default: 'invisible'},
        order_range: {default: 'invisible'},
        region_id: {default: 'invisible'},
        company_code: {default: 'invisible'},
        employee_id: {default: 'invisible'},
        order_institution_id: {default: 'invisible'},
        order_street: {default: 'invisible'},
        order_zip_code: {default: 'invisible'},
        order_city: {default: 'invisible'},
        institution_erp_number: {default: 'invisible'},
        purchase_order_number: {default: 'invisible'},
        order_person_name: {default: 'invisible'},
        order_discount: {default: 'invisible'},
        order_date: {default: 'invisible'},
        invoice_institution_id: {default: 'invisible'},
        invoice_name: {default: 'invisible'},
        invoice_street: {default: 'invisible'},
        invoice_zip_code: {default: 'invisible'},
        invoice_city: {default: 'invisible'},
        valuta_date: {default: 'invisible'},
        payment_condition: {default: 'invisible'},
        confirmation: {default: 'invisible'},
        wholesaler_institution_id: {default: 'invisible'},
        wholesaler_name: {default: 'invisible'},
        wholesaler_street: {default: 'invisible'},
        wholesaler_zip_code: {default: 'invisible'},
        wholesaler_city: {default: 'invisible'},
        dekorateur: {default: 'invisible'},
        delivery_institution_id: {default: 'invisible'},
        delivery_name: {default: 'invisible'},
        delivery_street: {default: 'invisible'},
        delivery_zip_code: {default: 'invisible'},
        delivery_city: {default: 'invisible'},
        text: {default: 'invisible'},
        text_extern: {default: 'invisible'},
        campaign: {default: 'invisible'},
        delivery_dates: {default: 'invisible'},
        order_item_amountAtDate: {default: 'invisible'},
        order_item_discount: {default: 'invisible'},
        order_item_charge: {default: 'invisible'},
        shipping_costs: {default: 'invisible'},
        order_item_delivery_institution_id: {default: 'invisible'},
    };

    /*
     * Explizite Zugriffsrechte werden hier gespeichert, um oberfläche schnell zuhalten
     * Werte werden nur neu gesetzt, wenn orderType geändert wird
     */
    public orderAccessRightsExplicit = {};

    public currentOrderAccessRightsExplicit = new BehaviorSubject<object>(this.orderAccessRightsExplicit);

    // Cache für vom Backend geladene Konfiguration, damit diese nur einmal geholt werden muss
    private orderConfig = null;

    // Konstruktor
    constructor(private backendService: BackendService) {
        if (hasOwn(environment, 'ordersDefaultMaxDeliveryDates')) {
            /*
             * Je nach Umgebung/Kunde sind standardmäßig mehr/weniger Lieferdaten erlaubt, der Wert aus dem Environment muss mit den Daten
             * zusammen passen, da sonst zu wenig Controls erzeugt werden.
             */
            this._maxDeliveryDates = environment.ordersDefaultMaxDeliveryDates;
            this.allMaxDeliveryDates.default = environment.ordersDefaultMaxDeliveryDates;
        }
    }

    /**
     * @brief   Wird aufgerufen, falls eine andere Einrichtung ausgewählt werden soll
     * @param   {OrderData} selectedOrder
     */
    selectOrder(selectedOrder: OrderData) {
        // Ausgewählte Einrichtung zwischenspeichern
        this.selectedOrder = selectedOrder;
        // Event auslösen um andere Komponenten des Feature-Moduls zu informieren
        this.selectionChanged.next(this.selectedOrder.id);
    }

    /**
     * @brief   Wird aufgerufen, wenn auf Abbrechen geklickt worden ist
     */
    cancelForm() {
        // Event auslösen um untergeordnete Komponenten des Formulars zu informieren
        const eventData: CWEvent = {
            sender: 'order-form',
            target: '',
        };
        this.cancelButtonClicked.next(eventData);
    }

    /**
     * @brief   Wird aufgerufen, wenn auf Speichern geklickt worden ist
     */
    submitForm() {
        // Event auslösen um untergeordnete Komponenten des Formulars zu informieren
        const eventData: CWEvent = {
            sender: 'order-form',
            target: '',
        };
        this.submitButtonClicked.next(eventData);
    }

    /**
     * @brief   Wird aufgerufen, wenn im Formular die Auftragsart geändert worden ist
     * @param   {string} orderType
     */
    changedOrderType(orderType: string) {
        this.currentOrderType = orderType;
        this.setOrderAccessRightsExplicit(orderType);
        this.setMaxDeliveryDates(orderType);
        this.selectedOrderType.next(orderType);
    }

    /**
     * @brief   Wird aufgerufen, wenn die Rabatte der Auftragspositionen aktualisiert werden sollen
     * @param   {number} discount
     */
    applyDiscountChanges(discount: number) {
        this.discountToBeApplied.next(discount);
    }

    /**
     * @brief   Einzelnen Auftrag laden
     * @param   {number} orderId
     * @param   {number} institutionId
     * @returns  {Observable<unknown>}
     */
    loadDetails(orderId: number, institutionId: number): Observable<OrderDto> {
        // GET-Request über BackendService senden
        return this.backendService.getRequest('InstitutionsOrders/details/' + orderId + '/' + institutionId).pipe(
            map((result) => this.prepareLoadedOrder(result['data'])), // Order auspacken und vorverarbeiten
        );
    }

    /**
     * @brief Vom Backend geladenen Auftrag vorverarbeiten und Daten anreichern
     * @param orderFromBackend
     * @private
     */
    private prepareLoadedOrder(orderFromBackend: object): OrderDto {
        // vom Backend gelieferte Order-Daten in ein data-transfer-object vom entsprechenden Typ wandeln
        const result = <OrderDto>Object.assign<OrderDto, any>(new OrderDto(), orderFromBackend);

        // Lieferdaten aus Items extrahieren
        result.deliveryDates = result.order_items
            .map((item) => item.delivery_date)
            .filter(notEmpty)
            .filter(distinctValues)
            .sort()
            .map((str) => new Date(str));

        if (result.deliveryDates.length === 0) {
            // Fallback auf Lieferdatum am Auftrag, sollte nicht vorkommen, denn order_items.delivery_date ist non-null
            result.deliveryDates.push(result.delivery_date);
        }

        // Same procedure for deliver_institution_id
        result.deliveryInstitutions = result.order_items
            .map((item) => item.delivery_institution_id)
            .filter(notEmpty)
            .filter(distinctValues)
            .sort();

        if (result.deliveryInstitutions.length === 0) {
            // Fallback auf Lieferdatum am Auftrag, sollte nicht vorkommen, denn order_items.delivery_date ist non-null
            result.deliveryInstitutions.push(result.delivery_institution_id);
        }

        /*
         * Die Order-Items sind in der Datenbank jeweils für ein Lieferdatum gespeichert, hier wieder zusammenfassen
         * damit sie wie beim Erfassen der Aufträge in einer Zeile je Produkt dargestellt werden können.
         * Den Originalzustand speichern, um in der Readonly-Ansicht die Items nach Lieferdatum aufgefächert zu zeigen.
         * Charge mit Leerstring ersetzen, um "null" Ausgabe in Tabelle zu vermeiden.
         */
        result.orderItemsReadonlyView = result.order_items.map((item) => ({
            ...item,
            charge: item.charge ?? '',
        }));

        // Group by Produkt + Delivery-Institution (in Typescript noch etwas umständlich)
        const groupByProduct = result.order_items.reduce((group, item) => {
            const category = `${item.product_id}_${item.delivery_institution_id}`; // Bucket name konstruieren
            group[category] = group[category] ?? [];
            group[category].push(item);
            return group;
        }, {});

        /*
         * die gruppierten Item-Listen zusammenführen und die Mengen anpassen
         * Original-Items in der Order durch die zusammengefassten ersetzen
         */
        result.order_items = Object.entries<OrderItemDto[]>(groupByProduct).map(([key, value]) => ({
            ...value[0], // Daten aus dem ersten Original-Item übernehmen, alle Items einer Gruppe haben jeweils gleiche Produkt-ID und Empfänger
            amountPerDeliveryDate: this.extractAmountPerDeliveryDate(value, result.deliveryDates),
            amount: value.map((item) => item.amount).reduce(toSum),
            total_price: value.map((item) => Number(item.total_price)).reduce(toSum),
        }));

        // Rechte entsprechend dem Ordertyp setzen
        this.setOrderAccessRightsExplicit(result.order_type);
        // auch die maximal erlaubte Zahl an Lieferdaten kann je Ordertyp variieren, jedoch hat die Anzahl von Daten in der geladenen Order Vorrang
        this.setMaxDeliveryDates(result.order_type, result.deliveryDates.length);
        // Lieferdaten aussenden, damit sich die Formularteile anpassen können
        this.deliveryDatesChanged.next(result.deliveryDates);
        return result;
    }

    /**
     * @brief Die Items mit dem gleichen Lieferdatum zusammenfassen und in ein Objekt für die FormControls wandeln
     * @param orderItems
     * @param deliveryDates
     * @private
     */
    private extractAmountPerDeliveryDate(orderItems: OrderItemDto[], deliveryDates: Date[]) {
        /*
         * zum Abgleich wird der Datumsteil im ISO-Format (YYYY-MM-dd) verwendet, da die Daten so vom Backend kommen
         * für jedes Lieferdatum ein Objekt mit der Summe der Mengen aller Items mit diesem Lieferdatum erstellen
         */
        return deliveryDates
            .map((d) => d.toISOString().substring(0, 10))
            .map((ds) => orderItems.filter((item) => item.delivery_date == ds))
            .map((items) => ({amountAtDate: items.map((item) => item.amount).reduce(toSum, 0)}));
    }

    /**
     * @brief   Hole alle verfügbaren ERP-Nummern der Einrichtung für SelectData
     * @param   {number} institutionId
     * @returns  {Observable<unknown>}
     */
    getInstitutionSupplementaryNumbers(institutionId: number): Observable<unknown> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<unknown> = this.backendService.getRequest(
            'InstitutionsOrders2/getSupplementaryNumbersForSelectData/' + institutionId,
        );
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Hole alle verfügbaren ERP-Nummern der Einrichtung für SelectData
     * @param   {number} institutionId
     * @returns  {Observable<unknown>}
     */
    getDeliveryInstitutions(institutionId: number): Observable<unknown> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<unknown> = this.backendService.getRequest(
            'InstitutionsOrders2/getDeliveryInstitutionsForSelectData/' + institutionId,
        );
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Hole den Rabatt für eine Einrichtung und speichere den Wert in einer Property
     * @param   {number} institutionId
     * @param institution
     * @returns  {void}
     */
    selectInstitution(institutionId: number, institution: Institution): void {
        // Referenz auf Einrichtungsstammdaten halten
        this.selectedInstitution = institution;

        if (institutionId > 0) {
            // Rabatt aus Kennzeichen vom Backend nachladen
            this.backendService
                .getRequest('InstitutionsOrders2/getDiscountForInstitution/' + institutionId)
                .pipe(map((result) => result['data']))
                .subscribe((value) => {
                    this.institutionDiscount = value;
                });
        }
    }

    /**
     * @param institutionId
     * @param user
     * @brief   Initialisiert ein neues Order-Objekt mit den Default-Werten und Daten des angegebenen Users
     * @returns  [Object]
     */
    initOrder(institutionId: number, user: User) {
        const result = {
            id: 0, // = neuer Auftrag
            order_status: 1, // Open
            institution_id: institutionId,
            employee_id: user.employee_id ?? 0,
            employee_label: (user.firstname ?? '') + ' ' + (user.lastname ?? ''), // für Anzeige im Formular
            region_id: user.region_id,
            order_date: new Date(),
            deliveryDates: [new Date()],
            order_discount: this.institutionDiscount,
            order_name: this.selectedInstitution.name1 ?? '',
            order_street: this.selectedInstitution.street ?? '',
            order_zip_code: this.selectedInstitution.zipcode ?? '',
            order_city: this.selectedInstitution.city ?? '',
            order_items: [],
        };

        // Lieferdaten an Komponente weitergeben, damit diese im Header und Produkttabelle aktualisiert werden
        this.deliveryDatesChanged.next(result.deliveryDates);

        return result;
    }

    /**
     * @param id
     * @param headerData
     * @param orderItems
     * @param footerData
     * @brief   Speichert eine Bestellung inklusive Order-Items
     * @returns  {Observable<any>}
     */
    saveOrder(id: number, headerData: any, orderItems: any, footerData: any): Observable<any> {
        // die Daten müssen so aufbereitet sein, dass Backend und Datenbank sie akzeptieren
        if (notEmptyObject(headerData.employee_id) && notEmpty(headerData.employee_id.id)) {
            headerData.employee_id = headerData.employee_id.id;
        }
        if (notEmptyObject(headerData.order_institution_id) && notEmpty(headerData.order_institution_id.id)) {
            headerData.institution_id = headerData.order_institution_id.id;
        }
        if (notEmptyObject(headerData.wholesaler_institution_id)) {
            headerData.wholesaler_institution_id = headerData.wholesaler_institution_id.id;
        }
        if (notEmptyObject(headerData.invoice_institution_id)) {
            headerData.invoice_institution_id = headerData.invoice_institution_id.id;
        }
        headerData.delivery_institution_id = headerData.delivery_institution_id ?? headerData.institution_id;

        // Sicherheitscheck Besteller-Einrichtung - Daten müssen vorhanden sein
        if (headerData?.institution_id > 0 && !notEmpty(headerData.order_name)) {
            console.error('Bestellerdaten fehlen an Auftrag, setze erneut aus gewählter Einrichtung', headerData);
            // Werte erneut übernehmen - sollte "eigentlich" nie vorkommen, aber es kam schon vor
            headerData = {
                ...headerData,
                order_name: this.selectedInstitution.name1 ?? '',
                order_street: this.selectedInstitution.street ?? '',
                order_zip_code: this.selectedInstitution.zipcode ?? '',
                order_city: this.selectedInstitution.city ?? '',
            };
        }

        // Sicherheitscheck für Auftragsdatum
        if (!notEmpty(headerData.order_date)) {
            console.error('Fehlendes Auftragsdatum, setze aktuelles Datum');
            headerData.order_date = new Date();
        }

        // spätestes Lieferdatum an Kopfdaten vermerken
        headerData.delivery_date = headerData.delivery_dates.filter(notEmpty).reduce((a, b) => (a > b ? a : b), null);

        // OrderItems filtern, nur Items mit Gesamtmenge (amount) > 0 werden gespeichert
        const filteredItems = orderItems
            .filter((p) => p.amount > 0)
            .flatMap((item) =>
                /*
                 * items aufsplitten, wenn mehrere Lieferdaten mit Menge vorhanden sind
                 * ausgehend von den aktiven Lieferdaten die Items ausmultiplizieren
                 */
                headerData.delivery_dates
                    .map((deliveryDate, index) => ({
                        ...item,
                        delivery_institution_id:
                            item.order_item_delivery_institution_id ?? headerData.delivery_institution_id,
                        delivery_date: deliveryDate,
                        amount: item.amountPerDeliveryDate[index].amountAtDate,
                        total_price:
                            item.amountPerDeliveryDate[index].amountAtDate * item.unit_price * (1 - item.discount),
                        amountPerDeliveryDate: null,
                    }))
                    .filter((splitItem) => splitItem.amount > 0));

        // Daten zusammenfügen, wie sie das Backend Model erwartet
        const formData = {
            ...headerData,
            ...footerData,
            order_items: filteredItems,
        };

        //
        this.setTimezonesToZero(formData);

        // an die entsprechenden CRUD Aufrufe weitergeben
        return this.backendService.postRequest('crud/orders/' + (id > 0 ? 'update' : 'create'), formData);
    }

    /**
     * @brief Ermittelt den Status aus der Konfiguration, auf den freigegebene Aufträge gesetzt werden
     * @param fallback falls in der Konfiguration kein Wert definiert ist, wird der Fallback verwendet
     */
    getOrderStatusSend(fallback = '3'): string {
        return environment['orderStatusSend'] ?? fallback;
    }

    /**
     * @brief Ermittelt die Status aus der Konfiguration, in denen ein Auftrag noch änderbar ist
     * @param fallback falls in der Konfiguration kein Wert definiert ist, wird der Fallback verwendet
     */
    getOrderStatusChangeable(fallback: string[] = ['1', '2']): string[] {
        return environment['orderStatusChangeable'] ?? fallback;
    }

    /**
     * @brief Überprüft anhand des Status, ob ein Auftrag noch geändert werden kann
     * @param currentStatus
     */
    isOrderChangeable(currentStatus: string) {
        return this.getOrderStatusChangeable().includes(currentStatus);
    }

    /**
     * @brief Speichert explizit die Werte, die das Form an sich dann direkt ansteuert
     * @param {string} orderType
     */
    setOrderAccessRightsExplicit(orderType: string) {
        for (const [fieldName, accessRight] of Object.entries(this.orderAccessRights)) {
            let value = accessRight['default'];
            if (Object.prototype.hasOwnProperty.call(accessRight, orderType)) {
                value = accessRight[orderType];
            }
            this.orderAccessRightsExplicit[fieldName] = {
                ngIf: ['visible', 'required', 'editable'].includes(value),
                editMode: ['required', 'editable'].includes(value),
                required: value == 'required',
            };
        }

        // nach Aktualisierung der Access-Rights auch die sichtbaren Spalten neu ermitteln
        this.currentOrderAccessRightsExplicit.next(this.orderAccessRightsExplicit);
    }

    /**
     * @brief Setzen der maximalen DeliveryDates in Abhängigkeit des Auftragstyps
     * @param {string} orderType Auftragstyp
     * @param {number} minimum Über die Angabe eines Minimalwerts kann beim Laden einer Order sichergestellt werden, dass alle Daten angezeigt werden
     */
    setMaxDeliveryDates(orderType: string, minimum = 1) {
        if (Object.prototype.hasOwnProperty.call(this.allMaxDeliveryDates, orderType)) {
            this.maxDeliveryDates = Math.max(minimum, this.allMaxDeliveryDates[orderType]);
        } else {
            this.maxDeliveryDates = Math.max(minimum, this.allMaxDeliveryDates['default']);
        }
    }

    /**
     * @brief LAden der Konfiguration aus dem Backend
     * @returns {Observable<unknown>}
     */
    getConfiguration(): Observable<unknown> {
        if (notEmptyObject(this.orderConfig)) {
            // Config wurde schon vom Backend geladen, gib gecachten Wert zurück
            return of(this.orderConfig);
        }
        return this.backendService.getRequest('Config/loadOrderAcessRights/').pipe(
            map((cwResult) => cwResult['data']), // auspacken der Nutzdaten
            tap((data) => (this.orderConfig = {...data})), // Config cachen
        );
    }

    /**
     * @brief Laden der Rabatte
     * @returns {Observable<unknown>}
     */
    getDiscounts(): Observable<unknown> {
        return this.backendService.getRequest('Discounts/getDefaultDiscounts');
    }

    /**
     * @brief Timezone zu 00 00 setzen
     * @param obj
     */
    private setTimezonesToZero(obj) {
        for (const key in obj) {
            if (hasOwn(obj, key)) {
                if (this.isDateOrMoment(obj[key])) {
                    obj[key] = moment(obj[key]).startOf('day').utcOffset('+00:00', true);
                } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                    // If the value is an object (but not null), recursively check it.
                    this.setTimezonesToZero(obj[key]);
                }
            }
        }
    }

    /**
     * @brief prüfen ob obj date or moment ist
     * @param value
     * @returns
     */
    isDateOrMoment(value) {
        return value instanceof Date || moment.isMoment(value);
    }
}
