/**
 * @brief   Globaler Service zur Speicherung des Tokens im Session-Storage.
 * @details Speichert das Authentication-Token, sowie dessen Ablauf
 *          im nicht persistenten Browser-Session-Storage. Dadurch werden
 *          die Token-Informationen nach dem Schließen des Fensters / Browsers
 *          gelöscht und bleiben nicht wie im Local-Storage weiterhin vorhanden.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {Injectable} from '@angular/core';

// Konstanten
const TOKEN_KEY = 'authToken';
const REFRESH_TOKEN_KEY = 'refreshAuthToken';
const USERNAME = 'username';

@Injectable({
    // Root-Injector (app.module.ts) is responsible for instantiating this global service
    providedIn: 'root',
})
export class TokenStorageService {
    // [private] Authentication-Token
    private _token: string = window.localStorage.getItem(TOKEN_KEY);
    // GET
    get token() {
        return window.localStorage.getItem(TOKEN_KEY);
    }

    // SET
    set token(value: string) {
        this._token = value;
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.setItem(TOKEN_KEY, value);
    }

    // [private] Authentication-Token
    private _refreshToken: string = window.localStorage.getItem(REFRESH_TOKEN_KEY);

    // GET
    get refreshToken() {
        return window.localStorage.getItem(REFRESH_TOKEN_KEY);
    }

    // SET
    set refreshToken(value: string) {
        this._refreshToken = value;
        window.localStorage.removeItem(REFRESH_TOKEN_KEY);
        window.localStorage.setItem(REFRESH_TOKEN_KEY, value);
    }

    private _username = '';

    // GET
    get username() {
        return window.localStorage.getItem(USERNAME);
    }

    // SET
    set username(value: string) {
        this._username = value;
        window.localStorage.removeItem(USERNAME);
        window.localStorage.setItem(USERNAME, value);
    }

    // Konstruktor
    constructor() {}
}
