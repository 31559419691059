// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* Feste Spaltenbreite für Kontakt-Typ */
.cw-grid-column-contact-type {
  min-width: 0;
  text-align: center;
  width: 35px;
  --color-tour-warning: rgb(255, 200, 0);
}

.cw-cell-color-red {
  color: red;
  color: var(--color-tour-critical);
}

.cw-cell-color-yellow {
  color: yellow;
  color: var(--color-tour-warning);
}

.cw-cell-color-green {
  color: green;
  color: var(--color-tour-minor);
}

.cw-toolbar-icon {
  margin-left: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid/grid-contact-type/grid-contact-type.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB,wCAAA;AACA;EACI,YAAA;EACA,kBAAA;EACA,WAAA;EAEA,sCAAA;AACJ;;AACA;EACI,UAAA;EACA,iCAAA;AAEJ;;AAAA;EACI,aAAA;EACA,gCAAA;AAGJ;;AADA;EACI,YAAA;EACA,8BAAA;AAIJ;;AADA;EACI,2BAAA;AAIJ","sourcesContent":["/* Feste Spaltenbreite für Kontakt-Typ */\n.cw-grid-column-contact-type {\n    min-width: 0;\n    text-align: center;\n    width: 35px;\n\n    --color-tour-warning: rgb(255, 200, 0);\n}\n.cw-cell-color-red {\n    color: red;\n    color: var(--color-tour-critical);\n}\n.cw-cell-color-yellow {\n    color: yellow;\n    color: var(--color-tour-warning);\n}\n.cw-cell-color-green {\n    color: green;\n    color: var(--color-tour-minor);\n}\n\n.cw-toolbar-icon {\n    margin-left: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
