import {Injectable} from '@angular/core';

import {Observable, Subject} from 'rxjs';

import {BackendService} from '@global/services/backend.service';
import {CWEvent} from './../cw-event';

@Injectable({providedIn: 'root'})
export class ChartService {
    // Grid Daten werden neu geladen
    public eventReloadChartData = new Subject<CWEvent>();

    // Institution-Icon wurde angeklickt
    public eventChartLinkIconClicked = new Subject<CWEvent>();

    constructor(private backendService: BackendService) {}

    // gridService abschauen objekt übergeben objekt immer befüllt mit regions handisch erweiterbar
    loadData(dataSource: string, backendOptions: any): Observable<any> {
        // Request-URL zusammen bauen
        const requestURL: string = dataSource;

        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(requestURL, backendOptions);
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    /*
     * holt alle benötigten Produkte für chart-yearly-sales
     */
    getProducts() {
        return this.backendService.getRequest('ChartSales/getProductsForDashboard');
    }

    /**
     * @param sender
     * @param ids
     * @brief   Einrichtungs-Icon wurde angeklickt
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    linkIconClicked(sender: string, ids: any[]) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target: null,
            data: {institutionIds: ids},
        };
        // Event auslösen
        this.eventChartLinkIconClicked.next(eventData);
    }
}
