// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globalen Service einbinden
import {AppCoreService} from '@global/services/app-core.service';
// Übergeordneter Contacts-Service
import {ContactsService} from '@shared/contacts/contacts.service';
// Services anderer Shared-Modules
import {GridService} from '@shared/grid/grid.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {MatDialog} from '@angular/material/dialog';
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {InputDateService} from '@shared/input/input-date/input-date.service';
import {LooseObject} from '@shared/loose-object';
// Shared Components
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';

// Services
import {GlobalHelpService} from '@global/components/global-help/global-help.service';

@Component({
    selector: 'phscw-contacts-help',
    templateUrl: './contacts-help.component.html',
    styleUrls: ['./contacts-help.component.scss'],
})
export class ContactsHelpComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // History-Daten
    data: any = [];
    // Flag, falls keine Daten vorhanden sind
    noData = false;

    // ID des aktuellen Kontakts - Über GETTER / SETTER, da bei Änderung ein Neuladen erfolgen soll
    _contactId = 0;
    get contactId() {
        return this._contactId;
    }

    @Input() set contactId(value) {
        // Wert übernehmen
        this._contactId = value;

        // Details laden (falls es sich NICHT um eine Neuanlage handelt - z.B. in Kontaktübersicht)
        if (this.contactId >= 0) {
            this.loadDetails(this.contactId);
        }
    }

    // ID des Grids
    @Input() gridId = 'contactsHelpList';
    // Daten für Grid
    gridData: any[] = [];

    gridColumns = [
        {
            columnDef: 'entry-name',
            header: 'Title of Knowledge',
            cell: (element: any) => `${element.entry_name}`,
            formatWidth: '100px',
        },
        {
            columnDef: 'date-added',
            header: 'Date added',
            cell: (element: any) => `${element.date_added}`,
            formatWidth: '100px',
            formatTemplate: 'date',
        },
        {
            columnDef: 'version-number',
            header: 'Version',
            cell: (element: any) => `${element.version_number}`,
            formatWidth: '100px',
        },
        {
            columnDef: 'edit-date',
            header: 'Date Version',
            cell: (element: any) => `${element.edit_date}`,
            formatWidth: '100px',
            formatTemplate: 'datetime',
        },
    ];

    // Anzuzeigende Spalten für Grid
    @Input() gridDisplayedColumns = ['date-added', 'entry-name', 'version-number', 'edit-date', 'delete'];

    // EditMode aktiv?
    editMode = true;

    // ID des Hilfeeintrags
    helpentryId: number = null;
    // Daten des Hilfeeintrags
    helpentryData: LooseObject = null;

    // heutiges Datum
    currentDate: any = new Date();

    // Flag definiert ob gerade geladen wird
    loading = false;

    // new properties
    saving = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param gridService
     * @param appCore
     * @param toolbarService
     * @param contactsService
     * @param inputDateService
     * @param dialog
     * @param globalHelpService
     */
    constructor(
        private gridService: GridService,
        private appCore: AppCoreService,
        private toolbarService: ToolbarService,
        private contactsService: ContactsService,
        private inputDateService: InputDateService,
        private dialog: MatDialog,
        private globalHelpService: GlobalHelpService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Event der Toolbar zum Erstellen eines Tickets
        this.appCore.appDataChanged.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls diese Komponente nicht Ziel des Events ist
            if (event.sender !== 'help-text-link' || event.target !== 'contacts-help') {
                return;
            }
            // Daten zwischenspeichern
            this.helpentryId = result.data.entry.id;
            this.helpentryData = result.data.entry;
        });

        this.gridService.eventGridRowDeleteClicked.subscribe((result) => {
            this.onEventGridRowDeleteClicked(result);
        });

        // Wenn im Grid ein Eintrag ausgewählt wurde...
        this.gridService.eventGridSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                this.onEventGridSelectionChanged(result);
            });
    }

    /**
     * Verbundene Hilfe-Einträge laden
     * @param contactId
     */
    loadDetails(contactId: number): void {
        // Submit der Formular-Daten über contactsDataService
        const serviceRequest$ = this.contactsService.loadContactHelpentries(contactId);
        serviceRequest$.subscribe((result: CWResult) => {
            this.gridData = result.data.entities;
        });
    }

    /**
     * Abbrechen
     */
    clickCancel(): void {
        this.helpentryId = null;
        this.helpentryData = null;
    }

    clickSubmit() {
        const formData = this.helpentryData;
        formData.helpentry_id = formData.id;
        formData.contact_id = this.contactId;
        formData.currentDate = this.inputDateService.getDateValueForSave(this.currentDate);

        // Submit der Formular-Daten über contactsDataService
        const serviceRequest$ = this.contactsService.saveContactHelpentry(formData);
        serviceRequest$.subscribe((result: CWResult) => {
            this.clickCancel();
            this.loadDetails(this.contactId);
        });
    }

    // Auf Toolbar-Klick reagieren (Notiz löschen)
    onEventGridRowDeleteClicked(result) {
        const event: CWEvent = result;
        if (event.sender === 'contactsHelpList') {
            this.openDeleteDialog(event.data['selectedRow']);
        }
    }

    // Dialog öffnen
    openDeleteDialog(selectedRow: any) {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: 'Delete?',
                message: 'Do you want to delete this FAQ Connection',
                contact_id: selectedRow['contact_id'],
                helpentry_id: selectedRow['helpentry_id'],
                version_id: selectedRow['version_id'],
            },
        });
        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result) => {
            this.deleteContactHelp(result);
        });
    }

    deleteContactHelp(result) {
        // Wenn in Comfirm-Dialog Button "Ja" geklickt wurde
        if (result.answer === 'yes') {
            // Submit der Formular-Daten über contactsDataService
            const serviceRequest$ = this.contactsService.deleteContactHelpentry(result);
            serviceRequest$.subscribe((result: CWResult) => {
                this.clickCancel();
                this.loadDetails(this.contactId);
            });
        }
    }

    /**
     * Auf Event "eventGridSelectionChanged" von "gridService" reagieren
     * @param event
     */
    onEventGridSelectionChanged(event: CWEvent): void {
        // Abbruch, falls das Event nicht vom eigenen Grid kam
        if (event.sender !== this.gridId) {
            return;
        }

        const data = event.data.selectedRow;

        this.globalHelpService.openPopup(data.contact_id, data.helpentry_id, data.version_id, data.version_number);
    }
}
