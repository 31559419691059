// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class GlobalSettingsChangelanguageService {
    // Konstruktor
    constructor(private backendService: BackendService) {}

    /**
     * @param formData
     * @brief   Speichert das neue Passwort
     * @returns  Observable<any>
     */
    saveNewLanguage(formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('employees/changeLanguage', formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
