// Angular-Module
import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
/*
 * Angular-Material
 * Globale Services einbinden
 */
import {StorageService} from '@global/services/storage.service';
// Eigener Service
import {GlobalSettingsChangelanguageService} from './global-settings-changelanguage.service';
// Interfaces für Structured Objects einbinden
import {CWResult} from '@shared/cw-result';
import {LooseObject} from '@shared/loose-object';

// Moment.js
import * as _moment from 'moment';

const moment = _moment;

@Component({
    selector: 'phscw-global-settings-changelanguage',
    templateUrl: './global-settings-changelanguage.component.html',
    styleUrls: ['./global-settings-changelanguage.component.scss'],
})
export class GlobalSettingsChangelanguageComponent implements OnInit, OnDestroy {
    // Referenz auf Form
    @ViewChild('changeLanguageForm', {static: false}) changeLanguageForm: NgForm;
    // Aktuelle Sprache
    languageKey = '';

    // Wurde Sprache geändert? -> Blende entsprechende Container ein/ aus
    public languageChanged = false;

    // Component-Event-Binding fürs Schließen
    @Output() buttonCloseClicked = new EventEmitter<any>();

    // Flag definiert ob gerade gespeichert wird
    saving = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param translate
     * @param storageService
     * @param globalSettingsChangelanguageService
     */
    constructor(
        private translate: TranslateService,
        private storageService: StorageService,
        private globalSettingsChangelanguageService: GlobalSettingsChangelanguageService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        this.languageKey = this.translate.currentLang;
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * @brief   Wird ausgeführt sobald eine neue Sprache ausgewählt wurde
     */
    clickChangeLanguage() {
        // Benutze die ausgewählte Sprache für ngx-translate
        this.translate.use(this.changeLanguageForm.form.value.language_key);
        // Sprache global setzen für moment.js - bereits existierende Instanzen werden nicht aktualisiert
        moment.locale(this.changeLanguageForm.form.value.language_key.substring(0, 2));

        // Flag "saving" aktivieren
        this.saving = true;

        // Speichere die ausgewählte Sprache in users_settings
        const serviceRequest$ = this.globalSettingsChangelanguageService.saveNewLanguage(
            this.changeLanguageForm.form.value,
        );
        serviceRequest$.subscribe((result: CWResult) => {
            /*
             * Blende den Container mit der Erfolgsmeldung ein, sobald die Sprache geändert wurde
             * 2018-11-27, PhS(MFe): Separates Popup mit Erfolgsmeldung ausgeblendet und Sprachwechsel-Dialog schließen
             */
            if (result.success) {
                /*
                 * Erfolgs-Popup
                 * this.languageChanged = true;
                 */

                // Daten im lokalen Speicher überschreiben
                this.updateLocalData(this.changeLanguageForm.form.value.language_key);

                // Flag "saving" deaktivieren
                this.saving = false;
                // Schließen
                this.clickAndClose();
            }
        });
    }

    /**
     * @param languageKey
     * @brief   Events subscriben
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private updateLocalData(languageKey: string) {
        // Daten über Service anfordern
        const promise = this.storageService.getItem('ownUser');
        promise.then((val: LooseObject) => {
            // Sprache aktualisieren
            val['language_key'] = languageKey;
            // Daten im Speicher überschreiben
            this.storageService.setItem('ownUser', val);
        });
    }

    /**
     * @brief   Popup schliessen
     */
    clickAndClose() {
        // Parent-Komponente über Event informieren, dass Schließen-Button geklickt wurde
        this.buttonCloseClicked.emit();
    }
}
