// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../cw-event';
import {LooseObject} from './../loose-object';

@Injectable({providedIn: 'root'})
export class ToolbarService {
    // Subjects (Observables) definieren für Toolbar-Aktionen
    /**
     * 2019-11-08, PhS(MFe):
     * ---------------------
     * Aktuell wird für jeden neuen Toolbar-Button ein eigenes Subject definiert.
     * Sender ist bei jedem Toolbar-Button schlicht "toolbar".
     * In den zu benachrichtigenden Komponenten kann auf einzelne Events
     * gehört werden. "eventName.subscribe"
     *
     * Idee:
     * -----
     * Statt für jeden Toolbar-Button ein eigenes Subject zu definieren, könnte
     * man ein einzelnes generelles Toolbar-Subject erstellen...
     *      z. B. eventToolbarButtonClicked
     * ...und die Information zum geklickten Button im Sender einbauen...
     *      sender: 'toolbar-add', 'toolbar-clone', 'toolbar-unlock', etc.
     * In den Komponenten welche eine Toolbar einbinden, kann dann auf das
     * generelle Subject subscribed werden...
     *      eventToolbarButtonClicked.subscribe
     * ...und dann je nach Sender (If / Switch) eine andere Funktion aufgerufen
     * werden.
     *
     * ==> Habe dies mal für den ganz neuen Google-Maps Button so angelegt.
     * ==> Ein Umbau muss ggf. nach und nach erfolgen, alle neuen Buttons
     *     sollten schon mal das neue generelle Subject verwenden.
     */
    public eventPasteItem = new Subject<CWEvent>();
    public eventAddItem = new Subject<CWEvent>();
    public eventAddShadowItem = new Subject<CWEvent>();
    public eventEditShadowItem = new Subject<CWEvent>();
    public eventAddSpecificItem = new Subject<CWEvent>();
    public eventCloneItem = new Subject<CWEvent>();
    public eventDeleteItem = new Subject<CWEvent>();
    public eventGenerateReport = new Subject<CWEvent>();
    public eventSendReport = new Subject<CWEvent>();
    public eventLockItem = new Subject<CWEvent>();
    public eventUnlockItem = new Subject<CWEvent>();
    public eventPreviousStatus = new Subject<CWEvent>();
    public eventNextStatus = new Subject<CWEvent>();
    public eventExcelExport = new Subject<CWEvent>();
    public eventExcelExportValidated = new Subject<CWEvent>();
    public eventFullscreenToggle = new Subject<CWEvent>();
    public eventGridExtensionToggle = new Subject<CWEvent>();
    public eventNext = new Subject<CWEvent>();
    public eventCompleteProcess = new Subject<CWEvent>();
    public eventCloseComponent = new Subject<CWEvent>();
    public eventLoadingComplete = new Subject<CWEvent>();
    public eventSwitchView = new Subject<CWEvent>();
    public eventDisabledEdetailerLinks = new Subject<CWEvent>();
    public eventReload = new Subject<CWEvent>();
    public eventFilterInvoices = new Subject<CWEvent>();
    public eventUploadFile = new Subject<CWEvent>();
    /**
     * Zentrales Subject sobald irgendein Toolbar-Button geklickt wurde
     * Informationen zu geklicktem Button werden in "sender" geliefert.
     */
    public eventToolbarButtonClicked = new Subject<CWEvent>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param backendService
     */
    constructor(private backendService: BackendService) {}

    /**
     * Fullscreen-Wechsel
     * @param target
     * @param fullscreenSize
     */
    toggleFullscreen(target: string, fullscreenSize: number) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target,
            data: {fullscreenSize},
        };
        this.eventFullscreenToggle.next(eventData);
    }

    /**
     * Expand Characteristics
     * @param componentIdentifier
     * @param openState
     */
    toggleCharacteristics(componentIdentifier: string, openState: boolean) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
            data: {openState},
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * Daten einfügen
     * @param componentIdentifier
     */
    pasteItem(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
        };
        this.eventPasteItem.next(eventData);
    }

    filterInvoices(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
        };
        this.eventFilterInvoices.next(eventData);
    }

    /**
     * Neuen Eintrag hinzufügen
     * @param componentIdentifier
     * @param addParameter
     * @param sender
     */
    addItem(componentIdentifier: string, addParameter: number, sender = 'toolbar') {
        const eventData: CWEvent = {
            sender,
            target: componentIdentifier,
            data: {addParameter},
        };
        this.eventAddItem.next(eventData);
    }

    /**
     * Neue Änderungsanfrage
     * @param componentIdentifier
     * @param addParameter
     * @param sender
     */
    addShadowItem(componentIdentifier: string, addParameter: number, sender = 'toolbar') {
        const eventData: CWEvent = {
            sender,
            target: componentIdentifier,
            data: {addParameter},
        };
        this.eventAddShadowItem.next(eventData);
    }

    /**
     * Neue Änderungsanfrage aus bestehenden Daten
     * @param componentIdentifier
     * @param sender
     */
    editShadowItem(componentIdentifier: string, sender = 'toolbar') {
        const eventData: CWEvent = {
            sender,
            target: componentIdentifier,
        };
        this.eventEditShadowItem.next(eventData);
    }

    /**
     * Neuen (spezifischen) Eintrag hinzufügen
     * @param componentIdentifier
     * @param specification
     */
    addSpecificItem(componentIdentifier: string, specification: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
            data: {specification},
        };
        this.eventAddSpecificItem.next(eventData);
    }

    /**
     * Eintrag duplizieren
     * @param componentIdentifier
     * @param cloneParameter
     */
    cloneItem(componentIdentifier: string, cloneParameter: number) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
            data: {cloneParameter},
        };
        this.eventCloneItem.next(eventData);
    }

    /**
     * Eintrag zusammenführen
     * @param componentIdentifier
     * @param mergeParameter
     */
    mergeItems(componentIdentifier: string, mergeParameter: number) {
        const eventData: CWEvent = {
            sender: 'toolbar-merge-items',
            target: componentIdentifier,
            data: {mergeParameter},
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * Eintrag löschen
     * @param componentIdentifier
     * @param deleteParameter
     */
    deleteItem(componentIdentifier: string, deleteParameter: LooseObject) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
            data: {deleteParameter},
        };
        this.eventDeleteItem.next(eventData);
    }

    /**
     * Report erzeugen
     * @param componentIdentifier
     */
    generateReport(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
        };
        this.eventGenerateReport.next(eventData);
    }

    /**
     * Report senden
     * @param componentIdentifier
     * @param sender
     */
    sendReport(componentIdentifier: string, sender = 'toolbar') {
        const eventData: CWEvent = {
            sender,
            target: componentIdentifier,
        };
        this.eventSendReport.next(eventData);
    }

    /**
     * Report abschließen
     * @param componentIdentifier
     */
    lockItem(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
        };
        this.eventLockItem.next(eventData);
    }

    /**
     * Datei hochladen
     * @param componentIdentifier
     */
    uploadFile(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
        };
        this.eventUploadFile.next(eventData);
    }

    /**
     * Report freigeben
     * @param componentIdentifier
     */
    unlockItem(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
        };
        this.eventUnlockItem.next(eventData);
    }

    /**
     * Report Status zurücksetzen
     * @param componentIdentifier
     */
    previousStatus(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
        };
        this.eventPreviousStatus.next(eventData);
    }

    /**
     * Report Status erhöhen
     * @param componentIdentifier
     */
    nextStatus(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
        };
        this.eventNextStatus.next(eventData);
    }

    /**
     * Excel-Export Button geklickt
     * @param componentIdentifier
     * @param gridId
     */
    excelExport(componentIdentifier: string, gridId = '') {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
            data: {gridId},
        };
        this.eventExcelExport.next(eventData);
    }

    /**
     * Excel-Export Auswahl bestätigt
     * @param componentIdentifier
     * @param gridId
     * @param selectedIds
     */
    excelExportValidated(componentIdentifier: string, gridId = '', selectedIds = []) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
            data: {
                gridId,
                selectedIds,
            },
        };
        this.eventExcelExportValidated.next(eventData);
    }

    /**
     * Popup für Mehrfachkennzeichen öffnen
     * @param componentIdentifier
     */
    openCharacteristics(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar-characteristics',
            target: componentIdentifier,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * Formular für Kontakte öffnen
     * @param componentIdentifier
     */
    openContactForm(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar-contacts',
            target: componentIdentifier,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * Formular für Sammelkontakte öffnen
     * @param componentIdentifier
     */
    openCollectiveEntryContactForm(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar-contacts-collective-entry',
            target: componentIdentifier,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * Formular für Kontakte öffnen
     * @param componentIdentifier
     */
    openApprovedMailsForm(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar-approved-mails',
            target: componentIdentifier,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * Terminplanung
     * @param componentIdentifier
     */
    openScheduling(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar-scheduling',
            target: componentIdentifier,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * Grid-Erweiterung ein-/ausblenden
     * @param componentIdentifier
     * @param extension
     */
    toggleGridExtension(componentIdentifier: string, extension: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
            data: {extension},
        };
        this.eventGridExtensionToggle.next(eventData);
    }

    /**
     * Nächsten Schritt auslösen
     * @param componentIdentifier
     */
    nextStep(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
        };
        this.eventNext.next(eventData);
    }

    /**
     * Prozess abschließen
     * @param componentIdentifier
     * @param data
     */
    completeProcess(componentIdentifier: string, data: LooseObject = {}) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
            data,
        };
        this.eventCompleteProcess.next(eventData);
    }

    /**
     * Komponente Schließen
     * @param componentIdentifier
     */
    closeComponent(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
        };
        this.eventCloseComponent.next(eventData);
    }

    /**
     * Ladeanimation beenden
     * @param componentIdentifier
     * @param toolbarAction
     */
    loadingComplete(componentIdentifier: string, toolbarAction: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
            data: {action: toolbarAction},
        };
        this.eventLoadingComplete.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief   Ansicht wechseln
     * @details Hierüber kann z.B. zwischen Tabellen- und Kalenderansicht gewechselt werden
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    switchView(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: componentIdentifier,
        };
        this.eventSwitchView.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief   Button "Google Maps" wurde in Toolbar geklickt.
     * @details Event auslösen um die verbundene Komponente informieren zu können.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    showGoogleMaps(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar-google-maps',
            target: componentIdentifier,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief   Button "Parkopedia" wurde in Toolbar geklickt.
     * @details Event auslösen um die verbundene Komponente informieren zu können.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    showParkopedia(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar-parkopedia',
            target: componentIdentifier,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief
     * @details
     * @author  Eric Häußel <e.haeusel@pharmakon.software>
     */
    startEdetailer(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar-start-edetailer',
            target: componentIdentifier,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief
     * @details
     * @author  Eric Häußel <e.haeusel@pharmakon.software>
     */
    sendEdetailer(componentIdentifier: string) {
        const eventData: CWEvent = {
            sender: 'toolbar-send-edetailer',
            target: componentIdentifier,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param personId
     * @param institutionId
     * @param multiContactId
     * @brief
     * @details
     * @author  Eric Häußel <e.haeusel@pharmakon.software>
     */
    getEdetailerLinks(personId: any, institutionId: any, multiContactId: any) {
        const post = {
            institutionId,
            personId,
            multiContactId,
        };

        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.postRequest('RemoteEdetailer/getLinks/', post);
        // Observable (an Komponente) zurückliefern
        return getRequest$;
    }

    /**
     * @param componentIdentifier
     * @param data
     * @brief   Version aus Historie wurde geklickt
     * @details Löst Wechsel der Anzeige zu angeklickter Version aus
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    toggleSpecificVersion(componentIdentifier: string, data: any): void {
        const eventData: CWEvent = {
            sender: 'toolbar-history',
            target: componentIdentifier,
            data,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief   Button "User-Check" wurde geklickt --> Event auslösen
     * @details Wird zum Beispiel für "Ticket übernehmen" verwendet.
     *
     * author  Massimo Feth <m.feth@pharmakon.software>
     */
    clickUserCheck(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-user-check',
            target: componentIdentifier,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief   Button "Reopen" wurde geklickt --> Event auslösen
     * @details Wird für "Ticket erneut auf 'offen' setzen" verwendet.
     *
     * author  Massimo Feth <m.feth@pharmakon.software>
     */
    clickReopen(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-reopen',
            target: componentIdentifier,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief   Löse ein Reload-Event aus. Kann genutzt werden, um z.B. Listen
     *          erneut zu laden.
     * @author  Michael Schiffner <m.schiffner@pharmakon.software>
     */
    reload(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-reload',
            target: componentIdentifier,
        };
        this.eventReload.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @param data
     * @brief   Suche der Duplikate auslösen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    searchDuplicate(componentIdentifier: string, data: any = {}): void {
        // Daten erweitern
        data['toggleLoading'] = 'SearchDuplicate';
        // Event auslösen
        const eventData: CWEvent = {
            sender: 'toolbar-duplicate-search',
            target: componentIdentifier,
            data,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief   Wechsel zur Einrichtungsliste auslösen
     * @details Personen-ID muss übergeben werden, damit die Einrichtungen der Person geladen werden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    navigateToInstitutions(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-navigate-institutions',
            target: componentIdentifier,
            data: {},
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief   Button "Transfer" wurde geklickt --> Event auslösen
     * @details Wird für "Transfer auslösen" im Musterlager verwendet.
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    clickTransfer(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-transfer',
            target: componentIdentifier,
            data: {toggleLoading: 'Transfer'},
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief   Button "Verlustmeldung" wurde geklickt --> Event auslösen
     * @details Wird für "Verlustmeldung erstellen" im Musterlager verwendet.
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    clickSampleLoss(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-sample-loss',
            target: componentIdentifier,
            data: {toggleLoading: 'SampleLoss'},
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     *
     * @param componentIdentifier
     * @brief   Button "Retoure" wurde geklickt --> Event auslösen
     * @details Wird für "Retoure erstellen" im Musterlager verwendet.
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    clickSampleReturn(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-sample-return',
            target: componentIdentifier,
            data: {toggleLoading: 'SampleReturn'},
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief   Button "In Personenliste anzeigen" wurde angeklickt -> Event auslösen
     * @details Wird für "Teilnehmer" im Veranstaltungsmanagement verwendet.
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    clickShowPeople(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-show-people',
            target: componentIdentifier,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief   Button "Teilnehmer in Liste bearbeiten" wurde angeklickt -> Event auslösen
     * @details Wird für "Teilnehmer" im Veranstaltungsmanagement verwendet.
     * @author  Tristan Krakau <t.krakau@pharmakon.software>
     */
    clickEditEventsPeople(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-edit-events-people',
            target: componentIdentifier,
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief   Button "Veranstaltungsstatus bearbeiten" wurde angeklickt -> Event auslösen
     * @details Wird für Änderungen am Status in der P-Liste benutzt.
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    clickEventStatus(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-event-status',
            target: componentIdentifier,
            data: {},
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * Button "Kosten hinzufügen" wurde angeklickt -> Event auslösen
     * Wird im Veranstaltungskosten-Modul verwendet. evtl. allgemeiner nutzbar
     * @param componentIdentifier
     * @author  Michael Schiffner <m.schiffner@pharmakon.software
     */
    clickAddCosts(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-add-costs',
            target: componentIdentifier,
            data: {},
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * Button "Plankosten hinzufügen" wurde angeklickt -> Event auslösen
     * Wird im Veranstaltungskosten-Modul verwendet. evtl. allgemeiner nutzbar
     * @param componentIdentifier
     * @author  Michael Schiffner <m.schiffner@pharmakon.software
     */
    clickAddPlanCosts(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-add-plan-costs',
            target: componentIdentifier,
            data: {},
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief Button "Hierarchie anzeigen" oder "Hierarche ausblenden" wurde angeklickt -> Event auslösen
     * @details Wird primär für die Einrichtungsliste verwendet
     */
    clickHierarchy(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-hierarchy',
            target: componentIdentifier,
            data: {},
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @param componentIdentifier
     * @brief Alle Daten laden
     */
    clickLoadAll(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-loadAll',
            target: componentIdentifier,
            data: {},
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @brief Startet den Chat
     * @param {string} componentIdentifier
     */
    clickStartChat(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-start-chat',
            target: componentIdentifier,
            data: {},
        };
        this.eventToolbarButtonClicked.next(eventData);
    }

    /**
     * @brief Gibt weiter das das generelle icon geklickt wurde
     * @param {string} componentIdentifier
     */
    clickIcon(componentIdentifier: string): void {
        const eventData: CWEvent = {
            sender: 'toolbar-icon',
            target: componentIdentifier,
            data: {},
        };
        this.eventToolbarButtonClicked.next(eventData);
    }
}
