// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/**
 * Allgemein
 * =========
 *
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
*/
:host {
  max-height: calc(100vh - 60px);
  -webkit-overflow-scrolling: touch;
}

.cw-institutiondetails {
  max-height: calc(100vh - 108px);
  overflow-y: auto;
}

/**
 * Icon
 * ====
 * @details Auf Wunsch von HS das Einrichtungs-Icon vergrößert. Damit das
 *          größere Icon den Inhalt nicht verschiebt muss es aus dem
 *          Dokumentenfluss herausgenommen werden.
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
*/
:host h2.cw-icon-container {
  padding-left: calc(3rem + 5px);
  position: relative;
}

:host h2 > .cw-grid-icon {
  font-size: 3rem;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/institutions/institutions-details/institutions-details.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;;;CAAA;AAOA;EACI,8BAAA;EAEA,iCAAA;AAAJ;;AAGA;EACI,+BAAA;EACA,gBAAA;AAAJ;;AAGA;;;;;;;;CAAA;AAUA;EACI,8BAAA;EACA,kBAAA;AADJ;;AAIA;EACI,eAAA;EACA,OAAA;EACA,kBAAA;EACA,QAAA;EACA,2BAAA;AADJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n*/\n\n:host {\n    max-height: calc(100vh - 60px);\n    // 2019-09-24, PhS(MFe): Touch-Scroll u. a. für iPad\n    -webkit-overflow-scrolling: touch;\n}\n\n.cw-institutiondetails {\n    max-height: calc(100vh - 108px);\n    overflow-y: auto;\n}\n\n/**\n * Icon\n * ====\n * @details Auf Wunsch von HS das Einrichtungs-Icon vergrößert. Damit das\n *          größere Icon den Inhalt nicht verschiebt muss es aus dem\n *          Dokumentenfluss herausgenommen werden.\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n*/\n\n:host h2.cw-icon-container {\n    padding-left: calc(3rem + 5px);\n    position: relative;\n}\n\n:host h2 > .cw-grid-icon {\n    font-size: 3rem;\n    left: 0;\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
