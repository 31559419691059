// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class InstitutionsNotesService {
    // Konstruktor (inkl. dependency injection)
    constructor(private backendService: BackendService) {}

    /**
     * @brief   Notizen einer Einrichtung laden
     * @param id
     * @param gridPage
     * @param   number  id      Einrichtung-ID
     * @returns  Observable<any>
     */
    loadData(id: number, gridPage: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'InstitutionsNotes/index/' + id + '/' + gridPage,
        );
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   einzelne Notiz laden
     * @param id
     * @param   number  id      Notiz-ID
     * @returns  Observable<any>
     */
    loadDetails(id: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('InstitutionsNotes/details/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @param id
     * @param formData
     * @brief   Speichert Notizen einer Einrichtung
     * @returns  Observable<any>
     */
    saveData(id: number, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('InstitutionsNotes/edit/' + id, formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param id
     * @param formData
     * @brief   Löschen einer Notiz
     * @returns  Observable<any>
     */
    deleteNoteData(id: number, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'InstitutionsNotes/delete/' + id,
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
