import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {Observable, Observer, Subject, Subscription, takeUntil} from 'rxjs';
import {FormGroup} from '@angular/forms';

/**
 * Convenience Base Class for standard components that provides
 * the common onDestroy logic with the #componentDestroyed$ subject and
 * a util function to subscribe to a given observable until the component is destroyed.
 * The changeDetection-strategy is set to OnPush!
 */
@Component({
    selector: 'phscw-default-reactive',
    templateUrl: './default-reactive.component.html',
    styleUrls: ['./default-reactive.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultReactiveComponent implements OnDestroy {
    #componentDestroyed$ = new Subject<void>();

    ngOnDestroy(): void {
        this.#componentDestroyed$.next();
        this.#componentDestroyed$.complete();
    }

    protected subscribeUntilDestroyed<T>(
        observable$: Observable<T> | Subject<T> | null | undefined,
        observerOrNext?: Partial<Observer<T>> | ((value: T) => void),
    ): Subscription | undefined {
        return observable$?.pipe(
            takeUntil(this.#componentDestroyed$),
        ).subscribe(observerOrNext);
    }

    protected onFormValueChange<T>(fg: FormGroup, name: string, handler: (value: T) => void) {
        this.subscribeUntilDestroyed(
            fg.get(name)?.valueChanges,
            handler,
        );
    }
}
