/* eslint-disable @angular-eslint/directive-selector */
import {Directive, ElementRef} from '@angular/core';

/**
 * Directive to add a class that changes the size of a mat-icon-button
 * works only when the button has the mat-icon-button attribute and one of the following attributes:
 * large-icon-button, medium-icon-button, small-icon-button, tiny-icon-button
 *
 * Currently only works with mat-icon as a child of the button
 * The icon inside should be a fa-icon for the best positioning
 *
 * Example:
 * <button mat-icon-button large-icon-button>
 *   <mat-icon>
 *      <fa-icon icon="add"></fa-icon>
 *   </mat-icon>
 * </button>
 */

@Directive({selector: 'button[mat-icon-button][large-icon-button]'})
export class MatLargeIconButtonDirective {
    constructor(private elementRef: ElementRef) {
        elementRef.nativeElement.classList.add('large-icon-button');
    }
}

@Directive({selector: 'button[mat-icon-button][medium-icon-button]'})
export class MatMediumIconButtonDirective {
    constructor(private elementRef: ElementRef) {
        elementRef.nativeElement.classList.add('medium-icon-button');
    }
}

@Directive({selector: 'button[mat-icon-button][small-icon-button]'})
export class MatSmallIconButtonDirective {
    constructor(private elementRef: ElementRef) {
        elementRef.nativeElement.classList.add('small-icon-button');
    }
}

@Directive({selector: 'button[mat-icon-button][tiny-icon-button]'})
export class MatTinyIconButtonDirective {
    constructor(private elementRef: ElementRef) {
        elementRef.nativeElement.classList.add('tiny-icon-button');
    }
}
