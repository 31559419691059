/**
 * @brief   Feature Module Institutions "Einrichtungen".
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// CDK & Angular Material
import {CdkTableModule} from '@angular/cdk/table';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatTableModule} from '@angular/material/table';
// NGX-Translate für Übersetzungen
import {TranslateModule} from '@ngx-translate/core';

// Highcharts
import {ChartModule} from 'angular-highcharts';
/*
 * https://stackoverflow.com/questions/47116730/how-to-import-highcharts-more
 * @todo: Aufräumen, zentralisieren
 */
import * as Highcharts from 'highcharts';

// Routing-Modul
import {InstitutionsRoutingModule} from './institutions-routing.module';
// Komponenten dieses Moduls
import {InstitutionsCharacteristicsComponent} from './institutions-characteristics/institutions-characteristics.component';
import {InstitutionsClienttemplatesComponent} from './institutions-clienttemplates/institutions-clienttemplates.component';
import {InstitutionsContactsComponent} from './institutions-contacts/institutions-contacts.component';
import {InstitutionsDataSupplementaryNumbersComponent} from './institutions-data/institutions-data-supplementary-numbers/institutions-data-supplementary-numbers.component';
import {InstitutionsDataComponent} from './institutions-data/institutions-data.component';
import {InstitutionsDetailsComponent} from './institutions-details/institutions-details.component';
import {InstitutionsDiscountsComponent} from './institutions-discounts/institutions-discounts.component';
import {InstitutionsDocumentsComponent} from './institutions-documents/institutions-documents.component';
import {InstitutionsEdetailerComponent} from './institutions-edetailer/institutions-edetailer.component';
import {InstitutionsInvoicesComponent} from './institutions-invoices/institutions-invoices.component';
import {InstitutionsListComponent} from './institutions-list/institutions-list.component';
import {InstitutionsNotesComponent} from './institutions-notes/institutions-notes.component';
import {InstitutionsOpeninghoursComponent} from './institutions-openinghours/institutions-openinghours.component';
import {InstitutionsOrdersComponent} from './institutions-orders/institutions-orders.component';
import {InstitutionsOrders2Component} from './institutions-orders2/institutions-orders2.component';
import {InstitutionsPeopleComponent} from './institutions-people/institutions-people.component';
import {InstitutionsProductAnalysisComponent} from './institutions-product-analysis/institutions-product-analysis.component';
import {InstitutionsSalesComponent} from './institutions-sales/institutions-sales.component';
import {InstitutionsTourneemasterComponent} from './institutions-tourneemaster/institutions-tourneemaster.component';
import {InstitutionsComponent} from './institutions/institutions.component';
// Shared Modules einbinden
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CharacteristicsModule} from '../../shared/characteristics/characteristics.module';
import {ChartModule as testChart} from '../../shared/chart/chart.module';
import {ClearingModule} from '../../shared/clearing/clearing.module';
import {ContactsModule} from '../../shared/contacts/contacts.module';
import {DocumentsModule} from '../../shared/documents/documents.module';
import {EntityIconsModule} from '../../shared/entity-icons/entity-icons.module';
import {GridModule} from '../../shared/grid/grid.module';
import {InputModule} from '../../shared/input/input.module';
import {OrderFormModule} from '../../shared/order-form/order-form.module';
import {OrderTrackingModule} from '../../shared/order-tracking/order-tracking.module';
import {OutputModule} from '../../shared/output/output.module';
import {OverlayModule} from '../../shared/overlay/overlay.module';
import {SplitterModule} from '../../shared/splitter/splitter.module';
import {SubmodulesMenuModule} from '../../shared/submodules-menu/submodules-menu.module';
import {ToolbarModule} from '../../shared/toolbar/toolbar.module';
import {InstitutionsAdditionalDataComponent} from './institutions-additional-data/institutions-additional-data.component';
import {MatBottomSheet, MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatDividerModule} from '@angular/material/divider';
import {ChatComponent} from '@shared/artificial-intelligence/chat/chat.component';
import {MatCardModule} from '@angular/material/card';
import {EntityAffiliationModule} from '@shared/entity-affiliation/entity-affiliation.module';
import {MatSelectModule} from '@angular/material/select';

declare let require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/heatmap')(Highcharts);
require('highcharts/modules/treemap')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/modules/xrange')(Highcharts);
require('highcharts/modules/networkgraph')(Highcharts);

@NgModule({
    // Module importieren
    imports: [
        // Angular
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // CDK & Angular-Material
        CdkTableModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatRadioModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatTableModule,
        // NGX-Translate
        TranslateModule,
        // Highcharts
        ChartModule,
        // Routing
        InstitutionsRoutingModule,
        // Shared modules
        CharacteristicsModule,
        ContactsModule,
        DocumentsModule,
        GridModule,
        InputModule,
        OrderTrackingModule,
        OutputModule,
        SplitterModule,
        SubmodulesMenuModule,
        ToolbarModule,
        ClearingModule,
        EntityIconsModule,
        OverlayModule,
        testChart,
        OrderFormModule,
        FontAwesomeModule,
        MatBottomSheetModule,
        MatDividerModule,
        MatCardModule,
        EntityAffiliationModule,
        MatSelectModule,
    ],
    // Deklaration der Komponenten dieses Moduls - lokal (innerhalb dieses Moduls)
    declarations: [
        InstitutionsComponent,
        InstitutionsListComponent,
        InstitutionsDetailsComponent,
        InstitutionsDataComponent,
        InstitutionsContactsComponent,
        InstitutionsOpeninghoursComponent,
        InstitutionsCharacteristicsComponent,
        InstitutionsNotesComponent,
        InstitutionsClienttemplatesComponent,
        InstitutionsOrdersComponent,
        InstitutionsDocumentsComponent,
        InstitutionsInvoicesComponent,
        InstitutionsSalesComponent,
        InstitutionsPeopleComponent,
        InstitutionsTourneemasterComponent,
        InstitutionsEdetailerComponent,
        InstitutionsProductAnalysisComponent,
        InstitutionsDiscountsComponent,
        InstitutionsDataSupplementaryNumbersComponent,
        InstitutionsOrders2Component,
        InstitutionsAdditionalDataComponent,
        ChatComponent,
    ],
    // Provider
    providers: [{
        provide: MAT_DATE_LOCALE,
        useValue: 'de-DE',
    }, {provide: MatBottomSheet}],
    // Exportierte Module
    exports: [TranslateModule],
})
export class InstitutionsModule {}
