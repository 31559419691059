// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Service dieses Shared-Moduls
import {OverlayService} from './../overlay.service';
// Eigener Service
import {OverlayTriggerLinkService} from './overlay-trigger-link.service';
// Interfaces für Structured Objects einbinden
import {LooseObject} from '@shared/loose-object';

@Component({
    selector: 'phscw-overlay-trigger-link',
    templateUrl: './overlay-trigger-link.component.html',
    styleUrls: ['./overlay-trigger-link.component.scss'],
})
export class OverlayTriggerLinkComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Komponente, die als Dialog geöffnet werden soll
    @Input() componentName = '';
    component: any = null;

    // ID der Entität
    @Input() entityId = 0;

    // anzuzeigende Bezeichnung
    @Input() linkLabel = '';
    // Flag definiert, ob Links aktiv sind
    @Input() enableLink = true;

    // Controller zum Laden der Daten
    @Input() backendController: string = null;
    @Input() backendMethod: string = null;
    // zusätzliche Daten für den Dialog
    @Input() additionalDialogData: LooseObject = {};

    /**
     * Konstruktor (inkl. dependency injection)
     * @param overlayService
     * @param overlayTriggerLinkService
     */
    constructor(
        private overlayService: OverlayService,
        private overlayTriggerLinkService: OverlayTriggerLinkService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // übergebene Komponente setzen
        this.setPopupComponent();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Komponente, die durch Klick in Popup geöffnet werden
     * soll, anhand des Namens finden und setzen
     * @todo setzen der Komponente dynamisch ermöglichen, damit nicht jedes Popup hier eingebunden sein muss
     */
    setPopupComponent(): void {
        // Links aktiviert?
        if (this.componentName === null || this.componentName.length === 0) {
            return;
        }

        // Komponente finden
        this.component = this.overlayTriggerLinkService.getComponentByName(this.componentName);
    }

    /**
     * Overlay in Dialog anzeigen
     * @param event
     */
    openDialog(event: any): void {
        // Links aktiviert?
        if (!this.enableLink || this.component === null) {
            return;
        }

        // Dialog öffnen
        this.overlayService.openOverlayInfoDialog(
            event.target,
            this.component,
            this.backendController,
            this.backendMethod,
            this.entityId,
            this.additionalDialogData,
        );
    }
}
