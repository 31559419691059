// Angular-Module
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Services
import {AppCoreService} from '@global/services/app-core.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
import {UserPermissionsService} from './../../../services/user-permissions.service';
import {GlobalHelpService} from './../global-help.service';
import {GlobalHelpTextService} from './global-help-text.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {MenuData} from '@shared/menu-data';
// Environment
import {environment} from '@environment';
// Datumsformat
import {MOMENT_DISPLAY_FORMAT} from '@shared/input/input-date/input-date.component';
// Moment-Modul zur Datums-Verarbeitung
import {hasOwn} from '@shared/utils';
import * as _moment from 'moment';

const moment = _moment;

@Component({
    selector: 'phscw-global-help-text',
    templateUrl: './global-help-text.component.html',
    styleUrls: ['./global-help-text.component.scss'],
})
export class GlobalHelpTextComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf Form
    @ViewChild('helpentriesDataForm', {static: false}) helpentriesDataForm: NgForm;

    // Konfiguration der Textkomponente
    @Input() allowEditmode = false;
    // EditMode aktiv?
    @Input() editMode = false;
    // Definiert, ob die neue Einträge angelegt werden dürfen
    @Input() allowNewHelpentry = false;
    // Definiert, ob die ältere Versionen geladen werden dürfen
    @Input() allowGlobalHelpChangeVersion = false;
    // Definiert, ob die ältere Versionen geladen werden dürfen
    _popupData: any = null;

    _editMode = false;
    get popupData() {
        return this._popupData;
    }

    @Input() set popupData(value) {
        if (!value) {
            return;
        }
        // {contactId: 231, helpentryId: 57, versionId: 3}
        this.loadData(value.versionId, null, 1);
        this._popupData = value;
    }

    // Flag definiert, ob Sortierungsfeld im EditMode angezeigt wird
    sortFieldVisible = false;
    // Flag definiert, ob Button zum Erstellen von Verlinkung zu FAQ angezeigt wird
    buttonAddLinkVisible = false;
    // Definiert, wie lange der Button zum Hinzufügen von Unterpunkten angezeigt wird
    maxLevels = 2;

    // ID des Eintrags
    entryId: number = null;
    // Version-ID des Eintrags
    versionId: number = null;
    // @todo: Beschreibung fehlt
    data: any = {};
    // @todo: Beschreibung fehlt
    originalData: any = {};

    // Daten zu den verfügbaren Versionen
    historyMenuData: MenuData[] = [];

    // Flag definiert, ob gerade geladen wird
    loading = false;
    // Flag definiert, ob gerade gespeichert wird
    saving = false;
    // Flag definiert, ob ein Fehler wegen zu langem Titel angezeigt wird
    displayLengthError = false;

    // Länge des Titels
    allowedTitleLength = 1000;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param translateService
     * @param appCore
     * @param globalHelpService
     * @param globalHelpTextService
     * @param userPermissions
     * @param toolbarService
     */
    constructor(
        private translateService: TranslateService,
        private appCore: AppCoreService,
        private globalHelpService: GlobalHelpService,
        private globalHelpTextService: GlobalHelpTextService,
        private userPermissions: UserPermissionsService,
        private toolbarService: ToolbarService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Initialisiere allowNewHelpEntry aus Userpermissions
        this.checkAllowNewHelpentry();

        // Initialisiere allowGlobalHelpChangeVersion aus Userpermissions
        this.checkAllowGlobalHelpChangeVersion();

        // Einstellung aus Environment übernehmen
        this.getEnvironmentConfigurations();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   EventSubscriptons
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    initializeEventSubscriptions(): void {
        // Event "eventHelpSelectionChanged" von "globalHelpService"
        this.globalHelpService.eventHelpSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Wenn Event von sich selbst ausgelöst wurde, Event ignorieren
                if (result.sender === 'globalHelpText') {
                    return;
                }
                // ID zwischenspeichern
                this.versionId = result.data['id'];
                // neu laden, kein reload erzwingen. Bei neuangelegten Einträgen wird der Parent gesetzt
                this.loadData(result.data['id'], result.data['parent']);
            });

        // Event "eventNewEntry" von "globalHelpService"
        this.globalHelpService.eventNewEntry.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            if (result.target !== 'global-help') {
                return;
            }
            this.newEntry(0);
        });

        // Button in Toolbar wurde angeklickt...
        this.toolbarService.eventToolbarButtonClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls diese Komponente nicht Ziel des Events ist
                if (event.target !== 'helpText') {
                    return;
                }
                this.onEventToolbarButtonClicked(event);
            });

        // Event der Toolbar zum Erstellen einer Verlinkung zum Hilfetext
        this.toolbarService.eventAddItem.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls diese Komponente nicht Ziel des Events ist
            if (event.target !== 'helpText') {
                return;
            }
            // Daten über globalen Service weitersenden
            const eventData: CWEvent = {
                sender: 'help-text-link',
                target: 'contacts-help',
                data: {entry: result.data.addParameter},
            };
            this.appCore.appDataChanged.next(eventData);
        });
    }

    /**
     * @param event
     * @brief   Auf Klick des Versionsbuttons reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventToolbarButtonClicked(event: CWEvent): void {
        // Icon entfernen
        const prevVersion = this.historyMenuData.find((menuItem: MenuData) => hasOwn(menuItem, 'icon'));
        if (prevVersion !== undefined) {
            delete prevVersion.icon;
        }

        // Icon neu setzen
        const newVersion = this.historyMenuData.find((menuItem: MenuData) => menuItem.name == event.data.version);
        if (newVersion !== undefined) {
            newVersion.icon = 'icon-check';
        }

        // Daten neu laden
        this.loadData(this.versionId, this.data.parent_id, event.data.version);
    }

    /**
     * @brief   Eintrag speichern
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    clickSave(): void {
        // Flag setzen
        this.saving = true;

        // ID laden
        const entryId = this.data['id'] ? this.data['id'] : 0;

        // Request auslösen
        const request = this.globalHelpTextService.saveEntry(entryId, this.data);
        request.subscribe(
            (result: CWResult) => {
                // Ergebnis prüfen
                if (result.success) {
                    // Flag setzen
                    this.displayLengthError = false;

                    // Daten setzen
                    this.data = result.data;
                    this.originalData = JSON.parse(JSON.stringify(result.data));
                    this.globalHelpService.selectionChange(this.data['version_id'], 'global-help-text');
                    this.globalHelpService.entryUpdate(
                        'global-help-text',
                        result.data['version_id'],
                        result.data['entry_name'],
                    );

                    // Edit-Mode beenden
                    this.editMode = false;
                } else if (
                    hasOwn(result.data, 'error') &&
                    hasOwn(result.data['error'], 'length') &&
                    result.data['error']['length']
                ) {
                    // Flag setzen
                    this.displayLengthError = true;

                    // Wert setzen
                    this.allowedTitleLength = result.data['error']['allowedTitleLength'];
                } else {
                    // Fehler anzeigen
                }

                // Flag deaktivieren
                this.saving = false;

                // Edit-Mode beenden
                this.editMode = false;
            },
            (error: any) => {
                // Daten zurücksetzen
                this.data = JSON.parse(JSON.stringify(this.originalData));

                // Flag deaktivieren
                this.saving = false;
                // Edit-Mode beenden
                this.editMode = false;
            },
        );
    }

    /**
     * @brief   Bearbeitung abbrechen
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    clickCancel(): void {
        // Bearbeitungsmodus beenden
        this.editMode = false;

        // Daten zurücksetzen
        this.data = JSON.parse(JSON.stringify(this.originalData));
        this.displayLengthError = false;
    }

    /**
     * @brief   Eintrag speichern
     * @param parent
     * @param   parent: number  ID des Parenteintrages unter dem der Eintrag abgelegt wird, 0 für Haupteinträge
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    newEntry(parent: number): void {
        const serviceRequest$ = this.globalHelpTextService.loadEntry(0);
        serviceRequest$.subscribe((result: CWResult) => {
            // Daten zwischenspeichern
            this.data = result.data;
            this.data.parent_id = parent;

            // Eintrags-ID zwischenspeichern
            this.entryId = this.data.id;

            // Flag setzen
            this.editMode = true;

            // Komponenten ausblenden
            this.globalHelpService.selectionChange(0, 'globalHelpText');
        });
    }

    /**
     * @brief   Einstellung aus Environment übernehmen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getEnvironmentConfigurations(): void {
        if (hasOwn(environment, 'enableGlobalHelpSortInputField')) {
            this.sortFieldVisible = environment.enableGlobalHelpSortInputField;
        }
        if (hasOwn(environment, 'enableGlobalHelpButtonAddLink')) {
            this.buttonAddLinkVisible = environment.enableGlobalHelpButtonAddLink;
        }
        if (hasOwn(environment, 'globalHelpTreeMaxLevels')) {
            this.maxLevels = environment.globalHelpTreeMaxLevels;
        }
    }

    /**
     * @brief   Setze allowNewHelpentry-Variable aus den Userpermissions
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    checkAllowNewHelpentry(): void {
        const permissionAllowNewHelpentry: boolean = this.userPermissions.getPermissionValue('allowNewHelpentry');
        this.allowNewHelpentry = permissionAllowNewHelpentry;
    }

    /**
     * @brief   Setze allowGlobalHelpChangeVersion-Variable aus den Userpermissions
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkAllowGlobalHelpChangeVersion(): void {
        const permissionAllowNewHelpentry: boolean =
            this.userPermissions.getPermissionValue('allowGlobalHelpChangeVersion');
        this.allowGlobalHelpChangeVersion = permissionAllowNewHelpentry;
    }

    /**
     * @param id
     * @param parent
     * @param version
     * @brief   Daten des Eintrags laden
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    loadData(id: any, parent: any = null, version: number = null): void {
        // Early Return, wenn Daten schon geladen
        if (this.data.version_id == id && !(typeof this.data.version_id === 'undefined') && version === null) {
            return;
        }

        if (this.popupData != null) {
            return;
        }

        // Flag setzen
        this.loading = true;
        this.editMode = false;
        this.displayLengthError = false;

        // Daten laden
        const serviceRequest$ = this.globalHelpTextService.loadEntry(id, version);
        serviceRequest$.subscribe(
            (result: CWResult) => {
                this.data = result.data;
                if (this.entryId == 0) {
                    this.data.parent_id = parent;
                }

                // Eintrags-ID zwischenspeichern
                this.entryId = this.data.id;

                // Kopie der Daten als "originalData" speichern
                this.originalData = JSON.parse(JSON.stringify(this.data));

                // Historie laden
                this.loadHistoryMenuData();

                // Flag setzen
                this.loading = false;
            },
            (error: any) => {
                // Keine Antwort vom Backend erhalten (Servererror)
                this.loading = false;
            },
        );
    }

    /**
     * @brief   Menüdaten für Historie laden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadHistoryMenuData(): void {
        // Daten zurücksetzen
        this.historyMenuData = [];

        // Für neue Einträge keine Historie laden
        if (this.data.id == 0) {
            return;
        }

        // Daten laden
        const serviceRequest$ = this.globalHelpTextService.loadHistory(this.data.id);
        serviceRequest$.subscribe(
            (result: CWResult) => {
                // Abbrechen, falls nicht erfolgreich
                if (!result.success) {
                    return;
                }

                // Menüdaten zusammensetzen
                result.data.forEach((entry: any) => {
                    // Datum formatieren
                    const date = moment(entry.version_date).format(MOMENT_DISPLAY_FORMAT + ' HH:mm:ss');

                    // Menüpunkt anlegen
                    const menuItem: MenuData = {
                        name: entry.version_number,
                        shownText: date + ' - Version ' + (entry.version_number + 1) + ' - ' + entry.version_author,
                        disabled: !this.allowGlobalHelpChangeVersion,
                    };

                    // Sprachabhängigen Text anhängen
                    menuItem['shownText_' + this.translateService.currentLang] =
                        date +
                        ' - ' +
                        this.translateService.instant('GENERAL.VERSION') +
                        ' ' +
                        (entry.version_number + 1) +
                        ' - ' +
                        entry.version_author;

                    // aktuelle Version markieren
                    if (entry.version_number === this.data.version_number) {
                        menuItem.icon = 'icon-check';
                        menuItem.disabled = false;
                    }

                    // Daten an Menü anhängen
                    this.historyMenuData.push(menuItem);
                });
            },
            (error: any) => {
                // Keine Antwort vom Backend erhalten (Servererror)
            },
        );
    }
}
