/**
 * @brief   Popup in dem jede Komponente dynamisch geladen und angezeigt werden kann
 * @details Die anzuzeigene Komponente muss in der einbindenen Komponente
 *          geladen und in DialogData hier übergeben werden. Außerdem muss die
 *          anzuzeigende Komponente im entsprechenden Modul in 'entryComponents'
 *          definiert sein und das Modul in 'app.modules.ts' importiert sein.
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */

// Angular-Module
import {Component, ComponentFactoryResolver, Inject, OnDestroy, OnInit, Type, ViewChild} from '@angular/core';
// Angular-Material
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Directives
import {DynamicInsertionDirective} from './dynamic-insertion.directive';

// Interface für Dialogdaten
export interface DialogData {
    component: Type<any>;
    data: DialogDataData;
}
// Interface für Daten in DialogData
interface DialogDataData {
    executionOrder: string[];
}

@Component({
    selector: 'phscw-global-component-popup',
    templateUrl: './global-component-popup.component.html',
    styleUrls: ['./global-component-popup.component.scss'],
})
export class GlobalComponentPopupComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Definition der zu erstellenden Komponente
    @ViewChild(DynamicInsertionDirective, {static: true}) dynamicComponent: DynamicInsertionDirective;
    private component: Type<any> = null;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param data
     * @param componentFactoryResolver
     */
    // eslint-disable-next-line new-cap
    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Komponente erstellen
        this.component = this.data.component;
        this.loadComponent();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {}

    /**
     * @brief   Komponente erstellen
     * @details Erstellt die Komponente mit ComponentFactory, weist Daten zu, und führt Methoden aus, die übergeben wurde.
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadComponent(): void {
        if (this.component !== null) {
            // ComponentFactory erstellen
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);

            // View zurücksetzen
            const viewContainerRef = this.dynamicComponent.viewContainerRef;
            viewContainerRef.clear();

            // Komponente erstellen
            const componentRef = viewContainerRef.createComponent(componentFactory);
            const instance = componentRef.instance;

            // Daten übergeben
            const componentData = this.data.data;
            if (typeof componentData === 'object') {
                // Attribute und Methoden in richtiger Reihenfolge übergeben und ausführen
                componentData.executionOrder.forEach((property: any) => {
                    // Jeden Wert in übergebenen Daten prüfen
                    for (const [key, value] of Object.entries(componentData)) {
                        // Reihenfolge einhalten
                        if (property === key) {
                            // Prüfe Typ des Werts für unterschiedliche Aktionen
                            if (typeof instance[key] === 'undefined') {
                                // Nichts tun, wenn die Variable weder Attribut oder Methode entspricht
                                console.warn('Attribut nicht bekannt in Komponente.');
                            } else if (typeof instance[key] === 'function' && value === true) {
                                // Methode ausführen
                                instance[key]();
                            } else if (typeof instance[key] === typeof value || instance[key] === null) {
                                // Wert in Attribut schreiben
                                instance[key] = value;
                            }

                            // Durchlauf abbrechen und nächsten starten, um nicht jedesmal das gesamte Array zu durchlaufen
                            break;
                        } else {
                            console.warn('Attribut nicht in Daten enthalten.');
                        }
                    }
                });
            }
        }
    }
}
