import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'currencyChf'})
/**
 * Diese Pipe wird verwendet, um Zahlen in das Schweizer Format zu bringen
 * Beispiel: 1234,50 -> 1'234.50
 *
 * Die Nachkommastellen können Optional entfernt werden: includeDecimals
 * Beispiel: 1234,50 -> 1'234
 * @author Daniel Nita <d.nita@pharmakon.software>
 */
export class CurrencyChfPipe implements PipeTransform {
    transform(value: string, includeDecimals = true) {
        value = value.replaceAll('.', '\'');
        value = value.replaceAll(',', '.');

        // Nachkommastellen entfernen
        const valueWithoutDecimalPoint = value.substring(0, value.length - 3);

        // Replace the decimal separator with an apostrophe
        return includeDecimals ? value : valueWithoutDecimalPoint;
    }
}
