import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessMonthPipe} from './business-month.pipe';
import {BusinessYearPipe} from './business-year.pipe';
import {CurrencyChfPipe} from './currency-chf.pipe';

@NgModule({
    declarations: [BusinessMonthPipe, BusinessYearPipe, CurrencyChfPipe],
    providers: [BusinessMonthPipe, BusinessYearPipe, CurrencyChfPipe],
    imports: [CommonModule],
    exports: [BusinessMonthPipe, BusinessYearPipe, CurrencyChfPipe],
})
export class PipesModule {}
