import {Pipe, PipeTransform} from '@angular/core';

type SupportedTypes =
  | 'number'
  | 'string'
  | 'boolean'
  | 'object'
  | 'array'
  | 'undefined'
  | 'function'
  | 'symbol'
  | 'bigint'
  | 'date'; // Erweitern wenn nötig

/**
 * Prüft, ob ein Wert einem oder mehreren angegebenen Typen entspricht.
 *
 * Unterstützte Typen: {@link SupportedTypes}
 *
 * Für den Typ 'number' wird zusätzlich überprüft, ob Strings in gültige Zahlen umgewandelt werden können.
 *
 * Diese Pipe ist "pure", sodass sie nur dann ausgeführt wird, wenn sich ihre Inputs ändern.
 * Wird nicht bei jedem change-detection Zyklus getriggert.
 * @example
 * // Einen Typ prüfen
 * <div *ngIf="someValue | isType: 'number'">...</div>
 *
 * // Mehrere Typen prüfen
 * <div *ngIf="someValue | isType: 'number':'string'">...</div>
 */
@Pipe({
    name: 'isType',
    pure: true,
})
export class IsTypePipe implements PipeTransform {
    /**
     * Prüft, ob ein Wert einem oder mehreren angegebenen Typen entspricht.
     * @template T gibt den Typ des zu prüfenden Werts an
     * @param {T} value - Der zu prüfende Wert.
     * @param {SupportedTypes[]} types - Ein oder mehrere Typnamen als string.
     * @returns {boolean} true, wenn der Wert einem der angegebenen Typen entspricht, sonst false.
     */
    transform<T>(value: T, ...types: SupportedTypes[]): boolean {
        if (!types || types.length === 0) {
            return false;
        }

        for (const type of types) {
            if (this.matchesType(value, type)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Überprüft, ob der Wert dem angegebenen Typ entspricht, mit speziellen Regeln für bestimmte Typen.
     * @template T gibt den Typ des zu prüfenden Werts an
     * @param {T} value - Der zu prüfende Wert.
     * @param {SupportedTypes} type - Der Typname als string.
     * @returns {boolean} true, wenn der Wert dem Typ entspricht, sonst false.
     */
    private matchesType<T>(value: T, type: SupportedTypes): boolean {
        switch (type) {
            case 'number':
                return this.isNumber(value);
            case 'string':
                return typeof value === 'string';
            case 'boolean':
                return typeof value === 'boolean';
            case 'object':
                return this.isObject(value);
            case 'array':
                return Array.isArray(value);
            case 'undefined':
                return typeof value === 'undefined';
            case 'function':
                return typeof value === 'function';
            case 'symbol':
                return typeof value === 'symbol';
            case 'bigint':
                return typeof value === 'bigint';
            case 'date':
                return this.isDate(value);
            default:
                console.warn(`Unsupported type '${type}' passed to IsTypePipe.`);
                return false;
        }
    }

    /**
     * Spezielle Logik zur Überprüfung, ob ein Wert eine Zahl ist.
     * Akzeptiert auch Strings, die in Zahlen umgewandelt werden können.
     * @param {unknown} value - Der zu prüfende Wert.
     * @returns {boolean} true, wenn der Wert eine Zahl ist oder ein String, der in eine gültige Zahl umgewandelt werden kann.
     */
    private isNumber(value: unknown): boolean {
        if (typeof value === 'number') {
            return !isNaN(value);
        }
        if (typeof value === 'string') {
            const parsed = parseFloat(value);
            return !isNaN(parsed);
        }
        return false;
    }

    /**
     * Überprüft, ob ein Wert ein Objekt ist (ausschließlich nicht-Array-Objekte).
     * @param {unknown} value - Der zu prüfende Wert.
     * @returns {boolean}  true, wenn der Wert ein Objekt ist, sonst false.
     */
    private isObject(value: unknown): boolean {
        return value !== null && typeof value === 'object' && !Array.isArray(value) && !(value instanceof Date);
    }

    /**
     * Überprüft, ob ein Wert ein gültiges Date-Objekt ist.
     * @param {unknown} value - Der zu prüfende Wert.
     * @returns {boolean} true, wenn der Wert ein gültiges Date-Objekt ist, sonst false.
     */
    private isDate(value: unknown): boolean {
        return value instanceof Date && !isNaN(value.getTime());
    }
}
