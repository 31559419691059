import {Injectable} from '@angular/core';
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
import {Observable} from 'rxjs';
import {CWEvent} from './../cw-event';
import {DocumentData} from './../document-data';
import {FormInput} from '@shared/form-input';

@Injectable({providedIn: 'root'})
export class DocumentsService {
    inputFields: FormInput[] = [
        {
            field_type: 'multiselect',
            field_id: 'UploadedDocuments.document_category',
            field_label: 'Kategorie',
            field_required: false,
            field_disabled: false,
            field_listentries: 'documentCategory',
            field_select_data: [],
        },
        {
            field_type: 'dateRange',
            field_id: 'UploadedDocuments.create_date',
            field_label: 'Datum',
            field_required: false,
            field_disabled: false,
            field_select_data: [],
        },
        {
            field_type: 'dateRange',
            field_id: 'UploadedDocuments.valid_to',
            field_label: 'Gültigkeitsdatum',
            field_required: false,
            field_disabled: false,
            field_select_data: [],
        },
        {
            field_type: 'text',
            field_id: 'UploadedDocuments.document_name',
            field_label: 'Dokument',
            field_required: false,
            field_disabled: false,
            field_select_data: [],
        },
        {
            field_type: 'text',
            field_id: 'UploadedDocuments.document_description',
            field_label: 'Beschreibung',
            field_required: false,
            field_disabled: false,
            field_select_data: [],
        },
        {
            field_type: 'multiselect',
            field_id: 'UploadedDocuments.document_status',
            field_label: 'Status',
            field_required: false,
            field_disabled: false,
            field_listentries: 'documentStatus',
            field_select_data: [],
        },
        {
            field_type: 'checkbox',
            field_id: 'UploadedDocuments.empty_person_id',
            field_label: 'Leere Person',
            field_required: false,
            field_disabled: false,
            field_select_data: [{
                id: 'empty_person_id',
                label: 'nur Dokumente ohne Person zeigen',
            }],
        },
    ];

    // Anzahl der geladenen Dokumente
    documentsCount: number = null;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param appCore
     * @param backendService
     */
    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
    ) {}

    /**
     * @brief   Dokumente laden
     * @param count
     * @param   number  count Anzahl der Dokumente
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    setDocumentsCount(count: number): void {
        this.documentsCount = count;
    }

    /**
     * @brief   Dokumente laden
     * @param   number  id                  Entitäts-Id
     * @param   string  backendController   Name des anzusprechenden Controllers
     * @param postBody
     * @param   number  gridPage            Pagination
     * @returns  Observable<any>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadData(postBody): Observable<any> {
        if (postBody.export) {
            // GET-Request über BackendService senden
            const getRequest$: Observable<any> = this.backendService.getFilePost('Documents/index/', postBody);
            // Observable (an Komponente) zurücklieferen
            return getRequest$;
        }
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.postRequest('Documents/index/', postBody);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * Veranstaltungskosten für Listenansicht laden
     * @param eventId
     * @param formData
     */
    excelExport(eventId: number, formData: any) {
        // GET-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.getFilePost(
            'EventsCosts/excelExport/' + eventId,
            formData,
        );
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    /**
     * @brief   Änderungen an Dokument speichern
     * @param   string      backendController   Name des anzusprechenden Controllers
     * @param   number      id                  Entitäts-Id
     * @param id
     * @param formData
     * @param   FormData    formData            Übergebene Änderungen am Dokument
     * @returns  Observable<any>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    saveDocument(id: number, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('Documents/edit/' + id, formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Dokument löschen
     * @param   string      backendController   Name des anzusprechenden Controllers
     * @param id
     * @param type
     * @param   number      id                  Entitäts-Id
     * @returns  Observable<any>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    deleteDocument(id: number, type: string): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('Documents/delete/' + id + '/' + type);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @param module
     * @param data
     * @brief   Falls Daten erfolgreich geändert wurden
     * @details Sendet aktualisierten Datensatz per Event, damit andere
     *          Module (z.B. Listen) darauf reagieren können
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    dataChanged(module: string, data: DocumentData[]): void {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: module,
            target: '',
            data: {changedItem: data},
        };
        // Daten emitten
        this.appCore.appDataChanged.next(eventData);
    }

    /**
     * @brief   Lädt Dokument aus dem Backend
     * @param id
     * @param   number  id  ID des Dokuments
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getDocument(id: number): Observable<Blob> {
        // GET-Request über BackendService senden
        const getRequest: Observable<Blob> = this.backendService.getFile('Documents/download/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest;
    }
}
