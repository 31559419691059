// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
*/
:host .cw-login-error + .cw-button-wrapper {
  justify-content: center;
}

h3 {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-settings-changelanguage/global-settings-changelanguage.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAUA;EACI,uBAAA;AAHJ;;AAMA;EACI,mBAAA;AAHJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n*/\n\n:host {\n}\n\n:host .cw-login-error + .cw-button-wrapper {\n    justify-content: center;\n}\n\nh3 {\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
