// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */
.cw-table-heading {
  padding: 15pt 0 1pt 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/entity-affiliation/entity-affiliation.component.scss"],"names":[],"mappings":"AAAA;;;;;EAAA;AAMA;EACI,qBAAA;AACJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Massimo Feth <m.feth@pharmakon.software>\n */\n.cw-table-heading {\n    padding: 15pt 0 1pt 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
