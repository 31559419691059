// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
:host > .cw-button-wrapper {
  justify-content: space-between;
}

:host ::ng-deep phscw-grid tr.disabled,
:host ::ng-deep phscw-grid tr.disabled td.mat-mdc-cell {
  color: var(--color-primary);
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/clearing/clearing-display/clearing-display.component.scss"],"names":[],"mappings":"AAAA;;;;;EAAA;AAOA;EACI,8BAAA;AAAJ;;AAGA;;EAEI,2BAAA;EACA,oBAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n */\n\n:host > .cw-button-wrapper {\n    justify-content: space-between;\n}\n\n:host ::ng-deep phscw-grid tr.disabled,\n:host ::ng-deep phscw-grid tr.disabled td.mat-mdc-cell {\n    color: var(--color-primary);\n    pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
