// Angular-Module
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
// Angular-Material
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Shared Components
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
// Eigenen Service einbinden
import {GridSelectionService} from './../grid-selection.service';
// Interface für Optionen
import {SelectData} from '@shared/select-data';
// Environment einbinden
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

@Component({
    selector: 'phscw-grid-selection-save-popup',
    templateUrl: './grid-selection-save-popup.component.html',
    styleUrls: ['./grid-selection-save-popup.component.scss'],
})
export class GridSelectionSavePopupComponent implements OnInit {
    // Name des Filters
    filtername = '';
    /*
     * Ob der die Selektion dynamisch geladen wird Nummer-Wert entspricht ID
     * von dynamicOptions
     */
    refresh = 1;
    saveButtonDisabled = false;
    // Für welche Rollen die Selektion gespeichert werden sollen
    roleIds: number[] = [];
    // Checkbox: soll die Selektion für Rollen gespeichert werden
    saveRoles = false;
    // Dynamisches Speichern von Selektionen erlauben
    allowDynamicSave = false;
    // Optionen für die Radiobuttons für Speicherart (dynamisch, statisch)
    dynamicOptions: SelectData[] = [];

    // Referenz auf Form
    @ViewChild('saveSelectionForm', {static: true}) saveSelectionForm: NgForm;

    constructor(
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<GridSelectionSavePopupComponent>,
        private dialog: MatDialog,
        private gridSelectionService: GridSelectionService,
        private translationService: TranslateService,
    ) {}

    ngOnInit() {
        // Lade, ob dynamisches Speichern der Selektion erlaubt ist
        if (hasOwn(environment, 'allowDynamicSelectionSave')) {
            this.allowDynamicSave = environment.allowDynamicSelectionSave;
        }
        if (!this.allowDynamicSave) {
            // Damit im Standard-Fall statisch gespeichert wird.
            this.refresh = 0;
        }
        // Erstelle die Optionen für die Speicherart-Radiobuttons
        this.dynamicOptions = [
            {
                id: 1,
                label: this.translationService.instant('SHARED.GRID.SELECTION.POPUP.DYNAMICSAVE'),
            },
            {
                id: 0,
                label: this.translationService.instant('SHARED.GRID.SELECTION.POPUP.STATICSAVE'),
            },
        ];
    }

    saveSelection(overwrite = false) {
        // Zuerst wird das Popup geschlossen. Die folgenden Schritte werden trotzdem ausgeführt
        this.clickAndClose();
        // Initialisierung einer Informationsvariable für Grid-Sources, ob die Selektion für andere Rollen freigegeben wurde.
        let clearedSelections = true;
        // Wenn der Haken zum Speichern von Rollen nicht gesetzt ist die Rollen zurücksetzen
        if (!this.saveRoles) {
            this.roleIds = [];
            clearedSelections = false;
        }
        // Benachrichtige das zugehörige Panel vom Start des Speichervorgangs
        this.gridSelectionService.notifyGridSelectionPanelOfSaveStart(this.data['gridId']);
        const saved = this.gridSelectionService.saveSelection({
            title: this.filtername,
            refresh: this.refresh,
            frontend_array: this.data['frontendArray'],
            transfer_array: this.data['transferArray'],
            grid_id: this.data['gridId'],
            role_ids: this.roleIds,
            overwrite_flag: overwrite,
        });
        saved.subscribe((result: any) => {
            // Wenn die Selektion erfolgreich gespeichert wurde, speichere Sie in das Storage
            if (result['success'] && typeof result['data'][0] !== 'undefined') {
                this.gridSelectionService.onSuccessfulSelectionSave(result['data'][0], clearedSelections, true);
            } else if (
                !result['success'] &&
                result['data'] !== null &&
                typeof result['data']['confirmOverwrite'] !== 'undefined' &&
                result['data']['confirmOverwrite'] === true
            ) {
                this.confirmOverwrite();
            } else if (
                !result['success'] &&
                result['data'] !== null &&
                typeof result['data']['noOverwriteRights'] !== 'undefined' &&
                result['data']['noOverwriteRights'] === true
            ) {
                this.openSaveForbiddenPopup();
            } else {
                // @todo Fehlerbehandlung
                this.gridSelectionService.notifyGridSelectionPanelOfSaveResult(this.data['gridId'], false);
            }
        });
    }

    clickAndClose() {
        // Dialog (über Referenz) schließen und Dialog-Ergebnis mitsenden
        this.dialogRef.close();
    }

    /**
     * Öffne ein Popup zum Bestätigen, dass ein Layout überschrieben werden soll
     */
    confirmOverwrite() {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: this.translationService.instant('SHARED.GRID.SELECTION.POPUP.OVERWRITESELECTIONHEADER'),
                message: this.translationService.instant('SHARED.GRID.SELECTION.POPUP.OVERWRITESELECTIONTEXT'),
            },
        });

        // auf Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result: any) => this.onConfirmationResult(result.answer));
    }

    /**
     * Ergebnis des Popups behandeln
     * @param answer
     */
    onConfirmationResult(answer: string) {
        if (answer === 'no') {
            // Informiere die Einbindende Komponente, dass nicht gespeichert wurde.
            this.gridSelectionService.notifyGridSelectionPanelOfSaveResult(this.data['gridId'], false);
            return;
        }
        this.saveSelection(true);
    }

    /**
     * Der User hat nicht die nötigen Rechte, um existierende Selektionen zu überschreiben
     */
    openSaveForbiddenPopup() {
        // Dialog konfigurieren und öffnen
        const dialogRef2 = this.dialog.open(PopupMessageComponent, {
            width: '350px',
            data: {
                title: this.translationService.instant('SHARED.GRID.SELECTION.POPUP.OVERWRITESELECTIONFORBIDDENHEADER'),
                message: this.translationService.instant('SHARED.GRID.SELECTION.POPUP.OVERWRITESELECTIONFORBIDDENTEXT'),
            },
        });

        dialogRef2
            .afterClosed()
            .subscribe((result: any) => this.gridSelectionService.notifyGridSelectionPanelOfSaveResult(this.data['gridId'], false));
    }
}
