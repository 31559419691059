// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* Kontakt-Gesprächsthemen */
.cw-grid-column-contact-topics-of-conversation {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid/grid-contact-topics-of-conversation/grid-contact-topics-of-conversation.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB,4BAAA;AACA;EACI,gBAAA;EACA,gBAAA;EACA,uBAAA;AAEJ","sourcesContent":["/* Kontakt-Gesprächsthemen */\n.cw-grid-column-contact-topics-of-conversation {\n    max-width: 150px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
