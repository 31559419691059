/**
 * @brief   Komponente zur Auswahl einer Uhrzeit oder Zeitdauer.
 * @details Diese Komponente baut auf "flatpickr" (JS-Library) auf:
 *          https://flatpickr.js.org
 *          https://github.com/flatpickr/flatpickr
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
// Flatpickr
import flatpickr from 'flatpickr';

@Component({
    selector: 'phscw-input-time',
    templateUrl: './input-time.component.html',
    styleUrls: ['./input-time.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputTimeComponent),
        multi: true,
    }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTimeComponent implements OnInit, OnDestroy {
    // Eingabefeld
    @ViewChild('myTimePicker', {static: false}) targetInput: ElementRef;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string;
    // Emitter zum benachrichtigen von Parent-Komponenten über Klicks
    @Output() timeBoundaryChanged = new EventEmitter<boolean>();

    // EditMode aktiv?
    private _editMode = false;
    get editMode() {
        return this._editMode;
    }

    @Input() set editMode(editMode: boolean) {
        this._editMode = editMode;
        this.changeDetector.detectChanges();
        /**
         * PhS(MS): Der TimePicker soll initialisiert, falls sich gerade der editMode auf true gesetzt hat,
         * da der der targetInput erst jetzt im Html angezeigt wird. Trotzdem darf dies nur geschehen, wenn der
         * timeValue initialisiert ist, um Fehler zu vermeiden.
         */
        if (editMode && this.targetInput && this.timeValue) {
            this.initializeTimePicker();
        } else if (!editMode) {
            this.picker = null;
        }
    }

    // Attribut: required = Pflichtfeld (ja / nein)
    @Input() required = false;
    // Attribut: disabled = Gesperrt (ja / nein)
    @Input() disabled = false;
    // Soll ein Löschbutton angezeigt werden.
    @Input() showDeleteButton = false;
    // In Ansichtsmodus verstecken, wenn leer?
    @Input() hideEmpty = true;

    // Standarddatum
    defaultDate = '';

    // Model "timeValue" mit GETTER & SETTER
    private _timeValue: any;
    @Input() set timeValue(value: string) {
        if (value != null && typeof value !== 'undefined') {
            this._timeValue = value;
            this.defaultDate = value;
            this.propagateChange(this._timeValue);
        }
        // manuelle ChangeDetection
        this.changeDetector.detectChanges();
    }

    get timeValue() {
        return this._timeValue;
    }

    // Startwerte für Uhrzeit (8:00)
    @Input() defaultHour = 8;
    @Input() defaultMinute = 0;
    // Zeit kann per Default in 15-Minutenschritten erhöht / reduziert werden
    @Input() minuteIncrement = 15;

    // Maximale Zeit, bis wann Daten im Zeit-Tool ausgewählt werden dürfen
    private _max = '23:59';
    @Input()
    set max(value: string) {
        // Wert übernehmen
        this._max = value;
        // Konfiguration setzen
        this.setTimePickerConfig(this.picker, 'maxTime', value);
        /*
         * 2018-12-17, PhS(MFe):
         * Falls Max geändert wurde, muss aktueller Wert geprüft und ggf.
         * angepasst werden. Da TimePicker "timeValue" bindet, muss der interne
         * Wert des Pickers, z.B. mit picker.setDate(value), nicht separat
         * gesetzt werden.
         */
        if (this.timeValue > this.max) {
            this.timeValue = this.max;
        }
        // Change-Event auslösen
        this.emitChangeEvent();
    }

    get max() {
        return this._max;
    }

    // Kleinste Zeit, bis wann Daten im Zeit-Tool ausgewählt werden dürfen
    private _min = '00:00';
    @Input()
    set min(value: string) {
        // Wert übernehmen
        this._min = value;
        // Konfiguration setzen
        this.setTimePickerConfig(this.picker, 'minTime', value);
        /*
         * 2018-12-17, PhS(MFe):
         * Falls Min geändert wurde, muss aktueller Wert geprüft und ggf.
         * angepasst werden. Da TimePicker "timeValue" bindet, muss der interne
         * Wert des Pickers, z.B. mit picker.setDate(value), nicht separat
         * gesetzt werden.
         */
        if (this.timeValue < this.min) {
            this.timeValue = this.min;
        }
        // Change-Event auslösen
        this.emitChangeEvent();
    }

    get min() {
        return this._min;
    }

    // Definiert, ob eine Eingabe direkt im Inputfeld erlaubt ist
    @Input() allowInput = false;
    // Definiert, ob Klick in Inputfeld den Picker öffnet
    @Input() clickOpens = true;

    // Konfiguration für flatpickr-Aufruf
    private flatpickrConfig: any;
    // Referenz auf TimePicker
    private picker: any = null;

    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    propagateChange = (_: any) => {};

    // Event-Emitter, falls der Wert gelöscht wurde
    @Output() deleteClicked = new EventEmitter<any>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param changeDetector
     */
    constructor(private changeDetector: ChangeDetectorRef) {}

    /**
     * Initialisieren
     */
    ngOnInit() {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        // Instanzen des Pickers aufräumen, um Beeinträchtigung der Performance zu verhindern
        this.destroyPicker();
    }

    /**
     * @brief   Konfiguration für TimePicker festlegen
     * @details Wird hier zunächst in Klassenvariable "flatpickrConfig"
     *          gespeichert, aber noch nicht mit "flatpickr" verbunden.
     */
    configuratePicker(): void {
        // Konfiguriere Picker
        this.flatpickrConfig = {
            allowInput: this.allowInput,
            clickOpens: this.clickOpens,
            dateFormat: 'H:i',
            defaultDate: this.defaultDate,
            defaultHour: this.defaultHour,
            defaultMinute: this.defaultMinute,
            enableTime: true,
            maxTime: this.max,
            minTime: this.min,
            minuteIncrement: this.minuteIncrement,
            noCalendar: true,
            static: true,
            time_24hr: true,
            mode: 'time',
            onClose: (selectedDates, dateString, instance) => {
                /*
                 * 2019-02-04, PhS(TH):
                 * Falls timeValue nicht gesetzt ist, z.B. keine Daten aus
                 * Backend erhalten hat und keine Änderung des Standardwerts stattfand,
                 * Wert manuell setzen, damit ngModel den Initialwert (defaultHour
                 * und defaultMinute) erhält.
                 */
                if (this.timeValue !== dateString) {
                    this.timeValue = dateString;
                }
            },
        };
    }

    /**
     * @brief   Entfernt eine bereits existierende TimePicker-Instanz
     * @details Wird nur durch "initializeTimePicker" aufgerufen
     */
    destroyPicker(): void {
        // Picker Instanz löschen, falls vorhanden
        if (this.picker) {
            this.picker.destroy();
        }
    }

    /**
     * @brief   TimePicker initialisieren
     * @details Falls Referenz auf Eingabefeld (targetInput) vorliegt, wird
     *          eine Flatpickr-Instanz erstellt.
     *          - 1. Parameter = Verbundenes Input-Feld
     *          - 2. Parameter = Konfiguration
     *          Die Flatpickr-Instanz wird in "this.picker" abgelegt.
     */
    initializeTimePicker(): void {
        // Referenz auf Inputfeld muss vorhanden sein
        if (this.targetInput) {
            // Picker Instanz löschen, falls bereits vorhanden
            this.destroyPicker();

            /*
             * 2019-02-04, PhS(TH):
             * Um Konsolen-Fehler wegen undefinierte Datentyps zu vermeiden, darf
             * value-Attribut des Inputs nur gesetzt werden, wenn timeValue initialisiert ist
             */
            if (typeof this.timeValue !== 'undefined' && this.timeValue !== undefined) {
                // Value des Inputfelds auf den geladenen Wert setzen
                this.targetInput.nativeElement['value'] = this.timeValue;
            }

            // Setze Konfiguration für TimePicker-Instanzen
            this.configuratePicker();

            // Flatpickr-Instanz erstellen (1. Parameter = verbundenes Input-Feld, 2. Parameter = Konfiguration)
            this.picker = flatpickr(this.targetInput.nativeElement, this.flatpickrConfig);
        }
    }

    /**
     * @brief   TimePicker öffnen
     * @details Notwendig, damit der Picker auch beim Klick über unser
     *          separates Icon (Uhr) geöffnet werden kann.
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openTimePicker(): void {
        if (typeof this.picker !== 'undefined' && this.picker !== null) {
            this.picker.open();
        } else {
            // Picker initialisieren vor Öffnen
            this.initializeTimePicker();
            this.picker.open();
        }

        // manuelle ChangeDetection
        this.changeDetector.detectChanges();
    }

    /**
     * @brief   Konfiguration und Werte für TimePicker setzen
     * @param   Object  picker  TimePicker-Instanz
     * @param   string  option  Bezeichnung der zu ändernden Konfiguration
     * @param picker
     * @param option
     * @param value
     * @param   any     value   Neuer Wert für die zu ändernde Konfiguration
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    setTimePickerConfig(picker: any, option: string, value: any): void {
        // Prüfe ob Picker existiert
        if (picker !== null && typeof picker !== 'undefined') {
            // Setze geänderte Konfiguration
            if (picker.config.option !== value) {
                picker.set(option, value);
            }

            // Setze Zeit neu falls außerhalb des Bereichs
            /*
             * 2018-12-17, PhS(MFe):
             * Nicht mehr notwendig, da der Wert des gebundenen Daten-Elements
             * direkt im Setter modifiziert wird. Setzen des internen Picker-
             * Werts hatte keine Auswirkung auf gebundenes Daten-Element.
             * Resultat war, dass etwas anderes angezeigt wurde, als tatsächlich
             * im Daten-Element vorhanden war.
             */
            /*
             * if (
             *      (option === 'maxTime' && this.timeValue > value) ||
             *      (option === 'minTime' && this.timeValue < value)
             *  ) {
             *      picker.setDate(value);
             *  }
             */
        }
    }

    /**
     * Event auslösen um Parent-Komponente Änderung mitzuteilen
     */
    emitChangeEvent(): void {
        // manuelle ChangeDetection
        this.changeDetector.detectChanges();
        // Event senden
        this.timeBoundaryChanged.emit(true);
    }

    /**
     * Lösche Inhalt
     */
    deleteValue(): void {
        if (typeof this.picker !== 'undefined' && this.picker !== null) {
            this.picker.clear();
            this.timeValue = null;
        }
        // manuelle ChangeDetection
        this.changeDetector.detectChanges();
        // Event senden
        this.deleteClicked.emit();
    }

    /**
     * Interface ControlValueAccessor: writeValue
     * @param value
     */
    writeValue(value: any) {
        // Wert, der übers Binding reinkommt wird in "timeValue" gespeichert
        this.timeValue = value;

        if (value !== undefined && value != null) {
            /*
             * 2018-10-09, PhS(MFe):
             * ---------------------
             * Erst jetzt darf der TimePicker initialisiert werden, da er sonst
             * nicht mit dem korrekten Wert aus dem Model instanziiert wird.
             *
             * Die gesamte Komponente wird zuerst initialisiert und erst am
             * Ende kommen die Daten über NgModel rein. Wird der TimePicker
             * also z.B. bereits in ngOnInit initialisiert ist das zu früh und
             * es wird nicht der korrekte Wert angezeigt bzw. immer auf Default
             * zurückgesetzt.
             */
            this.initializeTimePicker();
        }

        // manuelle ChangeDetection
        this.changeDetector.detectChanges();
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}
}
