import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-top-customer',
    templateUrl: './chart-top-customer.component.html',
    styleUrls: ['./chart-top-customer.component.scss'],
})
export class ChartTopCustomerComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
