// Angular-Module
import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
// Angular-Material-Module
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
// Moment-Modul zur Datums-Verarbeitung
import * as _moment from 'moment';

// Moment
const moment = _moment;

// Format-Konstante für das Anzeigeformat von Datum
export const MOMENT_DISPLAY_FORMAT = 'MM.YYYY';

/*
 * See the Moment.js docs for the meaning of these formats:
 * https://momentjs.com/docs/#/displaying/format/
 */
export const MY_FORMATS = {
    parse: {dateInput: 'MM.YYYY'},
    display: {
        dateInput: 'MM.YYYY',
        monthYearLabel: 'MMM Y',
        dateA11yLabel: 'DD.MM.Y',
        monthYearA11yLabel: 'MMM YY',
    },
};

@Component({
    selector: 'phscw-input-month',
    templateUrl: './input-month.component.html',
    styleUrls: ['./input-month.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputMonthComponent),
            multi: true,
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_FORMATS,
        },
    ],
})
export class InputMonthComponent implements OnInit, OnDestroy {
    // EditMode aktiv?
    @Input() editMode = false;
    // Komponente deaktiviert?
    @Input() disabled = false;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string;
    // Startansicht
    @Input() startView = 'month';
    // ankommendes Datumsformat
    @Input() dateValueStringFormat = 'YYYY-MM-DD';
    // Maximales Datum, bis wann Daten im Kalender-Tool ausgewählt werden dürfen
    @Input() max;
    // Kleinstes Datum bis wann Daten im Kalender-Tool ausgewählt werden dürfen
    @Input() min;
    // Emitter zum benachrichtigen von Parent-Komponenten über Klicks
    @Output() clickEvent = new EventEmitter<boolean>();

    // Wochentag als String anzeigen
    @Input() showWeekday = false;

    // Soll der Löschbutton angezeigt werden
    @Input() showDeleteButton = false;

    // Sollen Pfeiltasten & Today-Button  angezeigt werden
    @Input() showDateSwitchButtons = false;

    // In Ansichtsmodus verstecken, wenn leer?
    @Input() hideEmpty = true;

    // Sollen Texteingaben in das Datumsfeld unterbunden werden?
    @Input() inputReadonly = false;

    // Variable zum Anzeigen von Formatierungs-Fehlern
    showError = false;

    // Model "dateValue" mit GETTER & SETTER
    _dateValue = null;
    // Setter dateValue
    @Input() set dateValue(value) {
        if (value === null) {
            this.showError = false;
            this._dateValue = value;
            this.propagateChange(this._dateValue);
        } else if (!moment.isMoment(value) && typeof value === 'string') {
            this.showError = false;
            this._dateValue = moment(value, this.dateValueStringFormat);
            this.propagateChange(this._dateValue);
        } else if (!moment.isMoment(value)) {
            this.showError = false;
            this._dateValue = moment(value);
            this.propagateChange(this._dateValue);
        } else if (moment.isMoment(value) && !value.isValid()) {
            this.showError = true;
        } else {
            this._dateValue = value;
            this.showError = false;
            this.propagateChange(this._dateValue);
        }
    }

    // Getter dateValue
    get dateValue() {
        return this._dateValue;
    }

    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    propagateChange = (_: any) => {};

    // Attribut: required = Pflichtfeld (ja / nein)
    @Input() required = false;

    // Event-Emitter, falls der Wert gelöscht wurde
    @Output() deleteClicked = new EventEmitter<any>();

    /**
     * Konstruktor (inkl. dependency injection)
     */
    constructor() {}

    /**
     * Initialisieren
     */
    ngOnInit() {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * Interface ControlValueAccessor: writeValue
     * @param value
     */
    writeValue(value: any) {
        if (value !== undefined) {
            this.dateValue = value;
        }
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}

    /**
     * Formatiere Daten in ein deutsches Datumsformat um sie Anzuzeigen. Ausnahme leere Werte
     * @param date
     */
    parse(date: any): any {
        if (date !== null && moment.isMoment(date)) {
            return date.format(MOMENT_DISPLAY_FORMAT);
        } if (date !== null && typeof date === 'string') {
            return moment(date, this.dateValueStringFormat).format(MOMENT_DISPLAY_FORMAT);
        } if (date !== null) {
            return moment(date).format(MOMENT_DISPLAY_FORMAT);
        }
        return null;
    }

    /**
     * Emittiert ein Event, das eigentlich besagt, dass das Datum geändert wurde.
     * Wird aber genutzt, um den Parent auf Klicks auf den Datepicker aufmerksam zu machen
     * Kleiner Hack, da Datepicker Click-Event überschreibt.
     */
    emitClick(): void {
        this.clickEvent.emit(true);
    }

    /**
     * Lösche den Datums-Wert
     */
    deleteValue(): void {
        this.dateValue = null;
        this.deleteClicked.emit();
    }

    /**
     * Jahresauswahl verarbeiten
     * @param event
     */
    chosenYearHandler(event: any): void {
        this.dateValue = event;
    }

    /**
     * Monatsauswahl verarbeiten
     * @param event
     * @param picker
     */
    chosenMonthHandler(event: any, picker: any): void {
        this.dateValue = event;
        picker.close();
        this.emitClick();
    }

    /**
     * Setzt Datum ein Tag früher/später oder auf heute
     * Hier wird Date-Funktion verwendet, da ohne die wird das Datum in Anzeige nicht aktualisiert.#
     * Evtl. später wird eine schönere Lösung gefunden
     * @param direction
     */
    setDate(direction: string): void {
        // Richtung auswählen
        switch (direction) {
            // Einen Tag früher
            case 'left':
                this.dateValue = new Date(this.dateValue.add(-1, 'months'));
                break;

            // Einen Tag später
            case 'right':
                this.dateValue = new Date(this.dateValue.add(1, 'months'));
                break;

            // Heute
            case 'today':
            default:
                this.dateValue = moment();
                break;
        }

        // Seite neu laden
        this.clickEvent.emit(true);
    }
}
