// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Interfaces für Structured Objects einbinden
import {Contacts} from '@shared/contacts';

@Injectable({providedIn: 'root'})
export class ReportsContactsOverviewService {
    // Ausgewählter Kontakt
    public selectedContact: Contacts;

    // Subject (Observable) definieren "Es wurde ein anderer Kontakt ausgewählt"
    public selectionChanged = new Subject<number>();

    // Konstruktor
    constructor() {}

    /*
     * @brief   Wird aufgerufen, falls ein anderer Kontakt ausgewählt werden soll
     */
    selectContact(selectedContact: Contacts) {
        // Vorherige Kontaktauswahl aufheben
        this.selectEmptyContactsData();
        // Ausgewählten Kontakt zwischenspeichern
        this.selectedContact = selectedContact;
        // Event auslösen um andere Komponenten des Feature-Moduls zu informieren
        this.selectionChanged.next(this.selectedContact.id);
    }

    /*
     * @brief   Wird aufgerufen, falls die Auswahl "geleert" werden soll
     */
    selectEmptyContactsData() {
        this.selectedContact = null;
        this.selectionChanged.next(-1);
    }
}
