// Angular-Module
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// Environment einbinden
import {environment} from '@environment';
// Interfaces für Structured Objects einbinden
import {LooseObject} from '@shared/loose-object';

@Component({
    selector: 'phscw-global-settings-popup',
    templateUrl: './global-settings-popup.component.html',
    styleUrls: ['./global-settings-popup.component.scss'],
})
export class GlobalSettingsPopupComponent implements OnInit, OnDestroy {
    // Definiert, welches Setting gerade gesetzt werden soll / welcher Button geklickt wurde
    selectedSetting = '';

    // Definiert, welche Settings der Benutzer überhaupt verändern darf
    settingsPasswordEnabled = false;
    settingsLanguageEnabled = false;
    settingsSelectDeputyEnabled = false;

    // Konstruktor
    constructor(
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) private data: LooseObject,
        public dialogRef: MatDialogRef<GlobalSettingsPopupComponent>,
    ) {}

    // Initialisierung der Komponente
    ngOnInit() {
        // Definiere über Environment-Variablen, welche Settings der Benutzer verändern darf
        if (typeof environment.settingsPasswordEnabled !== 'undefined') {
            this.settingsPasswordEnabled = environment.settingsPasswordEnabled;
        }
        if (typeof environment.settingsLanguageEnabled !== 'undefined') {
            this.settingsLanguageEnabled = environment.settingsLanguageEnabled;
        }
        if (typeof environment.settingsSelectDeputyEnabled !== 'undefined') {
            this.settingsSelectDeputyEnabled = environment.settingsSelectDeputyEnabled;
        }

        // Direkt zu Formular springen
        if (
            this.data !== null &&
            Object.prototype.hasOwnProperty.call(this.data, 'selectedSetting') &&
            typeof this.data.selectedSetting === 'string' &&
            this.data.selectedSetting.length > 0
        ) {
            this.selectedSetting = this.data.selectedSetting;
        }
    }

    // Aufräumen
    ngOnDestroy() {
        // empty method
    }

    // Falls ein Einstellungs-Button angeklickt wurde
    clickSettingsButton(setting: string) {
        this.selectedSetting = setting;
    }

    // Falls die Änderung einer Einstellung abgeschlossen wird, werden wieder alle verfügbaren Einstellungen angezeigt
    clickSettingsClose() {
        this.selectedSetting = '';
    }

    // Popup "Einstellungen" schliessen
    clickAndClose() {
        // Dialog (über Referenz) schließen und Dialog-Ergebnis mitsenden
        this.dialogRef.close();
    }
}
