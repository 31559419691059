/**
 * @brief Data-Transfer-Object für Order-Daten
 */
export class OrderDto {
    id: number;
    phpi_id?: string;
    institution_id: number;
    person_id?: number;
    region_id: number;
    employee_id?: number;
    order_type?: string;
    order_status?: string;
    order_range?: string;
    order_sum?: number;
    order_discount?: number;
    order_total?: number;
    currency?: string;
    shipping_costs?: number;
    text?: string;
    text_extern?: string;
    order_date?: Date;
    delivery_date?: Date;
    valuta_date?: Date;
    value_date?: Date;
    payment_condition?: string;
    erp_number?: string;
    confirmation?: number;
    confirmation_message?: string;
    institution_erp_number?: string;
    order_name?: string;
    order_street?: string;
    order_street_number_from?: string;
    order_street_number_to?: string;
    order_zip_code?: string;
    order_city?: string;
    order_person_name?: string;
    delivery_institution_id?: number;
    delivery_name?: string;
    delivery_street?: string;
    delivery_street_number_from?: string;
    delivery_street_number_to?: string;
    delivery_zip_code?: string;
    delivery_city?: string;
    delivery_person_id?: number;
    delivery_person_name?: string;
    delivery_note?: string;
    delivery_time_from?: string;
    delivery_time_to?: string;
    invoice_institution_id?: number;
    invoice_name?: string;
    invoice_street?: string;
    invoice_street_number_from?: string;
    invoice_street_number_to?: string;
    invoice_zip_code?: string;
    invoice_city?: string;
    invoice_person_id?: number;
    invoice_person_name?: string;
    invoice_note?: string;
    wholesaler_institution_id?: number;
    wholesaler_name?: string;
    wholesaler_street?: string;
    wholesaler_street_number_from?: string;
    wholesaler_street_number_to?: string;
    wholesaler_zip_code?: string;
    wholesaler_city?: string;
    wholesaler_person_id?: number;
    wholesaler_person_name?: string;
    contact_method?: string;
    contact_id?: number;
    purchase_order_number?: string;
    campaign?: string;
    company_code?: string;

    create_date?: Date;
    create_user?: string;
    edit_date?: Date;
    edit_user?: string;
    delete_date?: Date;
    delete_user?: string;
    deleted?: string;

    order_items: OrderItemDto[];

    // Dynamisch erzeugte Felder, die nicht in der Datenbank vorhanden sind
    deliveryDates: Date[];

    orderItemsReadonlyView?: OrderItemDto[];

    deliveryInstitutions: number[];

    // Konstruktor
    constructor() {
        this.id = 0;
    }
}

/**
 * @brief Data-Transfer-Object für Order-Item-Daten
 */
export class OrderItemDto {
    id: number;
    order_id: number;
    product_id: number;
    order_item_status?: string;
    delivery_date?: Date | string;
    delivery_institution_id?: number;
    order_erp_number?: string;
    product_erp_number?: string;
    amount?: number;
    unit_price?: number;
    item_price?: number;
    discount?: number;
    total_price?: number;
    charge?: string;
    tracking_number?: string;
    tracking_url?: string;
    planned_delivery_date_from?: Date;
    planned_delivery_date_till?: Date;
    delivery_time?: Date;
    delivery_quantity?: number;
    package_unit_ids?: string;
    note?: string;
    batchnumnber?: string;
    create_date?: Date;
    create_user?: string;
    edit_date?: Date;
    edit_user?: string;
    delete_date?: Date;
    delete_user?: string;
    deleted?: string;
}
