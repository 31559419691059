// Angular-Module
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatCellDef, MatColumnDef, MatHeaderCellDef, MatTable} from '@angular/material/table';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service dieses Shared-Moduls
import {GridService} from './../../grid.service';
// GridComponent
import {GridComponent} from './../grid.component';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {MenuData} from '@shared/menu-data';
// Datumsformat
import {MOMENT_DISPLAY_FORMAT} from '@shared/input/input-date/input-date.component';
// Moment-Modul zur Datums-Verarbeitung
import * as _moment from 'moment';

const moment = _moment;

@Component({
    selector: 'phscw-grid-history',
    templateUrl: './grid-history.component.html',
    styleUrls: ['./grid-history.component.scss', './../grid.component.scss'],
})
export class GridHistoryComponent implements OnInit, OnDestroy {
    // Referenz auf Spalte
    @ViewChild(MatColumnDef, {static: true}) columnDef: MatColumnDef;
    @ViewChild(MatHeaderCellDef, {static: true}) headerCellDef: MatHeaderCellDef;
    @ViewChild(MatCellDef, {static: true}) cellDef: MatCellDef;

    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;

    // Spalten-Name der auf die Spalte verweist
    private _name: string;
    @Input()
    set name(name: string) {
        this._name = name;
        if (this.columnDef) {
            this.columnDef.name = name;
        }
    }

    get name(): string {
        return this._name;
    }

    // angeklicktes Element
    currentElement: any;
    // aktuelle ausgewählte Reihe
    currentId: number = null;
    // aktuell angezeigte Version
    currentVersion: number = null;

    // Flag definiert, ob Menü angezeigt wird
    buttonMenuItemsVisible = false;
    // Flag definiert, ob gerade geladen wird
    loading = false;

    // Daten zu den verfügbaren Versionen
    historyMenuData: MenuData[] = [];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param table
     * @param translateService
     * @param gridService
     */
    constructor(
        private table: MatTable<any>,
        private translateService: TranslateService,
        private gridService: GridService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        if (this.table) {
            this.columnDef.name = this._name;
            this.columnDef.headerCell = this.headerCellDef;
            this.columnDef.cell = this.cellDef;
            this.table.addColumnDef(this.columnDef);
        }

        // Event "appDataChanged" von "DocumentsService"
        this.gridService.eventGridHistoryDataInitialized
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht für die eigene Komponente ist
                if (event.target !== this.gridConnection.gridId) {
                    return;
                }
                this.onEventGridHistoryDataInitialized(event);
            });
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        if (this.table) {
            this.table.removeColumnDef(this.columnDef);
        }

        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Klick auf Icon
     * @param id
     * @param currentVersion
     */
    clickIcon(id: number, currentVersion: number): void {
        // aktuelle Version setzen
        this.currentVersion = currentVersion;
        // aktuelle ID setzen
        this.currentId = id;

        if (!this.loading && !this.buttonMenuItemsVisible) {
            // Flag setzen
            this.loading = true;

            // Event auslösen
            this.gridService.historyIconClicked(this.gridConnection.gridId, id);
        } else {
            this.buttonMenuItemsVisible = false;
            this.historyMenuData = [];
        }
    }

    /**
     * Klick auf Icon
     * @param event
     */
    onEventGridHistoryDataInitialized(event: CWEvent): void {
        // nur für angeklickt Reihe anzeigen
        if (event.data[0].id !== this.currentId) {
            return;
        }

        // Menüdaten aufbauen
        event.data.forEach((item: any) => {
            // Datum formatieren
            const date = moment(item.version_date).format(MOMENT_DISPLAY_FORMAT + ' HH:mm:ss');

            // Menüpunkt anlegen
            const menuItem: MenuData = {
                name: item.id,
                shownText: date + ' - Version ' + (item.version_number + 1),
            };

            // Sprachabhängigen Text anhängen
            menuItem['shownText_' + this.translateService.currentLang] =
                date +
                ' - ' +
                this.translateService.instant('GENERAL.VERSION') +
                ' ' +
                (item.version_number + 1) +
                ' - ' +
                item.version_author;

            // aktuelle Version markieren
            if (item.version_number === this.currentVersion) {
                menuItem.icon = 'icon-check';
            }

            // Daten an Menü anhängen
            this.historyMenuData.push(menuItem);
        });

        // Flag setzen
        this.loading = false;
        this.buttonMenuItemsVisible = true;
    }

    /**
     * Klick auf Menü
     * @param menuItem
     */
    clickMenu(menuItem: MenuData): void {
        this.gridService.historyMenuItemClicked(this.gridConnection.gridId, menuItem.name);
    }
}
