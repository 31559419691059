// Oberklasse importieren
import {OverlayInfoComponent} from './../overlay-info.component';
// Angular-Module
import {Component, OnDestroy, OnInit} from '@angular/core';
// Interfaces für Structured Objects einbinden
import {CWResult} from '@shared/cw-result';
import {LooseObject} from '@shared/loose-object';
// Environment
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

/*
 * @brief   Komponente um Mitarbeiterdetails in einem
 *          Info-Popup anzuzeigen.
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakons.software>
 */
@Component({
    selector: 'phscw-overlay-info-employee',
    templateUrl: './overlay-info-employee.component.html',
    styleUrls: ['./../overlay-info.component.scss', './overlay-info-employee.component.scss'],
})
export class OverlayInfoEmployeeComponent extends OverlayInfoComponent implements OnInit, OnDestroy {
    // Konfiguration der angezeigten Felder für den Namen des Gebiets
    regionLabelConfiguration: string[] = ['regionkey', 'name'];

    // Daten zur Anzeige
    regionData: LooseObject = null;
    divisionData: LooseObject = null;
    employeeData: LooseObject = null;

    /**
     * Initialisieren
     */
    ngOnInit() {
        // onInit der vererbenden Komponente aufrufen
        super.ngOnInit();

        // Einstellungen aus Environment laden
        if (hasOwn(environment, 'regionAssignmentDatafields')) {
            this.regionLabelConfiguration = environment['regionAssignmentDatafields'];
        }
    }

    /**
     * @brief   Daten laden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadData(): void {
        // Flag "loading" aktivieren
        this.loading = true;
        this.success = false;

        // Methode auslesen oder Standardmethode verwenden
        const backendMethod = this.data.backendMethod || 'details';

        // Daten des Mitarbeiters laden
        const serviceRequest$ = this.overlayService.loadOverlayInformation(
            this.data.backendController,
            this.data.entityId,
            backendMethod,
        );
        serviceRequest$.subscribe(
            (result: CWResult) => {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zum angefragten Element passen. Durch asynchrone
                 * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                 * bereits das Element gewechselt wurde und die Antwort
                 * eines Requests verspätet eintrifft und dadurch die
                 * korrekten Daten wieder überschreibt.
                 */
                if (result.data === null || this.overlayService.entityId != result.data['region']['id']) {
                    this.loading = false;
                    return;
                }

                if (result.success) {
                    // empfangene Daten zwischenspeichern
                    this.regionData = result.data['region'];
                    this.divisionData = result.data['division'];
                    this.employeeData = result.data['employee'];

                    // Flag "success" aktivieren
                    this.success = true;
                }

                // Titel setzen
                this.setHeaderText();

                // Flag "loading" deaktivieren
                this.loading = false;
            },
            (error: any) => {
                // Flag "loading" deaktivieren
                this.loading = false;
            },
        );
    }

    /**
     * @brief   Header-Text ändern
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    setHeaderText(): void {
        if (this.employeeData !== null) {
            this.headerText = this.getFullEmployeeName();
        } else if (this.divisionData !== null) {
            this.headerText = this.getDivisionName();
        } else if (this.regionData !== null) {
            this.headerText = this.getRegionName();
        }
    }

    /**
     * @brief   Mitarbeiternamen zusammensetzen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private getFullEmployeeName(): string {
        // Init
        let returnValue = '';

        // vorhandene Werte prüfen
        if (this.employeeData.firstname) {
            returnValue += this.employeeData.firstname;
        }
        if (returnValue.length > 0) {
            returnValue += ' ';
        }
        if (this.employeeData.lastname) {
            returnValue += this.employeeData.lastname;
        }

        // Return
        return returnValue;
    }

    /**
     * @brief   Bezeichnung der Linie zusammensetzen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private getDivisionName(): string {
        // Init
        let returnValue = '';

        // vorhandene Werte prüfen
        if (this.divisionData.name) {
            returnValue += this.divisionData.name;
        }

        // Return
        return returnValue;
    }

    /**
     * @brief   Bezeichnung des Gebiets zusammensetzen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private getRegionName(): string {
        // Init
        let returnValue = '';

        // Für jedes definierte Feld den entsprechenden Wert anhängen
        this.regionLabelConfiguration.forEach((field: string) => {
            // Werte mit Leerzeichen trennen
            if (returnValue.length > 0) {
                returnValue += ' ';
            }
            returnValue += this.regionData[field];
        });

        // Return
        return returnValue;
    }
}
