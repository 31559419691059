import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

@Pipe({name: 'businessMonth'})

/**
 * Pipe um zu berechnen, welcher "absolute" Monat einem Monat des Geschäftsjahres entspricht
 * @details Beispiel: Wenn das Geschäftsjahr im Oktober beginnt, ist der 5. Monat eines Geschäftsjahr der Februar.
 *          Die Pipe wandelt daher bei konfiguriertem businessYearStart = 10 eine 5 zu einer 2.
 *          Diese Zahl kann mit dem TranslationString "GENERAL.MONTHS." konkateniert werden, um einen übersetzbaren
 *          String für den gewünschten Monat zu erhalten.
 * @example Folgende Expression evaluiert in einem HTML-Template immer zum lokalisierten Namen des ersten Geschäftsmonats:
 *          {{ 'GENERAL.MONTHS.' + (1 | businessMonth) | translate }}
 * @author  Lennart Bentz <l.bentz@pharmakon.software>
 */
export class BusinessMonthPipe implements PipeTransform {
    transform(startMonth: number): string {
        if (
            hasOwn(environment, 'businessYearStart') &&
            environment.businessYearStart > 1 &&
            environment.businessYearStart <= 12
        ) {
            // -2 da beide Zahlen bei 1 zu zählen beginnen (Jahresstart im Januar = kein offset). Am Ende +1, um wieder 1 = erster Monat zu erhalten.
            return String(((startMonth + environment.businessYearStart - 2) % 12) + 1);
        }
        // Ergibt bei businessYearStart === 1 das gleiche Ergebnis, aber spart die Berechnung.
        return String(startMonth);
    }
}
