import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
// Interface für Optionen
import {SelectData} from '../../select-data';
// import {mixinDisabled} from '@angular/material';

/*
 * Custom Validator "required". Es soll mindestens eine Option gecheckt sein.
 * returns true für error, false für valide
 */
/**
 *
 */
export function createRequiredValidator() {
    return function validateRequired(value: any) {
        if (typeof value === 'object' && value !== null) {
            if (Object.keys(value).length === 0) {
                return true;
            }
            for (const property in value) {
                if (value[property] === true) {
                    return false;
                }
            }
            return true;
        }
        return true;
    };
}

@Component({
    selector: 'phscw-input-checkbox',
    templateUrl: './input-checkbox.component.html',
    styleUrls: ['./input-checkbox.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputCheckboxComponent),
        multi: true,
    }],
})
export class InputCheckboxComponent implements OnInit, OnDestroy {
    // EditMode aktiv?
    @Input() editMode = false;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Label des Input-Feldes
    @Input() label: string;
    // Soll die Checkbox außerhalb des edit-Modus angezeigt werden
    @Input() showCheckbox = false;
    // Sollen die Labels der Optionen angezeigt werden
    @Input() showOptionLabel = true;
    // Sollen die Optionen untereinander oder nebeneinander angezeigt werden?
    @Input() displayDirection = 'vertical';
    // Sind die Checkboxen Pflichtfelder. Momentan ohne Logik, die das prüft.
    @Input() required = false;
    // Attribut: disabled = Gesperrt (ja / nein)
    @Input() disabled = false;
    // Checkboxen-Informationen mit Ids und label
    @Input() checkboxOptions: Array<SelectData>;
    // Soll der Löschbutton angezeigt werden
    @Input() showDeleteButton = false;

    // Hat sich der Wert der Checkbox geändert
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onChange = new EventEmitter<boolean>();
    // Event-Emitter, falls der Wert gelöscht wurde
    @Output() deleteClicked = new EventEmitter<any>();

    // Objekt, das über die Ids den Checkboxen ihre Werte zuordnet
    _checkboxValue: any = {};
    // Setter checkboxValue
    @Input() set checkboxValue(value) {
        if (value === null) {
            this._checkboxValue = {};
        } else {
            this._checkboxValue = value;
        }

        // Validierung initial auslösen
        if (this.required) {
            this.triggerValidate();
        }

        this.propagateChange(this._checkboxValue);
    }

    // Getter checkboxValue
    get checkboxValue() {
        return this._checkboxValue;
    }

    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    propagateChange = (_: any) => {};

    // Event zum Mitteilen, ob die Checkbox-Form invalide ist.
    @Output() invalidate = new EventEmitter<boolean>();

    // Validierungs-Funktion
    validateFn: any;

    /**
     * Konstruktor (inkl. dependency injection)
     */
    constructor() {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        if (this.required) {
            this.validateFn = createRequiredValidator();
            this.triggerValidate();
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * Interface ControlValueAccessor: writeValue
     * @param value
     */
    writeValue(value: any) {
        if (value !== undefined) {
            this.checkboxValue = value;
        }
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}

    /**
     * Informiere die übergeordnete Komponente, dass sich der Checkbox-Wert geändert hat.
     */
    checkBoxValueChanged(): void {
        this.triggerValidate();
        this.onChange.emit(this.checkboxValue);
    }

    /**
     * Lösche den Checkbox-Wert
     */
    deleteValue(): void {
        this.checkboxValue = null;
        this.deleteClicked.emit();
    }

    /**
     * Validiere die Checkboxen
     */
    triggerValidate() {
        // Wenn die Validierungs-Funktion definiert ist...
        if (typeof this.validateFn !== 'undefined') {
            // ... sende ein Event an die übergeordnete Komponente.
            const invalid: boolean = this.validateFn(this.checkboxValue);
            this.invalidate.next(invalid);
        }
    }
}
