// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
import {LooseObject} from '@shared/loose-object';

@Injectable({providedIn: 'root'})
export class InstitutionsDiscountsService {
    /**
     * Konstruktor (inkl. dependency injection)
     * @param backendService
     */
    constructor(private backendService: BackendService) {}

    /**
     * @param id
     * @param gridPage
     * @param pageSize
     * @param requestBody
     * @brief   Konditionen einer Einrichtung laden
     * @returns  Observable<any>
     */
    loadData(id: number, gridPage: number, pageSize: number = null, requestBody: LooseObject = {}): Observable<any> {
        let backendPath: string = 'InstitutionsDiscounts/index/' + id + '/' + gridPage;
        if (pageSize !== null) {
            backendPath += '/' + pageSize;
        }
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(backendPath, requestBody);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
