// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Service der übergeordneten Komponente
import {ToolbarService} from '../toolbar.service';

@Component({
    selector: 'phscw-toolbar-transfer',
    templateUrl: './toolbar-transfer.component.html',
    styleUrls: ['./toolbar-transfer.component.scss'],
})
export class ToolbarTransferComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;
    // Tooltip
    @Input() tooltip: string = null;
    // Parameter
    @Input() parameter: any;

    // Flag definiert, ob gerade geladen wird
    @Input() loading = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param toolbarService
     */
    constructor(private toolbarService: ToolbarService) {}

    ngOnInit(): void {}

    /*
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /*
     * Daten einfügen
     */
    clickTransfer() {
        this.toolbarService.clickTransfer(this.componentIdentifier);
    }
}
