// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Interfaces für Structured Objects einbinden
import {CWResult} from '@shared/cw-result';
import {LooseObject} from '@shared/loose-object';
import {SelectData} from '@shared/select-data';
// Eigener Service
import {InstitutionsService} from '@modules/institutions/institutions.service';
import {OrderFormService} from '../../order-form.service';

// Moment-Modul zur Datums-Verarbeitung
import {notEmptyObject} from '@shared/utils';
import * as _moment from 'moment';
import {takeUntil} from 'rxjs/operators';

const moment = _moment;

@Component({
    selector: 'phscw-order-form-header',
    templateUrl: './order-form-header.component.html',
    styleUrls: ['./order-form-header.component.scss'],
})
export class OrderFormHeaderComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Editmode aktiv?
    @Input() editMode = false;

    // ID der ausgewählten Einrichtung
    _institutionId: any = null;
    get institutionId() {
        return this._institutionId;
    }

    @Input() set institutionId(value) {
        if (!value) {
            return;
        }
        if (value != this._institutionId) {
            // Nach Änderung der Einrichtungs ID mögliche Zusatznummer laden
            this.getAvailableSupplementaryNumbers(value);
            // auswählbare Lieferempfänger holen
            this.getAvailableDeliveryInstitutions(value);
        }
        this._institutionId = value;
    }

    // Auftragsdaten
    @Input() set orderData(order: LooseObject) {
        // Prüfe ob orderData nicht null/undefined oder empty object ist
        if (!(order && Object.keys(order).length === 0 && Object.getPrototypeOf(order) === Object.prototype)) {
            this.orderDataCopy = Object.assign(order);
            this.initializeOnAvailableData(order);
        }
    }

    // Zwischenspeichern der Auftragsdaten
    orderDataCopy: LooseObject;

    // init. Reactive Form
    orderHeaderFormGroup: UntypedFormGroup = new UntypedFormGroup({
        id: new UntypedFormControl(null, [Validators.maxLength(10)]),
        order_status: new UntypedFormControl(null),
        erp_number: new UntypedFormControl(null, [Validators.maxLength(20)]),
        order_type: new UntypedFormControl(null),
        order_range: new UntypedFormControl(null),
        region_id: new UntypedFormControl(null),
        employee_id: new UntypedFormControl(null),
        order_institution_id: new UntypedFormControl(null),
        order_name: new UntypedFormControl(null),
        order_street: new UntypedFormControl(null),
        order_zip_code: new UntypedFormControl(null),
        order_city: new UntypedFormControl(null),
        institution_erp_number: new UntypedFormControl(null),
        order_person_name: new UntypedFormControl(null),
        order_date: new UntypedFormControl(null),
        invoice_institution_id: new UntypedFormControl(null),
        invoice_name: new UntypedFormControl(null),
        invoice_street: new UntypedFormControl(null),
        invoice_zip_code: new UntypedFormControl(null),
        invoice_city: new UntypedFormControl(null),
        valuta_date: new UntypedFormControl(null),
        payment_condition: new UntypedFormControl(null),
        purchase_order_number: new UntypedFormControl(null),
        confirmation: new UntypedFormControl(null),
        order_discount: new UntypedFormControl(null),
        wholesaler_institution_id: new UntypedFormControl(null),
        wholesaler_name: new UntypedFormControl(null),
        wholesaler_street: new UntypedFormControl(null),
        wholesaler_zip_code: new UntypedFormControl(null),
        wholesaler_city: new UntypedFormControl(null),
        delivery_institution_id: new UntypedFormControl(null),
        delivery_name: new UntypedFormControl(null),
        delivery_street: new UntypedFormControl(null),
        delivery_zip_code: new UntypedFormControl(null),
        delivery_city: new UntypedFormControl(null),
        delivery_date: new UntypedFormControl(null),
        delivery_dates: new UntypedFormArray([]),
        text: new UntypedFormControl(null, [Validators.maxLength(4000)]),
        text_extern: new UntypedFormControl(null, [Validators.maxLength(4000)]),
        campaign: new UntypedFormControl(null),
        company_code: new UntypedFormControl(null),
    });

    deliveryDatesFormArray: UntypedFormArray;

    // Zwischenspeicher für Autocomplete Felder mit Anzeige der Einrichtungsadressen
    deliveryInstitution = '';
    wholesalerInstitution = '';

    // Alle Auswählbaren ERP-Nummern der momentanen Einrichtungen
    institutionSupplementaryNumbers: SelectData[];
    // Alle Auswählbaren Lieferempfänger
    deliveryInstitutionSelectData: SelectData[];

    deliveryDates: Date[] = [];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param orderFormService
     * @param institutionsService
     */
    constructor(
        public orderFormService: OrderFormService,
        private institutionsService: InstitutionsService,
    ) {
        this.deliveryDatesFormArray = <UntypedFormArray> this.orderHeaderFormGroup.controls['delivery_dates'];
    }

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // verfügbare ERP-Nummern der Einrichtung holen
        this.getAvailableSupplementaryNumbers(this.institutionId);

        // auswählbare Lieferempfänger holen
        this.getAvailableDeliveryInstitutions(this.institutionId);
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Änderung an Lieferdaten
        this.deliveryDatesFormArray.valueChanges.pipe(takeUntil(this._componentDestroyed$)).subscribe((value) => {
            if (notEmptyObject(value) && Array.isArray(value) && value.length > 0) {
                // Übergangszustand abfangen, wenn ein Datum gelöscht wurde und eines der Objekte null ist
                if (value.findIndex((v) => v === null) === -1) {
                    // input-date liefert Moment-Objekte keine Dates, diese konvertieren, um hier einheitlich mit Date zu arbeiten
                    this.deliveryDates = value.map(this.moment2date);
                    this.orderFormService.deliveryDatesChanged.next(this.deliveryDates);
                }
            }
        });

        // Auswahl der Besteller-Einrichtung - Adresse in die Felder übernehmen
        this.orderHeaderFormGroup.controls.order_institution_id.valueChanges.subscribe((value) => {
            if (notEmptyObject(value) && notEmptyObject(value['name1'])) {
                /*
                 * Daten nur übernehmen, wenn erweiterte Informationen vorliegen:
                 * Autocomplete liefert je nach Configuration auch die Adresse mit
                 */
                this.orderHeaderFormGroup.patchValue({
                    order_name: value['name1'] ?? '',
                    order_street: value['street'] ?? '',
                    order_zip_code: value['zipcode'] ?? '',
                    order_city: value['city'] ?? '',
                });
            }
        });

        // Auswahl eines Rechnungsempfängers - Adresse in die Felder übernehmen
        this.orderHeaderFormGroup.controls.invoice_institution_id.valueChanges
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((value) => {
                if (notEmptyObject(value)) {
                    this.orderHeaderFormGroup.patchValue({
                        invoice_name: value['name1'] ?? '',
                        invoice_street: value['street'] ?? '',
                        invoice_zip_code: value['zipcode'] ?? '',
                        invoice_city: value['city'] ?? '',
                    });
                }
            });

        // Auswahl eines Lieferempfängers
        this.orderHeaderFormGroup.controls.delivery_institution_id.valueChanges
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((value) => {
                if (notEmptyObject(value) && notEmptyObject(this.deliveryInstitutionSelectData)) {
                    const deliveryInstitution = this.deliveryInstitutionSelectData.find((deli) => deli.id == value);
                    this.orderHeaderFormGroup.patchValue(deliveryInstitution.data);
                }
            });

        // Auswahl eines Großhändlers - Adresse in die Felder übernehmen
        this.orderHeaderFormGroup.controls.wholesaler_institution_id.valueChanges
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((value) => {
                if (notEmptyObject(value)) {
                    this.orderHeaderFormGroup.patchValue({
                        wholesaler_name: value['name1'] ?? '',
                        wholesaler_street: value['street'] ?? '',
                        wholesaler_zip_code: value['zipcode'] ?? '',
                        wholesaler_city: value['city'] ?? '',
                    });
                }
            });
    }

    /**
     * @brief Helper, um aus Date und Moment-Objekten immer ein Date zu machen
     * @param m Datumsobjekt entweder vom Typ Moment oder Date
     */
    moment2date = (m): Date | null => {
        if (m instanceof Date) {
            return m;
        } if (moment.isMoment(m)) {
            return m.toDate();
        }
        return null;
    };

    /**
     * @brief   erst initialisieren wenn Auftragsdaten vorhanden sind
     * @param   {LooseObject} order
     */
    initializeOnAvailableData(order: LooseObject) {
        // Labels für Einrichtung-Autocompletes zusammenbauen
        this.deliveryInstitution = order.delivery_institution_id ?
            order.delivery_name +
            ', ' +
            order.delivery_street +
            ', ' +
            order.delivery_zip_code +
            ', ' +
            order.delivery_city :
            '';
        this.wholesalerInstitution = order.wholesaler_institution_id ?
            order.wholesaler_name +
            ', ' +
            order.wholesaler_street +
            ', ' +
            order.wholesaler_zip_code +
            ', ' +
            order.wholesaler_city :
            '';

        this.initializeFormControls(order);
    }

    /**
     * @brief   initialize Form Data
     * @param   {LooseObject} order Auftragsdaten
     */
    initializeFormControls(order: LooseObject) {
        // ReactiveForm zurücksetzen
        this.orderHeaderFormGroup.reset();
        this.deliveryDatesFormArray.clear();

        let selectedInstitution = null;

        // Prüfe ob es sich um eine Neuanlage handelt oder ein existierender Auftrag geöffnet wird.
        if (notEmptyObject(order.id) && order.id > 0) {
            // Bei existierenden Auftrag: Weise Einrichtungsdaten anhand der Order-Daten des momentan ausgewählten Auftrags zu
            selectedInstitution = {
                id: order.institution_id,
                name1: order.order_name,
            };
        } else {
            // Bei Neuanlage: Weise Einrichtungsdaten anhand der momentan ausgewählten Einrichtung in der E-Liste zu
            selectedInstitution = this.institutionsService.selectedInstitution;
        }

        // die vorhandenen Lieferdaten wurden aus den Items ermittelt
        this.deliveryDates = order.deliveryDates;
        // für jedes Lieferdatum ein Eingabefeld erzeugen
        order.deliveryDates.forEach((value, index) => {
            this.deliveryDatesFormArray.push(this.createDateFormControl(value));
        });

        // übergebene Werte in ReactiveForm setzen
        this.orderHeaderFormGroup.patchValue({
            id: order.id,
            order_status: order.order_status,
            erp_number: order.erp_number,
            order_type: order.order_type,
            order_range: order.order_range,
            region_id: order.region_id,
            employee_id: {
                id: order.employee_id,
                label: order.employee_label ?? order.employee_id,
            },
            order_institution_id: {
                id: selectedInstitution.id,
                label: selectedInstitution.name1,
            },
            order_name: order.order_name,
            order_street: order.order_street,
            order_zip_code: order.order_zip_code,
            order_city: order.order_city,
            institution_erp_number: order.institution_erp_number,
            order_person_name: order.order_person_name,
            order_date: order.order_date,
            invoice_institution_id: order.invoice_institution_id,
            invoice_name: order.invoice_name,
            invoice_street: order.invoice_street,
            invoice_zip_code: order.invoice_zip_code,
            invoice_city: order.invoice_city,
            valuta_date: order.valuta_date,
            payment_condition: order.payment_condition,
            confirmation: order.confirmation,
            purchase_order_number: order.purchase_order_number,
            // wholesaler_institution_id: {id: order.wholesaler_institution_id, label: this.wholesalerInstitution},
            wholesaler_institution_id: order.wholesaler_institution_id,
            wholesaler_name: order.wholesaler_name,
            wholesaler_street: order.wholesaler_street,
            wholesaler_zip_code: order.wholesaler_zip_code,
            wholesaler_city: order.wholesaler_city,
            // delivery_institution_id: {id: order.delivery_institution_id, label: this.deliveryInstitution},
            delivery_institution_id: order.delivery_institution_id,
            delivery_name: order.delivery_name,
            delivery_street: order.delivery_street,
            delivery_zip_code: order.delivery_zip_code,
            delivery_city: order.delivery_city,

            delivery_date: order.delivery_date,
            text: order.text,
            text_extern: order.text_extern,
            campaign: order.campaign,
            order_discount: order.order_discount ?? 0,
            company_code: order.company_code,
        });
    }

    /**
     * @brief   Hole alle verfügbaren ERP-Nummern der Einrichtung für SelectData
     * @param   {number} institutionId
     */
    getAvailableSupplementaryNumbers(institutionId: number) {
        if (institutionId == null) {
            return;
        }
        const serviceRequest$ = this.orderFormService.getInstitutionSupplementaryNumbers(institutionId);
        serviceRequest$.subscribe((result: CWResult) => {
            this.institutionSupplementaryNumbers = Object.assign(result['data']);

            // Falls nur eine ErpNummer zurückkommt, direkt auswählen
            if (
                this.institutionSupplementaryNumbers.length == 1 &&
                this.orderHeaderFormGroup.get('institution_erp_number').value == undefined
            ) {
                // Leider timeout nötig, da die select komponente sich selbst überschreibt
                setTimeout(() => {
                    this.orderHeaderFormGroup
                        .get('institution_erp_number')
                        .setValue(this.institutionSupplementaryNumbers[0]['id']);
                }, 250);
            }
        });
    }

    /**
     * @brief   Hole alle verfügbaren Lieferempfänger für SelectData
     * @param   {number} institutionId
     */
    getAvailableDeliveryInstitutions(institutionId: number) {
        if (institutionId == null) {
            return;
        }
        const serviceRequest$ = this.orderFormService.getDeliveryInstitutions(institutionId);
        serviceRequest$.subscribe((result: CWResult) => {
            this.deliveryInstitutionSelectData = Object.assign(result['data']);
        });
    }

    /**
     * @brief   Informiere das Formular für Auftragspositionen über geänderte orderType
     */
    onChangedOrderType() {
        // Formular für Auftragspositionen mitteilen, dass die Auftragsart geändert worden ist
        this.orderFormService.changedOrderType(this.orderHeaderFormGroup.get('order_type').value);
        // reactive forms requried anpassen
        for (const [key, value] of Object.entries(this.orderFormService.orderAccessRightsExplicit)) {
            if (!Object.prototype.hasOwnProperty.call(this.orderHeaderFormGroup.controls, key)) {
                continue;
            }
            if (value['required']) {
                this.orderHeaderFormGroup.controls[key].setValidators([Validators.required]);
            } else {
                this.orderHeaderFormGroup.controls[key].setValidators(null);
            }
            this.orderHeaderFormGroup.controls[key].updateValueAndValidity();
        }

        // Menge der Lieferdaten zurücksetzen, da je Ordertyp unterschiedlich viele möglich sind
        for (let i = this.deliveryDates.length - 1; i > 0; i--) {
            // Lieferdaten entfernen, aktualisiert auch das FormArray und dieses gibt Änderung an andere Listener weiter
            this.removeDeliveryDate(i); // schöner wäre es, alle auf einmal zu entfernen...
        }
    }

    /**
     * @brief Auf erweiterte List-Daten der ausgewählten Auftragsart reagieren, hier vorerst nur den Mandanten setzen
     * @param listData
     */
    onChangedOrderTypeListData(listData: any) {
        // Mandant geht aus der Auftragsart hervor - in entsprechendes Feld übernehmen
        if (notEmptyObject(listData) && Object.prototype.hasOwnProperty.call(listData, 'mandant')) {
            this.orderHeaderFormGroup.get('company_code').setValue(listData.mandant);
        }
    }

    /**
     * @brief   Informiere das Formular für Auftragspositionen über geänderte orderRange
     */
    onChangedOrderRange() {
        /*
         * Formular für Auftragspositionen mitteilen, dass die Auftragsart geändert worden ist
         * this.OrderFormService.onChangedOrderRange(this.orderHeaderFormGroup.get('order_range').value);
         */
        console.warn('onChangedOrderRange not yet implemented');
    }

    /**
     * @brief Erstelle ein FormControl für das übergebene Datum
     * @param value Wert, auf den das Control gesetzt wird
     * @private
     */
    private createDateFormControl(value: Date): UntypedFormControl {
        const formControl = new UntypedFormControl();
        formControl.setValue(value);
        return formControl;
    }

    /**
     * @brief Button zum Hinzufügen eines weiteren Lieferdatums wurde geklickt
     */
    addDeliveryDate() {
        if (this.deliveryDates.length === this.orderFormService.maxDeliveryDates) {
            return;
        }
        // Standardmäßig das aktuelle Datum setzen
        this.deliveryDatesFormArray.push(this.createDateFormControl(new Date()));
        /*
         * this.deliveryDates wird dabei implizit durch den Value-Listener auf dem FormControl aktualisiert
         * und von dort auch andere Komponenten über die Änderung informiert
         */
    }

    /**
     * @param index
     * @brief Button zum Entfernen eines Lieferdatums wurde geklickt
     */
    removeDeliveryDate(index: number) {
        if (index < 1 || index >= this.deliveryDates.length) {
            return;
        }
        this.deliveryDatesFormArray.removeAt(index);
        /*
         * this.deliveryDates wird dabei implizit durch den Value-Listener auf dem FormControl aktualisiert
         * und von dort auch andere Komponenten über die Änderung informiert
         */
    }

    /**
     * @brief Wendet eingetragenen Rabatt auf Auftragspositionen an
     * @param {number} orderDiscount
     */
    applyDiscount(orderDiscount: number) {
        this.orderFormService.applyDiscountChanges(orderDiscount);
    }
}
