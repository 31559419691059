// Angular-Module
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {StorageService} from '@global/services/storage.service';
// Service für das Kennzeichen-Modul
import {CharacteristicsService} from './../characteristics.service';
// Shared Services
import {InputDateService} from './../../input/input-date/input-date.service';
import {ToolbarService} from './../../toolbar/toolbar.service';
// Shared Komponenten
import {PopupConfirmationComponent} from './../../popups/popup-confirmation/popup-confirmation.component';
// Interface für Kennzeichengruppe einbinden
import {Characteristic} from './../../characteristic';
import {CWEvent} from './../../cw-event';
import {CWResult} from './../../cw-result';
import {Listentry} from './../../listentry';
// import { CharacteristicOption } from './../../characteristic-option';
import {environment} from '@environment';

@Component({
    selector: 'phscw-characteristic-group',
    templateUrl: './characteristic-group.component.html',
    styleUrls: ['./characteristic-group.component.scss'],
})
export class CharacteristicGroupComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf Form
    @ViewChild('characteristicGroupForm', {static: false}) characteristicGroupForm: NgForm;

    // ID der gewählten Person/Einrichtung etc.
    _entityId: number;
    // ID der Kennzeichengruppe
    @Input() groupId: number;

    /*
     * Flag: TRUE - aus dem Backend geladen werden sollen;
     *       FALSE - die Daten wurden vom Component übergeben.
     */
    @Input() loadCharacteristicsFromBackend = true;
    // Allgemeine Kennzeichendaten aus dem Storage oder von Component
    @Input() storageCharacteristics: Array<any>;
    // Übergebene Kennzeichen-Daten der Einrichtung
    @Input() characteristicsData: Array<Characteristic>;
    // Kennzeichen-Daten für das Template
    characteristics: Array<Characteristic>;
    // Unveränderte Kennzeichendaten beim Load für das Reseten beim Klicken auf Abbrechen
    originalCharacteristics: Array<Characteristic>;

    // Bearbeitungsmodus
    @Input() editMode = false;
    /*
     * !Momentan ungenutzt; Event das beim Ändern des Edit-Modes/Speichern/Abbrechen aufgerufen wird
     * @Output() changeEditMode = new EventEmitter<boolean>();
     * Flag definiert ob gerade geladen wird
     */
    loading = false;
    // Flag definiert ob gerade gespeichert wird
    saving = false;

    // Fehler
    error = false;

    // Kennzeichen-Typ
    @Input() characteristicType: string;

    // Zeigt an ob, das Panel der Kennzeichengruppe ausgklappt ist => Daten werden nur geladen, wenn true
    _isVisible = false;
    // Anzahl der Kennzeichen die einen Wert oder eine Ausprägung haben
    numberOfSetCharacteristics = 0;

    // Event das beim erfolgreichen Submitten ausgelöst wird und die Anzahl der gesetzten Kennzeichen übergeben soll.
    @Output() submitSuccess = new EventEmitter<number>();
    // Event das beim erfolgreichen Submitten ausgelöst wird und die neuen Icons übergeben soll.
    @Output() newEntityIcons = new EventEmitter<any>();
    // Event das bei Validierung der Form ausgelöst wird.
    @Output() formValid = new EventEmitter<boolean>();

    // Getter EntityID
    get entityId() {
        return this._entityId;
    }

    // Setter EntityId, @Input nötig um es im parent-Element aufrufen zu können
    @Input() set entityId(id: number) {
        this._entityId = id;
        if (this.isVisible) {
            // EditMode zurücksetzen
            this.editMode = false;
            this.onEntityChanged(id);
        }
    }

    @Input() set isVisible(value: boolean) {
        this._isVisible = value;
        if (value) {
            this.onEntityChanged(this.entityId);
        }
    }

    get isVisible() {
        return this._isVisible;
    }

    // Flag definiert ob Kennzeichen, die nicht bearbeitet werden können, auch angezeigt werden sollen
    @Input() showReadonly = true;
    // Flag definiert ob Bestätigungsdialoge angezeigt werden sollen
    @Input() showConfirmationDialog = false;
    // Flag definiert ob Löschbutton angezeigt werden sollen
    @Input() showGlobalDeleteButton = false;
    // Flag definiert ob Checkbox angezeigt werden sollen (zum Löschen in Popup)
    @Input() showCheckbox = false;
    // Flag definiert ob Speicherbuttons angzeigt werden (um bei Kontaktkennzeichen die Buttons auszublenden und per Event das Speichern auszulösen)
    @Input() showSaveButton = true;

    // IDs mehrerer Entitäten (für Mehrfachkennzeichenzuordnung)
    @Input() selectedEntities: any;

    // Currency-Symbol (wird in NgOnInit ggf. über Environment gesetzt / überschrieben)
    currency = '€';

    invalidateTimeout: any = null;
    formInitialized = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param appCoreService
     * @param storageService
     * @param characteristicsService
     * @param inputDateService
     * @param toolbarService
     * @param dialog
     * @param translateService
     */
    constructor(
        private appCoreService: AppCoreService,
        private storageService: StorageService,
        private characteristicsService: CharacteristicsService,
        private inputDateService: InputDateService,
        private toolbarService: ToolbarService,
        private dialog: MatDialog,
        private translateService: TranslateService,
    ) {}

    /**
     * @brief   Initialisierung
     * @details Holt allgemeine Kennzeichendaten für die Kennzeichengruppe
     */
    ngOnInit() {
        // Währung setzen
        if (typeof environment.defaultCurrency !== 'undefined') {
            this.currency = environment.defaultCurrency;
        }

        // Wenn Daten wurden nicht vom Component übergeben, sondern sollen aus dem Storage geladen werden
        this.characteristicsService
            .getUserCharacteristicGroupCharacteristics(this.groupId)
            .then((val) => this.setCharacteristicCallback(val));

        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions() {
        // Wenn der Close-Button der Toolbar ausgelöst wurde, Abbrechen auslösen.
        this.toolbarService.eventCloseComponent
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid (P-Liste) kam
                if (event.target !== 'characteristic-group' + this.groupId) {
                    return;
                }
                this.clickCancel();
            });

        // Daten neu laden nach Änderung
        this.appCoreService.appDataChanged.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls das Event nicht vom eigenen Grid (P-Liste) kam
            if (event.sender !== 'characteristics' && event.target !== 'characteristic-groups-list-popup') {
                return;
            }
            this.onEntityChanged(this.entityId);
        });

        // Daten speichern
        this.characteristicsService.eventSaveCharacteristics
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht von Kontaktkennzeichen kam
                if (event.target !== 'characteristic-group') {
                    return;
                }
                this.onEventSaveCharacteristics(event);
            });
    }

    /**
     * Callback-Funktion
     * @param value
     */
    setCharacteristicCallback(value: any): void {
        this.storageCharacteristics = value;
    }

    /**
     * @param id
     * @brief   Callback dafür, dass sich das Kennzeichen geändert hat.
     */
    onEntityChanged(id: number): void {
        // Die Daten wurden von Component übergeben
        if (this.loadCharacteristicsFromBackend === false && this.characteristicsData) {
            this.characteristics = this.characteristicsService.mergeCharacteristicsData(
                this.storageCharacteristics,
                this.characteristicsData,
            );
            this.originalCharacteristics = Object.values(JSON.parse(JSON.stringify(this.characteristics)));
        } else {
            // Flag setzen
            this.loading = true;

            // Daten werden aus Backend geholt
            this.characteristicsService.loadData(id, this.groupId, this.characteristicType).subscribe(
                // onNext
                (result: CWResult) => {
                    /**
                     * Prüfe, ob die Daten des eintreffenden Requests auch
                     * zur aktuell ausgewählten Einrichtung/Person/etc passen. Durch asynchrone
                     * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                     * bereits die Einrichtung/Person/etc gewechselt wurde und die Antwort
                     * eines Requests verspätet eintrifft und dadurch die
                     * korrekten Daten wieder überschreibt.
                     */
                    if (this.entityId != result['data']['id']) {
                        return;
                    }

                    if (result['success'] === true) {
                        this.characteristics = this.characteristicsService.mergeCharacteristicsData(
                            this.storageCharacteristics,
                            result['data']['characteristics'],
                        );
                        this.originalCharacteristics = Object.values(JSON.parse(JSON.stringify(this.characteristics)));

                        // Kennzeichentyp und Format prüfen
                        this.updateCharacteristicsFormatData();
                    }

                    // Flag setzen
                    this.loading = false;
                },
                // onError
                (error) => {
                    // Flag setzen
                    this.loading = false;
                },
            );
        }
    }

    /**
     * Listentries aus Storage laden
     */
    private updateCharacteristicsFormatData(): void {
        const promise = this.storageService.getItem('listentries|characteristicsFormatType');
        promise.then((val) => this.onGetListentriesFormatTypes(val));
    }

    /**
     * Kennzeichen mit Daten aus Listentries erweitern
     * @param values
     */
    private onGetListentriesFormatTypes(values: any): void {
        this.characteristics.forEach((characteristic, index) => {
            if (
                Object.prototype.hasOwnProperty.call(characteristic, 'format_type') &&
                characteristic.format_type !== null &&
                typeof characteristic.format_type !== 'undefined'
            ) {
                const formatType = values.find((formatType: Listentry) => formatType.list_key === characteristic.format_type);
                if (formatType !== null && typeof formatType !== 'undefined') {
                    this.characteristics[index]['format_data'] = JSON.parse(formatType.list_data);
                }
            }
        });

        // Status der Form beachten

        if (typeof this.characteristicGroupForm !== 'undefined') {
            this.characteristicGroupForm.form.statusChanges
                .pipe(takeUntil(this._componentDestroyed$))
                .subscribe((result: string) => {
                    if (result === 'VALID') {
                        this.formValid.next(true);
                    } else {
                        this.formValid.next(false);
                    }
                });
        }

        // aktuellen Status an übergeordnete Komponenten mitteilen
        if (typeof this.characteristicGroupForm !== 'undefined') {
            if (this.characteristicGroupForm.form.valid) {
                this.formValid.next(true);
            } else {
                this.formValid.next(false);
            }
        }
    }

    /**
     * @param event
     * @brief   Speichern der Kennzeichen auslösen
     */
    onEventSaveCharacteristics(event: CWEvent): void {
        // Entitäts-ID aus Event auslesen
        if (
            Object.prototype.hasOwnProperty.call(event.data, 'characteristicType') &&
            event.data.characteristicType === this.characteristicType &&
            Object.prototype.hasOwnProperty.call(event.data, 'entityId') &&
            event.data.entityId > 0
        ) {
            // Daten vorbereiten zum Speichern
            this.selectedEntities = [{id: event.data.entityId}];
            this.clickSubmit();
        }
    }

    /**
     * @param submitDeletedOnly
     * @brief   Sendet geänderte Daten zum Speichern ans Backend
     */
    clickSubmit(submitDeletedOnly = false): void {
        // Nur gültige Formulare submitten
        if (this.characteristicGroupForm.form.valid) {
            // Auf Klick reagieren
            if (this.showConfirmationDialog && submitDeletedOnly) {
                this.openDeleteDialog();
            } else if (this.showConfirmationDialog) {
                this.openSaveDialog();
            } else {
                this.saveData(this.getEditedCharacteristics());
            }
        }
    }

    /**
     * @brief   Setzt Kennzeichen auf ihren Ursprungsstand zurück. --> Abbrechen-Button
     */
    clickCancel(): void {
        this.editMode = false;

        /*
         * Momentan nicht genutzt. Könnte sich aber wieder ändern
         * this.changeEditMode.emit(this.editMode);
         */

        // Abfrage, ob Kopie-Objekt existiert, um Fehler beim Abbrechen, bevor die Komponente fertig geladen ist, zu vermeiden
        if (!this.isUndefined(this.originalCharacteristics)) {
            // Deep Copy Objects
            this.characteristics = Object.values(JSON.parse(JSON.stringify(this.originalCharacteristics)));
        }
    }

    /**
     * @brief       Dialog zum Speichern öffnen
     * @author      Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private openSaveDialog(): void {
        // geänderte Kennzeichen finden
        const editedCharacteristics = this.getEditedCharacteristics();
        // Bezeichnungen laden
        const characteristicsLabels = this.getCharacteristicsLabels(editedCharacteristics);

        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant(
                    'SHARED.CHARACTERISTICS.CHARACTERISTICGROUPLISTPOPUP.CONFIRMSAVEHEADER',
                ),
                message: this.translateService.instant(
                    'SHARED.CHARACTERISTICS.CHARACTERISTICGROUPLISTPOPUP.CONFIRMSAVETEXT',
                    {
                        characteristics: characteristicsLabels,
                        count: this.selectedEntities.length,
                    },
                ),
            },
        });

        // auf Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result.answer === 'yes') {
                // alle Kennzeichen speichern
                this.saveData(editedCharacteristics);
            }
        });
    }

    /**
     * @brief       Dialog zum Speichern öffnen
     * @author      Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private openDeleteDialog(): void {
        // gelöschte Kennzeichen finden
        const deletedCharacteristics = this.getDeletedCharacteristics();
        // Bezeichnungen laden
        const characteristicsLabels = this.getCharacteristicsLabels(deletedCharacteristics);

        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant(
                    'SHARED.CHARACTERISTICS.CHARACTERISTICGROUPLISTPOPUP.CONFIRMDELETEHEADER',
                ),
                message: this.translateService.instant(
                    'SHARED.CHARACTERISTICS.CHARACTERISTICGROUPLISTPOPUP.CONFIRMDELETETEXT',
                    {
                        characteristics: characteristicsLabels,
                        count: this.selectedEntities.length,
                    },
                ),
            },
        });

        // auf Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result.answer === 'yes') {
                // nur gelöschte Kennzeichen speichern
                this.saveData(deletedCharacteristics, true);
            }
        });
    }

    /**
     * @param changedCharacteristics
     * @param deleted
     * @brief   Sendet geänderte Daten zum Speichern ans Backend
     */
    saveData(changedCharacteristics: any, deleted = false): void {
        // Flag setzen
        this.saving = true;

        // Daten für Übergabe an Backend vorbereiten
        let formData = null;

        // Mehrfachvergabe oder einfache Kennzeichenvergabe
        if (this.selectedEntities !== undefined && this.selectedEntities !== null) {
            formData = {
                characteristics: changedCharacteristics,
                selectedEntities: this.selectedEntities,
                deleteFlag: deleted,
            };
        } else {
            formData = changedCharacteristics;
        }

        // Speichern
        const serviceRequest$ = this.characteristicsService.saveData(this.entityId, formData, this.characteristicType);
        serviceRequest$.subscribe(
            // onNext
            (result: CWResult) => {
                // EditMode verlassen
                this.editMode = false;

                /*
                 * Momentan nicht genutzt. Könnte sich aber wieder ändern
                 * this.changeEditMode.emit(false);
                 */

                // OriginalDaten ersetzen
                this.originalCharacteristics = Object.values(JSON.parse(JSON.stringify(this.characteristics)));
                // Anzahl der gesetzten Kennzeichen zurückliefern
                this.submitSuccess.emit(this.numberOfSetCharacteristics);
                // resetten.
                this.numberOfSetCharacteristics = 0;

                // neue Icons übergeben
                if (result['data'] && Object.prototype.hasOwnProperty.call(result['data'], 'iconString')) {
                    this.characteristicsService.dataChanged(this.characteristicType, {
                        id: this.entityId,
                        iconString: result['data']['iconString'],
                    });
                }

                // Flag setzen
                this.saving = false;

                // Event auslösen um Mehrfachkennzeichen-Popup zu schließen
                this.toolbarService.closeComponent('characteristic-groups-list-popup');
            },
            // onError
            (error) => {
                console.error(error);
                this.error = true;
                this.numberOfSetCharacteristics = 0;

                // Flag setzen
                this.saving = false;
            },
        );
    }

    /**
     * @brief   Finde geänderte Kennzeichen
     */
    private getEditedCharacteristics(): any[] {
        // Init
        const returnFormValues = [];
        const keys = Object.keys(this.characteristicGroupForm.form.controls);

        // Kennzeichen durchlaufen
        for (const key of keys) {
            // Nur geänderte Kennzeichen sollen gespeichert werden (also touched und dirty, touched wird benötigt, da manche Input-Komponenten die Values beim Initialisieren verändern -> dirty)
            if (
                this.characteristicGroupForm.form.controls[key].touched &&
                this.characteristicGroupForm.form.controls[key].dirty
            ) {
                const index = this.characteristics.findIndex((x) => x.id === parseInt(key, 10));
                let value;
                // Datumskennzeichen müssen vorher noch richtig formatiert werden.
                if (
                    this.characteristics[index].value_type === 'date' &&
                    this.characteristicGroupForm.form.controls[key].value !== null
                ) {
                    value = this.inputDateService.getDateValueForSave(
                        this.characteristicGroupForm.form.controls[key].value,
                    );
                } else {
                    value = this.characteristicGroupForm.form.controls[key].value;
                }
                const changedCharacteristic = {
                    id: key,
                    value,
                };
                returnFormValues.push(changedCharacteristic);
            }
            if (!this.isUndefined(this.characteristicGroupForm.form.controls[key].value)) {
                this.numberOfSetCharacteristics++;
            }
        }

        // Daten zurückgeben
        return returnFormValues;
    }

    /**
     * @brief   Finde gelöschte Kennzeichen
     */
    private getDeletedCharacteristics(): any[] {
        // Init
        const returnFormValues = [];
        const keys = Object.keys(this.characteristicGroupForm.form.controls);

        // Kennzeichen durchlaufen
        for (const key of keys) {
            const characteristic = this.characteristics.find((element) => element.id === parseInt(key));
            // Nur geänderte Kennzeichen sollen gespeichert werden (also touched und dirty, touched wird benötigt, da manche Input-Komponenten die Values beim Initialisieren verändern -> dirty)
            if (characteristic !== undefined && characteristic.selected) {
                const index = this.characteristics.findIndex((x) => x.id === parseInt(key, 10));
                let value;

                // Datumskennzeichen müssen vorher noch richtig formatiert werden.
                if (
                    this.characteristics[index].value_type === 'date' &&
                    this.characteristicGroupForm.form.controls[key].value !== null
                ) {
                    value = this.inputDateService.getDateValueForSave(
                        this.characteristicGroupForm.form.controls[key].value,
                    );
                } else {
                    value = this.characteristicGroupForm.form.controls[key].value;
                }

                if (
                    this.isUndefined(this.characteristicGroupForm.form.controls[key].value) ||
                    this.characteristicGroupForm.form.controls[key]['selected'] ||
                    (characteristic !== undefined && characteristic.selected)
                ) {
                    const changedCharacteristic = {
                        id: key,
                        value,
                    };
                    returnFormValues.push(changedCharacteristic);
                }
            }
            if (!this.isUndefined(this.characteristicGroupForm.form.controls[key].value)) {
                this.numberOfSetCharacteristics++;
            }
        }

        // Daten zurückgeben
        return returnFormValues;
    }

    /**
     * @param characteristics
     * @brief   Lade alle Kennzeichenbezeichnungen
     */
    private getCharacteristicsLabels(characteristics: any): string {
        // Init
        let returnValue = '';

        // Kennzeichen durchlaufen
        for (const characteristic of characteristics) {
            // Kennzeichen in Daten finden
            const existing = this.characteristics.find((data) => data.id == characteristic.id); // Typeunsicherer Vergleich, da unterschiedliche Typen
            if (this.isUndefined(existing)) {
                continue;
            }

            // Label aneinanderhängen
            if (returnValue.length > 0) {
                returnValue += ', ' + existing.label;
            } else {
                returnValue = existing.label;
            }
        }

        // Daten zurückgeben
        return returnValue;
    }

    /**
     * @brief   Überprüft, ob Wert gesetzt ist.
     * @param value
     * @param   any   value   Wert der überprüft werden soll.
     * @returns  boolean
     */
    isUndefined(value: any): boolean {
        return (
            typeof value === 'undefined' ||
            value === null ||
            (Object.keys(value).length === 0 && value.constructor === Object)
        );
    }

    /**
     * @brief   Markiert das übergebene Element als "touched"
     * @param controlName
     * @param   any   value   Schlüssel mit dem das Element in der Form gefunden werden kann
     */
    markCharacteristicInputAsTouched(controlName: string | number): void {
        this.characteristicGroupForm.form.controls[controlName].markAsTouched();
    }

    /**
     * Prüfe, ob Kennzeichen mittels Checkbox angewählt wurden.
     */
    checkIfCharacteristicsSelected() {
        for (const entityCharacteristic of this.characteristics) {
            if (entityCharacteristic['selected'] === true) {
                return true;
            }
        }
        return false;
    }

    /**
     * Setzte die Errors für Checkbox-Kennzeichen, weil wir im input-Checkbox-
     * Modul den Validator nicht richtig auf die FormControl binden können.
     * @param invalid
     * @param id
     * @param recursion
     * @todo: Sehr unsauber mit setTimeout(), evtl. nochmal nach besseren Lösungen suchen
     * @todo: Rekursion hat bei bereits gespeicherten Formularen dazu geführt, dass das Formular als INVALID markiert wurde, obwohl sie VALID war.
     */
    invalidateCheckboxes(invalid: boolean, id: number, recursion = false) {
        /*
         * bei bereits gespeicherten Formularen frühzeitig abbrechen, um den
         * Validierungsstatus nicht zu überschreiben durch das setTimeout
         */
        if (this.entityId > 0 && recursion && this.formInitialized) {
            return;
        }

        // Status der Validierung prüfen
        if (invalid === false) {
            // Form auf initialisiert setzen und Fehler zurücksetzen
            this.formInitialized = true;
            this.characteristicGroupForm.form.controls[id].setErrors(null);
        } else {
            const error = {
                validationError: {
                    given: false,
                    message: '',
                },
            };
            if (this.characteristicGroupForm !== undefined) {
                // Form auf initialisiert setzen und Fehler setzen
                this.formInitialized = true;
                this.characteristicGroupForm.form.controls[id].setErrors(error);
            } else {
                // Form war noch nicht initialisiert und daher muss noch einmal geprüft werden
                setTimeout(() => this.invalidateCheckboxes(invalid, id), 200);
            }
        }
    }

    /**
     * @brief Prüft ob Kennzeichen sichtbar sein soll
     * @todo Praktisch nicht mehr ersichtlich was genau passiert -> Komplett überabeiten
     * @param {any} entityCharacteristics
     * @returns {boolean}
     */
    checkVisible(entityCharacteristics) {
        const res =
            (this.showReadonly || (!this.showReadonly && entityCharacteristics.edit_right != 0)) &&
            (this.editMode || !this.isUndefined(entityCharacteristics.value)) &&
            entityCharacteristics.characteristic_type === this.characteristicType &&
            // Kennzeichen ausblenden wenn im EditMode und kein Bearbeitungsrecht vorhanden ist
            (!this.editMode || (this.editMode && entityCharacteristics.edit_right != 0));

        return res;
    }
}
