// Angular-Module
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';
// Eigener Service
import {EntityAffiliationService} from './../entity-affiliation.service';
// Shared Services
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {LooseObject} from '@shared/loose-object';
import {SelectData} from '@shared/select-data';
// Environment
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';
import {AffiliationType, EntityAffiliation} from '@shared/entity-affiliation';

@Component({
    selector: 'phscw-entity-affiliation-form',
    templateUrl: './entity-affiliation-form.component.html',
    styleUrls: ['./entity-affiliation-form.component.scss'],
})
export class EntityAffiliationFormComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ReactiveForm
    entityAffiliationForm: UntypedFormGroup = new UntypedFormGroup({
        current_entity_id: new UntypedFormControl(null, Validators.min(1)),
        relationship_type: new UntypedFormControl(null, Validators.required),
        relationship_direction: new UntypedFormControl(null, Validators.required),
        other_entity_type: new UntypedFormControl(null, Validators.required),
        other_entity: new UntypedFormControl(null, Validators.required),
    });

    // ID der ausgewählten Einrichtung
    @Input() currentEntityId: number;
    @Input() currentEntityType: AffiliationType;

    // ID der übergeordneten Einrichtung
    private _otherEntityId: string = null;
    directions: SelectData[] = [
        {
            id: 0,
            label: 'Überordnen',
            data: 'up',
        },
        {
            id: 1,
            label: 'Unterordnen',
            data: 'down',
        },
        {
            id: 2,
            label: 'Verknüpfen',
            data: 'equal',
        },
    ];

    types: SelectData[] = [
        {
            id: 0,
            label: 'Person',
            data: AffiliationType.Person,
        },
        {
            id: 1,
            label: 'Einrichtung',
            data: AffiliationType.Institution,
        },
        {
            id: 2,
            label: 'Patient',
            data: AffiliationType.Patient,
        },
    ];

    autocompleteLabel: string;
    autocompleteLink: string;

    @Input() set otherEntityId(value: string) {
        this._otherEntityId = value;
        this.inizializeFormControls(this.entityAffiliationForm.get('relationship_type').value);
        this.inizializeFormControls(this.entityAffiliationForm.get('entity_type').value);
        this.inizializeFormControls(this.entityAffiliationForm.get('relationship_direction').value);
    }

    get otherEntityId() {
        return this._otherEntityId;
    }

    // EditMode aktiv?
    @Input() editMode = false;
    @Output() editModeChange = new EventEmitter<boolean>();
    // Flag definiert, ob gerade gespeichert wird
    saving = false;

    // Optionen für InputSelect
    relationshipTypeOptions: SelectData[] = null;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param initService
     * @param storageService
     * @param entityAffiliationService
     * @param toolbarService
     */
    constructor(
        private initService: InitService,
        private storageService: StorageService,
        private entityAffiliationService: EntityAffiliationService,
        private toolbarService: ToolbarService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Zur Auswahl erlaubte Typen laden
        this.getRelationshipTypeOptions();

        // init props
        this.updateAutocompleteProperties(' ');
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Darauf warten, dass alle listentries in der indexedDB gespeichert sind
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            // Abbruch, falls Anfrage erfolglos war
            if (result === false) {
                return;
            }
            this.getRelationshipTypeOptions();
        });

        // Wenn der Cancel-Button der Toolbar ausgelöst wurde, Abbrechen auslösen.
        this.toolbarService.eventCloseComponent.pipe(takeUntil(this._componentDestroyed$)).subscribe((event: CWEvent) => {
            // Abbruch, falls das Event nicht von der eigenen Komponente kam
            if (event.target !== 'entityAffiliation') {
                return;
            }
            this.clickCancel();
        });
    }

    /**
     * Formular initialisieren mit geladenen Daten
     * @param relationshipType
     */
    inizializeFormControls(relationshipType = ' ') {
        // ReactiveForm zurücksetzen
        this.entityAffiliationForm.reset();
        // übergebene Werte in ReactiveForm setzen
        this.entityAffiliationForm.patchValue({
            other_entity_id: this.otherEntityId,
            current_entity_id: this.currentEntityId,
            relationship_type: relationshipType,
        });
    }

    clickSubmit() {
    // Abbrechen, wenn gerade gespeichert wird
        if (this.saving) {
            return;
        }

        // Flag aktivieren
        this.saving = true;

        // Request auslösen
        const data = this.mapFormDataToAffiliation();
        const serviceRequest$ = this.entityAffiliationService.crudCreateData(data);
        serviceRequest$.subscribe({
            next: (result) => {
                // Erfolgreich gespeichert?
                this.editMode = false;
                this.editModeChange.emit(this.editMode);

                // Flag zurücksetzen
                this.saving = false;

                // Daten zurücksetzen
                this.inizializeFormControls();
            },
            error: (error: any) => {
                console.error(error);

                // Flag zurücksetzen
                this.saving = false;
            },
        });
    }

    /**
     * Bearbeiten der Verknüpfung wird abgebrochen
     */
    clickCancel() {
        // Edit-Mode deaktivieren.
        this.editMode = false;
        this.editModeChange.emit(this.editMode);

        // Daten zurücksetzen
        this.inizializeFormControls();
    }

    /**
     * @brief   Optionen aller Selects laden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getRelationshipTypeOptions(): void {
        // Daten über Service anfordern
        const promise = this.storageService.getItem('listentries|entityRelationshipType');
        promise.then((listentries: LooseObject[]) => {
            // Init
            this.relationshipTypeOptions = [{
                id: 0,
                label: '',
                data: '',
            }];

            // Daten prüfen
            if (listentries) {
                // Platzhalter erstellen
                const tmpOption: SelectData = {
                    id: 0,
                    label: '',
                    data: '',
                };
                // Konfiguration laden - networkRelationShipKeys
                let relationshipKeys: string[] = [];
                if (hasOwn(environment, 'networkRelationShipKeys')) {
                    relationshipKeys = environment.networkRelationShipKeys;
                }

                // Werte der Liste (list_key & list_value) in Select-Optionen (id & label) übernehmen
                for (const entry of listentries) {
                    // Listeneintrag überspringen, wenn in der konfigurierten Sperrliste enthalten
                    if (relationshipKeys.length > 0 && relationshipKeys.includes(entry['list_key']) === false) {
                        // continue;
                    }

                    tmpOption.id = entry['list_key'];
                    tmpOption.label = entry['list_value'];
                    // Wenn es list.data gibt diese zu einem JSON-Parsen und ebenfalls speichern.
                    if (entry['list_data'] !== '' && entry['list_data'] !== null) {
                        tmpOption.data = JSON.parse(entry['list_data']);
                    } else {
                        // Wenn der List_data-Eintrag leer ist, muss die data der tmpOption auf null gesetzt werden. Kein JSON.parse um Fehler zu vermeiden.
                        tmpOption.data = null;
                    }

                    // An Liste anhängen
                    this.relationshipTypeOptions.push({...tmpOption});
                }
            }
        });
    }

    mapFormDataToAffiliation(): EntityAffiliation {
        const otherEntity = this.entityAffiliationForm.get('other_entity')?.value;
        const otherType = this.types.find((item) => item.id == this.entityAffiliationForm.get('other_entity_type')?.value).data;
        const direction = this.entityAffiliationForm.get('relationship_direction')?.value;
        const relationshipType = this.entityAffiliationForm.get('relationship_type')?.value;

        const affiliation: EntityAffiliation = {
            relationship_type: relationshipType,
            child_note: '',
            parent_note: '',
        };

        switch (direction) {
            case '0':
                affiliation.child_key = this.currentEntityId;
                affiliation.child_type = this.currentEntityType;
                affiliation.parent_key = otherEntity.id;
                affiliation.parent_type = otherType;
                affiliation.equal_partners = false;
                break;
            case '1':
                affiliation.parent_key = this.currentEntityId;
                affiliation.parent_type = this.currentEntityType;
                affiliation.child_key = otherEntity.id;
                affiliation.child_type = otherType;
                affiliation.equal_partners = false;
                break;
            case '2':
                affiliation.parent_key = this.currentEntityId;
                affiliation.parent_type = this.currentEntityType;
                affiliation.child_key = otherEntity.id;
                affiliation.child_type = otherType;
                affiliation.equal_partners = true;
                break;
            default:
                console.error('unknown direction');
                break;
        }
        return affiliation;
    }

    updateAutocompleteProperties(entityType: string): void {
        switch (entityType) {
            case '0':
                this.autocompleteLabel = 'Person';
                this.autocompleteLink = 'People/searchPeopleForAutoComplete';
                break;
            case '1':
                this.autocompleteLabel = 'Einrichtung';
                this.autocompleteLink = 'Institutions/searchInstitutionsForAutoComplete';
                break;
            case '2': // Patienten sind personen...
                this.autocompleteLabel = 'Person';
                this.autocompleteLink = 'People/searchPeopleForAutoComplete';
                break;
            default:
                this.autocompleteLabel = ' - ';
                this.autocompleteLink = ' ';
        }
    }
}
