// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class InstitutionsListService {
    ignoreHierarchy = false;

    // Konstruktor (inkl. dependency injection)
    constructor(private backendService: BackendService) {}

    /*
     * Layouts über Backend laden
     * @todo: Redundant in P-Liste, E-Liste, VM-Liste --> direkt in GridLayout auslagern?
     */
    getLayoutData(): Observable<unknown> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<unknown> = this.backendService.getRequest('Grid/getLayoutDatasets/institutions');
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    // Einrichtungen löschen
    deleteInstitutions(formData: any): Observable<unknown> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest('Institutions/delete/', formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Favorit setzen
     * @param   {number} institutionId   ID der Person
     * @param   {boolean} isFavorite
     * @returns  {Observable<unknown>}
     */
    toggleFavorite(institutionId: number, isFavorite: boolean): Observable<unknown> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<unknown> = this.backendService.getRequest(
            'Institutions/toggleFavorite/' + institutionId + '/' + isFavorite,
        );
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }
}
