// Angular-Module
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Eigener Service
import {OrderTrackingService} from './../order-tracking.service';
// Service von Shared Modules
import {GridService} from '@shared/grid/grid.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Globale Services einbinden
import {FileDownloadService} from '@global/services/file-download.service';
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
// Interfaces für Structured Objects einbinden
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {SelectData} from '@shared/select-data';
// Environment einbinden
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

// Angular-Material-Module
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';

// Moment-Modul zur Datums-Verarbeitung
import * as _moment from 'moment';

const moment = _moment;

interface LooseObject {
    [key: string]: any;
    order_type?: any;
    order_range?: any;
}
/**
 * @brief   Komponente zum Anzeigen und bearbeiten eines Auftrages
 * @author  Michael Schiffner <m.schiffner@pharmakon.software>
 * @author  Sena Üner <s.uener@pharmakons.software>
 */
@Component({
    selector: 'phscw-order-tracking',
    templateUrl: './order-tracking.component.html',
    styleUrls: ['./order-tracking.component.scss'],
    providers: [{
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE],
    }],
})
export class OrderTrackingComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf Form
    @ViewChild('orderTrackingForm', {static: true}) orderTrackingForm: NgForm;
    // Referenz auf die MatTable zur Darstellung der Produkte
    @ViewChild(MatTable, {static: false}) myTable;

    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;
    // ID des aktuell ausgewählten Auftrags
    orderId: number;
    @Input() set institutionAndOrderId(value) {
        this.orderId = value['orderId'];
        this.institutionId = value['institutionId'];
        if (typeof value !== 'undefined') {
            this.loadDetails();
        }
    }

    // Ausgewählte Aufträge der Einrichtung
    dataItem: LooseObject = {};
    // Kopie zum resetten des dataItems;
    dataItemCopy: LooseObject = {};
    // editMode um in die Auftragserfassung zu wechseln
    @Input() editMode = false;
    // Sind Auftragsart und Sortiment ausgefüllt, wenn nicht zeige einen Error
    showErrorMessage = false;
    // Welche Currency soll angezeigt werden (wird in NgOnInit ggf. über Environment gesetzt / überschrieben)
    _currency = '€';
    set currency(value: string) {
        this._currency = value;
        // Falls sich die Währung ändert, übergebe die Daten an das Data-Item, was ans Backend für den Auftrag übergebn wird
        if (typeof this.dataItem !== 'undefined') {
            this.dataItem['currency'] = value;
        }
    }

    get currency() {
        return this._currency;
    }

    // Event-Emitter der die Übergeordnete Komponente benachrichtigt wenn der DetailsMode verlassen werden soll
    @Output() changeShowOrderDetailsMode = new EventEmitter<boolean>();
    // Zusätzliche Variable, die anzeigt, ob der Abbrechen-Button versteckt werden soll
    @Input() showClose = true;
    // Variable soll verhindern, dass Produkte doppelt gewählt werden. DIRTY FIX. Blame Massimo
    orderProducts: number[] = [];
    // Zusätzlicher Event-Emitter, der die übergeordnete Komponente benachrichtigt, wenn das Formular submitted wird.
    @Output() submitOrder = new EventEmitter<boolean>();
    // Hiermit kann festgelegt werden, ob nur ein Gesamt-Rabatt und Positionsrabatte beide vergeben werden können, oder, ob sie exclusiv sind.
    @Input() exclusiveDiscounts = false;
    // Disabled den Gesamt-Rabatt im html
    orderDiscountDisabled = false;
    // Konfiguration der Felder im Auftragsformular
    datafieldsConfiguration: any;
    // Summe aller Artikel
    articlesSum = 0;
    // Welche Spalten der Backenddaten bei der Artikelsuche angezeigt werden sollen.
    articleSearchColumns = 'label';
    // Hängt die Zahlungsbedingungsauswahl von einem Kennzeichen ab?
    paymentConditionDependsOnCharacteristic = false;
    // Zahlungs-Bedingungs-Optionen aus listentries
    paymentConditionListentries: any[] = [];
    // Auf die Kennzeichen-Ausprägung eingeschränke Zahlungsbedingung
    paymentConditions: SelectData[] = [];

    version = 1;
    // Spaltendefinitionen für Grid Artikeltabelle
    articleGridColumns = [
        //        {columnDef: 'product_id', header: 'Produkt-ID.', cell: (element: any) => `${element.product_id}`,  formatTemplate: 'flush_right'},
        {
            columnDef: 'label',
            header: 'Bezeichnung',
            cell: (element: any) => `${element.label}`,
        },
        {
            columnDef: 'delivery_address',
            header: 'Lieferaddresse',
            cell: (element: any) => `${element.delivery_address}`,
            formatWidth: '80px',
        },
        {
            columnDef: 'order_item_delivery_date',
            header: 'Lieferdatum',
            formatTemplate: 'date',
            cell: (element: any) => `${element.delivery_date}`,
        },
        {
            columnDef: 'package_size',
            header: 'Packungs-Gr.',
            cell: (element: any) => `${element.package_size}`,
        },
        {
            columnDef: 'quantity_unit',
            columnField: 'quantity_unit',
            header: 'VPE',
            cell: (element: any) => `${element.quantity_unit}`,
            listentry: 'productPackageUnit',
            formatTemplate: 'listentries',
        },
        {
            columnDef: 'pzn',
            header: 'PZN',
            cell: (element: any) => `${element.pzn || ''}`,
            formatWidth: '80px',
        },
        {
            columnDef: 'ean',
            header: 'EAN',
            cell: (element: any) => `${element.ean || ''}`,
            formatWidth: '80px',
        },
        {
            columnDef: 'charge',
            header: 'Charge',
            cell: (element: any) => `${element.charge}`,
        },
        {
            columnDef: 'amount',
            header: 'Menge',
            cell: (element: any) => `${element.amount}`,
            formatTemplate: 'flush_right',
            formatWidth: '80px',
        },
        {
            columnDef: 'unit_price',
            header: 'Einzelpreis',
            cell: (element: any) => `${element.unit_price}`,
            formatTemplate: 'currency',
            formatWidth: '80px',
        },
        {
            columnDef: 'item_price',
            header: 'Positionspreis',
            cell: (element: any) => `${element.item_price}`,
            formatTemplate: 'currency',
            formatWidth: '80px',
        },
        {
            columnDef: 'discount',
            header: 'Rabatt',
            cell: (element: any) => `${element.discount}`,
            formatTemplate: 'percent',
            formatWidth: '80px',
        },
        {
            columnDef: 'max_discount_text',
            header: '',
            cell: (element: any) => `${element.max_discount_text}`,
            formatWidth: '5px',
        },
        {
            columnDef: 'total_price',
            header: 'Gesamtpreis',
            cell: (element: any) => `${element.total_price}`,
            formatTemplate: 'currency',
            formatWidth: '80px',
        },
    ];

    readonlyArticleGridDisplayedColumns = ['label', 'amount', 'unit_price', 'discount', 'total_price'];
    articleGridDisplayedColumns = ['label', 'amount', 'unit_price', 'discount', 'total_price', 'deleteColumn'];

    articleData: MatTableDataSource<any>;

    // Flag definiert ob gerade geladen wird
    loading = false;

    // Dürfen neue Produkte zum Auftrag hinzugefügt werden. (Bei manchen Kunden nur im PharmaPilot)
    allowAddNewOrderItems = true;

    // Soll die Logik zum Editieren der Aufträge über die orderStatus-Listentries überschrieben werden?
    allowOverwriteOrderStatusEditable = false;

    // Listentries für orderStatus
    orderStatusListentries: any[] = [];

    /*
     * Darf ein "übertragen"-Button angezeigt werden, der den Order-Status auf einen bestimmten status setzt
     * Konfigurierbar über das Environment
     */
    sendButtonConfig: any = {
        show: true,
        forOrderStatus: [],
    };

    // Soll der "übertragen"-Button auch tatsächlich angezeigt werden?
    showSendButton = false;

    /*
     * Von welchem Status darf automatisch mit welchen Buttons auf ander Status gesetzt werden.
     * Konfigurierbar über das Environment
     */
    sendStatus: any = {
        save: {},
        send: {},
    };

    // Sollen Dokumente in der Auftragserfassung hochgeladen werden können
    allowOrderDocumentUpload = false;

    // Die Spalten die bei den Auftrags-Dokumenten angezeigt werden sollen
    orderDocumentsDisplayedColumns: string[] = [
        'document-date',
        'document-institution',
        'document-name',
        'document-description',
        'document-category',
        'edit-delete',
    ];

    orderDocumentsDisplayGridId = 'orderDocumentsList';

    // ChangeOrderStatusPermission. Wird bei manchen Kunden genutzt, um zu prüfen, ob der Auftrag editierbar ist (abhängig vom Order-Status und Nutzer-Rolle).
    changeOrderStatusPermission = 0;

    /*
     * Falls es mehrere Werte für die changeOrderStatusPermission gibt (weil der Nutzer mehrere Rollen hat)
     * werden dies in dieser Variable gespeichert
     */
    changeOrderStatusMultiPermissions: number[] = [];

    // Custom-Währungs-Format für die Anzeige der Lieferkosten, kann später auch für die restlichen Währungsfelder übernommen werden
    customCurrencyFormat: any = {
        decimal_separator: ',',
        thousands_separator: '.',
        decimal_places: 2,
    };

    // Sollen im Datepicker bestimmte Tage hervorgehoben werden
    highlightDates = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param orderTrackingService
     * @param dialog
     * @param toolbarService
     * @param translateService
     * @param storageService
     * @param userPermissions
     * @param initService
     * @param fileDownloadService
     * @param gridService
     */
    constructor(
        private orderTrackingService: OrderTrackingService,
        private dialog: MatDialog,
        private toolbarService: ToolbarService,
        private translateService: TranslateService,
        private storageService: StorageService,
        private userPermissions: UserPermissionsService,
        private initService: InitService,
        private fileDownloadService: FileDownloadService,
        private gridService: GridService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Konfiguration prüfen
        this.getEnvironmentConfigurations();

        // Wenn die Auswählbaren Zahlungsbed. vom Kennzeichen abhängen
        if (this.paymentConditionDependsOnCharacteristic) {
            // Lade die Zahlungsbedingungs-Listentries separat (nicht automatisch über die input-Komponente)
            this.loadPaymentConditionListentries();
        }

        if (this.allowOverwriteOrderStatusEditable) {
            // Lade Listentries für orderStatus.
            this.loadOrderStatusListentries();
            this.checkAllowChangeOrderStatusPermission();
        }

        // Events subscriben
        this.initializeEventSubscriptions();

        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // ???
        this.toolbarService.eventCloseComponent
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                if (result.target !== 'order-tracking') {
                    return;
                }
                this.clickCancel();
            });

        // Alle Daten beim Login wurden in den Storage geladen
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            if (result && this.allowOverwriteOrderStatusEditable) {
                // Lade Listentries für orderStatus.
                this.loadOrderStatusListentries();
                this.checkAllowChangeOrderStatusPermission();
            }
        });

        // Event "eventGridSelectionChanged" von "gridService"
        this.gridService.eventGridSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid (E-Liste) kam
                if (event.sender !== this.orderDocumentsDisplayGridId) {
                    return;
                }
                this.onEventOrderDocumentsGridSelectionChanged(event);
            });
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeTranslateSubscriptions(): void {
        this.translateService
            .stream([
                'GENERAL.LABEL',
                'SHARED.ORDERTRACKING.PACKAGINGUNIT',
                'SHARED.ORDERTRACKING.QUANTITY',
                'SHARED.ORDERTRACKING.PZN',
                'SHARED.ORDERTRACKING.EAN',
            ])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: any) => {
                this.articleGridColumns.find((column: any) => column.columnDef === 'label').header =
                    translation['GENERAL.LABEL'];
                this.articleGridColumns.find((column: any) => column.columnDef === 'pzn').header =
                    translation['SHARED.ORDERTRACKING.PZN'];
                this.articleGridColumns.find((column: any) => column.columnDef === 'ean').header =
                    translation['SHARED.ORDERTRACKING.EAN'];
                this.articleGridColumns.find((column: any) => column.columnDef === 'quantity_unit').header =
                    translation['SHARED.ORDERTRACKING.PACKAGINGUNIT'];
                this.articleGridColumns.find((column: any) => column.columnDef === 'amount').header =
                    translation['SHARED.ORDERTRACKING.QUANTITY'];
            });
    }

    /**
     * Einzelne Aufträge laden
     */
    loadDetails(): void {
        this.loading = true;
        this.editMode = false;
        this.orderProducts = [];

        const serviceRequest$ = this.orderTrackingService.loadDetails(this.orderId, this.institutionId);
        serviceRequest$.subscribe(
            (result: CWResult) => {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zur aktuell ausgewählten Einrichtung und Auftrag passen. Durch asynchrone
                 * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                 * bereits die Einrichtung oder der Auftrag gewechselt wurde und die Antwort
                 * eines Requests verspätet eintrifft und dadurch die
                 * korrekten Daten wieder überschreibt.
                 */
                if (this.institutionId != result['data']['institution_id'] || this.orderId != result['data']['id']) {
                    return;
                }

                this.dataItem = Object.assign(result['data']);

                if (
                    this.dataItem['employee']['lastname'] != undefined &&
                    this.dataItem['employee']['firstname'] != undefined
                ) {
                    this.dataItem.employee_name =
                        this.dataItem['employee'].lastname + ', ' + this.dataItem['employee'].firstname;
                } else {
                    this.dataItem.employee_name = '';
                }

                this.dataItem.institution_id = this.institutionId;
                this.dataItem['order_discount'] = +this.dataItem['order_discount'];

                this.dataItemCopy = JSON.parse(JSON.stringify(this.dataItem));
                // Wenn die Währung noch nicht gesetzt ist sie dem Auftrag übergenben...
                if (typeof this.dataItem['currency'] === 'undefined' || this.dataItem['currency'] === null) {
                    this.dataItem['currency'] = this.currency;
                } else {
                    // ... sonst die Währung auf die vom Backend übergebene Währung setzten
                    this.currency = this.dataItem['currency'];
                }
                // Anzahl der bestellten Artikel zurücksetzten
                this.articlesSum = 0;
                // this.articleData = this.dataItem['products'];
                this.articleData = new MatTableDataSource(this.dataItem['order_items']);
                // Alle existierenden Produkte in den Prüfungsarray schreiben
                const keys = Object.keys(this.dataItem['order_items']);
                for (const key of keys) {
                    // Zähle die Summe aller Artikel
                    this.articlesSum += this.dataItem['order_items'][key]['amount'];
                    // Schreibe die Produkte in das Prüfungsarray
                    this.orderProducts.push(this.dataItem['order_items'][key]['product_id']);
                    this.setTotalUnitPrice(this.dataItem['order_items'][key], false);
                }
                // Überprüfe ob, der Gesamtrabatt deaktiviert werden soll.
                this.checkIfAllPositionDiscountsAreEmpty();

                // Wenn die Auswählbaren Zahlungsbedingungen von einem Kennzeichen abhängen,...
                if (this.paymentConditionDependsOnCharacteristic) {
                    // ... schränke die Zahlungsbedingungen entspr. ein.
                    this.changePaymentConditionOptions();
                }

                // Delivery-Time in Datum und Ort aufsplitten
                if (typeof this.dataItem['delivery_time'] !== 'undefined') {
                    if (this.dataItem['delivery_time'] === null) {
                        this.dataItem['delivery_time_date'] = null;
                        this.dataItem['delivery_time_time'] = null;
                    } else {
                        /*
                         * Das Lieferzeit-Feld wird in Datum und Zeitpunkt geteilt.
                         * Dies geht nur, weil es momentan ein virtuelles Feld ist,
                         * das nicht gespeichert werden soll. Sobald sich dies
                         * ändert muss die Logik überdacht werden.
                         */
                        this.dataItem['delivery_time_date'] = moment(this.dataItem['delivery_time']).format(
                            'YYYY-MM-DD',
                        );
                        this.dataItem['delivery_time_time'] = moment(this.dataItem['delivery_time']).format('HH:mm');
                    }
                }

                // Prüfe, ob der Auftrag bearbeitet werden darf.
                this.checkOrderEditability();

                // Prüfe, ob der "Übertragen"-Button angezeigt werden darf
                if (
                    this.sendButtonConfig['show'] === true &&
                    this.sendButtonConfig['forOrderStatus'].includes(this.dataItem['order_status'])
                ) {
                    this.showSendButton = true;
                }

                if (typeof this.dataItem['specificDeliveryDays'] !== 'undefined') {
                    this.highlightDates = true;
                }

                // Flag "loading" deaktivieren
                this.loading = false;
            },
            (error) => {
                // Flag "loading" deaktivieren
                this.loading = false;
            },
        );
    }

    /**
     * Neue Zeile in der Artikeltabelle hinzufügen
     */
    addRow(): void {
        // Falls order_type oder order_range noch nicht gesetzt sind erlaube nicht das Hinzufügen neuer Spalten.
        if (typeof this.dataItem.order_type !== 'undefined' && typeof this.dataItem.order_range !== 'undefined') {
            const temp = {
                product_id: 0,
                label: '',
                amount: 1,
                unit_price: 0,
                discount: 0,
                total_price: 0,
            };
            this.articleData.data.push(temp);
            this.showErrorMessage = false;
            this.articlesSum++;
            this.myTable.renderRows();
        } else {
            this.showErrorMessage = true;
        }
    }

    /**
     * Produkt-Informationen für bestimmte Id laden
     * @param infoObject
     */
    loadProductInformation(infoObject: any): void {
        // Check ob order_type und order_range gesetzt sind.
        if (typeof this.dataItem.order_type !== 'undefined' && typeof this.dataItem.order_range !== 'undefined') {
            const dataObject = {
                order_type: this.dataItem.order_type,
                order_range: this.dataItem.order_range,
                institution_id: this.institutionId,
            };

            const serviceRequest$ = this.orderTrackingService.loadProductInformation(infoObject.id, dataObject);
            serviceRequest$.subscribe((result: CWResult) => {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zum aktuell ausgewählten Produkt passen. Durch asynchrone
                 * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                 * bereits das Produkt gewechselt wurde und die Antwort
                 * eines Requests verspätet eintrifft und dadurch die
                 * korrekten Daten wieder überschreibt.
                 */
                if (infoObject.id != result['data']['id']) {
                    return;
                }

                // Finde Heraus von Welcher Tabellen-Spalte das Event kam
                const index = infoObject.optionalInfo;
                // Doppelte Produkte nicht zulassen
                if (!this.orderProducts.includes(result['data']['result'].product_id)) {
                    this.orderProducts.push(result['data']['result'].product_id);
                    // Löschen von doppel-Produktspeicher an Stelle falls das Produkt geändert wird
                    if (
                        typeof this.articleData.data[index].product_id !== 'undefined' &&
                        this.articleData.data[index].product_id !== 0
                    ) {
                        const spliceIndex = this.orderProducts.indexOf(this.articleData.data[index].product_id);
                        this.orderProducts.splice(spliceIndex, 1);
                    }
                    /*
                     * Ordne den Properties des letzten eintrags aus articleData.data die Daten aus dem Backend zu
                     * Kein Object-Assign, da sonst Daten verloren gehen.
                     */
                    this.articleData.data[index].productkey = result['data']['result'].productkey;
                    this.articleData.data[index].product_id = result['data']['result'].product_id;
                    this.articleData.data[index].label = result['data']['result'].label;
                    this.articleData.data[index].pzn = result['data']['result'].pzn;
                    this.articleData.data[index].ean = result['data']['result'].ean;
                    this.articleData.data[index].unit_price = result['data']['result'].unit_price;
                    this.currency = result['data']['result'].currency;
                    this.setTotalUnitPrice(this.articleData.data[index]);
                } else {
                    // Dialog: Doppelter Eintrag öffnen
                    this.openDoubleProductDialog(index);
                }
            });
        }
    }

    /**
     * Zeile in der Artikeltabelle löschen
     * @param i
     */
    deleteRow(i: number): void {
        this.openDeleteDialog(i);
    }

    /**
     * Das eigentliche Löschen der Daten findet hier statt
     * @param result
     * @param i
     */
    handleDeleteDialog(result: any, i: number): void {
        if (result.answer == 'yes') {
            /*
             * Falls der Delete nicht ein doppelter Delete ist das Element aus dem doppel-Produktspeicher entfernen
             * @todo: Ändern, verbessern, I know it's a horrible quickfix. &%"$! this module
             */
            if (
                typeof this.articleData.data[i].product_id !== 'undefined' &&
                this.articleData.data[i].product_id !== 0
            ) {
                const spliceIndex = this.orderProducts.indexOf(this.articleData.data[i].product_id);
                this.orderProducts.splice(spliceIndex, 1);
            }
            this.setTotalUnitPrice(this.articleData.data[i], true);
            this.articlesSum -= this.articleData.data[i]['amount'];
            this.articleData.data.splice(i, 1);
            this.myTable.renderRows();
        }
    }

    /**
     * Lösch-Dialog öffnen
     * @param i
     */
    openDeleteDialog(i: number): void {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('SHARED.ORDERTRACKING.DELETE'),
                message: this.translateService.instant('SHARED.ORDERTRACKING.DELETEQUESTION'),
            },
        });
        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result) => {
            this.handleDeleteDialog(result, i);
        });
    }

    /**
     * Dialog öffnen
     * @param i
     */
    openDoubleProductDialog(i: number): void {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupMessageComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('SHARED.ORDERTRACKING.DUPLICATE'),
                message: this.translateService.instant('SHARED.ORDERTRACKING.DUPLICATENOTICE'),
            },
        });
        // Bei Schließen des Dialogs den Eintrag löschen.
        dialogRef.afterClosed().subscribe((result) => {
            this.handleDeleteDialog({answer: 'yes'}, i);
        });
    }

    /**
     * Klick auf "Speichern" (Form-Submit)
     * @param saveType
     */
    clickSubmit(saveType = 'save'): void {
        if (saveType === 'save') {
            // Wenn der SendStatus entspr. konfiguriert ist und der Auftrag nicht neu ist...
            if (hasOwn(this.sendStatus.save, this.dataItem['order_status']) && this.dataItem['id'] !== 0) {
                // ... setze den Status auf den Konfigurierten Status
                this.dataItem['order_status'] = this.sendStatus.save[this.dataItem['order_status']];
            }
        } else if (saveType === 'send') {
            if (hasOwn(this.sendStatus.send, this.dataItem['order_status'])) {
                this.dataItem['order_status'] = this.sendStatus.send[this.dataItem['order_status']];
            }
        }

        // Nur gültige Formulare werden submitted
        if (this.orderTrackingForm.form.valid) {
            // Submit der Formular-Daten über InstitutionsDataService

            const serviceRequest$ = this.orderTrackingService.saveData(this.orderId, this.dataItem);
            serviceRequest$.subscribe(
                // onNext
                (result: CWResult) => {
                    if (result['success']) {
                        // EditMode verlassen
                        this.editMode = false;
                        // Falls auf das Event-Gehört wird, kann zurück in die Übersicht gewechselt werden.
                        this.changeShowOrderDetailsMode.emit(false);

                        // Submit-Event emitten
                        this.submitOrder.emit(true);
                    }
                },
                // onError
                (error) => {
                    console.error(error);
                },
            );
        }
    }

    /**
     * Klick auf "Abbrechen"
     */
    clickCancel(): void {
        // DataItem zurücksetzen
        this.dataItem = JSON.parse(JSON.stringify(this.dataItemCopy));
        // Reset articleData
        this.articleData = new MatTableDataSource(this.dataItem['order_items']);
        // EditMode verlassen
        this.editMode = false;
        // Falls auf das Event-Gehört wird, kann zurück in die Übersicht gewechselt werden.
        this.changeShowOrderDetailsMode.emit(false);
    }

    /**
     * First Draft: Berechne Gesamtsumme für einzelnen Artikel und
     * rechnet den Zwischen-Preis aus
     * @param element
     * @param deletion
     */
    setTotalUnitPrice(element: any, deletion?: boolean): void {
        element.total_price = +(
            Math.round(100 * (element.unit_price * (1 - element.discount) * element.amount)) / 100
        ).toFixed(2);

        let sum = 0;
        const keys = Object.keys(this.dataItem['order_items']);
        for (const key of keys) {
            // Zähle die Summe aller Artikel
            sum += +(
                Math.round(
                    100 *
                    (this.dataItem['order_items'][key].unit_price *
                    (1 - this.dataItem['order_items'][key].discount) *
                    this.dataItem['order_items'][key].amount),
                ) / 100
            ).toFixed(2);

            /*
             * Schreibe die Produkte in das Prüfungsarray
             * this.orderProducts.push(this.dataItem['order_items'][key]['product_id']);
             */
        }

        this.dataItem.order_sum = sum;
    }

    /**
     * Setze den endgültigen Preis der Bestellung
     */
    setOrderTotal(): void {
        this.dataItem.order_total = +(
            Math.round(100 * (this.dataItem.order_sum * (1 - this.dataItem.order_discount))) / 100
        ).toFixed(2);

        if (this.dataItem['order_items'] == null) {
            return;
        }
        let result = 0;
        for (const orderItem of this.dataItem['order_items']) {
            result += orderItem['amount'] * orderItem['unit_price'];
        }

        this.dataItem.order_total_before_discount = result;

        let percentDifference = ((1 - this.dataItem.order_total / this.dataItem.order_total_before_discount) * 100)
            .toFixed(2)
            .toString();

        if (percentDifference == 'NaN') {
            percentDifference = '0';
        }

        const absoluteDifference =
            (this.dataItem.order_total_before_discount - this.dataItem.order_total).toFixed(2).toString() + ' €';

        this.dataItem.order_discount_text = percentDifference + ' % bzw. ' + absoluteDifference;
    }

    /**
     * Funktion verhindert Artikelrabatte über 100%. Dies wird für die mat-Table
     * gebraucht, da hier die default-form-Validierung nicht funktioniert.
     * @param value
     */
    checkArticleDiscount(value: any): number {
        if (+value > 100) {
            return 1;
        } if (+value <= 0) {
            return 0;
        }
        return +value / 100;
    }

    /**
     * @brief   Funktion überprüft, ob alle Positions-Rabatte 0 sind dann enabled es das Gesamtrabattfeld, sonst wird es disabled
     */
    checkIfAllPositionDiscountsAreEmpty(): void {
        // Nur wenn die Rabatte gegenseitig exklusiv sind
        if (this.exclusiveDiscounts) {
            // Prüf-Variable
            let allZero = true;
            // Über die Auftragsprodukte iterieren
            const keys = Object.keys(this.dataItem['order_items']);
            for (const key of keys) {
                // Prüfen, ob der discount 0 ist.
                if (
                    this.dataItem['order_items'][key]['discount'] != 0 &&
                    this.dataItem['order_items'][key]['discount'] !== null
                ) {
                    allZero = false;
                }
            }

            //  Aktivieren oder deaktivieren des Gesamtrabattfeldes
            if (allZero) {
                this.orderDiscountDisabled = false;
            } else {
                this.orderDiscountDisabled = true;
            }
        }
    }

    /**
     * ???
     * @param element
     * @param value
     */
    setSum(element: any, value: any): void {
        this.articlesSum = this.articlesSum - element.amount + value;
        element.amount = value;
    }

    /**
     * Lade die Zahlungs-Bedingungs-Listeintentries
     */
    loadPaymentConditionListentries(): void {
        // Daten über Service anfordern
        const promise = this.storageService.getItem('listentries|orderPaymentCondition');
        promise.then((val) => {
            this.paymentConditionListentries = val;
        });
    }

    /**
     * Schränke die Zahlungsbedingungen je nach gesetzter Kennzeichenausprägung ein
     */
    changePaymentConditionOptions(): void {
        // Wenn keine Kennzeichen-Ausprägung gesetzt ist => Erlaube alle Zahlungsbedingungen
        if (
            typeof this.dataItem['payment_condition_characteristic_option_id'] === 'undefined' ||
            this.dataItem['payment_condition_characteristic_option_id'] === null
        ) {
            // Mappe die Zahlungsbedingungen in das Select-Data-Format
            this.paymentConditions = this.paymentConditionListentries.map((element) => ({
                id: element.list_key,
                label: element.list_value,
            }));
            return;
        }

        // Filtere die Zahlungsbedingungen nach Kennzeichenausprägung
        const conditions = this.paymentConditionListentries.filter((element) => {
            const listData = JSON.parse(element.list_data);
            // Kein typsicherer Vergleich, da der InstitutionPaymentOption Wert aus irgendeinem Grund ein String ist.
            if (
                typeof listData.InstitutionPaymentOption !== 'undefined' &&
                listData.InstitutionPaymentOption == this.dataItem['payment_condition_characteristic_option_id']
            ) {
                return element;
            }
        });
        // Mappe dann auf das Select-Data-Format
        this.paymentConditions = conditions.map((element) => ({
            id: element.list_key,
            label: element.list_value,
        }));
    }

    /**
     * Lade die orderStatus-Listentries
     */
    loadOrderStatusListentries(): void {
        // Daten über Service anfordern
        const promise = this.storageService.getItem('listentries|orderStatus');
        promise.then((value: any) => {
            this.orderStatusListentries = value;
        });
    }

    /**
     * Prüfe, ob der Auftrag für den User bearbeitbar sein soll
     */
    checkOrderEditability(): void {
        // Überprüfe, ob der Auftrag bearbeitet werden darf.
        if (this.allowOverwriteOrderStatusEditable) {
            // Finde den Listentry für den aktuellen order_status
            const listentry = this.orderStatusListentries.find(
                (value: any) => this.dataItem['order_status'] === value.list_key,
            );

            if (listentry !== undefined && typeof listentry.list_data !== 'undefined' && listentry.list_data !== null) {
                // Wenn listData gesetzt ist decode Sie ...
                const listData = JSON.parse(listentry.list_data);
                // ... und prüfe, ob die editPermission-Felder gesetzt sind
                if (typeof listData.editPermissions !== 'undefined') {
                    /*
                     * ... Wenn ja setzte den editable-Status des Auftrags auf true oder false
                     * Hier wird nun ein Unterschied gemacht, ob der Nutzer mehrere Rollen und Permissions-Werte für den Order-Status hat.
                     */
                    if (
                        this.changeOrderStatusMultiPermissions.length === 0 &&
                        listData.editPermissions.includes(this.changeOrderStatusPermission)
                    ) {
                        this.dataItem['editable'] = true;
                        this.dataItemCopy['editable'] = true;
                    } else if (
                        this.changeOrderStatusMultiPermissions.length > 0 &&
                        this.changeOrderStatusMultiPermissions.some((value) => listData.editPermissions.includes(value))
                    ) {
                        this.dataItem['editable'] = true;
                        this.dataItemCopy['editable'] = true;
                    } else {
                        this.dataItem['editable'] = false;
                        this.dataItemCopy['editable'] = false;
                    }
                }
            }
        }
    }

    /**
     * Berechtigung "allowChangeOrderStatus" laden
     */
    checkAllowChangeOrderStatusPermission(): void {
        const permissionAllowChangeOrderStatus: number =
            this.userPermissions.getPermissionValue('allowChangeOrderStatus');
        this.changeOrderStatusPermission = permissionAllowChangeOrderStatus;

        // Prüfen, ob der User mehrere Werte für die Permission hat.
        const permission: any = this.userPermissions.getPermission('allowChangeOrderStatus');
        if (hasOwn(permission, 'all_values')) {
            this.changeOrderStatusMultiPermissions = permission.all_values;
        }
    }

    /**
     * @brief   Einstellung aus Environment übernehmen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getEnvironmentConfigurations(): void {
        // Währung
        if (typeof environment.defaultCurrency !== 'undefined') {
            this.currency = environment.defaultCurrency;
        }

        if (hasOwn(environment, 'articleSearchColumns')) {
            this.articleSearchColumns = environment['articleSearchColumns'];
        }
        // Einstellung aus Environment übernehmen
        if (hasOwn(environment, 'orderVersion')) {
            this.version = environment['orderVersion'];
        }

        // Hängen die Auswählbaren Zahlungsbedingungen von einem Kennzeichen ab?
        if (hasOwn(environment, 'orderPaymentConditionDependsOnCharacteristic')) {
            this.paymentConditionDependsOnCharacteristic = environment['orderPaymentConditionDependsOnCharacteristic'];
        }

        // Die Konfiguration für das Auftragsformular laden.
        if (hasOwn(environment, 'orderTrackingDatafields')) {
            this.datafieldsConfiguration = environment['orderTrackingDatafields'];
        }

        // Prüfen, ob neue OrderItems hinzugefügt werden dürfen
        if (hasOwn(environment, 'allowAddNewOrderItems')) {
            this.allowAddNewOrderItems = environment['allowAddNewOrderItems'];
        }

        if (hasOwn(environment, 'orderTrackingItemColumns')) {
            this.readonlyArticleGridDisplayedColumns = environment['orderTrackingItemColumns'].defaultMode;
            this.articleGridDisplayedColumns = environment['orderTrackingItemColumns'].editMode;
        }

        if (hasOwn(environment, 'allowOverwriteOrderStatusEditable')) {
            this.allowOverwriteOrderStatusEditable = environment['allowOverwriteOrderStatusEditable'];
        }

        if (hasOwn(environment, 'showSendButton')) {
            this.sendButtonConfig = environment['showSendButton'];
        }

        if (hasOwn(environment, 'sendStatus')) {
            this.sendStatus = environment['sendStatus'];
        }

        if (hasOwn(environment, 'allowOrderDocumentUpload')) {
            this.allowOrderDocumentUpload = environment['allowOrderDocumentUpload'];
        }
    }

    /**
     * @param event
     * @brief   Auf Klick einer Reihe im Grid reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventOrderDocumentsGridSelectionChanged(event: CWEvent): void {
        const documentId: number = event.data.selectedRow.id;
        const documentName: string = event.data.selectedRow.document_name;
        this.downloadFile(documentId, documentName);
    }

    /**
     * @param id
     * @param name
     * @brief   Dokument herunterladen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    downloadFile(id: number, name: string): void {
        // Request auslösen
        const serviceRequest$ = this.orderTrackingService.getDocument(id);
        serviceRequest$.subscribe(
            (result: any) => {
                // Dialog zum Speichern öffnen
                this.fileDownloadService.openSaveDialog(result, name);
            },
            (error: any) => {
                // Error Handling
            },
        );
    }
}
