// Angular-Module
import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'phscw-input-textarea',
    templateUrl: './input-textarea.component.html',
    styleUrls: ['./input-textarea.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputTextareaComponent),
            multi: true,
        },
    ],
})
export class InputTextareaComponent implements OnInit, OnDestroy {
    // EditMode aktiv?
    @Input() editMode = false;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string;
    // Zeilenanzahl für Textarea (2018-12-17, PhS(MFe): Default von 3 auf 10 erhöht)
    @Input() rows = 10;
    // Model "myValue" mit GETTER & SETTER
    _myValue = '';
    @Input() set myValue(value) {
        this._myValue = value;
        this.propagateChange(this._myValue);
    }

    get myValue() {
        return this._myValue;
    }

    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    propagateChange = (_: any) => {};
    // Attribut: required = Pflichtfeld (ja / nein)
    @Input() required = false;
    // Attribut: disabled = Gesperrt (ja / nein)
    @Input() disabled = false;
    // Soll ein Löschbutton angezeigt werden.
    @Input() showDeleteButton = false;
    // In Ansichtsmodus verstecken, wenn leer?
    @Input() hideEmpty = true;
    // Textfarbe - Standard = var(--color-default-dark) in style.scss
    @Input() textColor = '#484848';
    // Maximale Anzahl der Erlaubten Zeichen für das Feld
    @Input() maxlength = 4000;

    /**
     * Konstruktor (inkl. dependency injection)
     */
    constructor() {}

    /**
     * Initialisieren
     */
    ngOnInit() {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * Interface ControlValueAccessor: writeValue
     * @param value
     */
    writeValue(value: any) {
        if (value !== undefined) {
            this.myValue = value;
        }
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}
}
