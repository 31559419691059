// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
 */
.cw-radio-button {
  margin: 1px 0;
}

.cw-radio-label {
  margin: auto 20px auto 0;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.
 */
.cw-radio-group[required]:not(.ng-valid) ::ng-deep .mat-radio-outer-circle {
  border-color: var(--color-accent);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/input/input-radio/input-radio.component.scss"],"names":[],"mappings":"AAAA;;;;;EAAA;AAOA;EACI,aAAA;AAAJ;;AAGA;EACI,wBAAA;AAAJ;;AAGA;EAAA;AAEA;EACI,iCAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n */\n\n.cw-radio-button {\n    margin: 1px 0;\n}\n\n.cw-radio-label {\n    margin: auto 20px auto 0;\n}\n\n/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.\n */\n.cw-radio-group[required]:not(.ng-valid) ::ng-deep .mat-radio-outer-circle {\n    border-color: var(--color-accent);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
