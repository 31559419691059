// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/**
 * Allgemein
 * =========
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
/* Für alle "Icon-Spalten" */
.mat-mdc-cell.cw-grid-column-icon {
  min-width: 40px;
  overflow: visible;
  text-align: center;
  width: 40px;
}

.cw-toolbar-icon {
  margin-left: auto;
}

.cw-grid-column-icon .cw-history-menu {
  display: flex;
  justify-content: flex-end;
}

.cw-grid-column-icon .cw-history-menu ::ng-deep .cw-overlay {
  margin-left: 0;
  margin-right: 0;
  position: relative;
}

.cw-grid-column-icon .cw-history-menu ::ng-deep .cw-overlay.cw-overlay-top:after {
  right: 7px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid/grid-history/grid-history.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;;;EAAA;AAOA,4BAAA;AACA;EACI,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,yBAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;AACJ;;AAEA;EACI,UAAA;AACJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n */\n\n/* Für alle \"Icon-Spalten\" */\n.mat-mdc-cell.cw-grid-column-icon {\n    min-width: 40px;\n    overflow: visible;\n    text-align: center;\n    width: 40px;\n}\n\n.cw-toolbar-icon {\n    margin-left: auto;\n}\n\n.cw-grid-column-icon .cw-history-menu {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.cw-grid-column-icon .cw-history-menu ::ng-deep .cw-overlay {\n    margin-left: 0;\n    margin-right: 0;\n    position: relative;\n}\n\n.cw-grid-column-icon .cw-history-menu ::ng-deep .cw-overlay.cw-overlay-top:after {\n    right: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
