// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class ContactsItemsOverviewService {
    /**
     * Konstruktor
     * @param backendService
     */
    constructor(private backendService: BackendService) {}

    /**
     * @param personId
     * @brief   Stammdaten einer Lieferung laden
     * @returns  Observable<any>
     */
    loadItems(personId: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'ReportsSamplesOverviewItems/details/' + personId,
        );
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }
}
