// Angular-Module
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatCellDef, MatColumnDef, MatHeaderCellDef, MatTable} from '@angular/material/table';
// GridComponent
import {GridComponent} from './../grid.component';

/**
 * @brief   Ausgelagerte Grid-Unterkomponente zur Anzeige von Spezialisierungen von Personen
 * @todo    Evtl. die Listentries auch separat laden und an die input-select-Komponente übergeben (siehe Grid)
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
@Component({
    selector: 'phscw-grid-person-specialization',
    templateUrl: './grid-person-specialization.component.html',
    styleUrls: ['./grid-person-specialization.component.scss', './../grid.component.scss'],
})
export class GridPersonSpecializationComponent implements OnInit, OnDestroy {
    // Referenz auf Spalte
    @ViewChild(MatColumnDef, {static: true}) columnDef: MatColumnDef;
    @ViewChild(MatHeaderCellDef, {static: true}) headerCellDef: MatHeaderCellDef;
    @ViewChild(MatCellDef, {static: true}) cellDef: MatCellDef;

    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;

    // Spalten-Name der auf die Spalte verweist
    private _name: string;
    @Input()
    set name(name: string) {
        this._name = name;
        if (this.columnDef) {
            this.columnDef.name = name;
        }
    }

    get name(): string {
        return this._name;
    }

    column = {
        columnDef: '',
        formatWidth: '200px',
    };

    /**
     * Konstruktor (inkl. dependency injection)
     * @param table
     */
    constructor(public table: MatTable<any>) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        if (this.table) {
            this.column.columnDef = this._name;
            this.columnDef.name = this._name;
            this.columnDef.headerCell = this.headerCellDef;
            this.columnDef.cell = this.cellDef;
            this.table.addColumnDef(this.columnDef);
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        if (this.table) {
            this.table.removeColumnDef(this.columnDef);
        }
    }
}
