// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class InstitutionsPeopleService {
    // Konstruktor (inkl. dependency injection)
    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
    ) {}

    /**
     * @brief   Personen einer Einrichtugn laden
     * @param institutionId
     * @param gridPage
     * @param   number  institutionId   ID der Einrichtung
     * @param   number  gridPage        Zu ladende Seite für Pagination
     * @returns  Observable<any>
     */
    loadData(institutionId: number, gridPage: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'InstitutionsPeople/index/' + institutionId + '/' + gridPage,
        );
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Favorit einer Einrichtugn ändern
     * @param institutionId
     * @param personId
     * @param isFavorite
     * @param   number  institutionId   ID der Einrichtung
     * @param   number  gridPage        Zu ladende Seite für Pagination
     * @returns  Observable<any>
     */
    setFavorite(institutionId: number, personId: number, isFavorite: boolean): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'InstitutionsPeople/updateFavorite/' + institutionId + '/' + personId + '/' + isFavorite,
        );
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }
}
