// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */
:host {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 60px;
}

.cw-global-header-usericon {
  border-radius: 50%;
  height: 40px;
  overflow: hidden;
  width: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-accountinfo/global-accountinfo.component.scss"],"names":[],"mappings":"AAAA;;;;;;EAAA;AAQA;EACI,oCAAA;EACA,eAAA;EACA,WAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;AAAJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n * @author  Massimo Feth <m.feth@pharmakon.software>\n */\n\n:host {\n    background-color: rgba(0, 0, 0, 0.2);\n    cursor: pointer;\n    width: 60px;\n}\n\n.cw-global-header-usericon {\n    border-radius: 50%;\n    height: 40px;\n    overflow: hidden;\n    width: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
