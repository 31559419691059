// Angular-Module
import {Injectable} from '@angular/core';
import {hasOwn} from '@shared/utils';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';

// Definition für einen zusammengesetzten Primärschlüssel
export interface CompositeKey {
    entity_id1: number;
    entity_id2: number;
    entity_key1: string;
    entity_key2: string;
}

@Injectable({providedIn: 'root'})
export class ClearingService {
    // Ausgewählter Clearing-Source
    public selectedSource: number;
    public selectedSourceString: string;
    // Ausgewählter Clearing-Eintrag
    public selectedEntity: any;

    // Subject (Observable) definieren "Es wurde eine andere Einrichtung ausgewählt"
    public selectionChanged = new Subject<number | CompositeKey>();

    /*
     * @brief   Wird aufgerufen, wenn eine neue BackendSource ausgewählt wurde
     *
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    selectSource(selectedSource: number, selectedSourceString: string) {
        // Ausgewählte Source zwischenspeichern
        this.selectedSource = selectedSource;
        this.selectedSourceString = selectedSourceString;
    }

    /*
     * @brief   Wird aufgerufen, falls eine andere Entität ausgewählt werden soll
     *
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    selectEntity(selectedEntity: any) {
        // Ausgewählte Einrichtung zwischenspeichern
        this.selectedEntity = selectedEntity;

        // Ergebnis initialisen danach überschreibven je nachdem welche werte gesetzt sind
        const compositeKey: CompositeKey = {
            entity_id1: null,
            entity_id2: null,
            entity_key1: null,
            entity_key2: null,
        };
        // falls gesetzt rein schreiben
        if (hasOwn(selectedEntity, 'id')) {
            // Eine eindeutige ID
            compositeKey.entity_id1 = this.selectedEntity.id;
        } else if (hasOwn(selectedEntity, 'entity_id1')) {
            compositeKey.entity_id1 = this.selectedEntity.entity_id1;
        }

        // ternary Operator damit das nicht ausartet, falls gesetzt rein schreiben
        compositeKey.entity_id2 = hasOwn(selectedEntity, 'entity_id2') ? this.selectedEntity.entity_id2 : null;
        compositeKey.entity_key1 = hasOwn(selectedEntity, 'entity_key1') ? this.selectedEntity.entity_key1 : null;
        compositeKey.entity_key2 = hasOwn(selectedEntity, 'entity_key2') ? this.selectedEntity.entity_key2 : null;

        // Event auslösen um andere Komponenten des Feature-Moduls zu informieren
        this.selectionChanged.next(compositeKey);
    }

    /*
     * @brief   Wird aufgerufen, falls die Auswahl "geleert" werden soll
     *
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    selectEmptyEntity() {
        this.selectedEntity = null;
        this.selectionChanged.next(-1);
    }
}
