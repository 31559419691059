// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
*/
:host h2 {
  overflow: hidden;
  overflow-wrap: break-word;
}

:host .error {
  color: var(--color-accent);
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-help/global-help-text/global-help-text.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,gBAAA;EACA,yBAAA;AAAJ;;AAGA;EACI,0BAAA;EACA,mBAAA;AAAJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n*/\n\n:host h2 {\n    overflow: hidden;\n    overflow-wrap: break-word;\n}\n\n:host .error {\n    color: var(--color-accent);\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
