/**
 * @brief   Shared SnackbarsModule zur Freigabe der Snackbar-Komponenten.
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */

// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
// Angular-Material
import {MatButtonModule} from '@angular/material/button';
// Komponenten dieses Shared Modules
import {SnackbarDefaultComponent} from './snackbar-default/snackbar-default.component';

@NgModule({
    imports: [CommonModule, MatButtonModule],
    declarations: [SnackbarDefaultComponent],
    // Komponenten freigeben
    exports: [SnackbarDefaultComponent],
})
export class SnackbarsModule {}
