import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {UserDataService} from './user-data.service';
import {InitService} from './init.service';
import {firstValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TitleService {
    constructor(
        private title: Title,
        private userDataService: UserDataService,
        private initService: InitService,
    ) {}

    async setWindowTitle() {
        try {
            // Versuchen, den Titel vom (eingeloggten) Benutzer zu setzen
            const titleSet = await this.setTitleByUser();

            if (!titleSet) {
                // Warte auf die Init, falls der Titel nicht gesetzt wurde
                const initialized = await firstValueFrom(this.initService.allInitialized);
                if (initialized) {
                    await this.setTitleByUser();
                }
            }
        } catch (error) {
            // Titel im Fehlerfall zurücksetzen
            this.resetWindowTitle();
        }
    }

    private async setTitleByUser(): Promise<boolean> {
        try {
            // Lade Benutzerdaten
            const user = await this.userDataService.loadUserData();
            if (user) {
                // Extrahiere benötigte Daten
                // eslint-disable-next-line @stylistic/object-curly-newline
                const {firstname, lastname, username} = user;

                // Titel zusammenbauen
                const title = this.getTitle(firstname, lastname, username);
                this.setTitle(`C-World - ${title}`);
                return true; // Gibt zurück, dass der Titel gesetzt wurde
            }

            this.resetWindowTitle();
            return false; // Gibt zurück, dass der Titel nicht gesetzt wurde
        } catch (error) {
            this.resetWindowTitle();
            return false;
        }
    }

    // Setze den Standardtitel "C-World"
    resetWindowTitle() {
        this.setTitle('C-World');
    }

    // Hilfsfunktion, um den Titel aus den Benutzerdaten zu erstellen
    private getTitle(
        firstname: string | undefined,
        lastname: string | undefined,
        username: string | undefined,
    ): string {
        return firstname && lastname ? `${firstname} ${lastname}` : username || 'User';
    }

    // Setze den Titel im Browser-Fenster
    private setTitle(title: string) {
        this.title.setTitle(title);
    }
}
