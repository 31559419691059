// Angular-Module
import {Injectable} from '@angular/core';
// Angular-Material
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {UserSettingsService} from '@global/services/user-settings.service';
// Services der Feature-Modulen (zu denen ggf. gewechselt werden soll)
import {InstitutionsService} from '@modules/institutions/institutions.service';
import {PeopleService} from '@modules/people/people.service';
// Service dieses Shared-Moduls
import {OverlayService} from './../overlay.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {LooseObject} from '@shared/loose-object';
// Environment einbinden
import {environment} from '@environment';
// Shared Components
import {PopupLoadingComponent} from '@shared/popups/popup-loading/popup-loading.component';

@Injectable({providedIn: 'root'})
export class OverlayInfoService {
    // Referenz auf Loading-Popup
    loadingPopupReference: MatDialogRef<PopupLoadingComponent>;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param loadingDialog
     * @param appCoreService
     * @param userSettingsService
     * @param institutionsService
     * @param peopleService
     * @param overlayService
     */
    constructor(
        private loadingDialog: MatDialog,
        private appCoreService: AppCoreService,
        private userSettingsService: UserSettingsService,
        private institutionsService: InstitutionsService,
        private peopleService: PeopleService,
        private overlayService: OverlayService,
    ) {}

    /**
     * @param data
     * @brief   Adresse zusammenführen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     * @author  Eric Häußel <e.haeusel@pharmakon.software>
     */
    getInstitutionAddress(data: LooseObject): string {
        // Intialisiere
        let returnValue = '';

        if (data.street && data.street.length > 0) {
            // Straße
            returnValue = data.street;

            // Straßennummer
            if (data.street_number_from && data.street_number_from.length > 0) {
                returnValue += ' ' + data.street_number_from;
            }
            if (data.street_number_from && data.street_number_from.length > 0 && data.street_number_to) {
                returnValue += ' -';
            }
            if (data.street_number_to && data.street_number_to.length > 0) {
                returnValue += ' ' + data.street_number_to;
            }

            // Ort
            returnValue += '\r\n';
            returnValue += data.zipcode + ' ' + data.city;

            // Land nur anzeigen, falls es auch in den Stammdaten angezeigt wird
            if (data.country != null && environment.institutionsDatafields['country']['visible']) {
                returnValue += '\r\n';
                returnValue += data.country;
            }
        }

        return returnValue;
    }

    /**
     * @brief   Navigiere zu einer Person
     * @param   target string   'people' oder 'institutions'
     * @param   id number       ID der Person / der Einrichtung
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    preparePeopleNavigation(target: string, id: number): void {
        // Lade-Popup zeigen
        this.loadingPopup(true);

        // USERSETTING: Datenquelle der Ziel-Liste auf "Auswahl" (KEY = 40) setzen
        this.userSettingsService.setValue('peopleListSource', '40');

        // Filter-Funktion ist abhängig vom Ziel
        const serviceRequest$: Observable<any> = this.institutionsService.executeFilterPerson(id);
        // Starte Filterung im Backend
        serviceRequest$.subscribe((result: CWResult) => {
            // Popup schließen (vorbeugend, um doppeltes Auslösen der Navigation zu verhindern)
            this.overlayService.closeCurrentOverlay();
            // Navigation auslösen
            this.executeNavigation('people');
        });
    }

    /**
     * @brief   Navigiere zu einer Einrichtung
     * @param   target string   'people' oder 'institutions'
     * @param   id number       ID der Person / der Einrichtung
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    prepareInstitutionsNavigation(target: string, id: number): void {
        // Lade-Popup zeigen
        this.loadingPopup(true);

        // USERSETTING: Datenquelle der Ziel-Liste auf "Auswahl" (KEY = 40) setzen
        this.userSettingsService.setValue('institutionsListSource', '40');

        // Filter-Funktion ist abhängig vom Ziel
        const serviceRequest$: Observable<any> = this.peopleService.executeFilterInstitution(id, true);
        // Starte Filterung im Backend
        serviceRequest$.subscribe((result: CWResult) => {
            // Popup schließen (vorbeugend, um doppeltes Auslösen der Navigation zu verhindern)
            this.overlayService.closeCurrentOverlay();
            // Navigation auslösen
            this.executeNavigation('institutions');
        });
    }

    /**
     * @brief   Das Loading-Popup wird verwendet um weitere User-Eingaben
     *          zu verhindern, während Daten im Hintergrund vorbereitet werden
     * @param   show boolean    <true> Zeige Popup
     *                          <false> Schließe Popup
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    loadingPopup(show: boolean): void {
        if (show === true) {
            // Loading-Popup öffnen
            this.loadingPopupReference = this.loadingDialog.open(PopupLoadingComponent, {
                width: '250px',
                data: {},
            });
        } else {
            // Loading-Popup schließen
            this.loadingPopupReference.close();
            // Nachdem fertig geladen wurde, kann auch das Overlay-Info-Popup geschlossen werden
            this.overlayService.closeCurrentOverlay();
        }
    }

    /**
     * @brief   Vorbereitungen sind abgeschlossen, Navigation wird durchgeführt
     * @param data
     * @param   target string   'people' oder 'institutions'
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    executeNavigation(target: string, data: LooseObject = {
        listKey: '40',
        selectRowAfterFinished: 'first',
    }): void {
        // Lade-Popup schließen
        this.loadingPopup(false);

        // EVENT: Datenquelle der Ziel-Liste auf "Auswahl" (KEY = 40) setzen
        const eventData: CWEvent = {
            sender: 'overlay-info',
            target: target + 'List',
            data,
        };
        this.appCoreService.changeGridSource.next(eventData);

        // Navigation zur P-Liste ausführen
        this.appCoreService.crossModuleNavigation(target, {});
    }
}
