// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
td {
  width: 400px;
}

/* Soll den Text fürs Overlay-Info unterstreichen */
.overlay-text-decoration {
  text-decoration: underline;
}

.wide-column {
  max-width: 400px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid/grid-sales-analysis-name/grid-sales-analysis-name.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,YAAA;AAEJ;;AACA,mDAAA;AACA;EACI,0BAAA;AAEJ;;AACA;EACI,2BAAA;AAEJ","sourcesContent":["td {\n    width: 400px;\n}\n\n/* Soll den Text fürs Overlay-Info unterstreichen */\n.overlay-text-decoration {\n    text-decoration: underline;\n}\n\n.wide-column {\n    max-width: 400px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
