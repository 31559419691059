// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
// Komponenten
import {EntityIconsComponent} from './entity-icons/entity-icons.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
    ],
    declarations: [EntityIconsComponent],
    exports: [EntityIconsComponent],
})
export class EntityIconsModule {}
