import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// global services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
// interfaces for structured objects
import {CWEvent} from '@shared/cw-event';
import {LooseObject} from '@shared/loose-object';
import {Product} from '@shared/product';

@Injectable({providedIn: 'root'})
export class AdminProductsService {
    // selected products
    public selectedProduct: Product;

    // events
    public selectionChanged = new Subject<number>();
    public eventReloadGridList = new Subject<CWEvent>();

    /**
     * Construktor (incl. dependency injection)
     * @param appCore
     * @param backendService
     */
    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
    ) {}

    /*
     * select new products
     */
    selectProduct(selectedProduct: Product): void {
        this.selectedProduct = selectedProduct;
        this.selectionChanged.next(this.selectedProduct.id);
    }

    /**
     * trigger event to inform other components about change of prodct
     * @param data
     */
    dataChanged(data: Product): void {
        const eventData: CWEvent = {
            sender: 'admin-products-data',
            target: '',
            data: {changedItem: data},
        };
        this.appCore.appDataChanged.next(eventData);
    }

    /**
     * trigger event to reload product list
     */
    reloadGridList(): void {
        const eventData: CWEvent = {
            sender: '',
            target: 'admin-products-list',
        };
        this.eventReloadGridList.next(eventData);
    }

    /**
     * send backend request to load all products for grid
     * @param requestData
     */
    loadListData(requestData: LooseObject): Observable<any> {
        const postRequest$: Observable<any> = this.backendService.postRequest('AdminProductsList/index', requestData);
        return postRequest$;
    }

    /**
     * send backend request to load product data
     * @param id
     */
    loadProductData(id: number): Observable<any> {
        const getRequest$: Observable<any> = this.backendService.getRequest('AdminProductsData/details/' + id);
        return getRequest$;
    }

    /**
     * send backend request to save product data
     * @param id
     * @param formData
     */
    saveProductData(id: number, formData: LooseObject): Observable<any> {
        const postRequest$: Observable<any> = this.backendService.postRequest('AdminProductsData/edit/' + id, formData);
        return postRequest$;
    }

    /**
     * send backend request to load product sample information
     * @param formData
     */
    loadProductSampleInformation(formData: LooseObject): Observable<any> {
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'AdminProductsProductSampleInformation/index/',
            formData,
        );
        return postRequest$;
    }

    /**
     * send backend request to save product sample information
     * @param formData
     */
    saveProductSampleInformationData(formData: LooseObject): Observable<any> {
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'AdminProductsProductSampleInformation/edit/',
            formData,
        );
        return postRequest$;
    }

    /**
     * send backend request to delete product sample information
     * @param formData
     */
    deleteProductSampleInformation(formData: LooseObject): Observable<any> {
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'AdminProductsProductSampleInformation/delete/',
            formData,
        );
        return postRequest$;
    }

    /**
     * send backend request to get all divisions for SelectData
     */
    getDivisionSelectDataforSampleInformation(): Observable<any> {
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'AdminProductsProductSampleInformation/getDivisionsForSelectData/',
        );
        return getRequest$;
    }

    /**
     * send backend request to load product order information
     * @param formData
     */
    loadProductOrderInformation(formData: LooseObject): Observable<any> {
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'AdminProductsProductOrderInformation/index/',
            formData,
        );
        return postRequest$;
    }

    /**
     * send backend request to save product order information
     * @param formData
     */
    saveProductOrderInformationData(formData: LooseObject): Observable<any> {
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'AdminProductsProductOrderInformation/edit/',
            formData,
        );
        return postRequest$;
    }

    /**
     * send backend request to delete product order information
     * @param formData
     */
    deleteProductOrderInformation(formData: LooseObject): Observable<any> {
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'AdminProductsProductOrderInformation/delete/',
            formData,
        );
        return postRequest$;
    }

    /**
     * send backend request to get all divisions for SelectData
     */
    getDivisionSelectDataforOrderInformation(): Observable<any> {
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'AdminProductsProductOrderInformation/getDivisionsForSelectData/',
        );
        return getRequest$;
    }

    /**
     * send backend request to load product batches
     * @param formData
     */
    loadProductBatches(formData: LooseObject): Observable<any> {
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'AdminProductsProductBatches/index/',
            formData,
        );
        return postRequest$;
    }

    /**
     * send backend request to save product batches
     * @param formData
     */
    saveProductBatches(formData: LooseObject): Observable<any> {
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'AdminProductsProductBatches/edit/',
            formData,
        );
        return postRequest$;
    }

    /**
     * send backend request to delete product batches
     * @param formData
     */
    deleteProductBatches(formData: LooseObject): Observable<any> {
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'AdminProductsProductBatches/delete/',
            formData,
        );
        return postRequest$;
    }

    /**
     * send backend request to load product displays
     * @param productId
     */
    loadProductDisplays(productId: number): Observable<any> {
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'AdminProductsProductDisplays/index/' + productId,
        );
        return getRequest$;
    }

    /**
     * send backend request to save product displays
     * @param formData
     */
    saveProductDisplays(formData: LooseObject): Observable<any> {
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'AdminProductsProductDisplays/edit/',
            formData,
        );
        return postRequest$;
    }

    /**
     * send backend request to delete product displays
     * @param formData
     */
    deleteProductDisplays(formData: LooseObject): Observable<any> {
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'AdminProductsProductDisplays/delete/',
            formData,
        );
        return postRequest$;
    }

    /**
     * Lade die Divisionen für die Regionszuordnung
     * @returns  Observable<any>
     */
    recoverTree(): Observable<any> {
        // GET über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('Products/recoverTree');
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * Lade alle Produkte für SelectBox
     * @returns  Observable<any>
     */
    loadProducts(): Observable<any> {
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'AdminProductsProductSampleInformation/getProductsForSelectbox/',
        );
        return getRequest$;
    }
}
