import {Component, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {MatColumnDef, MatHeaderCellDef, MatCellDef, MatTable} from '@angular/material/table';
import {GridComponent} from '../grid.component';
import {AffiliationType, EntityAffiliation} from '@shared/entity-affiliation';
import {Router} from '@angular/router';

@Component({
    selector: 'phscw-grid-entity-name',
    templateUrl: './grid-entity-name.component.html',
    styleUrls: ['./grid-entity-name.component.scss', './../grid.component.scss'],
})
export class GridEntityNameComponent implements OnInit, OnDestroy {
    @Input() gridConnection: GridComponent;

    @Input()
    get name(): string {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
        if (this.columnDef) {
            this.columnDef.name = name;
        }
    }

    _name: string;

    column = {
        columnDef: 'entity-name',
        formatWidth: '200px',
    };

    @ViewChild(MatColumnDef, {static: true}) columnDef: MatColumnDef;
    @ViewChild(MatHeaderCellDef, {static: true}) headerCellDef: MatHeaderCellDef;
    @ViewChild(MatCellDef, {static: true}) cellDef: MatCellDef;

    constructor(private router: Router, public table: MatTable<any>) {}

    ngOnDestroy() {
        if (this.table) {
            this.table.removeColumnDef(this.columnDef);
        }
    }

    ngOnInit() {
        if (this.table) {
            this.columnDef.name = this._name;
            this.columnDef.headerCell = this.headerCellDef;
            this.columnDef.cell = this.cellDef;
            this.table.addColumnDef(this.columnDef);
        }
    }

    /*
     * @brief   Overlay-Info in Dialog anzeigen für Personen oder Einrichtungen
     */
    openOverlayInfoDialog(entityAffiliation: EntityAffiliation): void {
        // Dialog öffnen
        switch (entityAffiliation.display_type) {
            case AffiliationType.Institution:
                this.router.navigate([`/institutions/${entityAffiliation.display_key}`]);
                break;
            case AffiliationType.Person:
                this.router.navigate([`/people/${entityAffiliation.display_key}`]);
                break;
            case AffiliationType.Patient:
                this.router.navigate([`/b2c/${entityAffiliation.display_key}`]);
                break;
            default:
                console.error('backendController unknown');
        }
    }
}
