import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-layout-login',
    templateUrl: './layout-login.component.html',
    styleUrls: ['./layout-login.component.scss'],
})
export class LayoutLoginComponent implements OnInit, OnDestroy {
    constructor() {}

    ngOnInit() {}

    // Aufräumen
    ngOnDestroy() {}
}
