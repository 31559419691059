// Angular-Module
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

// Interface für Dialogdaten
export interface DialogData {
    title: string;
    message: string;
    answer: string;
}

@Component({
    selector: 'phscw-popup-message',
    templateUrl: './popup-message.component.html',
    styleUrls: ['./popup-message.component.scss'],
    //    animations: [slideInTopToDownAnimation]
})
export class PopupMessageComponent implements OnInit, OnDestroy {
    /*
     *    @HostBinding('@routeAnimation') routeAnimation = true;
     *    @HostBinding('style.display') display = 'block';
     *    @HostBinding('style.position') position = 'absolute';
     */

    constructor(
        // private router: Router,
        public dialogRef: MatDialogRef<PopupMessageComponent>,
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit() {}

    // Aufräumen
    ngOnDestroy() {}

    // Button im Dialog wurde angeklickt, Antwort merken und Dialog schließen
    clickAndClose(answer: string) {
        // Antwort übernehmen
        this.data.answer = answer;
        // Dialog (über Referenz) schließen und Dialog-Ergebnis mitsenden
        this.dialogRef.close(this.data);
    }
}
