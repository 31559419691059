// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// Service dieses Shared-Moduls
import {GridService} from './../grid.service';

@Component({
    selector: 'phscw-grid-load-more',
    templateUrl: './grid-load-more.component.html',
    styleUrls: ['./grid-load-more.component.scss'],
})
export class GridLoadMoreComponent implements OnInit, OnDestroy {
    /**
     * *************************************************************************
     * Parameter, welche beim Einbinden der Komponente gesetzt werden
     *************************************************************************
     */
    // ID des (verbundenen) Grids
    @Input() gridId = '';

    /*
     * Dies ist NICHT der interne Grid.gridPageCounter, der innerhalb des Grids
     * selbst verwaltet und hochgezählt wird (lazy loading durch Scrollen).
     *
     * Es handelt sich um eine Ergänzung für das SimpleGrid "Load more..."
     */
    @Input() gridPageCounter = 1;

    /*
     * "Load more..." wird deaktiviert, sobald einmal darauf geklickt wurde.
     * Die verarbeitende Komponente muss "Load more..." dann wieder freigeben.
     */
    @Input() enabled = true;

    /*
     * Falls durch "Load more..." keine Daten mehr geladen werden, wird
     * die Komponente ausgeblendet.
     */
    @Input() visible = true;

    // Enable load all button with same behavior as load more button
    @Input() loadAllButton = false;

    // Konstruktor
    constructor(private gridService: GridService) {}

    // Initialisierungen
    ngOnInit() {}

    // Aufräumen
    ngOnDestroy() {}

    // Klick auf "Lade mehr..."
    clickLoadMore(): void {
        if (this.enabled === true) {
            // Flag setzen
            this.enabled = false;
            // Page-Counter hochzählen
            this.gridPageCounter++;
            // GridService informieren
            this.gridService.pageCounterChanged(this.gridId, this.gridPageCounter);
        }
    }

    // load all entries
    clickLoadAll(): void {
        if (this.enabled === true) {
            // Flag setzen
            this.enabled = false;
            // reset page counter
            this.gridPageCounter = 1;
            // GridService informieren
            this.gridService.loadAllClicked(this.gridId);
        }
    }
}
