import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
// Interface für Kennzeichengruppe einbinden

@Injectable({providedIn: 'root'})
export class InstitutionsTourneemasterService {
    constructor(private backendService: BackendService) {}

    /**
     * @param id
     * @param tourneemasterCharacteristic
     * @param allowedDivisions
     * @brief   Lädt Tourneemaster-Kennzeichen mit Ausprägungen einer Einrichtung
     * @returns  Observable<any>
     */
    loadData(id: number, tourneemasterCharacteristic: number, allowedDivisions?: number[]): Observable<any> {
        let requestData = {};
        // Falls Tourneemaster nur in bestimmten Divisionen erlaubt ist.
        if (typeof allowedDivisions !== 'undefined') {
            requestData = {allowedDivisions};
        }
        // GET-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'InstitutionsCharacteristics/getTourneemasterCharacteristics/' + id + '/' + tourneemasterCharacteristic,
            requestData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
