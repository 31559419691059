// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Service der übergeordneten Komponente
import {ToolbarService} from '../toolbar.service';

@Component({
    selector: 'phscw-toolbar-paste',
    templateUrl: './toolbar-paste.component.html',
    styleUrls: ['./toolbar-paste.component.scss'],
})
export class ToolbarPasteComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;
    // Tooltip
    @Input() tooltip: string = null;
    // Icon
    @Input() icon = 'icon-clipboard';

    // Konstruktor
    constructor(private toolbarService: ToolbarService) {}

    ngOnInit(): void {}

    // Aufräumen
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /*
     * Daten einfügen
     */
    clickPaste() {
        this.toolbarService.pasteItem(this.componentIdentifier);
    }
}
