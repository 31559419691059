/**
 * @brief   Eigene C-World 4 Routing-Strategie / Route-Reuse-Strategy
 * @details Durch diese Route-Reuse-Strategy werden Routen zu Hauptmodulen
 *          beim erneuten Aufruf wiederverwendet und nicht komplett neu
 *          initialisiert.
 *          Damit die Route eines (lazy loaded) Moduls gespeichert werden kann,
 *          muss in '%%%-routing.module.ts' des jeweiligen Moduls ein Key mit
 *          dessen Modulnamen hinterlegt werden.
 *          Beispiel (anhand Datei 'people-routing.module.ts'):
 *          const routes: Routes = [
 *              {
 *                  // Default-Route dieses Moduls (bereits als Child unter dem Kontext "people\")
 *                  path: '',
 *                  component: PeopleComponent,
 *                  data: {key: 'people'},
 *              }
 *          ];
 *          Im Standard werden alle Routen wiederverwendet. Falls eine Route
 *          explizit davon ausgeschlossen werden soll, muss in den Route-Daten
 *          das Flag reuse = <false> gesetzt werden.
 *          Funktionen der RouteReuseStrategy:
 *          - "shouldReuseRoute()" wird immer zuerst aufgerufen
 *          - "shouldDetach()" prüft, ob eine Route gespeichert werden soll
 *          - "store()" speichert eine Route
 *          - "shouldAttach()" prüft, ob eine zuvor gespeicherte Route wiederverwendet werden soll
 *          - "retrieve()" lädt eine zuvor gespeicherte Route
 * @see     https://angular.io/api/router/RouteReuseStrategy
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {ComponentRef} from '@angular/core';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import {hasOwn} from '@shared/utils';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
    // Hier werden alle (von Angular) losgelösten Route-Snapshots abgelegt
    handlers: {[key: string]: DetachedRouteHandle} = {};

    /**
     * @brief   Prüft ob aktueller Route-Snapshot gespeichert werden soll
     * @details Falls die Funktion "shouldReuseRoute" <false> liefert, wird diese
     *          Funktion "shouldDetach" aufgerufen, um festzustellen ob der
     *          aktuelle Route-Snapshot (von Angular) losgelöst und gespeichert
     *          werden soll, damit dieser später wiederverwendet werden kann.
     *          Falls diese Funktion <true> liefert, wird anschließend die
     *          Funktion "store" aufgerufen.
     * @param   route
     */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        // Default <true>, d.h. Route-Snapshot loslösen und speichern
        const defaultShouldReuse = true;

        // Falls die Routen-Konfiguration einen Key "reuse" besitzt, wird dieser zurückgeliefert
        if (route.routeConfig && route.routeConfig.data && hasOwn(route.routeConfig.data, 'reuse')) {
            return route.routeConfig.data['reuse'];
        }

        // Rückgabe
        return defaultShouldReuse;
    }

    /**
     * @brief   Speichert die losgelöste Route
     * @details Achtung! Sobald ein Route-Snapshot von Angular losgelöst wurde,
     *          liegt es in der Verantwortung des Entwicklers den Lifecycle
     *          des Route-Snapshots zu verwalten und etwaige Aufräumarbeiten
     * @param handle
     *          durchzuführen (z.B. richtige Speicherverwaltung).
     * @param   route
     * @param   handler     Ein Handler zum losgelösten Route-Snapshot zum
     *                      Speichern der Route für spätere Verwendung.
     *                      Falls als handler <null> übergeben wird, sollte dies
     *                      den gespeicherten Wert für die Input-Route entfernen.
     */
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        // Key der URL ermitteln
        const urlKey = this.getUrlKey(route);

        // Abbruch, falls kein "urlKey" ermittelt werden konnte
        if (!urlKey) {
            return;
        }

        // Alte Version aus app-routing.module.ts
        if (handle) {
            this.handlers[urlKey] = handle;
        }
    }

    /**
     * @brief   Falls die Funktion "shouldReuseRoute" <false> liefert, wird diese
     *          Funktion "shouldAttach" aufgerufen, um festzustellen ob eine
     *          gespeicherte Route wiederverwendet werden soll.
     * @details Falls diese Funktion <true> liefert, wird anschließend die
     *          Funktion "retrieve" aufgerufen um den gespeicherten Handler der
     *          zuvor losgelösten und gespeicherten Route zu ermitteln.
     *          Die Funktion "shouldAttach" ist ein geeigneter Platz um
     *          gespeicherte Route-Snapshots aufzuräumen.
     *          Zum Beispiel, wenn sich ein Benutzer abgemeldet hat oder der
     *          Snapshot veraltet ist. In diesem Fall sollten wir den Snapshot
     *          nicht verwenden. Diese Funktion sollte dann <false> zurückgeben
     *          und der gespeicherte Handler zum Routen-Snapshot sollte aus dem
     *          Speicher entfernt werden.
     * @param   route
     */
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        // Key der URL ermitteln
        const urlKey = this.getUrlKey(route);

        // Falls man sich auf der Login-Seite befindet...
        if (urlKey == 'Public Views') {
            // Für jeden zwischengespeicherten Route-Handler...
            for (const key in this.handlers) {
                if (Object.prototype.hasOwnProperty.call(this.handlers, key)) {
                    // ...werden die Komponenten zerstört
                    this.deactivateOutlet(this.handlers[key]);
                }
            }
            // Alle zwischengespeicherten Route-Handler löschen
            this.handlers = {};
        }

        // Rückgabe
        return !!urlKey && !!this.handlers[urlKey];
    }

    /**
     * @brief   Ruft die zuvor gespeicherte Route ab
     * @param   route
     */
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        // Key der URL ermitteln
        const urlKey = this.getUrlKey(route);

        // Abbruch, falls kein "urlKey" ermittelt werden konnte
        if (!urlKey) {
            return null;
        }

        // Gespeicherten Handler zurückliefern
        return this.handlers[urlKey];
    }

    /**
     * @brief   Bestimmt, ob eine Route wiederverwendet werden soll
     * @details Falls diese Funktion <true> liefert, wird keine der anderen
     *          Funktionen für diese Route aufgerufen.
     *          Dies geschieht zum Beispiel, wenn wir bereits den aktuellen
     *          Route-Snapshot verwenden.
     *          Etwas verwirrend: Parameter "future" bezieht sich auf die Route,
     *          von welcher man kommt und NICHT die Route, zu der man navigieren
     *          möchte.
     * @param   future      Route, von welcher man KOMMT
     * @param   current     Route, zu welcher man MÖCHTE
     */
    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        // Keys ermitteln
        const futureUrlKey = this.getUrlKey(future);
        const currentUrlKey = this.getUrlKey(current);

        // Prüfe Keys
        if (futureUrlKey === currentUrlKey) {
            // Falls Keys identisch sind, soll Route wiederverwendet werden
            return true;
        }
        // Keys sind nicht identisch, es findet eine neue Navigation statt
        return false;
    }

    /**
     * @brief   Liefert den "Key" aus den Daten einer Route.
     * @details Da das Routing der Hauptmodule in separaten Dateien
     *          '%%%-routing.module.ts' gehandhabt wird und der Pfad der
     *          jeweiligen Root-Komponente innerhalb des Hauptmoduls immer ''
     *          lautet, muss hier der "Key" und nicht der Pfad gespeichert
     *          werden.
     *          Für Child-Module (z.B. Module unterhalb "Reports" oder "Admin")
     *          wird der zurückgelieferte Key aus dem "Key" des Parents, sowie
     *          dem Route-Parameter "submodule" zusammengesetzt.
     *          Beispiele:
     *              "people"
     *              "institutions"
     *              "reports-dailyreport"
     *              "reports-contacts-overview"
     * @param route
     */
    private getUrlKey(route: ActivatedRouteSnapshot): string {
        // Route besitzt keine Routen-Konfiguration
        if (!route.routeConfig) {
            return null;
        }
        // Route besitzt keine Property "data" in Routen-Konfiguration
        if (!route.routeConfig.data) {
            return null;
        }

        // Daten der Route
        const urlData = route.routeConfig.data;
        // Exisitert innerhalb "data" eine Property "key"?
        if (!hasOwn(urlData, 'key')) {
            return null;
        }

        // Key der Route
        let urlKey: string = urlData['key'];

        // Existieren noch weitere Parameter (z.B. Submodule)?
        if (hasOwn(route, 'params')) {
            if (hasOwn(route.params, 'submodule')) {
                urlKey = urlKey + '-' + route.params['submodule'];
            }
        }

        // Rückgabe
        return urlKey;
    }

    /**
     * @brief   Component-View einer gespeicherten Route manuell zerstören
     * @details Wird in Funktion "shouldAttach" für jeden zwischengespeicherten
     *          Route-Handler aufgerufen, insofern man sich (wieder) auf der
     *          Login-Seite befindet.
     *          Dies dient dazu, sicherzustellen, dass auch wirklich alle
     *          Komponenten inklusive EventListener beim Logout zerstört werden.
     * @param   handle
     */
    private deactivateOutlet(handle: DetachedRouteHandle): void {
        const componentRef: ComponentRef<any> = handle['componentRef'];
        if (componentRef) {
            componentRef.destroy();
        }
    }
}
