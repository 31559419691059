// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .cw-error-message {
  color: var(--color-accent);
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-settings-select-deputy/global-settings-select-deputy.component.scss"],"names":[],"mappings":"AAGA;EACI,0BAAA;AAFJ","sourcesContent":[":host {\n}\n\n:host .cw-error-message {\n    color: var(--color-accent);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
