// Angular-Module
import {Component, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Service des übergeordneten Feature-Moduls
import {InstitutionsService} from '../institutions.service';
// Eigener Service
import {InstitutionsTourneemasterService} from './institutions-tourneemaster.service';
// Interface für Kennzeichengruppe einbinden
import {Characteristic} from '@shared/characteristic';
// Environment
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

// Kennzeichentyp setzen
const CHARACTERISTIC_TYPE = 'institution';

/**
 * @brief   Komponente zum Anzeigen und bearbeiten der Tourneemaster für eine Einrichtung
 * @author  Olga Salomatina <o.salomatina@pharmakon.software>
 */
@Component({
    selector: 'phscw-institutions-tourneemaster',
    templateUrl: './institutions-tourneemaster.component.html',
    styleUrls: ['./institutions-tourneemaster.component.scss'],
})
export class InstitutionsTourneemasterComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;
    // Kennzeichen-Typ
    characteristicType: string = CHARACTERISTIC_TYPE;
    // Kennzeichennumer "Tourneemaster"
    tourneemasterCharacteristic: number;

    // "Tourneemaster"-Gruppe ID
    characteristicGroupId: number;
    // "Tourneemaster"-Gruppe Bezeichnung
    characteristicGroupLabel = 'Tourneemaster';
    // Alle Kennzeichen der "Tourneemaster"-Gruppe mit allen Ausprägungen
    characteristicGroupDetails: any;

    // Gesetzte "Tourneemaster"-Kennzeichen der Einrichtung
    characteristicsData: Array<Characteristic>;

    // Das Modul soll nur für bestimmte Divisionen angezeigt werden
    allowedDivisions: number[];
    // Flag, ob das Modul angezeigt werden soll
    showModule = true;

    constructor(
        private institutionsService: InstitutionsService,
        private institutionsTourneemasterService: InstitutionsTourneemasterService,
    ) {}

    ngOnInit() {
        // Überprüfen, ob das Modulangezeigt werden soll.
        if (hasOwn(environment, 'tourneemasterCharacteristic')) {
            this.tourneemasterCharacteristic = environment['tourneemasterCharacteristic'];
        } else {
            this.showModule = false;
            return;
        }

        // Überprüfen, ob das Modul nur eingeschränkt angezeigt werden soll.
        if (hasOwn(environment, 'allowedTourneemasterDivisions')) {
            this.allowedDivisions = environment['allowedTourneemasterDivisions'];
        }

        // Auf geänderte Auswahl reagieren
        this._subscriptions.add(
            this.institutionsService.selectionChanged.subscribe((result) => {
                this.onSelectionChanged(result);
            }),
        );
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    /**
     * @param id
     * @brief  Setzt Einrichtungs-ID bei Änderungen in der Personen-Liste
     */
    onSelectionChanged(id: number): void {
        this.institutionId = id;

        // Variablen zurücksetzen
        this.characteristicGroupLabel = 'Tourneemaster';
        this.characteristicGroupId = 0;
        this.showModule = true;

        const serviceRequest$ = this.institutionsTourneemasterService.loadData(
            this.institutionId,
            this.tourneemasterCharacteristic,
            this.allowedDivisions,
        );
        serviceRequest$.subscribe(
            // onNext
            (result) => {
                /*
                 * Falls die Einrichtung nicht zu den Divisionen gehört, für die das Tourneemaster-Modul angezeigt werden darf => ausblenden
                 * Oder wenn Kennzeichen bei der Einrichtung nicht gepflegt => ausblenden
                 */
                if (result['data'] === 'WrongDivision' || result['data'] === 'WrongCharacteristic') {
                    this.showModule = false;
                    return;
                }

                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zur aktuell ausgewählten Einrichtung passen. Durch asynchrone
                 * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                 * bereits die Einrichtung gewechselt wurde und die Antwort
                 * eines Requests verspätet eintrifft und dadurch die
                 * korrekten Daten wieder überschreibt.
                 */
                if (result['data'] === null || this.institutionId != result['data']['id']) {
                    return;
                }

                if (result['success'] === true) {
                    this.characteristicsData = result['data']['characteristics'];
                    this.characteristicGroupId = result['data']['groupId'];
                    this.characteristicGroupLabel = 'Tourneemaster';
                    this.characteristicGroupDetails = result['data']['groupDetails'];
                    this.showModule = true;
                }
            },
            // onError
            (error) => {
                // Keine Antwort vom Backend erhalten (Servererror)
                console.error(error);
            },
        );
    }

    /**
     * @param value
     * @brief   Überprüft ein Wert undefined ist. Wird zum Abfangen von Fehlern bei leeren Kennzeichengruppen gebraucht.
     */
    isUndefined(value: any): boolean {
        return (
            typeof value === 'undefined' ||
            value === null ||
            (Object.keys(value).length === 0 && value.constructor === Object)
        );
    }
}
