// Interfaces für Structured Objects einbinden
import {FormInput} from '@shared/form-input';
import {MOMENT_DB_FORMAT} from '@shared/input/input-date/input-date.component';
// Moment.js
import * as _moment from 'moment';

const moment = _moment;

/*
 * Formular für den erweiterten Filter in Musterübersicht
 */
export class InstitutionsInvoicesFilterForm {
    // Definition der Felder
    inputFields: FormInput[] = [
        {
            field_type: 'multiselect',
            field_id: 'productGroup',
            field_label: 'Produktgruppe',
            field_required: false,
            field_disabled: false,
            field_select_data: [],
            field_value: [],
        },
        {
            field_type: 'date',
            field_id: 'startDate',
            field_label: 'Start Datum',
            field_required: false,
            field_disabled: false,
            field_max: moment().format(),
            field_select_data: [],
            field_value: moment().subtract(12, 'months').format('YYYY-MM-DD'),
        },
        {
            field_type: 'date',
            field_id: 'endDate',
            field_label: 'End Datum',
            field_required: false,
            field_disabled: false,
            field_max: moment().format(),
            field_select_data: [],
            field_value: moment().format('YYYY-MM-DD'),
        },
    ];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param storedFilterSetting
     */
    constructor(storedFilterSetting: string | object = {}) {
        let storedFilter;
        if (typeof storedFilterSetting === 'string') {
            storedFilter = JSON.parse(storedFilterSetting);
        } else {
            storedFilter = storedFilterSetting;
        }

        for (const property in storedFilter) {
            if (Object.prototype.hasOwnProperty.call(storedFilter, property)) {
                const inputField = this.inputFields.find((element) => element.field_id === property);
                if (inputField !== undefined) {
                    // Jahr in moment-Objekt umwandeln
                    if (inputField.field_type === 'date') {
                        inputField.field_value = moment.utc(storedFilter[property], MOMENT_DB_FORMAT);
                    } else if (inputField.field_type === 'multiselect') {
                        inputField.field_value = storedFilter[property];
                        inputField.field_value_label = storedFilter[property + '_value_label'];
                    } else {
                        inputField.field_value = storedFilter[property];
                    }
                }
            }
        }
    }
}
