// Angular-Module
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../cw-event';
import {FilterData} from './../filter-data';

@Injectable({providedIn: 'root'})
export class GridService {
    // Subject (Observable) definieren "Es wurde eine Datenzeile im Grid ausgewählt"
    public eventGridSelectionChanged = new Subject<CWEvent>();
    // Subject (Observable) definieren "Es soll die erste Datenzeile im Grid ausgewählt werden"
    public eventGridSelectFirstRow = new Subject<CWEvent>();
    // Subject (Observable) definieren "Leere Auswahl" (um z.B. nach erfolgloser Suche den Detailbereich zu "leeren", da sonst die zuvor ausgewählten Daten bestehen bleiben)
    public eventGridEmptySelection = new Subject<CWEvent>();
    // Eine NEUE Datenzeile auswählen (id = 0)
    public eventGridSelectNewRow = new Subject<CWEvent>();
    // Subject (Observable) definieren "Datenquelle geändert"
    public eventGridSourceChanged = new Subject<CWEvent>();
    // Subject (Observable) definieren "Layout geändert"
    public eventGridLayoutChanged = new Subject<CWEvent>();
    // Subject (Observable) definieren "Spalten geändert"
    public eventGridColumnsChanged = new Subject<CWEvent>();
    // Subject (Observable) definieren "Ändere Layout"
    public eventGridChangeLayout = new Subject<CWEvent>();

    // Sortierung wurde geändert
    public eventGridSortChanged = new Subject<CWEvent>();
    // Filterung wurde geändert
    public eventGridFilterChanged = new Subject<CWEvent<FilterData>>();
    // Spaltenauswahl wurde geändert
    public eventGridColumnChoiceChanged = new Subject<CWEvent>();
    // Page-Counter wurde geändert
    public eventGridPageCounterChanged = new Subject<CWEvent>();
    // Alle Seiten sollen geladen werden
    public eventGridLoadAllClicked = new Subject<CWEvent>();
    // Confirm-Icon in Grid wurde angeklickt
    public eventGridRowConfirmClicked = new Subject<CWEvent>();
    // Edit-Icon in Grid wurde angeklickt
    public eventGridRowEditClicked = new Subject<CWEvent>();
    // Delete-Icon in Grid wurde angeklickt
    public eventGridRowDeleteClicked = new Subject<CWEvent>();
    // Up-Icon in Grid wurde angeklickt
    public eventGridRowUpClicked = new Subject<CWEvent>();
    // Down-Icon in Grid wurde angeklickt
    public eventGridRowDownClicked = new Subject<CWEvent>();
    // Checkboxen wurden angeklickt
    public eventGridCheckboxClicked = new Subject<CWEvent>();
    // Mit Checkbox in der Grid ausgewählte Einträge werden abgehakt
    public eventUnsetGridCheckboxes = new Subject<CWEvent>();
    // Radio Button wurde angeklickt
    public eventGridRadioClicked = new Subject<CWEvent>();
    // Grid Daten werden neu geladen
    public eventReloadGridData = new Subject<CWEvent>();
    // Daten müssen neu geladen werden
    public eventReloadData = new Subject<CWEvent>();
    // Ändere das Layout nachdem die Daten neu geladen wurden
    public eventGridChangeLayoutAfterReload = new Subject<CWEvent>();
    // Lösche eine bestimmte Selektion über GridSource
    public eventGridSelectionDeleted = new Subject<CWEvent>();
    // Lösche das aktuelle gewählte Layout über GridLayout
    public eventGridLayoutDeleted = new Subject<CWEvent>();
    // Filter muss zurückgesetzt werden
    public eventResetGridFilter = new Subject<CWEvent>();
    // Sortierung muss zurückgesetzt werden
    public eventResetGridSort = new Subject<CWEvent>();

    // Versionierungs-Icon wurde angeklickt
    public eventGridHistoryIconClicked = new Subject<CWEvent>();
    // Versionsdaten wurden geladen
    public eventGridHistoryDataInitialized = new Subject<CWEvent>();
    // Versionsdaten wurden geladen
    public eventGridHistoryMenuItemClicked = new Subject<CWEvent>();
    // Favoriten-Icon wurde angeklickt
    public eventGridFavoriteIconClicked = new Subject<CWEvent>();
    // Favorit wurde erfolgreich geändert
    public eventGridFavoriteChanged = new Subject<CWEvent>();
    // Kontakt-Icon wurde angeklickt
    public eventGridContactIconClicked = new Subject<CWEvent>();
    // Institution-Icon wurde angeklickt
    public eventGridInstitutionIconClicked = new Subject<CWEvent>();
    // Person-Icon wurde angeklickt
    public eventGridPersonIconClicked = new Subject<CWEvent>();
    // Veranstaltung-Icon wurde angeklickt
    public eventGridEventIconClicked = new Subject<CWEvent>();

    // Icon wurde angeklickt
    public eventGridIconClicked = new Subject<CWEvent>();
    // Kontakt wird angelegt
    public eventQuickManageContact = new Subject<CWEvent>();
    // Tagesbericht herunterladen
    public eventGridDailyreportFileClicked = new Subject<CWEvent>();
    // Tagesbericht freigeben
    public eventGridDailyreportApproveClicked = new Subject<CWEvent>();
    // Tagesbericht ablehnen
    public eventGridDailyreportDeclineClicked = new Subject<CWEvent>();

    // Aufklapp-Button für ein Element wurde angeklickt (gilt nicht für Einrichtungen)
    public loadElementChildren = new Subject<CWEvent>();
    // Kind-Elemente wurden geladen
    public childrenElementLoaded = new Subject<CWEvent>();

    // Spalten-Header wurde angeklickt
    public eventColumnHeaderClicked = new Subject<CWEvent>();
    // Spalten-Header-Sortierung wurde (de)aktiviert
    public eventHeaderSortStateChanged = new Subject<CWEvent>();
    public eventFullscreenToggle = new Subject<CWEvent>();
    // Löst die change detection im Grid aus
    public eventTriggerChangeDetection = new Subject<void>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param appCore
     * @param backendService
     * @param router
     */
    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
        private router: Router,
    ) {}

    /**
     * Daten im Grid wurden verändert, löst die change detection im Grid aus
     */
    triggerGridChangeDetection() {
        this.eventTriggerChangeDetection.next();
    }

    /**
     * Wird von GridComponent aufgerufen, sobald eine Datenzeile selektiert wurde
     * @param gridId
     * @param row
     */
    selectionChanged(gridId: string, row: any) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {selectedRow: row},
        };
        // Event auslösen
        this.eventGridSelectionChanged.next(eventData);
    }

    /**
     * Kann von außen aufgerufen werden, damit die erste Datenzeile im Grid ausgewählt wird (z.B. nach durchgeführter Suche)
     * @param gridId
     */
    selectFirstRow(gridId: string) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
        };
        // Event auslösen
        this.eventGridSelectFirstRow.next(eventData);
    }

    /**
     * Kann von außen aufgerufen werden, damit eine "Leere Auswahl" (id = -1) erfolgt, um z.B. den Detailbereich zu "leeren"
     * @param gridId
     */
    emptySelection(gridId: string) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
        };
        // Event auslösen
        this.eventGridEmptySelection.next(eventData);
    }

    /**
     * Eine NEUE Datenzeile auswählen (id = 0)
     * @param gridId
     * @param newItem
     */
    selectNewRow(gridId: string, newItem: any) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {newItem},
        };
        // Event auslösen
        this.eventGridSelectNewRow.next(eventData);
    }

    /**
     * Datenquelle im Grid geändert, Event auslösen
     * @param gridId
     * @param sourceId
     */
    sourceChanged(gridId: string, sourceId: number) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'gridService',
            target: gridId,
            data: {selectedId: sourceId},
        };
        // Event auslösen
        this.eventGridSourceChanged.next(eventData);
    }

    /**
     * Layout des Grids geändert, Event auslösen
     * @param gridId
     * @param layoutId
     */
    layoutChanged(gridId: string, layoutId: number) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'gridService',
            target: gridId,
            data: {selectedId: layoutId},
        };
        // Event auslösen
        this.eventGridLayoutChanged.next(eventData);
    }

    /**
     * Layout des Grids geändert, Event auslösen
     * @param gridId
     * @param frontendArray
     * @param allowDeletion
     */
    columnsChanged(gridId: string, frontendArray: any, allowDeletion = false) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'gridService',
            target: gridId,
            data: {
                frontendArray,
                allowDeletion,
            },
        };
        // Event auslösen
        this.eventGridColumnsChanged.next(eventData);
    }

    /**
     * Layout des Grids geändert, Event auslösen
     * @param gridId
     * @param layoutId
     */
    changeLayout(gridId: string, layoutId: number) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'gridService',
            target: gridId,
            data: {layoutId},
        };
        // Event auslösen
        this.eventGridChangeLayout.next(eventData);
    }

    /**
     * Sortierung des Grids geändert, Event auslösen
     * @param gridId
     * @param sortfield
     */
    sortChanged(gridId: string, sortfield: FilterData) {
        const eventData: CWEvent<FilterData> = {
            sender: 'gridService',
            target: gridId,
            data: sortfield,
        };
        this.eventGridSortChanged.next(eventData);
    }

    /**
     * Filterung des Grids geändert, Event auslösen
     * @param gridId
     * @param filterfield
     */
    filterChanged(gridId: string, filterfield: FilterData) {
        const eventData: CWEvent = {
            sender: 'gridService',
            target: gridId,
            data: filterfield,
        };
        this.eventGridFilterChanged.next(eventData);
    }

    /**
     * Spaltenauswahl des Grid geändert, Event auslösen
     * @param gridId
     * @param chosenColumns
     */
    columnChoiceChanged(gridId: string, chosenColumns: any) {
        const eventData: CWEvent = {
            sender: 'gridService',
            target: gridId,
            data: chosenColumns,
        };
        this.eventGridColumnChoiceChanged.next(eventData);
    }

    /**
     * Page-Counter hat sich geändert, Event auslösen
     * @param gridId
     * @param gridPageCounter
     */
    pageCounterChanged(gridId: string, gridPageCounter: number) {
        const eventData: CWEvent = {
            sender: 'gridService',
            target: gridId,
            data: {gridPageCounter},
        };
        this.eventGridPageCounterChanged.next(eventData);
    }

    /**
     * Lade alle Seiten der Liste
     * @param gridId
     */
    loadAllClicked(gridId: string) {
        const eventData: CWEvent = {
            sender: 'gridService',
            target: gridId,
            data: null,
        };
        this.eventGridLoadAllClicked.next(eventData);
    }

    /**
     * Die Sortierung nach Spalten-Header wurde an- oder ausgeschaltet
     * @param gridId
     * @param headerSortActive
     */
    headerSortStateChanged(gridId: string, headerSortActive: boolean) {
        const eventData: CWEvent = {
            sender: 'gridService',
            target: gridId,
            data: headerSortActive,
        };
        this.eventHeaderSortStateChanged.next(eventData);
    }

    /**
     * Wird von GridComponent aufgerufen um Daten (nach) zu laden
     * @param gridDataSource
     * @param gridOptions
     */
    loadData(gridDataSource: string, gridOptions: any): Observable<unknown> {
        // Request-URL zusammen bauen
        const requestURL: string = gridDataSource;

        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest(requestURL, gridOptions);
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    /*
     *
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    loadDataDocument(gridDataSource: string, gridOptions: any): Observable<Blob> {
        const requestURL: string = gridDataSource;
        // GET-Request über BackendService senden
        const getRequest: Observable<Blob> = this.backendService.getFilePost(requestURL, gridOptions);
        // Observable (an Komponente) zurücklieferen
        return getRequest;
    }

    /**
     * Versionierungs-Icon wurde angeklickt
     * @param gridId
     * @param id
     */
    historyIconClicked(gridId: string, id: number) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {id},
        };
        // Event auslösen
        this.eventGridHistoryIconClicked.next(eventData);
    }

    /**
     * Daten für Versionierung wurden geladen
     * @param sender
     * @param target
     * @param data
     */
    historyDataInitialized(sender: string, target: string, data: any) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target,
            data,
        };
        // Event auslösen
        this.eventGridHistoryDataInitialized.next(eventData);
    }

    /**
     * Versionierung Menü-Item wurde geklickt
     * @param gridId
     * @param name
     */
    historyMenuItemClicked(gridId: string, name: string) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {name},
        };
        // Event auslösen
        this.eventGridHistoryMenuItemClicked.next(eventData);
    }

    /**
     * Favoriten-Icon wurde angeklickt
     * @param gridId
     * @param id
     * @param previousValue
     */
    favoriteIconClicked(gridId: string, id: number, previousValue: boolean) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {
                id,
                newFavoriteValue: !previousValue,
            },
        };
        // Event auslösen
        this.eventGridFavoriteIconClicked.next(eventData);
    }

    /**
     * Favoriten-Icon wurde angeklickt
     * @param gridId
     * @param id
     * @param isFavorite
     */
    toggleFavoriteIcon(gridId: string, id: number, isFavorite: boolean) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {
                id,
                isFavorite,
            },
        };
        // Event auslösen
        this.eventGridFavoriteChanged.next(eventData);
    }

    /**
     * Kontakt-Icon wurde angeklickt
     * @param gridId
     * @param id
     * @param sampleAuthorization
     */
    contactIconClicked(gridId: string, id: number, sampleAuthorization = false) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {
                id,
                sampleAuthorization,
            },
        };
        // Event auslösen
        this.eventGridContactIconClicked.next(eventData);
    }

    /**
     * Einrichtungs-Icon wurde angeklickt
     * @param gridId
     * @param id
     */
    institutionIconClicked(gridId: string, id: number) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {id},
        };
        // Event auslösen
        this.eventGridInstitutionIconClicked.next(eventData);
    }

    /**
     * Personen-Icon wurde angeklickt
     * @param gridId
     * @param id
     */
    personIconClicked(gridId: string, id: number) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {id},
        };
        // Event auslösen
        this.eventGridPersonIconClicked.next(eventData);
    }

    /**
     * Veranstaltungs-Icon wurde angeklickt
     * @param gridId
     * @param id
     */
    eventIconClicked(gridId: string, id: number) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {id},
        };
        // Event auslösen
        this.eventGridEventIconClicked.next(eventData);
    }

    /**
     * icon wurde geklickt
     * @param gridId
     * @param id
     */
    iconClicked(gridId: string, id: number) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {id},
        };
        // Event auslösen
        this.eventGridIconClicked.next(eventData);
    }

    /**
     * Confirm-Icon wurde geklickt
     * @param gridId
     * @param row
     */
    rowConfirmClicked(gridId: string, row: any) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {selectedRow: row},
        };
        // Event auslösen
        this.eventGridRowConfirmClicked.next(eventData);
    }

    /**
     * Edit-Icon wurde geklickt
     * @param gridId
     * @param row
     */
    rowEditClicked(gridId: string, row: any) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {selectedRow: row},
        };
        // Event auslösen
        this.eventGridRowEditClicked.next(eventData);
    }

    /**
     * Delete-Icon wurde geklickt
     * @param gridId
     * @param row
     */
    rowDeleteClicked(gridId: string, row: any) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {selectedRow: row},
        };
        // Event auslösen
        this.eventGridRowDeleteClicked.next(eventData);
    }

    /**
     * Up-Icon wurde geklickt
     * @param gridId
     * @param row
     */
    rowUpClicked(gridId: string, row: any) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {selectedRow: row},
        };
        // Event auslösen
        this.eventGridRowUpClicked.next(eventData);
    }

    /**
     * Down-Icon wurde geklickt
     * @param gridId
     * @param row
     */
    rowDownClicked(gridId: string, row: any) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {selectedRow: row},
        };
        // Event auslösen
        this.eventGridRowDownClicked.next(eventData);
    }

    /**
     * Checkboxen wurden angeklickt
     * @param gridId
     * @param selectionData
     * @param clickedRow
     */
    checkboxClicked(gridId: string, selectionData: any, clickedRow: any = null) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {
                selection: selectionData,
                clickedRow,
            },
        };
        // Event auslösen
        this.eventGridCheckboxClicked.next(eventData);
    }

    /**
     * Mit Checkbox in der Grid ausgewählte Einträge werden abgehakt
     * @param gridId
     */
    checkboxUncheckAll(gridId: string) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {},
        };
        // Event auslösen
        this.eventUnsetGridCheckboxes.next(eventData);
    }

    /**
     * Radio Button wurde angeklickt
     * @param gridId
     * @param selectionData
     */
    radioClicked(gridId: string, selectionData: any) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {selection: selectionData},
        };
        // Event auslösen
        this.eventGridRadioClicked.next(eventData);
    }

    /**
     * Grid Daten werden neu geladen
     * @param gridId
     */
    reloadGridData(gridId: string) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {},
        };
        // Event auslösen
        this.eventReloadGridData.next(eventData);
    }

    /**
     * Kontakt wird verwaltet
     * @param gridId
     * @param row
     * @param contactId
     * @param day
     */
    quickManageContactClicked(gridId: string, row: any, contactId: number, day: number) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {
                row,
                contactId,
                day,
            },
        };

        // Event auslösen
        this.eventQuickManageContact.next(eventData);
    }

    /**
     * Tagesbericht herunterladen
     * @param gridId
     * @param employeeId
     */
    dailyreportDownloadClicked(gridId: string, employeeId: number): void {
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {employeeId},
        };
        this.eventGridDailyreportFileClicked.next(eventData);
    }

    /**
     * Tagesbericht freigeben
     * @param gridId
     * @param employeeId
     * @param state
     */
    dailyreportApproveClicked(gridId: string, employeeId: number, state): void {
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {
                employeeId,
                state,
            },
        };
        this.eventGridDailyreportApproveClicked.next(eventData);
    }

    /**
     * Tagesbericht ablehnen
     * @param gridId
     * @param employeeId
     * @param state
     */
    dailyreportDeclineClicked(gridId: string, employeeId: number, state): void {
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {
                employeeId,
                state,
            },
        };
        this.eventGridDailyreportDeclineClicked.next(eventData);
    }

    /**
     * Daten von Grid sollen neu geladen werden
     * @param gridId
     * @param frontendArray
     */
    reloadData(gridId: string, frontendArray: any) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: frontendArray,
        };
        // Event auslösen
        this.eventReloadData.next(eventData);
    }

    /**
     * Ändere das Layout nachdem die Daten neu geladen wurden
     * @param gridId
     * @param layoutId
     */
    changeLayoutAfterReload(gridId: string, layoutId: number) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'gridService',
            target: gridId,
            data: {layoutId},
        };
        // Event auslösen
        this.eventGridChangeLayoutAfterReload.next(eventData);
    }

    /**
     * Ermittle die Anzahl der Kindereinrichtungen einer Einrichtung
     * @param institutionId
     */
    getNumberOfChildren(institutionId: any): Observable<unknown> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            'InstitutionsList/getNumberOfChildren',
            institutionId,
        );
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    /**
     * Lade die Kindeinrichtungen einer Einrichtung
     * @param institutionInfo
     */
    getInstitutionChildren(institutionInfo: any): Observable<unknown> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            'InstitutionsList/getInstitutionChildren',
            institutionInfo,
        );
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    /**
     * Informiere eine Komponente, dass KinderElemente nachgeladen werden sollen
     * @param element
     * @param gridId
     * @param sender
     * @param level
     */
    getElementChildren(element: any, gridId: string, sender: string, level: number) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target: gridId,
            data: {
                element,
                level,
            },
        };
        // Event auslösen
        this.loadElementChildren.next(eventData);
    }

    /**
     * Informiere Grid-Icons, dass KinderElemente geladen wurden und liefere diese mit
     * @param gridId
     * @param element
     * @param target
     * @param level
     */
    notifyGridIconsOfNewChildren(gridId: string, element: any, target: string, level: number) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target,
            data: {
                element,
                level,
            },
        };
        // Event auslösen
        this.childrenElementLoaded.next(eventData);
    }

    /**
     * Lösche eine Selektion
     * @param postData
     */
    deleteSelection(postData: any): Observable<unknown> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            'Selections/deleteSelection',
            postData,
        );
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    /**
     * Informiere Grid-source, dass eine Selektion gelöscht werden soll
     * @param source
     * @param target
     */
    gridSelectionDeleted(source: string, target: string) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: source,
            target,
            data: undefined,
        };
        // Event auslösen
        this.eventGridSelectionDeleted.next(eventData);
    }

    /**
     * Lösche ein Layout
     * @param postData
     */
    deleteLayout(postData: any): Observable<unknown> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest('Grid/deleteLayout', postData);
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    /**
     * Wird von GridComponent aufgerufen um Daten (nach) zu laden
     * @param selectedLayout
     * @param resizedColumn
     * @param customWidth
     */
    saveCustomWidth(selectedLayout, resizedColumn, customWidth): Observable<unknown> {
        const options = {
            selectedLayout,
            resizedColumn,
            customWidth,
        };
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest('Grid/saveCustomWidth', options);
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    /**
     * Informiere Grid-Layout, dass ein Layout gelöscht werden soll
     * @param source
     * @param target
     */
    gridLayoutDeleted(source: string, target: string) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: source,
            target,
            data: undefined,
        };
        // Event auslösen
        this.eventGridLayoutDeleted.next(eventData);
    }

    /**
     * Informiere die einbindende Komponente über den Klick auf den Spalten-Header
     * @param target
     * @param columnName
     * @param direction
     */
    gridColumnHeaderClicked(target: string, columnName: string, direction: string) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'gridService',
            target,
            data: {
                columnName,
                direction,
            },
        };
        // Event auslösen
        this.eventColumnHeaderClicked.next(eventData);
    }

    /**
     * Filter muss zurückgesetzt werden, Event auslösen
     * @param gridId
     */
    resetFilter(gridId: string): void {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'gridService',
            target: gridId,
            data: null,
        };
        // Event auslösen
        this.eventResetGridFilter.next(eventData);
    }

    /**
     * Sortierung muss zurückgesetzt werden, Event auslösen
     * @param gridId
     */
    resetSort(gridId: string): void {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'gridService',
            target: gridId,
            data: null,
        };
        // Event auslösen
        this.eventResetGridSort.next(eventData);
    }

    /**
     * fullscreenSize Wechsel
     * @param target
     * @param fullscreenSize
     */
    toggleFullscreen(target: string, fullscreenSize: number) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target,
            data: {fullscreenSize},
        };
        this.eventFullscreenToggle.next(eventData);
    }
}
