// Angular-Module
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {StorageService} from '@global/services/storage.service';
// Service für das Kennzeichen-Modul
import {CharacteristicsService} from './../characteristics.service';
// Shared Services
import {hasOwn} from '@shared/utils';
import {InputDateService} from './../../input/input-date/input-date.service';
/*
 * Shared Komponenten
 * Interface für Kennzeichengruppe einbinden
 */
import {Characteristic} from './../../characteristic';
import {CWEvent} from './../../cw-event';
import {CWResult} from './../../cw-result';
import {Listentry} from './../../listentry';
// import {environment} from '@environment';

@Component({
    selector: 'phscw-characteristic-single-edit',
    templateUrl: './characteristic-single-edit.component.html',
    styleUrls: ['./characteristic-single-edit.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacteristicSingleEditComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Variable, die anzeigen soll, ob das Modul zum ersten mal aufgerufen wird.
    initialized = false;
    // Flag definiert ob gerade geladen wird
    loading = false;
    // Flag definiert ob gerade gespeichert wird
    saving = false;
    // Fehler
    error = false;

    @Input() entityId: number;
    @Input() baseEntityId: number;

    // Referenz auf Form
    @ViewChild('characteristicSingleEditForm', {static: false}) characteristicSingleEditForm: NgForm;

    // Kennzeichen-Daten für das Template
    @Input() entityCharacteristics: Characteristic;

    // Kennzeichen-Typ
    @Input() characteristicType: string;
    @Input() characteristicLabel: string;

    // Bearbeitetes Kennzeichen
    @Input() characteristicId: number;

    // steuert, ob die Beschreibung des Kennzeichens unter dem Eingabefeld angezeigt wird
    @Input() show_details = false;

    // steuert, ob Input-Elemente Änderungen zulassen, hier immer true, da der Edit-Dialog nur mit Edit-Rechten aufgeht
    editMode = true;

    // Currency-Symbol (wird in NgOnInit ggf. über Environment gesetzt / überschrieben)
    currency = '€';

    invalidateTimeout: any = null;
    formInitialized = false;

    // Event das beim erfolgreichen Submitten ausgelöst wird und die Anzahl der gesetzten Kennzeichen übergeben soll.
    @Output() submitSuccess = new EventEmitter<any>();
    // Event das beim erfolgreichen Submitten ausgelöst wird und die neuen Icons übergeben soll.
    @Output() newEntityIcons = new EventEmitter<any>();
    // Event das bei Validierung der Form ausgelöst wird.
    @Output() formValid = new EventEmitter<boolean>();

    // Event das beim erfolgreichen Submitten ausgelöst wird und die neuen Icons übergeben soll.
    @Output() cancel = new EventEmitter<any>();

    /**
     * Konstruktor
     * @param appCoreService
     * @param storageService
     * @param characteristicsService
     * @param inputDateService
     */
    constructor(
        private appCoreService: AppCoreService,
        private storageService: StorageService,
        private characteristicsService: CharacteristicsService,
        private inputDateService: InputDateService,
    ) {}

    /**
     * Initialisierungen
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
        this.loadData();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions() {}

    /**
     * Daten laden
     */
    loadData(): void {
        // Falls noch nicht geladen wird
        if (!this.loading || !this.initialized) {
            // Flag setzen
            this.loading = true;

            // alles fertig geladen
            this.loading = false;
        }
    }

    /**
     * @brief   Finde geänderte Kennzeichen, um zu vermeiden, dass nicht geänderte Daten unnötig an das Backend gesendet werden
     */
    private getEditedCharacteristics(): any[] {
        // Init
        const returnFormValues = [];
        const keys = Object.keys(this.characteristicSingleEditForm.form.controls);

        // Kennzeichen durchlaufen
        for (const key of keys) {
            // Nur geänderte Kennzeichen sollen gespeichert werden (also touched und dirty, touched wird benötigt, da manche Input-Komponenten die Values beim Initialisieren verändern -> dirty)
            if (
                this.characteristicSingleEditForm.form.controls[key].touched &&
                this.characteristicSingleEditForm.form.controls[key].dirty
            ) {
                // let index = this.characteristics.findIndex(x => x.id === parseInt(key, 10));
                let value;
                // Datumskennzeichen müssen vorher noch richtig formatiert werden.
                if (
                    this.entityCharacteristics.value_type === 'date' &&
                    this.characteristicSingleEditForm.form.controls[key].value !== null
                ) {
                    value = this.inputDateService.getDateValueForSave(
                        this.characteristicSingleEditForm.form.controls[key].value,
                    );
                } else {
                    value = this.characteristicSingleEditForm.form.controls[key].value;
                }
                const changedCharacteristic = {
                    id: key,
                    value,
                };
                returnFormValues.push(changedCharacteristic);
            }
        }
        // Daten zurückgeben
        return returnFormValues;
    }

    /**
     * @param event
     * @brief   Speichern der Kennzeichen auslösen
     */
    onEventSaveCharacteristics(event: CWEvent): void {
        // Da immer genau eine Entity in Bearbeitung ist, ist deren ID bekannt, dennoch prüfen, ob das Event gültig ist
        if (
            hasOwn(event.data, 'characteristicType') &&
            event.data.characteristicType === this.characteristicType &&
            hasOwn(event.data, 'entityId') &&
            event.data.entityId > 0
        ) {
            this.clickSubmit();
        }
    }

    /**
     * @brief   Sendet geänderte Daten zum Speichern ans Backend
     */
    clickSubmit(): void {
        // Nur gültige Formulare submitten
        if (this.characteristicSingleEditForm.form.valid) {
            const editedData = this.getEditedCharacteristics();
            this.saveData(editedData);
        }
    }

    /**
     * @brief   Setzt Kennzeichen auf ihren Ursprungsstand zurück. --> Abbrechen-Button
     */
    clickCancel(): void {
        this.cancel.emit('Dialog closed by cancel');
    }

    /**
     * Listentries aus Storage laden
     */
    private updateCharacteristicsFormatData(): void {
        const promise = this.storageService.getItem('listentries|characteristicsFormatType');
        promise.then((val) => this.onGetListentriesFormatTypes(val));
    }

    /**
     * Kennzeichen mit Daten aus Listentries erweitern
     * @param values
     */
    private onGetListentriesFormatTypes(values: any): void {
        if (
            hasOwn(this.entityCharacteristics, 'format_type') &&
            this.entityCharacteristics.format_type !== null &&
            typeof this.entityCharacteristics.format_type !== 'undefined'
        ) {
            const formatType = values.find((formatType: Listentry) => formatType.list_key === this.entityCharacteristics.format_type);
            if (formatType !== null && typeof formatType !== 'undefined') {
                this.entityCharacteristics['format_data'] = JSON.parse(formatType.list_data);
            }
        }

        // Status der Form beachten
        this.characteristicSingleEditForm.form.statusChanges
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: string) => {
                if (result === 'VALID') {
                    this.formValid.next(true);
                } else {
                    this.formValid.next(false);
                }
            });

        // aktuellen Status an übergeordnete Komponenten mitteilen
        if (this.characteristicSingleEditForm.form.valid) {
            this.formValid.next(true);
        } else {
            this.formValid.next(false);
        }
    }

    /**
     * @param changedCharacteristics
     * @param deleted
     * @brief   Sendet geänderte Daten zum Speichern ans Backend
     */
    saveData(changedCharacteristics: any, deleted = false): void {
        if (changedCharacteristics === undefined || changedCharacteristics.length == 0) {
            // es gibt nichts zu speichern, direkt den Dialog schließen ohne Request
            this.cancel.emit('Dialog closed, nothing to save');
            return;
        }

        // Flag setzen
        this.saving = true;

        // bei EventsPeople Kennzeichen müssen beide IDs in der Edit-URL übergeben werden, die Event-ID wird als Prefix definiert, um die bestehende Funktion des CharacteristicsService weiter zu nutzen
        let idPrefix = '';
        if (!this.isUndefined(this.baseEntityId)) {
            idPrefix = this.baseEntityId + '/';
        }

        // Speichern
        const serviceRequest$ = this.characteristicsService.saveData(
            this.entityId,
            changedCharacteristics,
            this.characteristicType,
            idPrefix,
        );
        serviceRequest$.subscribe(
            // onNext
            (result: CWResult) => {
                // neue Icons übergeben
                if (result['data'] && hasOwn(result['data'], 'iconString')) {
                    this.characteristicsService.dataChanged(this.characteristicType, {
                        id: this.entityId,
                        iconString: result['data']['iconString'],
                    });
                }

                if (
                    result['data'] &&
                    hasOwn(result['data'], 'characteristics') &&
                    result['data']['characteristics'].length > 0
                ) {
                    // Signalisieren, dass sich Daten geändert haben

                    /*
                     * TODO evtl. wäre es sauberer, die geänderten Kennzeichen über den Service zu publizieren, statt über den submitSuccess-Callback
                     * dafür müssten hier die unterschiedlichen Datentypen aufbereitet werden, so dass das Grid sie versteht
                     * die Aufbereitung könnte eine spezielle Funktion im Service übernehmen, das lohnt sich aber nur,
                     * wenn auch andere Stellen den Service nutzen, z.B. die Bearbeitung der Kennzeichen in der Detailansicht -> spätere Erweiterung
                     * Weitergabe an den Service zur aktualisierung der Liste, würde ein Umverpacken der Optionswerte etc. benötigen, das wird stattdessen im Callback nach submitSuccess gemacht
                     * let changedCharacteristic = result['data']['characteristics'][0];
                     * this.characteristicsService.dataChanged(this.characteristicType, {
                     *     'changedItem': {
                     *         'id': this.entityId,
                     *         ['characteristic_' + changedCharacteristic.characteristic_id]: changedCharacteristic.value_date
                     *     }
                     * });
                     */

                    // Event auslösen, ruft den Callback zur Aktualisierung der Liste auf
                    this.submitSuccess.emit(result['data']['characteristics'][0]);
                }

                // Flag setzen
                this.saving = false;

                // Event auslösen um Popup zu schließen
                this.cancel.emit('Dialog closed after save');
            },
            // onError
            (error) => {
                console.error(error);
                // Flag setzen
                this.error = true;
                this.saving = false;
            },
        );
    }

    /**
     * @brief   Markiert das übergebene Element als "touched"
     * @param controlName
     * @param   any   value   Schlüssel mit dem das Element in der Form gefunden werden kann
     */
    markCharacteristicInputAsTouched(controlName: string | number): void {
        this.characteristicSingleEditForm.form.controls[controlName].markAsTouched();
    }

    /**
     * Setzte die Errors für Checkbox-Kennzeichen, weil wir im input-Checkbox-
     * Modul den Validator nicht richtig auf die FormControl binden können.
     * @param invalid
     * @param id
     * @param recursion
     * @todo: Sehr unsauber mit setTimeout(), evtl. nochmal nach besseren Lösungen suchen
     * @todo: Rekursion hat bei bereits gespeicherten Formularen dazu geführt, dass das Formular als INVALID markiert wurde, obwohl sie VALID war.
     */
    invalidateCheckboxes(invalid: boolean, id: number, recursion = false) {
        /*
         * bei bereits gespeicherten Formularen frühzeitig abbrechen, um den
         * Validierungsstatus nicht zu überschreiben durch das setTimeout
         */
        if (this.entityId > 0 && recursion && this.formInitialized) {
            return;
        }

        // Status der Validierung prüfen
        if (invalid === false) {
            // Form auf initialisiert setzen und Fehler zurücksetzen
            this.formInitialized = true;
            this.characteristicSingleEditForm.form.controls[id].setErrors(null);
        } else {
            const error = {
                validationError: {
                    given: false,
                    message: '',
                },
            };
            if (this.characteristicSingleEditForm !== undefined) {
                // Form auf initialisiert setzen und Fehler setzen
                this.formInitialized = true;
                this.characteristicSingleEditForm.form.controls[id].setErrors(error);
            } else {
                // Form war noch nicht initialisiert und daher muss noch einmal geprüft werden
                setTimeout(() => this.invalidateCheckboxes(invalid, id, true), 200);
            }
        }
    }

    /**
     * @brief   Überprüft, ob Wert gesetzt ist.
     * @param value
     * @param   any   value   Wert der überprüft werden soll.
     * @returns  boolean
     */
    isUndefined(value: any): boolean {
        return (
            typeof value === 'undefined' ||
            value === null ||
            (Object.keys(value).length === 0 && value.constructor === Object)
        );
    }
}
