// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Übergeordneter Contacts-Service
import {ContactsService} from '@shared/contacts/contacts.service';
// Services anderer Shared-Modules
import {GridService} from '@shared/grid/grid.service';
// Globalen Service einbinden
import {AppCoreService} from '@global/services/app-core.service';
// Interfaces für Structured Objects einbinden
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CWEvent} from '@shared/cw-event';

import {CWResult} from '@shared/cw-result';

// Shared Components
import {UserSettingsService} from '@global/services/user-settings.service';
import {PopupLoadingComponent} from '@shared/popups/popup-loading/popup-loading.component';

@Component({
    selector: 'phscw-contacts-links',
    templateUrl: './contacts-links.component.html',
    styleUrls: ['./contacts-links.component.scss'],
})
export class ContactsLinksComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Flag definiert ob gerade geladen wird
    loading = false;
    // History-Daten
    data: any = [];
    // Flag, falls keine Daten vorhanden sind
    noData = false;

    // ID des aktuellen Kontakts - Über GETTER / SETTER, da bei Änderung ein Neuladen erfolgen soll
    _contactId = 0;
    get contactId() {
        return this._contactId;
    }

    @Input() set contactId(value) {
        // Wert übernehmen
        this._contactId = value;

        // Details laden (falls es sich NICHT um eine Neuanlage handelt - z.B. in Kontaktübersicht)
        if (this.contactId >= 0) {
            this.loadDetails(this.contactId);
        }
    }

    // ID des Grids
    @Input() gridId = 'linkedContactsList';
    // Daten für Grid
    gridData: any[] = [];

    // Referenz auf Loading-Popup
    loadingPopupReference: MatDialogRef<PopupLoadingComponent>;

    /*
     * Spaltendefinitionen für Grid
     * @todo: Kommt später vom Backend
     */
    gridColumns = [
        {
            columnDef: 'ticket-id',
            header: 'Ticket ID',
            cell: (element: any) => `${element.id}</a>`,
            formatWidth: '100px',
        },
        {
            columnDef: 'ticket-received-at',
            header: 'Received at',
            cell: (element: any) => `${element.received_at}`,
            formatTemplate: 'date',
        },
        {
            columnDef: 'ticket-status',
            header: 'Status',
            columnField: 'ticket_status',
            formatTemplate: 'listentries',
            listentry: 'ticketState',
            cell: (element: any) => `${element.ticket_status}`,
            formatWidth: '125px',
        },
        {
            columnDef: 'ticket-title',
            header: 'Title',
            cell: (element: any) => `${element.ticket_title}`,
            formatWidth: '125px',
        },
        {
            columnDef: 'ticket-link-type',
            header: 'Linktype',
            cell: (element: any) => ` <span class="cw-grid-icon ${element.ticket_link_type}"></span>`,
            formatWidth: '125px',
        },
    ];

    // Anzuzeigende Spalten für Grid
    @Input() gridDisplayedColumns = [
        'ticket-id',
        'ticket-received-at',
        'ticket-status',
        'ticket-title',
        'ticket-link-type',
    ];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param appCore
     * @param contactsService
     * @param dialog
     * @param userSettingsService
     * @param gridService
     */
    constructor(
        private appCore: AppCoreService,
        private contactsService: ContactsService,
        private dialog: MatDialog,
        private userSettingsService: UserSettingsService,
        private gridService: GridService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Es wurde auf einen Benutzer geklickt
        this.gridService.eventGridSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Event verwerfen, wenn nicht für Komponente gedacht
                if (event.sender !== this.gridId) {
                    return;
                }
                this.prepareNavigationToUser(event.data.selectedRow.contact_id);
            });
    }

    /**
     * ???
     * @param contactId
     */
    loadDetails(contactId: number): void {
        // this.loading = true;

        const serviceRequest$ = this.contactsService.getContactLinks(contactId);
        serviceRequest$.subscribe((result: any) => {
            this.gridData = result['data'];

            this.gridData.forEach((value) => {
                if (value.ticket_link_type == 'child') {
                    value.ticket_link_type = 'icon-level-down-alt';
                }

                if (value.ticket_link_type == 'parent') {
                    value.ticket_link_type = 'icon-level-up-alt';
                }

                if (value.ticket_link_type == 'parallel') {
                    value.ticket_link_type = 'icon-link';
                }
            });

            this.loading = false;
        });
    }

    /**
     * @param userId
     * @brief   Zum jeweiligen Benutzer wechseln
     * @details In dieser Funktion werden die User-Settings überschrieben und
     *          die Filterung im Backend angestoßen.
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    prepareNavigationToUser(userId: number): void {
        // Lade-Popup zeigen
        this.loadingPopup(true);

        /**
         * Datenquelle der Liste soll auf "Auswahl" (KEY = 40) geändert werden.
         * =====================================================================
         * User-Setting wird hier überschrieben. Falls die Liste noch nicht
         * zuvor initialisiert wurde, wird beim Wechsel zur Liste direkt
         * mit dem gewünschten / geänderten Setting initialisiert.
         */
        this.userSettingsService.setValue('reportsTicketsListSource', '40');

        // Starte Filterung im Backend
        const serviceRequest$ = this.contactsService.executeFilterUserById(userId);
        serviceRequest$.subscribe((result: CWResult) => {
            this.executeNavigationToPeopleOfInstitution();
        });
    }

    /**
     * @brief   Das Loading-Popup wird verwendet um weitere User-Eingaben
     *          zu verhindern, während Daten im Hintergrund vorbereitet werden
     * @param   show boolean    <true> Zeige Popup
     *                          <false> Schließe Popup
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    private loadingPopup(show: boolean): void {
        if (show === true) {
            // Popup öffnen
            this.loadingPopupReference = this.dialog.open(PopupLoadingComponent, {
                width: '250px',
                data: {},
            });
        } else {
            // Popup schließen
            this.loadingPopupReference.close();
        }
    }

    /**
     * @brief   In der Benutzerliste der Rolle wurde ein Benutzer angeklickt und es soll
     *          deshalb zur Benutzerliste gewechselt werden.
     * @details Diese Funktion wird aufgerufen, sobald die Filterung im Backend
     *          abgeschlossen wurde, damit nun abschließend die Navigation
     *          auf das gewünschte Modul stattfinden kann.
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private executeNavigationToPeopleOfInstitution(): void {
        // Lade-Popup schließen
        this.loadingPopup(false);

        /**
         * Datenquelle der Liste soll auf "Auswahl" (KEY = 40) geändert werden.
         * =====================================================================
         * Falls die Liste bereits initialisiert wurde, muss die Veränderung
         * der Datenquelle über ein Event gesendet werden.
         */
        const eventData: CWEvent = {
            sender: 'contact-links',
            target: 'reportsTicketsOverviewList',
            data: {
                listKey: '40',
                selectRowAfterFinished: 'first',
            },
        };
        this.appCore.changeGridSource.next(eventData);

        /**
         * Navigation zur Benutzer-Liste
         */
        // this.appCore.crossModuleNavigation('reports/tickets-overview', {});

        // Liste auch neu laden
        this.gridService.reloadGridData('reportsTicketsOverviewList');
    }
}
