// Angular-Module
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Komponenten einbinden
import {GlobalHelpPopupComponent} from './../global-help-popup/global-help-popup.component';
// Services
import {GlobalHelpService} from '@global/components/global-help/global-help.service';

import {CWEvent} from '@shared/cw-event';

@Component({
    selector: 'phscw-global-help-icon',
    templateUrl: './global-help-icon.component.html',
    styleUrls: ['./global-help-icon.component.scss'],
})
export class GlobalHelpIconComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();
    // this data

    /**
     * Konstruktor (inkl. dependency injection)
     * @param dialog
     * @param globalHelpService
     */
    constructor(
        private dialog: MatDialog,
        private globalHelpService: GlobalHelpService,
    ) {}

    /*
     * component destroyed
     * event abfangen
     */
    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Wenn im Grid ein Eintrag ausgewählt wurde...
        this.globalHelpService.eventOpenPopup
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                this.onEventOpenPopup(result);
            });
    }

    /**
     * Auf Event "eventGridSelectionChanged" von "gridService" reagieren
     * @param event
     */
    onEventOpenPopup(event: CWEvent): void {
        // An eigenen Service weiterleiten
        this.showHelp(event.data);
    }

    /**
     * @param eventData
     * @brief   Account-Info als Popup anzeigen
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    showHelp(eventData = null): void {
        // Dialog öffnen
        this.dialog.open(GlobalHelpPopupComponent, {
            width: '950px',
            height: '600px',
            data: eventData,
        });
    }
}
