// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globalen Service einbinden
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';

@Injectable({providedIn: 'root'})
export class EventsPeopleService {
    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
    ) {}

    loadPeopleForEvent(eventId: number, page: number) {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'EventsPeople/index/' + eventId + '/' + page,
        );
        // Observable (an Komponente) zurückliefern
        return getRequest$;
    }

    saveNewEventPerson(eventId: number, personData: any) {
        const data = {
            event_id: eventId,
            person_id: personData['person_id'],
            event_person_role: personData['event_person_role'],
            event_person_status: personData['event_person_status'],
        };
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('EventsPeople/newPerson', data);
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    editEventPerson(data: any) {
        /*
         * Editor-Komponente hat Daten schon passend verpackt, reiche diese an das Backend weiter
         * POST-Request über BackendService senden
         */
        const postRequest$: Observable<any> = this.backendService.postRequest('EventsPeople/edit', data);
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    /**
     * @param eventId
     * @brief       Falls Daten erfolgreich geändert wurden
     * @details     Sendet Mitteilung über AppCore (jedoch ohne geändertes
     *              Datenobjekt), damit andere Module darauf reagieren können
     */
    dataChanged(eventId: number): void {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'events-people',
            target: '',
            data: {event_id: eventId},
        };
        // Event auslösen
        this.appCore.appDataChanged.next(eventData);
    }

    /**
     * @param eventId
     * @brief   Starte Filterung für Personen einer Veranstaltung
     * @details Dies wird z.B. aufgerufen, wenn in der Teilnehmerliste auf den Personenbutton geklickt wurde.
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    executeFilterPeopleOfEvent(eventId: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('EventsPeople/filterBy/event/' + eventId);
        // Observable (an Komponente) zurückliefern
        return getRequest$;
    }

    /*
     * Layouts über Backend laden
     * @todo: Redundant in P-Liste, E-Liste, VM-Liste --> direkt in GridLayout auslagern?
     */
    getLayoutData(): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('Grid/getLayoutDatasets/eventsPeople'); // Modulname hier ohne 'List' am Ende!
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }
}
