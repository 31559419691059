// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host h2 {
  margin-top: 0;
}

.cw-horizontalline {
  border-color: var(--color-accent);
  height: 2px;
}

.cw-dialog-content {
  max-height: 450px;
  overflow: auto;
  text-align: center;
}

.mat-mdc-raised-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.cw-subscription-canvas {
  border: 1px solid black;
  height: 400px;
  width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/popups/popup-signature/popup-signature.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,iCAAA;EACA,WAAA;AACJ;;AAEA;EACI,iBAAA;EACA,cAAA;EACA,kBAAA;AACJ;;AAEA;EACI,cAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,aAAA;EACA,YAAA;AACJ","sourcesContent":[":host h2 {\n    margin-top: 0;\n}\n\n.cw-horizontalline {\n    border-color: var(--color-accent);\n    height: 2px;\n}\n\n.cw-dialog-content {\n    max-height: 450px;\n    overflow: auto;\n    text-align: center;\n}\n\n.mat-mdc-raised-button {\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.cw-subscription-canvas {\n    border: 1px solid black;\n    height: 400px;\n    width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
