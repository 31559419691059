import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {FilterData} from '@shared/filter-data';

/**
 * Stellt Helper-Funktionalität bereit für die Sortierung nach Spalten-Headern, um diese aus der GridComponent auszulagern
 */
@Injectable({providedIn: 'root'})
export class GridSortService {
    public eventSaveSelection = new Subject<CWEvent>();
    public eventLayoutSaved = new Subject<CWEvent>();
    public eventLayoutSaveStarted = new Subject<CWEvent>();
    public eventGridReloadLayouts = new Subject<CWEvent>();

    constructor(private translateService: TranslateService) {}

    /**
     * Bereitet das FilterData-Objekt vor, welches beim Laden der Liste als Sortiervorgabe an das Backend gesendet wird
     * @param columnId  ID der Spalte, nach der Sortiert werden soll
     * @param listentry Angabe des Listentries, falls es sich um eine Spalte mit Listentry-Daten handelt
     * @param direction 'asc' oder 'desc'
     * @returns FilterData-Objekt, das alle Angaben enthält, auch die aktive Sprache, damit bei Listentries richtig sortiert wird
     */
    prepareBackendSort(columnId: string, listentry: string, direction: string): FilterData {
        const languageKey = this.translateService.currentLang;
        /*
         * die 'formular' Property (Typ any) wird hier verwendet, um den Typ der Spalte mitzugeben, insbesondere bei listentries ist das wichtig,
         * damit das Backend entsprechend sortieren kann
         */
        const sortObject: FilterData = {
            label: columnId,
            key: columnId,
            direction,
            formular: listentry,
            display: languageKey,
        };

        return sortObject;
    }

    /**
     * Markiert die angegebene Spalte mit dem Sortier-Icon entsprechend der gewählten Richtung.
     *
     * Diese Implmentierung injiziert den HTML-Code direkt in den Header-Text,
     * da dieser bei den meisten Spalten direkt per innerHTML-Binding in den Spalten-Header eingefügt ist.
     * Aus diesem Grund lässt sich das Styling auch nicht rein per CSS realisieren:
     * Das Einfügen des Icons mit :after geht zwar, aber die vertikale Ausrichtung verschiebt sich
     * und display:flex zerstört die sticky-position der Header-Zeile.
     * @todo evtl. weitere CSS-Tricks probieren oder alternativ bei allen Headern einen inneren Container einfügen.
     * @param columnDefinition  ColumnDef, über die der Header angesprochen werden kann
     * @param sortObject        Das vorbereitete Sort-Objekt
     */
    markSortColumnHeader(columnDefinition: any, sortObject: FilterData): void {
        if (columnDefinition) {
            // Setze den Header-Text, der an das innerHTML gebunden ist
            columnDefinition.sortObject = sortObject;
        }
    }

    /**
     * Setze die Namen der Spalten-Header zurück. (Entferne mögliche Sortierungs-
     * Icons). Entfernt die eingefügten Icons aller Spalten aus dem Header mittels Regex.
     * @param columns Die Liste der zu bearbeitenden Spalten
     */
    resetColumnHeaders(columns: any[]): void {

    }
}
