// animations.ts
import {trigger, transition, style, animate} from '@angular/animations';

export const detailExpandAnimation = trigger('detailExpand', [
    transition(':enter', [
        style({
            height: '0px',
            opacity: 0,
        }),
        animate(
            '225ms cubic-bezier(0.4, 0.0, 0.2, 1)',
            style({
                height: '*',
                opacity: 1,
            }),
        ),
    ]),
    transition(':leave', [
        style({
            height: '*',
            opacity: 1,
        }),
        animate(
            '225ms cubic-bezier(0.4, 0.0, 0.2, 1)',
            style({
                height: '0px',
                opacity: 0,
            }),
        ),
    ]),
]);
