import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-targets-visits-customer-classification',
    templateUrl: './chart-targets-visits-customer-classification.component.html',
    styleUrls: ['./chart-targets-visits-customer-classification.component.scss'],
})
export class ChartTargetsVisitsCustomerClassificationComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
