/**
 * @brief   Komponente die auf Enter Klick eine Liste von passenden vorschlägen gibt.
 * @details Das Einbinden der Komponente kann in shared\order-tracking\order-tracking.component.html
 *          z.B. beim delivery_address-Inputfeld angesehen werden.
 *
 *          Der Komponente muss ein backendLink übergeben werden, der eine Suchfunktion ansteuert, die
 *          ein Array mit Objekten, die mindestens die Eigenschaften label und id haben müssen,
 *          zurückgibt. Die Suchfunktion sucht nach dem in das Feld eingegebenen Wert und ihr können
 *          über den optionalRestrictions-Parameter weitere Informationen übergeben werden. In dem optionalInfo-
 *          Parameter können Informationen mitgegeben werden, die bei Auswahl einer Option zurück an die einbindende Komponente
 *          geliefert werden sollen. Bsp:
 *
 *          <mat-cell *matCellDef="let element; let i = index">
 *              <phscw-input-autocomplete name="article_nr" id="article_nr"
 *                 [backendLink]="'InstitutionsOrders/searchProductsForAutocomplete'"
 *                 [editMode]="editMode"
 *                 [optionalRestrictions]="{institution_id: institutionId, order_range: dataItem.order_range, order_type: dataItem.order_type, search_column: 'productkey'}"
 *                 [myValue]="element.productkey"
 *                 [optionalInfo]="i"
 *                 (optionSelected)="loadProductInformation($event)">
 *              </phscw-input-autocomplete>
 *          </mat-cell>
 *
 *          Siehe als Beispiel die Funktion searchProductsForAutocomplete() in InstitutionsOrdersController.php.
 *          Die zurückgegebenen Optionen im Array werden angezeigt. Bei Klick auf eine Option wird ein Event
 *          (optionSelected) an die einbindende Komponente gesendet, mit der Id des gewählten Eintrags.
 * @author  Michael Schiffner <m.schiffner@pharmakon.software>
 */
// Angular-Module
import {
    Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {hasOwn} from '@shared/utils';
// Services
import {InputAutocompleteService} from './input-autocomplete.service';
import {AutocompleteOption} from '@shared/autcomplete-option';

@Component({
    selector: 'phscw-input-autocomplete',
    templateUrl: './input-autocomplete.component.html',
    styleUrls: ['./input-autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputAutocompleteComponent),
            multi: true,
        },
        {provide: MatAutocompleteTrigger},
    ],
})
export class InputAutocompleteComponent implements OnInit, OnDestroy {
    /*
     * Optionen, die direkt vom parent übergeben werden
     * Wenn diese Optionen gesetzt sind, wird kein Backend request gemacht
     */
    @Input() providedOptions: AutocompleteOption[] = [];

    /*
     * Eine Auswahl ist erforderlich, sonst ist das Feld nicht valid
     * Funktioniert aktuell nur mit "providedOptions"
     *
     * TODO: auch für Backend-Requests implementieren, aktuell werden die Suchergebnisse direkt wieder geleert
     */
    @Input() requireSelection = false;

    // Referenz auf Input-Feld
    @ViewChild(MatAutocompleteTrigger, {static: false}) autocompleteInput: MatAutocompleteTrigger;

    // EditMode aktiv?
    @Input() editMode = false;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string;

    // Model "myValue" mit GETTER & SETTER
    @Input() _myValue = '';
    get myValue() {
        return this._myValue;
    }

    @Input() set myValue(value) {
        this._myValue = value;
        this.propagateChange(this._myValue);
    }

    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    propagateChange = (_: any) => {};

    // Attribut: required = Pflichtfeld (ja / nein)
    @Input() required = false;
    // Attribut: disabled = Gesperrt (ja / nein)
    @Input() disabled = false;

    // Soll ein Löschbutton angezeigt werden.
    @Input() showDeleteButton = false;
    // In Ansichtsmodus verstecken, wenn leer?
    @Input() hideEmpty = true;

    // Mindestlänge des Suchbegriffs (Standard: 3 Zeichen)
    @Input() minimumSearchInputLength = 3;
    // Läuft die Suchanfrage noch
    searchInProgress = false;
    // BackendController/Funktion die für die Suche aufgerufen werden sollen.
    @Input() backendLink = '';

    // Optionen für das Autocomplete Drop-Down-Menü, die aus dem Backendkommen
    options: AutocompleteOption[] = [];
    // Die vom Frontend gefilterten Optionen, im Autocomplete Drop-Down-Menü angezeigt werden.
    filteredOptions: AutocompleteOption[] = [];
    // Zusatzinformationen für die Suche, siehe Beschreibung oben.
    @Input() optionalRestrictions: any = {};
    // Event-Emmitter zur Anzeige gewählter Ids,
    @Output() optionSelected = new EventEmitter<any>();
    // Event-Emitter, falls der Wert gelöscht wurde
    @Output() deleteClicked = new EventEmitter<any>();
    // Optionale Informationen, die mit Eventauslösung zurück an die Einbindende Komponente gesendet werden.
    @Input() optionalInfo: any;
    /*
     * Soll zusätzlich das zur ID des gewählten Eintrages auch Label mit zurückgeben werden, wenn postEvent ausgeführt wird?
     * !Achtung! Kann momentan nicht gleichzeitig mit optionalInfo genutzt werden.
     */
    @Input() returnLabel = false;

    /**
     * Interface ControlValueAccessor: writeValue
     * @param value
     */
    writeValue(value: any) {
        if (value !== undefined) {
            this.myValue = value;
        }
    }

    /**
     * Konstruktor (inkl. dependency injection)
     * @param inputAutocompleteService
     */
    constructor(private inputAutocompleteService: InputAutocompleteService) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        /*
         * Es werden die Optionen verwendet, die vom parent übergeben wurden.
         * Falls nicht gesetzt, wird ein Backend-Request gemacht, um die Daten zu laden
         */
        if (this.providedOptions && this.providedOptions.length > 0) {
            this.options = this.providedOptions;
            this.filteredOptions = this.options;
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}

    /**
     * Lösche den Text
     */
    deleteValue(): void {
        this.myValue = null;
        this.deleteClicked.emit();
    }

    /**
     * Suche im Backend nach den Entsprechenden
     * @param value
     */
    startSearch(value: any): void {
        // Wenn Optionen vom parent übergeben wurden, kein Backend-Request machen
        if (this.providedOptions && this.providedOptions.length > 0) {
            this.filteredOptions = this.filter(value);
            this.autocompleteInput.openPanel();
            return;
        }

        // Falls es sich bei Value um ein Object (id+label) handelt
        if (typeof value === 'object') {
            if (value) {
                if (value.label) {
                    value = value.label;
                }
            }
        }
        /*
         * Abbruch, falls kein Suchbegriff oder zu wenig Buchstaben/Ziffern eingegeben wurde
         * 2020-10-19, PHS(MFe): Auf Holgers Wunsch kann auch gesucht werden, wenn gar nichts eingegeben wurde
         */
        if (!value || typeof value === 'undefined') {
            value = '';
        }
        // if (!value || value.length < this.minimumSearchInputLength) {
        if (value.length < this.minimumSearchInputLength) {
            return;
        }

        // Falls gerade eine Suche läuft, kann keine erneute Suche gestartet werden
        if (this.searchInProgress) {
            return;
        }

        // @todo early return;
        if (this.backendLink === '') {
            return;
        }

        this.searchInProgress = true;

        const serviceRequest$ = this.inputAutocompleteService.searchData(
            value,
            this.optionalRestrictions,
            this.backendLink,
        );
        serviceRequest$.subscribe(
            // onNext
            (result: any) => {
                // Antwort vom Backend erhalten

                if (result['success'] === true) {
                    /**
                     * Prüfe, ob die Daten des eintreffenden Requests auch
                     * zum aktuell ausgewählten Suchwert passen. Durch asynchrone
                     * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                     * bereits der Suchwert gewechselt wurde und die Antwort
                     * eines Requests verspätet eintrifft und dadurch die
                     * korrekten Daten wieder überschreibt.
                     */
                    if (value != result['data']['value']) {
                        return;
                    }

                    this.options = result['data']['result'];
                    // Setze die gefilterten Optionen initial auf die vom Backend bekommenen Optionen
                    this.filteredOptions = this.options;
                    // Suche beendet
                    this.searchInProgress = false;
                    // Panel öffnen
                    this.autocompleteInput.openPanel();
                }
            },
            // onError
            (error) => {
                //
                this.searchInProgress = false;
            },
        );
    }

    /**
     * Sendet bei Auswahl einer Option aus der Liste ein den Wert, der gewählt wird als Object oder einfachen Wert zurück an die Einbindende Komponente (Über ein emmittiertes Event)
     * @param value
     */
    postEvent(value: any): void {
        if (typeof this.optionalInfo !== 'undefined') {
            // Falls optionale Parameter mitgeliefert werden, die wieder zurückgeliefert werden müssen, diese mit der Id(dem value) in ein Object packen.
            const InfoObject = {
                id: value,
                optionalInfo: this.optionalInfo,
            };
            this.optionSelected.emit(InfoObject);
        } else if (this.returnLabel !== false) {
            const currentOption = this.filteredOptions.find((obj) => obj.id == value);
            const labelObject = {
                id: value,
                label: currentOption.label,
            };
            this.optionSelected.emit(labelObject);
        } else {
            this.optionSelected.emit(value);
        }
    }

    /**
     * @details  Funktion, die für die Darstellung des Wertes und der Optionen zuständig ist.
     * @param option
     *           Falls es sich um ein Optionen-Objekt handelt wird das labels des Objects angezeigt,
     *           sonst wird einfach der Wert angezeigt.
     * @param    any   option   Wert oder AutocompleteOption-Objekt
     * @returns   string
     */
    displayOption(option: any): string {
        if (
            typeof option !== 'undefined' &&
            option !== null &&
            option !== '' &&
            Object.keys(option).length !== 0 &&
            option.constructor === Object
        ) {
            return option.label;
        }

        if (typeof option !== 'undefined' && option !== null && option !== '') {
            return option;
        }

        return '';
    }

    /**
     * Filter-Funktion zum Einschränken der angezeigten Optionen
     * @param {string | AutocompleteOption} value Wert, der gefiltert wird
     * @returns {AutocompleteOption} gefilterte Optionen
     */
    private filter(value: string | AutocompleteOption): AutocompleteOption[] {
        // Ggf. label extrahieren
        const filterValue = typeof value === 'object' && value !== null ? value.label || '' : value;

        // Bei leeren Strings nicht filtern, sondern sofort auf die ursprungs-optionen zurücksetzen
        if (filterValue === '') {
            return this.options;
        }

        const lowerCaseFilterValue = filterValue.toLowerCase();
        // Wenn der filterValue mehrere Worte hat, soll nach jedem einzelnen im String gesucht werden
        const filterValues = lowerCaseFilterValue.split(' ');
        // Wenn es die product_number - Property gibt, die nicht Teil des Labels ist, soll auch danach gesucht werden
        if (hasOwn(this.options[0], 'product_number')) {
            return this.options.filter(
                (option) =>
                    this.filterOptions(option, filterValues) === true ||
                    option.product_number.includes(lowerCaseFilterValue),
            );
        }

        // Suche nach allen Wörtern, die im option-label vorkommen
        return this.options.filter((option) => this.filterOptions(option, filterValues) === true);
    }

    /**
     * @brief   Private Funktion die Filterung für mehrere durch Leerzeichen getrennte Worte durchführt.
     * @param   AutocompleteOption   option   Einzelne Option, die auf Filter-Werte überprüft wird
     * @param option
     * @param filterValues
     * @param   string[]       filterValues   Array von Wörtern die in das Input-Feld geschrieben wurden, getrennt durch Leerzeichen.
     * @returns  boolean
     */
    private filterOptions(option: AutocompleteOption, filterValues: string[]): any {
        // Initialisiere Rückgabewert
        let isInString = true;
        // Für jedes eingegebene Wort, prüfe, ob das Option-Label dieses enthält
        for (let i = 0; i < filterValues.length; i++) {
            if (!option.label.toLowerCase().includes(filterValues[i])) {
                // => wenn nicht wird die Option herausgefiltert
                isInString = false;
                break;
            }
        }
        return isInString;
    }

    /**
     * @brief   Reagiert auf den keyup des Input-Feldes. Startet die Filterung der Werte
     * @param value
     * @param   string   value   Eingegebener Wert im Input-Feld
     */
    inputChanged(value: string): void {
        // Wenn Optionen aus dem Backend geladen wurden
        if (this.options.length > 0) {
            // filtere diese auf den input-value und setze die gefilterten Optionen.
            this.filteredOptions = this.filter(value);
        }
    }

    /**
     * @brief   Selektiert Text im Input bei Klick in Input
     * @param       event   Focus-Event
     */
    selectText(event): void {
        event.target.select();
    }

    /**
     * @brief   Suche auslösen, wenn noch keine Daten vorhanden sind
     * @details Soll verhindern, dass bei Verlassen des Feldes zum Auswahl eines
     *          Wertes die Suche nochmal ausgelöst wird
     */
    onBlur(): void {
        // Prüfen, ob schon Daten vorhanden sind
        if (this.options.length === 0) {
            // Suche auslösen, wenn noch keine Daten vorhanden sind
            this.startSearch(this.myValue);
        }
    }

    /**
     * Interface ControlValueAccessor: setDisabledState
     * @param {boolean} isDisabled Input disabled?
     */
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
