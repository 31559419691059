// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */
:host {
  background: center/cover no-repeat;
}

.cw-login-error {
  font-size: 1.3rem;
  height: 4rem;
}

.cw-login-input {
  border: 1px solid var(--color-primary-lighter);
  font-size: 1.4rem;
  height: 35px;
  padding-left: 35px;
  margin-bottom: 10px;
  width: 100%;
}

.cw-login-input:focus {
  border-color: var(--color-primary);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgba(144, 144, 144, 0.4);
  outline: none;
}

.cw-login-icon {
  font-size: 2.5rem;
  height: 35px;
  line-height: 3.5rem;
  position: absolute;
  text-align: center;
  width: 35px;
}

.cw-login-forgot {
  text-align: right;
}

.cw-login-button {
  margin-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
}

.image-container {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust the space between the image and the text */
}

.image-container img {
  width: 24px; /* Adjust the size of the image */
  height: 24px; /* Adjust the size of the image */
}

.image-container span {
  margin: 0; /* Remove default margin */
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-login/global-login.component.scss"],"names":[],"mappings":"AAAA;;;;;;EAAA;AAQA;EACI,kCAAA;AAAJ;;AAGA;EACI,iBAAA;EACA,YAAA;AAAJ;;AAGA;EACI,8CAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;AAAJ;;AAGA;EACI,kCAAA;EACA,kFACI;EAEJ,aAAA;AAFJ;;AAKA;EACI,iBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;AAFJ;;AAKA;EACI,iBAAA;AAFJ;;AAKA;EACI,mBAAA;EACA,yBAAA;EACA,WAAA;AAFJ;;AAKA;EACI,aAAA;EACA,mBAAA;EACA,QAAA,EAAA,oDAAA;AAFJ;;AAKA;EACI,WAAA,EAAA,iCAAA;EACA,YAAA,EAAA,iCAAA;AAFJ;;AAKA;EACI,SAAA,EAAA,0BAAA;AAFJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n * @author  Massimo Feth <m.feth@pharmakon.software>\n */\n\n:host {\n    background: center / cover no-repeat;\n}\n\n.cw-login-error {\n    font-size: 1.3rem;\n    height: 4rem;\n}\n\n.cw-login-input {\n    border: 1px solid var(--color-primary-lighter);\n    font-size: 1.4rem;\n    height: 35px;\n    padding-left: 35px;\n    margin-bottom: 10px;\n    width: 100%;\n}\n\n.cw-login-input:focus {\n    border-color: var(--color-primary);\n    box-shadow:\n        inset 0 1px 1px rgba(0, 0, 0, 0.075),\n        0 0 6px rgba(144, 144, 144, 0.4);\n    outline: none;\n}\n\n.cw-login-icon {\n    font-size: 2.5rem;\n    height: 35px;\n    line-height: 3.5rem;\n    position: absolute;\n    text-align: center;\n    width: 35px;\n}\n\n.cw-login-forgot {\n    text-align: right;\n}\n\n.cw-login-button {\n    margin-bottom: 10px;\n    text-transform: uppercase;\n    width: 100%;\n}\n\n.image-container {\n    display: flex;\n    align-items: center;\n    gap: 8px; /* Adjust the space between the image and the text */\n}\n\n.image-container img {\n    width: 24px; /* Adjust the size of the image */\n    height: 24px; /* Adjust the size of the image */\n}\n\n.image-container span {\n    margin: 0; /* Remove default margin */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
