/**
 * @brief   Multi-Select-Komponente.
 * @details Kann wie folgt eingebunden werden:
 *          <phscw-input-multiselect
 *              [editMode]="editMode"
 *              label="ExchangeSync"
 *              name="exchangeSync"
 *              inputId="exchangeSync"
 *              [(ngModel)]="someIdArray"
 *              [selectData]="someSelectDataArray">
 *          </phscw-input-multiselect>
 *          Wichtig zu beachten ist, dass [(ngModel)] (zum aktuellen Zeitpunkt) ein Array-Wert
 *          von Nummern (ids) übergeben werden.
 *          [selectData] müssen die Optionen als SelectData-Array übergeben werden.
 *
 *          Listentries können übergeben werden (z.B. für die Selektion). Da
 *          aber mehrfache Werte zurück kommen könnne sie nicht einfach zurück
 *          ans Backend gesendet werden.
 * @author  Michael Schiffner <m.schiffner@pharmakon.software>
 */
// Angular-Module
import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Interfaces für Structured Objects einbinden
import {SelectData} from '@shared/select-data';
// Globale Services einbinden
import {FloatLabelType} from '@angular/material/form-field';
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';

@Component({
    selector: 'phscw-input-multiselect',
    templateUrl: './input-multiselect.component.html',
    styleUrls: ['./input-multiselect.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputMultiselectComponent),
            multi: true,
        },
    ],
})
export class InputMultiselectComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();
    required = false;
    // EditMode aktiv?
    @Input() editMode = false;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string;
    // Platzhalter (Wird angezeigt, wenn keine Auswahl)
    @Input() placeholder: string;
    // Modus, wann der Platzhalter über dem Feld angezeigt wird
    @Input() floatLabel: FloatLabelType = 'auto';

    // Model "myValue" mit GETTER & SETTER
    private _myValue: number[] = [];
    @Input() get myValue() {
        return this._myValue;
    }

    set myValue(value) {
        this._myValue = value;
        this.propagateChange(this._myValue);
    }

    // Attribut: disabled = Gesperrt (ja / nein)
    @Input() disabled = false;
    // In Ansichtsmodus verstecken, wenn leer?
    @Input() hideEmpty = true;
    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    propagateChange = (_: any) => {};

    /*
     * Optionen, die in Selectbox angezeigt werden.
     * Können von außen von der einbindenden Komponente bereits vorgegeben werden.
     */
    @Input() selectData: SelectData[] = [];

    /*
     * Falls die Komponente Einträge aus "listentries" verwenden soll, wird hier
     * der Listenname angegeben. Beim Initialisieren wird dann selectData
     * über diese Liste gefüllt.
     */
    @Input() listentries = '';

    // Events of mat-select
    @Output() openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param initService
     * @param storageService
     */
    constructor(
        private initService: InitService,
        private storageService: StorageService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Listentries aktualisieren
        this.initListentries();
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Darauf warten, dass alle listentries in der indexedDB gespeichert sind
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            if (result) {
                this.initListentries();
            }
        });
    }

    /**
     * Interface ControlValueAccessor: writeValue
     * @param value
     */
    writeValue(value: any) {
        if (value !== undefined) {
            this.myValue = value;
        }
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}

    /**
     * Falls ein Listentry mit mehrfach-Checkbox abgebildet werden soll
     * die Optionen dafür initialisieren
     */
    initListentries(): any {
        if (!this.listentries || this.listentries === '') {
            return;
        }

        // Daten über Service anfordern
        const promise = this.storageService.getItem('listentries|' + this.listentries);
        // Bei Ankunft der Daten diese in das richtige SelectData-Format bringen
        promise.then((val) => {
            if (Array.isArray(val)) {
                this.selectData = val.map((element) => ({
                    id: element.list_key,
                    label: element.list_value,
                }));
            }
        });
    }
}
