/**
 * @brief   Globaler Service zum Herunterladen von Dateien aus dem Backend
 * @details Ermöglicht das Herunterladen von z.B. durch Module generierte Dateien aus dem Backend
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from './backend.service';
// Shared Komponenten einbinden
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';

@Injectable({providedIn: 'root'})
export class FileDownloadService {
    constructor(
        private backendService: BackendService,
        private dialog: MatDialog,
        private translateService: TranslateService,
    ) {}

    /**
     * @brief   Datei herunterladen
     * @param   {string}  directory  Datei-Pfad
     * @param   {string}  fileName   Datei-Name
     * @returns  {Promise<boolean>}
     * @description Dokument herunterladen
     * @throws {Error} Download-Fehler aufgetreten
     * @returns {Promise<boolean>} Ein Promise, das true zurückgibt, wenn der download erfolgreich ist, sonst false
     */
    downloadFile(directory: string, fileName: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let newTab: any = null;

            // Logik für Apple-Geräte
            if (window.navigator && window.navigator.userAgent.match(/iPhone|iPad|iPod|Macintosh|MacIntel/i)) {
                newTab = window.open();
                if (newTab == null || typeof newTab === 'undefined') {
                    this.dialog.open(PopupMessageComponent, {
                        width: '350px',
                        data: {
                            title: this.translateService.instant('SHARED.POPUP.SAFARI.HEADER'),
                            message: this.translateService.instant('SHARED.POPUP.SAFARI.ALLOWPOPUPS'),
                        },
                    });

                    // Promise auflösen, wenn Popup geblockt wird
                    resolve(false);
                    return;
                }
            }

            // Datei Download
            const fileUrl = directory + fileName;
            const fileRequest$ = this.requestFileData(fileUrl);

            fileRequest$.subscribe({
                next: (result) => {
                    this.openSaveDialog(result, fileName, newTab);
                },
                error: (error) => {
                    console.error(error);
                    resolve(false);
                },
                complete: () => {
                    resolve(true);
                },
            });
        });
    }

    /**
     * @brief   Daten aus Backend laden als Blob
     * @param fileUrl
     * @param   Object  fileUrl  Datei-Pfad
     * @returns  Observable<Blob>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    requestFileData(fileUrl: string): Observable<Blob> {
        // POST-Request über BackendService senden
        const getRequest$: Observable<Blob> = this.backendService.getFile(fileUrl);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Dialog zum Speichern der Datei öffnen
     * @param   Blob    file        Datei als Blob-Objekt
     * @param file
     * @param fileName
     * @param windowReference
     * @param   string  fileName    als Vorschlag anzuzeigender Dateiname
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openSaveDialog(file: Blob, fileName: string, windowReference: any = null): void {
        // Prüfe ob Microsoft Methode verfügbar ist oder nicht
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            /*
             * separater Aufruf für Edge Legacy, da Microsoft noch die eigenen
             * Methoden verwendet hat, bevor Chromium eingebunden wurde
             */
            (window.navigator as any).msSaveOrOpenBlob(file, fileName);
        } else if (window.navigator && window.navigator.userAgent.match(/CriOS|EdgiOS/i)) {
            /*
             * eigenen Code für Apple, da die sich mal wieder nicht an Standards halten
             * wollen und ein Dokument nicht auf die gleiche Weise, wie in allen anderen
             * Betriebssystem- und Browser-Kombinationen, heruntergeladen werden kann.
             */

            // Typ des Blob-Objekts neu festlegen
            file = file.slice(0, file.size, 'application/octet-stream');

            // FileReader API Objekt erzeugen
            const reader = new FileReader();
            reader.onloadend = function () {
                window.open(reader.result as string, 'blank');
            };
            reader.readAsDataURL(file);
        } else if (window.navigator && window.navigator.userAgent.match(/iPhone|iPad|iPod|Macintosh|MacIntel/i)) {
            /*
             * eigenen Code für Apple, da die sich mal wieder nicht an Standards halten
             * wollen und ein Dokument nicht auf die gleiche Weise, wie in allen anderen
             * Betriebssystem- und Browser-Kombinationen, heruntergeladen werden kann.
             */

            // Mime type aus Blob sichern
            const mimetype = file.type;

            // Typ des Blob-Objekts neu festlegen
            file = file.slice(0, file.size, 'application/octet-stream');

            // Link generieren
            const windowUrlObject = webkitURL || window.URL;
            const url = windowUrlObject.createObjectURL(file);

            const a = window.document.createElement('a');

            // Applespezifische erzeugung eines neuen Blob-Objektes um den Filname mitzugeben
            a.href = window.URL.createObjectURL(
                new Blob([file], {type: mimetype + ';charset=UTF-8'}),
            );
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url as string);
            a.remove();
        } else {
            // FileReader API Objekt erzeugen
            const reader = new FileReader();
            reader.onloadend = function () {
                // Link generieren
                const a = document.createElement('a');

                // Attribue ergänzen und Link in Dokument einfügen
                a.setAttribute('style', 'display: none');
                a.href = reader.result as string;
                a.download = fileName;
                document.body.appendChild(a);

                // Link betätigen, um Download auszulösen, und anschließend Link entfernen
                a.click();
                window.URL.revokeObjectURL(reader.result as string);
                a.remove();
            };
            reader.readAsDataURL(file);
        }
    }
}
