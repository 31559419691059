// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Julian Roller <j.roller@pharmakon.software>
*/
:host .cw-dialog {
  background-color: var(--color-primary-light);
  height: calc(100% + 48px);
  margin: 0;
  overflow-y: hidden;
}

:host .cw-dialog-content {
  height: 100%;
}

:host .cw-dialog-details,
:host .cw-flex-row {
  height: 100%;
}

:host .help-details {
  flex: 2;
  height: 100%;
  overflow-y: hidden;
}

:host .help-menu {
  border-right: 1px solid var(--color-primary-lighter);
  flex: 1;
  overflow-y: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-help/global-help-popup/global-help-popup.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,4CAAA;EACA,yBAAA;EACA,SAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ;;AAGA;;EAEI,YAAA;AAAJ;;AAGA;EACI,OAAA;EACA,YAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,oDAAA;EACA,OAAA;EACA,kBAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Julian Roller <j.roller@pharmakon.software>\n*/\n\n:host .cw-dialog {\n    background-color: var(--color-primary-light);\n    height: calc(100% + 48px);\n    margin: 0;\n    overflow-y: hidden;\n}\n\n:host .cw-dialog-content {\n    height: 100%;\n}\n\n:host .cw-dialog-details,\n:host .cw-flex-row {\n    height: 100%;\n}\n\n:host .help-details {\n    flex: 2;\n    height: 100%;\n    overflow-y: hidden;\n}\n\n:host .help-menu {\n    border-right: 1px solid var(--color-primary-lighter);\n    flex: 1;\n    overflow-y: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
