// Angular-Module
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatCellDef, MatColumnDef, MatHeaderCellDef, MatTable} from '@angular/material/table';
// GridComponent
import {GridComponent} from './../grid.component';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Global Services
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';
// Interfaces & Classes
import {SelectData} from './../../../select-data';

const listnames = ['personSpecialization1', 'personSpecialization2', 'personFunction'];

/**
 * @brief   Ausgelagerte Grid-Unterkomponente zur Anzeige von Spezialisierungen von Personen
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
@Component({
    selector: 'phscw-grid-person-specialization-function',
    templateUrl: './grid-person-specialization-function.component.html',
    styleUrls: ['./grid-person-specialization-function.component.scss', './../grid.component.scss'],
})
export class GridPersonSpecializationFunctionComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf Spalte
    @ViewChild(MatColumnDef, {static: true}) columnDef: MatColumnDef;
    @ViewChild(MatHeaderCellDef, {static: true}) headerCellDef: MatHeaderCellDef;
    @ViewChild(MatCellDef, {static: true}) cellDef: MatCellDef;

    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;

    // Spalten-Name der auf die Spalte verweist
    private _name: string;
    @Input()
    set name(name: string) {
        this._name = name;
        if (this.columnDef) {
            this.columnDef.name = name;
        }
    }

    get name(): string {
        return this._name;
    }

    column = {
        columnDef: '',
        formatWidth: '200px',
    };

    JSON = undefined;

    // Listentries die zur Darstellung benötigt werden. Um nicht mehr mit jedem input-select-Aufruf die listentries neu zu Laden
    public lists: any = {};

    /**
     * Konstruktor (inkl. dependency injection)
     * @param table
     * @param storageService
     * @param initService
     */
    constructor(
        public table: MatTable<any>,
        private storageService: StorageService,
        private initService: InitService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        if (this.table) {
            this.column.columnDef = this._name;
            this.columnDef.name = this._name;
            this.columnDef.headerCell = this.headerCellDef;
            this.columnDef.cell = this.cellDef;
            this.table.addColumnDef(this.columnDef);
        }
        // Initialisiere Lists für evtl. Darstellung von Listentries im Grid
        this.initializeListentries();

        // Darauf warten, dass alle listentries in der indexedDB gespeichert sind
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            // Abbruch, falls Anfrage erfolglos war
            if (result === false) {
                return;
            }
            this.initializeListentries();
        });
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        if (this.table) {
            this.table.removeColumnDef(this.columnDef);
        }
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Die Listentries für Grideinträge mit Listen aus der IndexedDB laden.
     */
    initializeListentries(): void {
        for (const list of listnames) {
            const promise = this.storageService.getItem('listentries|' + list);
            promise.then((val) => this.onGetListentriesFromStorage(list, val));
        }
    }

    /**
     * Listentry-Daten wurden geladen
     * @param listname
     * @param storageData
     */
    onGetListentriesFromStorage(listname: string, storageData: any): void {
        // Initialisierung dieser Komponente war schneller als das Laden der Listentries
        if (storageData == null) {
            return;
        }

        // Daten aus Storage vorhanden
        if (storageData) {
            /*
             * Wenn listentries geladen werden sollen für die Anzeige von Listentry-Einträgen im Grid:
             * Schlüssele die Listen Daten in das SelectData-Format um, um diese dann an die input-Select-Komponenten zu übergeben.
             */
            const transcodedListData = [];
            for (const obj of storageData) {
                const newObject: SelectData = {
                    id: 0,
                    label: '',
                };
                newObject.id = obj.list_key;
                newObject.label = obj.list_value;
                transcodedListData.push(newObject);
            }
            // Lade Listen in ein Object mit dem Listennamen als Key
            this.lists[listname] = transcodedListData;
        }
    }
}
