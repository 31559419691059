// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  /*    display: block;
  overflow: auto;*/
  transition: min-width 0.5s linear, width 0.5s linear;
}

:host .cw-ticket-send-mail-save-message {
  align-self: center;
  color: var(--color-accent);
  opacity: 1;
}

:host .cw-ticket-send-mail-save-message + button {
  margin-left: 20px;
}

:host .cw-ticket-send-mail-save-message.hidden {
  opacity: 0;
  transition: opacity 3s linear;
}

:host .due-date-countdown {
  font-size: 1.4rem;
  margin-left: 80px;
  max-width: 300px;
}

:host .due-date-countdown > span {
  margin-left: 5px;
}

:host .markedField {
  border: solid 1px var(--color-accent);
}

:host ::ng-deep .forwardmail.ng-invalid .cw-input-label {
  align-self: baseline;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/contacts/contacts-form/contacts-form-ticket/contacts-form-ticket.component.scss"],"names":[],"mappings":"AAAA;EACI;kBAAA;EAEA,oDACI;AAAR;;AAGA;EACI,kBAAA;EACA,0BAAA;EACA,UAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ;;AAGA;EACI,UAAA;EACA,6BAAA;AAAJ;;AAGA;EACI,iBAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ;;AAGA;EACI,qCAAA;AAAJ;;AAGA;EACI,oBAAA;AAAJ","sourcesContent":[":host {\n    /*    display: block;\n    overflow: auto;*/\n    transition:\n        min-width 0.5s linear,\n        width 0.5s linear;\n}\n:host .cw-ticket-send-mail-save-message {\n    align-self: center;\n    color: var(--color-accent);\n    opacity: 1;\n}\n\n:host .cw-ticket-send-mail-save-message + button {\n    margin-left: 20px;\n}\n\n:host .cw-ticket-send-mail-save-message.hidden {\n    opacity: 0;\n    transition: opacity 3s linear;\n}\n\n:host .due-date-countdown {\n    font-size: 1.4rem;\n    margin-left: 80px;\n    max-width: 300px;\n}\n\n:host .due-date-countdown > span {\n    margin-left: 5px;\n}\n\n:host .markedField {\n    border: solid 1px var(--color-accent);\n}\n\n:host ::ng-deep .forwardmail.ng-invalid .cw-input-label {\n    align-self: baseline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
