// Definition der möglichen Modi für die Darstellung von Zellen
export const COLUMN_DECIMAL = 'decimal'; // Dezimalzahlen
export const COLUMN_INTEGER = 'integer'; // Integer
export const COLUMN_HTML = 'html'; // HTML-Inhalte
export const COLUMN_ICON = 'icon'; // Font-Awesome-Icons / SVGs
export const COLUMN_DATE = 'date'; // Datumsangaben
export const COLUMN_CURRENCY = 'currency'; // Währungen
export const COLUMN_COMPONENT = 'component'; // Darstellung von eigenen Komponenten - dafür ist die Angabe der "component" der TabelColumn notwendig
export const COLUMN_LISTENTRY = 'listentry'; // Listentries
export const COLUMN_TAG = 'tag'; // Farbige tags um listentries farbig darzustellen
export const COLUMN_PERCENTAGE = 'percentage';
export const COLUMN_ACTION = 'action';

// Typdefinition für TableColumnMode, um die möglichen Werte einzuschränken
export type TableColumnMode =
  | typeof COLUMN_DECIMAL
  | typeof COLUMN_INTEGER
  | typeof COLUMN_HTML
  | typeof COLUMN_ICON
  | typeof COLUMN_DATE
  | typeof COLUMN_CURRENCY
  | typeof COLUMN_COMPONENT
  | typeof COLUMN_TAG
  | typeof COLUMN_PERCENTAGE
  | typeof COLUMN_ACTION
  | typeof COLUMN_LISTENTRY;
