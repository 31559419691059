import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatCellDef, MatColumnDef, MatHeaderCellDef, MatTable} from '@angular/material/table';
// GridComponent
import {GridComponent} from './../grid.component';
// interfaces for structured objects
import {LooseObject} from '@shared/loose-object';

/**
 * Ausgelagerte Grid-Unterkomponente zur Anzeige von Kontakt-Typen mit Titel
 * @author Michael Schiffner <m.schiffner@pharmakon.software>
 */
@Component({
    selector: 'phscw-grid-contact-type',
    templateUrl: './grid-contact-type.component.html',
    styleUrls: ['./grid-contact-type.component.scss', './../grid.component.scss'],
})
export class GridContactTypeComponent implements OnInit, OnDestroy {
    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;

    @ViewChild(MatColumnDef, {static: true}) columnDef: MatColumnDef;
    @ViewChild(MatHeaderCellDef, {static: true}) headerCellDef: MatHeaderCellDef;
    @ViewChild(MatCellDef, {static: true}) cellDef: MatCellDef;

    // Spalten-Name der auf die Spalte verweist
    @Input()
    get name(): string {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
        if (this.columnDef) {
            this.columnDef.name = name;
        }
    }

    _name: string;

    constructor(public table: MatTable<any>) {}

    ngOnInit() {
        if (this.table) {
            this.columnDef.name = this._name;
            this.columnDef.headerCell = this.headerCellDef;
            this.columnDef.cell = this.cellDef;
            this.table.addColumnDef(this.columnDef);
        }
    }

    ngOnDestroy() {
        if (this.table) {
            this.table.removeColumnDef(this.columnDef);
        }
    }

    /*
     *  get icon of contact type
     */
    getContactType(element: LooseObject): string {
        let returnValue = 'icon-';

        if (element.contact_type == 'scheduled') {
            return 'scheduled';
        }

        if (element.contact_method === 'approvedMail') {
            if (element.mailing_opened) {
                returnValue += 'envelope-open';
            } else {
                returnValue += 'envelope';
            }
        } else {
            returnValue += element.contact_type;
        }

        return returnValue;
    }

    /*
     *  check conditions for green color of element
     */
    isHighlightGreen(element: LooseObject): boolean {
        const taskDone = element.contact_type == 'task' && element.done == 'Y';
        const mailingSent = element.contact_method === 'approvedMail' && element.mailing_status === 'sent';
        const mailingOpened = element.contact_method === 'approvedMail' && element.mailing_opened;

        return taskDone || (mailingSent && mailingOpened);
    }

    /*
     *  check conditions for yellow color of element
     */
    isHighlightYellow(element: LooseObject): boolean {
        const mailingSent = element.contact_method === 'approvedMail' && element.mailing_status === 'sent';
        const mailingNotOpened = element.contact_method === 'approvedMail' && !element.mailing_opened;

        return mailingSent && mailingNotOpened;
    }

    /*
     *  check conditions for red color of element
     */
    isHighlightRed(element: LooseObject): boolean {
        const taskNotDone = element.contact_type == 'task' && element.done == 'N';
        const mailingNotSent = element.contact_method === 'approvedMail' && element.mailing_status !== 'sent';

        return taskNotDone || mailingNotSent;
    }
}
