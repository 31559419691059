import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {HighchartsChartModule} from 'highcharts-angular';
import {ChartAccordionDemoComponent} from './chart-accordion-demo/chart-accordion-demo.component';
import {ChartAccordionComponent} from './chart-accordion/chart-accordion.component';
import {ChartInventoryDemandComponent} from './chart-inventory-demand/chart-inventory-demand.component';
import {ChartProductsSalesComponent} from './chart-products-sales/chart-products-sales.component';
import {ChartTargetsEmployeeComponent} from './chart-targets-employee/chart-targets-employee.component';
import {ChartTargetsProductsComponent} from './chart-targets-products/chart-targets-products.component';
import {ChartTargetsRegionsComponent} from './chart-targets-regions/chart-targets-regions.component';
import {ChartTargetsVisitsFrequencyComponent} from './chart-targets-visits-frequency/chart-targets-visits-frequency.component';
import {ChartTopFiveWorstFiveComponent} from './chart-top-five-worst-five/chart-top-five-worst-five.component';
import {ChartYearlyCountVisitsComponent} from './chart-yearly-count-visits/chart-yearly-count-visits.component';
import {ChartYearlySalesComponent} from './chart-yearly-sales/chart-yearly-sales.component';
import {ChartService} from './chart.service';
import {ChartComponent} from './chart/chart.component';

// CDK & Angular Material
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';

import {GridModule} from '@shared/grid/grid.module';
import {ChartTopProductsComponent} from './chart-top-products/chart-top-products.component';

import {FormsModule} from '@angular/forms';
import {ChartAccordionDetailsComponent} from './chart-accordion/chart-accordion-details/chart-accordion-details.component';
import {ChartAccordionPanelComponent} from './chart-accordion/chart-accordion-panel/chart-accordion-panel.component';
import {ChartSalesPerMonthComponent} from './chart-sales-per-month/chart-sales-per-month.component';
import {ChartSalesPerProductComponent} from './chart-sales-per-product/chart-sales-per-product.component';
import {ChartTargetsDistributionComponent} from './chart-targets-distribution/chart-targets-distribution.component';
import {ChartTargetsVisitsCustomerClassificationComponent} from './chart-targets-visits-customer-classification/chart-targets-visits-customer-classification.component';
import {ChartTopCustomerComponent} from './chart-top-customer/chart-top-customer.component';

import {InputModule} from '../../shared/input/input.module';
import {ChartAverageCountVisitsTeamViewComponent} from './chart-average-count-visits-team-view/chart-average-count-visits-team-view.component';
import {ChartContactFrequencyComponent} from './chart-contact-frequency/chart-contact-frequency.component';
import {ChartMonthlyAverageCountVisitsComponent} from './chart-monthly-average-count-visits/chart-monthly-average-count-visits.component';
import {ChartQuarterlySalesComponent} from './chart-quarterly-sales/chart-quarterly-sales.component';
import {ChartTargetAchievementVisitsComponent} from './chart-target-achievement-visits/chart-target-achievement-visits.component';
import {ChartWeeklyCountVisitsComponent} from './chart-weekly-count-visits/chart-weekly-count-visits.component';

import {ChartConductedTrainingsComponent} from './chart-conducted-trainings/chart-conducted-trainings.component';
import {ChartDistributionTargetsProductSalesComponent} from './chart-distribution-targets-product-sales/chart-distribution-targets-product-sales.component';
import {ChartQuarterlySalesTargetAchievementComponent} from './chart-quarterly-sales-target-achievement/chart-quarterly-sales-target-achievement.component';
import {ChartTargetsCustomerContactsComponent} from './chart-targets-customer-contacts/chart-targets-customer-contacts.component';
import {ChartAmountCharacteristicsComponent} from './chart-amount-characteristics/chart-amount-characteristics.component';

@NgModule({
    declarations: [
        ChartComponent,
        ChartYearlyCountVisitsComponent,
        ChartYearlySalesComponent,
        ChartProductsSalesComponent,
        ChartTargetsProductsComponent,
        ChartAccordionComponent,
        ChartAccordionDemoComponent,
        ChartTargetsEmployeeComponent,
        ChartTargetsRegionsComponent,
        ChartTargetsVisitsFrequencyComponent,
        ChartTopFiveWorstFiveComponent,
        ChartTopProductsComponent,
        ChartTopCustomerComponent,
        ChartSalesPerProductComponent,
        ChartSalesPerMonthComponent,
        ChartTargetsDistributionComponent,
        ChartTargetsVisitsCustomerClassificationComponent,
        ChartAccordionPanelComponent,
        ChartAccordionDetailsComponent,
        ChartInventoryDemandComponent,
        ChartWeeklyCountVisitsComponent,
        ChartQuarterlySalesComponent,
        ChartMonthlyAverageCountVisitsComponent,
        ChartContactFrequencyComponent,
        ChartTargetAchievementVisitsComponent,
        ChartAverageCountVisitsTeamViewComponent,
        ChartQuarterlySalesTargetAchievementComponent,
        ChartDistributionTargetsProductSalesComponent,
        ChartTargetsCustomerContactsComponent,
        ChartConductedTrainingsComponent,
        ChartInventoryDemandComponent,
        ChartAmountCharacteristicsComponent,
    ],
    imports: [
        CommonModule,
        // ChartModuleHighcharts,
        HighchartsChartModule,
        GridModule,
        // CDK & Angular-Material
        MatExpansionModule,
        MatDividerModule,
        MatListModule,

        FormsModule,
        InputModule,
    ],
    exports: [
        ChartComponent,
        ChartYearlyCountVisitsComponent,
        ChartYearlySalesComponent,
        ChartProductsSalesComponent,
        ChartTargetsProductsComponent,
        ChartAccordionComponent,
        ChartAccordionDemoComponent,
        ChartTargetsEmployeeComponent,
        ChartTargetsRegionsComponent,
        ChartTargetsVisitsFrequencyComponent,
        ChartTopFiveWorstFiveComponent,
        ChartTopCustomerComponent,
        ChartTopProductsComponent,
        ChartSalesPerProductComponent,
        ChartSalesPerMonthComponent,
        ChartTargetsDistributionComponent,
        ChartTargetsVisitsCustomerClassificationComponent,
        ChartInventoryDemandComponent,
        ChartQuarterlySalesTargetAchievementComponent,
        ChartDistributionTargetsProductSalesComponent,
        ChartTargetsCustomerContactsComponent,
        ChartConductedTrainingsComponent,
        ChartWeeklyCountVisitsComponent,
        ChartQuarterlySalesComponent,
        ChartMonthlyAverageCountVisitsComponent,
        ChartContactFrequencyComponent,
        ChartTargetAchievementVisitsComponent,
        ChartAverageCountVisitsTeamViewComponent,
        ChartAmountCharacteristicsComponent,
    ],
    providers: [
        // { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting, solidgauge ] } ,
        ChartService,
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'de-DE',
        },
    ],
})
export class ChartModule {}
