import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-target-achievement-visits',
    templateUrl: './chart-target-achievement-visits.component.html',
    styleUrls: ['./chart-target-achievement-visits.component.scss'],
})
export class ChartTargetAchievementVisitsComponent implements OnInit {
    @Input() timeHorizon: any = [
        {
            id: 1,
            label: 'Woche',
            value: 'rollingWeek',
        },
        {
            id: 2,
            label: 'Monat',
            value: 'month',
        },
        {
            id: 3,
            label: 'Tertial',
            value: 'tertial',
        },
        {
            id: 4,
            label: 'Geschäftsjahr',
            value: 'fiscalYear',
        },
        {
            id: 5,
            label: 'Kalenderjahr',
            value: 'currentYear',
        },
    ];

    @Input() backendOptions: any = {selectedTimeHorizon: 'rollingWeek'};

    constructor() {}

    ngOnInit() {}

    /*
     * setzt den gewählten Zeitraum
     */
    selectTimeHorizon(selectedTimeHorizon) {
        this.backendOptions = {selectedTimeHorizon};
    }
}
