// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .cw-input-span {
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/output/output-timestamp/output-timestamp.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ","sourcesContent":[":host .cw-input-span {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
