// Komentar, weil am Filebeginn immer n Fehler ist und es mich irritiert, wenn der auf echtem code liegt
import {Component, OnDestroy, OnInit} from '@angular/core';
// Globale Services einbinden
import {UserPermissionsService} from './../../../services/user-permissions.service';
// Services
import {GlobalHelpService} from './../global-help.service';
// Interfaces für Structured Objects einbinden
import {CWResult} from '@shared/cw-result';

@Component({
    selector: 'phscw-global-help-search',
    templateUrl: './global-help-search.component.html',
    styleUrls: ['./global-help-search.component.scss'],
})
export class GlobalHelpSearchComponent implements OnInit, OnDestroy {
    // Flag definiert, ob die Suche benutzt werden darf
    allowGlobalHelpSearch = false;

    // Läuft gerade ein Suchvorgang?
    searchInProgress = false;

    // Suchbegriff
    searchInput = '';

    /**
     * Konstruktor (inkl. dependency injection)
     * @param userPermissions
     * @param globalHelpService
     */
    constructor(
        private userPermissions: UserPermissionsService,
        private globalHelpService: GlobalHelpService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Berechtigungen prüfen
        this.checkAllowGlobalHelpSearch();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * @brief   Setze allowGlobalHelpSearch-Variable aus den Userpermissions
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkAllowGlobalHelpSearch(): void {
        const permissionAllowGlobalHelpSearch: boolean =
            this.userPermissions.getPermissionValue('allowGlobalHelpSearch');
        this.allowGlobalHelpSearch = permissionAllowGlobalHelpSearch;
    }

    /**
     * @brief   Suche auslösen
     * @details Löst bei validem Suchwort ein eventListChange im GlobalHelpService aus.
     *          Ein valides Suchwort ist ein String mit mindestens einer Länge von 3.
     *          Wird aufgerufen in
     *          - Click-Listener auf Icon,
     *          - keyup.enter-Listener auf Input
     *          - und für Apple auch in keyup.shift.enter-Listener (https://github.com/angular/angular/issues/32963#issuecomment-537928154)
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    startSearch(): void {
        // Abbruch, falls kein Suchbegriff eingegeben wurde
        if (!this.searchInput) {
            return;
        }
        // Falls gerade eine Suche läuft, kann keine erneute Suche gestartet werden
        if (this.searchInProgress) {
            return;
        }
        // Falls weniger als drei Zeichen eingegeben wurden, wird keine Suche gestartet
        if (this.searchInput.length < 3) {
            return;
        }

        // Flag setzen
        this.searchInProgress = true;
        // Event HelpSearch auslösen
        const request = this.globalHelpService.search(this.searchInput);
        request.subscribe(
            (result: CWResult) => {
                // Antwort vom Backend erhalten - Flag ausschalten
                this.searchInProgress = false;
                // Suchbegriff resetten
                this.searchInput = '';
            },
            (error: any) => {
                // Flag ausschalten
                this.searchInProgress = false;
            },
        );
    }
}
