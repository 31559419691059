// Angular-Module
import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-accordion',
    templateUrl: './chart-accordion.component.html',
    styleUrls: ['./chart-accordion.component.scss'],
})
export class ChartAccordionComponent implements OnInit {
    // Erlaubt das gleichzeitige Aufklappen mehrerer Expansions
    @Input() multi = false;

    // Definition der Accordion-Inhalte
    panels: any[] = [
        {
            title: 'Veränderungen Kunden',
            link: true,
            confirmation: true,
            children: [
                {
                    date: '10.02.2020',
                    id: 48178,
                    text: 'Rathaus Apotheke Herr Peter Dorfner e.K., Neumarkt',
                    confirmation: true,
                    link: true,
                },
                {
                    date: '10.02.2020',
                    id: 5822,
                    text: 'Adler-Apotheke Dr. Jürgen Schulz, Hilden',
                    confirmation: true,
                    link: true,
                },
                {
                    date: '10.02.2020',
                    id: 18059,
                    text: 'Mc Medi Apotheke, Schkeuditz',
                    confirmation: true,
                    link: true,
                },
            ],
        }, /*        {            title: 'Monatsberichte',
            icon: 'icon-comments',
            link: true,
            confirmation: true,
            children: [
                {date: '28.01.2020', text: 'Dr. Helmut Müller', confirmation: true, link: true},
            ]
        },
        {
            title: 'Aufträge',
            icon: 'icon-comments',
            link: true,
            confirmation: true,
            children: [
                {date: '17.06.2020', text: 'Bärenapotheke', confirmation: true, link: true},
                {date: '01.03.2020', text: 'Ludwigsapotheke', confirmation: true, link: true},
            ],
            panels: [{
                title: 'Unter Aufträge',
                icon: 'icon-cogs',
                link: true,
                confirmation: true,
                children: [
                    {date: '17.06.2020', text: 'Bärenapotheke',confirmation: true, link: true},
                ],
            }, {
                title: 'Unter Aufträge 2',
                icon: 'icon-cogs',
                link: true,
                confirmation: true,
                children: [
                    {date: '17.06.2020', text: 'Bärenapotheke',confirmation: true, link: true},
                ],
            }]

        } */
    ];

    // Konstruktor
    constructor() {}

    // Initialisierungen
    ngOnInit() {}
}
