// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service dieses Feature-Moduls
import {InstitutionsService} from '../institutions.service';
// Shared Services
import {StorageService} from '@global/services/storage.service';
import {CharacteristicsService} from '@shared/characteristics/characteristics.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {InitService} from '@global/services/init.service';
import {CWEvent} from '@shared/cw-event';

// Konstante für den Kennzeichen-Typ
const CHARACTERISTIC_TYPE = 'institution';

@Component({
    selector: 'phscw-institutions-characteristics',
    templateUrl: './institutions-characteristics.component.html',
    styleUrls: ['./institutions-characteristics.component.scss'],
})
export class InstitutionsCharacteristicsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;

    // Erlaubt das gleichzeitige Aufklappen mehrere Expansion
    @Input() multi = true;
    // Kennzeichen-Typ
    characteristicType: string = CHARACTERISTIC_TYPE;

    // Kennzeichen-Öffnen-Status
    @Input() characteristicsOpenState = false;
    ignoreAllowEditCombined = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param institutionsService
     * @param toolbarService
     * @param characteristicsService
     * @param storageService
     * @param initService
     */
    constructor(
        public institutionsService: InstitutionsService,
        private toolbarService: ToolbarService,
        private characteristicsService: CharacteristicsService,
        private storageService: StorageService,
        private initService: InitService,
    ) {}

    /**
     * @brief   Initialisieren
     * @details Höre auf Institution-SelectionChanged
     *          Hole Kennzeichengruppen aus der Session.
     */
    ngOnInit() {
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Auf geänderte Auswahl reagieren
        this.institutionsService.selectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: number) => {
                this.onSelectionChanged(result);
            });

        this.toolbarService.eventToolbarButtonClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event = result;
                // Abbruch, falls Anfrage erfolglos war
                if (event.target !== 'institutionsCharacteristics') {
                    return;
                }
                this.characteristicsService.expandCharacteristicsGroupsList(
                    'institutionsCharacteristics',
                    result.data['openState'],
                );
            });
        // Alle Daten beim Login wurden in den Storage geladen
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            if (result) {
                this.loadFrontendConfiguration();
            }
        });

        // Leider doppelt notwendig, wegen timing und f5 problemen
        this.loadFrontendConfiguration();
    }

    /**
     * @param id
     * @brief  Setzt Einrichtungs-ID bei Änderungen in der Einrichtungs-Liste
     */
    onSelectionChanged(id: number): void {
        // ID zwischenspeichern
        this.institutionId = id;

        // Daten neuladen
        this.characteristicsService.reloadCharacteristicsGroupsList('institutions-characteristics');
    }

    /**
     * @brief   Laden den Frontend Config
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    loadFrontendConfiguration(): void {
        const promise = this.storageService.getItem('config|characteristicsIgnoreAllowEditCombined');
        promise.then((value) => {
            this.ignoreAllowEditCombined = value;
        });
    }
}
