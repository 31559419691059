import {Pipe, PipeTransform} from '@angular/core';
// Interfaces für Structured Objects einbinden
import {SelectData} from './../select-data';

/**
 * @brief   Diese Pipe ist für die Darstellung der Werte der Input-Multiselect-Komponente verantwortlich.
 * @author  Michael Schiffner <m.schiffner@pharmakon.software>
 */

@Pipe({name: 'showMultiSelectValue'})
export class ShowMultiSelectValuePipe implements PipeTransform {
    /**
     * @brief   transform-Funktion von PipeTransform implementieren
     * @details Nimmt alle möglichen Multi-Select-Einträge und die Ids der gewählten
     * @param selectData
     * @param ids
     *          Einträge entgegen, durchläuft sie und verbindet die Labels zu einem gesamt-String
     * @param   SelectData[]   selectData   Die Auswahlmöglichkeiten des Multi-Selects
     * @param   number[]       ids          Die Ids der gewählten Einträge in einem Array
     * @returns  string
     */
    transform(selectData: SelectData[], ids: number[]): string {
        // Initialisiere den Rückgabe-String
        let resultLabel = '';

        // for each error abgefangen durch early return
        if (ids == null || ids == undefined || !Array.isArray(ids)) {
            return '';
        }

        // Durchlaufe das ids-Array
        ids.forEach((id) => {
            // Suche in dem SelectData-Array nach dem Object mit der entspr. ID
            const selectObject = selectData.find((x) => x.id === id);
            // Wenn das Object gefunden wurde ...
            if (typeof selectObject !== 'undefined') {
                // ... erweitere das Rückgabelabel um das Label des Objects/Eintrags
                resultLabel += selectObject.label + ', ';
            }
        });
        // Strippe das letze Komma und Leerzeichen.
        return resultLabel.substring(0, resultLabel.length - 2);
    }
}
