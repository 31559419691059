// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class GlobalHelpRolesService {
    /**
     * Konstruktor (inkl. dependency injection)
     * @param backendService
     */
    constructor(private backendService: BackendService) {}

    /**
     * @brief   Zugeordnete Rollen eines Eintrags laden
     * @param id
     * @param page
     * @param   number   id   Eintrags-ID
     * @returns  Observable<any>
     */
    loadData(id: number, page: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('HelpRoles/index/' + id + '/' + page);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Einzelne Rollen-Zuordnung laden
     * @param   number  id       Eintrags-ID
     * @param id
     * @param roleId
     * @param   number  roleId   Rollen-ID
     * @returns  Observable<any>
     */
    loadDetails(id: number, roleId: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('HelpRoles/details/' + id + '/' + roleId);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Holt die noch nicht zugeordneten Rollen für diesen Eintrag
     * @param id
     * @param   number   id   Eintrags-ID
     * @returns  Observable<Objecct>
     */
    getUnusedRoles(id: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('HelpRoles/getUnusedRoles/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Speichert Rollen-Zuordnung
     * @param   number   id         Eintrags-ID
     * @param   number   roleId     Rollen-ID
     * @param id
     * @param roleId
     * @param formData
     * @param   any      formData   Geänderte Daten
     * @returns  Observable<any>
     */
    saveData(id: number, roleId: number, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'HelpRoles/edit/' + id + '/' + roleId,
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Löscht Rollen-Zuordnung
     * @param   number   id         Eintrags-ID
     * @param id
     * @param roleId
     * @param   number   roleId     Rollen-ID
     * @returns  Observable<any>
     */
    deleteData(id: number, roleId: number): Observable<any> {
        // POST-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('HelpRoles/delete/' + id + '/' + roleId);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }
}
