import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
// CDK & Angular Material
import {CdkTableModule} from '@angular/cdk/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatTableModule} from '@angular/material/table';
import {TranslateModule} from '@ngx-translate/core';
// Eigene Komponenten
import {OrderFormFooterComponent} from './order-form/order-form-footer/order-form-footer.component';
import {OrderFormHeaderComponent} from './order-form/order-form-header/order-form-header.component';
import {OrderFormItemsComponent} from './order-form/order-form-items/order-form-items.component';
import {OrderFormComponent} from './order-form/order-form.component';
// Shared Modules einbinden
import {GridModule} from '@shared/grid/grid.module';
import {InputModule} from '@shared/input/input.module';
import {OutputModule} from '@shared/output/output.module';
import {SubmodulesMenuModule} from '@shared/submodules-menu/submodules-menu.module';
import {ToolbarModule} from '@shared/toolbar/toolbar.module';

@NgModule({
    declarations: [OrderFormComponent, OrderFormHeaderComponent, OrderFormFooterComponent, OrderFormItemsComponent],
    exports: [OrderFormComponent, OrderFormHeaderComponent, OrderFormFooterComponent, OrderFormItemsComponent],
    imports: [
        CommonModule,
        TranslateModule,
        GridModule,
        ToolbarModule,
        InputModule,
        OutputModule,
        SubmodulesMenuModule,
        FormsModule,
        ReactiveFormsModule,
        CdkTableModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatTableModule,
    ],
})
export class OrderFormModule {}
