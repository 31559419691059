// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ==========
 *
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
*/
:host {
  font-size: 1.4rem;
  height: 60px;
  min-height: 60px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-header/global-header.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;AAAJ","sourcesContent":["/**\n * 1. Allgemein\n * ==========\n *\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n*/\n\n:host {\n    font-size: 1.4rem;\n    height: 60px;\n    min-height: 60px;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
