// Angular-Module
import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
// Angular-Material-Module
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {Moment} from 'moment';

// Moment-Modul zur Datums-Verarbeitung
import * as _moment from 'moment';

const moment = _moment;

// Format-Konstante für den export von Datumsdaten zur Datenbank.
export const MOMENT_DB_FORMAT = 'YYYY-DD-MM';
// Format-Konstante für das Anzeigeformat von Datum
export const MOMENT_DISPLAY_FORMAT = 'DD.MM.YYYY';

/*
 * See the Moment.js docs for the meaning of these formats:
 * https://momentjs.com/docs/#/displaying/format/
 */
export const MY_FORMATS = {
    parse: {dateInput: 'DD.MM.YYYY'},
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM Y',
        dateA11yLabel: 'DD.MM.Y',
        monthYearA11yLabel: 'MMM YY',
    },
};

@Component({
    selector: 'phscw-input-date',
    templateUrl: './input-date.component.html',
    styleUrls: ['./input-date.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputDateComponent),
            multi: true,
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_FORMATS,
        },
    ],
})
export class InputDateComponent implements OnInit, OnDestroy {
    // EditMode aktiv?
    @Input() editMode = false;
    // Komponente deaktiviert?
    @Input() disabled = false;
    // Sollen Texteingaben in das Datumsfeld unterbunden werden?
    @Input() inputReadonly = false;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string;
    // Wochentag als String anzeigen
    @Input() showWeekday = false;
    // Maximales Datum, bis wann Daten im Kalender-Tool ausgewählt werden dürfen
    @Input() max;
    // Kleinstes Datum bis wann Daten im Kalender-Tool ausgewählt werden dürfen
    @Input() min;
    // Emitter zum benachrichtigen von Parent-Komponenten über Klicks
    @Output() clickEvent = new EventEmitter<boolean>();

    // Soll der Löschbutton angezeigt werden
    @Input() showDeleteButton = false;

    // Sollen Pfeiltasten & Today-Button  angezeigt werden
    @Input() showDateSwitchButtons = false;

    // In Ansichtsmodus verstecken, wenn leer?
    @Input() hideEmpty = true;

    @Input() highlightedDays: number[] = [0, 1, 2, 3, 4, 5, 6];
    @Input() highlightDates = false;

    // WEnn Input innerhalb eines Grid benutzt wird
    @Input() dateInGrid = false;

    // Model "dateValue" mit GETTER & SETTER
    _dateValue = null;
    // Variable zum Anzeigen von Formatierungs-Fehlern
    showError = false;

    // Getter dateValue
    get dateValue() {
        return this._dateValue;
    }

    // Setter dateValue
    @Input() set dateValue(value) {
        if (value === null) {
            this.showError = false;
            this._dateValue = value;
            this.propagateChange(this._dateValue);
        } else if (!moment.isMoment(value)) {
            this.showError = false;
            this._dateValue = moment(value);
            this.propagateChange(this._dateValue);
        } else if (moment.isMoment(value) && !value.isValid()) {
            this.showError = true;
        } else {
            this._dateValue = value;
            this.showError = false;
            this.propagateChange(this._dateValue);
        }
    }

    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    propagateChange = (_: any) => {};

    // Attribut: required = Pflichtfeld (ja / nein)
    @Input() required = false;

    // Event-Emitter, falls der Wert gelöscht wurde
    @Output() deleteClicked = new EventEmitter<any>();

    /**
     * Konstruktor (inkl. dependency injection)
     */
    constructor() {}

    /**
     * Initialisieren
     */
    ngOnInit() {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * Interface ControlValueAccessor: writeValue
     * @param value
     */
    writeValue(value: any) {
        if (value !== undefined) {
            this.dateValue = value;
        }
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}

    /**
     * Formatiere Daten in ein deutsches Datumsformat um sie Anzuzeigen. Ausnahme leere Werte
     * @param date
     */
    parse(date: any): any {
        if (date !== null && moment.isMoment(date)) {
            return date.format(MOMENT_DISPLAY_FORMAT);
        } if (date !== null) {
            return moment(date).format(MOMENT_DISPLAY_FORMAT);
        }
        return null;
    }

    /**
     * Emittiert ein Event, das eigentlich besagt, dass das Datum geändert wurde.
     * Wird aber genutzt, um den Parent auf Klicks auf den Datepicker aufmerksam zu machen
     * Kleiner Hack, da Datepicker Click-Event überschreibt.
     */
    emitClick(): void {
        this.clickEvent.emit(true);
    }

    /**
     * Lösche den Datums-Wert
     */
    deleteValue(): void {
        this.dateValue = null;
        this.deleteClicked.emit();
    }

    /**
     * Setzt Datum ein Tag früher/später oder auf heute
     * Hier wird Date-Funktion verwendet, da ohne die wird das Datum in Anzeige nicht aktualisiert.
     * Evtl. später wird eine schönere Lösung gefunden
     * @param direction
     */
    setDate(direction: string): void {
        // nicht ausführen, wenn das Element deaktiviert ist
        if (this.disabled) {
            return;
        }

        // Richtung auswählen
        switch (direction) {
            // Einen Tag früher
            case 'left':
                this.dateValue = new Date(this.dateValue.add(-1, 'days'));
                break;

            // Einen Tag später
            case 'right':
                this.dateValue = new Date(this.dateValue.add(1, 'days'));
                break;

            // Heute
            case 'today':
            default:
                this.dateValue = moment();
                break;
        }

        // Seite neu laden
        this.clickEvent.emit(true);
    }

    /**
     * Klasse für Material-Date-Picker-Kalendar um bestimmten Tagen eine eigene
     * CSS-Klasse zu geben
     *
     * https://v7.material.angular.io/components/datepicker/overview#highlighting-specific-dates
     *
     * Die Klasse ist in der globalen styles.scss definiert. (Funktioniert nicht
     * in lokaler scss-Datei)
     *
     * Für neue Material-Versionen min. 10+ kann auch der View des Kalendars
     * abgefragt werden, damit das nur in der Monatsansicht genutzt wird.
     * @param d
     */
    markDays = (d: Moment) => {
        const date = d.weekday();
        // Alle Tage ausgrauen / mit Custom-Klasse belegen, die nicht in den definierten Wochentagen liegen.
        return !this.highlightedDays.includes(date) ? 'cw-calendar-greyout-days' : '';
    }; // end function markDays
}
