// Angular-Module
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
import {InstitutionsListService} from '@modules/institutions/institutions-list/institutions-list.service';

// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';

@Injectable({providedIn: 'root'})
export class GlobalSearchService {
    /*
     * Subject (Observable) definieren "Suche abgeschlossen"
     * @Output() eventSearchComplete: EventEmitter<any> = new EventEmitter();
     */
    public eventSearchStarted = new Subject<CWEvent>();
    public eventSearchComplete = new Subject<CWEvent>();

    // Konstruktor
    constructor(
        private router: Router,
        private backendService: BackendService,
        private institutionsListService: InstitutionsListService,
    ) {}

    // Suche starten
    startSearch(searchInput: string): Observable<unknown> {
        // Such-Kontext hängt in dieser (einfachen) Suche vom aktuellen Modul ab
        let searchContext = this.router.url;

        // Entfernen der Ids , da das routing jetzt auch /people/1579213 zulässt
        const stringArray = searchContext.split('/');
        const lastElement = stringArray.pop();
        if (!isNaN(parseInt(lastElement))) {
            searchContext = stringArray.join('/');
        }

        // Aktuelles Modul definiert Backend-Controller
        const map = {
            '/people': 'People',
            '/b2c': 'People',
            '/institutions': 'Institutions',
            '/events': 'Events',
            '/events/overview': 'Events',
            '/admin/users': 'AdminUsers',
            '/admin/roles': 'AdminRoles',
            '/admin/characteristics': 'AdminCharacteristics',
            '/admin/characteristic-groups': 'AdminCharacteristicGroups',
            '/admin/notifications': 'AdminNotifications',
            '/admin/divisions': 'AdminDivisions',
            '/admin/regions': 'AdminRegions',
            '/admin/products': 'AdminProducts',
            '/maps/tour': 'MapsTour',
            '/reports/orders-overview': 'ReportsOrdersOverview',
            '/reports/orders-overview2': 'ReportsOrdersOverview2',
            '/reports/tickets-overview': 'ReportsTicketsOverview',
            '/sales/sales-daily': 'SalesDaily',
        };

        // Suche starten
        if (searchContext in map) {
            const searchObject: object = {searchInput};
            const searchController = map[searchContext];

            if (searchController == 'Institutions') {
                searchObject['ignoreHierarchy'] = this.institutionsListService.ignoreHierarchy;
            }

            const postRequest$: Observable<unknown> = this.backendService.postRequest(
                searchController + '/search',
                searchObject,
            );

            // Informationen für Event
            const eventData: CWEvent = {
                sender: 'globalSearch',
                target: searchContext,
                data: searchObject,
            };
            // Event auslösen
            this.eventSearchStarted.next(eventData);

            // Service subscribed selbst auf GET-Request
            postRequest$.subscribe((result: CWResult) => {
                // Informationen für Event
                const eventData: CWEvent = {
                    sender: 'globalSearch',
                    target: searchContext,
                    data: result['data'],
                };
                // Event auslösen
                this.eventSearchComplete.next(eventData);
            });

            // Observable (an Komponente) zurücklieferen, falls diese auch darauf subscriben muss
            return postRequest$;
        }
    }
}
