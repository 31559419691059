// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Julian Roller <j.roller@pharmakon.software>
*/
:host {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  width: 60px;
}

:host .cw-global-help-icon {
  font-size: 2rem;
  line-height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-help/global-help-icon/global-help-icon.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,oCAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AAAJ;;AAGA;EACI,eAAA;EACA,iBAAA;AAAJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Julian Roller <j.roller@pharmakon.software>\n*/\n\n:host {\n    background-color: rgba(0, 0, 0, 0.1);\n    cursor: pointer;\n    text-align: center;\n    width: 60px;\n}\n\n:host .cw-global-help-icon {\n    font-size: 2rem;\n    line-height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
