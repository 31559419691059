import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Service der übergeordneten Komponente
import {ToolbarService} from '../toolbar.service';

@Component({
    selector: 'phscw-toolbar-duplicate-search',
    templateUrl: './toolbar-duplicate-search.component.html',
    styleUrls: ['./toolbar-duplicate-search.component.scss'],
})
export class ToolbarDuplicateSearchComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;
    // Tooltip
    @Input() tooltip: string = null;
    // Parameter
    @Input() parameter: any;

    // Flag definiert, ob gerade geladen wird
    @Input() loading = false;

    constructor(private toolbarService: ToolbarService) {}

    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /*
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     */
    initializeEventSubscriptions(): void {}

    /**
     * @brief   Button-Klick an Service weiterleiten
     */
    searchDuplicate() {
        // Event auslösen
        this.toolbarService.searchDuplicate(this.componentIdentifier, {});
    }
}
