// Angular-Module
import {Component, Injectable, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services einbinden
import {UserPermissionsService} from './../../../services/user-permissions.service';
// Services
import {GlobalHelpService} from './../global-help.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';

@Component({
    selector: 'phscw-global-help-buttons',
    templateUrl: './global-help-buttons.component.html',
    styleUrls: ['./global-help-buttons.component.scss'],
})
export class GlobalHelpButtonsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ???
    @Input() selectedVal = '';

    // Flag definiert, ob die Übersicht angezeigt werden darf
    allowGlobalHelpOverview = false;
    // Flag definiert, ob der Schnellzugriff angezeigt werden darf
    allowGlobalHelpContext = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param userPermissions
     * @param globalHelpService
     */
    constructor(
        private userPermissions: UserPermissionsService,
        private globalHelpService: GlobalHelpService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        this.initializeEventSubscriptions();

        // Berechtigungen prüfen
        this.checkAllowGlobalHelpOverview();
        this.checkAllowGlobalHelpContext();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     * @todo    raus
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    initializeEventSubscriptions(): void {
        // Event "eventHelpListChanged" von "globalHelpService"
        this.globalHelpService.eventHelpListChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                this.selectedVal = result.data['listtype'];
            });
    }

    /**
     * @brief   Setze allowGlobalHelpOverview-Variable aus den Userpermissions
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkAllowGlobalHelpOverview(): void {
        const permissionAllowGlobalHelpOverview: boolean =
            this.userPermissions.getPermissionValue('allowGlobalHelpOverview');
        this.allowGlobalHelpOverview = permissionAllowGlobalHelpOverview;
    }

    /**
     * @brief   Setze allowGlobalHelpContext-Variable aus den Userpermissions
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkAllowGlobalHelpContext(): void {
        const permissionAllowGlobalHelpContext: boolean =
            this.userPermissions.getPermissionValue('allowGlobalHelpContext');
        this.allowGlobalHelpContext = permissionAllowGlobalHelpContext;
    }

    /**
     * @brief   Löst eventListChange für die kontextbezogene Ansicht aus.
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    contextSelected(): void {
        this.globalHelpService.listChange('help-buttons-context');
    }

    /**
     * @brief   Löst eventListChange für die hirarchische Ansicht aus.
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    hierarchySelected(): void {
        this.globalHelpService.listChange('help-buttons-hierarchy');
    }
}
