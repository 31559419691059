// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Michael Schiffner
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
*/`, "",{"version":3,"sources":["webpack://./src/app/modules/institutions/institutions-characteristics/institutions-characteristics.component.scss"],"names":[],"mappings":"AAAA;;;;;;CAAA","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Michael Schiffner\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n*/\n\n:host {\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
