// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// Globale Services einbinden
import {UserPermissionsService} from './../../../services/user-permissions.service';

@Component({
    selector: 'phscw-global-help-details',
    templateUrl: './global-help-details.component.html',
    styleUrls: ['./global-help-details.component.scss'],
})
export class GlobalHelpDetailsComponent implements OnInit, OnDestroy {
    // Flag definiert, ob Eintrag bearbeitete werden darf
    allowEditHelpentry = false;
    // Flag definiert, ob Eintrag Modulen zugewiesen werden darf
    allowAssignHelpentryModules = false;
    // Flag definiert, ob Eintrag Rollen zugewiesen werden darf
    allowAllowAssignHelpentryRoles = false;

    @Input() popupData: any = null;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param userPermissions
     */
    constructor(private userPermissions: UserPermissionsService) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        this.checkAllowEditHelpentry();
        this.checkAllowAssignHelpentryModules();
        this.checkAllowAssignHelpentryRoles();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * @brief   Setze allowEditHelpentry-Variable aus den Userpermissions
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    checkAllowEditHelpentry(): void {
        const permissionAllowEditHelpentry: boolean = this.userPermissions.getPermissionValue('allowEditHelpentry');
        this.allowEditHelpentry = permissionAllowEditHelpentry;
    }

    /**
     * @brief   Setze allowAssignHelpentryModules-Variable aus den Userpermissions
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkAllowAssignHelpentryModules(): void {
        const permissionAllowAssignHelpentryModules: boolean =
            this.userPermissions.getPermissionValue('allowAssignHelpentryModules');
        this.allowAssignHelpentryModules = permissionAllowAssignHelpentryModules;
    }

    /**
     * @brief   Setze allowAssignHelpentryRoles-Variable aus den Userpermissions
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkAllowAssignHelpentryRoles(): void {
        const permissionAllowAssignHelpentryRoles: boolean =
            this.userPermissions.getPermissionValue('allowAssignHelpentryRoles');
        this.allowAllowAssignHelpentryRoles = permissionAllowAssignHelpentryRoles;
    }
}
