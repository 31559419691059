// Angular-Module
import {Component, OnDestroy, OnInit} from '@angular/core';
// Environment einbinden
import {environment} from '@environment';

@Component({
    selector: 'phscw-global-header',
    templateUrl: './global-header.component.html',
    styleUrls: ['./global-header.component.scss'],
})
export class GlobalHeaderComponent implements OnInit, OnDestroy {
    // Global-Notifications anzeigen?
    showGlobalNotifications = false;

    /**
     * Konstruktor (inkl. dependency injection)
     */
    constructor() {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Setze passwordForgotLink über Environment-Variable. Falls nicht vorhanden, erscheint kein Link "Passwort vergessen"
        if (typeof environment.showGlobalNotifications !== 'undefined') {
            this.showGlobalNotifications = environment.showGlobalNotifications;
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        // nothing yet
    }
}
