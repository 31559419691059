// Angular-Module
import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services einbinden
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';
// Services anderer Shared-Modules
import {OverlayService} from '@shared/overlay/overlay.service';
// Interface für Optionen
import {SelectData} from '../../select-data';
// Shared Components
import {OverlayInfoInstitutionComponent} from '@shared/overlay/overlay-info/overlay-info-institution/overlay-info-institution.component';
import {OverlayInfoPersonComponent} from '@shared/overlay/overlay-info/overlay-info-person/overlay-info-person.component';

@Component({
    selector: 'phscw-input-radio',
    templateUrl: './input-radio.component.html',
    styleUrls: ['./input-radio.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputRadioComponent),
        multi: true,
    }],
})
export class InputRadioComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // EditMode aktiv?
    @Input() editMode = false;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Label des Input-Feldes
    @Input() label: string;
    // Soll der Text des Button innerhalb oder außerhalb des Material Elements sein
    @Input() labelInside = false;
    // Soll der Radio-Button außerhalb des Editmodus angezeigt werden.
    @Input() showButton = false;
    // Soll das Feld disabled werden.
    @Input() disabled = false;
    // Ist das Feld ein Pflichtfeld
    @Input() required = false;
    // Soll der Löschbutton angezeigt werden
    @Input() showDeleteButton = false;
    // Text für ein Tooltip auf dem Label
    @Input() labelTooltip = '';
    // Sollen die Optionen untereinander oder nebeneinander angezeigt werden?
    @Input() displayDirection = 'vertical';

    // Wert des Radio-Inputs
    _radioValue: any;
    // Setter _radioValue
    @Input() set radioValue(value) {
        this._radioValue = value;
        this.propagateChange(this._radioValue);
    }

    // Getter _radioValue
    get radioValue() {
        return this._radioValue;
    }

    // Array der Optionen für die Radio-Buttons mit Id, Label und Checked-Parameter
    @Input() radioOptions: Array<SelectData> = [];

    /*
     * Falls die Komponente Einträge aus "listentries" verwenden soll, wird hier
     * der Listenname angegeben. Beim Initialisieren wird dann selectData
     * über diese Liste gefüllt.
     */
    @Input() listentries = '';
    @Input() characteristics = '';

    // Event-Emitter, falls der Wert gelöscht wurde
    @Output() deleteClicked = new EventEmitter<any>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param initService
     * @param storageService
     * @param overlayService
     */
    constructor(
        private initService: InitService,
        private storageService: StorageService,
        private overlayService: OverlayService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Listentries aktualisieren
        this.initListentries();

        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Listentries initialisieren
     */
    initListentries(): void {
        // Falls der Name einer Liste hinterlegt wurde
        if (this.listentries != '') {
            // Daten über Service anfordern
            const promise = this.storageService.getItem('listentries|' + this.listentries);
            // let promise = this.storageService.getItemSlowly('listentries|' + this.listentries);
            promise.then((val) => this.onGetListentriesFromStorage(val));
        }
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Darauf warten, dass alle listentries in der indexedDB gespeichert sind
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            // Abbruch, falls Anfrage erfolglos war
            if (result === false) {
                return;
            }
            this.initListentries();
        });
    }

    /**
     * Listentry-Daten wurden geladen
     * @param value
     */
    onGetListentriesFromStorage(value: any): void {
        // Initialisierung dieser Komponente war schneller als das Laden der Listentries
        if (value == null) {
            /*
             * ...Listentries nochmal über StorageService ermitteln
             * Wird nicht mehr gemacht, kann eine "nahezu"-Endlosschleife auslösen, die nach Ausloggen und Einloggen C-World zur unbedienbarkeit verlangsamt
             * this.initListentries();
             */
            return;
        }

        // Initialisierung
        const tmpOption: SelectData = {
            id: 0,
            label: '',
            data: '',
        };
        const listentriesData: any[] = value;
        if (listentriesData) {
            // Werte der Liste (list_key & list_value) in Select-Optionen (id & label) übernehmen
            for (const entry of listentriesData) {
                tmpOption.id = entry['list_key'];
                tmpOption.label = entry['list_value'];
                // Wenn es list.data gibt diese zu einem JSON-Parsen und ebenfalls speichern.
                if (entry['list_data'] !== '' && entry['list_data'] !== null) {
                    tmpOption.data = JSON.parse(entry['list_data']);
                } else {
                    // Wenn der List_data-Eintrag leer ist, muss die data der tmpOption auf null gesetzt werden. Kein JSON.parse um Fehler zu vermeiden.
                    tmpOption.data = null;
                }
                this.radioOptions.push({...tmpOption});
            }
        }
    }

    /**
     * Funktion, die aufgerufen wird, wenn eine Änderung auftritt
     * @param _
     */
    propagateChange = (_: any) => {};

    /**
     * Interface ControlValueAccessor: writeValue
     * @param value
     */
    writeValue(value: any) {
        if (value !== undefined) {
            this.radioValue = value;
        }
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}

    /**
     * Lösche den Radiobutton-Wert
     */
    deleteValue(): void {
        this.radioValue = null;
        this.deleteClicked.emit();
    }

    /*
     * @brief   Overlay-Info in Dialog anzeigen für Personen oder Einrichtungen
     *
     * @author  Sena Üner <s.uener@pharmakon.software>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openOverlayInfoDialog(event: any, backendController: string, entityId: number): void {
        // Dialog öffnen
        let dialogRef = null;
        if (backendController === 'PeopleData') {
            dialogRef = this.overlayService.openOverlayInfoDialog(
                event.target,
                OverlayInfoPersonComponent,
                backendController,
                'details',
                entityId,
            );
        } else if (backendController === 'InstitutionsData') {
            dialogRef = this.overlayService.openOverlayInfoDialog(
                event.target,
                OverlayInfoInstitutionComponent,
                backendController,
                'details',
                entityId,
            );
        } else {
            console.error('backendController unknown');
            return;
        }

        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe(() => {});
    }
}
