// Angular-Module
import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[dynamicComponent]',
})
export class DynamicInsertionDirective {
    /**
     * Konstruktor (inkl. dependency injection)
     * @param viewContainerRef
     */
    constructor(public viewContainerRef: ViewContainerRef) {}
}
