// Angular Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
import {StorageService} from '@global/services/storage.service';
// Interface für Kennzeichengruppe einbinden
import {Characteristic} from './../characteristic';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../cw-event';
import {hasOwn} from '@shared/utils';

// Konstanten für die IndexedDB-StoreNames
const DB_CHARACTERISTICS_STORE_NAME = 'characteristicsForGroup|';
const DB_GROUPS_STORE_NAME = 'characteristicGroups';

interface LooseObject {
    [key: string]: any;
}

// Konstante für die legalen Kennzeichen-Typen und deren CakePHP-Controller
const CHARACTERISTIC_MODULES: any = {
    person: 'PeopleCharacteristics',
    institution: 'InstitutionsCharacteristics',
    contact: 'ContactsCharacteristics',
    event: 'EventsCharacteristics',
    event_person: 'EventsPeopleCharacteristics',
};

@Injectable({providedIn: 'root'})
export class CharacteristicsService {
    // Event zum Neuladen der Kennzeichen
    public eventReloadCharacteristicsGroupsList = new Subject<CWEvent>();
    // Event zum Auf- und Zuklappen der Kennzeichen
    public eventExpandCharacteristicsGroupsList = new Subject<CWEvent>();
    // Event zum Speichern der Kennzeichen
    public eventSaveCharacteristics = new Subject<CWEvent>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param backendService
     * @param storageService
     * @param appCore
     */
    constructor(
        private backendService: BackendService,
        private storageService: StorageService,
        private appCore: AppCoreService,
    ) {}

    /**
     * Wird von Kennzeichen-Modulen aufgerufen, um die Daten zu aktualisieren
     * @param component
     */
    reloadCharacteristicsGroupsList(component: string) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: component,
            target: '',
            data: {},
        };
        // Event auslösen
        this.eventReloadCharacteristicsGroupsList.next(eventData);
    }

    /**
     * Wird von Kennzeichen-Modulen aufgerufen, um alle Kennzeichen auf- und zuzuklappen
     * @param component
     * @param openState
     */
    expandCharacteristicsGroupsList(component: string, openState: boolean) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: component,
            target: 'characteristic-groups-list',
            data: {openState},
        };
        // Event auslösen
        this.eventExpandCharacteristicsGroupsList.next(eventData);
    }

    /**
     * Wird von anderen Modulen aufgerufen, um die Kennzeichen-Daten zu speichern
     * @param component
     * @param data
     */
    saveCharacteristics(component: string, data: LooseObject) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: component,
            target: 'characteristic-group',
            data,
        };
        // Event auslösen
        this.eventSaveCharacteristics.next(eventData);
    }

    /**
     * @param id
     * @param groupId
     * @param characteristicType
     * @brief   Lädt Kennzeichen-Ausprägungen einer Person
     * @returns  Observable<any>
     */
    loadData(id: number, groupId: number, characteristicType: string): Observable<any> {
        if (typeof CHARACTERISTIC_MODULES[characteristicType] !== 'undefined') {
            // GET-Request über BackendService senden
            const getRequest$: Observable<any> = this.backendService.getRequest(
                CHARACTERISTIC_MODULES[characteristicType] + '/details/' + id + '/' + groupId,
            );
            // Observable (an Komponente) zurücklieferen
            return getRequest$;
        }
        console.error('Pharmakon - CharacteristicsService hat nicht erlaubten Kennzeichentyp zugeordnet bekommen!');
    }

    /**
     * @brief   Merged Allgemeine Kennzeichend-Daten aus der Session, mit den speziellen Ausprägungen für eine Entity
     * @param   array   characteristicGroupArray   Allgemeine KennzeichenDaten
     * @param characteristicGroupArray
     * @param entityChar
     * @param   array   entityChar   Kennzeichen mit Wert/Ausprägungen für Person/Einrichtung/...
     * @returns  array
     * @author  Michael Schiffner <m.schniffern@pharmakon.software>
     */
    mergeCharacteristicsData(characteristicGroupArray: object, entityChar: Array<LooseObject>) {
        // Deep Copy um Referenzen auf Object-Array zu verlieren, not very nice. Object-Assign funktioniert nicht.
        const userCharacteristics: Array<Characteristic> = Object.values(
            JSON.parse(JSON.stringify(characteristicGroupArray)),
        );

        // PhS(MFe): Falls userGroupArray leer ist...
        if (!characteristicGroupArray) {
            return userCharacteristics;
        }

        /*
         * Über alle Gruppen Iterieren
         * Die vom Backend übergebenen Daten durchgehen.
         */
        for (const characteristicId in entityChar) {
            if (hasOwn(entityChar, characteristicId)) {
                // Überprüfen ob Kennzeichen in der Gruppe existiert. Falls nein index=>-1.
                const index = userCharacteristics.findIndex((x) => x.id === parseInt(characteristicId, 10));
                // Fall Kennzeichen in der Gruppe existiert den Wert aus den Controller-Daten setzen
                if (index !== -1) {
                    userCharacteristics[index].value = entityChar[characteristicId].value;
                }
            }
        }

        // Überprüfe auf Checkbox-Kennzeichen und setzte bei leeren Ausprägungen ein leeres Objekt als Wert. Wird nicht durch den Setter der Input-Checkbox-Komponente ersetzt.
        for (const characteristic in userCharacteristics) {
            if (
                userCharacteristics[characteristic].option_value_type === 'checkbox' &&
                typeof userCharacteristics[characteristic].value === 'undefined'
            ) {
                userCharacteristics[characteristic].value = {};
            } else {
                continue;
            }
        }

        return userCharacteristics;
    }

    /**
     * @brief   Speichert Kennzeichen-Zuordnungen
     * @param   number   id                   Entity-Id (z.B. PersonenId)
     * @param   any      characteristicData   DatenObjekt mit Kennzeichen und Ausprägungen, die gespeichert werden sollen.
     * @param   string   characteristicType   Kennzeichen-Typ
     * @param id
     * @param characteristicData
     * @param characteristicType
     * @param idPrefix
     * @param   string   idPrefix             Optionaler Prefix, der vor der ID in die URL eingefügt wird, notwendig z.B. bei EventsPeople, wo zwei IDs übergeben werden müssen (<url>/<event_id>/<person_id))
     * @returns  Observable
     */
    saveData(id: number, characteristicData: any, characteristicType: string, idPrefix = ''): Observable<any> {
        // Nur Kennzeichen mit erlaubtem Kennzeichen-Typ
        if (typeof CHARACTERISTIC_MODULES[characteristicType] !== 'undefined') {
            // POST-Request über BackendService senden
            const postRequest$: Observable<any> = this.backendService.postRequest(
                CHARACTERISTIC_MODULES[characteristicType] + '/edit/' + idPrefix + id,
                characteristicData,
            );
            // Observable (an Komponente) zurücklieferen
            return postRequest$;
        }
    }

    /**
     * @brief   Hole Kennzeichendaten für eine Gruppe aus dem Storage
     * @param id
     * @param   number   id   Gruppen-ID
     * @returns  any
     */
    getUserCharacteristicGroupCharacteristics<T = any>(id: number): Promise<T> {
        const characteristicsPromise = this.storageService.getItem(DB_CHARACTERISTICS_STORE_NAME + id);
        return characteristicsPromise.then((val) => val);
    }

    /**
     * @brief   Hole Keinzeichengruppen aus dem Storage
     * @returns  any
     */
    getUserCharacteristicGroups<T = any>(): Promise<T> {
        const characteristicGroupsPromise = this.storageService.getItem(DB_GROUPS_STORE_NAME);
        return characteristicGroupsPromise.then((val) => val);
    }

    /**
     * @param characteristicType
     * @param entityId
     * @brief   Holt die Anzahl aller und der gesetzten Kennzeichen in einer Gruppe.
     * @todo    Funktionalität im Backend und Frontend fehlt. noch nicht eingebunden
     */
    getCharacteristicGroupCounts(characteristicType: string, entityId: number): Observable<any> {
        // Wenn es den kennzeichentyp gibt: sende den Request
        if (typeof CHARACTERISTIC_MODULES[characteristicType] !== 'undefined') {
            const data = {
                characteristic_type: characteristicType,
                entity_id: entityId,
            };
            // POST-Request über BackendService senden
            const postRequest$: Observable<any> = this.backendService.postRequest(
                'Characteristics/getUserGroupsCount',
                data,
            );
            // Observable (an Komponente) zurücklieferen
            return postRequest$;
        }
    }

    /**
     * @param target
     * @param data
     * @brief       Falls Daten erfolgreich geändert wurden
     * @details     Sendet aktualisierten Datensatz per Event, damit andere
     *              Module (z.B. Listen) darauf reagieren können
     * @todo        geänderten Datensatz mitgeben
     */
    dataChanged(target: string, data: any): void {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'characteristics',
            target,
            data,
        };
        this.appCore.appDataChanged.next(eventData);
    }

    /*
     * @todo: Weitere Funktionen zur Nutzung durch das Admin-Modul
     * @todo: Anlegen von neuen KZ. und KZ-Gruppen zum Speichern in der Session
     */
}
