// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Übergeordneter Contacts-Service
import {ContactsService} from '@shared/contacts/contacts.service';
// Globalen Service einbinden
import {AppCoreService} from '@global/services/app-core.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';

@Component({
    selector: 'phscw-contacts-history',
    templateUrl: './contacts-history.component.html',
    styleUrls: ['./contacts-history.component.scss'],
})
export class ContactsHistoryComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Flag definiert ob gerade geladen wird
    loading = false;
    // History-Daten
    data: any = [];
    // Flag, falls keine Daten vorhanden sind
    noData = false;

    // ID des aktuellen Kontakts - Über GETTER / SETTER, da bei Änderung ein Neuladen erfolgen soll
    _contactId = 0;
    get contactId() {
        return this._contactId;
    }

    @Input() set contactId(value) {
        // Wert übernehmen
        this._contactId = value;

        // Details laden (falls es sich NICHT um eine Neuanlage handelt - z.B. in Kontaktübersicht)
        if (this.contactId >= 0) {
            this.loadDetails(this.contactId);
        }
    }

    /**
     * Konstruktor (inkl. dependency injection)
     * @param appCore
     * @param contactsService
     */
    constructor(
        private appCore: AppCoreService,
        private contactsService: ContactsService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Daten neu laden nach Änderung
        this.appCore.appDataChanged.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls das Event nicht vom eigenen Grid kam
            if (event.sender !== 'contacts' && event.target !== 'contacts-form-popup') {
                return;
            }
            // Daten in Kontaktmodul neu laden
            this.loadDetails(this.contactId);
        });
    }

    /**
     * Historie eines Kontakts / Tickets laden
     * @param contactId
     */
    loadDetails(contactId: number): void {
        // Flag "loading" aktivieren
        this.loading = true;
        // Falls es sich um einen neuen Kontakt handelt, wird auch der gewünschte Kontakttyp mitgesendet
        const serviceRequest$ = this.contactsService.loadDetails('ContactsHistory', contactId);
        serviceRequest$.subscribe((result: CWEvent) => {
            // Geladene Daten in Modul übernehmen
            this.data = Object.assign(result['data']);
            // No-Data-Flag
            this.noData = false;
            if (this.data[0] && this.data[0].length == 0) {
                this.noData = true;
            }
            // Flag "loading" deaktivieren
            this.loading = false;
        });
    }
}
