// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* Für einzelne Edit-Funktion */
.cw-grid-column-edit-single {
  min-width: 0px;
  width: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid/grid-actions/grid-actions.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB,+BAAA;AACA;EACI,cAAA;EACA,WAAA;AAEJ","sourcesContent":["/* Für einzelne Edit-Funktion */\n.cw-grid-column-edit-single {\n    min-width: 0px;\n    width: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
