// Angular-Module
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Komponenten dieses Moduls einbinden, welche per Route referenziert werden sollen
import {InstitutionsComponent} from './institutions/institutions.component';

// Konstante zur Defintion des Routings in diesem Modul
const routes: Routes = [
    {
        // Default-Route dieses Moduls (bereits als Child unter dem Kontext "institutions\")
        path: '',
        component: InstitutionsComponent,
        data: {key: 'institutions'},
    },
    {
        // Erweiterte Route (type + id) für neue Cross-Module-Variante
        path: ':id',
        component: InstitutionsComponent,
        data: {key: 'institutions'},
    },
    {
        // Erweiterte Route (type + id) für neue Cross-Module-Variante
        path: ':id/:submodule',
        component: InstitutionsComponent,
        data: {key: 'institutions'},
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class InstitutionsRoutingModule {}
