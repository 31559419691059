// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-wrapper {
  position: relative;
  display: inline-block;
}

.has-children {
  background: #fff;
  border-radius: 50%;
  color: var(--color-accent);
  font-size: 14px;
  left: 20px;
  position: absolute;
  top: 16px;
  z-index: 1;
}
.has-children:hover {
  color: var(--color-default-dark);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/entity-icons/single-icon-renderer/single-icon-renderer.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,kBAAA;EACA,0BAAA;EACA,eAAA;EACA,UAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;AACJ;AACI;EACI,gCAAA;AACR","sourcesContent":[":host {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.icon-wrapper {\n    position: relative;\n    display: inline-block;\n}\n\n.has-children {\n    background: #fff;\n    border-radius: 50%;\n    color: var(--color-accent);\n    font-size: 14px;\n    left: 20px;\n    position: absolute;\n    top: 16px;\n    z-index: +1;\n\n    &:hover {\n        color: var(--color-default-dark);\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
