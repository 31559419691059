import {Component, Input, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {MatCellDef, MatColumnDef, MatHeaderCellDef, MatTable} from '@angular/material/table';
// GridComponent
import {GridComponent} from './../grid.component';
// Shared Services
import {StorageService} from '@global/services/storage.service';

@Component({
    selector: 'phscw-grid-contact-person',
    templateUrl: './grid-contact-person.component.html',
    styleUrls: ['./grid-contact-person.component.scss', './../grid.component.scss'],
})
export class GridContactPersonComponent implements OnInit, OnDestroy {
    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;

    // Spalten-Name der auf die Spalte verweist
    @Input()
    get name(): string {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
        if (this.columnDef) {
            this.columnDef.name = name;
        }
    }

    _name: string;

    // config ob Multikontakte gruppiert werden sollen
    enableMultiContactGrouping = false;

    @ViewChild(MatColumnDef, {static: true}) columnDef: MatColumnDef;
    @ViewChild(MatHeaderCellDef, {static: true}) headerCellDef: MatHeaderCellDef;
    @ViewChild(MatCellDef, {static: true}) cellDef: MatCellDef;

    constructor(
        public table: MatTable<any>,
        private storageService: StorageService,
    ) {}

    ngOnInit() {
        if (this.table) {
            this.columnDef.name = this._name;
            this.columnDef.headerCell = this.headerCellDef;
            this.columnDef.cell = this.cellDef;
            this.table.addColumnDef(this.columnDef);
        }

        this.loadFrontendConfiguration();
    }

    ngOnDestroy() {
        if (this.table) {
            this.table.removeColumnDef(this.columnDef);
        }
    }

    /**
     * Lade Frontend Config ob Multikontakte gruppiert werden sollen
     */
    loadFrontendConfiguration(): void {
        const promise = this.storageService.getItem('config|enableGroupContacts');
        promise.then((value) => {
            this.enableMultiContactGrouping = value;
        });
    }

    /*
     * Baue String aus allen Personen eines Multikontaktes zum Anzeigen als Tooltip
     */
    displayMultiContactParticipants(element) {
        // prüfe ob aktueller Kontakt ein Multikontakt ist
        if (Object.prototype.hasOwnProperty.call(element, 'multi_contact_participants')) {
            // String welcher als tooltip angezeigt werden soll
            let toolTipString = '';

            // Hänge alle Personen des Multikontakts an
            for (const participants of element.multi_contact_participants) {
                if (
                    typeof participants.person_firstname !== 'undefined' &&
                    participants.person_firstname !== null &&
                    participants.person_firstname !== ''
                ) {
                    toolTipString = toolTipString.concat(
                        participants.person_lastname,
                        ', ',
                        participants.person_firstname,
                        '\r\n',
                    );
                } else {
                    toolTipString = toolTipString.concat(participants.person_lastname, '\r\n');
                }
            }

            return toolTipString;
        }
        return '';
    }

    // Prüfe auf config für Multikontakt Gruppierung und ob aktueller Kontakt ein Multikontakt ist
    contactGroupingEnabledAndIsMultiContact(element) {
        if (this.enableMultiContactGrouping) {
            if (element.multi_contact_id) {
                return true;
            }
            return false;
        }
        return false;
    }

    // Prüfe ob Vor- und Nachname angezeigt werden soll
    showContactPersonFullname(element) {
        if (this.contactGroupingEnabledAndIsMultiContact(element)) {
            return false;
        }
        if (element.person && element.person.lastname && element.person.firstname &&
          element.person.deleted == 'N'
        ) {
            return true;
        }
        return false;
    }

    // Prüfe ob Vor- und Nachname angezeigt werden soll obwohl Person 'deleted' ist
    showContactPersonFullnameDeleted(element) {
        if (this.contactGroupingEnabledAndIsMultiContact(element)) {
            return false;
        }
        if (element.person && element.person.lastname && element.person.firstname &&
          element.person.deleted == 'Y'
        ) {
            return true;
        }
        return false;

        return false;
    }

    // Prüfe ob nur Nachname angezeigt werden kann
    showContactPersonLastname(element) {
        if (this.contactGroupingEnabledAndIsMultiContact(element)) {
            return false;
        }
        if (element.person && element.person.lastname && !element.person.firstname) {
            return true;
        }
        return false;
    }
}
