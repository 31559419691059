import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

/**
 * Directive zur Generierung eines dynamischen Avatars basierend auf Vor- und Nachnamen.
 * Zeigt die Initialen in einem kreisförmigen Hintergrund an.
 *
 * Primär für die Verwendung zusammen mit "mat-card-avatar" gedacht, kann ggf. erweitert werden
 * @example
 * <div mat-card-avatar phscwPersonAvatar [firstName]="firstName" [lastName]="lastName"></div>
 */
@Directive({selector: '[phscwPersonAvatar]'})
export class PersonAvatarDirective implements OnChanges {
    /**
     * Der Vorname der Person.
     */
    @Input() firstName: string = '';

    /**
     * Der Nachname der Person.
     */
    @Input() lastName: string = '';

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    /**
     * Reagiert Änderungen der inputs
     * @param {SimpleChanges} changes Die Änderungen der inputs.
     */
    ngOnChanges(changes: SimpleChanges): void {
        this.updateAvatar();
    }

    /**
     * Aktualisiert den Avatar basierend auf den aktuellen Eingabewerten.
     */
    private updateAvatar(): void {
        const initials = this.getInitials(this.firstName, this.lastName);
        this.setStyles();

        // Initialien setzen oder default Icon setzen wenn kein Name vorhanden ist
        if (initials) {
            this.setText(initials);
        } else {
            this.setIcon();
        }
    }

    /**
     * Ermittelt die Initialen aus dem Vornamen und Nachnamen.
     * @param {string} firstName Der Vorname der Person.
     * @param {string} lastName Der Nachname der Person.
     * @returns {string} Die Initialen als Großbuchstaben.
     */
    private getInitials(firstName: string, lastName: string): string {
        let initials = '';
        if (firstName && firstName.length > 0) {
            initials += firstName.charAt(0).toUpperCase();
        }
        if (lastName && lastName.length > 0) {
            initials += lastName.charAt(0).toUpperCase();
        }
        return initials;
    }

    /**
     * Styles setzen
     */
    private setStyles(): void {
        const styles = {
            'width.px': 30,
            'height.px': 30,
            'background-color': 'var(--color-accent)',
            color: 'white',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'border-radius': '50%',
            'font-weight': 'bold',
            'text-transform': 'uppercase',
        };

        // Anwenden der Stile auf das Host-Element
        for (const [key, value] of Object.entries(styles)) {
            this.renderer.setStyle(this.el.nativeElement, key, value as any);
        }
    }

    /**
     * Setzt den Textinhalt des Avatars auf die berechneten Initialen.
     * @param {string} text Die zu setzenden Initialen.
     */
    private setText(text: string): void {
        // Bestehenden Inhalt des Elements löschen und die Initialen setzen
        this.renderer.setProperty(this.el.nativeElement, 'innerText', text);
    }

    // Default icon setzen wenn kein Name vorhanden
    private setIcon(): void {
        // Bestehenden Inhalt des Elements löschen
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', '');

        // Erstellen des <fa-icon> Elements
        const icon = this.renderer.createElement('span');

        // Leider mit iconfont, da fa-icons so nicht gerendert werden können(?)
        this.renderer.addClass(icon, 'icon-user');

        // Einfügen ins Host-Element
        this.renderer.appendChild(this.el.nativeElement, icon);
    }
}
