// Angular-Module
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services einbinden
import {ToolbarService} from '@shared/toolbar/toolbar.service';
import {GlobalHelpPopupService} from './../global-help-popup/global-help-popup.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';

// Interface für Dialogdaten
export interface DialogData {
    contactId: number;
    editable: boolean;
}

@Component({
    selector: 'phscw-global-help-popup',
    templateUrl: './global-help-popup.component.html',
    styleUrls: ['./global-help-popup.component.scss'],
})
export class GlobalHelpPopupComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param dialogRef
     * @param toolbarService
     * @param data
     * @param globalHelpPopupService
     */
    constructor(
        public dialogRef: MatDialogRef<GlobalHelpPopupComponent>,
        private toolbarService: ToolbarService,
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private globalHelpPopupService: GlobalHelpPopupService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        /*
         * Popup wird immer geschlossen geöffnet
         * this.globalHelpPopupService.setFullscreen(false);
         */
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Event "eventCloseComponent" von "toolbarService"
        this.toolbarService.eventCloseComponent
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                if (result.target === 'global-help') {
                    this.dialogRef.close();
                }
            });

        // Event "eventFullscreenStatusChanged" von "globalHelpPopupService"
        this.globalHelpPopupService.eventFullscreenStatusChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                this.toggleFullscreen(result.data['status']);
            });
    }

    /**
     * @param fullscreen
     * @brief   Größe des Popups ändern
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    toggleFullscreen(fullscreen: boolean): void {
        if (fullscreen === true) {
            this.dialogRef.updateSize('90%', '90%');
        } else {
            this.dialogRef.updateSize('950px', '600px');
        }
    }
}
