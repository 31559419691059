// Zulässige Icon-Typen
export enum IconType {
    FontAwesome = 'fa',
    SVG = 'svg',
    IconFont = 'iconfont', // Iconfont icons werden nicht mehr aktiv benutzt
}

export interface CwIcon {
    iconName: string;
    iconType: IconType;
    iconPath?: string; // Pfad zum SVG-Icon, wenn ein eigener Pfad mitgegeben wird
}
