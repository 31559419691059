import {Component, Input, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {formatDate} from '@angular/common';

import {NavigationEnd, Router} from '@angular/router';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
// Globale Services
import {UserPermissionsService} from '@global/services/user-permissions.service';
import {StorageService} from '@global/services/storage.service';
import {AppCoreService} from '@global/services/app-core.service';
// Übergeordneter Contacts-Service
import {ContactsService} from '@shared/contacts/contacts.service';
// Service von Shared Modules
import {GridService} from '@shared/grid/grid.service';
import {InputDateService} from '@shared/input/input-date/input-date.service';
// Interfaces für Structured Objects einbinden
import {Contacts} from '@shared/contacts';
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {Listentry} from '@shared/listentry';
import {SelectData} from '@shared/select-data';
import {LooseObject} from '@shared/loose-object';

interface Activity {
    activity: string | null;
    timePart: string | null;
    activityName?: string;
}
@Component({
    selector: 'phscw-contacts-list',
    templateUrl: './contacts-list.component.html',
    styleUrl: './contacts-list.component.scss',
})
export class ContactsListComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Personalnummer des aktuell ausgewählten Mitarbeiters
    private _selectedEmployeeId: number;
    @Input() set selectedEmployeeId(value: number) {
        this._selectedEmployeeId = value;
    }

    get selectedEmployeeId() {
        return this._selectedEmployeeId;
    }

    // ausgewähltes Datum
    private _selectedDate: any;
    @Input() set selectedDate(value: any) {
        if (this._selectedDate === value) {
            return;
        }

        this._selectedDate = value;

        // Kontakte neu laden
        this.resetContactsData();
        this.loadData();
    }

    get selectedDate() {
        return this._selectedDate;
    }

    regionName: string;
    formattedDate: string;
    formattedQueryDate: string;

    listentriesActivities: Listentry[] = [];
    listentriesTimeParts: Listentry[] = [];
    activities: Activity[] = [];

    // Kontakte
    gridData: Contacts[] = [];

    // Tagesbericht sichtbar
    dailyreportActive: boolean;

    // Spaltendefinitionen für Grid (Spezial-Spalten, z.B. "contact-info", sind direkt im Grid definiert)
    gridColumns = [
        {
            columnDef: 'contact-start',
            header: 'Datum',
            cell: (element: any) => `${element.contact_start}`,
            formatTemplate: 'date',
            formatWidth: '50px',
        },
        {
            columnDef: 'report-contacts-person',
            header: 'Person',
            cell: (element: Contacts) => {
                if (element.person && element.person.deleted == 'N') {
                    return `<strong>${element.person.lastname || ''}</strong>, ${element.person.firstname || ''} `;
                } if (element.person && element.person.deleted === 'Y') {
                    return `<p class="deleted-entities-contacts"> ${element.person.lastname || ''},
                    ${element.person.firstname || ''} </p>`;
                }
                return '<p class="deleted-person-contacts"> gelöschte Person </p>';
            },
            formatWidth: '105px',
        },
        {
            columnDef: 'report-contacts-institution',
            header: 'Einrichtung',
            cell: (element: Contacts) => {
                if (element.institution && element.institution.deleted == 'N') {
                    return `${element.institution.name1 || ''}<br>${element.institution.name2 || ''} `;
                } if (element.institution && element.institution.deleted === 'Y') {
                    return `<p class="deleted-entities-contacts"> ${element.institution.name1 || ''}<br>
                            ${element.institution.name2 || ''} </p>`;
                }
                return '';
            },
            formatWidth: '120px',
        },
        {
            columnDef: 'report-contacts-info',
            header: 'Info',
            cell: (element: Contacts) => {
                if (element.contact_type === 'contact') {
                    return `${element.note || ''}`;
                } if (element.contact_type !== 'contact') {
                    return `${element.title || ''}`;
                }
                return '';
            },
            formatWidth: '100px',
        },
    ];

    // Anzuzeigende Spalten für Grid Kontakte
    gridDisplayedColumns: string[] = [
        'contact-start',
        'contact-type',
        'contact-method',
        'report-contacts-person',
        'report-contacts-institution',
        'report-contacts-info',
    ];

    // Damit User-Setting für Filterung aus der DB übernommen werden
    gridContactsActiveFilter = null;

    // "Mehr laden..."
    loadMoreEnabled = false;
    loadMoreVisible = true;
    gridPage = 1;

    // Edit-Modus für Kontakte
    @Input() editMode = false;
    // Kontakt-ID
    contactId: number;

    // Kontakte nur lesbar?
    readonly = true;

    // Flag definiert, ob gerade geladen wird
    loading = false;
    // Flag definiert ob Kontakte angezeigt werden dürfen
    enableDailyreportContacts = false;
    // Personalnummer des aktuellen Benutzer
    employeeId: number;
    // Mitarbeiter Liste
    visibleEmployeesList: SelectData[] = [];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param router
     * @param userPermissionsService
     * @param gridService
     * @param dailyreportService
     * @param contactsService
     * @param inputDateService
     * @param dialogRef
     * @param storageService
     * @param dialogData
     * @param appCoreService
     */
    constructor(
        private router: Router,
        private userPermissionsService: UserPermissionsService,
        private gridService: GridService,
        private contactsService: ContactsService,
        private inputDateService: InputDateService,
        // eslint-disable-next-line new-cap
        @Optional() public dialogRef: MatDialogRef<ContactsListComponent>,
        private storageService: StorageService,
        // eslint-disable-next-line new-cap
        @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
        private appCoreService: AppCoreService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Für die Anzeige von Region und Datum
        this.initializeHeader();
        // Laden von Mitarbeiterliste für Tagesbericht Routen Knopf
        this.initializeEmployeesList();
        // Events subscriben
        this.initializeEventSubscriptions();
        // Listentries laden
        this.loadListentry();
        // Check nach Tagesbericht
        this.checkMenuForDailyReport();
        // Berechtigung prüfen
        this.checkEnableDailyreportContacts();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeEventSubscriptions(): void {
        this.onCurrentModuleChanged();
        // Router prüfen - wurde zu einem anderen Modul gewechselt?
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._componentDestroyed$),
            )
            .subscribe((event) => {
                if (event instanceof NavigationEnd && event['url'] === '/reports/dailyreport') {
                    this.onCurrentModuleChanged();
                }
            });

        // Event "eventGridSelectionChanged" von "gridService"
        this.gridService.eventGridSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid kam
                if (event.sender !== 'dailyreportContacts') {
                    return;
                }
                this.onEventGridSelectionChanged(event);
            });

        // Es wurde auf "Mehr laden..." geklickt
        this.gridService.eventGridPageCounterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid kam
                if (event.target !== 'dailyreportContacts') {
                    return;
                }
                this.onEventGridPageCounterChanged(event);
            });
    }

    /**
     * @brief   Kontaktliste neu laden nach wechsel des Moduls
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onCurrentModuleChanged(): void {
        // Kontakte neu laden
        this.resetContactsData();
        this.loadData();
        this.loadActivityData();
    }

    /**
     * @param event
     * @brief   Auf Event "eventGridSelectionChanged" von "gridService" reagieren
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventGridSelectionChanged(event: CWEvent): void {
        // Kontakt-Id setzen
        this.contactId = event.data['selectedRow']['id'];
        this.editMode = true;
    }

    /**
     * @param event
     * @brief   Es wurde auf "Mehr laden..." geklickt
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventGridPageCounterChanged(event: CWEvent): void {
        this.gridPage = event.data['gridPageCounter'];
        this.loadData();
    }

    /**
     * @brief   Alle Kontakte des Tages laden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadData(): void {
        if (this.dialogData && this.dialogData.eventData) {
            // Monatsbericht
            this.selectedDate = this.dialogData.eventData.year + '-' + this.dialogData.eventData.month + '-' + this.dialogData.eventData.day;
            this.selectedEmployeeId = this.dialogData.eventData.employee_id;
        }
        // Flag prüfen
        if (!this.loading) {
            // Flag setzen
            this.loading = true;

            // Funktion "Mehr laden..." wird wieder deaktiviert
            this.loadMoreEnabled = false;

            // Datum (im Frontend Moment) für Backend formatieren
            const backendDate: string = this.inputDateService.getDateValueForSave(this.selectedDate);

            // Daten zur Übergabe vorbereitet
            const formData: any = {
                page: this.gridPage,
                filter: this.gridContactsActiveFilter,
                date: backendDate,
            };
            if (this.selectedEmployeeId) {
                formData['selectedEmployeeId'] = this.selectedEmployeeId;
            }
            // Kontakte laden
            const serviceRequest$ = this.contactsService.loadContactsListForEmployee(formData);
            serviceRequest$.subscribe(
                (result: CWResult) => {
                    /**
                     * Prüfe, ob die Daten des eintreffenden Requests auch
                     * zu aktuell ausgewählten Datum und Mitarbeiter passen. Durch asynchrone
                     * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                     * bereits das Datum oder Mitarbeiter gewechselt wurde und die Antwort
                     * eines Requests verspätet eintrifft und dadurch die
                     * korrekten Daten wieder überschreibt.
                     */
                    if (this.selectedEmployeeId) {
                        if (
                            this.selectedEmployeeId != result['data']['employeeId'] ||
                            backendDate != result['data']['date']
                        ) {
                            return;
                        }
                    }

                    // Falls Daten geladen wurden
                    if (result['data']['contactsData'].length > 0) {
                        // Falls sich die vorhandenen Grid-Daten von den neu geladenen Daten unterscheiden...
                        if (JSON.stringify(this.gridData) != JSON.stringify(result['data']['contactsData'])) {
                            // Vorhandene Grid-Daten mit neu geladenen Daten erweitern
                            this.gridData = this.gridData.concat(result['data']['contactsData']);
                        }
                        // Funktion "Mehr laden..." wird wieder aktiviert
                        this.loadMoreEnabled = true;
                    } else {
                        // Funktion "Mehr laden..." wird ausgeblendet, da es keine weiteren Daten mehr gibt
                        this.loadMoreVisible = false;
                    }

                    // Nachdem Daten geladen wurden befindet sich das Modul immer in der normalen Ansicht
                    this.editMode = false;

                    // Flag deaktivieren
                    this.loading = false;
                },
                (error: any) => {
                    // Flag deaktivieren
                    this.loading = false;
                },
            );
        }
    }

    /**
     * @brief   Alle Tätigkeiten des Tages laden
     * @author  Nick Mühlberger <n.muehlberger@pharmakon.software>
     */
    loadActivityData(): void {
        if (this.dialogData && this.dialogData.eventData) {
            // Monatsbericht
            this.selectedDate = this.dialogData.eventData.year + '-' + this.dialogData.eventData.month + '-' + this.dialogData.eventData.day;
            this.selectedEmployeeId = this.dialogData.eventData.employee_id;
        }
        // Flag setzen
        this.loading = true;

        // Datum (im Frontend Moment) für Backend formatieren
        const backendDate: string = this.inputDateService.getDateValueForSave(this.selectedDate);

        // Daten zur Übergabe vorbereitet
        const formData: any = {date: backendDate};
        if (this.selectedEmployeeId) {
            formData['selectedEmployeeId'] = this.selectedEmployeeId;
        }

        // Tätigkeiten laden
        const serviceRequest$ = this.contactsService.loadDailyreportOfDate(formData);
        serviceRequest$.subscribe(
            (result: CWResult) => {
                if (result.success && result.data.length > 0) {
                    this.activities = this.mapActivityValues(
                        this.extractActivities(result.data[0]),
                        this.listentriesActivities,
                    );
                }
                this.loading = false;
            },
            (error: any) => {
                console.log('Error loading data:', error);
                this.loading = false;
            },
        );
    }

    /**
     * @brief   Zurücksetzen des Kontakte-Grid
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    resetContactsData(): void {
        // Seite zurücksetzen
        this.loadMoreEnabled = false;
        this.loadMoreVisible = true;
        this.gridPage = 1;
        // Liste leeren
        this.gridData = [];
    }

    /**
     * @brief   Berechtigung "enableDailyReportContacts" prüfen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkEnableDailyreportContacts(): void {
        const permissionEnableContacts: boolean =
            this.userPermissionsService.getPermissionValue('enableDailyReportContacts');
        this.enableDailyreportContacts = permissionEnableContacts;
    }

    initializeHeader(): void {
        if (this.dialogData) {
            this.regionName = this.dialogData.title;

            // Format the date from eventData
            if (this.dialogData.eventData) {
                const {
                    day, month, year,
                } = this.dialogData.eventData;
                this.formattedDate = `${this.padToTwoDigits(day)}.${this.padToTwoDigits(month)}.${year}`;
                this.formattedQueryDate = `${year}-${this.padToTwoDigits(month)}-${this.padToTwoDigits(day)}`;
            }
        }
    }

    // Helper method to ensure day/month are two digits
    private padToTwoDigits(value: number): string {
        return value.toString().padStart(2, '0');
    }

    navigateToRouteWithParams(route: string, queryParams: any): void {
        this.router.navigate([route], {queryParams});
        this.closeDialog();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    /**
     * Laden des generellen Listentries
     */
    loadListentry() {
        // Listentry laden
        const promiseActivities = this.storageService.getItem('listentries|dailyreportActivities');
        promiseActivities.then((value) => {
            if (value) {
                this.listentriesActivities = value;
            }
        });

        const promiseTimeParts = this.storageService.getItem('listentries|dailyreportActivityTimePart');
        promiseTimeParts.then((value) => {
            if (value) {
                this.listentriesTimeParts = value;
            }
        });
    }

    private extractActivities(rawData: any): Activity[] {
        const activities: Activity[] = [];
        for (let i = 1; i <= 5; i++) {
            if (rawData[`activity${i}`] && rawData[`time_part${i}`]) {
                activities.push({
                    activity: rawData[`activity${i}`],
                    timePart: rawData[`time_part${i}`],
                });
            }
        }
        return activities;
    }

    /**
     *
     * @param activities
     * @param listEntries
     * @param listentries
     */
    private mapActivityValues(activities: Activity[], listentries: Listentry[]): Activity[] {
        const lookup = listentries.reduce((acc, entry) => {
            acc[entry.list_key] = entry.list_value;
            return acc;
        }, {} as Record<string, string>);

        return activities.map((activity) => ({
            ...activity,
            activityName: activity.activity ? lookup[activity.activity] || null : null,
        }));
    }

    /**
     * Get list of employees from backend for whom user is allowed to see dailyreport
     */
    initializeEmployeesList(): void {
        const serviceRequest$: Observable<LooseObject> = this.contactsService.getVisibleEmployeesList();
        serviceRequest$.subscribe((result: CWResult) => {
            if (result.success) {
                if (!this.employeeId) {
                    this.employeeId = result.data['employee_id'];
                }

                this.visibleEmployeesList = result.data['employees_visible'];
            }
        });
    }

    isEmployeeVisibleForDailyReport(): boolean {
        return this.visibleEmployeesList.some((employee) => employee.id === this.selectedEmployeeId);
    }

    private checkMenuForDailyReport(result: any = this.appCoreService.globalMenu) {
        const globalMenuArray = result.globalMenu || result;
        this.dailyreportActive = this.findNameInGlobalMenu(globalMenuArray, 'dailyreport');
    }

    findNameInGlobalMenu(globalMenu: any[], targetName: string): boolean {
        for (const item of globalMenu) {
            if (item.name === targetName) {
                return true;
            }

            if (Array.isArray(item.children)) {
                if (this.findNameInGlobalMenu(item.children, targetName)) {
                    return true;
                }
            }
        }
        return false;
    }
}
