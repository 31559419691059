// Angular-Module
import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Globale Komponenten einbinden
import {GlobalSettingsPopupComponent} from './../global-settings-popup/global-settings-popup.component';
// Globale Services einbinden
import {AppCoreService} from './../../services/app-core.service';
// Environment einbinden
import {environment} from '@environment';

@Component({
    selector: 'phscw-global-settings',
    templateUrl: './global-settings.component.html',
    styleUrls: ['./global-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSettingsComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    // Darf der Benutzer Einstellungen verändern? Wird ggf. durch Environment überschrieben.
    settingsEnabled = false;

    // Konstruktor
    constructor(
        private appCore: AppCoreService,
        private dialog: MatDialog,
    ) {}

    // Initialisierung der Komponente
    ngOnInit() {
        // Setze settingsEnabled über Environment-Variable. Falls nicht vorhanden bleibt der Button deaktiviert.
        if (typeof environment.settingsEnabled !== 'undefined') {
            this.settingsEnabled = environment.settingsEnabled;
        }
        // Settings-Popup bei Event "appLogout" schließen
        this._subscriptions.add(
            this.appCore.appLogout.subscribe((result) => {
                this.dialog.closeAll();
            }),
        );
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    // Settings als Popup anzeigen
    showSettings() {
        // Dialog initialisieren
        const dialogConfig = new MatDialogConfig();

        /*
         * dialogConfig.hasBackdrop = true;
         * dialogConfig.disableClose = false;
         * Nichts automatisch fokusieren, sonst wird Button "Einstellungen" fokusiert dargestellt
         */
        dialogConfig.autoFocus = false;

        // Dialog öffnen
        this.dialog.open(GlobalSettingsPopupComponent, dialogConfig);
    }
}
