import {Directive, ElementRef, HostListener, Input} from '@angular/core';

/**
 * Einfache Helper-Direktive, die das color-Attribut eines HTML-Elements setzt, nachdem es angeklickt wurde
 * Z.B. als visuelle Kennzeichnung von Download-Buttons, ähnlich der visited-Farbe bei Links
 */
@Directive({
    selector: '[phscwClickedColor]',
    standalone: false,
})
export class ClickedColorDirective {
    @Input() set phscwClickedColor(color: string) {
        this.clickColor = color || 'green';
    }

    private clickColor = 'green';
    private isClicked = false;

    constructor(private el: ElementRef<HTMLButtonElement>) {}

    @HostListener('click')
    onClick() {
        this.isClicked = true;
        this.el.nativeElement.style.color = this.clickColor;
    }
}
