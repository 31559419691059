// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Eigenen Service einbinden
import {SubmodulesMenuService} from './../submodules-menu.service';
// Globale Services einbinden
import {UserSettingsService} from '@global/services/user-settings.service';
// Globale Klassen einbinden
import {KeyValue} from '@angular/common';
import {MenuData} from '@shared/menu-data';

@Component({
    selector: 'phscw-submodules-menu',
    templateUrl: './submodules-menu.component.html',
    styleUrls: ['./submodules-menu.component.scss'],
})
export class SubmodulesMenuComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Aktuelle Sprache
    langKeyReference = '';

    // Definiert, ob das Menü aus- oder eingeklappt ist
    public showMenuTexts = false;
    // Definiert, an welcher Position der Tooltip angezeigt wird
    public tooltipPosition = 'left';
    // Menüpunkte werden von außen über die verwendende Komponente als Input konfiguriert
    @Input() submoduleMenu: MenuData[] = [];
    // Definiert, ob Submodule angezeigt werden sollen
    @Input() displaySubmodules = false;

    // currently not implemented
    @Input() routedSubmodule = '';

    /**
     * Konstruktor (inkl. dependency injection)
     * @param userSettingsService
     * @param submodulesMenuService
     * @param translate
     */
    constructor(
        private userSettingsService: UserSettingsService,
        private submodulesMenuService: SubmodulesMenuService,
        private translate: TranslateService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Benutzer-Einstellung "submodulesMenuExpanded" definiert initialen Zustand des Menüs
        this.showMenuTexts = this.userSettingsService.getValue<boolean>('submodulesMenuExpanded');
        // Falls Submodule gar nicht angezeigt werden, so wird das Menü auch nicht ausgeklappt dargestellt
        if (!this.displaySubmodules) {
            this.showMenuTexts = false;
        }
        // Event auslösen um korrekte Breite für Inhalt zwischen Menüs zu setzen
        this.submodulesMenuService.submoduleMenuToggled('submodulesMenu', this.showMenuTexts);
        this.langKeyReference = this.translate.currentLang;

        // Events subscriben
        this.initializeEventSubscriptions();

        // currently not implemented
        if (this.routedSubmodule != '') {
            this.scrollToSubmodule(this.routedSubmodule);
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     * @author  Min Hye Park <m.park@pharmakon.software>
     */
    initializeEventSubscriptions() {
        // Auf Ändern der Sprache reagieren
        this.translate.onLangChange.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: any) => {
            this.langKeyReference = result.lang;
        });
    }

    /**
     * @brief   Funktion zum Ein-/Ausklappen des Menüs
     * @details Status des Menüs wird in Benutzereinstellungen gespeichert
     */
    toggleMenu() {
        this.showMenuTexts = !this.showMenuTexts;
        this.userSettingsService.setValue<boolean>('submodulesMenuExpanded', this.showMenuTexts);

        // Event auslösen um korrekte Breite für Inhalt zwischen Menüs zu setzen
        this.submodulesMenuService.submoduleMenuToggled('submodulesMenu', this.showMenuTexts);
    }

    /**
     * @param submodule
     * @brief   Funktion zum Scrollen zu Modul
     * @details Menüpunkt wird hervorgehoben
     */
    scrollToSubmodule(submodule: string) {
        const submoduleElement = document.getElementById('submodule-' + submodule);

        // Abbruch, falls Submodul nicht verfügbar ist
        if (!submoduleElement) {
            console.error('Submodule not found (submodule: ' + submodule + '). Please fix either config or html element id.');
            return;
        }

        // Zu Submodul scrollen
        submoduleElement.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
        });

        const menuItems = document.getElementsByClassName('cw-submenu-list-item');
        for (let i = 0; i < menuItems.length; i++) {
            menuItems[i].classList.remove('cw-submenu-active');
        }

        const menuElement = document.getElementById('submodule-button-' + submodule);
        menuElement.classList.add('cw-submenu-active');
    }

    /*
     * Preserve original property order
     * https://stackoverflow.com/a/52794221
     */
    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => 0;
}
