/**
 * @brief   Komponente zum Ausösen der Navigation zu Clearing-Eintrag
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {UserSettingsService} from '@global/services/user-settings.service';
// Service des Clearing Moduls
import {ClearingService as ClearingModuleService} from '@modules/clearing/clearing.service';
// Eigener Service
import {ClearingService} from './../clearing.service';
/*
 * Shared Services importieren
 * Shared Components
 */
import {PopupLoadingComponent} from '@shared/popups/popup-loading/popup-loading.component';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../../cw-event';
import {CWResult} from './../../cw-result';
import {LooseObject} from './../../loose-object';
// Environment

@Component({
    selector: 'phscw-clearing-navigation-button',
    templateUrl: './clearing-navigation-button.component.html',
    styleUrls: ['./clearing-navigation-button.component.scss'],
})
export class ClearingNavigationButtonComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Typ der Entität
    @Input() entityType = '';
    // IDs der Entität
    @Input() entityId1: number = null;
    @Input() entityId2: number = null;

    // Referenz auf Loading-Popup
    loadingPopupReference: MatDialogRef<PopupLoadingComponent>;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param dialog
     * @param appCoreService
     * @param userSettingsService
     * @param clearingModuleService
     * @param clearingService
     */
    constructor(
        private dialog: MatDialog,
        private appCoreService: AppCoreService,
        private userSettingsService: UserSettingsService,
        private clearingModuleService: ClearingModuleService,
        private clearingService: ClearingService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeEventSubscriptions(): void {
        /*
         *        // Event des Clearing zum Beenden der Animation (Merge)
         *        this.clearingService.eventUpdateEntityDataComplete.pipe(
         *            takeUntil(this._componentDestroyed$)
         *        ).subscribe((result: CWEvent) => {
         *            // Event-Daten
         *            const event: CWEvent = result;
         *            // Abbruch, falls Anfrage erfolglos war
         *            if (event.sender == this.gridId) {
         *                return;
         *            }
         *            this.onEventUpdateEntityDataComplete();
         *        });
         */
    }

    /**
     * @brief   Zum jeweiligen Benutzer wechseln
     * @details In dieser Funktion werden die User-Settings überschrieben und
     *          die Filterung im Backend angestoßen.
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    prepareNavigationToEntity(): void {
        // Lade-Popup zeigen
        this.loadingPopup(true);

        /**
         * Datenquelle der Liste soll auf "Auswahl" (KEY = 40) geändert werden.
         * =====================================================================
         * User-Setting wird hier überschrieben. Falls die Liste noch nicht
         * zuvor initialisiert wurde, wird beim Wechsel zur Liste direkt
         * mit dem gewünschten / geänderten Setting initialisiert.
         */
        this.userSettingsService.setValue('clearingGridListSource', '40');
        /*
         * Entität soll ausgewählt werden, aber weil der Schlüssel in der Liste
         * hier nicht bekannt ist, wird fest 1 übergeben. Im eigentlichen Modul
         * muss immer zuerst verglichen werden, ob Schlüssel und Bezeichnung
         * übereinstimmen. Damit ist die Bezeichnung führend bei der Anzeige
         */
        this.clearingModuleService.selectSource(1, this.entityType);

        // Daten für Backend vorbereiten
        const formData: LooseObject = {
            entity_type: this.entityType,
            entity_id1: this.entityId1,
            entity_id2: this.entityId2,
        };

        // Starte Filterung im Backend
        const serviceRequest$ = this.clearingService.executeFilterEntityByIds(formData);
        serviceRequest$.subscribe((result: CWResult) => {
            this.executeNavigationToClearing();
        });
    }

    /**
     * @brief   Das Loading-Popup wird verwendet um weitere User-Eingaben
     *          zu verhindern, während Daten im Hintergrund vorbereitet werden
     * @param   show boolean    <true> Zeige Popup
     *                          <false> Schließe Popup
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    private loadingPopup(show: boolean): void {
        if (show === true) {
            // Popup öffnen
            this.loadingPopupReference = this.dialog.open(PopupLoadingComponent, {
                width: '250px',
                data: {},
            });
        } else {
            // Popup schließen
            this.loadingPopupReference.close();
        }
    }

    /**
     * @brief   In der Benutzerliste der Rolle wurde ein Benutzer angeklickt und es soll
     *          deshalb zur Benutzerliste gewechselt werden.
     * @details Diese Funktion wird aufgerufen, sobald die Filterung im Backend
     *          abgeschlossen wurde, damit nun abschließend die Navigation
     *          auf das gewünschte Modul stattfinden kann.
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private executeNavigationToClearing(): void {
        // Lade-Popup schließen
        this.loadingPopup(false);

        // Datenquelle der Liste soll auf die entsprechende Entität geändert werden
        this.clearingService.changeClearingSource('clearing-navigation-button', 'clearingList', this.entityType);

        /**
         * Datenquelle der Liste soll auf "Auswahl" (KEY = 40) geändert werden.
         * =====================================================================
         * Falls die Liste bereits initialisiert wurde, muss die Veränderung
         * der Datenquelle über ein Event gesendet werden.
         */
        const eventData: CWEvent = {
            sender: 'clearing-navigation-button',
            target: 'clearingList',
            data: {
                listKey: '40',
                selectRowAfterFinished: 'first',
            },
        };
        this.appCoreService.changeGridSource.next(eventData);

        /**
         * Navigation zur Clearing-Liste
         */
        this.appCoreService.crossModuleNavigation('clearing', {});
    }
}
