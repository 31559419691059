// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {Regions} from '@shared/regions';

@Injectable({providedIn: 'root'})
export class AdminRegionsService {
    // Ausgewähltes Gebiet
    selectedRegion: Regions;

    // Subject (Observable) definieren "Es wurde ein anderes Gebiet ausgewählt"
    public selectionChanged = new Subject<number>();

    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
    ) {}

    /*
     * @brief   Wird aufgerufen, falls ein anderes Gebiet ausgewählt werden soll
     */
    selectRegion(selectedRegion: Regions) {
        // Ausgewähltes Gebiet zwischenspeichern
        this.selectedRegion = selectedRegion;
        // Event auslösen um andere Komponenten des Feature-Moduls zu informieren
        this.selectionChanged.next(this.selectedRegion.id);
    }

    /**
     * @param id
     * @brief   Stammdaten eines Gebiets laden
     * @returns  Observable<any>
     */
    loadData(id): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('AdminRegions/details/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @param id
     * @param formData
     * @brief   Speichert Stammdaten eines Gebiets
     * @returns  Observable<any>
     */
    saveData(id: number, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('AdminRegions/edit/' + id, formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param data
     * @brief      Falls Daten erfolgreich geändert wurden
     * @details    Sendet aktualisierten Datensatz per Event, damit andere
     *             Module (z.B. Listen) darauf reagieren können
     */
    dataChanged(data: Regions): void {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'admin-regions-data',
            target: '',
            data: {changedItem: data},
        };
        this.appCore.appDataChanged.next(eventData);
    }

    /**
     * Lade die Divisionen für die Regionszuordnung
     * @returns  Observable<any>
     */
    loadDivisions(): Observable<any> {
        // GET über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('AdminRegions/getDivisionsForSelectbox');
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * Lade die Divisionen für die Regionszuordnung
     * @returns  Observable<any>
     */
    recoverTree(): Observable<any> {
        // GET über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('Regions/recoverTree');
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }
}
