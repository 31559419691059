/* eslint-disable @typescript-eslint/no-explicit-any */
import {ContactType} from './contact-types';
import {Identifiable, Id} from './types';

/**
 * Type-guard, um zu überprüfen, ob die gegebenen Daten eine `id` besitzen.
 * Somit kann Typsicher auf die `id` zugegriffen werden.
 * @param {unknown} data - Die zu prüfenden Daten - z.B. eine Zeile im Grid
 * @returns {data is Identifiable & { id: Id }} - True, wenn die Daten eine `id` besitzen.
 * @example
 * const data: unknown = { id: 123, name: 'Max Mustermann', 'age': 30 };
 * if (isIdentifiableWithId(data)) {
 *     console.log(`Data ID: ${data.id}`); // Output: Data ID: 123
 * }
 */
export function isIdentifiableWithId(data: unknown): data is Identifiable & {id: Id} {
    if (typeof data !== 'object' || data === null) {
        return false;
    }

    // Prüfen ob eine 'id' vorhanden ist
    if (!('id' in data)) {
        return false;
    }

    // Data zu Identifiable casten, da 'id' existiert
    const identifiableData = data as Identifiable;

    // Id ist vom Typ number oder string
    return typeof identifiableData.id === 'number' || typeof identifiableData.id === 'string';
}

/**
 * Prüft, ob der gegebene Wert ein Array ist.
 * @param value - Der zu prüfende Wert.
 */
export function isArray<T>(value: T | T[]): value is T[] {
    return Array.isArray(value);
}

/**
 * Überprüft, ob der gegebene Wert ein gültiger `ContactType` ist.
 * @param {any} value - Der zu prüfende Wert.
 * @returns {value is ContactType} - True, wenn der Wert ein gültiger `ContactType` ist, sonst false.
 */
export function isContactType(value: any): value is ContactType {
    return Object.values(ContactType).includes(value);
}
