import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {GlobalSettingsSelectDeputyService} from './global-settings-select-deputy.service';

@Component({
    selector: 'phscw-global-settings-select-deputy',
    templateUrl: './global-settings-select-deputy.component.html',
    styleUrls: ['./global-settings-select-deputy.component.scss'],
})
export class GlobalSettingsSelectDeputyComponent implements OnInit {
    // Flag definiert ob gerade geladen wird
    loading = false;

    // Flag definiert ob gerade gespeichert wird
    saving = false;

    // Definiert, ob das Suchfeld aktiv ist
    searchDisabled = false;

    // Flag definiert ob der eingeloggte User einen aktiven Vertreter hat
    employeeHasActiveDeputy = false;

    // Employee_id des Vertreters
    deputyEmployeeId: number = null;

    // Vertreter Name
    deputyName: string;

    // Den Speichern-Button erst aktivieren, wenn ein Mitarbeiter ausgewählt wurde
    saveButtonEnabled = false;

    // Fehlermeldung bei fehlenden untergeordneten Mitarbeitern
    showErrorNoSubordinates = false;

    // Form initialisieren
    settingsSelectDeputyForm: UntypedFormGroup = new UntypedFormGroup({deputy_autocomplete: new UntypedFormControl()});

    // Component-Event-Binding fürs Schließen
    @Output() buttonCloseClicked = new EventEmitter<any>();

    constructor(private globalSettingsSelectDeputyService: GlobalSettingsSelectDeputyService) {}

    ngOnInit() {
        this.getActiveDeputy();
    }

    // Setzt einen Vertreter für den eingeloggten User
    clickSubmit() {
        this.saving = true;
        const deputyEmployeeId = this.settingsSelectDeputyForm.get('deputy_autocomplete').value['id'];

        if (deputyEmployeeId != null) {
            this.globalSettingsSelectDeputyService.setDeputy(deputyEmployeeId);
        } else {
            this.saving = false;
            return;
        }
        this.saving = false;
        this.employeeHasActiveDeputy = true;
        this.saveButtonEnabled = false;
    }

    /**
     * @brief Entfernt den aktuellen Vertreter des eingeloggten Users
     * @author Daniel Nita <d.nita@pharmakon.software>
     */
    removeDeputy() {
        // "Vertreter entfernen"-Button deaktivieren
        this.employeeHasActiveDeputy = false;

        this.globalSettingsSelectDeputyService.removeActiveDeputy();

        // Feld leeren
        this.settingsSelectDeputyForm.patchValue({deputy_autocomplete: null});
    }

    /**
     * @brief Prüft, ob es einen aktiven Vertreter für den eingeloggten User gibt
     * @author Daniel Nita <d.nita@pharmakon.software>
     */
    getActiveDeputy() {
        this.loading = true;
        const serviceRequest$ = this.globalSettingsSelectDeputyService.getActiveDeputy();
        serviceRequest$.subscribe(
            (result: any) => {
                if (result.success && result.data !== null) {
                    // Name zuweisen
                    this.deputyName = result.data.lastname + ', ' + result.data.firstname;
                    // Id zuweisen
                    this.deputyEmployeeId = result.data.id;
                    // Flag setzen, damit der Button "Vertreter entfernen" aktiv ist
                    this.employeeHasActiveDeputy = true;
                    // Name im Textfeld anzeigen
                    this.settingsSelectDeputyForm.patchValue({deputy_autocomplete: this.deputyName});
                    // Flag deaktivieren
                    this.loading = false;
                } else if (result.success && result.data === null) {
                    // Flag zurücksetzen
                    this.loading = false;
                } else {
                    // Fehlermeldung anzeigen
                    this.showErrorNoSubordinates = true;
                    // Suchfeld deaktivieren
                    this.searchDisabled = true;
                    // Flag zurücksetzen
                    this.loading = false;
                }
            },
            (error: any) => {
                // Suchfeld deaktivieren
                this.searchDisabled = true;
                // Logging
                console.error(error);
                // Flag deaktivieren
                this.loading = false;
            },
        );
    }

    // Popup schliessen
    clickAndClose() {
        // Parent-Komponente über Event informieren, dass Schließen-Button geklickt wurde
        this.buttonCloseClicked.emit();
    }

    // Ein Mitarbeiter wurde ausgewählt, jetzt kann gespeichert werden
    selectOption() {
        this.saveButtonEnabled = true;
    }
}
