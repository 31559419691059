import {
    Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output,
} from '@angular/core';
import {UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {InputNumberService} from './input-number.service';

// Custom Validator (über factory closure)
/**
 *
 * @param maxValue
 * @param minValue
 */
export function createNumberRangeValidator(maxValue, minValue) {
    return function validateCounterRange(c: UntypedFormControl, percent?: boolean) {
        if (typeof percent === 'undefined') {
            percent = false;
        }
        if (typeof maxValue === 'undefined' || isNaN(maxValue)) {
            maxValue = Infinity;
        } else if (typeof minValue === 'undefined' || isNaN(minValue)) {
            minValue = Infinity * -1;
        }
        const error = {
            rangeError: {
                given: c.value,
                max: maxValue,
                min: minValue,
                message: 'Wert muss >=' + minValue + ' und <=' + maxValue + ' sein.',
            },
        };
        // Falls es sich um einen Prozent-Wert handelt muss der geprüfte Wert mit 100 multipliziert werden, da der math-Value geprüft wird.
        if (percent) {
            return c.value * 100 > +maxValue || c.value * 100 < +minValue ? error : null;
        }
        return c.value > +maxValue || c.value < +minValue ? error : null;
    };
}

@Component({
    selector: 'phscw-input-number',
    templateUrl: './input-number.component.html',
    styleUrls: ['./input-number.component.scss'],
    providers: [
        {
            // custom value accessor for dependency injection (https://blog.thoughtram.io/angular2/2015/11/23/multi-providers-in-angular-2.html)
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputNumberComponent),
            multi: true,
        },
        {
            // custom validator
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputNumberComponent),
            multi: true,
        },
    ],
})
export class InputNumberComponent implements OnInit, OnChanges, OnDestroy {
    // EditMode aktiv?
    @Input() editMode = false;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string;
    // Soll der Wert als Währung, Zahl, Prozentzahl, oder Ganzzahl angezeigt werden?
    @Input() valueType = 'number';

    @Input() customFormat: any = null;

    // Anordnung der Anzeige
    @Input() textAlign = 'right';

    // Value der aus dem Backend kommt. Eigentlicher Wert
    private _mathValue = 0;
    get mathValue() {
        return +this._mathValue;
    }

    @Input() set mathValue(value: number) {
        // Setze den display-Value
        this.updateDisplayValueFromMathValue(value);

        /*
         * Setze den Wert zur Berechnung
         * 2020-10-29, PhS(LB): Bei Integer- und Nummer-Werten sollte null nicht überschrieben werden, ohne
         *                      diese Prüfung wurden required-Felder als mit 0 befüllt ausgewertet.
         */
        if ((this.valueType === 'integer' || this.valueType === 'number') && this.required && value === null) {
            this._mathValue = null;
        } else {
            this._mathValue = +value;
        }
        this.propagateChange(this._mathValue);
    }

    // Wert der angezeigt wird. Sowohl im Input-Feld als auch bei normaler Anzeige
    private _displayValue: any = 0;
    get displayValue() {
        // Das Custom-Format nur im Nicht-Edit-Modus beim getten laufen lassen um Probleme zu vermeiden
        if (this.customFormat !== null && typeof this.customFormat !== 'undefined' && !this.editMode) {
            return this.inputNumberService.applyCustomFormat(this.customFormat, this._displayValue) as any;
        }
        return this._displayValue;
    }

    @Input() set displayValue(value: number) {
        // aktualisiere den Math-Value;
        this.updateMathValueFromDisplayValue(value);

        // Setze den displayValue
        if (this.valueType === 'integer' && value !== null) {
            this._displayValue = Math.round(value);
        } else if ((this.valueType === 'integer' || this.valueType === 'number') && value === null) {
            // Null soll bei Integer- und Nummer-Werten tatsächlich null bleiben und nicht zu 0 konvertiert werden
            this._displayValue = null;
        } else {
            this._displayValue = +value;
        }
    }

    // Min & Max für Validator
    @Input() maxValue;
    @Input() minValue;

    // Attribut: required = Pflichtfeld (ja / nein)
    @Input() required = false;
    // Attribut: disabled = Gesperrt (ja / nein)
    @Input() disabled = false;
    /*
     * Soll ein Komma oder ein Punkt als Dezimaltrennzeichen verwendet werden?
     * @todo noch ungenutzt da html relativ klug ist.
     */
    @Input() valueDelimiter = ',';
    // Soll der Löschbutton angezeigt werden
    @Input() showDeleteButton = false;
    // Wie groß ist die Stepsize und wie viele Nachkommstellen dürfen angezeigt werden?
    @Input() stepSize: any = 'any';
    // Welches Währungssymbol soll bei Währungen angezeigt werden
    @Input() currency = '€';
    /*
     * Gibt es einen Validierungsfehler?
     * @todo: Ein bisschen hin und her gereiche. Evtl. noch verbesserungswürdig
     */
    @Input() error: any;
    // Falls ein Style übergeben werden soll
    @Input() style: any = {};
    // Falls ein alternativ-Style für die Ausrichtung der Felder mitgegeben werden soll
    @Input() showAlternativeStyle = false;
    // Sollen die Fehlernachrichten angezeigt werden (oder nur die Felder rot umrahmt werden)
    @Input() showErrorMessage = true;

    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    propagateChange = (_: any) => {};

    validateFn: any;

    // In Ansichtsmodus verstecken, wenn leer?
    @Input() hideEmpty = false;

    // Event-Emitter, falls der Wert gelöscht wurde
    @Output() deleteClicked = new EventEmitter<any>();

    // Interface ControlValueAccessor: writeValue
    writeValue(value: any) {
        if (value !== undefined) {
            this.mathValue = value;
        }
    }

    /**
     * Konstruktor (inkl. dependency injection)
     * @param inputNumberService
     */
    constructor(private inputNumberService: InputNumberService) {}

    /**
     * Initialisieren
     */
    ngOnInit() {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * Ändern
     * @param changes
     */
    ngOnChanges(changes: any) {
        if (typeof changes.maxValue !== 'undefined' || typeof changes.minValue !== 'undefined') {
            if (typeof changes.maxValue === 'undefined') {
                this.validateFn = createNumberRangeValidator(undefined, changes.minValue.currentValue);
            } else if (typeof changes.minValue === 'undefined') {
                this.validateFn = createNumberRangeValidator(changes.maxValue.currentValue, undefined);
            } else {
                this.validateFn = createNumberRangeValidator(
                    changes.maxValue.currentValue,
                    changes.minValue.currentValue,
                );
            }
        }
    }

    /**
     * Validieren
     * @param c
     */
    validate(c: UntypedFormControl) {
        if (typeof this.validateFn !== 'undefined') {
            if (this.valueType === 'percent') {
                return this.validateFn(c, true);
            }
            return this.validateFn(c);
        }
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}

    /**
     * Lösche den Text
     */
    deleteValue(): void {
        this.mathValue = null;
        this.displayValue = null;
        this.deleteClicked.emit();
    }

    /**
     * aktualisiere den eigentlichen Wert abhängig vom Anzeigewert
     * @param value
     */
    private updateMathValueFromDisplayValue(value: number): void {
        if (this.valueType === 'percent' && value !== null) {
            this._mathValue = +value / 100;
        } else if (this.valueType === 'integer' && value !== null) {
            this._mathValue = Math.round(value);
        } else if ((this.valueType === 'integer' || this.valueType === 'number') && value === null) {
            // Null soll bei Integer-Werten tatsächlich null bleiben und nicht zu 0 konvertiert werden
            this._mathValue = null;
        } else {
            this._mathValue = +value;
        }
        this.propagateChange(this._mathValue);
    }

    /**
     * aktualisiere den angezeigten Wert abhängig vom Rechenwert
     * @param value
     */
    private updateDisplayValueFromMathValue(value: number): void {
        if (this.valueType === 'percent' && value !== null) {
            this._displayValue = +Math.round(value * 100000) / 1000;
        } else if (this.valueType === 'integer' && value !== null) {
            this._displayValue = Math.round(value);
        } else if ((this.valueType === 'integer' || this.valueType === 'number') && value === null) {
            // Null soll bei Integer- und Nummern-Werten tatsächlich null bleiben und nicht zu 0 konvertiert werden
            this._displayValue = null;
        } else {
            this._displayValue = +value;
        }
    }
}
