// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cw-chart-accordion-header-icon {
  padding: 4px;
}

.accordion-header {
  line-height: 1px;
}

.strikethrough:after {
  content: "";
  position: absolute;
  display: block;
  width: 80%;
  height: 2px;
  box-shadow: 0 1px rgba(255, 255, 255, 0.6);
  background: #b0b0b0;
  margin-top: -20px;
  transform-origin: left center;
  animation: strikethrough 1s cubic-bezier(0.55, 0, 0.1, 1) 1;
}

/* Keyframes for initial animation */
@keyframes strikethrough {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/chart/chart-accordion/chart-accordion-panel/chart-accordion-panel.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAMA;EACI,WAAA;EACA,kBAAA;EACA,cAAA;EACA,UAAA;EACA,WAAA;EACA,0CAAA;EACA,mBAAA;EACA,iBAAA;EACA,6BAAA;EACA,2DAAA;AAHJ;;AASA,oCAAA;AACA;EACI;IACI,oBAAA;EANN;EAQE;IACI,oBAAA;EANN;AACF","sourcesContent":[".cw-chart-accordion-header-icon {\n    padding: 4px;\n}\n\n.accordion-header {\n    line-height: 1px;\n}\n\n.strikethrough {\n    //transition: all 0.5s cubic-bezier(.55, 0, .1, 1);\n}\n\n.strikethrough:after {\n    content: '';\n    position: absolute;\n    display: block;\n    width: 80%;\n    height: 2px;\n    box-shadow: 0 1px rgba(255, 255, 255, 0.6);\n    background: #b0b0b0;\n    margin-top: -20px;\n    transform-origin: left center;\n    animation: strikethrough 1s cubic-bezier(0.55, 0, 0.1, 1) 1;\n    //left: 40px;\n    //transform: scaleX(0);\n    //transition: transform 0.5s cubic-bezier(.55, 0, .1, 1);\n}\n\n/* Keyframes for initial animation */\n@keyframes strikethrough {\n    from {\n        transform: scaleX(0);\n    }\n    to {\n        transform: scaleX(1);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
