import {Component, Input, OnInit} from '@angular/core';
// Service dieses Shared-Moduls
import {ChartService} from './../../chart.service';

@Component({
    selector: 'phscw-chart-accordion-panel',
    templateUrl: './chart-accordion-panel.component.html',
    styleUrls: ['./chart-accordion-panel.component.scss'],
})
export class ChartAccordionPanelComponent implements OnInit {
    @Input() panels: any[];

    constructor(private chartService: ChartService) {}

    ngOnInit() {}

    confirm(panel) {
        this.panels.forEach((item, index) => {
            if (item === panel) {
                this.panels[index]['strikeout'] = true;

                // alle unter liegend durchstreichen
                this.panels[index].children.forEach((item, index) => {
                    item.strikeout = true;
                });

                // wenn animation fertig ist, eintrag löschen
                setTimeout(() => {
                    this.panels.splice(index, 1);
                }, 1000);
            }
        });
    }

    // Klick auf Icon
    clickLinkIcon(panel: any[]) {
        const ids: number[] = [];
        panel['children'].forEach((item: {id: number}, index: any) => {
            ids.push(item.id);
        });
        this.chartService.linkIconClicked('chart-accordion', ids);
    }
}
