import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
import {InputDateService} from '@shared/input/input-date/input-date.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {OrderData} from '@shared/order-data';
import {hasOwn} from '@shared/utils';

/**
 * @brief   Service, bietet Funktionen mit Backendanbindung für die Auftrags-Komponente
 * @author  Michael Schiffner <m.schiffner@pharmakon.software>
 * @author  Sena Üner <s.uener@pharmakons.software>
 */
@Injectable({providedIn: 'root'})
export class OrderTrackingService {
    // Ausgewählter Auftrag
    selectedOrder: OrderData;

    // Subject (Observable) definieren "Es wurde eine andere Einrichtung ausgewählt"
    public selectionChanged = new Subject<number>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param appCore
     * @param backendService
     * @param inputDateService
     */
    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
        private inputDateService: InputDateService,
    ) {}

    /**
     * @brief   einzelne Aufträge laden
     * @param id
     * @param institutionId
     * @param   number  id      order - ID
     * @returns Observable < Object >
     */
    loadDetails(id: number, institutionId: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'InstitutionsOrders/details/' + id + '/' + institutionId,
        );
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @param id
     * @param formData
     * @brief   Speichert Stammdaten einer Einrichtung
     * @returns  Observable<any>
     */
    saveData(id: number, formData: any): Observable<any> {
        // Deep-Copy der Form-Data weil sonst die Datumsformatierung Probleme macht
        const copyFormData = JSON.parse(JSON.stringify(formData));

        copyFormData['order_date'] = this.inputDateService.getDateValueForSave(formData['order_date']);
        copyFormData['value_date'] = this.inputDateService.getDateValueForSave(formData['value_date']);

        if (copyFormData.version == 1) {
            copyFormData['delivery_date'] = this.inputDateService.getDateValueForSave(formData['delivery_date']);
        } else {
            let dateCount = 1;
            copyFormData['delivery_date'] = {};

            let idCount = 1;
            copyFormData['delivery_institution_id'] = {};
            for (const outerKey in copyFormData['order_items']) {
                if (hasOwn(copyFormData['order_items'], outerKey)) {
                    copyFormData['order_items'][outerKey]['delivery_date'] = this.inputDateService.getDateValueForSave(
                        formData['order_items'][outerKey]['delivery_date'],
                    );
                    const toBeAddedDate = this.inputDateService.getDateValueForSave(
                        formData['order_items'][outerKey]['delivery_date'],
                    );
                    let found = true;
                    for (const key in copyFormData['delivery_date']) {
                        if (copyFormData['delivery_date'][key] === toBeAddedDate) {
                            found = false;
                        }
                    }
                    if (found) {
                        copyFormData['delivery_date'][dateCount] = toBeAddedDate;
                        dateCount++;
                    }

                    const toBeAddedID = copyFormData['order_items'][outerKey]['delivery_institution_id'];
                    found = true;
                    for (const key in copyFormData['delivery_institution_id']) {
                        if (copyFormData['delivery_institution_id'][key] === toBeAddedID) {
                            found = false;
                        }
                    }
                    if (found) {
                        copyFormData['delivery_institution_id'][idCount] = toBeAddedID;
                        idCount++;
                    }
                }
            }
        }

        // POST-Request über BackendService senden

        const postRequest$: Observable<any> = this.backendService.postRequest(
            'InstitutionsOrders/edit/' + id,
            copyFormData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param data
     * @brief      Falls Daten erfolgreich geändert wurden
     * @details    Sendet aktualisierten Datensatz per Event, damit andere
     *             Module (z.B. Listen) darauf reagieren können
     */
    dataChanged(data: OrderData): void {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'institutions-orders',
            target: '',
            data: {changedItem: data},
        };
        // this.appEventsService.appDataChanged.emit(eventData);
        this.appCore.appDataChanged.next(eventData);
    }

    /**
     * @param id
     * @param dataObject
     * @brief   Lädt die Produkt-Informationen anhand von Produkt-Id
     */
    loadProductInformation(id: number, dataObject: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'InstitutionsOrders/loadProductOrderInformation/' + id,
            dataObject,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Lädt Dokument aus dem Backend
     * @param id
     * @param   number  id  ID des Dokuments
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getDocument(id: number): Observable<Blob> {
        // GET-Request über BackendService senden
        const getRequest: Observable<Blob> = this.backendService.getFile('Documents/download/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest;
    }
}
