/**
 * @brief   Definition der Routen, welche nur für authentifizierte Benutzer
 *          verfügbar sind.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {Routes} from '@angular/router';

// Globale Komponenten einbinden, die durch das Routing direkt angezeigt werden
import {GlobalPageNotFoundComponent} from '@global/components/global-page-not-found/global-page-not-found.component';

/*
 * Routing-Konstanten für Haupt-Module, die per Lazy-Loading geladen werden
 * export const lazyDashboards = '@modules/dashboards/dashboards.module#DashboardsModule';
 * export const lazyInstitutions = '@modules/institutions/institutions.module#InstitutionsModule';
 * export const lazyPeople = '@modules/people/people.module#PeopleModule';
 * export const lazyAdmin = '@modules/admin/admin.module#AdminModule';
 */

/*
 * Definiere Routen, die nur für authentifizierte Benutzer verfügbar sind
 * @todo: in Confluence Erstellung dokumentieren -> CLI verwenden
 */
export const SECURE_ROUTES: Routes = [
    // Default-Route
    {
        path: '',
        redirectTo: '/dashboards',
        pathMatch: 'full' as const,
    },
    // Hauptmodule (lazy loaded feature modules)
    {
        path: 'dashboards',
        loadChildren: () => import('app/modules/dashboards/dashboards.module').then((m) => m.DashboardsModule),
    },
    {
        path: 'dashboards/:submodule',
        loadChildren: () => import('app/modules/dashboards/dashboards.module').then((m) => m.DashboardsModule),
    },
    {
        path: 'institutions',
        loadChildren: () => import('app/modules/institutions/institutions.module').then((m) => m.InstitutionsModule),
    },
    {
        path: 'people',
        loadChildren: () => import('app/modules/people/people.module').then((m) => m.PeopleModule),
        data: {isB2C: false},
    },
    {
        path: 'b2c',
        loadChildren: () => import('app/modules/people/people.module').then((m) => m.PeopleModule),
        data: {isB2C: true},
    },
    {
        path: 'maps',
        loadChildren: () => import('app/modules/maps/maps.module').then((m) => m.MapsModule),
    },
    {
        path: 'events',
        loadChildren: () => import('app/modules/events/events.module').then((m) => m.EventsModule),
    },
    {
        path: 'reports',
        loadChildren: () => import('app/modules/reports/reports.module').then((m) => m.ReportsModule),
    },
    {
        path: 'sales',
        loadChildren: () => import('app/modules/sales/sales.module').then((m) => m.SalesModule),
    },
    {
        path: 'admin',
        loadChildren: () => import('app/modules/admin/admin.module').then((m) => m.AdminModule),
    },
    {
        path: 'clearing',
        loadChildren: () => import('app/modules/clearing/clearing.module').then((m) => m.ClearingModule),
    },
    // Wildcard = 404
    {
        path: '**',
        component: GlobalPageNotFoundComponent,
    },
];
