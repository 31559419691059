// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/**
 * 1. Allgemein
 * ============
 *
 * @author  Eric Häusel <e.haeusel@pharmakon.software>
 */
.cw-login-input {
  margin-bottom: 10px;
  width: 100%;
}

.cw-login-input:focus {
  border-color: var(--color-primary);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgba(144, 144, 144, 0.4);
  outline: none;
}

:host .cw-login-error + .cw-button-wrapper {
  justify-content: center;
}

h3 {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-settings-changepassword/global-settings-changepassword.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;;;EAAA;AAOA;EACI,mBAAA;EACA,WAAA;AACJ;;AAEA;EACI,kCAAA;EACA,kFACI;EAEJ,aAAA;AADJ;;AAIA;EACI,uBAAA;AADJ;;AAIA;EACI,mBAAA;AADJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Eric Häusel <e.haeusel@pharmakon.software>\n */\n\n.cw-login-input {\n    margin-bottom: 10px;\n    width: 100%;\n}\n\n.cw-login-input:focus {\n    border-color: var(--color-primary);\n    box-shadow:\n        inset 0 1px 1px rgba(0, 0, 0, 0.075),\n        0 0 6px rgba(144, 144, 144, 0.4);\n    outline: none;\n}\n\n:host .cw-login-error + .cw-button-wrapper {\n    justify-content: center;\n}\n\nh3 {\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
