// Angular-Module
import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-global-help-nav',
    templateUrl: './global-help-nav.component.html',
    styleUrls: ['./global-help-nav.component.scss'],
})
export class GlobalHelpNavComponent implements OnInit, OnDestroy {
    /**
     * Konstruktor (inkl. dependency injection)
     */
    constructor() {}

    /**
     * Initialisieren
     */
    ngOnInit() {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {}
}
