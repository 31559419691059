import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatCellDef, MatColumnDef, MatHeaderCellDef, MatTable} from '@angular/material/table';
// GridComponent
import {GridComponent} from './../grid.component';
import {environment} from '@environment';
// Moment-Modul zur Datums-Verarbeitung
import {hasOwn} from '@shared/utils';

/**
 * Ausgelagerte Grid-Unterkomponente zur Anzeige von Kontakt-Infos mit Titel
 * @author Michael Schiffner <m.schiffner@pharmakon.software>
 */
@Component({
    selector: 'phscw-grid-contact-info',
    templateUrl: './grid-contact-info.component.html',
    styleUrls: ['./grid-contact-info.component.scss', './../grid.component.scss'],
})
export class GridContactInfoComponent implements OnInit, OnDestroy {
    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;

    // Spalten-Name der auf die Spalte verweist
    @Input()
    get name(): string {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
        if (this.columnDef) {
            this.columnDef.name = name;
        }
    }

    _name: string;

    // Zeigt den Inhalt der Notiz im Grid unter "Info", statt Titel (betrifft Termine und Aufgaben)
    showNoteInInfo = false;

    @ViewChild(MatColumnDef, {static: true}) columnDef: MatColumnDef;
    @ViewChild(MatHeaderCellDef, {static: true}) headerCellDef: MatHeaderCellDef;
    @ViewChild(MatCellDef, {static: true}) cellDef: MatCellDef;

    constructor(public table: MatTable<any>) {}

    ngOnInit() {
        if (this.table) {
            this.columnDef.name = this._name;
            this.columnDef.headerCell = this.headerCellDef;
            this.columnDef.cell = this.cellDef;
            this.table.addColumnDef(this.columnDef);
        }

        if (hasOwn(environment, 'contactsShowNoteInInfo')) {
            this.showNoteInInfo = environment.contactsShowNoteInInfo;
        }
    }

    ngOnDestroy() {
        if (this.table) {
            this.table.removeColumnDef(this.columnDef);
        }
    }
}
