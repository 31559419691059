import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {StorageService} from '@global/services/storage.service';
import {Notification} from '@shared/notification';
import {BackendService} from '@global/services/backend.service';
import {Observable} from 'rxjs';
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';

@Component({
    selector: 'phscw-input-form-notification',
    templateUrl: './input-form-notification.component.html',
    styleUrls: ['./input-form-notification.component.scss'],
})
export class InputFormNotificationComponent implements OnInit {
    activatedNotificationMenu = false;
    showNotification = true;

    @Input() dataNotification: Notification;
    @Input() notification_type: any;
    @Input() entity_type: any;
    @Input() entity_id = 0;

    @Input() notification: string;
    @Input() notificationToSelf = false;

    @Input() showValidFrom = true;
    @Input() showValidTo = true;
    @Input() hasNotification = false;
    showDelete = true;
    employeeName = '';

    constructor(
        private storageService: StorageService,
        private backendService: BackendService,
        private dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.dataNotification.notification_type = this.notification_type;
        this.dataNotification.entity_type = this.entity_type;

        if (this.notification) {
            this.dataNotification.notification = this.notification;
            this.showNotification = false;
        }
        const promise = this.storageService.getItem('ownUser');
        promise.then((userData: any) => {
            this.dataNotification.employee_id = userData.employee_id;
            this.employeeName = userData.lastname + ', ' + userData.firstname;
        });
    }

    toggleNotificationMenu() {
        this.activatedNotificationMenu = !this.activatedNotificationMenu;
        this.dataNotification.active = this.activatedNotificationMenu;
    }

    deleteNotifications() {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: 'Bestätigung zum Löschen',
                message: 'Wollen sie wirklich alle Benachrichtungen dieser Entität löschen?',
            },
        });

        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result) => {
            if (result.answer === 'yes') {
                const postBody = {
                    dataNotification: this.dataNotification,
                    entity_id: this.entity_id,
                };
                const postRequest$: Observable<any> = this.backendService.postRequest(
                    'Notifications/deleteNotifications/',
                    postBody,
                );
                postRequest$.subscribe((result) => {});
                this.showDelete = false;
            }
        });
    }
}
