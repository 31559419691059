// Angular-Module
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
// ReactiveX for JavaScript
import {Subject, Subscription} from 'rxjs';
// Service für Global-Notifications
import {GlobalNotificationsService} from './global-notifications.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {Notification} from '@shared/notification';
import {StorageService} from '@global/services/storage.service';
import {InitService} from '@global/services/init.service';
import {takeUntil} from 'rxjs/operators';
import {Listentry} from '@shared/listentry';
import {hasOwn} from '@shared/utils';
import {UserSettingsService} from '@global/services/user-settings.service';
import {AppCoreService} from '@global/services/app-core.service';

@Component({
    selector: 'phscw-global-notifications',
    templateUrl: './global-notifications.component.html',
    styleUrls: ['./global-notifications.component.scss'],
})
export class GlobalNotificationsComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    // Flag definiert ob gerade geladen wird
    loading = false;

    // Definiert, ob Benachrichtigungen angezeigt werden sollen
    showNotifications = false;

    // flag um das wackeln der glocke auszulösen
    doUnreadNotificationsExist = false;

    // Anzahl der Benachrichtigungen
    countNotifications = 0;

    // Benachrichtigungen
    data: Notification[] = [];
    notificationTypes: Listentry[] = [];
    // sollte immer aus dem backend überschrieben werden
    millisecondIntervall = 2 * 60 * 1000;
    intervalId = null;
    loggedOut = false;

    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Konstruktor
    constructor(
        private globalNotificationsService: GlobalNotificationsService,
        private storageService: StorageService,
        private initService: InitService,
        private router: Router,
        private userSettingsService: UserSettingsService,
        private appCore: AppCoreService,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Laden der benötigen listentry
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            this.loadListentry();
        });
        this.loadListentry();
        // Daten laden
        this.loadData();
        // Accountinfo-Popup bei Event "appLogout" schließen
        this.appCore.appLogout.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: any) => {
            this.loggedOut = true;
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
        });
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    // Es wurden Daten geändert
    onAppDataChanged(eventData: CWEvent) {
        // Prüfe, ob Änderung in Rolle-Stammdaten vorgenommen wurden
        if (eventData.sender == 'admin-notifications-data') {
            this.loadData();
        }
    }

    /**
     * Laden des generellen Listentries
     */
    loadListentry() {
        // Listentry laden um icon anzuzeigen
        const promiseNotificationType = this.storageService.getItem('listentries|notificationType');
        promiseNotificationType.then((value) => {
            if (value) {
                this.notificationTypes = value;
            }
        });

        // laden des intervalls
        const promise = this.storageService.getItem('config|notificationLoadIntervall');
        promise.then((value) => {
            if (value) {
                this.millisecondIntervall = value;
            }
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            // Function to load data with error handling and retry logic
            const loadDataWithRetry = async () => {
                try {
                    await this.loadData();
                } catch (error) {
                    console.error('Error loading data:', error);
                    // Optionally, you can add a delay before retrying
                    setTimeout(loadDataWithRetry, this.millisecondIntervall);
                }
            };
            // Im konfigurierten Abstand regelmäßig Daten laden
            this.intervalId = setInterval(() => {
                if (!this.loggedOut) {
                    loadDataWithRetry();
                }
            }, this.millisecondIntervall);
        });
    }

    /**
     * Herrausfischen des korrekten Icons
     * @param notificationType
     */
    findFittingIcon(notificationType) {
        const listentry = this.notificationTypes.find((listentry: Listentry) => listentry.list_key == notificationType);
        if (!listentry) {
            return;
        }
        const iconData = JSON.parse(listentry.list_data);

        if (hasOwn(iconData, 'icon')) {
            return iconData['icon'];
        }
    }

    /**
     * @brief   Daten laden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadData(): void {
        // Wenn die Notifications angezeigt werden, nicht nach laden
        if (this.showNotifications) {
            return;
        }
        // Flag "loading" aktivieren
        this.loading = true;

        const serviceRequest$ = this.globalNotificationsService.loadData();
        serviceRequest$.subscribe((result) => {
            // Geladene Daten als <Notification> in Component speichern
            this.data = result['data'];

            // WEnn Anzalh sich erhöht, Animation der Glocke auslösen
            if (this.countNotifications < this.data.length) {
                this.doUnreadNotificationsExist = true;
            }
            // Anzahl setzen
            this.countNotifications = this.data.length;

            // Flag "loading" deaktivieren
            this.loading = false;
        });
    }

    /**
     * Reagiere auf das Vormerken einer Benachrichtigung zum "gelesen"-Markieren
     * @param checked
     * @param id
     * @param index
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    onCheckboxChange(checked: boolean, id: number, index: number) {
        setTimeout(() => {
            this.data.splice(index, 1);
            this.countNotifications = this.data.length;
            this.markRead(id);
        }, 1000);
    }

    // wechsel zur entität
    navigateTo(url) {
        if (url != null) {
            this.toggleNotifications();
            // Route-Navigation ausführen
            this.router.navigate([url]);
        } else {
            console.error('navigation not implemented');
        }
    }

    /**
     * @param id
     * @brief   Benachrichtigung als gelesen markieren
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    markRead(id: number) {
        this.globalNotificationsService.markRead(id);
    }

    // alle als gelesen markieren
    markAllRead() {
        const serviceRequest$ = this.globalNotificationsService.markAllRead(this.data);
        serviceRequest$.subscribe(() => {
            this.data = [];
            this.countNotifications = 0;
        });
    }

    // Benachrichtigungen anzeigen / verstecken
    toggleNotifications() {
        this.showNotifications = !this.showNotifications;
        if (this.showNotifications) {
            this.doUnreadNotificationsExist = false;
        }
    }
}
