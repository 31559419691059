import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-targets-products',
    templateUrl: './chart-targets-products.component.html',
    styleUrls: ['./chart-targets-products.component.scss'],
})
export class ChartTargetsProductsComponent implements OnInit {
    xAxisTitle = new Date().getFullYear();

    constructor() {}

    ngOnInit() {}
}
