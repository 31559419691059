// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
*/
:host {
  width: 100%;
}

:focus {
  border-color: var(--color-primary);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgba(144, 144, 144, 0.4);
  outline: none;
  user-select: auto !important;
  -webkit-user-select: auto !important;
}

input[required]:invalid {
  border-color: var(--color-accent);
}

.text-input-errors {
  border: 1px solid var(--color-accent);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgba(255, 0, 0, 0.6);
}

.input-error-msg {
  background: var(--color-accent-light);
  color: var(--color-default-light);
  flex: none;
  margin: 5px 0;
  padding: 5px 10px;
  position: relative;
  z-index: 100;
}

.input-error-msg:after {
  border: 10px solid transparent;
  border-top: 0;
  border-bottom-color: var(--color-accent-light);
  content: "";
  height: 0;
  left: 3px;
  margin-top: -10px;
  position: absolute;
  top: 0;
  width: 0;
}

.phone {
  color: var(--color-default-dark);
}

.currency-alignment {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/input/input-text/input-text.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,WAAA;AAAJ;;AAGA;EACI,kCAAA;EACA,kFACI;EAEJ,aAAA;EACA,4BAAA;EACA,oCAAA;AAFJ;;AAKA;EACI,iCAAA;AAFJ;;AAKA;EACI,qCAAA;EACA,8EACI;AAHR;;AAOA;EACI,qCAAA;EACA,iCAAA;EACA,UAAA;EACA,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;AAJJ;;AAOA;EACI,8BAAA;EACA,aAAA;EACA,8CAAA;EACA,WAAA;EACA,SAAA;EACA,SAAA;EACA,iBAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;AAJJ;;AAOA;EACI,gCAAA;AAJJ;;AAOA;EACI,iBAAA;AAJJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n*/\n\n:host {\n    width: 100%;\n}\n\n:focus {\n    border-color: var(--color-primary);\n    box-shadow:\n        inset 0 1px 1px rgba(0, 0, 0, 0.075),\n        0 0 6px rgba(144, 144, 144, 0.4);\n    outline: none;\n    user-select: auto !important;\n    -webkit-user-select: auto !important;\n}\n\ninput[required]:invalid {\n    border-color: var(--color-accent);\n}\n\n.text-input-errors {\n    border: 1px solid var(--color-accent);\n    box-shadow:\n        inset 0 1px 1px rgba(0, 0, 0, 0.075),\n        0 0 6px rgba(255, 0, 0, 0.6);\n}\n\n.input-error-msg {\n    background: var(--color-accent-light);\n    color: var(--color-default-light);\n    flex: none;\n    margin: 5px 0;\n    padding: 5px 10px;\n    position: relative;\n    z-index: 100;\n}\n\n.input-error-msg:after {\n    border: 10px solid transparent;\n    border-top: 0;\n    border-bottom-color: var(--color-accent-light);\n    content: '';\n    height: 0;\n    left: 3px;\n    margin-top: -10px;\n    position: absolute;\n    top: 0;\n    width: 0;\n}\n\n.phone {\n    color: var(--color-default-dark);\n}\n\n.currency-alignment {\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
