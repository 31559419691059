import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-monthly-average-count-visits',
    templateUrl: './chart-monthly-average-count-visits.component.html',
    styleUrls: ['./chart-monthly-average-count-visits.component.scss'],
})
export class ChartMonthlyAverageCountVisitsComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
