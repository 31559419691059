// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Service dieses Shared-Moduls
import {ToolbarService} from '../toolbar.service';

@Component({
    selector: 'phscw-toolbar-remote-edetailer-invite',
    templateUrl: './toolbar-remote-edetailer-invite.component.html',
    styleUrls: ['./toolbar-remote-edetailer-invite.component.scss'],
})
export class ToolbarRemoteEdetailerInviteComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;
    // Tooltip
    @Input() tooltip: string = null;
    // Parameter
    @Input() parameter: any = {};

    // Konstruktor
    constructor(private toolbarService: ToolbarService) {}

    // Initialisierungen
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    // Events subscriben
    initializeEventSubscriptions() {}

    /*
     * Mail Link öffnen
     * @todo Link nicht fest im Code behalten
     */
    sendRemoteEdetailerInvite() {
        this.toolbarService.sendEdetailer(this.componentIdentifier);
        /*
         * let mailLink = document.createElement('a');
         * mailLink.href = 'mailto:h.schoenecker@pharmakon.software?subject=Einladung%20zur%20Pr%C3%A4sentation%0A&body=Sehr%20geehrte%20Frau%20M%C3%BCller%2C%0A%0ABitte%20klicken%20Sie%20auf%20folgenden%20Link%2C%20um%20die%20Pr%C3%A4sentation%20zu%20starten%3A%0A%0Ahttps%3A%2F%2Fpharmacrm.info%2FeDetailer%3Fcode%3D798202682859%0A%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen%2C%0A%0AHolger%20Sch%C3%B6necker%0A';
         * mailLink.click();
         * mailLink.remove();
         */
    }
}
