// Angular-Module
import {Component, Inject, OnInit, OnDestroy} from '@angular/core';

// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

// Interface für Dialogdaten
export interface PopupFormularData {
    title: string;
    message: string;
    answer: string;
    employees: [];
}

@Component({
    selector: 'phscw-popup-select-employees',
    templateUrl: './popup-select-employees.component.html',
    styleUrls: ['./popup-select-employees.component.scss'],
})
export class PopupSelectEmployeesComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    employeeData = [
        {
            id: 0,
            label: '',
        },
    ];

    result: any[];
    // Konstruktor
    constructor(
        public dialogRef: MatDialogRef<PopupSelectEmployeesComponent>,
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: PopupFormularData,
    ) {
        dialogRef.disableClose = true;
    }

    // Initialisierungen
    ngOnInit() {
        this.employeeData = this.data.employees;
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    // Dialog (über Referenz) schließen und Dialog-Ergebnis mitsenden
    clickSubmit() {
        this.dialogRef.close(this.result);
    }

    // Dialog schließen
    clickClose() {
        this.dialogRef.close();
    }

    // Bei jeder Änderung wird das Ergebnis
    employeeSelected(event: number[]) {
        this.result = [];

        for (const [key, value] of Object.entries(event)) {
            if (value) {
                this.result.push(key);
            }
        }
    }
}
