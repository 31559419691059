/**
 * @brief   Globaler Service zum Lesen & Schreiben der Benutzer-Einstellungen.
 * @details Benutzer-Einstellungen dürfen (im Gegensatz zu Berechtigungen)
 *          durch den Benutzer selbst verändert werden. Benutzer-Einstellungen
 *          verändern sich z.B. durch Verwendung von C-World und beinhalten
 *          Informationen wie zuletzt aufgerufenes Modul, eingestellte Filter,
 *          Sortierungen, etc.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {hasOwn} from '@shared/utils';
import {BackendService} from './backend.service';

// Konstanten
const STORAGE_PREFIX = 'UserSetting.';

@Injectable({
    // Root-Injector (app.module.ts) ist verantwortlich für das Instanziieren dieses globalen Services
    providedIn: 'root',
})
export class UserSettingsService {
    // Konstruktor (inkl. dependency injection)
    constructor(private backendService: BackendService) {}

    /**
     * @param key
     * @brief   Key aus Session-Storage entfernen
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    removeSetting(key: string): void {
        window.localStorage.removeItem(key);
    }

    /**
     * @param key
     * @brief   Lese Benutzer-Einstellung aus Session-Storage
     * @details Liefert das gesamte User-Settings-Objekt inklusive:
     *          - id
     *          - type
     *          - value
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    getSetting<T>(key: string): T {
        // Präfix voranstellen
        key = STORAGE_PREFIX + key;

        // Prüfe, ob Key überhaupt vorhanden ist
        const setting: string = window.localStorage.getItem(key);
        if (setting === null) {
            return undefined;
        }

        // Lese Wert aus Session Storage
        const settingObject: T = JSON.parse(window.localStorage.getItem(key));

        // Rückgabe
        return settingObject;
    }

    /**
     * @param key
     * @brief   Liest den Wert einer Benutzer-Einstellung aus.
     * @details Liefert nur "value" des User-Settings-Objekts.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    getValue<T>(key: string): T {
        // Lade User-Settings-Object
        const setting: object = this.getSetting<any>(key);

        // Setting wurde nicht gefunden
        if (!setting) {
            console.error('Pharmakon - UserSetting ' + key + ' existiert nicht!');
            return null;
        }

        // Ermittle den Wert
        let value: T;
        if (hasOwn(setting, 'value')) {
            value = setting['value'];
        } else {
            value = null;
        }

        // Rückgabe des Werts
        return value;
    }

    /**
     * @param key
     * @param value
     * @brief   Schreibe Benutzer-Einstellung in Session-Storage
     * @details Wird auch durch setMany() aufgerufen.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    setSetting<T>(key: string, value: T): void {
        // Präfix voranstellen
        key = STORAGE_PREFIX + key;

        // Speichern
        window.localStorage.setItem(key, JSON.stringify(value));
    }

    /**
     * @brief   Ändert den Wert einer Benutzer-Einstellung.
     * @details Änderung wird in localStorage und im Backend vorgenommen.
     * @param saveBackend
     * @param value
     * @param key
     * @param   saveAsync Standardverhalten (false) ist das Senden der Requests über die Queue, also sequentiell.
     *                    Mittels true lässt sich der Request asynchron/parallel ans Backend senden, damit folgende Requests nicht warten müssen.
     *                    Das ist vor allem beim einfachen Speichern von User-Settings nützlich (set and forget).
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    setValue<T>(key: string, value: T, saveBackend = true, saveAsync = false): void {
        // Lade User-Settings-Object
        const setting: object = this.getSetting<any>(key);

        // Prüfe ob Setting-Key überhaupt gefunden wurde
        if (typeof setting === 'undefined') {
            return;
        }

        // Überschreibe Wert des Settings
        setting['value'] = value;

        // Setting-Key entfernen
        this.removeSetting(key);
        // Aktualisierten Key speichern
        this.setSetting<any>(key, setting);

        // Im Backend speichern?
        if (saveBackend === true) {
            let postRequest$: Observable<any>;

            if (saveAsync) {
                postRequest$ = this.backendService.postRequestAsync('Users/setUsersetting/' + key, setting);
            } else {
                postRequest$ = this.backendService.postRequest('Users/setUsersetting/' + key, setting);
            }

            postRequest$.subscribe();
        }
    }

    /**
     * @param settings
     * @brief   Legt mehrere Benutzer-Einstellungen im Session-Storage ab
     * @details Wird nach erfolgreichem Login aufgerufen um die im Backend
     *          geladenen Benutzer-Einstellungen zu speichern.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    storeManySettings(settings: object): void {
        // Alle vorhandenen Keys (User-Settings) durchlaufen
        for (const key in settings) {
            if (hasOwn(settings, key)) {
                // Key speichern
                this.setSetting(key, settings[key]);
            }
        }
    }
}
