import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// CDK & Angular Material
import {CdkTableModule} from '@angular/cdk/table';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatTableModule} from '@angular/material/table';
// Für Übersetzung benötigte Module
import {TranslateModule} from '@ngx-translate/core';
// Angular-Split
import {AngularSplitModule} from 'angular-split';
// Service dieses Shared-Modules
import {OrderTrackingService} from './order-tracking.service';
// Komponenten
import {OrderTrackingComponent} from './order-tracking/order-tracking.component';
// Shared Module einbinden
import {CharacteristicsModule} from '../../shared/characteristics/characteristics.module';
import {DocumentsModule} from '../../shared/documents/documents.module';
import {GridModule} from '../../shared/grid/grid.module';
import {InputModule} from '../../shared/input/input.module';
import {OutputModule} from '../../shared/output/output.module';
import {SubmodulesMenuModule} from '../../shared/submodules-menu/submodules-menu.module';
import {ToolbarModule} from '../../shared/toolbar/toolbar.module';
import {DirectivesModule} from '@shared/directives/directives.module';
import {MatIconModule} from '@angular/material/icon';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        AngularSplitModule,
        GridModule,
        InputModule,
        OutputModule,
        CharacteristicsModule,
        SubmodulesMenuModule,
        ToolbarModule,
        DocumentsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatRadioModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        FormsModule,
        MatTableModule,
        CdkTableModule,
        TranslateModule,
        DirectivesModule,
        MatIconModule,
        FontAwesomeModule,
    ],
    declarations: [OrderTrackingComponent],
    exports: [OrderTrackingComponent, TranslateModule],
    providers: [OrderTrackingService, {
        provide: MAT_DATE_LOCALE,
        useValue: 'de-DE',
    }],
})
export class OrderTrackingModule {}
