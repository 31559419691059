// Angular-Module
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'phscw-popup-loading',
    templateUrl: './popup-loading.component.html',
    styleUrls: ['./popup-loading.component.scss'],
})
export class PopupLoadingComponent implements OnInit, OnDestroy {
    // Konstruktor
    // eslint-disable-next-line new-cap
    constructor(
        public dialogRef: MatDialogRef<PopupLoadingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        dialogRef.disableClose = true;
    }

    // Initialisierungen
    ngOnInit() {}

    // Aufräumen
    ngOnDestroy() {}
}
