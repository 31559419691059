/**
 * @brief   Globaler Service zur Bewachung von Routen abhängig von Authentifizierung.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
// Globale Services
import {AuthService} from './auth.service';
import {UserPermissionsService} from './user-permissions.service';

@Injectable({
    // Root-Injector (app.module.ts) ist verantwortlich für das Instanziieren dieses globalen Services
    providedIn: 'root',
})
export class AuthGuardService {
    constructor(
        private authService: AuthService,
        private router: Router,
        private userPermissions: UserPermissionsService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        return this.checkRoutePermission(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    checkRoutePermission(url: string): boolean {
        // Einige Seiten sind nur durch spezielle Berechtigungen verfügbar
        if (url.includes('/admin')) {
            const adminPermission: boolean = this.userPermissions.getPermissionValue('enableModuleAdmin');
            if (adminPermission === true) {
                return adminPermission;
            }
            console.error('Keine Admin-Rechte vorhanden.');
            // Zugriff auf Admin-Bereich ist nicht gestattet...auf "institutions" weiterleiten, sonst bleibt der User auf der Loginseite hängen
            this.router.navigate(['/institutions']);
        }
        // Route ist verfügbar
        return true;
    }
}
