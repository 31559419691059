import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {extractFirstWord} from '@shared/utils';

@Injectable({providedIn: 'root'})
export class GridColumnsService {
    public eventSaveSelection = new Subject<CWEvent>();
    public eventLayoutSaved = new Subject<CWEvent>();
    public eventLayoutSaveStarted = new Subject<CWEvent>();
    // Subject (Observable) definieren "Ändere Layout"
    public eventGridReloadLayouts = new Subject<CWEvent>();

    constructor(
        private backendService: BackendService,
        private router: Router,
    ) {}

    /**
     * @param selection
     * @brief   Speichert ein Layout in der DB
     * @returns  Observable<any>
     */
    saveLayout(selection: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('Grid/saveLayout', selection);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * Benachrichtigt das GridColumnsPanel, ob das Layout erfolgreich gespeichert wurde
     * @param target
     * @param data
     */
    notifyGridColumnsPanelOfSaveResult(target: string, data: boolean) {
        // Target ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);

        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target,
            data,
        };
        // Event auslösen
        this.eventLayoutSaved.next(eventData);
    }

    notifyGridColumnsPanelOfSaveStart(target: string) {
        // Target ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);

        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target,
            data: '',
        };
        // Event auslösen
        this.eventLayoutSaveStarted.next(eventData);
    }

    // Neues Layout gespeichert => Layouts neu laden
    reloadLayouts(gridId: string, layoutId: number) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'gridService',
            target: gridId,
            data: layoutId,
        };
        // Event auslösen
        this.eventGridReloadLayouts.next(eventData);
    }
}
