// Angular-Module
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatCellDef, MatColumnDef, MatHeaderCellDef, MatTable} from '@angular/material/table';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// GridComponent
import {SupplementaryNumber} from '@shared/supplementary-number';
import {GridComponent} from './../grid.component';

@Component({
    selector: 'phscw-grid-supplementary-numbers',
    templateUrl: './grid-supplementary-numbers.component.html',
    styleUrls: ['./grid-supplementary-numbers.component.scss', './../grid.component.scss'],
})
export class GridSupplementaryNumbersComponent implements OnInit, OnDestroy {
    // Referenz auf Spalte
    @ViewChild(MatColumnDef, {static: true}) columnDef: MatColumnDef;
    @ViewChild(MatHeaderCellDef, {static: true}) headerCellDef: MatHeaderCellDef;
    @ViewChild(MatCellDef, {static: true}) cellDef: MatCellDef;

    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;

    // Spalten-Name der auf die Spalte verweist
    private _name: string;
    @Input() set name(name: string) {
        this._name = name;
        if (this.columnDef) {
            this.columnDef.name = name;
        }
    }

    get name(): string {
        return this._name;
    }

    // Kopfzeile der Tabellenspalte
    @Input() header = '';
    // Typ der zusätzlichen Nummer
    @Input() supplementaryNumberType = '';
    column = {
        columnDef: '',
        formatWidth: '200px',
    };

    /**
     * Konstruktor (inkl. dependency injection)
     * @param table
     */
    constructor(private table: MatTable<any>) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        if (this.table) {
            this.columnDef.name = this._name;
            this.column.columnDef = this._name;
            this.columnDef.headerCell = this.headerCellDef;
            this.columnDef.cell = this.cellDef;
            this.table.addColumnDef(this.columnDef);
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        if (this.table) {
            this.table.removeColumnDef(this.columnDef);
        }

        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Nummern durchlaufen und aneinanderhängen
     * @param rows
     */
    prepareSupplementaryNumberString(rows: SupplementaryNumber[]): string {
        // Init
        let returnValue = '';

        if (rows == undefined) {
            console.error('phscw-grid-supplementary-numbers row undefined');
            return '';
        }

        rows.forEach((row: any) => {
            // nur aktive Nummern des gewählten Typs anzeigen
            if (row.number_type === this.supplementaryNumberType && !row.locked && row.supplementary_number) {
                // Komma vorstellen ab der zweiten gefundenen Nummber
                if (returnValue.length > 0) {
                    returnValue += ', ';
                }

                returnValue += row.supplementary_number;
            }
        });
        // Wert zurückgeben
        return returnValue;
    }
}
