import {EventsPeopleService} from './../../../modules/events/events-people/events-people.service';
/**
 * @brief   Popup für Einzelbearbeitung eines Listentry-Werts über dem Grid, zunächst nur für Teilnehmer Status und Rolle verwendet
 * @author  Tristan Krakau <t.krakau@pharmakon.software>
 */

// Angular-Module
import {
    Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, OnDestroy,
} from '@angular/core';
import {NgForm} from '@angular/forms';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

// Interface für Dialogdaten
export interface DialogData {
    entityId: number;
    baseEntityId?: number;
    label: string;
    currentValue: any;
    onChangeCallback: any;
    listentry: string;
    fieldName: string;
}

@Component({
    selector: 'phscw-grid-cell-edit-listentry',
    templateUrl: './grid-cell-edit-listentry.component.html',
    styleUrls: ['./grid-cell-edit-listentry.component.scss'],
})
export class GridCellEditListentryComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // bisheriger Wert der Auswahl, falls im weiteren Verlauf für Vergleich o.ä. benötigt
    originalValue: any;

    editEntity: any = {
        id: 0,
        baseEntityId: 0,
        value: '',
        fieldName: '',
    };

    // Callback-Funktion, die nach dem Speichern aufgerufen wird, um Daten in Liste zu aktualisieren
    onChangeCallback: any;

    // Name der Liste wird an die Input-Komponente weitergegeben, daraus werden die Auswahlwerte ermittelt
    listentry: string;

    // Variable, die anzeigen soll, ob das Modul zum ersten mal aufgerufen wird.
    initialized = false;

    // Flag definiert ob gerade geladen wird
    loading = false;

    // Flag definiert ob gerade gespeichert wird
    saving = false;

    // Fehler
    error = false;

    // Referenz auf Form
    @ViewChild('editListentryForm', {static: false}) editListentryForm: NgForm;

    // Überschrift
    @Input() label: string;

    invalidateTimeout: any = null;
    formInitialized = false;

    // Event das beim erfolgreichen Submitten ausgelöst wird und die Anzahl der gesetzten Kennzeichen übergeben soll.
    @Output() submitSuccess = new EventEmitter<any>();

    // Event das bei Validierung der Form ausgelöst wird.
    @Output() formValid = new EventEmitter<boolean>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param data
     * @param dialogRef
     * @param eventsPeopleService
     */
    constructor(
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private dialogRef: MatDialogRef<GridCellEditListentryComponent>,
        private eventsPeopleService: EventsPeopleService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        this.label = this.data.label;
        // aktuellen Wert merken für spätere Prüfung auf Änderungen
        this.originalValue = this.data.currentValue;

        // wird nach dem Speichern aufgerufen, um z.B. die Grid-Zelle zu aktualisieren
        this.onChangeCallback = this.data.onChangeCallback;

        // Listentries-Name, wird der Input-Komponente mitgeteilt
        this.listentry = this.data.listentry;

        // Mini-Entity bauen, welche editiert und gespeichert wird
        this.editEntity.id = this.data.entityId;
        this.editEntity.value = this.data.currentValue;
        this.editEntity.fieldName = this.data.fieldName;

        if (!this.isUndefined(this.data.baseEntityId)) {
            /*
             * falls die entity-ID alleine nicht ausreicht, gibt die baseEntityId den Kontext an,
             * z.B. die event_id ist Kontext und person_id die bearbeitete Entity
             */
            this.editEntity.baseEntityId = this.data.baseEntityId;
        }

        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions() {
        this.submitSuccess.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            this.data.onChangeCallback(result);
        });
    }

    /**
     * Daten laden
     */
    loadData(): void {
        // Falls noch nicht geladen wird
        if (!this.loading || !this.initialized) {
            // Flag setzen
            this.loading = true;

            // alles fertig geladen
            this.loading = false;
        }
    }

    /**
     * @brief   Sendet geänderte Daten zum Speichern ans Backend
     */
    clickSubmit(): void {
        // Nur gültige Formulare submitten
        if (this.editListentryForm.form.valid) {
            if (this.editEntity.value !== this.originalValue) {
                this.saveData(this.editEntity);
            } else {
                // es gab keine Änderung, einfach schließen
                this.dialogRef.close();
            }
        }
    }

    /**
     * @brief  Abbrechen-Button schließt Dialog ohne weitere Aktionen
     */
    clickCancel(): void {
        this.dialogRef.close();
    }

    /**
     * @param entityToSave
     * @brief   Sendet geänderte Daten zum Speichern ans Backend
     */
    saveData(entityToSave: any): void {
        // TODO wenn der Editor auch für andere Typen von Listentries/Entities genutzt wird, muss hier eine Fallunterscheidung gemacht werden

        const data = {
            event_id: entityToSave.baseEntityId,
            person_id: entityToSave.id,
        };

        // Feldname und Wert dynamisch setzen
        data[entityToSave.fieldName] = entityToSave.value;

        // Flag setzen
        this.saving = true;

        // Speichern
        const serviceRequest$ = this.eventsPeopleService.editEventPerson(data);
        serviceRequest$.subscribe(
            // onNext
            (result: CWResult) => {
                if (result['success'] && result['data']) {
                    /*
                     * Signalisieren, dass sich Daten geändert haben
                     * TODO Daten in Details-Modul aktualisieren sich nicht - warum?
                     */
                    this.eventsPeopleService.dataChanged(data.event_id);

                    // Event auslösen, ruft den Callback zur Aktualisierung der Liste auf
                    this.submitSuccess.emit(result['data']);
                }

                // Flag setzen
                this.saving = false;

                /*
                 * Event auslösen um Popup zu schließen
                 * this.cancel.emit("Dialog closed after save");
                 */
                this.dialogRef.close();
            },
            // onError
            (error) => {
                console.error(error);
                // Flag setzen
                this.error = true;
                this.saving = false;
            },
        );
    }

    /**
     * @brief   Überprüft, ob Wert gesetzt ist.
     * @param value
     * @param   any   value   Wert der überprüft werden soll.
     * @returns  boolean
     */
    isUndefined(value: any): boolean {
        return (
            typeof value === 'undefined' ||
            value === null ||
            (Object.keys(value).length === 0 && value.constructor === Object)
        );
    }
}
