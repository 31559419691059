// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* Feste Spaltenbreite für Kontakt-Methode */
.cw-grid-column-contact-method {
  width: 125px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid/grid-contact-method/grid-contact-method.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB,4CAAA;AACA;EACI,YAAA;AAEJ","sourcesContent":["/* Feste Spaltenbreite für Kontakt-Methode */\n.cw-grid-column-contact-method {\n    width: 125px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
