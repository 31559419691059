import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../cw-event';

@Injectable({providedIn: 'root'})
export class SubmodulesMenuService {
    // Aufklappen/Einklappen Icon wurde geklickt
    public eventSubmoduleMenuToggleClicked = new Subject<CWEvent>();

    constructor() {}

    /**
     * @brief   Löst Event aus für Menü auf- oder zuklappen
     * @param   string menu
     * @param menu
     * @param menuExpanded
     * @param   string menuExpanded
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    submoduleMenuToggled(menu: string, menuExpanded: boolean) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: menu,
            target: '',
            data: {menuExpanded},
        };
        // Event auslösen
        this.eventSubmoduleMenuToggleClicked.next(eventData);
    }
}
