import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-quarterly-sales',
    templateUrl: './chart-quarterly-sales.component.html',
    styleUrls: ['./chart-quarterly-sales.component.scss'],
})
export class ChartQuarterlySalesComponent implements OnInit {
    @Input() quarters: any = [
        {
            id: 1,
            label: 'Quartal 1',
            value: 1,
        },
        {
            id: 2,
            label: 'Quartal 2',
            value: 4,
        },
        {
            id: 3,
            label: 'Quartal 3',
            value: 7,
        },
        {
            id: 4,
            label: 'Quartal 4',
            value: 10,
        },
    ];

    @Input() backendOptions: any = null;
    currentQuartal = 1;

    constructor() {}

    ngOnInit() {
        this.currentQuartal = this.getQuarter();
        this.selectQuarter(this.currentQuartal);
    }

    /*
     * setzt das gewählte Quartal
     */
    selectQuarter(event) {
        this.backendOptions = {selectedQuarter: event};
    }

    getQuarter() {
        const month = new Date().getMonth();
        if (month > 0 && month <= 3) {
            return 1;
        }
        if (month > 3 && month <= 6) {
            return 4;
        }
        if (month > 6 && month <= 9) {
            return 7;
        }
        if (month > 9 && month <= 12) {
            return 10;
        }
    }
}
