/**
 * Wird für CRM-Personen verwendet.
 */
import {Institution} from './institution';

export class Person {
    id = 0;
    lifetime_doctornumber?: string = '';
    icons = '';

    firstname = '';
    lastname = '';

    person_salutation = '';
    person_salutation_letter = '';
    person_greeting_letter = '';
    person_title = '';
    person_function = '';
    person_specialization1 = '';
    person_specialization2 = '';

    phone1 = '';
    phone2 = '';
    phone3 = '';
    fax = '';
    mail = '';
    internet = '';

    // Privatadresse
    street = '';
    street_number_from = '';
    street_number_to = '';
    zipcode = '';
    city = '';

    external_customer_id1?: string;
    external_customer_id2?: string;

    institutions: Institution[];

    // Zusatzfelder für Ansprechpartner
    custom_field1: any = null;
    custom_field2: any = null;
    custom_field3: any = null;
    custom_field4: any = null;

    // Standardfelder
    create_date?: Date;
    create_user?: string = '';
    edit_date?: Date;
    edit_user?: string = '';
    delete_date?: Date;
    delete_user?: string = '';
    deleted = 'N';
    // added for compiling reasons
    assistant1?: any;
    assistant2?: any;
    assistant3?: any;
    onekey_number?: any;
    health_insurance_association_number?: any;
    type1?: any;
    person_specialization_focus?: any;
    name_suffix?: string;
    isB2C?: boolean = false;

    constructor(institutionId: number) {
        this.id = 0;
        this.firstname = '';
        this.lastname = '';

        if (institutionId > -1) {
            // Für neue Person auch direkt die Einrichtung "verbinden"
            const addInstitution: Institution = new Institution();
            addInstitution.id = institutionId;
            this.institutions = [];
            this.institutions.push(addInstitution);
        }
    }
}
