/**
 * @brief   Shared SubmodulesMenuModule zur Freigabe der SubmodulesMenuComponent.
 * @details Um die SubmodulesMenuComponent in Feature Modulen verwenden zu können,
 *          wird dieses Shared Module eingebunden.
 *
 *          // Shared Modules einbinden
 *          import {SubmodulesMenuModule} from '../../shared/submodules-menu/submodules-menu.module';
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
// Angular-Material
import {MatTooltipModule} from '@angular/material/tooltip';
// Für Übersetzung benötigte Module
import {TranslateModule} from '@ngx-translate/core';
// Komponente
import {SubmodulesMenuComponent} from './submodules-menu/submodules-menu.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [CommonModule, MatTooltipModule, TranslateModule, FontAwesomeModule],
    declarations: [SubmodulesMenuComponent],
    exports: [SubmodulesMenuComponent, TranslateModule],
})
export class SubmodulesMenuModule {}
