export class LooseObjectUser {
    deleted?: any;
    edit_user?: any;
    edit_date?: any;
    create_user?: any;
    display_right?: any;
    create_date?: any;
    delete_date?: any;
    edit_right?: any;
    label?: any;
    option_sort?: any;
    delete_user?: any;
    characteristic_option_id?: any;
}
