import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-average-count-visits-team-view',
    templateUrl: './chart-average-count-visits-team-view.component.html',
    styleUrls: ['./chart-average-count-visits-team-view.component.scss'],
})
export class ChartAverageCountVisitsTeamViewComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
