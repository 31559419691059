import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatCellDef, MatColumnDef, MatHeaderCellDef, MatTable} from '@angular/material/table';
// Service dieses Shared-Moduls
import {GridService} from './../../grid.service';
// GridComponent
import {GridComponent} from './../grid.component';

/**
 * Ausgelagerte Grid-Unterkomponente zur Anzeige von Up- und Down-Buttons im Grid
 * @author Michael Schiffner <m.schiffner@pharmakon.software>
 */
@Component({
    selector: 'phscw-grid-up-down',
    templateUrl: './grid-up-down.component.html',
    styleUrls: ['./grid-up-down.component.scss', './../grid.component.scss'],
})
export class GridUpDownComponent implements OnInit, OnDestroy {
    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;

    // Spalten-Name der auf die Spalte verweist
    @Input()
    get name(): string {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
        if (this.columnDef) {
            this.columnDef.name = name;
        }
    }

    _name: string;

    @ViewChild(MatColumnDef, {static: true}) columnDef: MatColumnDef;
    @ViewChild(MatHeaderCellDef, {static: true}) headerCellDef: MatHeaderCellDef;
    @ViewChild(MatCellDef, {static: true}) cellDef: MatCellDef;

    constructor(
        private gridService: GridService,
        public table: MatTable<any>,
    ) {}

    ngOnInit() {
        if (this.table) {
            this.columnDef.name = this._name;
            this.columnDef.headerCell = this.headerCellDef;
            this.columnDef.cell = this.cellDef;
            this.table.addColumnDef(this.columnDef);
        }
    }

    ngOnDestroy() {
        if (this.table) {
            this.table.removeColumnDef(this.columnDef);
        }
    }

    // Klick auf "Up"-Icon in einer Datenzeile für Sortierungen
    handleRowUp(row: any) {
        this.gridService.rowUpClicked(this.gridConnection.gridId, row);
    }

    // Klick auf "Down"-Icon in einer Datenzeile für Sortierungen
    handleRowDown(row: any) {
        this.gridService.rowDownClicked(this.gridConnection.gridId, row);
    }
}
