/**
 * @brief   Globaler Service zum Lesen der Benutzer-Berechtigungen.
 * @details Benutzer-Berechtigungen werden über zugewiesene Rollen durch das
 *          Backend gesetzt und dürfen NICHT durch den Benutzer verändert werden.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {Injectable} from '@angular/core';
import {hasOwn} from '@shared/utils';

// Konstanten
const STORAGE_PREFIX = 'UserPermission.';

@Injectable({
    // Root-Injector (app.module.ts) ist verantwortlich für das Instanziieren dieses globalen Services
    providedIn: 'root',
})
export class UserPermissionsService {
    // Debug-Informationen ausgeben <true> oder nicht <false>
    private debug = false;

    // Konstruktor
    constructor() {}

    /**
     * @param key
     * @brief   Lese Benutzer-Berechtigung
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    getPermission<T>(key: string): T {
        // Präfix voranstellen
        key = STORAGE_PREFIX + key;

        // Lese Wert aus Session Storage
        const settingValue: T = JSON.parse(window.localStorage.getItem(key));

        // Rückgabe
        return settingValue;
    }

    /**
     * @param key
     * @brief   Liest den Wert einer Benutzer-Berechtigung aus.
     * @details Liefert nur "value" des User-Permission-Objekts.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    getPermissionValue<T>(key: string): T {
        // Lade User-Settings-Object
        const permission: object = this.getPermission<any>(key);

        // Setting wurde nicht gefunden
        if (!permission) {
            console.error('Pharmakon - UserPermission ' + key + ' existiert nicht!');
            return null;
        }

        // Ermittle den Wert
        let value: T;
        if (hasOwn(permission, 'value')) {
            value = permission['value'];
        } else {
            value = null;
        }

        // Rückgabe des Werts
        return value;
    }

    /**
     * @param key
     * @param value
     * @brief   Schreibe Benutzer-Berechtigung
     * @details Dies darf nicht vom Benutzer selbst durchgeführt werden.
     *          Benutzer-Berechtigungen werden durch Rollen definiert.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    setPermission<T>(key: string, value: T): void {
        // Präfix voranstellen
        key = STORAGE_PREFIX + key;

        // Speichern
        window.localStorage.setItem(key, JSON.stringify(value));
    }

    /**
     * @param permissions
     * @brief   Legt mehrere Benutzer-Berechtigungen im Session-Storage ab
     * @details Wird nach erfolgreichem Login aufgerufen um die im Backend
     *          geladenen Benutzer-Berechtigungen zu speichern.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    storeManyPermissions(permissions: Array<any>): void {
        // Alle vorhandenen Keys (User-Permissions) durchlaufen
        for (const permission of permissions) {
            // Speichern
            this.setPermission(permission['name'], permission);
        }
    }
}
