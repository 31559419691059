// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {BehaviorSubject} from 'rxjs';

/**
 * Tree node data with nested structure.
 * Each node has a name, and a type or a list of children.
 */
export class TreeNode {
    children?: TreeNode[];
    fullname: string;
    name: string;
    id: number;
    parent_id: number;
    level: number;
    path: string;
}

/**
 * Flat node with expandable and level information
 * Wird aktuell nicht verwendet
 */
export class TreeFlatNode {
    fullname: string;
    name: string;
    id: number;
    parent_id: number;
    level: number;
    expandable: boolean;
    path: string;
}

/**
 * Tree database - baut einen Baum aus einem JSON-Objekt als verschachtelte
 * Liste von "TreeNode".
 */
@Injectable()
export class TreeDatabase {
    dataChange: BehaviorSubject<TreeNode[]> = new BehaviorSubject<TreeNode[]>([]);

    get data(): TreeNode[] {
        return this.dataChange.value;
    }

    initialize(treeData: any) {
        // Baue Tree-Nodes vom JSON-Objekt --> liefert Liste von "TreeNode" mit verschaltelten "file node" als Kind-Elemente
        const data = this.buildTree(treeData, 0);
        // Änderung bekanntgeben
        this.dataChange.next(data);
    }

    /**
     * @brief   Baumstruktur aufbauen.
     * @param value
     * @param level
     * @param   any     value   JSON-Objekt oder Sub-Tree eines JSON-Objekts
     * @returns  list of "TreeNode"
     */
    buildTree(value: any, level: number): TreeNode[] {
        const data: any[] = [];

        // eslint-disable-next-line guard-for-in
        for (const k in value) {
            const v = value[k];
            const node = new TreeNode();
            node.fullname = `${k}`;

            // Region ID ermitteln
            const nodeName = node.fullname.split('_');
            node.name = nodeName[1];

            // Parent-IDs ermitteln
            node.path = nodeName[0];

            // Region ID ermitteln
            const nodeKey = node.path.split('|');
            node.id = parseInt(nodeKey[nodeKey.length - 1], 10);

            // Ebene übergeben
            node.level = level;
            if (node.level > 0) {
                node.parent_id = parseInt(nodeKey[nodeKey.length - 2], 10);
            }

            if (v === null || v === undefined) {
                // no action
            } else if (typeof v === 'object') {
                node.children = this.buildTree(v, level + 1);
            }
            data.push(node);
        }

        return data;
    }
}
