import {Component, Inject, OnInit} from '@angular/core';
// Eigenen Service einbinden
import {GridColumnsService} from './../grid-columns.service';
// Angular-Material
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// Globale Services einbinden
import {UserPermissionsService} from '@global/services/user-permissions.service';
// Shared Components
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
// Service der Selektion
import {GridSelectionService} from './../../grid-selection/grid-selection.service';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Klasse für Backend-Antworten laden
import {CWResult} from '@shared/cw-result';

@Component({
    selector: 'phscw-grid-columns-save-popup',
    templateUrl: './grid-columns-save-popup.component.html',
    styleUrls: ['./grid-columns-save-popup.component.scss'],
})
export class GridColumnsSavePopupComponent implements OnInit {
    // Name des Filters
    layoutname = '';
    // Für welche Rollen die Selektion gespeichert werden sollen
    roleIds: number[] = [];
    // Checkbox: soll die Selektion für Rollen gespeichert werden
    saveRoles = false;

    // Darf das Layout beim Speichern für andere Rollen freigegeben werden.
    allowClearanceLayouts = false;
    // Welche Rollen gibt es für die Zusätzlich Daten gespeichert werden können
    roles: any[] = [];

    constructor(
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<GridColumnsSavePopupComponent>,
        private dialog: MatDialog,
        private gridColumnsService: GridColumnsService,
        private gridSelectionService: GridSelectionService,
        private userPermissionsService: UserPermissionsService,
        private translationService: TranslateService,
    ) {}

    ngOnInit() {
        // Prüfe ob die Selektion mehreren Rollen zugeordnet werden darf
        this.checkAllowClearanceLayouts();
    }

    // Läd mithilfe des SelectionService alle Rollen für die Rollenfreigabe
    loadRoles() {
        const $roleRequest = this.gridSelectionService.getRoles();
        $roleRequest.subscribe((result: any) => {
            if (result['success'] && result['data'] !== null) {
                this.roles = result['data'];
            }
        });
    }

    saveLayout(overwriteLayout = false) {
        // Zuerst wird das Popup geschlossen. Die folgenden Schritte werden trotzdem ausgeführt
        this.clickAndClose();
        // Wenn der Haken zum Speichern von Rollen nicht gesetzt ist die Rollen zurücksetzen
        if (!this.saveRoles) {
            this.roleIds = [];
        }
        // Benachrichtige das zugehörige Panel vom Start des Speichervorgangs
        this.gridColumnsService.notifyGridColumnsPanelOfSaveStart(this.data['gridId']);
        const saved = this.gridColumnsService.saveLayout({
            title: this.layoutname,
            frontend_array: this.data['frontendArray'],
            grid_id: this.data['gridId'],
            role_ids: this.roleIds,
            overwrite_flag: overwriteLayout,
        });
        saved.subscribe((result: CWResult) => {
            // Wenn die Selektion erfolgreich gespeichert wurde, speichere Sie in das Storage
            let successfulSave = false;
            // Wenn das Speichern fehlgeschlagen ist  => early-Return
            if (!result['success'] && (typeof result['data'] === 'undefined' || result['data'] === null)) {
                this.gridColumnsService.notifyGridColumnsPanelOfSaveResult(this.data['gridId'], successfulSave);
                return;
            } if (
                !result['success'] &&
                result['data'] !== null &&
                typeof result['data']['confirmOverwrite'] !== 'undefined' &&
                result['data']['confirmOverwrite'] === true
            ) {
                this.confirmOverwrite();
                return;
            } if (
                !result['success'] &&
                result['data'] !== null &&
                typeof result['data']['noOverwriteRights'] !== 'undefined' &&
                result['data']['noOverwriteRights'] === true
            ) {
                this.openSaveForbiddenPopup();
                return;
            }
            successfulSave = true;
            // Wechsle auf das gerade gespeicherte Layout
            this.gridColumnsService.reloadLayouts(this.data['gridId'], result['data'][0]['id']);

            this.gridColumnsService.notifyGridColumnsPanelOfSaveResult(this.data['gridId'], successfulSave);
        });
    }

    clickAndClose() {
        // Dialog (über Referenz) schließen und Dialog-Ergebnis mitsenden
        this.dialogRef.close();
    }

    /**
     * Öffne ein Popup zum Bestätigen, dass ein Layout überschrieben werden soll
     */
    confirmOverwrite() {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: this.translationService.instant('SHARED.GRID.COLUMNS.POPUP.OVERWRITELAYOUTHEADER'),
                message: this.translationService.instant('SHARED.GRID.COLUMNS.POPUP.OVERWRITELAYOUTTEXT'),
            },
        });

        // auf Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result: any) => this.onConfirmationResult(result.answer));
    }

    /**
     * Ergebnis des Popups behandeln
     * @param answer
     */
    onConfirmationResult(answer: string) {
        if (answer === 'no') {
            // Informiere die Einbindende Komponente, dass nicht gespeichert wurde.
            this.gridColumnsService.notifyGridColumnsPanelOfSaveResult(this.data['gridId'], false);
            return;
        }
        this.saveLayout(true);
    }

    /**
     * Der User hat nicht die nötigen Rechte, um existierende Selektionen zu überschreiben
     */
    openSaveForbiddenPopup() {
        // Dialog konfigurieren und öffnen
        const dialogRef2 = this.dialog.open(PopupMessageComponent, {
            width: '350px',
            data: {
                title: this.translationService.instant('SHARED.GRID.COLUMNS.POPUP.OVERWRITELAYOUTFORBIDDENHEADER'),
                message: this.translationService.instant('SHARED.GRID.COLUMNS.POPUP.OVERWRITELAYOUTFORBIDDENTEXT'),
                allowClearanceLayouts: this.allowClearanceLayouts,
                roles: this.roles,
            },
        });

        dialogRef2
            .afterClosed()
            .subscribe((result: any) => this.gridColumnsService.notifyGridColumnsPanelOfSaveResult(this.data['gridId'], false));
    }

    /**
     * Berechtigung "allowClearanceLayouts" prüfen
     */
    checkAllowClearanceLayouts(): void {
        const permissionAllowClearanceSelections: boolean =
            this.userPermissionsService.getPermissionValue('allowClearanceLayouts');
        this.allowClearanceLayouts = permissionAllowClearanceSelections;

        // Falls die Berechtigung gesetzt ist außerdem die Rollen aus dem Backend laden.
        if (this.allowClearanceLayouts) {
            this.loadRoles();
        } else {
            this.roles = [];
        }
    }
}
