/* eslint-disable @angular-eslint/no-input-rename */
import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-overlay-panel',
    templateUrl: './overlay-panel.component.html',
    styleUrls: ['./overlay-panel.component.scss'],
})
export class OverlayPanelComponent implements OnInit, OnDestroy {
    @Input() title = 'Default Title';
    @Input() content = 'Default Content';
    @Input('arrow-position') position = 'top';
    @Input('buttons') items: any = [{
        text: 'Default Button',
        color: 'warn',
    }];

    constructor() {}

    ngOnInit() {}

    // Aufräumen
    ngOnDestroy() {}
}
