import {BackendService} from '@global/services/backend.service';
import {Observable, Observer, Subscription} from 'rxjs';

/**
 * @brief   Util-Klasse zum Starten und Beobachten asynchroner Backend-Requests und beliebiger Promises
 * @details Es wird mitgezählt, wie viele Requests gestartet und beendet werden.
 *          Wenn nach dem Beenden eines Requests die Anzahl wieder auf 0 sinkt,
 *          wird der dem Konstruktor übergebene Subscriber informiert.
 *          Somit können Requests und/oder Promises parallel laufen
 *          und es kann abgewartet werden, bis alle fertig sind.
 * @author  Tristan Krakau <t.krakau@pharmakon.software>
 */
export class FutureMonitor {
    public runningTasks = 0;

    public watching = false;

    public dirty = false;

    public name = '';

    constructor(
        private onFinishedObserver: Observer<boolean>,
        private backendService?: BackendService,
    ) {}

    startWatching() {
        // falls schon zuvor Tasks hinzugefügt waren, die bereits beendet sind
        if (this.runningTasks <= 0 && this.dirty) {
            // direkt benachrichtigen
            this.finish();
        } else {
            this.watching = true;
        }
    }

    finish() {
        this.watching = false;
        this.dirty = false;
        this.runningTasks = 0;
        if (typeof this.onFinishedObserver !== 'undefined') {
            this.onFinishedObserver.next(true);
        }
    }

    requestAdded(count = 1) {
        this.runningTasks += count;
        if (!this.dirty) {
            this.dirty = true;
        }
    }

    requestFinished(count = 1) {
        this.runningTasks -= count;

        if (this.runningTasks <= 0 && this.watching) {
            // Wenn kein Request oder Promise mehr läuft, benachrichtige die Subscriber
            this.finish();
        }
    }

    // Starte einen beobachteten Backend-Request
    addRequest(urlPath: string, next?: (value: any) => void): Subscription {
        if (typeof this.backendService === 'undefined') {
            return null;
        }
        return this.addObservable(this.backendService.getRequestAsync(urlPath), next);
    }

    // Subscribe und beboachte ein beliebiges Observable
    addObservable(task: Observable<any>, next?: (value: any) => void): Subscription {
        this.requestAdded();
        return task.subscribe(
            (value: any) => {
                if (typeof next !== 'undefined') {
                    next(value);
                }
                this.requestFinished();
            },
            (error: any) => {
                this.requestFinished();
            },
        );
    }

    // Beobachte ein Promise
    addPromise(promise: Promise<any>): Promise<any> {
        this.requestAdded();
        return promise.then(
            () => this.requestFinished(),
            (error) => {
                this.requestFinished();
            },
        );
    }

    // Bequemes Hinzufügen von mehreren Promises
    addPromises(promises: Promise<any>[]): Promise<any>[] {
        return promises.map((p) => this.addPromise(p));
    }
}
