// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
*/
:host {
  height: 100%;
}

.snackbar-container {
  align-items: center;
  height: 100%;
  justify-content: space-between;
  position: relative;
}

.snackbar-container .cw-icon {
  align-items: center;
  border-radius: 50%;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  height: 30px;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 30px;
}

.snackbar-container .cw-icon:hover {
  border-color: rgba(255, 255, 255, 0.7); /* Farbe des Texts in Snackbar */
}

.snackbar-container .cw-icon:active {
  background: rgba(255, 255, 255, 0.7); /* Farbe des Texts in Snackbar */
  color: #323232; /* Farbe des Hintergrunds in Snackbar */
}`, "",{"version":3,"sources":["webpack://./src/app/shared/snackbars/snackbar-default/snackbar-default.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,YAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,YAAA;EACA,8BAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,kBAAA;EACA,6BAAA;EACA,eAAA;EACA,aAAA;EACA,iBAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;AAAJ;;AAGA;EACI,sCAAA,EAAA,gCAAA;AAAJ;;AAGA;EACI,oCAAA,EAAA,gCAAA;EACA,cAAA,EAAA,uCAAA;AAAJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n*/\n\n:host {\n    height: 100%;\n}\n\n.snackbar-container {\n    align-items: center;\n    height: 100%;\n    justify-content: space-between;\n    position: relative;\n}\n\n.snackbar-container .cw-icon {\n    align-items: center;\n    border-radius: 50%;\n    border: 1px solid transparent;\n    cursor: pointer;\n    display: flex;\n    font-size: 1.6rem;\n    height: 30px;\n    justify-content: center;\n    position: absolute;\n    right: 0;\n    width: 30px;\n}\n\n.snackbar-container .cw-icon:hover {\n    border-color: rgba(255, 255, 255, 0.7); /* Farbe des Texts in Snackbar */\n}\n\n.snackbar-container .cw-icon:active {\n    background: rgba(255, 255, 255, 0.7); /* Farbe des Texts in Snackbar */\n    color: #323232; /* Farbe des Hintergrunds in Snackbar */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
