// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden

@Injectable({providedIn: 'root'})
export class GlobalNotificationsService {
    // Konstruktor
    constructor(private backendService: BackendService) {}

    /**
     * @brief   Benachrichtigungen laden
     * @returns  Observable<any>
     */
    loadData(): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('Notifications/getUnreadNotifications/');
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Benachrichtigung als gelesen markieren
     * @param   id  ID der Benachrichtigung
     * @returns  Observable<any>
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    markRead(id: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('Notifications/markRead/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Benachrichtigung als gelesen markieren
     * @param data
     * @param   id  ID der Benachrichtigung
     * @returns  Observable<any>
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    markAllRead(data): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.postRequest('Notifications/markAllRead/', data);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }
}
