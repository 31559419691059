// Angular-Module
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Übergeordneter Contacts-Service
import {ContactsService} from '@shared/contacts/contacts.service';
// Services anderer Shared-Modules
import {GridService} from '@shared/grid/grid.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Globalen Service einbinden
import {AppCoreService} from '@global/services/app-core.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
// Environment einbinden
import {environment} from '@environment';
import {SelectData} from '@shared/select-data';

@Component({
    selector: 'phscw-contacts-form',
    templateUrl: './contacts-form.component.html',
    styleUrls: ['./contacts-form.component.scss'],
})
export class ContactsFormComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    #componentDestroyed$ = new Subject<void>();

    // Name des Backend-Controllers, welcher angesteuert werden soll
    @Input() controllerName = '';

    // ID des aktuellen Kontakts - Über GETTER / SETTER, da bei Änderung Auswirkung auf Toolbar
    #contactId = 0;

    get contactId() {
        return this.#contactId;
    }

    @Input() set contactId(value) {
        // Wert übernehmen
        this.#contactId = value;
        // Toolbar-Flags zurücksetzen
        this.resetToolbarflags();
    }

    // ID des übergeordneten Kontakts
    @Input() parentContactId: number = null;
    // Typ der Verlinkung zu anderem Kontakt
    @Input() contactLinkType: string = null;

    // FK Person für Neuanlage
    @Input() personId: number;
    // FK Institution für Neuanlage
    @Input() institutionId: number;
    // Weitere Teilnehmer
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() participants: any[] = [];
    // Alle Teilnehmer haben Emails
    @Input() popUpParticipantsHaveEmailSet = false;

    // EditMode aktiv?
    #editMode = false;
    @Input() set editMode(value: boolean) {
        this.#editMode = value;
        // Gib die Infos über den geänderten EditMode an die parent-Komponente weiter
        this.changeEditMode.emit(value);
    }

    get editMode() {
        return this.#editMode;
    }

    // Definiert, ob der Kontakt schreibgeschützt ist
    @Input() readonly = true;
    // Definiert, ob der Readonly, von der Contacts-Form-Komponente verändert werden darf.
    @Input() readonlyImmutable = false;
    // Definiert, ob gar keine Buttons angezeigt werden sollen
    @Input() hideButtons = false;
    // Soll eine Toolbar angezeigt werden
    @Input() showToolbar = false;

    // Für Neuanlage eines Kontakts muss der gewünschte Typ (contact_type) an die shared component übergeben werden
    @Input() newContactType = '';

    // Nach dem Laden kann der Typ eines bestehenden Kontakts automatisch umgewandelt werden
    @Input() autoChangeContactType = '';

    /**
     * Handelt es sich um eine neu angelegte und ungeclearte Entity?
     * Falls ja, können kundenspezifische Einschränkungen bei der
     * Kontakteingabe vorliegen.
     */
    @Input() sampleAuthorization = false;
    // Sichtbare Produkttypen
    @Input() allowedProductTypes = [];
    // Event-Emitter der die übergeordnete Komponente benachrichtigt
    @Output() changeEditMode = new EventEmitter<boolean>();

    // Soll die Person per Select ausgewählt werden können?
    @Input() enablePersonSelection = false;
    displayPersonSelectBox = false;
    institutionPeopleData: SelectData[] = [];
    // Soll die Personenzuordnung zurückgesetzt werden?
    @Input() resetPersonSelection = false;

    // Darf die Einrichtung auch nachträglich noch geändert werden
    @Input() allowChangeInstitutionSelection = false;

    // Verfügbarkeit der Toolbar-Buttons "EditMode" und "UserCheck"
    toolbarEditModeAvailable = true;
    toolbarUserCheckAvailable = false;
    toolbarReopenAvailable = false;
    toolbarAddChildAvailable = false;
    toolbarAddLinkAvailable = false;

    // ID des Grids
    allowTicketEdit = false;

    // Sollen die Tickets auf Sperrung durch andere Nutzer überprüft werden
    checkLock = false;

    // Ist das Ticket gesperrt / Zeige das Lock-Icon
    showLockIcon = false;

    // Permission, darf der Lock-Button angezeigt werden.
    lockButtonPermission = false;

    // Sammelkontakt?
    @Input() collectiveEntry = false;

    // Kontakt für eine Einrichtung?
    @Input() isInstitutionContact = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param {GridService} gridService GridService
     * @param {AppCoreService} appCoreService AppCoreService
     * @param {ContactsService} contactsService ContactsService
     * @param {UserPermissionsService} userPermissionsService UserPermissionsService
     * @param {MatDialog} dialog MatDialog
     * @param {TranslateService} translateService TranslateService
     * @param {ToolbarService} toolbarService ToolbarService
     */
    constructor(
        private gridService: GridService,
        private appCoreService: AppCoreService,
        private contactsService: ContactsService,
        private userPermissionsService: UserPermissionsService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private toolbarService: ToolbarService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
        this.allowTicketEdit = this.userPermissionsService.getPermissionValue('allowTicketEdit');
        if (Object.prototype.hasOwnProperty.call(environment, 'lockTickets')) {
            this.checkLock = environment.lockTickets;
        }

        this.lockButtonPermission = this.userPermissionsService.getPermissionValue('allowUnlockTicket');
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this.#componentDestroyed$.next();
        this.#componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Daten neu laden nach Änderung
        this.appCoreService.appDataChanged.pipe(takeUntil(this.#componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls das Event nicht vom eigenen Grid kam
            if (event.sender !== 'contacts' && event.target !== 'contacts-form-popup') {
                return;
            }
            // Daten in Kontaktmodul neu laden
            this.gridService.reloadGridData('contacts-form');
        });

        // Event "eventCheckedTicketResponsibility" von "ContactsService"
        this.contactsService.eventCheckedTicketResponsibility
            .pipe(takeUntil(this.#componentDestroyed$))
            .subscribe((result: CWEvent) => {
                this.onEventCheckedTicketResponsibility(result);
            });

        // Wenn das Ticket entsperrt werden soll:
        this.toolbarService.eventLockItem.pipe(takeUntil(this.#componentDestroyed$)).subscribe((result: CWEvent) => {
            if (result.target === 'contacts-form') {
                // @todo Kommentar
                this.showLockIcon = false;
                this.contactsService.lockEntity(this.newContactType, this.contactId, false);
                this.toolbarService.loadingComplete('contacts-form', 'Lock');
            }
        });
    }

    /**
     * Auf Event "CheckedTicketResponsibilty" reagieren, um Toolbar in Abhängigkeit
     * der Verantwortlichkeit zu konfigurieren
     * @param {CWEvent} result Event-Daten
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    onEventCheckedTicketResponsibility(result: CWEvent): void {
        if (result.sender !== 'contactsService') {
            return;
        }

        // eslint-disable-next-line eqeqeq
        if (!result.data['contactId'] || result.data['contactId'] != this.contactId) {
            return;
        }

        if (!this.allowTicketEdit) {
            this.toolbarReopenAvailable = false;
            this.toolbarEditModeAvailable = false;
            this.toolbarUserCheckAvailable = false;
            return;
        }

        if (result.data['state'] === 'closed') {
            const allowReopenPermission: boolean = this.userPermissionsService.getPermissionValue('allowReopenTickets');
            this.toolbarUserCheckAvailable = false;
            this.toolbarEditModeAvailable = false;
            this.toolbarReopenAvailable = allowReopenPermission;
            // eslint-disable-next-line eqeqeq
        } else if (result.data['ownTicket'] === true || result.data['contactId'] == 0) {
            this.toolbarReopenAvailable = false;
            this.toolbarEditModeAvailable = true;
            this.toolbarUserCheckAvailable = false;
        } else {
            this.toolbarReopenAvailable = false;
            this.toolbarEditModeAvailable = false;
            this.toolbarUserCheckAvailable = true;
        }
        this.toolbarAddChildAvailable = true;
        this.toolbarAddLinkAvailable = true;
    }

    /**
     * @description Flags zurücksetzen
     * @author Massimo Feth <m.feth@pharmakon.software>
     */
    resetToolbarflags(): void {
        // Flags zurücksetzen
        this.toolbarEditModeAvailable = true;
        this.toolbarUserCheckAvailable = false;
        this.toolbarReopenAvailable = false;
    }

    /**
     * @description   EditMode ändern
     * @param {CWEvent} event Event-Daten
     */
    changeEdit(event: boolean): void {
        if (this.newContactType === 'ticket' && event === true && this.checkLock) {
            this.contactsService.checkLock(this.newContactType, this.contactId).subscribe((result: CWResult) => {
                // Abbruch, falls das Event nicht vom eigenen Grid kam
                if (result.success !== true) {
                    this.editMode = false;
                    return;
                }
                if (Object.prototype.hasOwnProperty.call(result.data, 'locked') && result.data.locked === true) {
                    this.editMode = false;
                    this.openMessagePopup(result.data.employeename);
                    return;
                }
                this.editMode = event;
                this.contactsService.lockEntity(this.newContactType, this.contactId, true);
            });
        } else {
            this.editMode = event;
        }
    }

    /**
     * Dialog anzeigen, falls das Ticket gerade für die Bearbeitung gesperrt ist.
     * @param {string} name Name des Mitarbeiters, der das Ticket gesperrt hat
     */
    openMessagePopup(name: string): void {
        // Dialog konfigurieren und öffnen
        this.dialog.open(PopupMessageComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('SHARED.CONTACTS.FORM.TICKET.LOCKEDTICKETTITLE'),
                message: this.translateService.instant('SHARED.CONTACTS.FORM.TICKET.LOCKEDTICKETMESSAGE', {name}),
            },
        });
    }
}
