// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/**
 * Component-CSS für Einrichtungsliste
 * ===================================
 *
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */
/* Listen-Modul hat selbst keine Scrollbar */
.cw-module {
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/institutions/institutions-list/institutions-list.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;;;EAAA;AAOA,4CAAA;AACA;EACI,gBAAA;AACJ","sourcesContent":["/**\n * Component-CSS für Einrichtungsliste\n * ===================================\n *\n * @author  Massimo Feth <m.feth@pharmakon.software>\n */\n\n/* Listen-Modul hat selbst keine Scrollbar */\n.cw-module {\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
