// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
import {StorageService} from '@global/services/storage.service';
// Interfaces für Structured Objects einbinden
import {UserSettingsService} from '@global/services/user-settings.service';
import {EventData} from '@shared/event-data';
import {EventsPermissionsService} from '@shared/events/events-permissions.service';
import {SelectData} from '@shared/select-data';

@Injectable({providedIn: 'root'})
export class EventsService {
    // Ausgewählte Veranstaltung
    public selectedEvent: EventData;

    // Subject (Observable) definieren "Es wurde eine andere Veranstaltung ausgewählt"
    public selectionChanged = new Subject<number>();

    // Konstruktor
    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
        private userSettingsService: UserSettingsService,
        private storageService: StorageService,
        private eventsPermissionsService: EventsPermissionsService,
    ) {}

    /*
     * @brief   Wird aufgerufen, falls eine andere Veranstaltung ausgewählt werden soll
     */
    selectEvent(selectedEvent: EventData) {
        // Ausgewählte Veranstaltung zwischenspeichern
        this.selectedEvent = selectedEvent;

        // gewählte Veranstaltung in Usersetting speichern
        this.userSettingsService.setValue<number>('eventsOverviewLastSelected', selectedEvent.id);

        // Den Events-Permissions-Service über Wechsel informieren, neue Permissions laden
        this.eventsPermissionsService.selectionChanged(selectedEvent.id);

        // Event auslösen um andere Komponenten des Feature-Moduls zu informieren
        this.selectionChanged.next(this.selectedEvent.id);
    }

    /*
     * @brief   Wird aufgerufen, falls die Auswahl "geleert" werden soll
     */
    selectEmptyEvent() {
        this.selectedEvent = null;
        this.selectionChanged.next(-1);
    }

    /**
     * @brief   Listentries für angegebene Listname laden und zurückgeben
     * @param   listName     {string}    Listname
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     */
    getEventListentries(listName: string): SelectData[] {
        // Rückgabe Array
        const availableListEntries = [];

        // Daten über Service anfordern
        const promise = this.storageService.getItem('listentries|' + listName);

        promise.then((val: any[]) => {
            if (val) {
                // Init
                const tmpOption: SelectData = {
                    id: 0,
                    label: '',
                    data: '',
                };

                // Über alle Einträge laufen
                for (const listentry of val) {
                    tmpOption.id = listentry['list_key'];
                    tmpOption.label = listentry['list_value'];
                    // Wenn es list.data gibt diese zu einem JSON-Parsen und ebenfalls speichern.
                    if (listentry['list_data'] !== '' && listentry['list_data'] !== null) {
                        tmpOption.data = JSON.parse(listentry['list_data']);
                    } else {
                        // Wenn der List_data-Eintrag leer ist, muss die data der tmpOption auf null gesetzt werden. Kein JSON.parse um Fehler zu vermeiden.
                        tmpOption.data = null;
                    }
                    availableListEntries.push({...tmpOption});
                }
            }
        });
        return availableListEntries;
    }

    /**
     * @brief   Selectbox-Bedingungen filtern
     * @param   tmpOption       {SelectData}    Listentry
     * @param   relationType    {string}        Parameter in der list_data
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     */
    checkSelectboxListentryConfiguration(tmpOption: SelectData, relationType: string): boolean {
        // entsprechende Value von ausgewählter VA
        const selectedEventRelation = this.selectedEvent[relationType];

        // Keine Daten -> Option anhängen
        if (
            tmpOption.data === null ||
            typeof tmpOption.data === 'undefined' ||
            selectedEventRelation == '0' ||
            selectedEventRelation === null ||
            typeof selectedEventRelation === 'undefined'
        ) {
            // absichtlich typunsicherer Vergleich der ausgewählten Daten mit 0
            return true;
        }

        // Konfiguration nicht vorhanden oder übereinstimmend -> Option anhängen
        if (!tmpOption.data[relationType] || tmpOption.data[relationType] === selectedEventRelation) {
            return true;
        }

        // Konfiguration als Array vorhanden und Element in Array gefunden -> Option anhängen
        if (
            Array.isArray(tmpOption.data[relationType]) &&
            tmpOption.data[relationType].some((element: any) => element === selectedEventRelation)
        ) {
            return true;
        }

        // Sonst nicht anhängen
        return false;
    }
}
