// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Julian Roller <j.roller@pharmakon.software>
*/
.globalHelpModulesGrid,
cw-module-card {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-help/global-help-modules/global-help-modules.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;;EAEI,WAAA;AAAJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Julian Roller <j.roller@pharmakon.software>\n*/\n\n.globalHelpModulesGrid,\ncw-module-card {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
