// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class PopupSchedulingService {
    // Konstruktor (inkl. dependency injection)
    constructor(private backendService: BackendService) {}

    /**
     * @brief   Kontakt speichern
     * @param   string  controller          Name des Backend-Controllers
     * @param controller
     * @param formData
     * @param   any     formData            Formular-Daten, die submitted werden
     * @returns  Observable<any>
     */
    loadContactsData(controller: string, formData: any) {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            controller + '/getScheduledAppointments/',
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Nächsten Termin für ausgewählte Entität laden
     * @param   string  controller          Name des Backend-Controllers
     * @param controller
     * @param entityId
     * @param   number  entityId            ID der Entität
     * @returns  Observable<any>
     */
    loadNextAppointment(controller: string, entityId: number) {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            controller + '/getNextScheduledAppointment/' + entityId,
        );
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Kontakt speichern
     * @param   string  controller          Name des Backend-Controllers
     * @param controller
     * @param formData
     * @param   any     formData            Formular-Daten, die submitted werden
     * @returns  Observable<any>
     */
    saveDetails(controller: string, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(controller + '/edit/', formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Kontakt löschen
     * @param   string  controller          Name des Backend-Controllers
     * @param controller
     * @param entityId
     * @param   number  entityId            ID des Kontakts
     * @returns  Observable<any>
     */
    deleteContact(controller: string, entityId: number): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(controller + '/delete/', {contacts: [{id: entityId}]});
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
