// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Julian Roller <j.roller@pharmakon.software>
*/
:host .cw-module-card {
  padding: 20px;
}

:host mat-panel-title {
  line-height: 1.6rem;
  margin-right: 0;
  max-width: 100%;
  min-height: 1em;
  overflow: hidden;
  overflow-wrap: break-word;
}

:host mat-panel-title > .cw-expansion-panel-title {
  display: block;
  max-width: 100%;
}

/*.mat-expansion-panel-header-title*/
:host ::ng-deep .mat-expansion-panel-header {
  color: inherit;
  cursor: pointer;
  padding: 12px 24px;
}

:host ::ng-deep .mat-expansion-panel {
  border-radius: 0;
}

:host .cw-toolbar-icon:not(:hover) {
  border: 1px solid transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-help/global-help-lists/global-help-lists.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,aAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,eAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;AAAJ;;AAGA;EACI,cAAA;EACA,eAAA;AAAJ;;AAGA,oCAAA;AAEA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;AADJ;;AAIA;EACI,gBAAA;AADJ;;AAIA;EACI,6BAAA;AADJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Julian Roller <j.roller@pharmakon.software>\n*/\n\n:host .cw-module-card {\n    padding: 20px;\n}\n\n:host mat-panel-title {\n    line-height: 1.6rem;\n    margin-right: 0;\n    max-width: 100%;\n    min-height: 1em;\n    overflow: hidden;\n    overflow-wrap: break-word;\n}\n\n:host mat-panel-title > .cw-expansion-panel-title {\n    display: block;\n    max-width: 100%;\n}\n\n/*.mat-expansion-panel-header-title*/\n\n:host ::ng-deep .mat-expansion-panel-header {\n    color: inherit;\n    cursor: pointer;\n    padding: 12px 24px;\n}\n\n:host ::ng-deep .mat-expansion-panel {\n    border-radius: 0;\n}\n\n:host .cw-toolbar-icon:not(:hover) {\n    border: 1px solid transparent;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
