// Angular-Module
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
    forwardRef,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Interfaces für Structured Objects einbinden
import {SelectData} from '@shared/select-data';

// import 'node_modules/selectize/dist/js/standalone/selectize.js';
@Component({
    selector: 'phscw-input-select-searchable',
    templateUrl: './input-select-searchable.component.html',
    styleUrls: ['./input-select-searchable.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputSelectSearchableComponent),
            multi: true,
        },
    ],
})
export class InputSelectSearchableComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // EditMode aktiv?
    @Input() editMode = false;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string;

    @Input() myValue = '';
    @Input() selectData: SelectData[] = [];
    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    propagateChange = (_: any) => {};

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onChange = new EventEmitter<number>();

    /**
     * Konstruktor (inkl. dependency injection)
     */
    constructor() {}

    /**
     * Initialisieren
     */
    ngOnInit() {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Interface ControlValueAccessor: writeValue
     * @param {any} value  zu schreibender wert
     */
    writeValue(value: any): void {
        if (value !== undefined) {
            this.myValue = value;
        }
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param {any} fn  fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {
        return undefined;
    }

    localChange($event) {
        this.onChange.emit($event);
    }
}
