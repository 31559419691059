// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
// Services anderer Shared-Modules
import {InputDateService} from '@shared/input/input-date/input-date.service';
// Pipes
import {ReplaceSalesPlaceholdersPipe} from '@shared/input/replace-sales-placeholders.pipe';

@Injectable({providedIn: 'root'})
export class SalesService {
    // Subject (Observable) definieren "Es wurde eine Spalte hinzugefügt."
    public columnAdded = new Subject<CWEvent>();
    // Subject definieren "Das Layout hat sich geändert"
    public salesLayoutChanged = new Subject<CWEvent>();
    // Subject defininieren "Das Speichern des Layouts wurde gestartet"
    public layoutSaveStarted = new Subject<CWEvent>();
    // Subject definieren "Das Speichern des Layouts wurde beendet"
    public layoutSaveFinished = new Subject<CWEvent>();
    // Subject definieren "Die aktuelle Umsatzanalyse soll gelöscht werden"
    public eventGridLayoutDeleted = new Subject<CWEvent>();
    // Subject definieren "Lade die Produktanalyse für folgende Einrichtung: ..."
    public eventLoadSalesAnalysisProductView = new Subject<CWEvent>();

    // Konstruktor
    constructor(
        private backendService: BackendService,
        private dateService: InputDateService,
        private replaceSalesPlaceholdersPipe: ReplaceSalesPlaceholdersPipe,
    ) {}

    loadSalesAnalysisData(params: any) {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('SalesAnalysisList/index', params);
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    salesAnalysisColumnAdded(columnData: any, columnIndex: number): void {
        // Um Abhängigkeiten zu verlieren wird die columnData geparsed
        columnData = JSON.parse(JSON.stringify(columnData));

        // Daten Formatieren, dies muss hier im Service passieren, da sonst der Date-Picker die Änderung im Lifecycle wieder überschreibt
        if (typeof columnData.date !== 'undefined') {
            columnData.date[0] = this.dateService.getDateValueForSave(columnData.date[0], 'YYYY-MM-DD HH:mm');
            columnData.date[1] = this.dateService.getDateValueForSave(columnData.date[1], 'YYYY-MM-DD HH:mm');
        }
        if (typeof columnData.date2 !== 'undefined') {
            columnData.date2[0] = this.dateService.getDateValueForSave(columnData.date2[0], 'YYYY-MM-DD HH:mm');
            columnData.date2[1] = this.dateService.getDateValueForSave(columnData.date2[1], 'YYYY-MM-DD HH:mm');
        }

        const eventData: CWEvent = {
            sender: 'ColumnPopup',
            target: 'salesAnalysisColumnSelection',
            data: {
                columnData,
                columnIndex,
            },
        };

        this.columnAdded.next(eventData);
    }

    salesAnalysisSaveColumnLayout(postData: any = {}): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'SalesAnalysisList/saveColumnLayout',
            postData,
        );

        return postRequest$;
    }

    getSalesLayoutData(url = 'Grid/getLayoutDatasets/salesAnalysis'): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(url);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    changeSalesLayout(sender: string, params: any): void {
        const eventData: CWEvent = {
            sender,
            target: '',
            data: params,
        };

        this.salesLayoutChanged.next(eventData);
    }

    loadSalesAnalysisChildren(gridOptions): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'SalesAnalysisList/loadChildrenSales',
            gridOptions,
        );
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    salesAnalysisSavingLayoutStartet(): void {
        const eventData: CWEvent = {
            sender: 'SavingPopup',
            target: 'salesAnalysisColumnSelection',
            data: true,
        };

        this.layoutSaveStarted.next(eventData);
    }

    salesAnalysisSavingLayoutFinished(success: boolean): void {
        const eventData: CWEvent = {
            sender: 'SavingPopup',
            target: 'salesAnalysisColumnSelection',
            data: success,
        };

        this.layoutSaveFinished.next(eventData);
    }

    // Informiere sales-analysis-Layout, dass eine Umsatzanalyse gelöscht werden soll
    deleteSalesAnalysisLayout(source: string, target: string) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: source,
            target,
            data: undefined,
        };
        // Event auslösen
        this.eventGridLayoutDeleted.next(eventData);
    }

    excelExportSalesAnalysis(postData): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'SalesAnalysisList/exportSalesAnalysisToExcel',
            postData,
        );
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    loadSalesAnalysisProductView(sender: string, institutionData: any): void {
        // Infomrationen für Event
        const eventData: CWEvent = {
            sender,
            target: 'SalesAnalysisProductView',
            data: institutionData,
        };
        // Event auslösen
        this.eventLoadSalesAnalysisProductView.next(eventData);
    }

    loadSalesAnalysisFlatListSelection(): Observable<any> {
        // GET-Request über den BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'SalesAnalysisList/initializeSelectionForFlatList',
        );
        // Observable zurückliefern
        return getRequest$;
    }

    /**
     * @brief Replaces PlacesHolders in ColumHeaders
     * @param {string} header
     * @param {any[]} salesDateSelectionData
     * @returns
     */
    replacePlaceHolder(header: string, salesDateSelectionData: any[]): string {
        return this.replaceSalesPlaceholdersPipe.transform(header, salesDateSelectionData);
    }
}
