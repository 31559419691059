// Angular-Module
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Globale Services
import {UserSettingsService} from '@global/services/user-settings.service';
// Eigener Service
import {SplitterService} from './../splitter.service';
// Shared Services
import {CalendarViewService} from '@shared/calendar-view/calendar-view.service';
import {GridService} from '@shared/grid/grid.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {SplitBlock} from '@shared/split-block';

@Component({
    selector: 'phscw-splitter',
    templateUrl: './splitter.component.html',
    styleUrls: ['./splitter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitterComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    /**
     * Definiert, wie der Splitter die Bereiche unterteilen soll.
     *
     * Falls diese Einstellung nicht von außen gesetzt wird, verwendet
     * Angular-Split automatisch die Ausrichtung "horizontal".
     *
     * horizontal      Bereich wird in links / rechts aufgeteilt
     * vertical        Bereich wird in oben / unten aufgeteilt
     */
    @Input() splitterDirection: 'horizontal' | 'vertical';

    /**
     * Blöcke, die durch Splitter geteilt werden
     *
     * Im Standard wird der erste Block (id:1) immer zu 100% angezeigt
     * und der zweite Block (id:2) noch nicht.
     */
    @Input() splitBlock1: SplitBlock = {
        id: 1,
        size: 100,
        fullscreen: true,
    };

    @Input() splitBlock2: SplitBlock = {
        id: 2,
        size: 0,
        fullscreen: false,
    };

    // Maximale Größe, die ein Block haben darf
    @Input() splitBlock1MaxSize = 100;
    @Input() splitBlock2MaxSize = 100;

    /**
     * Definiert, ob der Splitter deaktiviert ist.
     * true         Splitter kann nicht verschoben werden
     * false        Splitter ist verschiebbar
     */
    @Input() splitterDisabled = false;

    /**
     * Bezeichnung der Benutzereinstellung, in welcher die Splitterposition
     * bei DragEnd gespeichert werden soll.
     */
    @Input() settingSplitterPosition = '';

    /**
     * Namen der beiden Blöcke, insofern z.B. auf Events reagiert werden soll.
     */
    @Input() blockname1 = '';
    @Input() blockname2 = '';
    // Setter method for the input property
    @Input() set routed(value: boolean) {
        if (value) {
            const resizeData: any = {fullscreenSize: 50};
            this.resizeAreas(this.blockname1, resizeData);
        }
    }

    // Konstruktor (inkl. dependency injection)
    constructor(
        private calendarViewService: CalendarViewService,
        private gridService: GridService,
        private toolbarService: ToolbarService,
        private userSettingsService: UserSettingsService,
        private splitterService: SplitterService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Blöcke initialisieren
        this.initSplitBlocks();
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    // Blöcke initialisieren
    initSplitBlocks() {
        if (this.splitBlock1.fullscreen === true) {
            // Liste befindet sich in Fullscreen und nimmt gesamte Breite ein
            this.splitBlock1.size = 100;
            this.splitBlock2.size = 0;
        } else {
            // Falls Bezeichnung der Benutzereinstellung vorliegt...
            if (this.settingSplitterPosition != '') {
                // ...wird Block1 darauf eingestellt
                this.splitBlock1.size = this.userSettingsService.getValue(this.settingSplitterPosition);
            }
            // Ungültige Größenangaben werden auf 50% gesetzt
            if (typeof this.splitBlock1.size !== 'number' || this.splitBlock1.size < 0 || this.splitBlock1.size > 100) {
                this.splitBlock1.size = 50;
            }
            // ...und anschließend noch die Größe des zweiten Blocks berechnen
            this.splitBlock2.size = 100 - this.splitBlock1.size;
        }
        // Fullscreen-Status wurde verändert --> Event auslösen
        this.fullscreenStatusChanged();
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Wenn im Grid (z.B. E-Liste) ein Eintrag ausgewählt wurde...
        this._subscriptions.add(
            this.gridService.eventGridSelectionChanged.subscribe((result) => {
                // Abbruch, falls das Event von keinem meiner Blöcke kam
                const event: CWEvent = result;
                if (event.sender != this.blockname1 && event.sender != this.blockname2) {
                    return;
                }
                // wenn die rechte seite kleiner als 3% ist, ist der linke teil "fullscreen"
                if (this.splitBlock2.size < 3) {
                    this.splitBlock1.fullscreen = true;
                }
                // ...und sich die Liste im Fullscreen befindet...
                if (this.splitBlock1.fullscreen === true) {
                    // ...wird Resize der Liste ausgelöst, damit Details erscheinen
                    const resizeData: any = {fullscreenSize: 100};
                    this.resizeAreas(event.sender, resizeData);
                }
            }),
        );
        // Wenn im Grid (z.B. E-Liste) ein "Leer-Eintrag" ausgewählt werden soll...
        this._subscriptions.add(
            this.gridService.eventGridEmptySelection.subscribe((result) => {
                // Abbruch, falls das Event von keinem meiner Blöcke kam
                const event: CWEvent = result;
                if (event.sender != this.blockname1 && event.sender != this.blockname2) {
                    return;
                }
                // ...und sich die Liste NICHT im Fullscreen befindet...
                if (this.splitBlock1.fullscreen === false) {
                    // ...wird Resize der Liste ausgelöst, damit Details ausgeblendet werden
                    const eventData: any = {fullscreenSize: 100};
                    this.resizeAreas(event.sender, eventData);
                }
            }),
        );
        // Wenn ein neuer Datensatz (über Toolbar) angelegt werden soll...
        this._subscriptions.add(
            this.toolbarService.eventAddItem.subscribe((result) => {
                // Abbruch, falls das Event von keinem meiner Blöcke kam
                const event: CWEvent = result;
                if (event.target != this.blockname1 && event.target != this.blockname2) {
                    return;
                }
                // ...und sich die Liste im Fullscreen befindet...
                if (this.splitBlock1.fullscreen === true) {
                    // ...wird Resize der Liste ausgelöst, damit Details erscheinen
                    const eventData: any = {fullscreenSize: 100};
                    this.resizeAreas(event.target, eventData);
                }
            }),
        );
        // Wenn ein neue Änderungsanfrage (über Toolbar) angelegt werden soll...
        this._subscriptions.add(
            this.toolbarService.eventAddShadowItem.subscribe((result) => {
                // Abbruch, falls das Event von keinem meiner Blöcke kam
                const event: CWEvent = result;
                if (event.target != this.blockname1 && event.target != this.blockname2) {
                    return;
                }
                // ...und sich die Liste im Fullscreen befindet...
                if (this.splitBlock1.fullscreen === true) {
                    // ...wird Resize der Liste ausgelöst, damit Details erscheinen
                    const eventData: any = {fullscreenSize: 100};
                    this.resizeAreas(event.target, eventData);
                }
            }),
        );
        // Button "Fullscreen-Toggle" wurde in Toolbar gedrückt (Event "eventFullscreenToggle" von "toolbarService")
        this._subscriptions.add(
            this.toolbarService.eventFullscreenToggle.subscribe((result) => {
                // Abbruch, falls das Event von keinem meiner Blöcke kam
                const event: CWEvent = result;
                if (event.target != this.blockname1 && event.target != this.blockname2) {
                    return;
                }
                // Resize auslösen
                this.resizeAreas(event.target, event.data);
            }),
        );

        // Im gridserive wird aktualsieren des splitter benötigt
        this._subscriptions.add(
            this.gridService.eventFullscreenToggle.subscribe((result) => {
                // Abbruch, falls das Event von keinem meiner Blöcke kam
                const event: CWEvent = result;

                // // Resize auslösen
                this.resizeAreas(event.target, event.data);
            }),
        );

        // Wenn im Kalender ein Eintrag ausgewählt wurde
        this._subscriptions.add(
            this.calendarViewService.eventCalendarSelectionChanged.subscribe((result) => {
                const event: CWEvent = result;
                /**
                 * @todo:   Ab hier hart kodiert...ich hasse mich dafür
                 *          Problem, blockname1 = "eventsList"
                 *          Block1 wechselt zwischen Liste & Kalender.
                 *          Kalender --> sendet natürlich "eventsCalendar" und
                 *          nicht "eventsList"...
                 */
                if (event.sender != 'eventsCalendar') {
                    return;
                }
                // ...und sich die Liste / der Kalender im Fullscreen befindet...
                if (this.splitBlock1.fullscreen === true) {
                    // ...wird Resize der Liste / des Kalenders ausgelöst, damit Details erscheinen
                    const resizeData: any = {fullscreenSize: 100};
                    this.resizeAreas(this.blockname1, resizeData);
                }
            }),
        );
    }

    // Splitter-Drag beendet: Einstellungen übernehmen
    splitterDragEnd(event: {gutterNum: number; sizes: Array<number>}) {
        // Größe der Bereiche übernehmen
        this.splitBlock1.size = event.sizes[0];
        this.splitBlock2.size = event.sizes[1];
        // Setting speichern
        this.saveSetting();
    }

    // Beide Blöcke nehmen jeweils die Hälfte ein
    splitHalf() {
        if (this.splitBlock1MaxSize < 50) {
            this.splitBlock1.size = this.splitBlock1MaxSize;
            this.splitBlock2.size = 100 - this.splitBlock1MaxSize;
        } else if (this.splitBlock2MaxSize < 50) {
            this.splitBlock1.size = 100 - this.splitBlock2MaxSize;
            this.splitBlock2.size = this.splitBlock2MaxSize;
        } else {
            this.splitBlock1.size = 50;
            this.splitBlock2.size = 50;
        }
        this.saveSetting();
    }

    // Einstellung in UserSettings speichern
    saveSetting() {
        // Abbruch, falls kein Setting angegeben wurde
        if (this.settingSplitterPosition == '') {
            return;
        }
        // Splitterposition speichern
        this.userSettingsService.setValue(this.settingSplitterPosition, this.splitBlock1.size);
        // Event auslösen, um andere Komponenten über Änderung zu informieren
        this.splitterService.splitterPositionChanged(this.settingSplitterPosition);
    }

    // Resize der Bereiche (z.B. ausgelöst durch Toolbar-Klick)
    resizeAreas(target: string, data: any[]) {
        // Initialisierungen
        let blockTrigger: SplitBlock;
        let blockReceiver: SplitBlock;

        // Definiere, wer Fullscreen auslöst (trigger) und wer darauf auch reagieren muss (receiver)
        if (target == this.blockname1) {
            blockTrigger = this.splitBlock1;
            blockReceiver = this.splitBlock2;
        } else if (target == this.blockname2) {
            blockTrigger = this.splitBlock2;
            blockReceiver = this.splitBlock1;
        } else {
            return;
        }

        // Änderungen sind abhängig davon, ob der auslösende Block bereits im Fullscreen angezeigt wird
        if (blockTrigger.fullscreen !== true) {
            /**
             * *****************************************************************
             * Block soll auf Fullscreen vergrößert werden
             *****************************************************************
             */
            // Auslösenden Block auf Fullscreen = true setzen
            blockTrigger.fullscreen = true;
            // Empfangenden Block immer auf Fullscreen = false setzen (falls er vorher im Fullscreen war)
            blockReceiver.fullscreen = false;
            // Größen ändern
            blockTrigger.size = data['fullscreenSize'];
            blockReceiver.size = 100 - blockTrigger.size;
        } else {
            /**
             * *****************************************************************
             * Block soll von Fullscreen zurück auf normale Größe verändert werden
             *****************************************************************
             */
            // Auslösenden Block zurück auf Fullscreen = false
            blockTrigger.fullscreen = false;
            // Empfangenden Block immer auf Fullscreen = false setzen (falls er vorher im Fullscreen war)
            blockReceiver.fullscreen = false;
            // Beide Blöcke nehmen jeweils die Hälfte des Schirms ein
            this.splitHalf();
        }

        // Event auslösen (Fullscreen-Status der beiden Blöcke weiterleiten)
        this.fullscreenStatusChanged();

        // Event auslösen, um andere Komponenten über Änderung zu informieren
        this.splitterService.splitterPositionChanged(this.settingSplitterPosition);
    }

    /**
     * @brief   Fullscreen-Status der Blöcke hat sich verändert
     * @details Event auslösen (um z.B. Toolbar zu informieren)
     */
    fullscreenStatusChanged() {
        // Änderung an SplitterService übergeben
        this.splitterService.fullscreenStatusChanged(this.blockname1, this.splitBlock1.fullscreen);
        this.splitterService.fullscreenStatusChanged(this.blockname2, this.splitBlock2.fullscreen);
        // Change-Detection manuell auslösen
        this.changeDetector.detectChanges();
    }
}
