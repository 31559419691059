import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-quarterly-sales-target-achievement',
    templateUrl: './chart-quarterly-sales-target-achievement.component.html',
    styleUrls: ['./chart-quarterly-sales-target-achievement.component.scss'],
})
export class ChartQuarterlySalesTargetAchievementComponent implements OnInit {
    @Input() overlap = true;
    constructor() {}

    ngOnInit() {}
}
