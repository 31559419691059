// Oberklasse importieren
import {DialogData, OverlayInfoComponent} from './../overlay-info.component';
// Angular-Module
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// Service dieses Shared-Moduls
import {OverlayService} from './../../overlay.service';
// Eigener Service
import {OverlayInfoService} from './../overlay-info.service';
// Interfaces für Structured Objects einbinden
import {CWResult} from '@shared/cw-result';

/*
 * @brief   Komponente um Personendetails in einem
 *          Info-Popup anzuzeigen.
 *
 * @author  Sena Üner <s.uener@pharmakons.software>
 */
@Component({
    selector: 'phscw-overlay-info-person',
    templateUrl: './overlay-info-person.component.html',
    styleUrls: ['./../overlay-info.component.scss', './overlay-info-person.component.scss'],
    standalone: false,
})
export class OverlayInfoPersonComponent extends OverlayInfoComponent implements OnInit, OnDestroy {
    // Adresse der Einrichtung
    institutionAddress = '';

    /**
     * Konstruktor (inkl. dependency injection)
     * @param dialogRef
     * @param data
     * @param overlayService
     * @param overlayInfoService
     */
    constructor(
        protected dialogRef: MatDialogRef<OverlayInfoComponent>,
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        protected overlayService: OverlayService,
        private overlayInfoService: OverlayInfoService,
    ) {
        // Konstruktor der vererbenden Komponente aufrufen
        super(dialogRef, data, overlayService);
    }

    /**
     * Initialisieren
     */
    ngOnInit() {
        // onInit der vererbenden Komponente aufrufen
        super.ngOnInit();
    }

    /**
     * @brief   Daten laden
     * @author  Sena Üner <s.uener@pharmakon.software>
     */
    loadData(): void {
        // Flag "loading" aktivieren
        this.loading = true;
        this.success = false;

        // Daten der entsprechenden Entität laden
        const serviceRequest$ = this.overlayService.loadOverlayInformation(
            this.data.backendController,
            this.data.entityId,
        );
        serviceRequest$.subscribe(
            (result: CWResult) => {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zum angefragten Element passen. Durch asynchrone
                 * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                 * bereits das Element gewechselt wurde und die Antwort
                 * eines Requests verspätet eintrifft und dadurch die
                 * korrekten Daten wieder überschreibt.
                 */
                if (result.data === null || this.overlayService.entityId != result.data['id']) {
                    this.loading = false;
                    return;
                }

                if (result.success) {
                    // empfangene Daten zwischenspeichern
                    this.backendData = result.data;

                    // Titel setzen
                    this.setHeaderText();

                    // Adressblock zusammenbauen, falls Einrichtung vorhanden
                    this.institutionAddress = this.backendData.institutions.length
                        ? this.overlayInfoService.getInstitutionAddress(
                            this.backendData.institutions[0],
                        )
                        : '';

                    // Flag "success" aktivieren
                    this.success = true;
                    // Flag "AdviceCheckbox" aktivieren
                    this.displayAdviceCheckbox = this.data.displayAdviceCheckbox;
                }

                // Flag "loading" deaktivieren
                this.loading = false;
            },
            (error: any) => {
                // Flag "loading" deaktivieren
                this.loading = false;
            },
        );
    }

    /**
     * @brief   Header-Text ändern
     * @author  Sena Üner <s.uener@pharmakon.software>
     */
    setHeaderText(): void {
        this.headerText = this.getFullPersonName();
    }

    /**
     * @brief   Person Titel + Name zusammenführen
     * @author  Sena Üner <s.uener@pharmakon.software>
     */
    private getFullPersonName(): string {
        // Intialisiere
        let returnValue = '';

        // Name
        if (this.backendData && this.backendData.firstname) {
            returnValue += this.backendData.firstname;
        }
        if (this.backendData && this.backendData.firstname && this.backendData.lastname) {
            returnValue += ' ';
        }
        if (this.backendData && this.backendData.lastname) {
            returnValue += this.backendData.lastname;
        }

        // Return
        return returnValue;
    }

    /**
     * @brief   Navigiere zu einer Person oder Einrichtung
     * @param   target string   'people' oder 'institutions'
     * @param   id number       ID der Person / der Einrichtung
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    prepareNavigation(target: string, id: number): void {
        if (target === 'people') {
            // TODO Patienten richtig behandeln
            this.overlayInfoService.preparePeopleNavigation(target, id);
        } else if (target === 'institutions') {
            this.overlayInfoService.prepareInstitutionsNavigation(target, id);
        } else {
            console.error('wrong parameter values');
        }
    }
}
