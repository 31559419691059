import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {BackendService} from '@global/services/backend.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class GlobalSettingsSelectDeputyService {
    constructor(private backendService: BackendService) {}

    setDeputy(deputyEmployeeId: number) {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'Employees/setEmployeeDeputy/' + deputyEmployeeId,
            [],
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    getActiveDeputy() {
        const getRequest$: Observable<any> = this.backendService.getRequest('Employees/getActiveDeputy/');
        return getRequest$;
    }

    removeActiveDeputy() {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('Employees/removeActiveDeputy/', []);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
