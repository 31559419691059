// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';

@Injectable({providedIn: 'root'})
export class GlobalHelpPopupService {
    // Event: löst Anpassung der Popupgröße aus
    public eventFullscreenStatusChanged = new Subject<CWEvent>();
    // Flag definiert, ob Fullscreen aktiv ist
    private isFullscreen = false;

    /**
     * Konstruktor (inkl. dependency injection)
     */
    constructor() {}

    /**
     * @brief   ???
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    public toggleFullscreen() {
        this.isFullscreen = !this.isFullscreen;
        const eventData: CWEvent = {
            sender: 'global-help-toolbar',
            target: 'global-help',
            data: {status: this.isFullscreen},
        };
        // Event abschicken
        this.eventFullscreenStatusChanged.next(eventData);
    }
}
