// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
/*
 * Service dieses Feature-Moduls
 * import {InstitutionsService} from '../institutions.service';
 * Interfaces für Structured Objects einbinden
 */
import {CWEvent} from '@shared/cw-event';
import {Institution} from '@shared/institution';

@Injectable({providedIn: 'root'})
export class InstitutionsDataService {
    // Konstruktor (inkl. dependency injection)
    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
    ) {}

    /**
     * @param id
     * @brief   Stammdaten einer Einrichtung laden
     * @returns  Observable<any>
     */
    loadData(id: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('InstitutionsData/details/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @param id
     * @param formData
     * @brief   Speichert Stammdaten einer Einrichtung
     * @returns  Observable<any>
     */
    saveData(id: number, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('InstitutionsData/edit/' + id, formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param formData
     * @brief   Speichert Änderungsanfrage einer Einrichtung
     * @returns  Observable<any>
     */
    saveShadowData(formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('InstitutionsData/shadowEdit/', formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param data
     * @brief      Falls Daten erfolgreich geändert wurden
     * @details    Sendet aktualisierten Datensatz per Event, damit andere
     *             Module (z.B. Listen) darauf reagieren können
     */
    dataChanged(data: Institution): void {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'institutions-data',
            target: '',
            data: {changedItem: data},
        };
        // this.appEventsService.appDataChanged.emit(eventData);
        this.appCore.appDataChanged.next(eventData);
    }
}
