// Angular-Module
import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {NavigationEnd, Router} from '@angular/router';
// ReactiveX for JavaScript
import {Observable, of as observableOf} from 'rxjs';
// Eigenen Service einbinden
import {GlobalRegionsfilterService} from './global-regionsfilter.service';
// Globale Services einbinden
import {StorageService} from './../../services/storage.service';
import {UserPermissionsService} from './../../services/user-permissions.service';
import {UserSettingsService} from './../../services/user-settings.service';
// TreeDatabase
import {TreeDatabase, TreeFlatNode, TreeNode} from '@shared/tree-database';
// Interfaces für Structured Objects einbinden
import {CWResult} from '@shared/cw-result';
// Environment einbinden
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

@Component({
    selector: 'phscw-global-regionsfilter',
    templateUrl: './global-regionsfilter.component.html',
    styleUrls: ['./global-regionsfilter.component.scss'],
    providers: [TreeDatabase],
})
export class GlobalRegionsfilterComponent implements OnInit, OnDestroy {
    // Flag definiert, ob gerade geladen wird
    loading = false;

    // RegionsData vom Backend
    regionsData: any[] = [];

    // Aktuell ausgewählte Region
    currentDivision = 0; // Level 1 = Aussendienst
    currentRegion = 0; // Level 2 & 3 = Gabel, Jochen
    currentDeepestRegionLevel = 0;

    /*
     * treeControl
     * -----------
     * Enthält flache Liste von "TreeFlatNode" - d.h. hier sind alle Regionsfilter-Elemente ohne Verschachtelung abgelegt
     * z.B. this.treeControl.dataNodes = Array
     *          0   TreeFlatNode {id: 10, parent_id: undefined, name: "Außendienst", ... }
     *          1   TreeFlatNode {id: 100, parent_id: 10, name: "Nord", ... }
     *          2   TreeFlatNode {id: 101, parent_id: 100, name: "Müller, Fritz", ... }
     */
    treeControl: FlatTreeControl<TreeFlatNode>;

    treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>;
    dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>;

    // Bezeichnungen der drei Filter-Ebenen (Anzeige Linie-Region-Gebiet)
    filterLabelTotal = 'Total';
    filterLabel1: string = this.filterLabelTotal;
    filterLabel2: string = this.filterLabelTotal;
    filterLabel3: string = this.filterLabelTotal;
    /*
     * Schalter, ob Regionsfilter angezeigt wird
     * 2018-11-27, PhS(MFe): Default geändert auf <false>
     */
    showFilter = false;
    // Module in denen der Regionsfilter angezeigt werden soll
    modulesWithActiveFilter: string[] = environment.globalRegionsfilterActiveModules;
    // Bezeichnung des aktuellen Moduls
    currentModule = '';
    currentSubModule = '';

    // Definiert wieviel Regionslevel (zusätzlich zur Linie) im globalen Regionsfilter angezeigt werden. Standard = 2 (Region & Gebiet)
    globalRegionsfilterLevels = 2;

    // Berechtigung: Darf der Nutzer nur die eigene Region sehen?
    onlyOwnRegion = false;

    // Ob Button "Total" erlaubt ist --> Button setzt komplett auf Total-Total-Total zurück
    totalAllowed = true;

    // Flag definiert, ob Home-Button aktiviert ist
    homeEnabled = true;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param router
     * @param storageService
     * @param globalRegionsfilterService
     * @param userPermissions
     * @param userSettingsService
     * @param database
     */
    constructor(
        private router: Router,
        private storageService: StorageService,
        private globalRegionsfilterService: GlobalRegionsfilterService,
        private userPermissions: UserPermissionsService,
        private userSettingsService: UserSettingsService,
        database: TreeDatabase,
    ) {
        this.treeFlattener = new MatTreeFlattener(
            this.transformer,
            this._getLevel,
            this._isExpandable,
            this._getChildren,
        );
        this.treeControl = new FlatTreeControl<TreeFlatNode>(this._getLevel, this._isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        database.dataChange.subscribe((data) => {
            this.dataSource.data = data;
        });
    }

    // Transformiert TreeNode (verschachtelt) in flache FlatTreeNode
    transformer = (node: TreeNode, level: number) => {
        // Neue TreeFlatNode
        const flatNode = new TreeFlatNode();
        // Werte zuweisen
        flatNode.id = node.id;
        flatNode.parent_id = node.parent_id;
        flatNode.name = node.name;
        flatNode.level = level;
        flatNode.path = node.path;
        // Node ist aufklappbar, wenn Children vorhanden sind
        flatNode.expandable = !!node.children;
        // Rückgabe
        return flatNode;
    };

    private _getLevel = (node: TreeFlatNode) => node.level;

    private _isExpandable = (node: TreeFlatNode) => node.expandable;

    private _getChildren = (node: TreeNode): Observable<TreeNode[]> => observableOf(node.children);

    hasChild = (_: number, _nodeData: TreeFlatNode) => _nodeData.expandable;

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Definiert wieviel Regionslevel (zusätzlich zur Linie) im globalen Regionsfilter angezeigt werden. Standard = 2 (Region & Gebiet)
        if (typeof environment.globalRegionsfilterLevels !== 'undefined') {
            this.globalRegionsfilterLevels = environment.globalRegionsfilterLevels;
        }

        // Prüft ob Button für eigene Region aktiviert ist
        if (this.onlyOwnRegion === false && hasOwn(environment, 'showRegionsfilterHomeButton')) {
            this.homeEnabled = environment.showRegionsfilterHomeButton;
        }

        // Events subscriben
        this.initializeEventSubscriptions();

        // Regionsfilter laden
        this.loadData();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Router prüfen - wurde zu einem anderen Modul gewechselt?
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // if (event instanceof NavigationStart){
                this.onCurrentModuleChanged();
            }
        });
    }

    /**
     * Daten für Regionsfilter über Service laden
     */
    loadData(): void {
        // Loading-Flag aktivieren
        this.loading = true;

        // Daten über Service ermitteln
        const serviceRequest$ = this.globalRegionsfilterService.loadData();
        serviceRequest$.subscribe(
            (result: CWResult) => {
                // Geladene Daten zwischenspeichern
                this.regionsData = result['data'];
                // Baum initialisieren
                this.initTree();
                /**
                 * Beim ersten Aufruf kann in "initializeEventSubscriptions" noch nicht
                 * auf das Router-Event gehört werden. Deswegen wird hier initial
                 * onCurrentModuleChanged() aufgerufen.
                 */
                this.onCurrentModuleChanged();

                // Loading-Flag wieder deaktivieren
                this.loading = false;
            },
            (error: any) => {
                // Loading-Flag wieder deaktivieren
                this.loading = false;
            },
        );
    }

    /**
     * @brief   Prüfe Berechtigungen
     */
    checkPermissions(): void {
        // Nur eigene Region erlaubt? <true> Keine Wechsel des Regionsfilters erlaubt
        const permissionOnlyOwnRegion: boolean = this.userPermissions.getPermissionValue('onlyOwnRegion');
        this.onlyOwnRegion = permissionOnlyOwnRegion;
        /**
         * Falls nur eine Division erlaubt ist, darf Anwender nicht auf "Total"
         * klicken.
         * @todo: Falls mehrere Linien erlaubt sind (aber nicht alle), so
         * hat Total eine andere Bedeutung!
         *      a) Keine Einschränkung, alle Linien erlaubt
         *              Klick auf Total --> Keinerlei Einschränkung im Backend notwendig
         *      b) Nur eine Linie erlaubt
         *              Kein Klick auf Total möglich, immer eingeschränkt auf Regionen der einzigen erlaubten Linie
         *      c) Mehrere Linien erlaubt, aber nicht alle
         *              Klick auf Total sollte möglich sein, Einschränkung im Backend notwendig um nur Gebiete der erlaubten Linien zu filtern
         */
        const permissionAllowedDivisions: string = this.userPermissions.getPermissionValue('allowedDivisions');
        const allowedDivisions = permissionAllowedDivisions.split(';');
        if ((allowedDivisions[0] !== '0' && allowedDivisions.length === 1) || this.onlyOwnRegion) {
            this.totalAllowed = false;
        } else {
            this.totalAllowed = true;
        }
    }

    /**
     * @brief   Auf Modul-Änderungen reagieren, damit Regionsfilter entsprechend
     *          eingestellt werden kann.
     * @details
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     */
    onCurrentModuleChanged(): void {
        // Aktuelles Haupt-Modul speichern
        const splitRoute = this.router.url.split('/');
        this.currentModule = splitRoute[1];
        this.currentSubModule = splitRoute[2] || '';

        // Early-return falls es sich um Login-Seite handelt
        if (this.currentModule === 'login') {
            return;
        }

        // Regionen Filter im Hauptmodul "Service" und im Hauptmodul "Clearing" ausblenden
        if (
            this.modulesWithActiveFilter.includes(this.currentModule) ||
            this.modulesWithActiveFilter.includes(this.currentModule + '-' + this.currentSubModule)
        ) {
            this.showFilter = true;
        } else {
            this.showFilter = false;
        }

        // Falls Filter angezeigt wird, werden die Einstellungen gesetzt
        if (this.showFilter === true) {
            this.manageSettings('load');
        }

        // Berechtigung prüfen
        this.checkPermissions();
    }

    /**
     * @brief       Benutzereinstellungen für Regionsfilter verwalten
     * @details     Regionsfilter-Einstellungen aus Benutzereinstellungen laden oder geänderte Regionsfilter-Einstellungen in Benutzereinstellungen speichern
     * @param action
     * @param       string      action      Beim 'load' werden Benutzereinstellungen geladen
     *                                      Beim 'save' werden Benutzereinstellungen gespeichert
     * @author      Massimo Feth <m.feth@pharmakon.software>
     * @author      Olga Salomatina <o.salomatina@pharmakon.software>
     */
    manageSettings(action: string): void {
        // Key-Name der Benutzereinstellung
        const settingsVariableOfDivision: string = this.currentModule + 'RegionsfilterDivision';
        const settingsVariableOfRegion: string = this.currentModule + 'RegionsfilterRegion';

        // Welche Aktion soll durchgeführt werden
        switch (action) {
            // Laden
            case 'load':
                // Benutzereinstellungen laden und Variablen aktualisieren
                this.currentDivision = parseInt(this.userSettingsService.getValue(settingsVariableOfDivision), 10);
                this.currentRegion = parseInt(this.userSettingsService.getValue(settingsVariableOfRegion), 10);
                // Variablen im Service (immer aktueller Modul-Scope) aktualisieren
                this.updateCurrentSettingsInService();
                break;

            // Speichern
            case 'save':
                // Benutzereinstellungen speichern
                this.userSettingsService.setValue<number>(settingsVariableOfDivision, this.currentDivision);
                this.userSettingsService.setValue<number>(settingsVariableOfRegion, this.currentRegion);
                break;
            default:
                break;
        }

        // Labels des Gebietsfilters aktualisieren
        this.updateRegionsLabels(action);
    }

    /**
     * @brief   Funktion für Regionen Baum initialisieren
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     */
    initTree(): void {
        const treeDatabase = new TreeDatabase();
        treeDatabase.initialize(this.regionsData);

        this.treeFlattener = new MatTreeFlattener(
            this.transformer,
            this._getLevel,
            this._isExpandable,
            this._getChildren,
        );
        this.treeControl = new FlatTreeControl<TreeFlatNode>(this._getLevel, this._isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        treeDatabase.dataChange.subscribe((data) => {
            this.dataSource.data = data;
        });
    }

    /**
     * @param level
     * @brief   Aufgeklappter Regionsfilter ist abhängig davon, welches Level angeklickt wurde
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    openRegionsfilter(level: number): void {
        // Abbruch, falls kein Regionsfilter erlaubt sein soll
        if (this.onlyOwnRegion == true) {
            return;
        }

        // Beim Öffnen des Regionsfilters wird erstmal alles wieder zugeklappt
        this.treeControl.collapseAll();

        // Falls "Linie" angeklickt wurde (level = 0), kann der Baum so bleiben, da nun sowieso nur die Linien angezeigt werden
        if (level === 0) {
            return;
        }

        // Falls "Region" angeklickt wurde (level >= 1)...
        if (level >= 1) {
            // ...und Linie nicht auf "Total" steht, werden Regionen der aktuellen Linie aufgeklappt
            if (this.currentDivision > 0) {
                // Aktuelle Division-Node finden
                const currentDivisionNode: TreeFlatNode = this.treeControl.dataNodes.find(
                    (item) => item.id === this.currentDivision,
                );
                // Division-Node aufklappen
                this.treeControl.expand(currentDivisionNode);
            }
        }

        // Falls "Gebiet" angeklickt wurde (level = 2)...
        if (level === 2) {
            // ...und Region ODER Gebiet nicht auf "Total" steht
            if (this.currentRegion > 0) {
                // Aktuelle(s) Region / Gebiet finden
                const currentRegionNode: TreeFlatNode = this.treeControl.dataNodes.find(
                    (item) => item.id === this.currentRegion,
                );
                // Falls es sich um eine Region handelt (level = 1)...
                if (currentRegionNode.level === 1) {
                    // ...muss diese aufgeklappt werden
                    this.treeControl.expand(currentRegionNode);
                } else {
                    // Andernfalls handelt es sich um ein AD-Gebiet (level = 2)...
                    const currentParentRegionNode: TreeFlatNode = this.treeControl.dataNodes.find(
                        (item) => item.id === currentRegionNode.parent_id,
                    );
                    // ...dessen Parent aufgeklappt werden muss
                    this.treeControl.expand(currentParentRegionNode);
                }
            }
        }
    }

    /**
     * @brief   Funktion zum Klicken des Regionenfilters.
     * @details Die Funktion wird beim Anklicken von Linie/Region/Gebiet im Regionenfilter aufgerufen.
     * @param   TreeNode    node    Angeklicktes Element im Regionsfilter mit folgenden Eigenschaften:
     *           -  number  .id     ID des Elements
     *           -  string  .title  Bezeichnung des angeklickten Elements
     *           -  number  .level  Ebene von angeklicktem Linie/Region/Gebiet
     * @param node
     * @param isOwnRegion
     *                              (0 - für Linie, 1 - für Region oder 2 - für Gebiet)
     *           -  string  .path   Pfad des angeklickten Elements (z.B. 10|100|101)
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    clickRegionsfilter(node: TreeNode, isOwnRegion = false): void {
        /*
         * Abbruch, falls kein Regionsfilter erlaubt sein soll
         *        if (this.onlyOwnRegion == true) {
         *            return;
         *        }
         */

        // zuletzt gewählte Linie und Region merken
        const lastSelectedDivision = this.currentDivision;
        const lastSelectedRegion = this.currentRegion;

        // Welche Ebene wurde angeklickt?
        let nodePath = null;
        // wenn onlyOwn Region aktiv ist, darf nur die unterste Region ausgewählkt werden
        if (this.onlyOwnRegion == true && node.level < this.globalRegionsfilterLevels && !isOwnRegion) {
            return;
        }
        switch (node.level) {
            // Division
            case 0:
                // Angeklickte Linie übernehmen
                this.currentDivision = node.id;
                this.currentRegion = 0;
                // Variablen im Service (immer aktueller Modul-Scope) aktualisieren
                this.updateCurrentSettingsInService();

                // Filter-Bezeichnungen aktualisieren
                this.filterLabel1 = node.name;
                this.filterLabel2 = this.filterLabelTotal;
                this.filterLabel3 = this.filterLabelTotal;

                break;
            // Region
            case 1:
                // Linie und Region ermitteln
                nodePath = node.path.split('|');
                this.currentDivision = parseInt(nodePath[0], 10);
                this.currentRegion = node.id;
                // Variablen im Service (immer aktueller Modul-Scope) aktualisieren
                this.updateCurrentSettingsInService();

                // Filter-Bezeichnungen aktualisieren
                this.filterLabel1 = this.getNodeTitle(this.currentDivision);
                this.filterLabel2 = node.name;
                this.filterLabel3 = this.filterLabelTotal;

                break;
            // Gebiet
            case 2:
                // Linie und Region ermitteln
                nodePath = node.path.split('|');
                this.currentDivision = parseInt(nodePath[0], 10);
                this.currentRegion = parseInt(nodePath[2], 10);
                // Variablen im Service (immer aktueller Modul-Scope) aktualisieren
                this.updateCurrentSettingsInService();

                // Filter-Bezeichnungen aktualisieren
                this.filterLabel1 = this.getNodeTitle(this.currentDivision);
                this.filterLabel2 = this.getNodeTitle(node.parent_id);
                this.filterLabel3 = node.name;

                break;
            default:
                break;
        }

        // Level "merken"
        this.currentDeepestRegionLevel = node.level;

        // Nur wenn letzte Einstellungen geändert wurden
        if (this.currentDivision != lastSelectedDivision || this.currentRegion != lastSelectedRegion) {
            // Veränderung des Regionsfilters an Service weiterleiten und Benutzereinstellungen speichern
            this.executeRegionsfilterChange(true);
        }
    }

    /**
     * @param saveSettingsAndUpdateGrid
     * @brief   Funktion leitet die Veränderungen des Regionsfilters an Server weiter
     *          und speichert die Benutzereinstellungen.
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     */
    executeRegionsfilterChange(saveSettingsAndUpdateGrid: boolean): void {
        // Veränderung des Regionsfilters an Service weiterleiten
        this.globalRegionsfilterService.regionsfilterChanged(
            this.currentDivision,
            this.currentRegion,
            this.currentDeepestRegionLevel,
            saveSettingsAndUpdateGrid,
        );

        // Benutzereinstellung speichern
        if (saveSettingsAndUpdateGrid === true) {
            this.manageSettings('save');
        }
    }

    /**
     * @brief       Funktion liefert Bezeichnung eines Elements über dessen ID
     * @param id
     * @param       number      id          ID des Elements (Linie/Region/Gebiet)
     * @returns      string      title       Bezeichnung von angeklicktem Linie/Region/Gebiet
     * @author      Olga Salomatina <o.salomatina@pharmakon.software>
     */
    getNodeTitle(id: number): string {
        // Variable für den Titel definieren ( default "Total" )
        let title: string = this.filterLabelTotal;

        // Region nach ID finden
        const currentRegionNode: TreeFlatNode = this.treeControl.dataNodes.find((item) => item.id === id);

        if (currentRegionNode) {
            // Bezeichnung übernehmen
            title = currentRegionNode.name;
        }

        // Bezeichnung zurückgeben
        return title;
    }

    /**
     * @param previousAction
     * @brief       Funktion aktualisiert Filter-Bezeichnungen
     * @author      Olga Salomatina <o.salomatina@pharmakon.software>
     */
    updateRegionsLabels(previousAction: string): void {
        // Linie-Bezeichnung aktualisieren
        this.filterLabel1 = this.getNodeTitle(this.currentDivision);
        // Region & Gebiet auf "Total" setzen (für den Fall, dass nichts ausgewählt wurde)
        this.filterLabel2 = this.filterLabelTotal;
        this.filterLabel3 = this.filterLabelTotal;

        // Region nach ID finden
        const currentRegionNode: TreeFlatNode = this.treeControl.dataNodes.find(
            (item) => item.id === this.currentRegion,
        );
        if (currentRegionNode) {
            // Region oder Gebiet
            if (currentRegionNode.level === 1) {
                // Region-Bezeichnung aktualisieren
                this.filterLabel2 = this.getNodeTitle(this.currentRegion);
                this.filterLabel3 = this.filterLabelTotal;
            } else {
                // Region- & Gebiet-Bezeichnung aktualisieren
                this.filterLabel2 = this.getNodeTitle(currentRegionNode.parent_id);
                this.filterLabel3 = currentRegionNode.name;
            }
            // Level "merken"
            this.currentDeepestRegionLevel = currentRegionNode.level;
        }

        /*
         * 2018-09-20, PhS(MFe):
         * ---------------------
         * Nachdem zuvor die aktuellen Settings für Division und Region geladen
         * wurden (previousAction = 'load'), wird auch das Event
         * "Regionsfilter geändert" ausgelöst.
         *
         * Dieses "manuelle" Auslösen soll nicht zu einer erneuten
         * Speicherung der User-Settings führen und auch nicht den
         * Gridfilter nochmal neu setzen (2.Parameter = false)
         */
        if (previousAction === 'load') {
            this.executeRegionsfilterChange(false);
        }
    }

    /**
     * @brief       Funktion setzt Regionen Filter zurück
     * @author      Olga Salomatina <o.salomatina@pharmakon.software>
     */
    resetFilter(): void {
        // Einstellungen setzen
        this.currentDivision = 0;
        this.currentRegion = 0;
        this.currentDeepestRegionLevel = 0;
        // Variablen im Service (immer aktueller Modul-Scope) aktualisieren
        this.updateCurrentSettingsInService();

        // Alle Filter-Bezeichnungen aktualisieren (Total-Total-Total)
        this.filterLabel1 = this.filterLabelTotal;
        this.filterLabel2 = this.filterLabelTotal;
        this.filterLabel3 = this.filterLabelTotal;

        // Alles wieder zuklappen
        this.treeControl.collapseAll();

        // Veränderung des Regionsfilters an Service weiterleiten und Benutzereinstellungen speichern
        this.executeRegionsfilterChange(true);
    }

    /**
     * @brief   Im Service des globalen Regionsfilters werden immer die
     *          aktuellen Einstellungen (division & region) zur Verfügung
     *          gestellt.
     */
    updateCurrentSettingsInService(): void {
        // Variablen im Service (immer aktueller Modul-Scope) aktualisieren
        this.globalRegionsfilterService.currentDivision = this.currentDivision;
        this.globalRegionsfilterService.currentRegion = this.currentRegion;
    }

    /**
     * @brief       Wechsel zur eigenen Region
     * @author      Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    toggleOwnRegion() {
        // Daten über Service anfordern
        const promise = this.storageService.getItem('ownUser');
        promise.then((userData: any) => {
            // Region zwischenspeichern
            const regionId = userData.region_id;
            // Node der eigenen Region suchen
            const homeNode: TreeNode = this.treeControl.dataNodes.find((node: TreeFlatNode) => node.id === regionId);

            // Wenn eine Region gefunden wurde, ...
            if (typeof homeNode !== 'undefined') {
                // ... zur Region wechseln
                this.clickRegionsfilter(homeNode, true);
            } else {
                // ... zur Übersicht wechseln
                this.resetFilter();
            }
        });
    }
}
