// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Klassen einbinden
import {MenuData} from '@shared/menu-data';
// Globale Komponenten einbinden
import {GridSelectionPopupComponent} from './../grid-selection-popup/grid-selection-popup.component';
// Globalen Service einbinden
import {StorageService} from '@global/services/storage.service';
// Eigenen Service einbinden
import {ActivatedRoute} from '@angular/router';
import {GridSelectionService} from './../grid-selection.service';

@Component({
    selector: 'phscw-grid-selection-button',
    templateUrl: './grid-selection-button.component.html',
    styleUrls: ['./grid-selection-button.component.scss'],
})
export class GridSelectionButtonComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    /**
     * *************************************************************************
     * Parameter, welche beim Einbinden der Komponente gesetzt werden
     *************************************************************************
     */
    // ID des (verbundenen) Grids
    @Input() gridId = '';

    toolbarOpen = false;
    toolbarItems: MenuData[] = [];
    characteristics_groups_institutions: any[] = [];
    characteristics_groups_people: any[] = [];

    // Aktuelle Sprache
    langKeyReference = '';

    isB2C = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param dialog
     * @param gridSelectionService
     * @param storageService
     * @param translate
     * @param route
     */
    constructor(
        private dialog: MatDialog,
        private gridSelectionService: GridSelectionService,
        private storageService: StorageService,
        private translate: TranslateService,
        private route: ActivatedRoute,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Daten initialisieren
        this.initData();

        // aktuelle Sprache laden
        this.langKeyReference = this.translate.currentLang;
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Wenn Filter geändert wird
        this.gridSelectionService.eventReopenPopup
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: any) => {
                if (
                    this.gridId.toLowerCase().includes(result['sender']) ||
                    (this.gridId == 'peopleList' && result['sender'] == 'b2c')
                ) {
                    this.openPopup(result['data']);
                }
            });

        // Auf Ändern der Sprache reagieren
        this.translate.onLangChange.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: any) => {
            this.langKeyReference = result.lang;
        });
    }

    /**
     * ???
     */
    initData(): void {
        this.route.data.subscribe((data) => {
            this.isB2C = data.isB2C;
        });
        /*
         * Toolbar-Daten aus Storage laden
         * @todo: Toolbar-Data evtl. noch Listenspezifisch laden
         */
        const promise = this.storageService.getItem('selectionToolbar');
        promise.then((result) => {
            if (Array.isArray(result)) {
                for (let i = 0; i < result.length; i++) {
                    if (this.isB2C && (result[i].name == 'events' || result[i].name == 'institutions')) {
                        continue;
                    }
                    if (typeof result[i]['shownText_' + this.langKeyReference] !== 'undefined') {
                        this.toolbarItems.push(result[i]);
                    }
                }
            }
        });

        // Kennzeichen-Daten laden
        const promiseCharacteristicGroups = this.storageService.getItem('characteristicGroups');
        promiseCharacteristicGroups.then((result) => {
            if (typeof result !== 'undefined' && result !== null) {
                result.forEach((group) => {
                    if (group['characteristic_group_type'] === 'institution') {
                        this.characteristics_groups_institutions.push(group);
                    } else if (group['characteristic_group_type'] === 'person') {
                        this.characteristics_groups_people.push(group);
                    }
                });
            }
        });
    }

    /**
     * ???
     */
    toggleToolbar(): void {
        if (this.toolbarOpen) {
            this.toolbarOpen = false;
        } else {
            this.toolbarOpen = true;
        }
    }

    /**
     * ???
     * @param filter
     */
    openPopup(filter: any): void {
        if (filter['frontendArray'] !== undefined) {
            this.gridSelectionService.openSelection({
                frontend_array: filter['frontendArray'],
                allow_deletion: false,
            });
        } else {
            // Dialog initialisieren
            const dialogConfig = new MatDialogConfig();

            // Daten des gewählten Kriteriums dem Dialog übergeben
            dialogConfig.data = {
                chosenFilter: filter,
                characteristics_groups_institutions: this.characteristics_groups_institutions,
                characteristics_groups_people: this.characteristics_groups_people,
            };

            this.toolbarOpen = false;
            // Dialog öffnen
            this.dialog.open(GridSelectionPopupComponent, dialogConfig);
        }
    }
}
