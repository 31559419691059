// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Service des übergeordneten Feature-Moduls
import {InstitutionsService} from '../institutions.service';
// Eigener Service
import {InstitutionsOrdersService2} from './institutions-orders2.service';
// Service von Shared Modules
import {GridService} from '@shared/grid/grid.service';
import {OrderFormService} from '@shared/order-form/order-form.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {OrderData} from '@shared/order-data';
// import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';
import {environment} from '@environment';
import {StorageService} from '@global/services/storage.service';

@Component({
    selector: 'phscw-institutions-orders2',
    templateUrl: './institutions-orders2.component.html',
    styleUrls: ['./institutions-orders2.component.scss'],
})
export class InstitutionsOrders2Component implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;
    // ID des aktuell ausgewählten Auftrags
    orderId: number;
    // Modul-Daten (Alle Aufträge der Einrichtung)
    data: OrderData[] = [];
    // editMode macht jetzt das Was alle EditModes machen Wechsel von Bearbeitungsmodus in ansichtsmodus
    @Input() editMode = false;
    // Zum Wechsel von der Listen-Ansicht in die Auftragsdetailsansicht
    showOrderDetails = false;
    // "Mehr laden..."
    loadMoreEnabled = false;
    loadMoreVisible = true;
    gridPage = 1;
    // Soll das Order-Modul nur für bestimmte Divisionen angezeigt werden
    allowedDivisions: number[];
    // Soll das Modul angezeigt werden
    showModule = true;
    // Es soll entweder der Positionsrabatt oder der Gesamtrabatt im Untergeordneten editierbar sein
    exclusiveDiscounts = true;

    // Spaltendefinitionen für Grid Auftragsübersicht
    gridColumns = [
        {
            columnDef: 'id',
            header: 'Auftrags-Nr.',
            cell: (element: OrderData) => `${element.id}`,
            formatTemplate: 'flush_right',
            formatWidth: '100px',
        },
        {
            columnDef: 'order_date',
            header: 'Auftragsdatum',
            cell: (element: OrderData) => `${element.order_date}`,
            formatTemplate: 'date',
            formatWidth: '100px',
        },
        {
            columnDef: 'delivery_date',
            header: 'Lieferdatum',
            cell: (element: OrderData) => `${element.delivery_date}`,
            formatTemplate: 'date',
            formatWidth: '100px',
        },
        {
            columnDef: 'username',
            header: 'Mitarbeiter',
            cell: (element: OrderData) => `${element.employee_firstname || ''} ${element.employee_lastname + ''}`,
        },
        {
            columnDef: 'order_total',
            header: 'Summe',
            cell: (element: OrderData) => `${element.order_total}`,
            formatTemplate: 'currency',
            formatWidth: '65px',
        },
        {
            columnDef: 'order_status',
            columnField: 'order_status',
            header: 'Status',
            formatTemplate: 'listentries',
            listentry: 'orderStatus',
        },
        /*
         * Pseudo-Spalte die davon ausgeht, das der Preis für alle Produkte 1 ist.
         * Um die Menge anzuzeigen. Nur für die Pandemie-Phase bei BNT relevant.
         */
        {
            columnDef: 'order_amount',
            header: 'Menge',
            cell: (element: OrderData) => `${element.order_total}`,
            formatWidth: '5px',
            formatTemplate: 'flush_right',
        },
    ];

    // order_status-Spalte ist direkt in der Grid-Komponente definiert.
    gridDisplayedColumns = ['id', 'order_date', 'delivery_date', 'order_status', 'username', 'order_total'];

    // Konstruktor (inkl. dependency injection)
    constructor(
        private institutionsService: InstitutionsService,
        private institutionsOrdersService2: InstitutionsOrdersService2,
        private gridService: GridService,
        private toolbarService: ToolbarService,
        private translateService: TranslateService,
        private storageService: StorageService,
        private orderFormService: OrderFormService,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Überprüfe, ob das Modul eine eigene Spalten-Definition hat.
        if (Object.prototype.hasOwnProperty.call(environment, 'institutionsOrdersOverviewDisplayedColumns')) {
            this.gridDisplayedColumns = environment['institutionsOrdersOverviewDisplayedColumns'];
        }

        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();
    }

    // Aufräumen
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
        this._subscriptions.unsubscribe();
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     * @author  Min Hye Park     <m.park@pharmakon.software>
     */
    initializeTranslateSubscriptions() {
        this.translateService
            .stream([
                'GENERAL.ORDERID',
                'GENERAL.ORDERDATE',
                'GENERAL.DELIVERYDATE',
                'GENERAL.EMPLOYEE',
                'GENERAL.SUM',
                'GENERAL.ORDERFROM',
                'GENERAL.STATUS',
            ])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: any) => {
                this.gridColumns.find((column: any) => column.columnDef === 'id').header =
                    translation['GENERAL.ORDERID'];
                this.gridColumns.find((column: any) => column.columnDef === 'order_date').header =
                    translation['GENERAL.ORDERDATE'];
                this.gridColumns.find((column: any) => column.columnDef === 'delivery_date').header =
                    translation['GENERAL.DELIVERYDATE'];
                this.gridColumns.find((column: any) => column.columnDef === 'username').header =
                    translation['GENERAL.EMPLOYEE'];
                this.gridColumns.find((column: any) => column.columnDef === 'order_total').header =
                    translation['GENERAL.SUM'];
                this.gridColumns.find((column: any) => column.columnDef === 'order_status').header =
                    translation['GENERAL.STATUS'];
            });
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Event "selectionChanged" von "institutionsService"
        this._subscriptions.add(
            this.institutionsService.selectionChanged.subscribe((result) => {
                this.onSelectionChanged(result);
            }),
        );
        // Event "eventGridSelectionChanged" von "gridService" (E-Aufträge)
        this._subscriptions.add(
            this.gridService.eventGridSelectionChanged.subscribe((result) => {
                this.onEventGridSelectionChanged(result);
            }),
        );
        // Wenn ein neuer Auftrag (über Toolbar) angelegt werden soll...
        this._subscriptions.add(
            this.toolbarService.eventAddItem.subscribe((result) => {
                this.onEventAddItem(result);
            }),
        );
        // Es wurde auf "Mehr laden..." geklickt
        this._subscriptions.add(
            this.gridService.eventGridPageCounterChanged.subscribe((result) => {
                this.onEventGridPageCounterChanged(result);
            }),
        );
    }

    // Auf geänderte Auswahl reagieren
    onSelectionChanged(id: number) {
        // Bereits vorhandene Daten (einer anderen Einrichtung) entfernen
        this.resetData();
        // ID der aktuellen Einrichtung merken
        this.institutionId = id;
        // Aus dem Detail-Modus von Aufträgen herauswechseln
        this.showOrderDetails = false;
        // Basisdaten zur Einrichtung wie z.B. Adresse und Rabatt laden
        this.orderFormService.selectInstitution(this.institutionId, this.institutionsService.selectedInstitution);
        // Auftrags-Daten laden
        this.loadData();
    }

    // Auf Event "eventGridSelectionChanged" von "gridService" reagieren
    onEventGridSelectionChanged(result) {
        const event: CWEvent = result;
        if (event.sender == 'institutionsOrders2') {
            this.orderId = event.data['selectedRow']['id'];
            this.editMode = false;
            this.showOrderDetails = true;
        }
    }

    // Auf Toolbar-Klick reagieren (Auftrag anlegen)
    onEventAddItem(result) {
        const event: CWEvent = result;
        if (event.target == 'institutions-orders2') {
            this.orderId = 0;
            this.showOrderDetails = true;
            this.editMode = true;
        }
    }

    // Es wurde auf "Mehr laden..." geklickt
    onEventGridPageCounterChanged(result) {
        const event: CWEvent = result;
        if (event.target == 'institutionsOrders2') {
            this.gridPage = event.data['gridPageCounter'];
            this.loadData();
        }
    }

    // Alle Aufträge der Einrichtung laden
    loadData(reload?: boolean): void {
        // Defaultmäßig soll weiter geladen werden, nach Speichern soll neu geladen werden
        if (typeof reload === 'undefined') {
            reload = false;
        } else if (reload) {
            // Wieder erste Page laden
            this.gridPage = 1;
        }
        // Den Edit-Mode zurücksetzen. Er sollte immer auf false stehen, wenn Daten geladen werden.
        this.editMode = false;
        // Funktion "Mehr laden..." wird wieder deaktiviert
        this.loadMoreEnabled = false;

        const serviceRequest$ = this.institutionsOrdersService2.loadData(
            this.institutionId,
            this.gridPage,
            this.allowedDivisions,
        );
        serviceRequest$.subscribe(
            // onNext
            (result) => {
                if (result['success']) {
                    if (!reload) {
                        if (result['data'].length > 0) {
                            /**
                             * Prüfe, ob die Daten des eintreffenden Requests auch
                             * zur aktuell ausgewählten Einrichtung passen. Durch asynchrone
                             * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                             * bereits die Einrichtung gewechselt wurde und die Antwort
                             * eines Requests verspätet eintrifft und dadurch die
                             * korrekten Daten wieder überschreibt.
                             */
                            if (this.institutionId != result['data'][0]['institution_id']) {
                                return;
                            }

                            // Falls sich die vorhandenen Grid-Daten von den neu geladenen Daten unterscheiden...
                            if (JSON.stringify(this.data) != JSON.stringify(result['data'])) {
                                // Vorhandene Grid-Daten mit neu geladenen Daten erweitern
                                this.data = this.data.concat(result['data']);
                            }
                            this.loadMoreEnabled = true;
                        } else {
                            this.loadMoreVisible = false;
                        }
                    } else {
                        this.data = JSON.parse(JSON.stringify(result['data']));
                        this.loadMoreEnabled = true;
                        this.loadMoreVisible = true;
                    }
                } else if (result['data'] === 'WrongDivision') {
                    // Falls die Einrichtung nicht zu den Divisionen gehört, für die das Order-Modul angezeigt werden darf => ausblenden
                    this.showModule = true;
                }
            },
        );
    }

    // Daten zurücksetzen (z.B. wenn Einrichtung gewechselt wird)
    resetData(): void {
        this.loadMoreEnabled = false;
        this.loadMoreVisible = true;
        this.gridPage = 1;
        this.data = [];
        this.showModule = true;
        this.orderFormService.institutionDiscount = 0;
    }
}
