import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'phscw-institutions-additional-data',
    templateUrl: './institutions-additional-data.component.html',
    styleUrls: ['./institutions-additional-data.component.scss'],
})
export class InstitutionsAdditionalDataComponent implements OnInit {
    @Input() institutionId = 0;

    ngOnInit(): void {}
}
