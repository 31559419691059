// Import Angular-Module
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class InputNumberService {
    /**
     * Konstruktor (inkl. dependency injection)
     */
    constructor() {}

    /**
     * übergebenes Format anwenden
     * @param customFormat
     * @param value
     */
    applyCustomFormat(customFormat: any, value: number): string | number {
        // Konfigurationen
        const decimalSeparator: string = customFormat.decimal_separator;
        const thousandsSeparator: string = customFormat.thousands_separator;
        const decimalPlaces: number = customFormat.decimal_places;

        // Wert runden
        let returnValue: string | number = null;
        if (typeof decimalPlaces !== 'undefined' && decimalPlaces > 0) {
            returnValue = Number(value).toFixed(decimalPlaces); // https://stackoverflow.com/a/11832950
        } else {
            returnValue = Math.round(value);
        }

        // Zahl an Dezimaltrennzeichen teilen
        const numberParts = returnValue.toString().split('.');

        // Zeichen vor Dezimaltrennzeichen
        let preSeparator = numberParts[0];
        // String umdrehen
        preSeparator = this.reverseString(preSeparator);
        // Tausendertrennzeichen verwenden
        preSeparator = preSeparator.replace(/(\d{3})(?=\d{1,3})/g, '$1' + thousandsSeparator); // https://stackoverflow.com/a/1772997 + https://stackoverflow.com/a/14231378
        // String wieder richtig drehen
        preSeparator = this.reverseString(preSeparator);

        // String zusammensetzen
        returnValue = preSeparator;
        if (decimalPlaces > 0 && numberParts.length > 1) {
            returnValue += decimalSeparator + numberParts[1];
        }

        // formatierten String zurückgeben
        return returnValue as string;
    }

    /**
     * String umkehren
     * @param value
     */
    private reverseString(value: string): string {
        return value.split('').reverse().join('');
    }
}
