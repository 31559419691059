// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
*/
:host {
  margin-bottom: 40px;
  width: 100%;
}

.cw-activities {
  margin-top: 20px;
}

.cw-activities-items {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/contacts/contacts-list/contacts-list.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,mBAAA;EACA,WAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n*/\n\n:host {\n    margin-bottom: 40px;\n    width: 100%;\n}\n\n.cw-activities {\n    margin-top: 20px;\n}\n\n.cw-activities-items {\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
