// Angular-Module
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

// ReactiveX for JavaScript
import {merge, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Eigener Service
import {OrderFormService} from '../../order-form.service';
// Interfaces für Structured Objects einbinden
import {StorageService} from '@global/services/storage.service';
import {FormStatus} from '@shared/form-status';
import {LooseObject} from '@shared/loose-object';
import {SelectData} from '@shared/select-data';
import {hasOwn, notEmpty, notEmptyObject, numberRange, toSum} from '@shared/utils';
import * as _moment from 'moment';

const moment = _moment;
@Component({
    selector: 'phscw-order-form-items',
    templateUrl: './order-form-items.component.html',
    styleUrls: ['./order-form-items.component.scss'],
})
export class OrderFormItemsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    gridId = 'OrderFormItems';

    // id of selected order
    orderId: number = null;

    // Zwischenspeichern der Auftragsdaten
    orderDataCopy: LooseObject;

    // data of selected order
    @Input() set orderData(order) {
        // Prüfe ob orderData nicht null/undefined oder empty object ist
        if (!(order && Object.keys(order).length === 0 && Object.getPrototypeOf(order) === Object.prototype)) {
            this.orderDataCopy = Object.assign(order);
            // Hole orderItems wenn es sich nicht um eine Neuanlage handelt
            if (order.id !== 0 && order.id !== null && typeof order.id !== 'undefined') {
                this.getOrderItems(order);
            }
        }
    }

    // defines if component is currently loading data
    loading = false;
    // defines if data is currently edited
    @Input() editMode = false;

    // Status des Formulars
    status: FormStatus;

    // Reactive forms
    orderItemFormGroup: UntypedFormGroup;

    // Event wird ausgelöst, wenn an den Item-Feldern Änderungen vorgenommen werden
    @Output() valueChanges = new EventEmitter<any>();

    // Sammlung der Subscriptions für Order-Item-Felder, die beim Wechsel der Auftragsart abbestellt werden müssen
    productItemFieldsSubscriptions = new Subscription();

    // Ausgewählte Auftragsart
    selectedOrderType: string;
    // Ausgewählte Auftragssortiment
    selectedOrderRange: number | string;
    // gewählte Produktgruppe
    selectedParentProductId: number | string;

    // Auswahl der Produktegruppen
    selectDataProductGroups: SelectData[] = [];
    // Auswahl der Sortimente
    selectDataProductRange: SelectData[] = [];
    // Auswahl der Produkt-Chargen
    selectDataProductBatches: SelectData[] = [];

    // Gesamte Produktliste aus dem Storage
    orderEntryProductList = [];
    // Gesamte Produktgruppenliste aus dem Storage
    productGroupList = [];
    // Gesamte Sortimentsliste aus dem Storage
    productRangeList = [];
    // Gesamte Produktchargenliste aus dem Storage
    productBatchList = [];

    // Suchwert der Suchleiste bei der Produktliste
    productSearchValue: string;
    // Flag ob Produkt Suche aktiv ist
    productSearchActive = false;
    // Felder in denen gesucht werden soll
    productDataFieldsForSearch = ['product_id', 'product_label', 'product_erp_number', 'pzn'];

    // aktuell aktive Lieferdaten zur Anzeige in der Items-Tabelle
    deliveryDates: Date[];
    visibleDeliveryDates = 1;

    // Ob From initialisert ist
    init = false;

    // Zahlenformat für Preise
    customCurrencyFormat = {
        decimal_separator: ',',
        thousands_separator: '.',
        decimal_places: 2,
    };

    // Spaltendefinitionen für Grid Artikeltabelle
    articleGridColumns = [
        // {columnDef: 'product_id', header: 'Produkt-ID.', cell: (element: any) => `${element.product_id}`,  formatTemplate: 'flush_right'},
        {
            columnDef: 'label',
            header: 'Bezeichnung',
            cell: (element: any) => `${element.label}`,
        },
        {
            columnDef: 'delivery_address',
            header: 'Lieferaddresse',
            cell: (element: any) => `${element.delivery_address}`,
            formatWidth: '80px',
        },
        {
            columnDef: 'delivery_date',
            header: 'Lieferdatum',
            formatTemplate: 'date',
            cell: (element: any) => `${element.delivery_date}`,
        },
        {
            columnDef: 'order_item_delivery_institution_id',
            header: 'Empfänger',
            cell: (element: any) => `${element.delivery_institution_id}`,
        },
        {
            columnDef: 'package_size',
            header: 'Packungs-Gr.',
            cell: (element: any) => `${element.package_size}`,
        },
        {
            columnDef: 'quantity_unit',
            columnField: 'quantity_unit',
            header: 'VPE',
            cell: (element: any) => `${element.quantity_unit}`,
            listentry: 'productPackageUnit',
            formatTemplate: 'listentries',
        },
        {
            columnDef: 'pzn',
            header: ' PZN',
            cell: (element: any) => `${element.pzn || ''}`,
        },
        {
            columnDef: 'order_item_charge',
            header: 'Charge',
            cell: (element: any) => `${element.charge}`,
        },
        {
            columnDef: 'amount',
            header: 'Menge',
            cell: (element: any) => `${element.amount}`,
            formatTemplate: 'flush_right',
            formatWidth: '80px',
        },
        {
            columnDef: 'unit_price',
            header: 'Einzelpreis',
            cell: (element: any) => `${element.unit_price}`,
            formatTemplate: 'currency',
            formatWidth: '80px',
        },
        {
            columnDef: 'item_price',
            header: 'Positionspreis',
            cell: (element: any) => `${element.item_price}`,
            formatTemplate: 'currency',
            formatWidth: '80px',
        },
        {
            columnDef: 'discount',
            header: 'Rabatt',
            cell: (element: any) => `${element.discount}`,
            formatTemplate: 'percent',
            formatWidth: '80px',
        },
        {
            columnDef: 'max_discount_text',
            header: '',
            cell: (element: any) => `${element.max_discount_text}`,
            formatWidth: '5px',
        },
        {
            columnDef: 'total_price',
            header: 'Gesamtpreis',
            cell: (element: any) => `${element.total_price}`,
            formatTemplate: 'currency',
            formatWidth: '80px',
        },
    ];

    readonly availableArticleGridDisplayedColumns = [
        'label',
        'delivery_date',
        'order_item_delivery_institution_id',
        'amount',
        'unit_price',
        'discount',
        'total_price',
        'order_item_charge',
    ];

    // per default show all available columns, will later be modified according to access-rights
    articleGridDisplayedColumns = [...this.availableArticleGridDisplayedColumns];

    articleData: LooseObject[] = [];
    articleDataReadOnlyView: LooseObject[] = [];

    // defines if data is currently edited
    discounts = [];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param orderFormService
     * @param storageService
     */
    constructor(
        public orderFormService: OrderFormService,
        private storageService: StorageService,
    ) {}

    /**
     * Initialize
     */
    ngOnInit() {
        // Leere Default-FormGroup anlegen, wird beim Laden der Produkte befüllt
        this.orderItemFormGroup = new UntypedFormGroup({});

        // Produktdaten initialisieren
        this.getOrderEntryProductList();

        // Events subscriben
        this.initializeEventSubscriptions();
        // Discounts laden
        this.orderFormService.getDiscounts().subscribe((result) => {
            this.discounts = result['data'];
        });
    }

    /**
     * Cleanup
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Susbcribe to events
     */
    private initializeEventSubscriptions(): void {
        // auf neu evaluierte Access-Rights reagieren
        this.orderFormService.currentOrderAccessRightsExplicit
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((currentRights) => {
                // sichtbare Spalten neu ermitteln - ausblenden, wenn in den Access-Rights explizit unsichtbar gesetzt
                this.articleGridDisplayedColumns = this.availableArticleGridDisplayedColumns
                    .filter((columnKey) =>
                        typeof currentRights[columnKey] === 'undefined' || currentRights[columnKey]['ngIf'] === true);

                // set required validator in reactive forms
                for (const [key, value] of Object.entries(this.orderFormService.orderAccessRightsExplicit)) {
                    if (!Object.prototype.hasOwnProperty.call(this.orderItemFormGroup.controls, key)) {
                        continue;
                    }

                    if (value['required']) {
                        this.orderItemFormGroup.controls[key].setValidators([Validators.required]);
                    } else {
                        this.orderItemFormGroup.controls[key].setValidators(null);
                    }
                    this.orderItemFormGroup.controls[key].updateValueAndValidity();
                }
            });

        // Auf das Event hören, wenn OrderType gewechselt wird
        this.orderFormService.selectedOrderType
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: string) => {
                // Werte intern speichern und Formular neu initialisieren
                this.selectedOrderType = event;
                this.selectDataProductGroups = [];
                this.selectDataProductRange = [];
                this.selectParentProductId(-1);
                // für den Order-Typ verfügbare Produkte laden, baut Items-Formular auf
                this.getOrderEntryProductList();
            });

        // Änderungen an der Lieferdaten-Konfiguration beobachten
        this.orderFormService.deliveryDatesChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: Date[]) => {
                this.onChangedDeliveryDates(event);
            });

        // Auf das Event hören, wenn gesetzter Rabatt vom Auftragskopf übernommen werden soll
        this.orderFormService.discountToBeApplied
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((discountNew: number) => {
                this.applyDiscount(discountNew);
            });
    }

    /**
     * @brief Hole alle bestellten Produkte einer Order
     * @param {LooseObject} order Gesamte Bestelldaten
     */
    private getOrderItems(order: LooseObject) {
        // flag ob gerade geladen wird
        this.loading = true;

        // Zwischenspeichern der gesetzten Auftragsart
        this.selectedOrderType = order['order_type'];

        // Zwischenspeichern von order_items
        this.articleData = order['order_items'];

        this.articleDataReadOnlyView = order['orderItemsReadonlyView'];

        // zur Sicherheit die Anzahl sichtbarer Datumsspalten anpassen, sollte schon beim Laden geschehen sein
        this.visibleDeliveryDates = order.deliveryDates.length;

        const promise = this.storageService.getItem('orderEntryProductList');
        promise.then((productList: LooseObject) => {
            // Produkte Zwischenspeichern
            this.orderEntryProductList = productList['products'];
            this.productGroupList = productList['product_groups'];
            this.productRangeList = productList['product_range'];
            this.productBatchList = productList['product_batches'];

            // Auswahlfelder initialisieren
            this.getProductRangeForSelectData();
            this.getProductGroupsForSelectData();
            this.getProductBatchesForSelectData();

            // vorherige Subscriptions beenden, wenn neue Formularfelder erstellt werden
            this.productItemFieldsSubscriptions.unsubscribe();
            this.productItemFieldsSubscriptions = new Subscription();

            // Form zurücksetzen und neu aufbauen
            this.orderItemFormGroup.reset();
            this.orderItemFormGroup = this.initForm();
            this.productItemFieldsSubscriptions.add(
                this.orderItemFormGroup.valueChanges.subscribe((result) => {
                    // geänderte Daten nach außen geben
                    this.valueChanges.emit(result);
                }),
            );

            // Vorhandene Daten in Form aufnehmen
            for (const orderItem of this.articleData) {
                for (const product of this.orderItemFormGroup.controls.productsFormArray['controls']) {
                    // Daten der Zeile aktualisieren, die zu Produkt und Lieferempfänger des OrderItems passt
                    if (
                        orderItem.product_id === product.get('product_id').value &&
                        orderItem.delivery_institution_id === product.get('order_item_delivery_institution_id').value
                    ) {
                        product.patchValue({
                            amount: orderItem.amount,
                            unit_price: orderItem.unit_price,
                            discount: orderItem.discount,
                            charge: orderItem.charge,
                            total_price: orderItem.total_price,
                            amountPerDeliveryDate: orderItem.amountPerDeliveryDate,
                        });
                    }
                }
            }

            // flag loading zurücksetzen
            this.loading = false;
        });
    }

    /**
     * Hole alle Produkte aus dem Storage
     */
    private getOrderEntryProductList() {
        // flag ob gerade geladen wird
        this.loading = true;

        // early return wenn keine Auftragsart gesetzt ist oder existierender Auftrag ausgewählt ist
        if (typeof this.selectedOrderType === 'undefined' || this.orderDataCopy?.id > 0) {
            this.loading = false;
            return;
        }

        const promise = this.storageService.getItem('orderEntryProductList');
        promise.then((productList: LooseObject) => {
            // Produkte Zwischenspeichern
            this.orderEntryProductList = productList['products'];
            this.productGroupList = productList['product_groups'];
            this.productRangeList = productList['product_range'];
            this.productBatchList = productList['product_batches'];

            // Auswahlfelder initialisieren
            this.getProductRangeForSelectData();
            this.getProductGroupsForSelectData();
            this.getProductBatchesForSelectData();

            // vorherige Subscriptions beenden, wenn neue Formularfelder erstellt werden
            this.productItemFieldsSubscriptions.unsubscribe();
            this.productItemFieldsSubscriptions = new Subscription();

            // Form zurücksetzen und neu aufbauen
            this.orderItemFormGroup.reset();
            this.orderItemFormGroup = this.initForm();
            this.productItemFieldsSubscriptions.add(
                this.orderItemFormGroup.valueChanges.subscribe((result) => {
                    // geänderte Daten nach außen geben
                    this.valueChanges.emit(result);
                }),
            );

            // flag loading zurücksetzen
            this.loading = false;
        });
    }

    /**
     * Bereite die verfügbaren Sortimente für Auswahlliste vor
     */
    private getProductRangeForSelectData() {
        // Default Auswahl "Alle" hinzufügen
        this.selectDataProductRange.push({
            id: 'all',
            label: 'Alle',
        });

        // Falls keine produkte exisiteren, keine anzeigen
        if (!this.checkIfProductsExits()) {
            return;
        }

        // Auswahlmöglichkeiten je nach verfügbaren Sortimenten hinzufügen
        for (const [key] of Object.entries(this.orderEntryProductList[this.selectedOrderType])) {
            this.selectDataProductRange.push({
                id: key,
                label: this.productRangeList[key].list_value,
            });
        }
        // Beim selektieren standardmäßig alle auswählen
        this.selectedOrderRange = 'all';
    }

    /**
     * Bereite die verfügbaren Produktgruppen für Auswahlliste vor
     */
    private getProductGroupsForSelectData() {
        // Default Auswahl "Alle" hinzufügen
        this.selectDataProductGroups.push({
            id: 'all',
            label: 'Alle',
        });
        // Auswahl für Produkte mit amount > 0 hinzufügen
        this.selectDataProductGroups.push({
            id: 'amountSet',
            label: 'Bestellung',
        });

        // Falls keine produkte exisiteren, keine anzeigen
        if (!this.checkIfProductsExits()) {
            return;
        }

        // Auswahlmöglichkeiten je nach verfügbarer Produktgruppe des momentan ausgewählten Sortiments hinzufügen
        for (const orderRange of Object.values(this.orderEntryProductList[this.selectedOrderType])) {
            for (const [key] of Object.entries(orderRange)) {
                // Hole Produktgruppe auf der höchsten Ebene des Produkts
                const selectDataEntry = this.getTopLevelProductGroup(key);

                // Prüfe ob Produktgruppe nicht bereits hinzugefügt worden ist
                const existingIndex = this.selectDataProductGroups.findIndex((item) => item.id === selectDataEntry.id);
                if (existingIndex === -1) {
                    this.selectDataProductGroups.push(selectDataEntry);
                }
            }
        }

        // Sortiere Liste alphabetisch nach label
        this.selectDataProductGroups = this.sortSelectDataListAlphabetically(this.selectDataProductGroups);

        // Beim selektieren standardmäßig alle auswählen
        this.selectedParentProductId = 'all';
    }

    /*
     * Prüfe ob Produkt eine Produktgruppe auf der Höchsten Ebene ist
     */
    private isTopLevelProductGroup(productId: string): boolean {
        return this.productGroupList[productId].parent_id === null;
    }

    /*
     * Hole Produktgruppe der höchsten Ebene des Produkts
     */
    private getTopLevelProductGroup(productId: string) {
        if (this.isTopLevelProductGroup(productId)) {
            return {
                id: productId,
                label: this.productGroupList[productId].label,
            };
        }
        return this.getTopLevelProductGroup(this.productGroupList[productId].parent_id);
    }

    /*
     * Sortiere die SelectData Liste alphabetisch nach "label"
     */
    private sortSelectDataListAlphabetically(list) {
        // Ignore die ersten 2 Element, da diese die Festen Filter "Alle" und "Bestellung" sind
        const ignoredElements = list.slice(0, 2);
        const arrToSort = list.slice(2);

        // Sortiere nach label
        arrToSort.sort((a, b) => a.label.localeCompare(b.label));

        // Ignorierte Elemente und sortierte Liste zusammenfügen
        const sortedArr = ignoredElements.concat(arrToSort);

        return sortedArr;
    }

    /**
     * Bereite die verfügbaren Produktchargen für Auswahlliste vor
     */
    private getProductBatchesForSelectData() {
        for (const [key, value] of Object.entries(this.productBatchList)) {
            this.selectDataProductBatches[key] = [];
            for (const val of value) {
                const validFrom = moment(val.valid_from);
                const validTo = moment(val.valid_to);
                /*
                 * da in der auftragsübersicht die chargen nur relevant sind wenn es perönlich direkt abgegeben wurde
                 * wird nur geprüft ob die charge jetzt gerade valide ist
                 */
                if (!val.locked && validFrom.isSameOrBefore(moment()) && moment().isSameOrBefore(validTo)) {
                    this.selectDataProductBatches[key].push({
                        id: val.charge,
                        label: val.charge,
                    });
                }
            }
        }
    }

    /*
     * Initialisierung des Forms
     */
    private initForm() {
        const result = {};
        result['productsFormArray'] = this.getProductFormControls();
        return new UntypedFormGroup(result);
    }

    /**
     * Initialisierung der Form-Controls für die Auftragspositionen
     * @returns {FormArray}
     */
    private getProductFormControls() {
        // Falls keine produkte exisiteren, keine anzeigen
        if (!this.checkIfProductsExits()) {
            return new UntypedFormArray([]);
        }

        const result = [];
        for (const [orderRangeKey, orderRange] of Object.entries(
            this.orderEntryProductList[this.selectedOrderType],
        )) {
            for (const childProducts of Object.values(orderRange)) {
                for (const productEntity of Object.values(childProducts)) {
                    // zusätzliche Dimension für mehrere Lieferempfänger, es wird eine Zeile je Produkt+Empfänger erstellt
                    const numOfDeliveryInstitutions = this.orderDataCopy?.deliveryInstitutions?.length ?? 1;

                    for (const recipient of numberRange(1, numOfDeliveryInstitutions)) {
                        let orderItemDeliveryInstitutionId = 0;
                        if (hasOwn(this.orderDataCopy ?? null, 'deliveryInstitutions')) {
                            orderItemDeliveryInstitutionId =
                                this.orderDataCopy?.deliveryInstitutions[recipient - 1] ?? null;
                        }

                        const childResult = {
                            product_id: new UntypedFormControl(productEntity['id']),
                            product_erp_number: new UntypedFormControl(productEntity['erp_number']),
                            pzn: new UntypedFormControl(productEntity['pzn']),
                            order_item_delivery_institution_id: new UntypedFormControl(orderItemDeliveryInstitutionId),
                            order_item_delivery_institution_index: new UntypedFormControl(recipient),
                            parent_product_id: new UntypedFormControl(productEntity['parent_id']),
                            product_label: new UntypedFormControl(productEntity['label']),
                            amount: new UntypedFormControl(0, Validators.min(0)),
                            amountPerDeliveryDate: this.createDeliveryDatesFormArray(),
                            unit_price: new UntypedFormControl(productEntity['price']),
                            discount: new UntypedFormControl(this.orderDataCopy.order_discount ?? 0, [
                                Validators.min(0),
                                Validators.max(100),
                            ]),
                            charge: new UntypedFormControl(null),
                            total_price: new UntypedFormControl(null),
                            quantity: new UntypedFormControl(productEntity['quantity']),
                            max_amount: new UntypedFormControl(productEntity['max_amount']),
                            valid_from: new UntypedFormControl(productEntity['valid_from']),
                            valid_to: new UntypedFormControl(productEntity['valid_to']),
                            // speichern des list_key für Sortiment, damit man später noch weiß zu welchem Sortiment das Produkt gehört
                            order_range: new UntypedFormControl(orderRangeKey),
                        };
                        result.push(new UntypedFormGroup(childResult));

                        // Listener für die Eingabefelder dieses Produkts registrieren und in Liste der Subscriptions aufnehmen
                        this.productItemFieldsSubscriptions.add(
                            childResult.amountPerDeliveryDate.valueChanges.subscribe((value) => {
                                // das (unsichtbare) control für die Gesamtmenge des Items aktualisieren
                                childResult.amount.setValue(value.map((g) => g.amountAtDate).reduce(toSum, 0));
                            }),
                        );

                        // Der aufsummierte Amount und Discount des Items gehen in die weitere Berechnung der Summen/Rabatte ein
                        this.productItemFieldsSubscriptions.add(
                            merge(childResult.amount.valueChanges, childResult.discount.valueChanges).subscribe(() => {
                                let discount = childResult.discount.value;
                                if (!this.orderFormService.orderAccessRightsExplicit['order_item_discount']['ngIf']) {
                                    discount = 0;
                                }
                                childResult.total_price.setValue(
                                    childResult.amount.value * childResult.unit_price.value * (1 - discount),
                                );
                            }),
                        );
                    }
                }
            }
        }
        return new UntypedFormArray(result);
    }

    /**
     * @brief Variante für die Mengen-Buttons bei den Auftragspositionen mit mehreren Lieferdaten,
     *        falls eine Quantity gesetzt ist, wird der amount um diesen Wert erhöht
     * @param {number} productIndex
     * @param {number} dateIndex
     * @param {number} multiplyer
     */
    changeAmountAtDate(productIndex: number, dateIndex: number, multiplyer: number) {
        const orderItemForm = this.orderItemFormGroup.controls.productsFormArray['controls'][productIndex];
        const currentQuantity = parseInt(orderItemForm.value.quantity, 10);
        const deliveryDateAmountControl =
            orderItemForm.controls.amountPerDeliveryDate.controls[dateIndex].controls.amountAtDate;

        const currentAmmountValue = parseInt(deliveryDateAmountControl.value, 10);

        let amount = multiplyer;
        if (currentQuantity > 0) {
            amount = currentQuantity * multiplyer;
        }

        if (currentAmmountValue + amount < 0) {
            deliveryDateAmountControl.setValue(0);
        } else {
            deliveryDateAmountControl.setValue(currentAmmountValue + amount);
        }
        this.overwriteDiscounts(productIndex);
    }

    /**
     * @brief Setzt die Rabatte falls bestellmenge die mindest menge überschreitet
     * @param {number} productIndex
     */
    overwriteDiscounts(productIndex) {
        const orderItemForm = this.orderItemFormGroup.controls.productsFormArray['controls'][productIndex];
        // temp variablen
        const productId = orderItemForm.value.product_id;
        const specificDiscounts =
            notEmptyObject(this.discounts[0]) && notEmptyObject(this.discounts[0][0]) ?
                this.discounts[0][0][productId] :
                false;

        const discountControl = orderItemForm.controls.discount;
        // check if specific Discount are set
        if (specificDiscounts) {
            // Falls mehrere Lieferdaten gewählt sind, soll die summe dieser genommen werden
            let amountSum = 0;
            for (const element of orderItemForm.controls.amountPerDeliveryDate.controls) {
                amountSum += element.value.amountAtDate;
            }

            // berechnet des höchsten Rabattes das über dem min_amount liegt
            let highestDiscount = 0;
            for (const specificDiscount of specificDiscounts) {
                if (amountSum >= specificDiscount.min_amount) {
                    if (specificDiscount.discount > highestDiscount) {
                        highestDiscount = specificDiscount.discount;
                    }
                }
            }

            // setzten des Wertes im reactive Form
            discountControl.setValue(highestDiscount);
        }
    }

    /*
     * Auswahl des Sortiments
     */
    selectProductRange(event: number | string) {
        this.selectedOrderRange = event;

        // Auswahlfelder für Produktgruppen aktualisieren
        this.selectDataProductGroups = [];
        this.getProductGroupsForSelectData();
        // Ausgewählte Produktgruppe zurücksetzen
        this.selectedParentProductId = 'all';
    }

    /*
     * Auswahl der Produktgruppe
     */
    selectParentProductId(event: number | string) {
        this.selectedParentProductId = event;
    }

    /**
     * @brief Prüfe ob Formular für Items sichbar sein soll
     * @details Bei einer Neuanlage eines Auftrags:
     *          Sichtbar wenn man grade im EditMode ist, eine Auftragsart ausgewählt ist und
     *          Produkte unter der ausgewählten Auftragsart vorhanden sind
     * @returns {boolean}
     */
    public checkIfOrderFormVisible(): boolean {
        // Prüfe ob es sich um eine Neuanlage handelt
        if (notEmpty(this.orderDataCopy) && notEmpty(this.orderDataCopy.id) && this.orderDataCopy.id > 0) {
            return this.editMode && !this.loading;
        }
        return (
            this.editMode &&
            !this.loading &&
            this.selectedOrderType !== undefined &&
            this.orderEntryProductList[this.selectedOrderType]
        );
    }

    /**
     * @brief Prüft ob einzelne OrderItems sichtbar sind
     * @param {FormControl} control
     * @returns {boolean}
     */
    public checkIfOrderItemVisible(control: UntypedFormControl): boolean {
        // Produkt Sortiment ermitteln
        const productOrderRange = control.get('order_range').value;
        // Parent-ID ermitteln
        const productParentId = control.get('parent_product_id').value;
        // Menge ermitteln
        const productAmount = control.get('amount').value;

        // Prüfe ob aktuell eine Produkt Suche aktiv ist
        if (this.productSearchActive) {
            // Suchwert zu lowercase da includes() case-sensitive ist
            const needle = this.productSearchValue.toLowerCase();

            // Gehe über alle Felder in denen gesucht werden soll
            for (const searchField of this.productDataFieldsForSearch) {
                // Hole Wert des Feldes
                const controlValue = control.get(searchField).value;
                // Prüfe Typ des Wertes und passe den Wert entsprechend für includes() an
                if (typeof controlValue === 'number') {
                    if (controlValue.toString().includes(needle)) {
                        return this.checkProductRangeAndGroupFilter(
                            productOrderRange,
                            productParentId,
                            productAmount,
                            control.valid,
                        );
                    }
                }
                if (typeof controlValue === 'string') {
                    if (controlValue.toLowerCase().includes(needle)) {
                        return this.checkProductRangeAndGroupFilter(
                            productOrderRange,
                            productParentId,
                            productAmount,
                            control.valid,
                        );
                    }
                }
            }

            // Wenn kein Wert gefunden worden ist
            return false;
        }
        // Wenn kein Suchfilter aktiv ist, wende regulären Filter an
        return this.checkProductRangeAndGroupFilter(
            productOrderRange,
            productParentId,
            productAmount,
            control.valid,
        );
    }

    /**
     * @brief Prüft ob Sortimentsfilter aktiv ist
     * @param {string} orderRange
     * @returns {boolean}
     */
    private checkProductRangeFilter(orderRange: string): boolean {
        // Prüfe ob "Alle" Filter ausgewählt ist
        if (this.selectedOrderRange === 'all') {
            return true;
        }
        return this.selectedOrderRange == orderRange;
    }

    /**
     * @brief Prüft ob Produkte unter dem ausgewählten Produktfilter sichtbar sein sollen
     * @param {number} productParentId
     * @param isControlValid
     * @param {number} productAmount
     * @returns {boolean}
     */
    private checkProductGroupFilter(productParentId: number, productAmount: number, isControlValid: boolean): boolean {
        // Prüfe ob Produktgruppe oder spezieller Filter ausgewählt ist
        if (this.selectedParentProductId == 'all') {
            // Gebe alle Produkte zurück beim Filter "Alle"
            return true;
        } if (this.selectedParentProductId == 'amountSet') {
            // Gebe alle Produkte dessen Menge gesetzt ist zurück
            return productAmount > 0 || !isControlValid;
        }
        // Gebe Produkte der ausgewählten Produktgruppe zurück
        return this.checkTopLevelProductGroup(productParentId);
    }

    /*
     * @brief Prüft ob Produkt direktes Child oder Subchild der höchsten Produkt-Gruppen Ebene ist
     */
    private checkTopLevelProductGroup(productParentId: number): boolean {
        if (this.selectedParentProductId == productParentId) {
            return true;
        }
        if (this.productGroupList[productParentId].parent_id === null) {
            return false;
        }
        return this.checkTopLevelProductGroup(this.productGroupList[productParentId].parent_id);
    }

    /**
     * @brief Prüfung auf Sortiment und Produktgruppen Filter
     * @param {string} orderRange
     * @param {number} productParentId
     * @param isControlValid
     * @param {number} productAmount
     * @returns {boolean}
     */
    private checkProductRangeAndGroupFilter(
        orderRange: string,
        productParentId: number,
        productAmount: number,
        isControlValid: boolean,
    ) {
        return (
            this.checkProductRangeFilter(orderRange) &&
            this.checkProductGroupFilter(productParentId, productAmount, isControlValid)
        );
    }

    /**
     * Suche nach Produkten in der Produktliste
     * @param {string} searchValue Wert im Such-Input-Feld
     */
    public searchProducts(searchValue: string) {
        // Prüfe vorerst ob ein Suchwert eingegeben worden ist
        if (notEmpty(searchValue) && typeof searchValue !== 'object') {
            this.productSearchActive = true;
        }
    }

    /**
     * Suche nach Produkten aufheben
     */
    public resetProductSearch() {
        this.productSearchActive = false;
        this.productSearchValue = null;
    }

    /**
     * @brief Wenn Lieferdaten hinzugefügt oder entfernt wurden, das interne Modell aktualisieren
     * @param {Date[]} deliveryDates
     * @private
     */
    private onChangedDeliveryDates(deliveryDates: Date[]) {
        // zunächst Werte der Felder auf 0 setzen, die durch die Änderung unsichtbar/unbenutzt werden
        if (
            notEmptyObject(this.deliveryDates) &&
            notEmptyObject(deliveryDates) &&
            notEmptyObject(this.orderItemFormGroup.controls.productsFormArray)
        ) {
            if (deliveryDates.length < this.deliveryDates.length) {
                const productsFormArrayControls = this.orderItemFormGroup.controls.productsFormArray['controls'];
                for (const p of numberRange(0, productsFormArrayControls.length - 1)) {
                    const amountControls = productsFormArrayControls[p].controls.amountPerDeliveryDate.controls;
                    if (notEmptyObject(amountControls)) {
                        // je nach Zustand der Initialisierung existieren noch nicht alle Controls, daher Zugriffe absichern
                        for (const d of numberRange(
                            deliveryDates.length,
                            Math.min(amountControls.length, this.deliveryDates.length - 1),
                        )) {
                            amountControls[d]?.controls?.amountAtDate?.setValue(0);
                        }
                    }
                }
            }
        }
        this.visibleDeliveryDates = deliveryDates.length;
        this.deliveryDates = deliveryDates;
    }

    /**
     * @brief Erzeugt einen FormArray für die Mengen-Eingaben je Lieferdatum an einem Item
     * @returns {FormArray}
     * @private
     */
    private createDeliveryDatesFormArray() {
        // maximale Anzahl möglicher Lieferdaten erstellen, damit die Controls nur jeweils ein- und ausgeblendet werden müssen
        return new UntypedFormArray(
            numberRange(1, this.orderFormService.maxDeliveryDates).map(
                () => new UntypedFormGroup({amountAtDate: new UntypedFormControl(0, Validators.min(0))}),
            ),
        );
    }

    /**
     * @brief Prüft ob die eingegebene Bestellmenge eines Produkts über die Mindestbestellmenge und unter der Maximalmenge liegt
     * @param {FormControl} control Feld mit Daten
     * @param {number} index Lieferdatum 1, 2, 3 oder 4
     * @returns {boolean}
     */
    public checkMaxAndMinOrderAmount(control: UntypedFormControl, index: number): boolean {
        // IDs ermitteln
        const productId = control.get('product_id').value;
        const parentId = control.get('parent_product_id').value;

        // Produkt Sortiment ermitteln
        const orderRangeKey = control.get('order_range').value;

        // Produkte holen
        const products = this.orderEntryProductList[this.selectedOrderType][orderRangeKey][parentId];

        // Maximal und Minimal Menge ermitteln
        const maxAmount = products.find((item) => item.id === productId).max_amount;
        const minAmount = products.find((item) => item.id === productId).min_amount;

        // momentan eigegebene Menge ermitteln
        const productAmountArray = control.get('amountPerDeliveryDate').value;
        let productAmount = 0;

        if (productAmountArray.length > index) {
            productAmount = productAmountArray[index].amountAtDate;
        }

        // Ausgabe ob Menge über- oder unterschritten worden ist, ignoriere falls keine Bestellung vom Produkt (Menge = 0)
        return (productAmount > maxAmount || productAmount < minAmount) && productAmount !== 0;
    }

    /**
     * @brief Wendet angegebenen Rabatt auf alle Auftragspositionen an
     * @param {number} discountNew
     */
    private applyDiscount(discountNew: number) {
        const orderItemFormGroups = this.orderItemFormGroup.controls.productsFormArray['controls'];
        for (const orderItem of orderItemFormGroups) {
            orderItem.patchValue({discount: discountNew});
        }
    }

    /**
     * prüft ob produkte für die gewählte Werte existieren
     * @returns
     */
    private checkIfProductsExits() {
        return !(
            this.orderEntryProductList == undefined ||
            this.orderEntryProductList == undefined ||
            this.orderEntryProductList[this.selectedOrderType] == undefined
        );
    }
}
