// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Dominik Treutle <d.treutle@pharmakon.software>
 */
:host {
  margin-top: 10px;
}

.operatorButton {
  background: #9e9e9e;
  border-radius: 20px;
  margin: 0px 5px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid-selection/grid-selection-panel/grid-selection-operatorbutton/grid-selection-operatorbutton.component.scss"],"names":[],"mappings":"AAAA;;;;;EAAA;AAOA;EACI,gBAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,mBAAA;EACA,0BAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Dominik Treutle <d.treutle@pharmakon.software>\n */\n\n:host {\n    margin-top: 10px;\n}\n\n.operatorButton {\n    background: #9e9e9e;\n    border-radius: 20px;\n    margin: 0px 5px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
