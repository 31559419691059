/**
 * @brief   Eingabe-Komponente für einen Counter
 * @details Ein Wert kann über Buttons "+" und "-" erhöht bzw. reduziert werden
 * @todo    Style
 *
 * Beispiel für Verwendung:
 * ------------------------
 * <phscw-input-counter name="counter" [(ngModel)]="outerCounterValue" counterRangeMin="0" counterRangeMax="5"></phscw-input-counter>
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {
    Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output,
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';

// Custom Validator (über factory closure)
/**
 *
 * @param maxValue
 * @param minValue
 */
export function createCounterRangeValidator(maxValue, minValue) {
    return function validateCounterRange(c: UntypedFormControl) {
        const err = {
            rangeError: {
                given: c.value,
                max: maxValue,
                min: minValue,
            },
        };

        return c.value > +maxValue || c.value < +minValue ? err : null;
    };
}

@Component({
    selector: 'phscw-input-counter',
    templateUrl: './input-counter.component.html',
    styleUrls: ['./input-counter.component.scss'],
    providers: [
        {
            // custom value accessor for dependency injection (https://blog.thoughtram.io/angular2/2015/11/23/multi-providers-in-angular-2.html)
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputCounterComponent),
            multi: true,
        },
        {
            // custom validator
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputCounterComponent),
            multi: true,
        },
    ],
})
export class InputCounterComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
    // EditMode aktiv?
    @Input() editMode = false;

    // Model "counterValue"
    @Input() _counterValue = 0;
    get counterValue() {
        return this._counterValue;
    }

    set counterValue(value) {
        this._counterValue = value;
        this.propagateChange(this._counterValue);
    }

    // Min & Max für Counter zur Berücksichtigung im Validator
    @Input() counterRangeMax;
    @Input() counterRangeMin;
    // Soll der Löschbutton angezeigt werden
    @Input() showDeleteButton = false;

    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    propagateChange = (_: any) => {};

    validateFn: any;

    // Event-Emitter, falls der Wert gelöscht wurde
    @Output() deleteClicked = new EventEmitter<any>();

    /**
     * Konstruktor (inkl. dependency injection)
     */
    constructor() {}

    /**
     * Initialisieren
     */
    ngOnInit() {}

    /**
     * Ändern
     * @param changes
     */
    ngOnChanges(changes: any) {
        if (changes.counterRangeMin || changes.counterRangeMax) {
            this.validateFn = createCounterRangeValidator(this.counterRangeMax, this.counterRangeMin);
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * Validieren
     * @param c
     */
    validate(c: UntypedFormControl) {
        return this.validateFn(c);
    }

    /**
     * Wert des Counters erhöhen
     */
    increment(): void {
        this.counterValue++;
    }

    /**
     * Wert des Counters verringern
     */
    decrement(): void {
        this.counterValue--;
    }

    /**
     * Interface ControlValueAccessor: writeValue
     * @param value
     */
    writeValue(value: any) {
        if (value !== undefined) {
            this.counterValue = value;
        }
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}

    /**
     * Lösche den Counter-Wert
     */
    deleteValue(): void {
        this.counterValue = 0;
        this.deleteClicked.emit();
    }
}
