import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-targets-customer-contacts',
    templateUrl: './chart-targets-customer-contacts.component.html',
    styleUrls: ['./chart-targets-customer-contacts.component.scss'],
})
export class ChartTargetsCustomerContactsComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
