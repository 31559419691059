export class SelectionOperator {
    type: string;
    children: any[];
    frontendName: string;

    // Konstruktor
    constructor() {
        this.type = 'AND';
        this.children = [];
        this.frontendName = 'UND';
    }
}
