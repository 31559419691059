// Angular-Module
import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
// Eigener Service
import {InputHtmlEditorService} from './input-html-editor.service';
// Interfaces für Structured Objects einbinden
import {CWResult} from './../../cw-result';

@Component({
    selector: 'phscw-input-html-editor',
    templateUrl: './input-html-editor.component.html',
    styleUrls: ['./input-html-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputHtmlEditorComponent),
            multi: true,
        },
    ],
})
export class InputHtmlEditorComponent implements OnInit, OnDestroy {
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string = null;
    // EditMode aktiv?
    @Input() editMode = false;
    // Model "myValue" mit GETTER & SETTER
    _myValue = '';
    inputId: any = '';
    required = false;
    get myValue() {
        return this._myValue;
    }

    @Input() set myValue(value) {
        this._myValue = value;
        this.propagateChange(this._myValue);
    }

    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    propagateChange = (_: any) => {};

    // In Ansichtsmodus verstecken, wenn leer?
    @Input() hideEmpty = true;

    // Instanz des Editors
    editor: any;
    // Config für HTML-Editor
    editorConfig = {
        history: {
            delay: 2000,
            userOnly: true,
        },
        toolbar: {
            container: [
                ['undo', 'redo'],
                ['bold', 'underline', 'strike'],
                [{list: 'ordered'}, {list: 'bullet'}],
                [{header: [1, 2, 3, 4, 5, 6, false]}],
                ['image'],
                ['clean'],
            ],
            handlers: {
                undo: this.undo.bind(this),
                redo: this.redo.bind(this),
                image: this.selectImage.bind(this),
            },
        },
    };

    /**
     * Konstruktor (inkl. dependency injection)
     * @param inputHtmlEditorService
     */
    constructor(private inputHtmlEditorService: InputHtmlEditorService) {}

    /**
     * Initialisieren
     */
    ngOnInit() {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * Instanz des Editors zwischenspeichern
     * @param instance
     */
    initializeEditor(instance) {
        this.editor = instance;
    }

    /**
     * Änderung rückgängig machen
     */
    undo() {
        this.editor.history.undo();
    }

    /**
     * Änderung wiederherstellen
     */
    redo() {
        this.editor.history.redo();
    }

    /**
     * @brief   Bild auswählen
     * @todo:   @HostListener statt manuellem addEventListener
     */
    selectImage(): void {
        // Input definieren
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/svg+xml');

        // Datei prüfen
        input.addEventListener('change', () => {
            const file = input.files[0];

            // nur Bilddateien hochladen
            if (/^image\//.test(file.type)) {
                this.uploadImage(file);
            }
        });

        // Auslösen
        input.click();
    }

    // Bild hochladen
    /**
     * Konstruktor (inkl. dependency injection)
     * @param file
     */
    uploadImage(file: any): void {
        const formData = new FormData();
        formData.append('file', file);

        const serviceRequest$ = this.inputHtmlEditorService.uploadImage(formData);
        serviceRequest$.subscribe((result: CWResult) => {
            // Bei Erfolg Bild in Editor einbinden
            if (result['success']) {
                const range = this.editor.getSelection();
                this.editor.insertEmbed(range.index, 'image', result['data']);

                // Änderung "manuell" auslösen
                this.myValue = this.editor.root.innerHTML;
            } else {
                /*
                 * Fehlermeldung anzeigen
                 * @todo: Fehlermeldung programmieren
                 */
            }
        });
    }

    /**
     * Interface ControlValueAccessor: writeValue
     * @param value
     */
    writeValue(value: any) {
        if (value !== undefined) {
            this.myValue = value;
        }
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}
}
