// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../cw-event';

@Injectable({providedIn: 'root'})
export class SplitterService {
    // Subjects (Observables) definieren für Splitter-Aktionen
    public eventFullscreenStatusChanged = new Subject<CWEvent>();
    public eventSplitterPositionChanged = new Subject<CWEvent>();

    /**
     * Konstruktor (inkl. dependency injection)
     */
    constructor() {}

    /*
     * Fullscreen-Status wurde verändert
     */
    fullscreenStatusChanged(target: string, status: boolean) {
        const eventData: CWEvent = {
            sender: 'splitter',
            target,
            data: {status},
        };
        this.eventFullscreenStatusChanged.next(eventData);
    }

    /*
     * Splitter Position wurde verändert
     */
    splitterPositionChanged(setting: string) {
        const eventData: CWEvent = {
            sender: 'splitter',
            target: '',
            data: {setting},
        };
        this.eventSplitterPositionChanged.next(eventData);
    }
}
