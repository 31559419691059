import {Pipe, PipeTransform} from '@angular/core';

/**
 * @brief Ersetzt Sales platzhalter
 * @author Eric Haeussel <e.haeusel@pharmakon.software>
 */
@Pipe({name: 'replaceSalesPlaceholdersPipe'})
export class ReplaceSalesPlaceholdersPipe implements PipeTransform {
    // String zum markieren der Platzhalter in den Columnheader
    public placeHolderDelimiter = '|||';

    transform(value: string, salesDateSelectionData: any[]): string {
        if (salesDateSelectionData == undefined) {
            value = value.replaceAll(this.placeHolderDelimiter, '');
            return value;
        }
        if (value.includes(this.placeHolderDelimiter)) {
            let found = false;
            // Über alle Konfigraution laufen und versuchen diese mit dem entsprechenden Label zu ersetzen
            for (let i = 0; i < salesDateSelectionData.length; i++) {
                const toBeReplaced =
                    this.placeHolderDelimiter + salesDateSelectionData[i]['id'] + this.placeHolderDelimiter;
                if (value.includes(toBeReplaced)) {
                    found = true;
                    value = value.replace(toBeReplaced, salesDateSelectionData[i]['label']);
                    continue;
                }
            }

            // Wenn keine Ersetzung statt fand alle Platzhalter markierungen entfernen
            if (!found) {
                console.error('config missing for ' + value);
                value = value.replaceAll(this.placeHolderDelimiter, '');
            }
        }
        return value;
    }
}
