import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-targets-regions',
    templateUrl: './chart-targets-regions.component.html',
    styleUrls: ['./chart-targets-regions.component.scss'],
})
export class ChartTargetsRegionsComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
