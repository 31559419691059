// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
 * @author  Michael Schiffner <m.schiffner@pharmakon.software>
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
*/
:host .mat-expansion-panel-header-title {
  line-height: 1.2;
  min-height: 40px;
  position: relative;
}

:host .loading {
  right: 0;
  position: absolute;
}

.cw-characteristic-groups-list-toolbar {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  :host .mat-expansion-panel-header {
    padding: 0 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/characteristics/characteristic-groups-list/characteristic-groups-list.component.scss"],"names":[],"mappings":"AAAA;;;;;;;CAAA;AAYA;EACI,gBAAA;EACA,gBAAA;EACA,kBAAA;AAHJ;;AAMA;EACI,QAAA;EACA,kBAAA;AAHJ;;AAMA;EACI,gBAAA;AAHJ;;AAMA;EACI;IACI,eAAA;EAHN;AACF","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n * @author  Michael Schiffner <m.schiffner@pharmakon.software>\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n*/\n\n:host {\n}\n\n:host .mat-expansion-panel-header-title {\n    line-height: 1.2;\n    min-height: 40px;\n    position: relative;\n}\n\n:host .loading {\n    right: 0;\n    position: absolute;\n}\n\n.cw-characteristic-groups-list-toolbar {\n    margin-bottom: 0;\n}\n\n@media (max-width: 991px) {\n    :host .mat-expansion-panel-header {\n        padding: 0 12px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
