// Angular-Module
import {Component, Input, OnDestroy, OnInit, SimpleChanges, OnChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services
import {UserPermissionsService} from '@global/services/user-permissions.service';
// Service des übergeordneten Feature-Moduls

// Eigener Service
import {EntityAffiliationService} from './entity-affiliation.service';
// Shared Services
import {GridService} from '@shared/grid/grid.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {AffiliationType, EntityAffiliation} from '@shared/entity-affiliation';
import {LooseObject} from '@shared/loose-object';
// Shared Components
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';

@Component({
    selector: 'phscw-entity-affiliation',
    templateUrl: './entity-affiliation.component.html',
    styleUrls: ['./entity-affiliation.component.scss'],
})
export class EntityAffiliationComponent implements OnInit, OnDestroy, OnChanges {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$: Subject<void> = new Subject<void>();

    // Berechtigungen
    allowNewAffiliation = true;
    allowDeleteAffiliation = false;

    // ID der aktuell ausgewählten Einrichtung
    entityId: number;
    // Name der ausgewählten Einrichtung
    entityName: string;
    // ID der übergeordneten Einrichtung
    parentEntityId: number = null;
    // ID der untergeordneten Einrichtung
    childEntityId: number = null;

    // Modul-Daten
    childrenData: EntityAffiliation[] = [];
    parentsData: EntityAffiliation[] = [];
    partnerData: EntityAffiliation[] = [];

    // EditMode aktiv?
    @Input() editMode = false;

    // id?
    @Input() activeID: number;
    @Input() entityType: AffiliationType;

    // Flag definiert ob gerade geladen wird
    loading = false;
    // Flag definiert ob Daten gefunden wurden
    dataExists = true;

    // Spaltendefinitionen für Grid
    gridColumns: LooseObject[] = [
        {
            columnDef: 'relationship-type',
            header: 'Typ',
            columnField: 'relationship_type',
            formatTemplate: 'listentries',
            listentry: 'entityRelationshipType',
        },
    ];

    // Anzuzeigende Spalten für Grid
    gridChildDisplayedColumns: string[] = ['icon', 'entity-name', 'relationship-type', 'delete'];
    gridParentDisplayedColumns: string[] = ['icon', 'entity-name', 'relationship-type', 'delete'];
    gridPartnerDisplayedColumns: string[] = ['icon', 'entity-name', 'relationship-type', 'delete'];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param dialog
     * @param translateService
     * @param userPermissionsService
     * @param entityAffiliationService
     * @param toolbarService
     * @param gridService
     * @param storageService
     * @param initService
     * @param overlayService
     */
    constructor(
        private dialog: MatDialog,
        private translateService: TranslateService,
        private userPermissionsService: UserPermissionsService,
        private entityAffiliationService: EntityAffiliationService,
        private toolbarService: ToolbarService,
        private gridService: GridService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();

        // Berechtigungen laden
        this.checkPermissions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Wenn eine neue Verknüpfung angelegt werden soll...
        this.toolbarService.eventAddItem.pipe(takeUntil(this._componentDestroyed$)).subscribe((event: CWEvent) => {
            // Abbruch, falls das Event nicht von der eigenen Komponente kam
            if (event.target !== 'entityAffiliation') {
                return;
            }
            this.onEventAddItem(event);
        });

        // Wenn eine bestehende Verknüpfung gelöscht werden soll...
        this.gridService.eventGridRowDeleteClicked.pipe(takeUntil(this._componentDestroyed$)).subscribe((event: CWEvent) => {
            // Abbruch, falls das Event nicht von der eigenen Komponente kam
            if (!['childEntityAffiliation', 'parentEntityAffiliation', 'partnerEntityAffiliation'].includes(event.sender)) {
                return;
            }
            this.onEventDeleteItem(event);
        });
    }

    /**
     * @description   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Eric Haeussel <t.haeusel@pharmakon.software>
     */
    initializeTranslateSubscriptions(): void {
        this.translateService
            .stream(['GENERAL.TYPE', 'SHARED.AFFILIATION.HEADER.NUMBER', 'SHARED.AFFILIATION.HEADER.NAME'])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: string[]) => {
                this.gridColumns.find((column: any) => column.columnDef === 'relationship-type').header = translation['GENERAL.TYPE'];
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeID) {
            this.loadData();
        }
    }

    onEditModeChange(isEditMode: boolean) {
        this.editMode = isEditMode;
        if (!isEditMode) {
            this.loadData(); // Call your loadData function
        }
    }

    /**
     * Daten laden
     */
    loadData(): void {
        // Flag setzen
        this.loading = true;

        // Request senden, zum Laden der Verknüpfungsdaten einer Einrichtung
        const serviceRequest$ = this.entityAffiliationService.loadData(this.activeID);
        serviceRequest$.subscribe(
            (result: CWResult) => {
                // Daten zwischenspeichern
                this.childrenData = result['child'];
                this.parentsData = result['parent'];
                this.partnerData = [];

                this.childrenData.forEach((element, index) => {
                    this.addIcons(element);
                    element.icon = element.child_icon;
                    element.display_name = element.child_name;
                    element.display_key = element.child_key;
                    element.display_type = element.child_type;
                    if (element.equal_partners) {
                        this.mapChildToDisplay(element);
                        this.childrenData.splice(index);
                        this.partnerData.push(element);
                    }
                });

                this.parentsData.forEach((element, index) => {
                    this.addIcons(element);
                    element.icon = element.parent_icon;
                    element.display_name = element.parent_name;
                    element.display_key = element.parent_key;
                    element.display_type = element.parent_type;
                    if (element.equal_partners) {
                        this.mapParentToDisplay(element);
                        this.parentsData.splice(index);
                        this.partnerData.push(element);
                    }
                });

                // Prüfen, ob Daten gefunden wurden
                if ((result['child'] && result['child'].length) || (result['parent'] && result['parent'].length) || (this.partnerData.length > 0)) {
                    this.dataExists = true;
                } else {
                    this.dataExists = false;
                }

                // Flag deaktivieren
                this.loading = false;
            },
            (error: any) => {
                // Logging
                console.error(error);

                // Flag deaktivieren
                this.loading = false;
            },
        );
    }

    addIcons(row: EntityAffiliation): void {
        row.parent_icon = this.translateTypeToIcon(row.parent_type);
        row.child_icon = this.translateTypeToIcon(row.child_type);
    }

    translateTypeToIcon(type: string): string {
        let result = 'no icon Defined For type';
        switch (type) {
            case AffiliationType.Institution: {
                result = 'house';
                break;
            }
            case AffiliationType.Person: {
                result = 'user';
                break;
            }
            case AffiliationType.Patient: {
                result = 'user';
                break;
            }
            default:
                break;
        }
        return result;
    }

    mapChildToDisplay(element: EntityAffiliation) {
        element.display_key = element.child_key;
        element.display_name = element.child_name;
        element.display_note = element.child_note;
        element.display_type = element.child_type;
        element.icon = element.child_icon;
    }

    mapParentToDisplay(element: EntityAffiliation) {
        element.display_key = element.parent_key;
        element.display_name = element.parent_name;
        element.display_note = element.parent_note;
        element.display_type = element.parent_type;
        element.icon = element.parent_icon;
    }

    /**
     * Daten zurücksetzen (z.B. wenn Einrichtung gewechselt wird)
     */
    resetData(): void {
        this.editMode = false;
        this.childrenData = [];
        this.parentsData = [];
    }

    /**
     * Initialisiere Formular für Neuanlage einer Verknüpfung
     * @TODO wiederbeleben für generelle Verknüpfungen
     * @param event
     */
    onEventAddItem(event: CWEvent): void {
        // Reihenfolge bestimmen
        if (event.sender === 'toolbar-add-child') {
            this.parentEntityId = this.entityId;
            this.childEntityId = 0;
        } else if (event.sender === 'toolbar-add-parent') {
            this.parentEntityId = 0;
            this.childEntityId = this.entityId;
        }
        // EditMode einschalten
        this.editMode = true;
    }

    /**
     * Zu löschende Verknüpfung vorbereiten
     * @TODO wiederbeleben für generelle Verknüpfungen
     * @param event
     */
    onEventDeleteItem(event: CWEvent): void {
        // Warten bis fertig geladen wurde
        if (this.loading) {
            return;
        }

        // Dialog öffnen
        this.openDeleteDialog(event);
    }

    /**
     * @param event
     * @description   Dialog zum Löschen öffnen
     * @TODO wiederbeleben für generelle Verknüpfungen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openDeleteDialog(event: CWEvent): void {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('MODULES.INSTITUTIONS.NETWORK.DELETELINKEDINSTITUTION'),
                message: this.translateService.instant('MODULES.INSTITUTIONS.NETWORK.DELETELINKEDINSTITUTIONQUESTION'),
            },
        });

        // auf Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result: LooseObject) => this.onDeleteDialogClosed(result.answer, event));
    }

    /**
     * @param answer
     * @param event
     * @description   Auf Schließen des Dialog zum Löschen reagieren
     * @TODO wiederbeleben für generelle Verknüpfungen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onDeleteDialogClosed(answer: string, event: CWEvent): void {
        // Wenn im Dialog "Ja" geklickt wurde
        if (answer === 'yes') {
            // Daten vorbereiten
            const clickedInstitutionId: string = event.data['selectedRow']['id'];

            // Daten löschen
            this.deleteData(clickedInstitutionId);
        }
    }

    deleteData(id: string): void {
        // Flag setzen
        this.loading = true;

        const serviceRequest$ = this.entityAffiliationService.crudDeleteData(id);
        serviceRequest$.subscribe({
            next: (result) => {
                // Flag deaktivieren
                this.loading = false;
                this.loadData();
            },
            error: (error: any) => {
                // Logging
                console.error(error);

                // Flag deaktivieren
                this.loading = false;
            },
        });
    }

    /**
     * @description   Berechtigungen überprüfen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkPermissions(): void {
        this.allowDeleteAffiliation = this.userPermissionsService.getPermissionValue('allowDeleteAffiliation');
        this.allowNewAffiliation = this.userPermissionsService.getPermissionValue('allowNewAffiliation');

        if (!this.allowDeleteAffiliation) {
            this.removeDelFromGrid(this.gridParentDisplayedColumns);
            this.removeDelFromGrid(this.gridChildDisplayedColumns);
            this.removeDelFromGrid(this.gridPartnerDisplayedColumns);
        }
    }

    private removeDelFromGrid(grid: string[]): void {
        const columnIndex = grid.findIndex((source: string) => source === 'delete');
        if (columnIndex > -1) {
            grid.splice(columnIndex, 1);
        }
    }
}
