/**
 * @brief   Globaler Service für übergreifende / globale Events.
 * @details Cross-Module-Navigation
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {MenuData} from '@shared/menu-data';

@Injectable({
    // Root-Injector (app.module.ts) ist verantwortlich für das Instanziieren dieses globalen Services
    providedIn: 'root',
})
export class AppCoreService {
    // Subject (Observable) definieren "Logout"
    public appLogout = new Subject<boolean>();

    // Subject (Observable) definieren "appDataChanged"
    public appDataChanged = new Subject<CWEvent>();

    // Parameter beim modulübergreifenden Wechsel
    public crossModuleActive = false;
    public crossModuleTarget = '';
    public crossModuleParameter: any = {};

    /**
     * Subject (Observable) definieren "GridSource ändern"
     * ===================================================
     * Dieses AppCore-Event kann von unterschiedlichen Modulen heraus
     * gestartet werden.
     *
     * Zum Beispiel in "institutions.component" um Datenquelle in P-Liste zu
     * ändern und dann zur P-Liste zu wechseln.
     */
    public changeGridSource = new Subject<CWEvent>();

    // Subject (Observable) für Änderung im globalen Menü --> "Es wurde ein anderes Hauptmodul ausgewählt"
    public globalMenuChanged = new Subject<CWEvent>();
    public globalMenu: MenuData[] = [];
    public submodules: any[] = [];

    // Konstruktor
    constructor(private router: Router) {}

    /**
     * @param target
     * @param parameter
     * @brief   Wechsel zwischen zwei (lazy loaded) Modulen
     * @details Da die Feature Module nicht alle direkt beim Programmstart geladen
     *          werden, sondern erst bei Bedarf, kann nicht eine modulübergreifende
     *          Navigation nicht über EventListener stattfinden (da das aufgerufene
     *          Modul z.B. noch gar nicht initialisiert wurde). Daher werden
     *          notwendige Parameter im AppCore zwischengespeichert, die Navigation
     *          auf das gewünschte Modul durchgeführt und die Paramter aus
     *          AppCore wieder geladen.
     *          Dieses Vorgehen wird leider sehr schnell komplex und ist noch
     *          nicht 100% ausgereift.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    crossModuleNavigation(target: string, parameter: any) {
        // Parameter zwischenspeichern
        this.crossModuleActive = true;
        this.crossModuleTarget = target;
        this.crossModuleParameter = parameter;

        // Navigation ausführen
        this.router.navigate([target]);

        /*
         * 2019-03-12, PhS(TH): Prüfe nach Router Navigation ob noch Tooltips
         *                      existieren und entferne diese aus dem DOM.
         *                      Temporäre Lösung für Angular Material Bug.
         *                      siehe https://github.com/angular/material2/issues/11478
         *
         *                      @todo: Code-Block entfernen, sobald der Fehler in Material behoben wurde.
         */
        const tooltips = document.getElementsByTagName('mat-tooltip-component');
        if (tooltips.length > 0) {
            const node = tooltips[0].parentElement.parentNode;
            node.parentNode.removeChild(node);
        }
    }

    /**
     * @brief   Parameter für Cross-Module-Navigation zurücksetzen
     */
    crossModuleReset() {
        this.crossModuleActive = false;
        this.crossModuleTarget = '';
        this.crossModuleParameter = {};
    }
}
