import {Component, Input, OnInit} from '@angular/core';
import {hasOwn} from '@shared/utils';

@Component({
    selector: 'phscw-chart-weekly-count-visits',
    templateUrl: './chart-weekly-count-visits.component.html',
    styleUrls: ['./chart-weekly-count-visits.component.scss'],
})
export class ChartWeeklyCountVisitsComponent implements OnInit {
    @Input() tooltipFunction = function () {
        let res = '';

        for (const key in this.point) {
            if (hasOwn(this.point, key) && key.startsWith('c-world-')) {
                res += key.replace('c-world-', '');
                // Nur Wert anzeigen wenn gesetzt ist, Key wird immer angezeigt da hier die "Fehlermeldung" drin stehen könnte
                if (this.point[key]) {
                    res += ': ' + this.point[key] + '%</br>';
                }
            }
        }
        if (res == '') {
            return 'Anzahl Kontakte :' + this.y;
        }

        return res;
    };

    constructor() {}

    ngOnInit() {}
}
