// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../../cw-event';

@Injectable({providedIn: 'root'})
export class ClearingDuplicatesPanelService {
    // Duplikate prüfen
    public eventDuplicatesCheckRequired = new Subject<CWEvent>();
    // Prüfung abgeschlossen
    public eventDuplicatesCheckComplete = new Subject<CWEvent>();
    // Merge auslösen
    public eventMergeSelectedDuplicate = new Subject<CWEvent>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param backendService
     */
    constructor(private backendService: BackendService) {}

    /**
     * @param entityId
     * @param data
     * @brief   Daten auf Duplikate prüfen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkForDuplicates(entityId: any, data: any) {
        const eventData: CWEvent = {
            sender: entityId,
            target: '',
            data,
        };
        this.eventDuplicatesCheckRequired.next(eventData);
    }

    /**
     * @param entityId
     * @param data
     * @brief   Prüfung abgeschlossen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    duplicatesCheckComplete(entityId: any, data: any = {}) {
        const eventData: CWEvent = {
            sender: 'clearing-duplicates-panel',
            target: entityId,
            data,
        };
        this.eventDuplicatesCheckComplete.next(eventData);
    }

    /**
     * @param data
     * @brief   Duplikate zusammenführen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    mergeSelectedDuplicate(data: any) {
        const eventData: CWEvent = {
            sender: 'clearing-duplicates-panel',
            target: '',
            data,
        };
        this.eventMergeSelectedDuplicate.next(eventData);
    }

    /**
     * @brief   Duplikate finden
     * @param   string  backendController   Name des anzusprechenden Controllers
     * @param backendController
     * @param formData
     * @param   Object  formData            eingegebene Daten
     * @returns  Observable<any>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadData(backendController: string, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(backendController + '/', formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Personendaten speichern
     * @param id
     * @param formData
     * @param   Object  formData    eingegebene Daten
     * @returns  Observable<any>
     * @todo    BackendURL als Parameter
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    saveData(id: number, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('PeopleData/edit/' + id, formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
