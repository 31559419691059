import {Component, Input, OnInit} from '@angular/core';
// Service dieses Shared-Moduls
import {ChartService} from './../../chart.service';

@Component({
    selector: 'phscw-chart-accordion-details',
    templateUrl: './chart-accordion-details.component.html',
    styleUrls: ['./chart-accordion-details.component.scss'],
})
export class ChartAccordionDetailsComponent implements OnInit {
    @Input() panelChildren: any[] = [];
    constructor(private chartService: ChartService) {}

    ngOnInit() {}

    confirm(child) {
        this.panelChildren.forEach((item, index) => {
            if (item === child) {
                this.panelChildren[index]['strikeout'] = true;

                // wenn animation fertig ist, eintrag löschen
                setTimeout(() => {
                    this.panelChildren.splice(index, 1);
                }, 1000);
            }
        });
    }

    // Klick auf Icon
    clickLinkIcon(id: number) {
        const ids: number[] = [id];
        this.chartService.linkIconClicked('chart-accordion', ids);
    }
}
