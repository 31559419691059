// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
:host {
  width: 100%;
}
:host mat-radio-button {
  margin-bottom: 5px; /* zu Input zentrieren */
}

.input-container {
  align-items: center;
}

.cw-input {
  margin: 0;
}

.cw-input-label {
  margin-bottom: 5px;
}

.cw-input-label.multiple-lines {
  align-self: flex-start;
  display: flex;
}

.multiple-lines.line-height {
  line-height: 26px; /* um Sprünge in der Positionirung zu verhindern */
}

.cw-button-wrapper {
  margin: 0 0 5px 0; /* zu Input zentrieren */
  min-width: 60px;
  width: auto;
}

.cw-toolbar-icon {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--color-primary-dark);
  font-size: 12px;
  height: 25px;
  margin-left: 5px;
  width: 25px;
}

.cw-toolbar-icon:hover {
  border-color: var(--color-primary-dark);
}

.cw-toolbar-icon:active {
  background-color: var(--color-primary-dark);
  color: var(--color-default-light);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/input/input-multiple-autocomplete/input-multiple-autocomplete.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,WAAA;AAEJ;AAAI;EACI,kBAAA,EAAA,wBAAA;AAER;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,SAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,aAAA;AACJ;;AAEA;EACI,iBAAA,EAAA,kDAAA;AACJ;;AAEA;EACI,iBAAA,EAAA,wBAAA;EACA,eAAA;EACA,WAAA;AACJ;;AAEA;EACI,6BAAA;EACA,6BAAA;EACA,gCAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,uCAAA;AACJ;;AAEA;EACI,2CAAA;EACA,iCAAA;AACJ","sourcesContent":[":host {\n    width: 100%;\n\n    mat-radio-button {\n        margin-bottom: 5px; /* zu Input zentrieren */\n    }\n}\n\n.input-container {\n    align-items: center;\n}\n\n.cw-input {\n    margin: 0;\n}\n\n.cw-input-label {\n    margin-bottom: 5px;\n}\n\n.cw-input-label.multiple-lines {\n    align-self: flex-start;\n    display: flex;\n}\n\n.multiple-lines.line-height {\n    line-height: 26px; /* um Sprünge in der Positionirung zu verhindern */\n}\n\n.cw-button-wrapper {\n    margin: 0 0 5px 0; /* zu Input zentrieren */\n    min-width: 60px;\n    width: auto;\n}\n\n.cw-toolbar-icon {\n    background-color: transparent;\n    border: 1px solid transparent;\n    color: var(--color-primary-dark);\n    font-size: 12px;\n    height: 25px;\n    margin-left: 5px;\n    width: 25px;\n}\n\n.cw-toolbar-icon:hover {\n    border-color: var(--color-primary-dark);\n}\n\n.cw-toolbar-icon:active {\n    background-color: var(--color-primary-dark);\n    color: var(--color-default-light);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
