/**
 * @brief   Globaler Service als Abstraktionsschicht für Backend-Kommunikation.
 * @details Alle HTTP-Requests (GET / POST) an das Backend werden über diesen
 *          Service verarbeitet.
 *          Jedes C-World 4 Modul verwendet hierzu die folgenden Methoden:
 *          - getRequest(url: string): Observable<any>
 *          - postRequest(url: string, data: object): Observable<any>
 *          - getFile(url: string): Observable<Blob>
 *          1. Version:
 *          -----------
 *          Jeder eintreffende Request wurde direkt an das Backend weitergeleitet.
 *          Dies hatte zur Folge, dass die Antworten der Requests in umgekehrter
 *          Reihenfolge der Aufrufe eintrafen: LIFO - Last In First Out
 *          2. Version:
 *          -----------
 *          Die eintreffenden Requests werden in einer Queue gesammelt und
 *          nacheinander an das Backend weitergeleitet. Dadurch wird immer der
 *          Request zuerst ausgeführt, der zuerst eintraf: FIFO - First In First Out
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Environment einbinden
import {environment} from '@environment';
// Angular-Module
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
import {share} from 'rxjs/operators';
import {GlobalErrorPopupComponent} from '@global/components/global-error-popup/global-error-popup.component';
import {MatDialog} from '@angular/material/dialog';
import {StorageService} from '@global/services/storage.service';

/**
 * @brief   Hilfsklasse zur Repräsentation eines Requests in der Warteschlange
 * @details Klasse wird nur in diesem Service benötigt, daher nicht in eigene
 *          Datei ausgelagert.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */
export class PendingRequest {
    // Attribute des wartenden Requests
    url: string;
    method: string;
    data: any;
    options: any;
    subscription: Subject<any>;

    // Konstruktor
    constructor(url: string, method: string, data: any, options: any, subscription: Subject<any>) {
        this.url = url;
        this.method = method;
        this.data = data;
        this.options = options;
        this.subscription = subscription;
    }
}

@Injectable({
    // Root-Injector (app.module.ts) ist verantwortlich für das Instanziieren dieses globalen Services
    providedIn: 'root',
})
export class BackendService {
    // Backend-URL (über Environment gesetzt)
    private backendUrl: string = environment.backendUrl;

    // Interner Request-Warteschlangen-Emitter
    private requests$ = new Subject<any>();

    // Request-Warteschlange
    private queue: PendingRequest[] = [];
    private configType = 'DEV';

    // Konstruktor (inkl. dependency injection)
    constructor(
        private http: HttpClient,
        private dialog: MatDialog,
        private storageService: StorageService,
    ) {
        const promise = this.storageService.getItem('config|configType');
        promise.then((value) => {
            this.configType = value;
        });
        // Warteschlange starten
        this.requests$.subscribe((request) => this.execute(request));
    }

    /**
     * @param requestData
     * @brief   HTTP-Request ausführen
     * @details Nachdem ein HTTP-Request eine Antwort lieferte, wird der nächste
     *          Request in der Queue gestartet.
     */
    private execute(requestData: PendingRequest) {
        // GET oder POST?
        if (requestData.method === 'get') {
            const getRequest = this.http.get(requestData.url, requestData.options);
            getRequest.subscribe(
                // Success
                (res) => {
                    // Event triggern
                    const sub = requestData.subscription;
                    sub.next(res);
                    sub.complete();
                    // Weiter mit nächstem Request
                    this.queue.shift();
                    this.startNextRequest();
                },
                // Error
                (error) => {
                    this.openErrorPopup(error, requestData.options);
                    // Fehler ausgeben
                    if (error.error instanceof ErrorEvent) {
                        // Clientseitiger Fehler oder Netzwerkfehler
                        console.error('An error occurred:', error.error.message);
                    } else {
                        // Backend-Error
                        console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
                    }

                    // Event triggern, um in den Komponenten spezifischen Code im Fehlerfall ausführen zu können (z.B. Flags zurücksetzen)
                    const sub = requestData.subscription;
                    sub.error(error);

                    /*
                     * Weiter mit nächstem Request in der Queue - sonst ist bei
                     * einem einzigen fehlgeschlagenen Request keine Backend-
                     * Kommunikation mehr möglich und die Queue hängt.
                     */
                    this.queue.shift();
                    this.startNextRequest();
                },
            );
        } else if (requestData.method === 'post') {
            const postRequest = this.http.post(requestData.url, requestData.data);
            postRequest.subscribe(
                // Success
                (res) => {
                    // Event triggern
                    const sub = requestData.subscription;
                    sub.next(res);
                    sub.complete();
                    // Weiter mit nächstem Request
                    this.queue.shift();
                    this.startNextRequest();
                },
                // Error
                (error) => {
                    this.openErrorPopup(error, requestData.data);
                    // Fehler ausgeben
                    if (error.error instanceof ErrorEvent) {
                        // Clientseitiger Fehler oder Netzwerkfehler
                        console.error('An error occurred:', error.error.message);
                    } else {
                        // Backend-Error
                        console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
                    }

                    // Event triggern, um in den Komponenten spezifischen Code im Fehlerfall ausführen zu können (z.B. Flags zurücksetzen)
                    const sub = requestData.subscription;
                    sub.error(error);

                    /*
                     * Weiter mit nächstem Request in der Queue - sonst ist bei
                     * einem einzigen fehlgeschlagenen Request keine Backend-
                     * Kommunikation mehr möglich und die Queue hängt.
                     */
                    this.queue.shift();
                    this.startNextRequest();
                },
            );
        } else if (requestData.method === 'delete') {
            const postRequest = this.http.delete(requestData.url, {body: requestData.data});
            postRequest.subscribe(
                // Success
                (res) => {
                    // Event triggern
                    const sub = requestData.subscription;
                    sub.next(res);
                    sub.complete();
                    // Weiter mit nächstem Request
                    this.queue.shift();
                    this.startNextRequest();
                },
                // Error
                (error) => {
                    this.openErrorPopup(error, requestData.data);
                    // Fehler ausgeben
                    if (error.error instanceof ErrorEvent) {
                        // Clientseitiger Fehler oder Netzwerkfehler
                        console.error('An error occurred:', error.error.message);
                    } else {
                        // Backend-Error
                        console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
                    }

                    // Event triggern, um in den Komponenten spezifischen Code im Fehlerfall ausführen zu können (z.B. Flags zurücksetzen)
                    const sub = requestData.subscription;
                    sub.error(error);

                    /*
                     * Weiter mit nächstem Request in der Queue - sonst ist bei
                     * einem einzigen fehlgeschlagenen Request keine Backend-
                     * Kommunikation mehr möglich und die Queue hängt.
                     */
                    this.queue.shift();
                    this.startNextRequest();
                },
            );
        }
    }

    /**
     * Öffnet ein Popup-Fenster mit einer Fehlermeldung.
     * @param {Error} error - Der Fehler, der angezeigt werden soll
     * @param {object} payload - Zusätzliche Daten, die an das Popup-Fenster übergeben werden
     * @returns {void}
     */
    openErrorPopup(error, payload): void {
        // niemals Popup im Prod zeigen
        if (this.configType == 'PROD') {
            return;
        }

        // Prüfen, ob ein Error dialog schon offen ist. Wenn ja, kein weiteres öffnen
        const isOpen = this.dialog.openDialogs.some(
            (ref) => ref.componentInstance instanceof GlobalErrorPopupComponent,
        );
        if (isOpen) {
            return;
        }

        // Dialog konfigurieren und öffnen
        this.dialog.open(GlobalErrorPopupComponent, {
            disableClose: true,
            maxWidth: '450px',
            maxHeight: '300px',
            data: {
                title: 'Es ist etwas schiefgegangen…',
                message: 'Daten konnten möglicherweise nicht gespeichert werden.',
                submessage: 'Bitte Netzwerkverbindung prüfen.',
                error,
                payload,
            },
        });
    }

    /**
     * @param url
     * @param method
     * @param data
     * @param options
     * @brief   Request über Queue behandeln
     * @details Abstraktion für die öffentlichen Methoden "getRequest",
     *          "postRequest".
     *          Dient aktuell als Weiterleitung zu "addRequestToQueue", ohne
     *          immer alle Parameter angeben zu müssen.
     * @returns  Subject<any>
     */
    private invoke(url: string, method: string, data: any = {}, options: any = {}): Subject<any> {
        return this.addRequestToQueue(url, method, data, options);
    }

    /**
     * @param url
     * @param method
     * @param data
     * @param options
     * @brief   Neuen Request in Warteschlange aufnehmen
     */
    private addRequestToQueue(url: string, method: string, data: any, options: any): Subject<any> {
        const subject = new Subject<any>();
        const request = new PendingRequest(url, method, data, options, subject);
        this.queue.push(request);
        if (this.queue.length === 1) {
            this.startNextRequest();
        }
        return subject;
    }

    /**
     * @brief   Nächsten Request der Warteschlange ausführen, insofern die
     *          Warteschlange nicht leer ist.
     * @details Wird über "execute" und "addRequestToQueue" aufgerufen.
     */
    private startNextRequest() {
        // Nur ausführen, falls sich mindestens ein Request in der Warteschlange befindet
        if (this.queue.length > 0) {
            // Ersten Request der Warteschlange ausführen: FIFO
            this.execute(this.queue[0]);
        }
    }

    /**
     * *************************************************************************
     * Die folgenden Funktionen repräsentieren die öffentlichen Methoden,
     * welche durch die einzelnen C-World Module aufgerufen werden dürfen.
     *************************************************************************
     */

    /**
     * @brief   GET-Request an das Backend senden
     * @param url
     * @param   string  url     Ziel-URL im Backend für diesen GET-Request
     * @returns  Observable<any> (this.http.get)
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    public getRequest(url: string): Observable<any> {
        // Sende GET-Request an Backend (über Warteschlange)
        return this.invoke(this.backendUrl + url, 'get');
    }

    public deleteRequest(url: string, data: Object): Observable<any> {
        // Sende GET-Request an Backend (über Warteschlange)
        return this.invoke(this.backendUrl + url, 'delete', data);
    }

    /**
     * @brief   POST-Request an das Backend senden
     * @param   string  url     Ziel-URL im Backend für diesen POST-Request
     * @param url
     * @param data
     * @param   object  data    Zu sendende Daten
     * @returns  Observable<any> (this.http.post)
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    public postRequest(url: string, data: object): Observable<any> {
        // Liefere Observable<any>
        return this.invoke(this.backendUrl + url, 'post', data);
    }

    /**
     * @brief   GET-Request an das Backend senden für Datei-Download
     * @param url
     * @details 2019-02-07, PhS(MFe): Nicht in Warteschlange aufgenommen.
     * @param   string  url     Ziel-URL von Datei im Backend
     * @returns  Observable<Blob> (this.http.post)
     * @author  Tobias Hannemann <m.feth@pharmakon.software>
     */
    public getFile(url: string): Observable<Blob> {
        // Sende GET-Request an Backend (shared!)
        return this.http.get(this.backendUrl + url, {responseType: 'blob'}).pipe(share());
    }

    /**
     * @brief   Post-Request an das Backend senden für Datei-Download
     * @param url
     * @param postPayload
     * @details 2019-02-07, PhS(MFe): Nicht in Warteschlange aufgenommen.
     * @param   string  url     Ziel-URL von Datei im Backend
     * @returns  Observable<Blob> (this.http.post)
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    public getFilePost(url: string, postPayload): Observable<Blob> {
        // Sende GET-Request an Backend (shared!)
        return this.http.post(this.backendUrl + url, postPayload, {responseType: 'blob'}).pipe(share());
    }

    /**
     * @brief   Asynchronen GET-Request an das Backend senden
     * @param url
     * @details Die asynchrone Version von @see getRequest(),
     *          startet den Request sofort ohne Queue, um parallel und schneller Daten vom Backend zu erhalten,
     *          was vor allem beim Login bessere Performance bringt.
     * @param   string  url     Ziel-URL im Backend für diesen POST-Request
     * @returns  Observable<any> (this.http.post)
     * @author  Tristan Krakau <t.krakau@pharmakon.software>
     */
    public getRequestAsync(url: string): Observable<any> {
        // Request-URL zusammenbauen
        const requestUrl = this.backendUrl + url;

        // unabhängig von der Warteschlange direkt den Request starten und Observable zurückgeben
        return this.http.get(requestUrl);
    }

    /**
     * @brief   Asynchronen POST-Request an das Backend senden
     * @details Die asynchrone Version von @see postRequest(),
     * @param url
     * @param data
     *          startet den Request sofort ohne Queue, um parallel und schneller Daten vom Backend zu erhalten,
     *          was vor allem beim Login bessere Performance bringt.
     * @param   string  url     Ziel-URL im Backend für diesen POST-Request
     * @param   object  data    Zu sendende Daten
     * @returns  Observable<any> (this.http.post)
     * @author  Tristan Krakau <t.krakau@pharmakon.software>
     */
    public postRequestAsync(url: string, data: object): Observable<any> {
        // Request-URL zusammenbauen
        const requestUrl = this.backendUrl + url;

        // Liefere Observable<any>
        return this.http.post(requestUrl, data);
    }
}
