// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid/grid-entity-name/grid-entity-name.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,eAAA;EACA,iBAAA;AACJ","sourcesContent":["a {\n    text-decoration: underline;\n    cursor: pointer;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
