// Import Angular-Module
import {Injectable} from '@angular/core';
// Import Moment-Modul zur Datumsformatierung
import * as _moment from 'moment';

const moment = _moment;

@Injectable({providedIn: 'root'})
export class InputDateService {
    // Konstruktor
    constructor() {}

    /**
     * @brief   Formatiere Datums-Daten die gespeichert werden, in dem für die Datenbank richtigem Format
     * @param   Moment-Object   dateValue   Datums-Objekt, das in einen String konvertiert werden soll
     * @param dateValue
     * @param dateFormat
     * @param ignoreUndefined
     * @param   string          dateFormat  Format-Konstante für den export von Datumsdaten zur Datenbank.
     * @returns  string
     * @author  Michael Schiffner <m.schiffner@pharmakon.software>
     */
    getDateValueForSave(dateValue: any, dateFormat = 'YYYY-DD-MM', ignoreUndefined = false) {
        if (ignoreUndefined && dateValue == undefined) {
            return dateValue;
        }

        if (typeof dateValue === 'object' && moment.isMoment(dateValue)) {
            return dateValue.format(dateFormat);
        } if (dateValue !== null && dateValue !== '') {
            /*
             * Nutze zum Formatieren in ein Moment-Date-Object das ISO 8601-Format by default.
             * Formatiere dann in das von der Datenbank benötigte Format
             */
            return moment(dateValue).format(dateFormat);
        }
        return null;
    }

    getDateValueForSaveWithTimezone(dateValue: any) {
        if (typeof dateValue === 'object' && moment.isMoment(dateValue)) {
            return dateValue.toDate().toISOString();
        } if (dateValue !== null && dateValue !== '' && dateValue instanceof Date) {
            /*
             * Nutze zum Formatieren in ein Moment-Date-Object das ISO 8601-Format by default.
             * Formatiere dann in das von der Datenbank benötigte Format
             */
            return dateValue.toISOString();
        } if (dateValue !== null && dateValue !== '') {
            /*
             * Nutze zum Formatieren in ein Moment-Date-Object das ISO 8601-Format by default.
             * Formatiere dann in das von der Datenbank benötigte Format
             */
            return moment(dateValue).toDate().toISOString();
        }
        return null;
    }
}
