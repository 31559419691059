// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Buttons
 * =======
 *
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
 */
:host button {
  margin-right: 10px;
  margin-top: 20px;
}

:host button:last-of-type {
  margin-right: 0;
}

:host .cw-button-wrapper {
  justify-content: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/institutions/institutions-clienttemplates/institutions-clienttemplates.component.scss"],"names":[],"mappings":"AAAA;;;;;EAAA;AAOA;EACI,kBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,eAAA;AAAJ;;AAGA;EACI,2BAAA;AAAJ","sourcesContent":["/**\n * Buttons\n * =======\n *\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n */\n\n:host button {\n    margin-right: 10px;\n    margin-top: 20px;\n}\n\n:host button:last-of-type {\n    margin-right: 0;\n}\n\n:host .cw-button-wrapper {\n    justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
