// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/**
 * Allgemein
 * =========
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
*/
.cw-documents-upload-toolbar {
  height: 35px; /* feste Höhe setzen, damit sich andere Inhalte nich aus dem Container herausschieben, falls keine Toolbar-Icons aktiv sind */
  margin: 0;
  margin-bottom: -35px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/documents/documents/documents.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;;;CAAA;AAOA;EACI,YAAA,EAAA,6HAAA;EACA,SAAA;EACA,oBAAA;AACJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n*/\n\n.cw-documents-upload-toolbar {\n    height: 35px; /* feste Höhe setzen, damit sich andere Inhalte nich aus dem Container herausschieben, falls keine Toolbar-Icons aktiv sind */\n    margin: 0;\n    margin-bottom: -35px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
