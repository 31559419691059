import {Component, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service des globalen Regionsfilters
import {GlobalRegionsfilterService} from '@global/components/global-regionsfilter/global-regionsfilter.service';
import {UserSettingsService} from '@global/services/user-settings.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';

// Environment
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

@Component({
    selector: 'phscw-institutions-edetailer',
    templateUrl: './institutions-edetailer.component.html',
    styleUrls: ['./institutions-edetailer.component.scss'],
})
export class InstitutionsEdetailerComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _componentDestroyed$ = new Subject<void>();

    // Grundlage des E-Detailer-Links
    edetailerBaseLink = '';
    // E-Detailer-Link
    edetailerLink = '';

    // Division-Defaultmäßig auf 1 setzen, wenn z.
    division: number = null;

    constructor(
        private globalRegionsfilterService: GlobalRegionsfilterService,
        private userSettingsService: UserSettingsService,
    ) {}

    ngOnInit() {
        if (hasOwn(environment, 'edetailerLink')) {
            this.edetailerBaseLink = environment['edetailerLink'];
        }
        // Gewählte Division nehmen und als Start-Parameter mitgeben.
        this.division = this.userSettingsService.getValue('salesRegionsfilterDivision');
        // Link zum E-Detailer mit Parameter für Division anreichern.
        this.edetailerLink = this.edetailerBaseLink + 'start/' + this.division;

        // Subscriptions initiieren
        this.initializeSubscriptions();
    }

    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Subscibtions erstellen
     */
    initializeSubscriptions() {
        // Wenn Regionsfilter geändert wird
        this.globalRegionsfilterService.eventRegionsfilterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls Regionsfilter nicht für Umsatzanalyse (oder Produktanalyse) gilt
                if (event.target !== 'institutions') {
                    return;
                }
                this.division = event.data['division'];
                this.edetailerLink = this.edetailerBaseLink + 'start/' + this.division;
            });
    }

    /**
     * Öffnet den E-Detailer-Link
     */
    openEdetailer() {
        window.open(this.edetailerLink);
    }
}
