import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-distribution-targets-product-sales',
    templateUrl: './chart-distribution-targets-product-sales.component.html',
    styleUrls: ['./chart-distribution-targets-product-sales.component.scss'],
})
export class ChartDistributionTargetsProductSalesComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
