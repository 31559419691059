import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessMonthPipe} from './business-month.pipe';
import {BusinessYearPipe} from './business-year.pipe';
import {CurrencyChfPipe} from './currency-chf.pipe';
import {IsTypePipe} from './is-type.pipe';
import {FindLabelPipe} from './find-label.pipe';

@NgModule({
    declarations: [BusinessMonthPipe, BusinessYearPipe, CurrencyChfPipe, IsTypePipe, FindLabelPipe],
    providers: [BusinessMonthPipe, BusinessYearPipe, CurrencyChfPipe, IsTypePipe, FindLabelPipe],
    imports: [CommonModule],
    exports: [BusinessMonthPipe, BusinessYearPipe, CurrencyChfPipe, IsTypePipe, FindLabelPipe],
})
export class PipesModule {}
