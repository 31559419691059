/**
 * @brief   Shared ToolbarModule zur Freigabe der ToolbarComponent.
 * @details Um die ToolbarComponent in Feature Modulen verwenden zu können,
 *          wird dieses Shared Module eingebunden.
 *          Die Einbindung erfolgt im Feature Module (z.B. people.module.ts)
 *          und nicht in den einzelnen Komponenten des Feature Moduls.
 *
 *          // Shared Modules einbinden
 *          import {ToolbarModule} from '../../shared/toolbar/toolbar.module';
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
// NGX-Translate für Übersetzungen
import {TranslateModule} from '@ngx-translate/core';
// Service dieses Shared-Modules
import {ToolbarService} from './toolbar.service';
// Komponenten
import {ToolbarAddChildComponent} from './toolbar-add-child/toolbar-add-child.component';
import {ToolbarAddLinkComponent} from './toolbar-add-link/toolbar-add-link.component';
import {ToolbarAddParentComponent} from './toolbar-add-parent/toolbar-add-parent.component';
import {ToolbarCharacteristicsComponent} from './toolbar-characteristics/toolbar-characteristics.component';
import {ToolbarDuplicateSearchComponent} from './toolbar-duplicate-search/toolbar-duplicate-search.component';
import {ToolbarEditEventsPeopleComponent} from './toolbar-edit-events-people/toolbar-edit-events-people.component';
import {ToolbarEventStatusComponent} from './toolbar-event-status/toolbar-event-status.component';
import {ToolbarFilterInvoicesComponent} from './toolbar-filter-invoices/toolbar-filter-invoices.component';
import {ToolbarFullscreenComponent} from './toolbar-fullscreen/toolbar-fullscreen.component';
import {ToolbarGoogleMapsComponent} from './toolbar-google-maps/toolbar-google-maps.component';
import {ToolbarHistoryComponent} from './toolbar-history/toolbar-history.component';
import {ToolbarNavigateInstitutionsComponent} from './toolbar-navigate-institutions/toolbar-navigate-institutions.component';
import {ToolbarParkopediaComponent} from './toolbar-parkopedia/toolbar-parkopedia.component';
import {ToolbarPasteComponent} from './toolbar-paste/toolbar-paste.component';
import {ToolbarRemoteEdetailerInviteComponent} from './toolbar-remote-edetailer-invite/toolbar-remote-edetailer-invite.component';
import {ToolbarRemoteEdetailerStartComponent} from './toolbar-remote-edetailer-start/toolbar-remote-edetailer-start.component';
import {ToolbarReopenComponent} from './toolbar-reopen/toolbar-reopen.component';
import {ToolbarSampleLossComponent} from './toolbar-sample-loss/toolbar-sample-loss.component';
import {ToolbarSampleReturnComponent} from './toolbar-sample-return/toolbar-sample-return.component';
import {ToolbarSendReportComponent} from './toolbar-send-report/toolbar-send-report.component';
import {ToolbarShowPeopleComponent} from './toolbar-show-people/toolbar-show-people.component';
import {ToolbarSwitchViewComponent} from './toolbar-switch-view/toolbar-switch-view.component';
import {ToolbarTransferComponent} from './toolbar-transfer/toolbar-transfer.component';
import {ToolbarUserCheckComponent} from './toolbar-user-check/toolbar-user-check.component';
import {ToolbarComponent} from './toolbar/toolbar.component';
// Shared Modules einbinden
import {OverlayModule} from '../../shared/overlay/overlay.module';
import {ToolbarExpandCharacteristicsComponent} from './toolbar-expand-characteristics/toolbar-expand-characteristics.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [CommonModule, MatTooltipModule, TranslateModule, OverlayModule, FontAwesomeModule],
    declarations: [
        ToolbarComponent,
        ToolbarFullscreenComponent,
        ToolbarGoogleMapsComponent,
        ToolbarSwitchViewComponent,
        ToolbarParkopediaComponent,
        ToolbarPasteComponent,
        ToolbarCharacteristicsComponent,
        ToolbarRemoteEdetailerInviteComponent,
        ToolbarRemoteEdetailerStartComponent,
        ToolbarPasteComponent,
        ToolbarHistoryComponent,
        ToolbarUserCheckComponent,
        ToolbarReopenComponent,
        ToolbarAddChildComponent,
        ToolbarAddLinkComponent,
        ToolbarAddParentComponent,
        ToolbarDuplicateSearchComponent,
        ToolbarNavigateInstitutionsComponent,
        ToolbarTransferComponent,
        ToolbarSampleLossComponent,
        ToolbarSampleReturnComponent,
        ToolbarExpandCharacteristicsComponent,
        ToolbarEditEventsPeopleComponent,
        ToolbarShowPeopleComponent,
        ToolbarEventStatusComponent,
        ToolbarFilterInvoicesComponent,
        ToolbarSendReportComponent,
    ],
    exports: [MatTooltipModule, ToolbarComponent],
    providers: [ToolbarService],
})
export class ToolbarModule {}
