// Angular-Module
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// GridComponent
import {GridComponent} from './../grid/grid.component';
import {GridColumnsSavePopupComponent} from './grid-columns-save-popup/grid-columns-save-popup.component';
import {UserPermissionsService} from '@global/services/user-permissions.service';
import {GridService} from './../grid.service';
import {GridColumnsService} from './grid-columns.service';
// Shared Components
import {PopupMessageComponent} from './../../popups/popup-message/popup-message.component';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../../cw-event';

@Component({
    selector: 'phscw-grid-columns-panel',
    templateUrl: './grid-columns-panel.component.html',
    styleUrls: ['./grid-columns-panel.component.scss'],
})
export class GridColumnsPanelComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    /**
     * *************************************************************************
     * Parameter, welche beim Einbinden der Komponente gesetzt werden
     *************************************************************************
     */
    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;
    // ID des (verbundenen) Grids
    @Input() gridId = '';
    // Referenz auf Form
    @ViewChild('gridColumnsPanelForm', {static: false}) gridColumnsPanelForm: NgForm;
    // Ist diese Grid-Extension sichtbar?
    @Input() visible = false;
    // auswählbare Spalten
    @Input() allColumns: any[] = [];
    allColumnsConstant: any[] = [];
    // gewählte Spalten
    chosenColumns: any[] = [];
    // Informationen für den SpeichernButton und den AusführenButton, ob gerade gespeichert wird
    buttonSavingInProgress = false;
    // Information für den SpeichernButton, ob das Speichern erfolgreich war
    buttonSavedSuccessful = false;
    // Ist das Kennzeichen-Panel ausgeklappt?
    characteristicsExpanded = false;
    // Ist das Einrichtungs-Kennzeichen-Panel ausgeklappt?
    institutionsCharacteristicsExpanded = false;
    // Darf das Layout gelöscht werden
    allowDeletion = false;
    // Soll das Kennzeichen-Panel angezeigt werden?

    maxColumns = null; // Set your desired maximum number of columns
    currentColumnNumber = 0;

    @Input() showCharacteristicsPanel = true;
    /*
     * Sollen Kennzeichen nach KennzeichenTyp in eigene Gruppen sortiert werden?
     * (Momentan nur für Personen-Spalten relevant)
     */
    @Input() showCharacteristicsByType = false;

    // Konstruktor
    constructor(
        private dialog: MatDialog,
        private translateService: TranslateService,
        private gridService: GridService,
        private gridColumnsService: GridColumnsService,
        private userPermissionsService: UserPermissionsService,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Prüfe Anforderungen
        this.checkRequirements();
        // Events subscriben
        this.initializeEventSubscriptions();
        this.maxColumns = this.userPermissionsService.getPermissionValue('maximumColumnNumber');
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    // Prüfe Anforderungen
    checkRequirements() {
        // Diese Grid-Erweiterung benötigt ein verbundenes Grid
        if (typeof this.gridConnection === 'undefined') {
            console.error(
                'Pharmakon - GridColumnsPanelComponent verfügt über kein verbundenes Grid und kann deshalb nicht funktionieren. Bitte beim Einbinden der Komponente die [gridConnection] setzen!',
            );
        }
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Spalten wurden geändert
        this._subscriptions.add(
            this.gridService.eventGridColumnsChanged.subscribe((result) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid kam
                if (event.target != this.gridId || event.target == 'eventsList') {
                    return;
                }

                this.onEventGridColumnsChanged(result);
            }),
        );

        // Auf das Event zum Start des Speichern des Layouts reagieren
        this._subscriptions.add(
            this.gridColumnsService.eventLayoutSaveStarted.subscribe((result) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid kam
                if (event.target != this.gridId) {
                    return;
                }
                this.buttonSavingInProgress = true;
            }),
        );

        // Auf das Event, dass Erfolg oder Fehlschlag des Speicherns mitgibt hören
        this._subscriptions.add(
            this.gridColumnsService.eventLayoutSaved.subscribe((result) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid (E-Liste) kam
                if (event.target != this.gridId) {
                    return;
                }
                this.buttonSavingInProgress = false;
                // Abfrage, ob das Speichern erfolgreich war
                if (event.data === true) {
                    this.buttonSavedSuccessful = true;
                    // Ausblenden nach kurzer Zeit
                    setTimeout(() => (this.buttonSavedSuccessful = false), 2000);
                } else {
                    // Bei Fehlschlag Fehlerdialog öffnen
                    this.openFailedSaveDialog();
                }
            }),
        );

        /*
         * Daten neu laden, da "fremde" Spalten gewählt wurden
         * @todo Durch changeLayoutAfterReload wird ein Teil der Layout-Vorbereitung doppelt gemacht, gesamten Ablauf anders koordinieren, Daten zwischenspeichern?
         */
        this._subscriptions.add(
            this.gridService.eventReloadData.subscribe((result) => {
                // Spalten-Infos ans Backend senden
                this.gridConnection.gridColumnChoice = result['data']['frontendArray'];
                // Layout wechseln, nachdem Daten neu geladen wurden
                this.gridService.changeLayoutAfterReload(this.gridId, result['data']['layoutId']);
            }),
        );
    }

    // Wird aufgerufen, wenn ein neues Layout ausgewählt wird.
    onEventGridColumnsChanged(result) {
        // Wenn die Funktion zum ersten Mal aufgerufen wird, speichere die verfügbaren Spalten in einer seperaten Variable
        if (this.allColumnsConstant.length === 0) {
            this.allColumnsConstant = JSON.parse(JSON.stringify(this.allColumns));
        }
        // Spaltenauswahl-Listen leeren und neu setzen
        this.chosenColumns = [];
        this.allColumns = [];

        this.allColumns = JSON.parse(JSON.stringify(this.allColumnsConstant));
        this.chosenColumns = result.data['frontendArray'];
        this.allowDeletion = result.data['allowDeletion'];
        // Gewählte Spalten aus dem "Verfügbare Spalten"-Array löschen
        for (let i = 0; i < this.chosenColumns.length; i++) {
            const columnGroupIndex = this.allColumns.findIndex(
                (column) => column['id'] === this.chosenColumns[i]['columnGroup'],
            );
            const columnIndex = this.allColumns[columnGroupIndex]['columns'].findIndex(
                (column) => column['id'] === this.chosenColumns[i]['id'],
            );
            this.allColumns[columnGroupIndex]['columns'].splice(columnIndex, 1);
        }
    }

    // Klick auf "Spalten anpassen"
    changeLayout(): void {
        // Expansion Panels einklappen wenn auf "Spalten anpassen" geklickt wurde
        this.characteristicsExpanded = false;
        this.buttonSavingInProgress = true;
        for (let i = 0; i < this.allColumns.length; i++) {
            this.allColumns[i]['expanded'] = false;
        }
        /*
         * Das Grid informieren, dass die Column-Daten für den nächsten Request mitgegeben werden müssen.
         * @TODO: Nochmal prüfen, wird zurzeit nicht benötigt
         */
        this.gridConnection.sendColumnsWithLoadData = true;

        // Speichere die gewählten Spalten unter "Mein Layout"
        const saved = this.gridColumnsService.saveLayout({
            title: 'Mein Layout',
            frontend_array: this.chosenColumns,
            grid_id: this.gridId,
            role_ids: [],
        });
        saved.subscribe((result: any) => {
            // Wechsle auf das gerade gespeicherte Layout
            this.gridColumnsService.reloadLayouts(this.gridId, result['data'][0]['id']);

            // Wenn die Selektion erfolgreich gespeichert wurde, speichere Sie in das Storage
            let successfulSave = false;
            if (result['success'] && typeof result['data'][0] !== 'undefined') {
                successfulSave = true;
            }
            this.gridColumnsService.notifyGridColumnsPanelOfSaveResult(this.gridId, successfulSave);
            this.buttonSavingInProgress = false;
        });

        // Das grid informieren, dass die Spalten-Daten nicht mitgegeben werden sollen.
        this.gridConnection.sendColumnsWithLoadData = false;
    }

    openSaveColumnsPopup() {
        const dialogConfig = new MatDialogConfig();

        // Daten des gewählten Kriteriums dem Dialog übergeben
        dialogConfig.data = {
            frontendArray: this.chosenColumns,
            gridId: this.gridId,
        };

        // Dialog öffnen
        this.dialog.open(GridColumnsSavePopupComponent, dialogConfig);
    }

    // Wird aufgerufen, wenn eine Spalte per Drag&Drop verschoben werden soll
    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container && event.container.id === 'chosenColumns') {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else if (event.container.id === 'chosenColumns' || event.container.id === event.item.data.target) {
            this.currentColumnNumber = event.container.data.length + 1;

            if (this.currentColumnNumber < this.maxColumns || this.maxColumns == null || this.maxColumns == 0) {
                transferArrayItem(
                    event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex,
                );
            } else {
                this.openColumnsWarningDialog();
            }
        }
    }

    // Verschiebt eine Spalte vom "Gewählte Spalten"-Array in die richtige Gruppe des "Verfügbare Spalten"-Arrays
    deleteFilter(deletedColumn) {
        // Index der Gruppe im "Verfügbare Spalten"-Array ermitteln, zu welcher die zu löschende Spalte gehört
        const columnGroupIndex = this.allColumns.findIndex((column) => column['id'] === deletedColumn['columnGroup']);
        // Index der zu löschenden Spalte im "Gewählte Spalten"-Array ermitteln
        const previousIndex = this.chosenColumns.indexOf(deletedColumn);
        // Verschiebe die Spalte mithilfe der zuvor ermittelten Infos in die richtige Gruppe im "Verfügbare Spalten"-Array
        transferArrayItem(this.chosenColumns, this.allColumns[columnGroupIndex]['columns'], previousIndex, 0);
    }

    // Verschiebt eine Spalte bei einem Doppelklick ins "Gewählte Spalten"-Array
    moveToChosenColumns(chosenColumn) {
        // Index der Gruppe im "Verfügbare Spalten"-Array ermitteln, zu welcher die zu verschiebende Spalte gehört
        const columnGroupIndex = this.allColumns.findIndex((column) => column['id'] === chosenColumn['columnGroup']);
        // Index der zu verschiebenden Spalte im "Verfügbare Spalten"-Array ermitteln
        const previousIndex = this.allColumns[columnGroupIndex]['columns'].indexOf(chosenColumn);
        // Verschiebe die Spalte mithilfe der zuvor ermittelten Infos ins "Gewählte Spalten"-Array

        this.currentColumnNumber = this.chosenColumns.length + 1;

        if (this.currentColumnNumber < this.maxColumns || this.maxColumns == null || this.maxColumns == 0) {
            transferArrayItem(
                this.allColumns[columnGroupIndex]['columns'],
                this.chosenColumns,
                previousIndex,
                this.chosenColumns.length,
            );
        } else {
            this.openColumnsWarningDialog();
        }
    }

    // Öffnen eines Message-Dialogs, falls das Speichern fehlgeschlagen ist
    openFailedSaveDialog() {
        // Dialog konfigurieren und öffnen
        this.dialog.open(PopupMessageComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('SHARED.GRID.COLUMNS.POPUP.SAVELAYOUT'),
                message: this.translateService.instant('SHARED.GRID.COLUMNS.POPUP.UNSUCCESFULSAVE'),
            },
        });
    }

    // Prüft, ob eine Spalte zu Kennzeichen gehört
    isCharacteristic(column) {
        return typeof column !== 'undefined';
    }

    // Lösche das aktuelle Layout
    clickDelete() {
        this.gridService.gridLayoutDeleted('GridColumns', this.gridId);
    }

    /**
     * @brief   Dialog zum Anzeigen der Meldung (max.Spalten erreicht)
     * @author  Julia Zitzmann <j.zitzmann@pharmakon.software>
     */
    openColumnsWarningDialog(): void {
        this.dialog.open(PopupMessageComponent, {
            disableClose: true,
            width: '400px',
            data: {
                title: this.translateService.instant('SHARED.GRID.COLUMNS.POPUP.TOMANYCOLUMNSWARINGTITLE'),
                message: this.translateService.instant('SHARED.GRID.COLUMNS.POPUP.TOMANYCOLUMNSWARINGTEXT'),
            },
        });
    }
}
