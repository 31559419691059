// Angular-Module
import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-accordion-demo',
    templateUrl: './chart-accordion-demo.component.html',
    styleUrls: ['./chart-accordion-demo.component.scss'],
})
export class ChartAccordionDemoComponent implements OnInit {
    // Erlaubt das gleichzeitige Aufklappen mehrerer Expansions
    @Input() multi = false;

    // Definition der Accordion-Inhalte
    panels: any[] = [
        {
            title: 'Offene Aufgaben',
            children: [
                {
                    date: 'HEUTE',
                    text: 'Musterempfang bestätigen',
                },
                {
                    date: new Date(Date.now() + 24 * 60 * 60 * 1000).toLocaleDateString(),
                    text: 'Rückruf Dr. Helmut Müller',
                },
                {
                    date: new Date(Date.now() + 24 * 60 * 60 * 1000).toLocaleDateString(),
                    text: '100 Broschüren vorbereiten',
                },
            ],
        },
        {
            title: 'Zu genehmigen',
            children: [
                {
                    date: '17.06.2024',
                    text: 'Deutscher Heilpraktikertag Karlsruhe',
                },
                {
                    date: '01.03.2024',
                    text: 'Fortbildung Winterbevorratung',
                },
            ],
        },
        {
            title: 'Neue Ärzte',
            children: [{
                date: '',
                text: 'Dr. Peter Föh, Breite Str. 155, 76135 Karlsruhe',
            }],
        },
        {
            title: 'Neue Apotheker',
            children: [
                {
                    date: '',
                    text: 'Bärenapotheke, Karlsruhe',
                },
                {
                    date: '',
                    text: 'Ludwigsapotheke, Stuttgart',
                },
            ],
        },
    ];

    // Konstruktor
    constructor() {}

    // Initialisierungen
    ngOnInit() {}
}
