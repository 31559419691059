// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
:host {
  display: block;
  line-height: 1.25;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/clearing/clearing-navigation-button/clearing-navigation-button.component.scss"],"names":[],"mappings":"AAAA;;;;;EAAA;AAOA;EACI,cAAA;EACA,iBAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n */\n\n:host {\n    display: block;\n    line-height: 1.25;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
