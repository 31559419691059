import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {SwUpdate} from '@angular/service-worker';
import {GlobalUpdateDialogComponent} from '@global/components/global-update-dialog/global-update-dialog.component';
import {Subscription} from 'rxjs';

/** Uses ngsw to handle application updates */
@Injectable({providedIn: 'root'})
export class UpdateService {
    private newVersionSubscription: Subscription;
    private dialogShown = false;
    private needToRefresh = false;

    constructor(private swUpdate: SwUpdate, private dialog: MatDialog) {
    }

    /**
     * Sets up Update service if ngsw is enabled and supported
     */
    public init(): void {
        if (!this.swUpdate.isEnabled) {
            if ('serviceWorker' in navigator) {
                console.log('leftover SW exists');
                navigator.serviceWorker.getRegistration().then((registration) => {
                    if (registration) {
                        console.log('leftover SW is being removed');
                        registration.unregister().then(() => {
                            console.log('leftover SW removed');
                        });
                    }
                });
            }
            console.log('Service Worker is not enabled');
            return;
        }

        /*
         * makes sure that force reload wont lead to ngsw loosing controll of the tab.
         * more details:
         * https://web.dev/articles/service-worker-lifecycle#shift-reload
         * https://stackoverflow.com/questions/51597231/register-service-worker-after-hard-refresh
         */
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration().then((reg) => {
                if (reg.active && !navigator.serviceWorker.controller) {
                    window.location.reload();
                }
            });
        }
        this.subscribeVersionUpdates();
        this.setUpAutoCheckForUpDates(1000 * 10);
    }

    /**
     * sets up the automatic checks for updates.
     * @param {number} milliseconds time between checks for update in milliseconds
     */
    private setUpAutoCheckForUpDates(milliseconds: number): void {
        console.log('Setting up auto-check for updates');
        this.checkForUpdate();
        setInterval(() => {
            this.checkForUpdate();
        }, milliseconds);
    }

    /**
     * Triggers ngsw to check for app updates,
     * if update is available snackbar willappear to promt user to update
     */
    private checkForUpdate(): void {
        if (!this.swUpdate.isEnabled) {
            console.log('Service Worker is not enabled');
            return;
        }
        this.swUpdate.checkForUpdate()
            .then((isUpdateAvailable) => {
                if (isUpdateAvailable || this.needToRefresh) {
                    this.needToRefresh = true;
                    this.showUpdateAvailableDialog();
                    this.dialogShown = true;
                }
            })
            .catch(() => {
                console.log('swupdate rejected');
            });
    }

    /**
     * applys update if it exists prompting the page to soft reload
     */
    public applyUpdate(): void {
        // Reload the page to update to the latest version after the new version is activated
        document.location.reload();
        this.needToRefresh = false;
    }

    /**
     * postpones update
     */
    public postponeUpdate(): void {
        this.needToRefresh = true;
    }

    /**
     * Logs ngsw Version events
     */
    private subscribeVersionUpdates(): void {
        this.newVersionSubscription?.unsubscribe(); // Unsubscribe if already subscribed to avoid memory leaks
        this.newVersionSubscription = this.swUpdate.versionUpdates.subscribe({
            next: (evt) => {
                let msg = `Version update event: ${evt.type} \n`;
                switch (evt.type) {
                    case 'VERSION_DETECTED':
                        msg += `Downloading new app version: ${evt.version.hash}`;
                        break;
                    case 'VERSION_READY':
                        msg += `Current app version: ${evt.currentVersion.hash}\nNew app version ready for use: ${evt.latestVersion.hash}`;
                        break;
                    case 'VERSION_INSTALLATION_FAILED':
                        msg += `Failed to install app version '${evt.version.hash}': ${evt.error}`;
                        break;
                    case 'NO_NEW_VERSION_DETECTED':
                        // skips logging
                        msg = '';
                        break;
                    default:
                        msg += `unhandled service worker event ${evt}`;
                        break;
                }
                if (msg.length > 0) {
                    console.log(msg);
                }
            },
            error: (err) => {
                console.log(err);
            },
        });
    }

    private showUpdateAvailableDialog() {
        if (this.dialogShown) return;
        this.dialog.open(GlobalUpdateDialogComponent, {
            data: {
                title: 'Eine neue Version von C-World ist verfügbar.',
                message: 'Es wird empfohlen diese Version baldmöglichst zu installieren.',
                confirm: 'Jetzt Aktualisieren',
                cancel: 'Später erinnern',
            },
            position: {bottom: '20px'},
            panelClass: 'update-panel',
        }).afterClosed().subscribe(() => {
            this.dialogShown = false;
        });
    }
}
