import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';

@Component({
    selector: 'phscw-input-single-checkbox',
    templateUrl: './input-single-checkbox.component.html',
    styleUrls: ['./input-single-checkbox.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputSingleCheckboxComponent),
        multi: true,
    }],
})
export class InputSingleCheckboxComponent implements OnInit, OnDestroy {
    // EditMode aktiv?
    @Input() editMode = false;
    // Attribut: disabled = Gesperrt (ja / nein)
    @Input() disabled = false;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Label des Input-Feldes
    @Input() label: string;
    // Label der Checkbox-Option (optional)
    @Input() optionLabel: string;
    // Soll die Checkbox außerhalb des Edit-Moduses angezeigt werden.
    @Input() showCheckbox = false;
    // Soll das OptionLabel angezeigt werden.
    @Input() showOptionLabel = true;
    // Soll ein Löschbutton angezeigt werden.
    @Input() showDeleteButton = false;
    // Ist die Checkbox ein Pflichtfeld
    @Input() required = false;

    /*
     * eigenes "Icon" für abgehakten Status aktivieren
     * ermöglicht Anzeige von einzelnen Symbolen, z.B. Zahlen, Buchstaben
     */
    @Input() enableCustomCheckmarkCharacter = false;
    private _customCheckmark = '';
    @Input() set customCheckmark(value: any) {
        this._customCheckmark = value;

        // CSS ändern
        if (value) {
            this.customCssStyle = this.domSanitizer.bypassSecurityTrustStyle('--custom-content: "' + value + '"');
        }
    }

    get customCheckmark() {
        return this._customCheckmark;
    }

    // Attribut-String zum setzen der CSS Variable
    customCssStyle: SafeStyle = null;

    /*
     * @todo mit Upgrade auf Angular9 ändern auf Hostbinding um attr.style
     * Manipulation in Template zu vermeiden und sanitizer rauswerfen zu können
     * siehe https://github.com/angular/angular/issues/9343
     * @HostBind('style.--custom-property')
     */

    // Hat sich der Wert der Checkbox geändert
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onChange = new EventEmitter<boolean>();
    // Event-Emitter, falls der Wert gelöscht wurde
    @Output() deleteClicked = new EventEmitter<any>();

    // Checkbox-Wert
    _checkboxValue: any;

    // Getter _checkboxValue
    get checkboxValue() {
        return this._checkboxValue;
    }

    // Setter _checkboxValue
    set checkboxValue(value) {
        if (typeof value === 'boolean') {
            this._checkboxValue = value;
        } else {
            this._checkboxValue = false;
        }
        this.propagateChange(this._checkboxValue);
    }

    /**
     * Konstruktor (inkl. dependency injection)
     * @param domSanitizer
     */
    constructor(public domSanitizer: DomSanitizer) {}

    /**
     * Initialisieren
     */
    ngOnInit() {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * Funktion, die aufgerufen wird, wenn eine Änderung auftritt
     * @param _
     */
    propagateChange = (_: any) => {};

    /**
     * Interface ControlValueAccessor: writeValue
     * @param value
     */
    writeValue(value: any) {
        if (value !== undefined) {
            this.checkboxValue = value;
        }
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}

    /**
     * Lösche den Checkbox-Wert
     */
    deleteValue(): void {
        this.checkboxValue = null;
        this.deleteClicked.emit();
    }

    /**
     * Informiere die übergeordnete Komponente, dass sich der Checkbox-Wert geändert hat.
     */
    checkBoxValueChanged(): void {
        this.onChange.emit(this.checkboxValue);
    }
}
