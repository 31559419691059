// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/**
 * Allgemein
 * =========
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
/* Für alle "Icon-Spalten" */
.mat-mdc-cell.cw-grid-column-icon {
  min-width: 40px;
  overflow: visible;
  text-align: center;
  width: 40px;
}

/* Icons im Grid (z.B. Einrichtungstyp) */
.cw-grid-icon {
  font-size: 18px;
  height: 18px;
  vertical-align: middle;
}

.cw-grid-icon.colored {
  color: var(--color-accent);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid/grid-icon-favorite/grid-icon-favorite.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;;;EAAA;AAOA,4BAAA;AACA;EACI,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AACJ;;AAEA,yCAAA;AACA;EACI,eAAA;EACA,YAAA;EACA,sBAAA;AACJ;;AAEA;EACI,0BAAA;AACJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n */\n\n/* Für alle \"Icon-Spalten\" */\n.mat-mdc-cell.cw-grid-column-icon {\n    min-width: 40px;\n    overflow: visible;\n    text-align: center;\n    width: 40px;\n}\n\n/* Icons im Grid (z.B. Einrichtungstyp) */\n.cw-grid-icon {\n    font-size: 18px;\n    height: 18px;\n    vertical-align: middle;\n}\n\n.cw-grid-icon.colored {\n    color: var(--color-accent);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
