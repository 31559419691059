/**
 * @brief   Modifizierter ngx-translate HTTP Loader für C-World 4
 * @details Lädt aus der environment das Kürzel des Kunden,
 *          um kundenabhängig unterschiedliche Übersetzungsdateien zu verwenden
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */

import {HttpClient} from '@angular/common/http';
import {TranslateLoader} from '@ngx-translate/core';
import merge from 'deepmerge-json';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

export interface ITranslationResource {
    prefix: string;
    suffix: string;
}
export class CustomTranslateHttpLoader implements TranslateLoader {
    constructor(
        private http: HttpClient,
        private resources: ITranslationResource[],
    ) {}

    public getTranslation(lang: string): Observable<any> {
        const requests = this.resources.map((resource) => {
            const path = resource.prefix + lang + resource.suffix;
            return this.http.get(path).pipe(
                catchError((res) => {
                    console.error('Something went wrong for the following translation file:', path);
                    console.error(res.message);
                    return of({});
                }),
            );
        });

        return forkJoin(requests).pipe(map((response) => this.mergeAll(response)));
    }

    private mergeAll(response: any) {
        let result = {};

        response.forEach((element) => {
            // https://www.npmjs.com/package/deepmerge-json
            result = merge(result, element);
        });

        return result;
    }
}
