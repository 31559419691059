// Angular-Core
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Import Services
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class InputAutocompleteService {
    constructor(private backendService: BackendService) {}

    searchData(searchInput: string, additionalRestraints: any, backendUrl: string): Observable<any> {
        const searchObject: object = {
            searchInput,
            additionalRestraints,
        };
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(backendUrl, searchObject);

        // Observable (an Komponente) zurücklieferen, falls diese auch darauf subscriben muss
        return postRequest$;
    }
}
