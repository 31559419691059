// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DocumentData} from '@shared/document-data';

@Component({
    selector: 'phscw-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnDestroy {
    // Name des anzusprechenden Controllers
    @Input() backendController: string;
    @Input() type: string;
    // ID des Grids
    @Input() displayGridId = 'documentsList';
    // ID des aktuell ausgewählten Entrags
    @Input() entityId: number;

    // Angezeigte Spalten in Auswahl und Grid
    @Input() selectionDisplayedColumns: string[] = [
        'document-icon',
        'document-name',
        'document-description',
        'valid_to',
        'document-category',
        'document-status',
        'delete',
    ];

    @Input() gridDisplayedColumns = [
        'document-icon',
        'document-date',
        'document-institution',
        'document-person',
        'document-name',
        'valid_to',
        'document-description',
        'document-category',
        'document-status',
        'edit-delete',
    ];

    // Upload erlaubt
    @Input() allowFileUpload = true;
    // Mehrere Dateien zur Auswahl zulassen
    @Input() allowMultiple = true;
    // Edit Mode
    @Input() editMode = false;
    @Input() ignoreFilter = false;
    @Input() dataItem: DocumentData | null = null;

    /**
     * Konstruktor (inkl. dependency injection)
     */
    constructor() {}

    /**
     * Initialisieren
     */
    ngOnInit() {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * EditMode wechseln
     * @param $event
     */
    onChangeEditMode($event: boolean): void {
        this.editMode = $event;
    }
}
