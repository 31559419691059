// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host h2 {
  margin-top: 0;
}

.cw-horizontalline {
  border-color: var(--color-accent);
  height: 2px;
}

.cw-dialog-content {
  line-height: 20px;
  max-height: 200px;
  overflow: auto;
}

:host .cw-input-container {
  height: 30px;
}

:host #textarea {
  height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/popups/popup-confirmation/popup-confirmation.component.scss"],"names":[],"mappings":"AAGA;EACI,aAAA;AAFJ;;AAKA;EACI,iCAAA;EACA,WAAA;AAFJ;;AAKA;EACI,iBAAA;EACA,iBAAA;EACA,cAAA;AAFJ;;AAKA;EACI,YAAA;AAFJ;;AAKA;EACI,YAAA;AAFJ","sourcesContent":[":host {\n}\n\n:host h2 {\n    margin-top: 0;\n}\n\n.cw-horizontalline {\n    border-color: var(--color-accent);\n    height: 2px;\n}\n\n.cw-dialog-content {\n    line-height: 20px;\n    max-height: 200px;\n    overflow: auto;\n}\n\n:host .cw-input-container {\n    height: 30px;\n}\n\n:host #textarea {\n    height: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
