import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-yearly-count-visits',
    templateUrl: './chart-yearly-count-visits.component.html',
    styleUrls: ['./chart-yearly-count-visits.component.scss'],
})
export class ChartYearlyCountVisitsComponent implements OnInit {
    backendOptions = {};
    @Input() allowDecimals = false;
    xAxisTitle = new Date().getFullYear();

    constructor() {}

    ngOnInit() {}
}
