// Angular-Module
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';
// Interfaces für Structured Objects einbinden
import {Icon} from '@shared/icon';
import {Listentry} from '@shared/listentry';

@Component({
    selector: 'phscw-entity-icons',
    templateUrl: './entity-icons.component.html',
    styleUrls: ['./entity-icons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityIconsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Verfügbare Icons
    private availableIcons: Listentry[] = [];
    private availableIconsInitialized = false;

    // ID der Entität
    @Input() entityId: number;
    // Icon-String
    private _iconString = '';
    @Input()
    set iconString(value: string) {
        this._iconString = value;

        // Icons setzen
        if (this.availableIconsInitialized) {
            this.setIcons();
        }

        // Änderungen manuell auslösen
        this.changeDetector.detectChanges();
    }

    get iconString() {
        return this._iconString;
    }

    // maximal anzuzeigende Icons
    @Input() maxDisplayLength = 3;
    // Icons
    icons: Icon[] = [];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param sanitizer
     * @param initService
     * @param storageService
     * @param changeDetector
     */
    constructor(
        private sanitizer: DomSanitizer,
        private initService: InitService,
        private storageService: StorageService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Mögliche Icons initialisieren
        this.initializeAvailableIcons();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions() {
        // Darauf warten, dass alle listentries in der indexedDB gespeichert sind
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            // Abbruch, falls Anfrage erfolglos war
            if (result === false) {
                return;
            }
            this.initializeAvailableIcons();
        });
    }

    /**
     * @brief   Mögliche Icons initialisieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeAvailableIcons(): void {
        // Daten über Service anfordern
        const promise = this.storageService.getItem('listentries|globalIcons');
        promise.then((value: Listentry[]) => {
            // Falls Eintrag aus 'listentries' vorhanden ist
            if (value) {
                // Listentries zwischenspeichern
                this.availableIcons = value;

                // Flag setzen
                this.availableIconsInitialized = true;

                // ausgewählte Icons setzen
                this.setIcons();

                // Änderungen manuell auslösen
                this.changeDetector.detectChanges();
            }
        });
    }

    /**
     * @brief   Übergebene Icons filtern
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    setIcons(): void {
        // Daten zurücksetzen
        this.icons = [];

        // prüfen ob Daten vorhanden sind
        if (this.iconString !== null && typeof this.iconString !== 'undefined') {
            // Übergebene Icons in Array umwandeln
            const passedIcons = this.iconString.split('|');

            // Für jedes übergebene Icon die Daten laden
            passedIcons.forEach((iconKey: string) => {
                // Prüfe Länge des Strings
                if (iconKey.length > 0) {
                    // Icon in geladenen Listentries suchen
                    const iconDefinition = this.availableIcons.find((definition: Listentry) => {
                        if (definition.list_key === iconKey || definition.list_value === iconKey) {
                            return true;
                        }
                        return false;
                    });
                    // Icon anhängen
                    if (iconDefinition) {
                        this.icons.push(JSON.parse(iconDefinition.list_data));
                    }
                }
            });
        }

        // gefilterte Icons durchlaufen
        this.icons.forEach((icon: Icon) => {
            if (icon.icon_type === 'image') {
                // Sanitizer
                icon.content = this.sanitizer.bypassSecurityTrustHtml(icon.content as string) as SafeHtml;
            }
        });
    }

    /**
     * @brief   maximal anzuzeigene Icons laden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getDisplayedIcons(): Icon[] {
        return this.icons.slice(0, this.maxDisplayLength);
    }
}
