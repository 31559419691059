// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../cw-event';

@Injectable({providedIn: 'root'})
export class CalendarViewService {
    // Subject (Observable) definieren "Es wurde ein Item im Kalender ausgewählt"
    public eventCalendarSelectionChanged = new Subject<CWEvent>();

    // Konstruktor
    constructor(private backendService: BackendService) {}

    // Daten laden (@todo: gridOptions)
    loadData(calendarDataSource: string, gridOptions: any) {
        // Request-URL zusammen bauen
        const requestURL: string = calendarDataSource;
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(requestURL, gridOptions);
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    // Wird von CalendarViewComponent aufgerufen, sobald ein Item im Kalender ausgewählt wurde
    selectionChanged(calendarId: string, calendarItem: any) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: calendarId,
            target: '',
            data: {selectedItem: calendarItem},
        };

        // Event auslösen
        this.eventCalendarSelectionChanged.next(eventData);
    }
}
