// Angular-Module
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service dieses Shared-Moduls
import {ContactsService} from './../../contacts.service';
// Globalen Service einbinden
import {StorageService} from '@global/services/storage.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
// Services anderer Shared-Modules
import {ReportsContactsOverviewService} from '@modules/reports/reports-contacts-overview/reports-contacts-overview.service';
import {CharacteristicsService} from '@shared/characteristics/characteristics.service';
import {GridService} from '@shared/grid/grid.service';
import {InputDateService} from '@shared/input/input-date/input-date.service';
import {OverlayService} from '@shared/overlay/overlay.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Shared Components
import {OverlayInfoInstitutionComponent} from '@shared/overlay/overlay-info/overlay-info-institution/overlay-info-institution.component';
import {OverlayInfoPersonComponent} from '@shared/overlay/overlay-info/overlay-info-person/overlay-info-person.component';
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
import {PopupSignatureComponent} from '@shared/popups/popup-signature/popup-signature.component';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {Listentry} from '@shared/listentry';
import {LooseObject} from '@shared/loose-object';
// Environment einbinden
import {environment} from '@environment';

// Moment-Modul zur Datums-Verarbeitung
import {SelectData} from '@shared/select-data';
import {hasOwn, notEmpty, notEmptyObject} from '@shared/utils';
import * as _moment from 'moment';
import {Contacts} from '@shared/contacts';
import {GridNewService} from '@shared/grid-new/shared/grid-new.service';

const moment = _moment;

// Kennzeichen
const CHARACTERISTIC_TYPE = 'contact';

@Component({
    selector: 'phscw-contacts-form-contact',
    templateUrl: './contacts-form-contact.component.html',
    styleUrls: ['./contacts-form-contact.component.scss'],
})
export class ContactsFormContactComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    #componentDestroyed$ = new Subject<void>();

    // Referenz auf Form
    @ViewChild('contactsForm', {static: false}) contactsForm: NgForm;

    // Name des Backend-Controllers, welcher angesteuert werden soll
    @Input() controllerName = '';

    // Flag, das gesetzt wird, wenn die Komponente fertig initialisiert ist und Daten geladen werden können
    private isInitialized = false;

    // ID des aktuellen Kontakts - Über GETTER / SETTER, da bei Änderung ein Neuladen erfolgen soll
    #contactId = 0;
    get contactId() {
        return this.#contactId;
    }

    @Input() set contactId(value) {
        // Wert übernehmen
        this.#contactId = value;

        // hier den Reload nur triggern, wenn die Komponente bereits vollständig initialisiert ist
        if (this.isInitialized && this.contactId > 0) {
            // Details laden (falls es sich NICHT um eine Neuanlage handelt - z.B. in Kontaktübersicht)
            this.loadDetails(this.contactId);
        }
    }

    // Kennzeichen-Typ
    characteristicType: string = CHARACTERISTIC_TYPE;
    characteristicsEnabled = false;
    // Flag definiert, ob Kennzeichne validiert wurden
    characteristicsFormValid = true;

    // Für Neuanlage eines Kontakts muss der gewünschte Typ (contact_type) an die shared component übergeben werden
    @Input() newContactType = '';

    // Nach dem Laden kann der Typ eines bestehenden Kontakts automatisch umgewandelt werden
    @Input() autoChangeContactType = '';

    // FK für Neuanalge
    @Input() institutionId: number;
    @Input() personId: number;
    @Input() opportunityId: number = null;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multiContactId: any = null;

    // Daten des aktuellen Kontakts
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    originalData: any = {};
    // Muster-Daten
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    samplesData: any = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    enrichedSamplesData: any = [];
    // Weitere Teilnehmer
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() participants: any[] = [];

    // Added multi
    @Input() multi = false;

    // Definiert, ob bei Kontakten "Dauer" oder "Von / Bis" angezeigt wird
    contactsUseDuration = true;
    // Definiert, ob bei Kontakten auch "Items" (d.h. Gesprächsthemen, Muster, Abgabeartikel) verwendet werden
    contactsUseItems = false;
    // Definiert, ob die Zeit und die Dauer angezeigt oder ausgeblendet werden soll
    contactsShowTime: LooseObject = {
        contact: true,
        appointment: true,
        task: false,
        remote: true,
        ticket: true,
    };

    // Definiert, ob der Zeitstempel angezeigt werden soll
    contactsShowTimeStamp = false;
    // <true> Eine neu angelegte Person darf keine Muster erhalten, bis die Person gecleared / freigegeben wurde. <false> Keine Einschränkung für Musterabgabe.
    contactsNoSamplesForNewPeople = false;
    // Flag definiert, ob fremde Kontakte bearbeitet werden dürfen
    allowEditForeignContacts: boolean = null;
    // Wie viele Tage darf der Kontakt in die Vergangenheit noch bearbeitet werden. Wird über die Konfig gesetzt (0 heißt unendlich viele Tage)
    allowEditContact = 0;
    // Berechtigung zum Ändern des Mitarbeiters eines Kontakts
    allowAssignForeignContacts = false;
    // Kleinstes Datum an dem Ein Kontakt angelegt werden darf
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    minimumStartDate: any = moment('19000101');

    // Definiert, ob E-Visit-Funktionalität ausgeblendet werden soll
    eVisitEnabled = false;
    // Custom Kontaktmethode
    remoteMethod = [
        {
            id: 1000,
            label: 'E-Visit',
        },
    ];

    // Links für E-Visit
    serverLink = '';
    clientLink = '';
    // Teilnehmer haben E-Mail in Daten
    participantsHaveEmailSet = false;
    // Alle Teilnehmer haben Emails
    @Input() popUpParticipantsHaveEmailSet = false;

    // Definiert, ob Kontakttyp "Tickets" verfügbar ist (wäre besser über listentries, contact_types...)
    enableTicketContactType = false;

    // Definiert, ob bei Kontakttyp "Termin" der Titel angezeigt werden soll
    contactsShowAppointmentTitle = true;

    // Definiert, ob bei Kontakttyp "Termin" die Priorität angezeigt werden soll
    contactsShowAppointmentPriority = true;

    // Definiert, ob bei Kontakttyp "Kontaktbericht" die Priorität angezeigt werden soll
    contactsShowContactPriority = true;

    // Definiert, ob Muster abgegeben werden dürfen
    allowContactsSamples = false;

    // Definiert, für welche Kontakt-Typen die Muster ausgeblendet sind
    contactsHideSamplesForContactTypes = [];

    // Definiert, ob alle Kontakte aus einem Mehrfachkontakt bearbeitet werden oder nur der ausgewählte Kontakt
    allowEditMultiContact = false;

    // Titel required
    titleRequired = true;
    // Titel Termin
    appointmentTitleRequired = true;
    // Titel Aufgabe
    taskTitleRequired = true;

    // Notiz required
    noteRequired = false;
    // Notiz Kontakt
    contactNoteRequired = false;
    // Notiz Termin
    appointmentNoteRequired = false;
    // Notiz Aufgabe
    taskNoteRequired = false;

    // Mitarbeiter Feld anzeigen
    employeeVisible = true;
    contactEmployeeVisible = true;
    appointmentEmployeeVisible = true;
    taskEmployeeVisible = true;

    // EditMode aktiv?
    #editMode = false;
    @Input() set editMode(value: boolean) {
        this.#editMode = value;
        // Gib die Infos über den geänderten EditMode an die parent-Komponente weiter
        this.changeEditMode.emit(value);
    }

    get editMode() {
        return this.#editMode;
    }

    // Definiert, ob der Kontakt schreibgeschützt ist
    @Input() readonly = true;
    // Definiert, ob der Readonly, von der Contacts-Form-Komponente verändert werden darf.
    @Input() readonlyImmutable = false;
    // Definiert, ob gar keine Buttons angezeigt werden sollen
    @Input() hideButtons = false;
    // Definiert, ob Muster abgegeben werden dürfen?
    @Input() sampleAuthorization = false;
    // Sichtbare Produkttypen
    @Input() allowedProductTypes: string[] = [];

    // Soll die Person per Select ausgewählt werden können?
    @Input() enablePersonSelection = false;
    displayPersonSelectBox = false;
    institutionPeopleData: SelectData[] = [];
    // Soll die Personenzuordnung zurückgesetzt werden?
    @Input() resetPersonSelection = false;
    // Darf die Einrichtung auch nachträglich noch geändert werden
    @Input() allowChangeInstitutionSelection = false;

    // Flag definiert ob gerade geladen wird
    loading = false;
    // Flag definiert ob gerade gespeichert wird
    saving = false;
    // Komponente intialisiert?
    initialized = false;

    // Label für "Datum" unterscheidet sich je nach Kontakt-Typ
    labelContactStart = 'Datum';
    labelContactEnd = 'Datum';
    // Label für "Notiz" unterscheidet sich je nach Kontakt-Typ
    labelContactNote = 'Notiz';

    // Kontakt in der Zukunft erlauben?
    disableFutureDateForContacts = false;
    currentDate: Date = new Date();

    // Differenz der Tage zwischen dem Start- und Enddatum / Start- und Endzeit
    contactDatesDifference = 0;
    contactTimeDifference = 0;

    // Soll die Task-Typ-Liste angezeigt werden
    enableTaskType = false;

    // Event-Emitter der die übergeordnete Komponente benachrichtigt
    @Output() changeEditMode = new EventEmitter<boolean>();
    // Event-Emitter der die übergeordnete Komponente benachrichtigt
    @Output() contactTypeChanged = new EventEmitter<string>();

    // Ob Kontaktformaler ein Sammelkontakt
    @Input() collectiveEntry = false;

    // Kontakt für eine Einrichtung?
    @Input() isInstitutionContact = false;

    // enthält den Wert den Sammel kontakt per Barcode Scanner eingelesen wird
    collectiveEntryKey;

    // Info Text ob der Kontakt erfolgreich gespeichert wurde
    collectiveEntryKeyInfo = '';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    allowChangeTo: any = {
        contact: true,
        task: true,
        appointment: true,
        ticket: true,
    };

    reactions = {};

    // Titel beim Typ Kontakt
    showContactTitle = false;
    contactTitleRequired = false;

    /*
     * Name der einbindenden Komponente
     * Um leichtere Kommunikation mit der parent-Komponente zu ermöglichen
     *
     * z.B. wenn sich Daten ändern, kann in der Parent-Komponente das Grid aktualisiert werden
     */
    @Input() parentGridName: string | null = null;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param {MatDialog} dialog - Material Dialog
     * @param {TranslateService} translateService - Übersetzungsservice
     * @param {StorageService} storageService - Service für den Zugriff auf den LocalStorage
     * @param {UserPermissionsService} userPermissionsService - Service für die Benutzerberechtigungen
     * @param {ToolbarService} toolbarService - Service für die Toolbar
     * @param {GridService} gridService - Service für die Grids
     * @param {InputDateService} inputDateService - Service für die Datumsauswahl
     * @param {OverlayService} overlayService - Service für die Overlays
     * @param {CharacteristicsService} characteristicsService - Service für die Kennzeichen
     * @param {ContactsService} contactsService - Service für die Kontakte
     * @param {ReportsContactsOverviewService} reportsContactsOverviewService - Service für die Kontaktübersicht
     * @param {GridNewService} gridNewService - GridNewService
     */
    constructor(
        private dialog: MatDialog,
        private translateService: TranslateService,
        private storageService: StorageService,
        private userPermissionsService: UserPermissionsService,
        private toolbarService: ToolbarService,
        private gridService: GridService,
        private inputDateService: InputDateService,
        private overlayService: OverlayService,
        private characteristicsService: CharacteristicsService,
        private contactsService: ContactsService,
        private reportsContactsOverviewService: ReportsContactsOverviewService,
        private gridNewService: GridNewService<Contacts>,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Features prüfen
        this.checkFeaturesConfiguration();
        // Events subscriben
        this.initializeEventSubscriptions();

        // Übersetzung
        this.initializeDateTranslations();

        // Berechtigung prüfen
        this.checkAllowContactsSamples();
        this.checkAllowAssignForeignContacts();
        this.checkAllowEditMultiContact();

        /*
         * Asynchrones Laden aus IndexedDB oder Backend koordinieren, auf alle Promises warten
         */
        Promise.all([
            // Muster laden - diese Daten müssen vorhanden sein, bevor loadDetails ausgeführt wird
            this.loadSamplesData(),
            /*
             * Check, ob die Listentries für den Kontakttyp existieren, und ob
             * der Nutzer das Recht hat den Kontakttyp zu bearbeiten.
             * Wenn nicht, darf der User den Kontakt nicht den entspr. Kontakttyp
             * überführen
             */
            this.checkContactTypeListentriesForTypeChange(),
        ]).then(() => {
            /*
             * Da Angular die Setter ausführt, bevor ngOnInit durchgelaufen ist, dient dieses Flag als Signal, dass erst
             * ab jetzt Daten geladen werden können (z.B. in set contactId → loadDetails)
             */
            this.isInitialized = true;
            /*
             * Zu diesem Zeitpunkt sind alle @Input gesetzt und die Konfiguration ausgelesen, hier kann nun
             * die Anfrage für eine Neuanlage (contactId = 0) bzw. das Laden eines vorhandenen Kontakts erfolgen.
             */
            this.loadDetails(this.contactId);
        });
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this.#componentDestroyed$.next();
        this.#componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Wenn der Cancel-Button der Toolbar ausgelöst wurde. Abbrechen auslösen.
        this.toolbarService.eventCloseComponent
            .pipe(takeUntil(this.#componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht für Komponente gesendet wurde
                if (event.target !== 'contacts-form') {
                    return;
                }
                this.clickCancel();
            });

        // Reagiert auf geänderte Auswahl im Modul Kontaktübersicht
        this.reportsContactsOverviewService.selectionChanged
            .pipe(takeUntil(this.#componentDestroyed$))
            .subscribe(() => {
                // TODO muss samples data hier immer neu geladen werden - samplesData sind statisch?
                // eslint-disable-next-line no-console
                this.loadSamplesData().then((result) =>
                    console.log('Loaded samples data on contact-selection', result));
            });
    }

    // Muster-Artikel aus Storage laden, gibt Promise zurück, damit Aufrufer bei Bedarf auf Erfüllung warten kann
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadSamplesData(): Promise<any> {
        if (this.allowContactsSamples && this.contactsUseItems === true) {
            const promise = this.storageService.getItem('sampleProducts');
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            promise.then((result: any) => {
                this.samplesData = result;
            });
            return promise;
        }
        // leeres Promise, das sofort auflöst
        return Promise.resolve();
    }

    /**
     * Prüfen welche Features aktiviert sind
     */
    checkFeaturesConfiguration(): void {
        // Environment-Definition, ob die Zeitstempel sichtbar sind
        if (typeof environment.contactsShowTimeStamp !== 'undefined') {
            this.contactsShowTimeStamp = environment.contactsShowTimeStamp;
        }
        // Environment-Definition, für welche Kontakt-Typen die Musterabgabe deaktiviert ist
        if (typeof environment.contactsHideSamplesForContactTypes !== 'undefined') {
            this.contactsHideSamplesForContactTypes = environment.contactsHideSamplesForContactTypes;
        }
        // Environment-Definition, ob bei Terminen der Titel angezeigt werden soll
        if (typeof environment.contactsShowAppointmentTitle !== 'undefined') {
            this.contactsShowAppointmentTitle = environment.contactsShowAppointmentTitle;
        }
        // Environment-Definition, ob bei Terminen die Priorität angezeigt werden soll
        if (typeof environment.contactsShowAppointmentPriority !== 'undefined') {
            this.contactsShowAppointmentPriority = environment.contactsShowAppointmentPriority;
        }
        // Environment-Definition, ob bei Kontaktberichten die Priorität angezeigt werden soll
        if (typeof environment.contactsShowContactPriority !== 'undefined') {
            this.contactsShowContactPriority = environment.contactsShowContactPriority;
        }
        // Environment-Definition, ob bei Kontakten "Dauer" oder "Von / Bis" angezeigt wird
        if (typeof environment.contactsUseDuration !== 'undefined') {
            this.contactsUseDuration = environment.contactsUseDuration;
        }
        // Environment-Definition, ob bei Kontaktberichten auch "contact-items" (Gesprächsthemen, Muster, Abgabeartikel) aktiv sein sollen
        if (typeof environment.contactsUseItems !== 'undefined') {
            this.contactsUseItems = environment.contactsUseItems;
        }
        // Environment-Definition, ob die Zeitfenster beim Kontakt-Modul angezeigt werden sollen
        if (typeof environment.contactsShowTimeContact !== 'undefined') {
            this.contactsShowTime.contact = environment.contactsShowTimeContact;
        }
        if (typeof environment.contactsShowTimeAppointment !== 'undefined') {
            this.contactsShowTime.appointment = environment.contactsShowTimeAppointment;
        }
        if (typeof environment.contactsShowTimeTask !== 'undefined') {
            this.contactsShowTime.task = environment.contactsShowTimeTask;
        }
        if (typeof environment.contactsShowTimeRemote !== 'undefined') {
            this.contactsShowTime.remote = environment.contactsShowTimeRemote;
        }
        if (typeof environment.contactsShowTimeTicket !== 'undefined') {
            this.contactsShowTime.ticket = environment.contactsShowTimeTicket;
        }
        // Environment-Definition, ob für neu angelegete / nicht geclearte Personen keine Muster abgegeben werden dürfen
        if (typeof environment.contactsNoSamplesForNewPeople !== 'undefined') {
            this.contactsNoSamplesForNewPeople = environment.contactsNoSamplesForNewPeople;
        }
        // Environment-Definition, ob E-Visit aktiviert ist
        if (typeof environment.eVisit !== 'undefined' && environment.eVisit) {
            this.eVisitEnabled = true;
        }
        // Environment-Definition, ob Kontakttyp "Ticket" verfügbar ist
        if (typeof environment.enableTicketContactType !== 'undefined') {
            this.enableTicketContactType = environment.enableTicketContactType;
        }
        // Environment-Definition, ob Kontakte in der Zukunft angelegt werden können
        if (typeof environment.contactsDisableFutureDateForContacts !== 'undefined') {
            this.disableFutureDateForContacts = environment.contactsDisableFutureDateForContacts;
        }

        // Environment-Definition, ob der Aufgabentyp aktiviert ist
        if (typeof environment.enableTaskType !== 'undefined' && environment.enableTaskType) {
            this.enableTaskType = true;
        }
        // Environment-Definition, ob Kennzeichen aktiviert sind
        if (
            typeof environment.contactsCharacteristicsEnabled !== 'undefined' &&
            environment.contactsCharacteristicsEnabled
        ) {
            this.characteristicsEnabled = true;
        }
        // Environment-Definition, ob Titel Pflichtfeld ist
        if (typeof environment.appointmentTitleRequired !== 'undefined') {
            this.appointmentTitleRequired = environment.appointmentTitleRequired;
        }
        if (typeof environment.contactNoteRequired !== 'undefined') {
            this.contactNoteRequired = environment.contactNoteRequired;
        }
        if (typeof environment.appointmentNoteRequired !== 'undefined') {
            this.appointmentNoteRequired = environment.appointmentNoteRequired;
        }
        if (typeof environment.taskNoteRequired !== 'undefined') {
            this.taskNoteRequired = environment.taskNoteRequired;
        }
        if (typeof environment.contactEmployeeVisible !== 'undefined') {
            this.contactEmployeeVisible = environment.contactEmployeeVisible;
        }
        if (typeof environment.appointmentEmployeeVisible !== 'undefined') {
            this.appointmentEmployeeVisible = environment.appointmentEmployeeVisible;
        }
        if (typeof environment.taskEmployeeVisible !== 'undefined') {
            this.taskEmployeeVisible = environment.taskEmployeeVisible;
        }
        // Environment-Definition, ob bei Kontakten der Titel angezeigt werden soll
        if (typeof environment.contactsShowContactTitle !== 'undefined') {
            this.showContactTitle = environment.contactsShowContactTitle;
        }
        // Environment-Definition, ob Titel Pflichtfeld ist bei Kontakten
        if (typeof environment.contactTitleRequired !== 'undefined') {
            this.contactTitleRequired = environment.contactTitleRequired;
        }
    }

    /**
     * @description   Übersetzungen laden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeDateTranslations() {
        this.labelContactStart = this.translateService.instant('GENERAL.DATE');
        this.labelContactEnd = this.translateService.instant('GENERAL.DATE');
        this.labelContactNote = this.translateService.instant('GENERAL.NOTE');
    }

    /**
     * Details eines Kontakts laden
     * @param {number} id - ID des Kontakts
     */
    loadDetails(id: number): void {
        // Flag "loading" aktivieren
        this.loading = true;

        // Falls es sich um einen neuen Kontakt handelt, werden "newContactOptions" gesetzt
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let newContactOptions: any = {};

        // @todo: Muster-Logik in Environment konfigurierbar machen, lint warning prüfen
        // eslint-disable-next-line eqeqeq
        if (id == 0) {
            newContactOptions = {
                contactType: this.newContactType,
                /*
                 * Achtung: wenn "enablePersonSelection = true", darf die personId nicht auf 0 gesetzt werden
                 * damit die Personenauswahl anhand der Einrichtung funktioniert, muss personId "null" oder "undefined" sein
                 *
                 * TODO: Grund?
                 */
                personId: this.personId,
                institutionId: this.institutionId,
                showItems: true,
                getSampleAccountData: this.contactsUseItems,
                getInstitutionPeopleData: this.enablePersonSelection,
                opportunityId: this.opportunityId,
            };
        } else {
            newContactOptions = {
                showItems: true,
                getSampleAccountData: this.contactsUseItems,
                getSignature: true,
                getInstitutionPeopleData: this.enablePersonSelection,
                getInstitutionsOfPerson: this.allowChangeInstitutionSelection,
            };
        }

        // Falls es sich um einen neuen Kontakt handelt, wird auch der gewünschte Kontakttyp mitgesendet
        const serviceRequest$ = this.contactsService.loadDetails(this.controllerName, id, newContactOptions);
        serviceRequest$.subscribe(
            (result: CWResult) => {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zum angefragten Element passen. Durch asynchrone
                 * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                 * bereits das Element gewechselt wurde und die Antwort
                 * eines Requests verspätet eintrifft und dadurch die
                 * korrekten Daten wieder überschreibt.
                 */
                // @Todo lint warning prüfen
                // eslint-disable-next-line eqeqeq
                if (this.contactsService.contactId != result.data['id']) {
                    return;
                }

                this.reactions = this.createReactions(result.data);
                // Geladene Daten in Modul übernehmen
                this.data = Object.assign(result.data);

                // Falls die Musterberechtigung nicht mit dem Input übergeben wurde
                if (this.data.sample_authorization) {
                    this.sampleAuthorization = this.data.sample_authorization.sampleAuthorization;
                    this.allowedProductTypes = this.data.sample_authorization.allowedProductTypes;
                }

                // NUR falls noch keine Teilnehmer an @Input übergeben wurden, Variable zuweisen - @Input darf auf keinen Fall zurückgesetzt werden!
                if ((this.participants.length === 0 || this.initialized) && 'participants' in result.data) {
                    this.participants = Object.assign(result.data['participants']);
                } else if ((this.participants.length === 0 || this.initialized) && !this.data['noParticipants']) {
                    this.participants = [Object.assign(result.data)];
                }

                // Person zurücksetzen wenn von der aufrufenden Komponente angefordert
                if (this.resetPersonSelection) {
                    this.data.person_id = null;
                }

                // Nur wenn der readonlyWert geändert werden darf...
                if (!this.readonlyImmutable) {
                    // ... soll überprüft werden, ob der Kontakt noch bearbeitet werden darf.
                    this.checkAllowEditContact();
                    // Readonly-Wert des Kontakts weitergeben
                    this.contactsService.contactReadonlyChanged.next(this.readonly);
                }

                // Muster-Daten anreichern
                if (result.data['division'] && this.samplesData[result.data['division']] !== undefined) {
                    this.enrichSampleData(result);
                } else {
                    // eslint-disable-next-line no-console
                    console.error('No samples data can be enriched for division ' + result.data['division']);
                }

                // Behandlung von mehreren Personen zu einer Einrichtung, z.B. bei Kontaktanlage über den Tourenplaner
                if (this.enablePersonSelection) {
                    this.displayPersonSelectBox = true;
                    this.institutionPeopleData = this.data.institution_people ?? [];

                    if (this.data.person_id > 0) {
                        // Personenauswahl sperren, wenn es im Kontakt bereits eine Person gibt und bestimmte Bedingungen erfüllt sind
                        const alreadyHasItems =
                            notEmptyObject(this.data.contact_items) && this.data.contact_items.length > 0;
                        const turnAppointmentIntoContact =
                            this.data.contact_type === 'appointment' && this.autoChangeContactType === 'contact';
                        /*
                         * jedoch nur bei Umwandlung eines Termins in einen Kontakt und wenn noch keine Items erfasst wurden
                         * oder bei Einrichtungskontakten, wenn noch keine contact items erfasst wurden
                         */
                        this.enablePersonSelection =
                            (turnAppointmentIntoContact && !alreadyHasItems) ||
                            (this.isInstitutionContact && !alreadyHasItems);
                        // die Person des Kontakts beibehalten, aber auch die zugehörigen Musterdaten laden
                        this.onPersonSelected(this.data.person_id);
                    } else if (
                        result.data.institution_people.length === 1 &&
                        (this.data.person_id === 0 || this.data.person_id === null)
                    ) {
                        // wenn nur eine Person vorhanden ist, wird diese automatisch ausgewählt
                        this.data.person_id = result.data.institution_people[0].id;
                    } else {
                        // Personenauswahl zurücksetzen, Formular wird erst gültig, wenn eine Auswahl getroffen wurde
                        this.data.person_id = null;
                    }
                } else {
                    this.institutionPeopleData = [];
                }

                // Falls es sich um einen Einrichtungskontakt handelt..
                if (this.isInstitutionContact) {
                    // Leeres Element für Personenauswahl hinzufügen
                    const emptyPerson = {
                        id: 0,
                        label: 'Keine Auswahl',
                    };
                    this.institutionPeopleData.push(emptyPerson);
                }

                // die Parent-Komponente wünscht die automatische Umwandlung des Kontakttyps, z.B. Termin zu Kontakt
                if (notEmpty(this.autoChangeContactType)) {
                    this.data.contact_type = this.autoChangeContactType;
                }

                // Sammelkontakte besitzen keine Person beim Laden, deswegen notwendige Daten tricksen
                if (this.collectiveEntry) {
                    // Division des eingeloggten Nutzer als divison ablegen
                    // eslint-disable-next-line no-param-reassign
                    result.data['division'] = result.data['loggedInUser']['division_id'];
                    // eslint-disable-next-line no-param-reassign
                    result.data['sample_account_data'] = [];
                }

                // Kopie der Daten als "originalData" speichern
                this.originalData = JSON.parse(JSON.stringify(this.data));

                // Anpassungen der Kontaktartspezifischen Felder
                this.updateFields();

                /**
                 * Im Readonly-Modus werden keine Produkte gefiltert,
                 * da evtl. schon Produkte abgegeben wurden, bevor es eine Einschränkung gab
                 */
                if (!this.readonly) {
                    // Sichtbare Produkte und Produktgruppen anhand der Berechtigungen der Person aktualisieren
                    this.applyContactItemsVisibilityRules();
                }

                // Sichtbarkeit der Produktgruppen aktualisieren, anhand der Kontaktmethode und dem Kontakttyp
                this.updateContactItemsVisibilityByContactData();

                // Nur bei Mehrfachkontakt prüfen
                if (this.data['multi_contact_id'] > 0) {
                    // Init
                    this.multiContactId = this.data['multi_contact_id'];
                    this.participantsHaveEmailSet = true;

                    // Jeden Teilnehmer prüfen
                    for (const item of this.participants) {
                        if (
                            (Object.prototype.hasOwnProperty.call(item, 'institutions') &&
                              item['institutions'][0]['_joinData']['mail'] == null) || // in Popup geöffnet
                              (Object.prototype.hasOwnProperty.call(item, 'institutions') === false && item.mail == null) // in Details geöffnet
                        ) {
                            this.participantsHaveEmailSet = false;
                        }
                    }
                }

                // Nur bei Remote Kontakt zusätzliche Daten laden
                if (this.data['contact_type'] === 'remote') {
                    const getEdetailerLinks$ = this.toolbarService.getEdetailerLinks(
                        this.personId,
                        '',
                        this.multiContactId,
                    );
                    getEdetailerLinks$.subscribe((edetailerResult: CWResult) => {
                        // Links zwischenspeichern
                        this.serverLink = edetailerResult.data['server'];
                        this.clientLink = edetailerResult.data['client'];
                    });
                }

                // Subject "Kontakt wurde geladen" auslösen
                this.contactsService.setContactFinishedLoading();

                // Flag "loading" deaktivieren
                this.loading = false;

                // Flag setzen
                this.initialized = true;
            },
            () => {
                // Flag "loading" deaktivieren
                this.loading = false;

                // Flag setzen
                this.initialized = false;
            },
        );
    }

    createReactions(backendData) {
        const result = {};

        if (!hasOwn(backendData, 'contact_items')) {
            return result;
        }
        backendData.contact_items.forEach((element) => {
            result[element.product_id] = element.contact_item_product_reaction;
        });
        return result;
    }

    /**
     * @param {CWResult} result - CWResult
     * @description   Daten anreichern
     * @todo    Überarbeiten - zu viele Schleifen über die gleichen Daten
     * @author  ???
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    enrichSampleData(result: CWResult): void {
        // Die Musterdaten mit den Kontakt-Daten anreichern
        this.enrichedSamplesData = this.samplesData[result['data']['division']];

        /*
         * Bei der Sammelabgabe wird die Freigabe nachdem Absenden geprüft,
         * da erst dann ersichtlich ist welche Person es ist
         */
        if (this.collectiveEntry) {
            this.sampleAuthorization = true;
        }

        // Initialisiere die Muster //@todo besser machen.
        for (let i = 0, samplesLength = this.enrichedSamplesData.length; i < samplesLength; i += 1) {
            // Flags initialisieren
            this.enrichedSamplesData[i]['conversation'] = false;
            this.enrichedSamplesData[i]['hasSamplesForContact'] = false;
            this.enrichedSamplesData[i]['visible'] = true;
            let countConversationChildren = 0;
            let countIgnoredProductType = 0;

            // Werte der abgegebenen Muster initialisieren
            for (let j = 0, childrenLength = this.enrichedSamplesData[i].children.length; j < childrenLength; j += 1) {
                this.enrichedSamplesData[i]['children'][j]['sample_amount'] = 0;
                this.enrichedSamplesData[i]['children'][j]['samples_account'] = 0;

                // Zähler hochzählen falls keine Muster-Berechtigung besteht, aber Gesprächsthema vorliegt
                if (
                    this.sampleAuthorization === false &&
                    this.enrichedSamplesData[i]['children'][j]['topic_of_conversation'] === true
                ) {
                    countConversationChildren += 1;
                }

                // Zähler hochzählen falls keine Muster-Berechtigung besteht, aber Produkttyp ignoriert werden soll
                if (
                    this.data.always_allowed_product_types &&
                    this.data.always_allowed_product_types.includes(
                        this.enrichedSamplesData[i]['children'][j]['product_type'],
                    )
                ) {
                    countIgnoredProductType += 1;
                }
            }
            /**
             * Gruppe ausblenden, wenn keine Berechtigung zur Musterabgabe vorliegt, keine Gesprächsthemen enthalten sind
             * und der Kontakt nicht im readonly Modus ist
             */
            const isConversationTopic = this.enrichedSamplesData[i]['topic_of_conversation'];
            if (
                !this.readonly &&
                this.sampleAuthorization === false &&
                countConversationChildren === 0 &&
                countIgnoredProductType === 0 &&
                !isConversationTopic
            ) {
                this.enrichedSamplesData[i]['visible'] = false;
            }
        }

        // Initialisiere ein Array zum Zwischenspeichern der Produkte, die nicht mehr abgegeben werden können
        const legacySamplesData: LooseObject[] = [];
        // Setzt geladene Daten eines Kontakts zum Bearbeiten
        if (typeof this.data['contact_items'] !== 'undefined') {
            for (const sampleItem of this.data['contact_items']) {
                // Variable initialisieren, die definiert, ob das Produkt in den Musterdefinitionen gefunden wurde
                let sampleDefinitionFound = false;

                // @todo: Effizienter machen.
                for (let i = 0; i < this.enrichedSamplesData.length; i += 1) {
                    // Flag setzen und nächsten Durchlauf starten
                    if (this.enrichedSamplesData[i]['product_id'] === sampleItem['product_id']) {
                        this.enrichedSamplesData[i]['conversation'] = true;
                        this.enrichedSamplesData[i]['conversation_order'] = sampleItem['conversation_order'];

                        // Flag setzen
                        sampleDefinitionFound = true;

                        // Nächsten Durchlauf starten
                        continue;
                    }

                    // Muster finden
                    for (let j = 0; j < this.enrichedSamplesData[i].children.length; j += 1) {
                        if (this.enrichedSamplesData[i]['children'][j]['product_id'] === sampleItem['product_id']) {
                            // Werte übertragen
                            this.enrichedSamplesData[i]['children'][j]['sample_amount'] = sampleItem['sample_amount'];
                            this.enrichedSamplesData[i]['children'][j]['charge'] = sampleItem['charge'];
                            this.enrichedSamplesData[i]['children'][j]['pzn'] = sampleItem['pzn'];
                            this.enrichedSamplesData[i]['children'][j]['conversation'] =
                                this.enrichedSamplesData[i]['children'][j]['topic_of_conversation'];
                            this.enrichedSamplesData[i]['children'][j]['conversation_order'] =
                                sampleItem['conversation_order'];

                            // Flag setzen
                            this.enrichedSamplesData[i]['hasSamplesForContact'] = true;
                            sampleDefinitionFound = true;

                            // Durchlauf beenden
                            break;
                        }
                    }
                }

                /*
                 * Prüfen, ob eine Musterdefinition gefunden wurde oder nicht
                 * Wenn keine Musterdefinition gefunden wurde, muss das Produkt zwischengespeichert werden
                 */
                if (sampleDefinitionFound === false) {
                    // Objekt generieren
                    const undefinedItem: LooseObject = this.prepareUndefinedSampleItem(sampleItem);

                    // Objekt zwischenspeichern
                    legacySamplesData.push(undefinedItem);
                }
            }
        }

        // Daten der bisher im gesamten Jahr abgegebenen Einheiten der Muster
        for (const sampleAccountItem of this.data['sample_account_data']) {
            for (let i = 0; i < this.enrichedSamplesData.length; i += 1) {
                for (let j = 0; j < this.enrichedSamplesData[i].children.length; j += 1) {
                    if (
                        this.enrichedSamplesData[i]['children'][j]['product_id'] ===
                        parseInt(sampleAccountItem['product_id'], 10)
                    ) {
                        this.enrichedSamplesData[i]['children'][j]['samples_account'] =
                            sampleAccountItem['samples_account'];
                        this.enrichedSamplesData[i]['children'][j]['pzn'] = sampleAccountItem['pzn'];
                        break;
                    }
                }
            }
        }

        /*
         * Prüfen, ob Produkte ohne Musterdefinition gefunden wurden oder nicht
         * Wenn Produkte ohne Definitionen gefunden wurden, müssen diese in einer eigenen Gruppe angehängt werden
         */
        if (legacySamplesData.length > 0) {
            this.addUndefinedProductGroupToEnrichedSamplesData(legacySamplesData);
        }
    }

    /**
     * Initialisiert eine Musterabgabe für ein Produkt, dass nicht als Muster bekannt ist
     * @param {LooseObject} sampleItem - Muster-Item
     * @returns {LooseObject} - Muster-Item
     */
    private prepareUndefinedSampleItem(sampleItem: LooseObject): LooseObject {
        // Objekt mit Werten initialisieren
        const undefinedItem: LooseObject = {
            product_id: sampleItem['product_id'],
            parent_product_id: null,
            sample: true,
            topic_of_conversation: false,
            sort: 20,
            product_level: sampleItem['product_level'],
            label: sampleItem['label'],
            pzn: sampleItem['pzn'],
            available_batches: 0,
            batches: [],
            charge: sampleItem['charge'],
            sample_amount: sampleItem['sample_amount'],
            conversation_order: sampleItem['conversation_order'],
        };

        // Bearbeitbarkeit einschränken
        undefinedItem.readonly = true;

        // Besprechungsreihenfolge vorbereiten
        if (undefinedItem.conversation_order !== null || undefinedItem.sample_amount <= 0) {
            undefinedItem.sample = false;
            undefinedItem.conversation = true;
            undefinedItem.topic_of_conversation = true;
            undefinedItem.sort = 10;
        }

        // Batches vorbereiten
        if (undefinedItem.charge !== null && undefinedItem.charge.length > 0) {
            // Charge initialisieren
            const legacyBatch = {
                id: sampleItem['charge'],
                product_id: sampleItem['product_id'],
                charge: sampleItem['charge'],
                label: sampleItem['charge'],
                valid_from: moment().startOf('day'),
                valid_to: moment().endOf('day'),
            };

            // Chargen an Objekt anhängen
            undefinedItem.batches.push(legacyBatch);
            undefinedItem.available_batches = 1;
        }

        // Objekt zurückgeben
        return undefinedItem;
    }

    /**
     * Initialisiert eine Produktgruppe für alle Produkte, die nicht als Muster bekannt sind
     * @param {LooseObject[]} legacySamplesData - Muster-Items
     */
    private addUndefinedProductGroupToEnrichedSamplesData(legacySamplesData: LooseObject[]): void {
        // Objekt mit den Daten der neuen Produktgruppe initialisieren
        const legacyProductGroup: LooseObject = {
            charge_required: null,
            children: [],
            conversation: false,
            erp_number: null,
            hasSamplesForContact: true,
            label: null,
            max_amount_samples: 0,
            parent_product_id: null,
            product_id: null,
            product_level: '0',
            sample: false,
            signature_required: null,
            sort: 999999,
            topic_of_conversation: false,
            visible: true,
            alwaysVisible: true,
        };

        // Bezeichnung laden
        legacyProductGroup.label = this.translateService.instant('SHARED.CONTACTS.CONTACTITEMS.UNDEFINEDSAMPLES');

        // Bearbeitbarkeit einschränken
        legacyProductGroup.readonly = true;

        // Produkte sortieren
        legacySamplesData.sort((a, b) => {
            // Sortierung nach Sort-Feld (Besprechungen an den Anfang, alles andere danach)
            if (a.sort > b.sort) {
                return 1;
            }

            if (b.sort > a.sort) {
                return -1;
            }

            // Falls der gleiche Wert im Sort-Feld steht nach Bezeichnung sortieren
            if (a.label > b.label) {
                return 1;
            }

            if (b.label > a.label) {
                return -1;
            }

            return 0;
        });

        // Produkte als Kinder der Gruppe anhängen
        legacyProductGroup.children.push(...legacySamplesData);

        // generierte Produktgruppe an die Musterdaten anhängen
        this.enrichedSamplesData.push(legacyProductGroup);

        // Methode beendet
    }

    /**
     * Klick auf "Speichern" (Form-Submit)
     * @param {boolean} collectiveEntry - Flag für Sammelkontakt
     * @returns {any} -
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, consistent-return
    clickSubmit(collectiveEntry = false): any {
        // Nur gültige Formulare werden submitted
        if (this.contactsForm.form.valid) {
            // Start- & Enddatum validieren
            const validationError = this.validateDate();
            if (validationError) {
                this.openMessageDialog(validationError);
                return false;
            }

            // Flag "saving" aktivieren
            this.saving = true;

            // "contact_start_date" im Frontend "MOMENT" für Backend-Speicherung konvertieren
            this.contactsForm.form.value['contact_start_date_backendsave'] = this.inputDateService.getDateValueForSave(
                this.contactsForm.form.value['contact_start_date'],
            );

            // "contact_end_date" im Frontend "MOMENT" für Backend-Speicherung konvertieren, insofern hier "End-Datum" statt "Dauer" verwendet wird
            if (this.contactsForm.form.value['contact_end_date']) {
                this.contactsForm.form.value['contact_end_date_backendsave'] =
                    this.inputDateService.getDateValueForSave(this.contactsForm.form.value['contact_end_date']);
            } else {
                // Es gibt kein separates Feld für End-Datum, daher den gleichen Wert wie beim Start-Datum verwenden
                this.contactsForm.form.value['contact_end_date_backendsave'] =
                    this.contactsForm.form.value['contact_start_date_backendsave'];
            }

            const contactItems = [];
            for (let i = 0; i < this.enrichedSamplesData.length; i += 1) {
                if (this.enrichedSamplesData[i].conversation === true) {
                    contactItems.push({
                        product_id: this.enrichedSamplesData[i].product_id,
                        sample_amount: 0,
                        conversation_order: this.enrichedSamplesData[i].conversation_order,
                        contact_item_product_reaction: this.enrichedSamplesData[i].contact_item_product_reaction,
                    });
                }

                for (let j = 0; j < this.enrichedSamplesData[i].children.length; j += 1) {
                    if (this.enrichedSamplesData[i]['children'][j]['sample_amount'] > 0) {
                        contactItems.push({
                            product_id: this.enrichedSamplesData[i].children[j].product_id,
                            sample_amount: this.enrichedSamplesData[i].children[j].sample_amount,
                            charge: this.enrichedSamplesData[i].children[j].charge,
                        });
                    } else if (this.enrichedSamplesData[i]['children'][j].conversation === true) {
                        contactItems.push({
                            product_id: this.enrichedSamplesData[i].children[j].product_id,
                            sample_amount: 0,
                            conversation_order: this.enrichedSamplesData[i]['children'][j].conversation_order,
                            contact_item_product_reaction:
                                this.enrichedSamplesData[i]['children'][j].contact_item_product_reaction,
                        });
                    }
                }
            }

            // contactItems im Form.value zurücksetzen, da die Musterdaten direkt aus this.enrichedSamplesData kommen.
            if (typeof this.contactsForm.form.value.contactItems !== 'undefined') {
                delete this.contactsForm.form.value.contactItems;
            }

            if (this.data['contact_type'] === 'remote') {
                this.contactsForm.form.value['edetailer_server_link'] = this.serverLink;
                this.contactsForm.form.value['edetailer_client_link'] = this.clientLink;
            }

            // Submit der Formular-Daten über contactsDataService
            const serviceRequest$ = this.contactsService.saveDetails(
                this.controllerName,
                this.contactId,
                this.contactsForm.form.value,
                contactItems,
                this.participants,
            );
            serviceRequest$.subscribe((result: CWResult) => {
                if (!result.success) {
                    // Flag "saving" deaktivieren
                    this.saving = false;

                    if (result.message === 'tooManySamples') {
                        let message = this.translateService.instant('SHARED.CONTACTS.FORM.TOMANYSAMPLESSAVINGERROR');

                        // Für den Sammelkontakt Text überschreiben
                        if (collectiveEntry) {
                            message = 'Der Kontakt konnte wegen Musterüberschreitung nicht gespeichert werden.';
                        }
                        // Es wurden zu viele Muster übergeben.
                        this.openMessageDialog(message);
                        return;
                    }

                    if (notEmpty(result.message)) {
                        // Es wurden zu viele Muster übergeben.
                        this.openMessageDialog(result.message['error'].join(', '));
                    }
                    return;
                }

                // fur SammelKontakte immer neue Anlage auslösen
                if (collectiveEntry) {
                    this.collectiveEntryKeyInfo = 'Kontakt erfolgreich gespeichert.';
                    // Nach einer Sekunde Text wieder ausblenden
                    setTimeout(() => {
                        this.collectiveEntryKeyInfo = '';
                    }, 2000);
                    this.collectiveEntryKey = '';
                    return;
                }

                // Falls es sich um eine Neuanlage handelte... @todo lint warning prüfen
                // eslint-disable-next-line eqeqeq
                if (this.data['id'] == 0) {
                    // ...wird die neue ID übernommen
                    this.data['id'] = result.data['id'];
                }

                // Kopie der geänderten Daten als neue "originalData" speichern
                this.originalData = JSON.parse(JSON.stringify(this.data));

                // Speichern der Kennzeichen auslösen
                if (this.characteristicsEnabled) {
                    this.triggerSaveContactCharacteristics(result.data);
                }

                // EditMode verlassen (und auch nach außen emitten)
                this.editMode = false;
                // Flag "saving" deaktivieren
                this.saving = false;

                // Daten wurden erfolgreich geändert --> Event über Service auslösen
                this.contactsService.dataChanged('', this.data);
                // Liste neu laden
                this.gridService.reloadGridData('contacts-form');
                // Liste neu laden im neuen Grid
                this.gridNewService.dataChanged(this.parentGridName, this.parentGridName, this.data);

                // Event auslösen um Mehrfachkontakt-Popup zu schließen
                this.toolbarService.closeComponent('contacts-form-popup');
            });
        } else {
            this.openMessageDialog('Bitte Formular korrekt ausfüllen.');
        }
    }

    /**
     * Löse das Speichern der Kennzeichen aus
     * @param {any} contactData - Kontakt-Daten
     * Kennzeichen Multi Kontakt Edit wird eventuell nicht bei allen Kontakten mit multi_contact_id übernommen
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private triggerSaveContactCharacteristics(contactData: any): void {
        // Kontakt prüfen
        if (
            Object.prototype.hasOwnProperty.call(contactData, 'participants') &&
            (Object.prototype.hasOwnProperty.call(contactData, 'id') === false || typeof contactData.id === 'undefined')
        ) {
            // Kennzeichen für jeden erstellten Kontakt anlegen
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            contactData.participants.forEach((personData: any) => {
                // einzelner Kontakt
                if (
                    Object.prototype.hasOwnProperty.call(personData, 'contact_id') &&
                    personData.contact_id !== null &&
                    personData.contact_id > 0
                ) {
                    const formData = {
                        characteristicType: CHARACTERISTIC_TYPE,
                        entityId: personData.contact_id,
                    };
                    this.characteristicsService.saveCharacteristics('contacts-form-contacts', formData);
                }
            });
        } else {
            // einzelner Kontakt
            const formData = {
                characteristicType: CHARACTERISTIC_TYPE,
                entityId: contactData['id'],
            };
            this.characteristicsService.saveCharacteristics('contacts-form-contacts', formData);
        }
    }

    /**
     * Klick auf "Abbrechen"
     */
    clickCancel(): void {
        // Da Cancel doch nicht so funktioniert wie gedacht, hier die schnelle Variante mit eigener Datenkopie vor Benutzeränderungen
        this.data = JSON.parse(JSON.stringify(this.originalData));

        // EditMode verlassen (und auch nach außen emitten)
        this.editMode = false;

        // Event auslösen um Mehrfachkontakt-Popup zu schließen
        this.toolbarService.closeComponent('contacts-form-popup');
    }

    /**
     * @param {string} newContactType - neuer Kontakttyp
     * @description   Kontakttyp ändern
     *          Nachträglicher Änderungswunsch von IVF, da z.B. oft erst ein
     *          Termin angelegt wird, der dann nachträglich zu einem
     *          Kundenbesuch geändert wird.
     * @author  Massimo Feth <m.feth@phrmakon.software>
     */
    changeContactType(newContactType: string): void {
        this.data.contact_type = newContactType;
        this.contactTypeChanged.emit(newContactType);
        // Sichtbare Produkte filtern, damit bei bestimmten Kontaktmethoden / Typen keine Muster sichtbar sind
        this.updateContactItemsVisibilityByContactData();
        this.updateFields();
    }

    /**
     * @description   Anpassung der Kontaktartspezifischen Felder
     *                Beim auswählen oder wechseln der Kontaktart,
     *                müssen die spezifischen Felder angepasst werden
     * @author  Julia Zitzmann <j.zitzmann@pharmakon.software>
     */
    private updateFields(): void {
        // Label für "Datum" und "Notiz" unterscheidet sich bei Typ "Aufgabe"
        if (this.data['contact_type'] === 'task') {
            this.labelContactStart = this.translateService.instant('SHARED.CONTACTS.FORM.DUE');
            this.labelContactNote = this.translateService.instant('GENERAL.DESCRIPTION');
        } else {
            if (this.contactsUseDuration) {
                this.labelContactStart = this.translateService.instant('GENERAL.DATE');
            } else {
                this.labelContactStart = this.translateService.instant('GENERAL.START');
                this.labelContactEnd = this.translateService.instant('GENERAL.END');
            }

            this.labelContactNote = this.translateService.instant('GENERAL.NOTE');
        }

        // titel required unterscheidet sich je nach Kontaktart
        if (this.data['contact_type'] === 'appointment') {
            this.titleRequired = this.appointmentTitleRequired;
        }
        if (this.data['contact_type'] === 'task') {
            this.titleRequired = this.taskTitleRequired;
        }

        // note required unterscheidet sich je nach Kontaktart
        if (this.data['contact_type'] === 'contact') {
            this.noteRequired = this.contactNoteRequired;
        }
        if (this.data['contact_type'] === 'appointment') {
            this.noteRequired = this.appointmentNoteRequired;
        }
        if (this.data['contact_type'] === 'task') {
            this.noteRequired = this.taskNoteRequired;
        }

        // ob employee angezeigt werden soll unterscheidet sich je nach Kontaktart
        if (this.data['contact_type'] === 'contact') {
            this.employeeVisible = this.contactEmployeeVisible;
        }
        if (this.data['contact_type'] === 'appointment') {
            this.employeeVisible = this.appointmentEmployeeVisible;
        }
        if (this.data['contact_type'] === 'task') {
            this.employeeVisible = this.taskEmployeeVisible;
        }
        if (this.data['contact_type'] === 'contact') {
            this.titleRequired = this.contactTitleRequired;
        }
    }

    /**
     * @description   Link für eVisit-Instanz laden und in neuem Tab öffnen
     * @author  Eric Häußel <e.haeusel@pharmakon.software>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    startEdetailer(): void {
        /*
         * Prüfe ob es sich um ein Apple Device handelt
         * ebenfalls Macintosh abfragen - siehe https://forums.developer.apple.com/thread/119186 (ziemlich beschissene Lösung - ändern sobald man korrekt zwischen iPad und Macintosh unterscheiden kann)
         */
        if (window.navigator && window.navigator.userAgent.match(/iPhone|iPad|iPod|Macintosh/i)) {
            this.enterNewRoomApple();
        } else {
            this.enterNewRoom();
        }
    }

    /**
     * @description   Link-Element erzeugen und klicken, um neuen Tab zu öffnen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private enterNewRoom(): void {
        // Link aus Backend laden
        const serviceRequest$ = this.toolbarService.getEdetailerLinks(this.personId, '', this.multiContactId);
        serviceRequest$.subscribe((result: CWResult) => {
            // Ergebnis prüfen
            if (result.success) {
                // Link zwischenspeichern
                const link = result['data']['server'];

                // Link in neuem Tab öffnen
                window.open(link, '_blank');
            }
        });
    }

    /**
     * @description   Link für eVisit-Instanz laden und in neuem Tab öffnen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private enterNewRoomApple(): void {
        // neues Fenster öffnen
        const newTab = window.open();
        newTab.document.title = 'Bitte warten...';

        // Link aus Backend laden
        const serviceRequest$ = this.toolbarService.getEdetailerLinks(this.personId, '', this.multiContactId);
        serviceRequest$.subscribe(
            (result: CWResult) => {
                // Ergebnis prüfen
                if (result.success) {
                    // Link zwischenspeichern
                    const link = result['data']['server'];

                    // Link in neuem Tab öffnen
                    newTab.location = link;
                } else {
                    // Fenster schließen bei Fehler
                    newTab.close();
                }
            },
            () => {
                // Fenster schließen bei Fehler
                newTab.close();
            },
        );
    }

    /*
     * @description   Overlay-Info in Dialog anzeigen für Personen oder Einrichtungen
     *
     * @author  Sena Üner <s.uener@pharmakon.software>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    openOverlayInfoDialog(event: any, backendController: string, entityId: number): void {
        // Dialog öffnen
        let dialogRef = null;
        if (backendController === 'PeopleData') {
            dialogRef = this.overlayService.openOverlayInfoDialog(
                event.target,
                OverlayInfoPersonComponent,
                backendController,
                'details',
                entityId,
            );
        } else if (backendController === 'InstitutionsData') {
            dialogRef = this.overlayService.openOverlayInfoDialog(
                event.target,
                OverlayInfoInstitutionComponent,
                backendController,
                'details',
                entityId,
            );
        } else {
            // eslint-disable-next-line no-console
            console.error('backendController unknown');
            return;
        }

        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe(() => undefined);
    }

    /**
     * @param {string} message - Nachricht
     * @param {string} title - Titel
     * @description   Dialog öffnen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openMessageDialog(message: string, title = null): void {
        const dialogTitle = title == null ? this.translateService.instant('SHARED.CONTACTS.FORM.CHECKCONTACT') : title;
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupMessageComponent, {
            width: '350px',
            data: {
                title: dialogTitle,
                message,
            },
        });
        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe(() => undefined);
    }

    /**
     * @description   Prüfe, ob der Kontakt bearbeitet werden darf.
     * @author  Michael Schiffner <m.schiffner@pharmakon.software>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkAllowEditContact(): void {
        // Versuche die Permission zu laden
        this.allowEditForeignContacts = this.userPermissionsService.getPermissionValue('allowEditForeignContacts');
        this.allowEditContact = this.userPermissionsService.getPermissionValue('allowEditContactFor');

        // Berechtigung und Zugehörigkeit des Kontakts prüfen
        if (
            this.allowEditForeignContacts ||
            (Object.prototype.hasOwnProperty.call(this.data, 'responsible_employee_id') &&
              Object.prototype.hasOwnProperty.call(this.data['loggedInUser'], 'employee_id') &&
              this.data.responsible_employee_id === this.data['loggedInUser'].employee_id)
        ) {
            // Daten prüfen
            if (this.data.exported !== null && this.data.exported !== undefined && this.data.exported) {
                // Wenn ein Kontakt exportiert wurde, kann der Kontakt nicht mehr bearbeitet werden
                this.readonly = true;
                this.hideButtons = true;
            } else if (
                this.data.signature_id !== null &&
                this.data.signature_id !== undefined &&
                this.data.signature_id > 0
            ) {
                // Wenn eine Unterschrift gegeben wurde, kann der Kontakt nicht mehr bearbeitet werden
                this.readonly = true;
                this.hideButtons = true;
            } else if (this.allowEditContact === 0) {
                // Wenn der die Anzahl der Tage auf 0 gesetzt ist => sollen die Kontakte unendlich lange nachbearbeitet werden dürfen
                this.readonly = false;
                this.hideButtons = false;
            } else if (
                moment(this.data['contact_end_date'])
                    .add(this.allowEditContact, 'days')
                    .isAfter(moment().startOf('day').subtract(1, 'ms'))
            ) {
                /*
                 * ... Sonst muss überprüft werden, ob das Ende des Kontakts weniger als die Konfigurierte Anzahl an Tagen zurückliegt
                 * => Dann darf es bearbeitet werden
                 */
                this.readonly = false;
                this.hideButtons = false;
                this.minimumStartDate = moment().subtract(this.allowEditContact, 'days');
            } else {
                // Sonst darf der Kontakt nicht mehr bearbeitet werden.
                this.readonly = true;
                this.hideButtons = true;
            }
        } else {
            // Der Kontakt darf nicht bearbeitet werden.
            this.readonly = true;
            this.hideButtons = true;
        }
    }

    /**
     * @description Setze allowContactsSamples-Variable aus den Userpermissions
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkAllowContactsSamples(): void {
        const permissionAllowContactsSamples: boolean =
            this.userPermissionsService.getPermissionValue('allowContactsSamples');
        this.allowContactsSamples = permissionAllowContactsSamples;
    }

    /**
     * @description Berechtigung allowEditMultiContact prüfen
     * @author Daniel Nita <d.nita@pharmakon.software>
     */
    checkAllowEditMultiContact(): void {
        const permissionAllowEditMultiContact: boolean =
            this.userPermissionsService.getPermissionValue('allowEditMultiContact');
        this.allowEditMultiContact = permissionAllowEditMultiContact;
    }

    /**
     * @description   Berechtigung prüfen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkAllowAssignForeignContacts(): void {
        // Versuche die Permission zu laden
        const permissionAllowAssignForeignContacts: boolean =
            this.userPermissionsService.getPermissionValue('allowAssignForeignContacts');
        this.allowAssignForeignContacts = permissionAllowAssignForeignContacts;
    }

    /**
     * @description Datum prüfen und entsprechende Nachricht zurückgeben
     * @returns {string |void} Liefert Fehlernachricht
     * @author Tobias Hannemann <t.hannemann@pharmakon.software>
     * @author Daniel Nita <d.nita@pharmakon.software>
     */
    validateDate(): string | void {
        /*
         * Falls keine separate Angabe für End-Zeit im Formular erfolgt,
         * wird die End-Zeit automatisch auf die Start-Zeit gesetzt.
         */
        if (!this.contactsForm.form.value['contact_end_time']) {
            this.data.contact_end_time = this.data.contact_start_time;
        }

        // Falls der Nutzer versucht einen Kontakt weiter in der Vergangenheit anzulegen als erlaubt ist
        if (
            this.allowEditContact !== 0 &&
            !moment(this.data['contact_start_date'])
                .add(this.allowEditContact, 'days')
                .isAfter(moment().startOf('day').subtract(1, 'ms'))
        ) {
            return this.translateService.instant('SHARED.CONTACTS.FORM.ENDDATENOTALLOWEDERROR', {days: this.allowEditContact});
        }

        // Startdatum für Kontakte darf nicht in der Zukunft sein
        if (
            this.data.contact_type === 'contact' &&
            this.disableFutureDateForContacts &&
            this.data.contact_start_date.isAfter(this.currentDate)
        ) {
            return this.translateService.instant('SHARED.CONTACTS.FORM.FUTUREDATEERROR');
        }

        return '';
    }

    /**
     * @description   Korrigiert das Enddatum unter Beachtung der Differenz zwischen dem Start- und Enddatum
     *                Differenz wird berechnet, wenn das Enddatum verändert wird
     * @author  Daniel Nita <d.nita@pharmakon.software>
     */
    correctContactEndDate(): void {
        if (
            !this.contactsUseDuration &&
            this.data.contact_start_date &&
            typeof this.data.contact_start_date !== 'undefined'
        ) {
            if (this.contactDatesDifference === 0) {
                this.data.contact_end_date = this.data.contact_start_date;
            } else if (this.contactDatesDifference > 0) {
                // Enddatum = Startdatum + Differenz
                const startDate: _moment.Moment = this.data.contact_start_date.clone();
                this.data.contact_end_date = startDate.add(this.contactDatesDifference, 'days');
            }
        }
    }

    /**
     * @description   Berechnet die Differenz der Tage zwischen dem Start- und Enddatum
     *          Zusätzlich wird geprüft, dass das Enddatum nicht kleiner als das Startdatum ist
     * @author  Daniel Nita <d.nita@pharmakon.software>
     */
    calculateDateDifference() {
        if (
            !this.contactsUseDuration &&
            this.data.contact_end_date &&
            typeof this.data.contact_end_date !== 'undefined'
        ) {
            this.contactDatesDifference = this.data.contact_end_date.diff(this.data.contact_start_date, 'days');

            /**
             * Durch manuelle Eingaben im Input Feld kann es vorkommen, dass das Enddatum kleiner als das Startdatum ist
             * In diesem Fall wird das Enddatum wieder auf das Startdatum gesetzt
             */
            if (this.contactDatesDifference < 0) {
                this.data.contact_end_date = this.data.contact_start_date.clone();
            }
        }
    }

    /**
     * @description   Korrigiert die Endzeit unter Beachtung der Differenz der Start- und Endzeit
     *                Differenz wird berechnet, wenn die Endzeit verändert wird
     */
    correctContactEndTime(): void {
        if (
            !this.contactsUseDuration &&
            this.data.contact_start_time &&
            typeof this.data.contact_start_time !== 'undefined'
        ) {
            // Differenz der Zeiten prüfen
            if (this.contactTimeDifference === 0) {
                this.data.contact_end_time = this.data.contact_start_time;
            } else if (this.contactTimeDifference > 0) {
                // Endzeit = Startzeit + Differenz
                const start: _moment.Moment = moment(this.data.contact_start_time, 'HH:mm');
                const end: _moment.Moment = start.add(this.contactTimeDifference, 'minutes');
                this.data.contact_end_time = end.format('HH:mm');
            }
        }
    }

    /**
     * @description   Berechnet die Differenz der Start- und Endzeit
     * @author  Daniel Nita <d.nita@pharmakon.software>
     */
    calculateTimeDifference() {
        // Zeit-Strings in moment Objekt umwandeln
        const start: _moment.Moment = moment(this.data.contact_start_time, 'HH:mm');
        const end: _moment.Moment = moment(this.data.contact_end_time, 'HH:mm');

        if (!this.contactsUseDuration && this.isMomentObject(end) && this.isMomentObject(start)) {
            this.contactTimeDifference = end.diff(start, 'minutes');
        }
    }

    /**
     * @description   Setzt den "min" Wert der Endzeit abhängig vom Start- und Enddatum
     * @returns {string} - Minimale Endzeit - @todo string??
     * @author  Daniel Nita <d.nita@pharmakon.software>
     */
    calculateMinTime(): string {
        if (
            this.isMomentObject(this.data.contact_end_date) &&
            this.data.contact_end_date.isSame(this.data.contact_start_date, 'days')
        ) {
            return this.data.contact_start_time;
        }

        return '';
    }

    /**
     * @param {any} value - Wert
     * @description Prüfe, ob es sich beim übergebenen Value um ein Moment-Objekt handelt
     * @author Tobias Hannemann <t.hannemann@pharmakon.software>
     * @returns {boolean} - true, wenn es sich um ein Moment-Objekt handelt
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isMomentObject(value: any): boolean {
        return moment.isMoment(value);
    }

    /**
     * Prüfe, ob Listentries für die Kontakttypen existieren, wenn nicht,
     * oder wenn der User keine Edit-Rechte für den Kontakttyp hat, wird der
     * wechsel zu den KontaktTypen ausgeblendet
     * @author  Michael Schiffner <m.schiffner@pharmakon.software>
     * @returns {Promise<any>} - Promise
     */
    checkContactTypeListentriesForTypeChange() {
        const promise = this.storageService.getItem('listentries|contactType');
        promise.then((listentries: Listentry[]) => {
            if (listentries === null) {
                /*
                 * @todo: Eigentlich wäre es hier sinnvoller für this.allowChangeTo
                 * alle properties auf false zu setzen. Weitreichende Konsequenzen.
                 */
                return;
            }

            for (const listKey of Object.keys(this.allowChangeTo)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const listentry: Listentry = listentries.find((element: any) => element.list_key === listKey);
                if (typeof listentry === 'undefined') {
                    this.allowChangeTo[listKey] = false;
                    continue;
                }
                if (listentry.list_data === null || listentry.list_data === '') {
                    continue;
                }
                const listData = JSON.parse(listentry.list_data);
                if (Object.prototype.hasOwnProperty.call(listData, 'readonly') && listData.readonly === true) {
                    this.allowChangeTo[listKey] = false;
                }
            }
        });
        return promise;
    }

    /**
     * @description Prüfe ob alle Pflichtkennzeichen gesetzt wurden
     * @author Tobias Hannemann <t.hannemann@pharmakon.software>
     * @returns {boolean} - true, wenn alle Pflichtkennzeichen gesetzt wurden
     */
    checkCharacteristicsFormValidity() {
        // Kennzeichen nicht aktiviert
        if (this.characteristicsEnabled === false) {
            return true;
        }

        // Form Status
        return this.characteristicsFormValid;
    }

    /**
     * @description   Dialog öffnen
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     */
    openSignatureDialog(): void {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupSignatureComponent, {
            width: '450px',
            data: {
                title: this.translateService.instant('SHARED.CONTACTS.FORM.SIGNATURE'),
                message: this.data.signature,
            },
        });
        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe(() => undefined);
    }

    /**
     * @description Gibt zurück, ob das Zeitfeld für den Kontakt im jeweiligen Formular angezeigt werden soll
     * @author Tobias Hannemann <t.hannemann@pharmakon.software>
     * @returns {boolean} - true, wenn das Zeitfeld angezeigt werden soll
     */
    displayTimeField(): boolean {
        // Init
        let returnValue = true;

        // Typ des Formulars herausfinden
        const currentType = this.data.contact_type;
        if (Object.prototype.hasOwnProperty.call(this.contactsShowTime, currentType)) {
            // Wert anhand des
            returnValue = this.contactsShowTime[currentType];
        }

        // Ergebnis zurückgeben
        return returnValue;
    }

    // Fängt das Enter key down event ein auf das vom Barcode eingelesen Werts ein
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clickCollectiveEntrySubmit(event) {
        // Prüfen ob contact methode gesetzt wird, im normalen submit wird es über valid form gemacht
        if (this.data.contact_method === undefined || this.data.contact_method === '') {
            const message = this.translateService.instant('GENERAL.CONTACTMETHOD') + ' bitte wählen';
            // Es wurden zu viele Muster übergeben.
            this.openMessageDialog(message);

            return;
        }

        // Prüfen ob nur eine Person existiert die auch für Musterabgabe freigegeben ist
        const serviceRequest$ = this.contactsService.isPersonEligibleForCollectiveEntry(
            this.collectiveEntryKey,
            this.contactsForm.form.value['note'],
        );
        serviceRequest$.subscribe((result: CWResult) => {
            let message = null;
            // Keine Person gefunden
            if (Object.keys(result.data).length === 0) {
                message = 'Es wurde keine Person mit dieser Nummer gefunden';
            }

            // Mehr als eine Person gefunden
            if (Object.keys(result.data).length > 1) {
                message = 'Diese Nummer ist nicht eindeutig. Es wurden mehrere Personen mit dieser Nummer gefunden';
            }
            // Falls nur eine Person existiert prüfen ob die Person hat bereits einen Kontakt mit gleicher Notiz hat
            if (message === null && result.data[0].hasContactWithNote) {
                message = 'Diese Person wurde bereits erfasst.';
            }
            // Falls nicht nur eine Persone gefunden wurde
            if (message !== null) {
                // Es wurden zu viele Muster übergeben.
                this.openMessageDialog(message);
                return;
            }

            // notwenidge Infos für das Kontaktformauler setzen
            const person = result.data[0];
            this.contactsForm.form.value['person_id'] = person['id'];
            this.data.person_id = person['id'];
            this.participants = [person];

            // Es wurden zu viele Muster übergeben.
            if (!person['sampleAuthorization']) {
                const newMessage = 'Person (ID: ' + person['id'] + ') ist nicht für Musterabgabe freigegeben';
                this.openMessageDialog(newMessage);
                return;
            }

            // falls keine Einrichtung exisitiert 0 als Fallback
            this.contactsForm.form.value['institution_id'] = 0;

            // Falls die Haupteinrichtung gesetzt ist, diese setzen
            for (const institution of person['institutions']) {
                // eslint-disable-next-line eqeqeq
                if (institution['_joinData']['main_institution'] == 'Y') {
                    this.contactsForm.form.value['institution_id'] = institution['id'];
                    this.data.institution_id = institution['id'];
                    this.data.institution_name1 = institution['name1'];
                }
            }

            // Submit händisch auslösen
            this.clickSubmit(true);
        });
    }

    /**
     * @description Reagiert auf die Auswahl einer Person im Kontaktbericht
     * @param {string} selectedPersonId Aktuelle Auswahl: ID der Person, wird als string übergeben (?)
     */
    onPersonSelected(selectedPersonId: string) {
        // Zu Number konvertieren
        const personId = Number(selectedPersonId);

        if (personId !== null && personId !== 0) {
            // mit den Personen wurden auch Musterberechtigung und Musterkonto vom Backend geladen
            const selectedPerson = this.institutionPeopleData.find((p) => p.id === personId);

            if (hasOwn(selectedPerson, 'data') && hasOwn(selectedPerson['data'], 'sampleAuthorization')) {
                this.sampleAuthorization =
                    selectedPerson['data']['sampleAuthorization']['sampleAuthorization'] ?? this.sampleAuthorization;
                this.allowedProductTypes = selectedPerson['data']['sampleAuthorization']['allowedProductTypes'] ?? [];
            } else if (hasOwn(selectedPerson, 'id') && selectedPerson['id'] === 0) {
                // falls keine Person ausgewählt wurde, wird die Musterberechtigung auf false gesetzt
                this.sampleAuthorization = false;
                this.allowedProductTypes = [];
            }

            // Sample-Accounts entsprechend der Person aktualisieren
            this.updateProductSamplesAccounts(selectedPerson);

            // Sichtbare Produkte und Produktgruppen anhand der Berechtigungen der Person aktualisieren
            this.applyContactItemsVisibilityRules();

            // Sichtbare Produkte und Produktgruppen anhand der Kontaktdaten (Methode/Typ) aktualisieren
            this.updateContactItemsVisibilityByContactData();
        }
    }

    /**
     * @description Aktualisiert die Sample-Accounts für alle Muster anhand der ausgewählten Person
     * @param {SelectData} selected Daten der ausgewählten Person
     * @private
     */
    private updateProductSamplesAccounts(selected: SelectData) {
        // Hilfsfunktion zum Extrahieren der Sample-Account Angabe
        // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
        const getProductSampleAccount = (selected: SelectData, product: any): number => {
            if (notEmptyObject(selected) && hasOwn(selected, 'data')) {
                const sampleInfo = selected['data']['sample_account_data'].find(
                    (p) => p.product_id === product.product_id,
                );
                if (notEmptyObject(sampleInfo) && hasOwn(sampleInfo, 'samples_account')) {
                    return <number>sampleInfo['samples_account'];
                }
            }
            return 0;
        };
        // die bereits angereicherten Sample-Daten aktualisieren
        this.enrichedSamplesData.forEach((productGroup) => {
            // die an diese Person abgegebenen Muster in den Musterdaten der Kind-Produkte aktualisieren
            productGroup.children.forEach(
                // eslint-disable-next-line no-param-reassign
                (child) => (child.samples_account = getProductSampleAccount(selected, child)),
            );
        });
    }

    /**
     * Filtert Produktgruppen und einzelne Produkte basierend auf den Berechtigungen der ausgewählten Person.
     *
     * Diese Funktion überprüft für jede Produktgruppe und jedes Produkt, ob der zugehörige Produkttyp in der Liste der erlaubten Produkttypen enthalten ist.
     * Zusätzlich werden Produkte, die als "Gesprächsthema" markiert sind, immer angezeigt.
     * Produktgruppen werden nur angezeigt, wenn sie mindestens ein sichtbares Produkt enthalten oder
     * explizit als "immer sichtbar" gekennzeichnet sind.
     * @returns {void} Die Funktion verändert die `enrichedSamplesData` direkt
     */
    private applyContactItemsVisibilityRules(): void {
        // Abbruch, wenn keine Daten vorhanden sind
        if (this.enrichedSamplesData.length === 0) {
            return;
        }

        // Wenn es eine generelle Musterberechtigung gibt und die Produkttypen nicht eingeschränkt sind, werden alle Produkte angezeigt.
        if (this.sampleAuthorization && this.allowedProductTypes.length === 0) {
            this.setAllProductsToVisible();
            return;
        }

        // Produkttypen, die immer angezeigt werden sollen, auch bei Personen ohne Berechtigung
        if (this.data.always_allowed_product_types) {
            this.allowedProductTypes.push(...this.data.always_allowed_product_types);
        }

        // Produktgruppen durchlaufen
        this.enrichedSamplesData = this.enrichedSamplesData.map((group) => {
            const productGroup = {...group};

            // Produkte der Gruppe durchlaufen
            productGroup.children = productGroup.children.map((product) => {
                // Handlet es sich um ein Besprechungsprodukt?
                const isConversation = product['topic_of_conversation'];
                // Handelt es sich um einen erlaubten Produkttyp? Entweder explizit erlaubt oder immer erlaubt
                const isProductTypeAllowed =
                    this.allowedProductTypes.includes(product.product_type) ||
                    this.data.always_allowed_product_types.includes(product.product_type);

                // Sichtbarkeit des Produkts setzen
                return {
                    ...product,
                    visible: isProductTypeAllowed || isConversation,
                };
            });

            // Überprüfe, ob die Produktgruppe immer sichtbar sein soll
            const isGroupAlwaysVisible = hasOwn(productGroup, 'alwaysVisible') && productGroup.alwaysVisible === true;

            // Überprüfe, ob die Gruppe mindestens ein sichtbares Produkt enthält
            const hasVisibleProducts = productGroup.children.some((product) => product.visible);

            // Sichtbarkeit der Produktgruppe setzen
            return {
                ...productGroup,
                visible: hasVisibleProducts || isGroupAlwaysVisible,
            };
        });
    }

    /**
     * Wird aufgerufen, sobald die Kontaktart oder Kontaktmethode geändert wird
     * Ändert die sichtbarkeit von Produktgruppen entsprechend den erlaubten
     * Kontaktarten und Kontaktmethoden aus der Backend-config
     * @returns {void}
     */
    public updateContactItemsVisibilityByContactData(): void {
        /**
         * Prüfe ob Einschränkungen für Produkgruppen vorhanden sind
         * Im readonly Modus wird die Sichtbarkeit der Produktgruppe nicht verändert
         */
        if (
            this.readonly ||
            !this.data.contact_product_groups_restrictions ||
            this.data.contact_product_groups_restrictions.length === 0
        ) {
            return;
        }

        // Die benötigten Daten extrahieren
        const contactRestrictions = this.data.contact_product_groups_restrictions;
        const contactMethod = this.data.contact_method;
        const contactType = this.data.contact_type;

        // Sichtbare Produktgruppen setzen
        const allowedProductGroups = [];

        // Alle Einschränkungen durchlaufen
        for (let i = 0; i < contactRestrictions.length; i += 1) {
            const restriction = contactRestrictions[i];

            // Überprüfen ob alle Kontaktmethoden mit einem bestimmten Kontakttyp erlaubt sind
            if (
                restriction['criteria']['contact_method'][0] === 'all' &&
                restriction['criteria']['contact_type'].includes(contactType)
            ) {
                allowedProductGroups.push(...restriction['product_groups']);

                // Überprüfen, ob die Kontaktmethode und die Kontaktart erlaubt sind
            } else if (
                restriction['criteria']['contact_method'].includes(contactMethod) &&
                restriction['criteria']['contact_type'].includes(contactType)
            ) {
                allowedProductGroups.push(...restriction['product_groups']);
            } else {
                continue;
            }
        }

        // Die Sichtbarkeit jeder Produktgruppe basierend auf den erlaubten Produktgruppen aktualisieren
        this.enrichedSamplesData.forEach((productGroup) => {
            // eslint-disable-next-line no-param-reassign
            productGroup['visible'] = allowedProductGroups.includes(productGroup.product_id);
        });
    }

    /**
     * Macht alle Produktgruppen und Produkte sichtbar
     * @returns {void} - Die Funktion verändert die `enrichedSamplesData` direkt
     */
    private setAllProductsToVisible(): void {
        // Alle Gruppen und Produkte sichtbar machen
        this.enrichedSamplesData = this.enrichedSamplesData.map((group) => {
            const productGroup = {...group};
            // Sichtbarkeit der Produkte setzen
            productGroup.children = productGroup.children.map((product) => ({
                ...product,
                visible: true,
            }));

            // Sichtbarkeit der Produktgruppe setzen
            return {
                ...productGroup,
                visible: true,
            };
        });
    }
}
