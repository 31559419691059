// Angular-Module
import {Component, Input, OnInit, OnDestroy} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Service der übergeordneten Komponente
import {ToolbarService} from '../toolbar.service';

@Component({
    selector: 'phscw-toolbar-filter-invoices',
    templateUrl: './toolbar-filter-invoices.component.html',
    styleUrls: ['./toolbar-filter-invoices.component.scss'],
})
export class ToolbarFilterInvoicesComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;
    // Tooltip
    @Input() tooltip: string = null;
    // Icon
    @Input() icon = 'icon-clipboard';

    // Konstruktor
    constructor(private toolbarService: ToolbarService) {}

    // Initialisierungen
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    // Aufräumen
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeEventSubscriptions(): void {}

    /*
     * Daten einfügen
     */
    clickFilterInvoices() {
        this.toolbarService.filterInvoices(this.componentIdentifier);
    }
}
