// Angular-Module
import {Injectable} from '@angular/core';
// Angular-Material
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';

@Injectable({providedIn: 'root'})
export class OverlayService {
    // Subjects (Observables) definieren
    public eventOverlayInfoDialogClose = new Subject<CWEvent>();
    public eventOverlayInfoDialogOpened = new Subject<CWEvent>();
    public eventOverlayInfoCheckboxToggled = new Subject<CWEvent>();

    // Aktuelle Entity-Id
    public entityId: number;
    public additionalEntityId: number;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param backendService
     * @param dialog
     */
    constructor(
        private backendService: BackendService,
        private dialog: MatDialog,
    ) {}

    /**
     * @brief   Daten einer Person oder Einrichtung laden
     * @param   string  controller          Name des Backend-Controllers
     * @param   number  entityId            Eindeutige ID der Entität
     * @param controller
     * @param entityId
     * @param controllerMethod
     * @param additionalEntityId
     * @param   number  additionalEntityId  zusätzliche ID
     * @returns  Observable<any>
     */
    loadOverlayInformation(
        controller: string,
        entityId: number,
        controllerMethod = 'details',
        additionalEntityId: number = null,
    ): Observable<any> {
        // Speicher Kontakt-ID
        this.entityId = entityId;
        this.additionalEntityId = additionalEntityId;

        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            controller + '/' + controllerMethod + '/' + entityId,
        );
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Overlay-Info Dialog öffnen neben geklicktem Element
     * @param           clickedElement      Dokument-Node die geklickt wurde
     * @param   class   templateRef         Name der Klasse die als Dialog geöffnet wird
     * @param   string  controller          Name des Backend-Controllers
     * @param   string  controllerMethod    Name der Backend-Methode
     * @param   number  entityId            Eindeutige ID der Entität
     * @param entityId
     * @param controllerMethod
     * @param controller
     * @param templateRef
     * @param displayAdviceCheckbox
     * @param adviceCheckboxChecked
     * @param additionalEntityId
     * @param           additionalData      Zusätzliche Daten für den Dialog
     * @returns  MatDialogRef
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openOverlayInfoDialog(
        clickedElement: any,
        templateRef: any,
        controller: string,
        controllerMethod: string,
        entityId: number,
        additionalData: any = null,
        displayAdviceCheckbox = false,
        adviceCheckboxChecked = false,
        additionalEntityId: number = null,
    ): MatDialogRef<any> {
        // Position festlegen
        const element = clickedElement.getBoundingClientRect();
        // Top
        const dialogTop = element.top - 62 / 2 + element.height / 2; // obere Link-Kante - halbe Overlay-Höhe + halbe Link-Höhe
        // Left
        let dialogLeft = element.right + 20; // rechte Link-Kante + Abstand
        const dialogWidth = 400;
        const clientWidth = document.body.clientWidth;

        // Falls Dialog außerhalb des sichtbaren Bereichs ist ...
        if (dialogLeft + dialogWidth > clientWidth) {
            // ... die Position abhängig vom rechten Rand setzen
            dialogLeft = clientWidth - 20 - dialogWidth;
        }

        const dialogRef = this.dialog.open(templateRef, {
            width: dialogWidth + 'px',
            hasBackdrop: false,
            position: {
                top: dialogTop + 'px',
                left: dialogLeft + 'px',
            },
            data: {
                backendController: controller,
                backendMethod: controllerMethod,
                entityId,
                additionalEntityId,
                top: dialogTop,
                left: dialogLeft,
                linkHeight: element.height,
                additionalData,
                displayAdviceCheckbox,
                adviceCheckboxChecked,
            },
        });

        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'overlayInfoDialog',
            target: 'overlayInfoDialog',
            data: {},
        };
        // Event auslösen, damit andere Dialoge darauf reagieren können
        this.eventOverlayInfoDialogOpened.next(eventData);

        return dialogRef;
    }

    /**
     * @param data
     * @brief   Wert der Checkbox per Broadcast senden
     * @returns  Observable<any>
     */
    checkboxToggled(data: any) {
        const eventData: CWEvent = {
            sender: 'overlay-info',
            target: '',
            data,
        };
        this.eventOverlayInfoCheckboxToggled.next(eventData);
    }

    /**
     * @brief   Löse ein Event aus, um den geöffneten Dialog zu schließen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    closeCurrentOverlay(): void {
        const eventData: CWEvent = {
            sender: 'overlay-info',
            target: '',
        };
        this.eventOverlayInfoDialogClose.next(eventData);
    }
}
