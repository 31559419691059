// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host h2 {
  margin-top: 0;
}

.cw-horizontalline {
  border-color: var(--color-accent);
  height: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/popups/popup-formular-contact-filter/popup-formular-contact-filter.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,iCAAA;EACA,WAAA;AACJ","sourcesContent":[":host h2 {\n    margin-top: 0;\n}\n\n.cw-horizontalline {\n    border-color: var(--color-accent);\n    height: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
