/**
 * @brief   Popup für Kennzeichen
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */

// Angular-Module
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Shared Services importieren
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Service für das Kennzeichen-Modul
import {CharacteristicsService} from './../characteristics.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';

// Interface für Dialogdaten
export interface DialogData {
    selectedEntities: any[];
    characteristicType: string;
    multi: boolean;
    showReadonly: boolean;
    showConfirmationDialog: boolean;
    showDeleteButton: boolean;
}

@Component({
    selector: 'phscw-characteristic-groups-list-popup',
    templateUrl: './characteristic-groups-list-popup.component.html',
    styleUrls: ['./characteristic-groups-list-popup.component.scss'],
})
export class CharacteristicGroupsListPopupComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // für Kennzeichen ausgewählte Entitäten
    selectedEntities: any[] = [];
    // Kennzeichentyp
    characteristicType: string;
    // Mehrere Kennzeichengruppen anzeigen
    multi: boolean;
    // Flag definiert ob Kennzeichen, die nicht bearbeitet werden können, auch angezeigt werden sollen
    showReadonly = true;
    // Flag definiert ob Bestätigungsdialoge angezeigt werden sollen
    showConfirmationDialog = true;
    // Flag definiert ob Löschbutton angezeigt werden sollen
    showDeleteButton = false;
    // Flag definiert ob Checkbox angezeigt werden sollen (zum Löschen in Popup)
    showCheckbox = true;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param data
     * @param dialogRef
     * @param toolbarService
     * @param characteristicsService
     */
    constructor(
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private dialogRef: MatDialogRef<CharacteristicGroupsListPopupComponent>,
        private toolbarService: ToolbarService,
        private characteristicsService: CharacteristicsService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Daten für Template zwischenspeichern
        this.selectedEntities = this.data.selectedEntities;
        this.characteristicType = this.data.characteristicType;
        this.multi = this.data.multi;
        this.showReadonly = this.data.showReadonly;
        this.showConfirmationDialog = this.data.showConfirmationDialog;
        this.showDeleteButton = this.data.showDeleteButton;
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions() {
        // Wenn der Close-Button der Toolbar ausgelöst wurde, Abbrechen auslösen.
        this.toolbarService.eventCloseComponent
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht für die eigene Komponente ist
                if (event.target !== 'characteristic-groups-list-popup') {
                    return;
                }
                this.clickClose();
            });
    }

    /**
     * @brief   Dialog schließen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickClose(): void {
        this.dialogRef.close();
        // Daten in Kennzeichenmodul neu laden
        this.characteristicsService.dataChanged('characteristic-groups-list-popup', {});
    }
}
