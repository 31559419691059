// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// GridComponent
import {GridComponent} from './../grid/grid.component';
// Service dieses Shared-Moduls
import {GridService} from './../grid.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FilterData} from './../../filter-data';

@Component({
    selector: 'phscw-grid-sort',
    templateUrl: './grid-sort.component.html',
    styleUrls: ['./grid-sort.component.scss'],
})
export class GridSortComponent implements OnInit, OnDestroy {
    /**
     * *************************************************************************
     * Parameter, welche beim Einbinden der Komponente gesetzt werden
     *************************************************************************
     */
    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;
    // ID des (verbundenen) Grids
    @Input() gridId = '';

    // Ist diese Grid-Extension sichtbar?
    @Input() visible = false;

    // Sortier-Felder
    @Input() sortFields: FilterData[] = [];

    // Der Key des zuletzt aktiven Sortierfelds, wird immer mit notiert, damit er wieder genutzt werden kann, wenn zwischenzeitlich nach Spalten-Headern sortiert wurde
    private lastSortFieldKey = '';

    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Konstruktor
    constructor(private gridService: GridService) {}

    // Initialisierungen
    ngOnInit() {
        // Prüfe Anforderungen
        this.checkRequirements();
        this.initializeEventSubscriptions();
        // akviten Sortier-Key merken
        const currentSortField = this.sortFields.find((f) => f.active === true);
        if (typeof currentSortField !== 'undefined') {
            this.lastSortFieldKey = currentSortField.key;
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    // Prüfe Anforderungen
    checkRequirements() {
        // Diese Grid-Erweiterung benötigt ein verbundenes Grid
        if (typeof this.gridConnection === 'undefined') {
            console.error(
                'Pharmakon - GridSortComponent verfügt über kein verbundenes Grid und kann deshalb nicht funktionieren. Bitte beim Einbinden der Komponente die [gridConnection] setzen!',
            );
        }
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // reagiere darauf, wenn die Sortierung nach Spalten-Headern an/ausgeschaltet wurde
        this.gridService.eventHeaderSortStateChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid kam
                if (event.target !== this.gridId) {
                    return;
                }
                if (event.data === true) {
                    // Sortierbuttons abwählen, Grid-Header hat Steuerung übernommen
                    this.sortFields.forEach((f) => (f.active = false));
                } else {
                    // zuletzt aktive Button-Sortierung wiederherstellen
                    const currentSortField = this.sortFields.find((f) => f.key === this.lastSortFieldKey);
                    if (typeof currentSortField !== 'undefined') {
                        currentSortField.active = true;
                        this.gridConnection.gridSort = currentSortField;
                    }
                }
            });
    }

    /**
     *  Klick auf Sortier-Button
     * @param sortFieldKey
     */
    clickSort(sortFieldKey: string) {
        let currentSortfield: FilterData;

        for (const sortfield of this.sortFields) {
            // Handelt es sich um den Key?
            if (sortfield.key != sortFieldKey) {
                // Alle anderen Sortierfelder sind nicht aktiv
                sortfield.active = false;
            } else if (
                Object.prototype.hasOwnProperty.call(sortfield, 'lockedDirection') &&
                sortfield.lockedDirection !== null &&
                typeof sortfield.lockedDirection !== 'undefined' &&
                sortfield.lockedDirection !== ''
            ) {
                // Falls eine Sortierrichtung festgesetzt wurde, soll immer diese gesetzt werden
                sortfield.direction = sortfield.lockedDirection;
                sortfield.active = true;
            } else {
                // Ansonsten kann sie durch anklicken geändert werden
                if (sortfield.active == true) {
                    // Falls dieses Sortierfeld bereits aktiv war, wird die Richtung geändert...
                    if (sortfield.direction == 'asc') {
                        sortfield.direction = 'desc';
                    } else {
                        sortfield.direction = 'asc';
                    }
                } else {
                    /*
                     * ...andernfalls wird das Sortierfeld nun aktiviert
                     * Wurde eine Startrichtung festgelegt?
                     */
                    if (
                        Object.prototype.hasOwnProperty.call(sortfield, 'initialDirection') &&
                        sortfield.initialDirection !== null &&
                        typeof sortfield.initialDirection !== 'undefined' &&
                        sortfield.initialDirection !== ''
                    ) {
                        sortfield.direction = sortfield.initialDirection;
                    } else {
                        sortfield.direction = 'asc';
                    }
                    sortfield.active = true;
                }
                currentSortfield = sortfield;
                // akviten Sortier-Key merken
                this.lastSortFieldKey = sortFieldKey;
            }
        }

        // GridService informieren (damit dort Event ausgelöst werden kann --> damit in übergeordneten Modulen z.B. die Settings gespeichert werden können)
        this.gridService.sortChanged(this.gridId, currentSortfield);

        // Info zur geänderten Sortierung über gridConnection direkt weiterreichen
        this.gridConnection.gridSort = currentSortfield;
    }
}
