// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/**
 * Component-CSS für Öffnungszeitenmodul
 * ===================================
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
:host .cw-submodule {
  flex: 1 0 calc(50% - 10px);
}

:host .cw-weekend-break {
  flex-basis: 100%;
}

:host .cw-weekend-break .cw-horizontalline {
  width: calc(50% - 10px);
}

:host .cw-weekend-break phscw-input-text {
  width: calc(50% - 10px);
}

:host phscw-input-time ::ng-deep .cw-time-input-wrapper {
  padding-right: 40px;
}

:host phscw-input-time ::ng-deep .cw-input-label,
:host phscw-input-date ::ng-deep .cw-input-label {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 0;
  width: auto;
}

:host phscw-input-time ::ng-deep .cw-input {
  position: relative;
}

:host phscw-input-time ::ng-deep .cw-delete {
  margin: 0;
  position: absolute;
  right: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/institutions/institutions-openinghours/institutions-openinghours.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;;;EAAA;AAOA;EACI,0BAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;;EAEI,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,SAAA;EACA,kBAAA;EACA,WAAA;AACJ","sourcesContent":["/**\n * Component-CSS für Öffnungszeitenmodul\n * ===================================\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n */\n\n:host .cw-submodule {\n    flex: 1 0 calc(50% - 10px);\n}\n\n:host .cw-weekend-break {\n    flex-basis: 100%;\n}\n\n:host .cw-weekend-break .cw-horizontalline {\n    width: calc(50% - 10px);\n}\n\n:host .cw-weekend-break phscw-input-text {\n    width: calc(50% - 10px);\n}\n\n:host phscw-input-time ::ng-deep .cw-time-input-wrapper {\n    padding-right: 40px;\n}\n\n:host phscw-input-time ::ng-deep .cw-input-label,\n:host phscw-input-date ::ng-deep .cw-input-label {\n    margin-left: 5px;\n    margin-right: 5px;\n    min-width: 0;\n    width: auto;\n}\n\n:host phscw-input-time ::ng-deep .cw-input {\n    position: relative;\n}\n\n:host phscw-input-time ::ng-deep .cw-delete {\n    margin: 0;\n    position: absolute;\n    right: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
