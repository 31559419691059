// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .cw-input-span {
  font-size: unset;
}
:host ::ng-deep .cw-input-label {
  align-self: flex-start;
}

.error-dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.message {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;
}

.cw-horizontalline {
  border-color: var(--color-accent);
  height: 2px;
}

.settingsPopupWidth {
  min-width: 242px;
}

::ng-deep .mat-mdc-dialog-container {
  background: var(--color-default-light);
}

.clipboard {
  margin-right: auto;
}

.buttons {
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-error-popup/global-error-popup.component.scss"],"names":[],"mappings":"AAEQ;EACI,gBAAA;AADZ;AAIQ;EACI,sBAAA;AAFZ;;AAOA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAJJ;;AAOA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,aAAA;AAJJ;;AAOA;EACI,iCAAA;EACA,WAAA;AAJJ;;AAOA;EACI,gBAAA;AAJJ;;AAOA;EACI,sCAAA;AAJJ;;AAOA;EACI,kBAAA;AAJJ;;AAOA;EACI,mBAAA;AAJJ","sourcesContent":[":host {\n    ::ng-deep {\n        .cw-input-span {\n            font-size: unset;\n        }\n\n        .cw-input-label {\n            align-self: flex-start;\n        }\n    }\n}\n\n.error-dialog {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.message {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    margin: 8px 0;\n}\n\n.cw-horizontalline {\n    border-color: var(--color-accent);\n    height: 2px;\n}\n\n.settingsPopupWidth {\n    min-width: 242px;\n}\n\n::ng-deep .mat-mdc-dialog-container {\n    background: var(--color-default-light);\n}\n\n.clipboard {\n    margin-right: auto;\n}\n\n.buttons {\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
