// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */
:host .cw-submodule {
  width: 350px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/institutions/institutions-sales/institutions-sales.component.scss"],"names":[],"mappings":"AAAA;;;;;EAAA;AAOA;EACI,YAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Massimo Feth <m.feth@pharmakon.software>\n */\n\n:host .cw-submodule {\n    width: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
