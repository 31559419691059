// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card {
  margin-bottom: 20px;
  margin-top: 10px;
}

.cw-notification-icon {
  font-size: 28px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/input/input-form-notification/input-form-notification.component.scss"],"names":[],"mappings":"AAWA,0HAAA;AACA;EACI,mBAAA;EACA,gBAAA;AAVJ;;AAaA;EACI,eAAA;AAVJ","sourcesContent":["// falls formular mehr wie popup funktionieren soll\n// :host mat-card {\n//     cursor: default;\n//     font-size: 1.4rem;\n//     padding: 0;\n//     position: absolute;\n//     text-align: left;\n//     width: 550px;\n//     z-index: 300;\n// }\n\n/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/\nmat-card {\n    margin-bottom: 20px;\n    margin-top: 10px;\n}\n\n.cw-notification-icon {\n    font-size: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
