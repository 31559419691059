// Angular-Module
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
// Globale Services einbinden
import {AppCoreService} from './../../services/app-core.service';
import {AuthService} from './../../services/auth.service';

@Component({
    selector: 'phscw-global-logout',
    templateUrl: './global-logout.component.html',
    styleUrls: ['./global-logout.component.scss'],
})
export class GlobalLogoutComponent implements OnInit, OnDestroy {
    // Konstruktor
    constructor(
        private appCore: AppCoreService,
        private authService: AuthService,
        private router: Router,
    ) {}

    // Initialisierung der Komponente
    ngOnInit() {}

    // Aufräumen
    ngOnDestroy() {}

    /**
     * @brief   Klick auf Button "Logout"
     * @details Loggt den Anwender über "authService" aus und leitet
     *          zurück auf Loginseite.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    clickLogout() {
        this.authService.invalidateRefreshToken();
        // Logout durchführen
        this.authService.logout();
        // Zur Loginseite wechseln
        this.router.navigate(['/login']);
        /*
         * Globales Event auslösen
         * this.appEvents.appLogout.emit(true);
         */
        this.appCore.appLogout.next(true);
    }
}
