// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#personen {
  text-align: center;
  font-size: 18px;
  width: 60%;
  left: 50%;
  padding: 10px;
  top: 30%;
  transform: translate(-50%, -50%);
  position: absolute;
}

#einrichtungen {
  text-align: center;
  font-size: 18px;
  padding: 10px;
  width: 60%;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
  position: absolute;
}

p {
  font-size: 32px;
  margin-top: 10px;
}

#outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/chart/chart-conducted-trainings/chart-conducted-trainings.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;EACA,UAAA;EACA,SAAA;EACA,aAAA;EACA,QAAA;EAGA,gCAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,eAAA;EACA,aAAA;EACA,UAAA;EACA,SAAA;EACA,QAAA;EAGA,gCAAA;EACA,kBAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;AACJ;;AACA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAEJ","sourcesContent":["#personen {\n    text-align: center;\n    font-size: 18px;\n    width: 60%;\n    left: 50%;\n    padding: 10px;\n    top: 30%;\n    -webkit-transform: translate(-50%, -50%);\n    -moz-transform: translate(-50%, -50%);\n    transform: translate(-50%, -50%);\n    position: absolute;\n}\n\n#einrichtungen {\n    text-align: center;\n    font-size: 18px;\n    padding: 10px;\n    width: 60%;\n    left: 50%;\n    top: 70%;\n    -webkit-transform: translate(-50%, -50%);\n    -moz-transform: translate(-50%, -50%);\n    transform: translate(-50%, -50%);\n    position: absolute;\n}\n\np {\n    font-size: 32px;\n    margin-top: 10px;\n}\n#outer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
