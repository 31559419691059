import {Component, Input, OnInit} from '@angular/core';
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';
import {notEmpty} from '@shared/utils';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'phscw-output-additional-data',
    templateUrl: './output-additional-data.component.html',
    styleUrls: ['./output-additional-data.component.scss'],
})
export class OutputAdditionalDataComponent implements OnInit {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gridColumns: any[] = [
        {
            columnDef: 'row_label',
            header: 'GF',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            cell: (element: any) => `${element.row_label}`,
        },
    ];

    gridDisplayedColumns = ['row_label'];
    @Input() entity_id = 0;
    @Input() data_type = '';
    @Input() entity_type = '';
    private init = false;
    constructor(
        private initService: InitService,
        private storageService: StorageService,
    ) {}

    maxDate = null;

    private config = [];

    ngOnInit(): void {
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            this.loadConfig();
        });
        this.loadConfig();
    }

    loadConfig() {
        if (this.init) {
            return;
        }
        // laden des intervalls
        const promise = this.storageService.getItem('config|additionalDataColumns');
        promise.then((additionalDataColumns) => {
            if (!additionalDataColumns || !notEmpty(additionalDataColumns)) {
                return;
            }
            this.config = additionalDataColumns[this.data_type]['columns'];
            for (const [key, value] of Object.entries(this.config)) {
                const colName = 'AdditionalDataRowsCol_' + key + '_column_value';
                // Check if the columnDef already exists in the gridColumns array
                const columnExists = this.gridColumns.some((column) => column.columnDef === colName);
                // If the columnDef does not exist, push the new column
                if (!columnExists) {
                    this.gridColumns.push({
                        columnDef: colName,
                        header: value.label,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        cell: (element: any) => `${element[colName]}`,
                        formatWidth: '80px',
                        formatTemplate: value.format,
                    });
                    this.gridDisplayedColumns.push(colName);
                }
            }
            this.init = true;
        });
    }

    // Geänderte griddaten prüfen um create date zu bergen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gridDataChanged(gridData: any) {
        // Extracting create_date values into an array
        const createDates = gridData.map((item) => item.AdditionalDataRowsCol_create_date_column_value);

        // Converting date strings to Date objects for accurate comparison
        const dateObjects = createDates.map((dateString) => new Date(dateString));

        // Finding the maximum and minimum dates
        const maxDate = new Date(Math.max.apply(null, dateObjects));

        this.maxDate = maxDate;
    }
}
