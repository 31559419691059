import {Pipe, PipeTransform} from '@angular/core';

/**
 * @brief   Diese Pipe ist für die Darstellung der Werte der Input-Multiselect-Komponente verantwortlich.
 * @author  Michael Schiffner <m.schiffner@pharmakon.software>
 */

@Pipe({name: 'isUndefined'})
export class IsUndefinedPipe implements PipeTransform {
    /**
     * @brief   transform-Funktion von PipeTransform implementieren
     * @details Nimmt alle möglichen Multi-Select-Einträge und die Ids der gewählten
     * @param value
     *          Einträge entgegen, durchläuft sie und verbindet die Labels zu einem gesamt-String
     * @param   SelectData[]   selectData   Die Auswahlmöglichkeiten des Multi-Selects
     * @param   number[]       ids          Die Ids der gewählten Einträge in einem Array
     * @returns  string
     */
    transform(value: any): boolean {
        return (
            typeof value === 'undefined' ||
            value === null ||
            (Object.keys(value).length === 0 && value.constructor === Object)
        );
    }
}
