// Angular-Module
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
// ReactiveX for JavaScript

@Component({
    selector: 'phscw-input-multiple-autocomplete',
    templateUrl: './input-multiple-autocomplete.component.html',
    styleUrls: ['./input-multiple-autocomplete.component.scss'],
    // Um Daten an übergeordnete Form übergeben zu können (siehe https://stackblitz.com/edit/angular-ua5xcz?file=app/address.component.ts)
    viewProviders: [{
        provide: ControlContainer,
        useExisting: NgForm,
    }],
})
export class InputMultipleAutocompleteComponent implements OnInit, OnDestroy {
    // EditMode aktiv?
    @Input() editMode = false;
    // Auswahl erlaubt?
    @Input() allowSelection = false;
    // Hinzufügen erlaubt?
    @Input() allowAddition = false;
    // RadioButton anzeigen?
    @Input() displayRadioButton = true;
    // In Ansichtsmodus verstecken, wenn leer?
    @Input() hideEmpty = true;
    // In Ansichtsmodus verstecken, wenn ausgewählt?
    @Input() hideChecked = false;
    // Mindestlänge des Suchbegriffs (Standard: 3 Zeichen)
    @Input() minimumSearchInputLength = 3;
    // ID des Inputfelds
    @Input() inputId: string;
    // Name der ngModelGroup
    @Input() formModelGroup: string;
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string;
    // Zusätzliche Optionen, die bei Bedarf ans Backend mitgegeben werden sollen.
    @Input() optionalRestrictions: any = {};

    // Attribut: required = Pflichtfeld (ja / nein)
    @Input() required = false;
    // Attribut: disabled = Gesperrt (ja / nein)
    @Input() disabled = false;

    // BackendController/Funktion die für die Suche aufgerufen werden sollen
    @Input() backendLink = '';

    // bereits vorhandene Daten
    @Input() data: any[] = [];

    // Event-Emitter
    @Output() optionSelected = new EventEmitter<any>();
    @Output() elementRemoved = new EventEmitter<any>();

    /**
     * Konstruktor (inkl. dependency injection)
     */
    constructor() {}

    /**
     * Initialiseren
     */
    ngOnInit() {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * RADIO: Handelt den Klick auf einen Radio Button
     * @param row
     */
    radioToggle(row: any): void {
        // Bei allen Elementen die Markierung entfernen
        this.data.forEach((el) => {
            el.checked = 'false';
        });

        // Angeklicktes Element markieren
        row.checked = 'true';
    }

    /**
     * auf Auswahl eines Ergebnisses reagieren
     * @param elementIndex
     * @param event
     * @param event.id
     * @param event.label
     */
    onOptionSelected(elementIndex: number, event: {id: number; label: string}): void {
        // Objekte zusammenführen
        this.data[elementIndex] = Object.assign(this.data[elementIndex], event);

        // Daten nach außen geben
        this.optionSelected.emit({
            index: elementIndex,
            data: event,
        });
    }

    /**
     * neue Reihe hinzufügen
     */
    addElement(): void {
        this.data.push({checked: 'false'});
    }

    /**
     * bestehende Reihe entfernen
     * @param elementIndex
     */
    removeElement(elementIndex: number): void {
        // Prüfe, ob das zu löschende Element das Hauptelement ist
        if (this.data[elementIndex].checked === 'false') {
            // Element am angegebenen Index löschen
            this.data.splice(elementIndex, 1);

            // Daten nach außen geben
            this.elementRemoved.emit({
                index: elementIndex,
                data: null,
            });
        }
    }
}
