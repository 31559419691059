// Angular-Module
import {Injectable} from '@angular/core';
// // ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
import {AppCoreService} from './../../services/app-core.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';

@Injectable({
    // Root-Injector (app.module.ts) ist verantwortlich für das Instanziieren dieses globalen Services
    providedIn: 'root',
})
/**
 * @brief   Globaler Service für Menü.
 * @author  Olga Salomatina <o.salomatina@pharmakon.software>
 */
export class GlobalMenuService {
    // Aufklappen/Einklappen Icon wurde geklickt
    public eventGlobalMenuToggleClicked = new Subject<CWEvent>();

    // Konstruktor
    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
    ) {}

    /**
     * @brief   Daten für Menü aus Backend laden.
     * @returns  Observable<any>
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     */
    loadData(): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequestAsync('Menu/index');
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Löst Event aus für Menü auf- oder zuklappen
     * @param   string  menu
     * @param menu
     * @param menuExpanded
     * @param   boolean menuExpanded
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    globalMenuToggled(menu: string, menuExpanded: boolean) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: menu,
            target: '',
            data: {menuExpanded},
        };
        // Event auslösen
        this.eventGlobalMenuToggleClicked.next(eventData);
    }
}
