/**
 * @brief   Repräsentation einer Benachrichtigung in C-World 4
 * @details Dies entspricht im Wesentlichen einer Entity in der
 *          Datenbanktabelle "notifications"
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
export class Notification {
    id: number;
    employee_id?: number;

    entity_type?: any;
    entity_id?: number;

    notification: string;
    notification_type: string;
    valid_from?: any;
    valid_to?: any;

    // wird im backend nur gespeichert wenn active ist
    active = false;

    create_date?: any;
    create_user?: any;

    edit_date?: any;
    edit_user?: any;

    delete_date?: any;
    delete_user?: any;
    deleted?: any;

    constructor() {
        this.id = 0;
        this.notification = '';
    }
}
