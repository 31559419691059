// Angular-Module
import {Routes} from '@angular/router';

// Globale Komponenten einbinden, die durch das Routing direkt angezeigt werden
import {GlobalLoginComponent} from './../../components/global-login/global-login.component';

// Definiere Routen, die ohne Authentifizierung aufgerufen werden können
export const PUBLIC_ROUTES: Routes = [
    // Default-Route
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full' as const,
    },
    // Route "login"
    {
        path: 'login',
        component: GlobalLoginComponent,
    },
    {
        path: 'sso',
        component: GlobalLoginComponent,
    },
];
