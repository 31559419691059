// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class InputHtmlEditorService {
    // Konstruktor (inkl. dependency injection)
    constructor(private backendService: BackendService) {}

    /**
     * @param formData
     * @brief   Bild hochladen
     * @todo    allgemeinen Controller explizit für ImageUpload erstellen
     * @returns  Observable<any>
     */
    uploadImage(formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('HelpContent/uploadImage/', formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
