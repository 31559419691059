// Angular-Module
import {Component, Input, OnInit} from '@angular/core';
// Interfaces für Structured Objects einbinden
import {SelectionFilter} from '@shared/selection-filter';
// Eigenen Service einbinden
import {GridSelectionService} from './../../grid-selection.service';
// Moment-Modul zur Datums-Verarbeitung
import * as _moment from 'moment';

const moment = _moment;

@Component({
    selector: 'phscw-grid-selection-filterbutton',
    templateUrl: './grid-selection-filterbutton.component.html',
    styleUrls: ['./grid-selection-filterbutton.component.scss'],
})
export class GridSelectionFilterbuttonComponent implements OnInit {
    @Input() filterButton: SelectionFilter;

    constructor(private gridSelectionService: GridSelectionService) {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ngOnInit() {}

    deleteFilter() {
        this.gridSelectionService.filterDeleted(this.filterButton);
    }

    reopenFilter() {
        this.gridSelectionService.reopenPopup(this.filterButton);
    }

    /**
     * Überprüft ob übergebener string dem korrekten Format entspricht
     * @param {string} date
     * @returns {boolean}
     */
    isDate(date: string) {
        return moment(date, 'dd.MM.yyyy', true).isValid();
    }
}
