// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
import {StorageService} from '@global/services/storage.service';
// Interfaces für Structured Objects einbinden
import {Institution} from '@shared/institution';
// Environment einbinden
import {environment} from '@environment';

@Injectable({providedIn: 'root'})
export class InstitutionsService {
    // Ausgewählte Einrichtung
    public selectedInstitution: Institution;

    // Subject (Observable) definieren "Es wurde eine andere Einrichtung ausgewählt"
    public selectionChanged = new Subject<number>();

    public institutionIsInOwnRegion = false;
    public allowEditInstitution = false;
    public allowEditCombined = false;
    public allowEditForeignInstitution = false;

    // Ob Formular benutzt werden soll, Änderungsanfragen abzuschicken
    public isShadow = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param storageService
     * @param backendService
     */
    constructor(
        private storageService: StorageService,
        private backendService: BackendService,
    ) {}

    /**
     * @param selectedInstitution
     * @param isShadow
     * @brief   Wird aufgerufen, falls eine andere Einrichtung ausgewählt werden soll
     */
    selectInstitution(selectedInstitution: Institution, isShadow = false): void {
        // Ausgewählte Einrichtung zwischenspeichern
        this.selectedInstitution = selectedInstitution;
        this.isShadow = isShadow;
        // Event auslösen um andere Komponenten des Feature-Moduls zu informieren
        this.selectionChanged.next(this.selectedInstitution.id);
    }

    /**
     * @brief   Wird aufgerufen, falls die Auswahl "geleert" werden soll
     */
    selectEmptyInstitution(): void {
        this.selectedInstitution = null;
        this.selectionChanged.next(-1);
    }

    /*
     * @brief   Wird aufgerufen, um Icons der Einrichtung zu aktualisieren
     */
    updateInstitutionIcons(iconString: string): void {
        if (this.selectedInstitution !== undefined && this.selectedInstitution !== null) {
            this.selectedInstitution.icons = iconString;
        }
    }

    /**
     * @param institutionId
     * @brief   Starte Filterung für Personen einer Einrichtung
     * @details Dies wird z.B. aufgerufen, wenn in der E-Liste das Personenicon
     *          angeklickt wurde um zu den Personen der Einrichtung zu
     *          wechseln ODER um nach der Neuanlage einer Person die Auswahl
     *          in der P-Liste (Alle Personen der Einrichtung) neu zu laden.
     * @see     Diese Filter-Funktion existiert auch in people.service.ts,
     *          da sowohl in E-Modulen als auch P-Modulen benötigt.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    executeFilterPeopleOfInstitution(institutionId: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'PeopleInstitutions/filterBy/institution/' + institutionId,
        );
        // Observable (an Komponente) zurückliefern
        return getRequest$;
    }

    /**
     * @param personId
     * @brief   Starte Filterung auf eine ausgewählte Person
     * @details Dies wird z.B. aufgerufen, wenn im Grid der E-Ansprechpartner
     *          das Personenicon einer Person geklickt wurde um zu dieser
     *          ausgewählten Person zu wechseln.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    executeFilterPerson(personId: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'PeopleInstitutions/select/person/' + personId,
        );
        // Observable (an Komponente) zurückliefern
        return getRequest$;
    }

    /**
     * @brief   Prüfen, ob Segmente als Feature aktiviert sind und Wert mit Berechtigung kombinieren
     * @param permission
     * @details Diese Überprüfung wird durchgeführt in Komponenten "details" und "list"
     * @param   boolean permissions Wert der Berechtigung, mit der kombiniert werden soll
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkSegmentsFeatureEnabled(permission: boolean): boolean {
        // Init
        let returnValue = false;

        // Konfiguration prüfen - Segmente werden im Frontend und Backend aktiviert
        if (
            Object.prototype.hasOwnProperty.call(environment, 'regionSegmentsEnabled') &&
            environment.regionSegmentsEnabled &&
            environment.institutionsDatafields &&
            environment.institutionsDatafields.zipcode &&
            environment.institutionsDatafields.zipcode.required &&
            permission === true
        ) {
            /*
             * Wenn Segmente aktiviert sind und die Postleitzahl ein Pflichtfeld
             * ist, können unabhängig von der ausgewählten Region Einrichtungen
             * angelegt werden, da die Regionszuordnung anhand der Postleitzahl
             * und des zugehörigen Segments gesetzt wird
             */
            returnValue = true;
        }

        // Antwort zurückgeben
        return returnValue;
    }

    /**
     * @brief   Prüfen, ob Segmente als Feature aktiviert sind und Wert mit Berechtigung kombinieren
     * @param permission
     * @param division
     * @param region
     * @param regionLevel
     * @details Diese Überprüfung wird durchgeführt in Komponenten "details" und "list"
     * @param   boolean permissions Wert der Berechtigung, mit der kombiniert werden soll
     * @todo    segmentsEnabled aus Backend lesen statt eigene Variable in environment zu haben
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkNewEntityAllowed(permission: boolean, division: number, region: number, regionLevel: number): boolean {
        // Init
        let returnValue = false;

        // Konfiguration prüfen - Segmente werden im Frontend und Backend aktiviert
        if (
            Object.prototype.hasOwnProperty.call(environment, 'regionSegmentsEnabled') &&
            environment.regionSegmentsEnabled &&
            environment.institutionsDatafields &&
            environment.institutionsDatafields.zipcode &&
            environment.institutionsDatafields.zipcode.required &&
            permission === true
        ) {
            /*
             * Wenn Segmente aktiviert sind und die Postleitzahl ein Pflichtfeld
             * ist, können unabhängig von der ausgewählten Region Einrichtungen
             * angelegt werden, da die Regionszuordnung anhand der Postleitzahl
             * und des zugehörigen Segments gesetzt wird. Ergebnis wird sofort
             * zurückgegeben
             */
            return true;
        }

        // Definiert wieviel Regionslevel (zusätzlich zur Linie) im globalen Regionsfilter angezeigt werden. Standard = 2 (Region & Gebiet)
        let globalRegionsfilterLevels = 2;
        if (Object.prototype.hasOwnProperty.call(environment, 'globalRegionsfilterLevels')) {
            globalRegionsfilterLevels = environment.globalRegionsfilterLevels;
        }

        // Standard: Linie - Region - Gebiet
        if (globalRegionsfilterLevels == 2) {
            if (division > 0 && region > 0 && regionLevel > 1 && permission === true) {
                returnValue = true;
            } else {
                returnValue = false;
            }
        }

        // Linie - Region
        if (globalRegionsfilterLevels == 1) {
            if (division > 0 && region > 0 && permission === true) {
                returnValue = true;
            } else {
                returnValue = false;
            }
        }

        // Antwort zurückgeben
        return returnValue;
    }

    /**
     * @param data
     * @param allowEditInstitution
     * @param allowEditForeignInstitution
     * @param allowEditCombined
     * @brief   Prüfen, ob der Mitarbeiter eine Einrichtung im eigenen Gebiet ausgewählt hat.
     * @author  Daniel Nita <d.nita@pharmakon.software>
     */
    checkInstitutionOwnRegion(
        data: any,
        allowEditInstitution: boolean,
        allowEditForeignInstitution: boolean,
        allowEditCombined: boolean,
    ): any {
        this.allowEditInstitution = allowEditInstitution;
        this.allowEditForeignInstitution = allowEditForeignInstitution;
        const promise = this.storageService.getItem('ownUser');
        promise.then((userData: any) => {
            // Region zwischenspeichern
            const ownRegionId = userData.region_id;
            // Prüfen, ob die Einrichtung im eigenen Gebiet ist
            if (data.regions.some((region: any) => ownRegionId === region.id)) {
                this.institutionIsInOwnRegion = true;
            } else {
                this.institutionIsInOwnRegion = false;
            }
        });
        this.allowEditCombined = allowEditCombined;
        return promise;
    }
}
