// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from './../../../services/backend.service';

@Injectable({providedIn: 'root'})
export class GlobalHelpTextService {
    /**
     * Konstruktor (inkl. dependency injection)
     * @param backendService
     */
    constructor(private backendService: BackendService) {}

    /**
     * @brief   Lädt Daten eines Eintrags aus dem Backend
     * @param entryId
     * @param version
     * @param   entryID number  ID des Eintrags
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    loadEntry(entryId: number, version: number = null): Observable<any> {
        // Request-URL
        let url = 'HelpContent/details/' + entryId;
        if (version !== null) {
            url += '/' + version;
        }

        // POST-Request über BackendService senden
        const request$: Observable<any> = this.backendService.getRequest(url);
        // Observable (an Komponente) zurücklieferen
        return request$;
    }

    /**
     * @brief   Lädt Menüdaten für Historie des ausgewählten Eintrags
     * @param entryId
     * @param   entryID number  ID des Eintrags
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadHistory(entryId: number): Observable<any> {
        // POST-Request über BackendService senden
        const request$: Observable<any> = this.backendService.getRequest('HelpContent/getHistory/' + entryId);
        // Observable (an Komponente) zurücklieferen
        return request$;
    }

    /**
     * @param id
     * @param formData
     * @brief   Hilfeintrag speichern
     * @returns  Observable<any>
     */
    saveEntry(id: number, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('HelpContent/edit/' + id, formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param formData
     * @brief   Hilfeintrag löschen
     * @returns  Observable<any>
     */
    deleteEntry(formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('HelpContent/delete/', formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
