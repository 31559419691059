/**
 * @brief   Globaler Service zum Initialisieren von C-World
 * @details Initialisierungen, die nach dem Login passieren sollten, sollen hier
 *          definiert werden.
 *          Über diesen Service werden nacheinander die Daten vom Backend
 *          angefordert, die im Frontend gespeichert werden sollen. Dazu gehören
 *          z.B. Listentries oder die zur Verfügung stehenden Kennzeichen.
 * @author  Michael Schiffner <m.schiffner@pharmakon.software>
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SafeHtml} from '@angular/platform-browser';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Globale Services einbinden
import {AppCoreService} from './app-core.service';
import {BackendService} from './backend.service';
import {StorageService} from './storage.service';
// Interfaces für Structured Objects einbinden
import {Listentry} from '@shared/listentry';
import {FutureMonitor} from '@shared/future-monitor';
import {takeUntil} from 'rxjs/operators';
import {hasOwn} from '@shared/utils';

const ALL_INITIALIZED_KEY = 'allInitialized';

@Injectable({providedIn: 'root'})
export class InitService {
    // Konstruktor
    constructor(
        private http: HttpClient,
        private appCore: AppCoreService,
        private backendService: BackendService,
        private storageService: StorageService,
    ) {}

    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Informiert die Interessenten, sobald alle Initialisierungen nach dem Login abgeschlossen sind (push)
    public allInitialized = new Subject<boolean>();

    // Stellt den aktuellen Initialisierungsstatus anwendungsweit zur Verfügung (pull)
    get initialized(): boolean {
        return /true/i.test(window.localStorage.getItem(ALL_INITIALIZED_KEY));
    }

    // setter schreibt den Status in die Browser-Session, damit er bei einem Page-Reload erhalten bleibt
    set initialized(value: boolean) {
        window.localStorage.removeItem(ALL_INITIALIZED_KEY);
        window.localStorage.setItem(ALL_INITIALIZED_KEY, value.toString());
    }

    /*
     * @brief   Initialisierungs-Funktion, die nach erfolgreichem Login
     *          aufgerufen wird.
     * @details Fordert nacheinander die Daten zur Speicherung im Frontend
     *          ab. Dazu gehören z.B. Listentries oder die zur Verfügung
     *          stehenden Kennzeichen.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     *          Michael Schiffner <m.schiffner@pharmakon.software>
     *          Tristan Krakau <t.krakau@pharmakon.software>
     */
    initializeAll(): void {
        // Status zurücksetzen, z.B. bei erneutem Login, dann existiert das Service-Objekt (Singleton) schon
        this.initialized = false;

        // Storage Service initialisieren
        this.storageService.initialize();

        /*
         * Alle Requests an das Backend bei der Initialisierung werden parallel ausgeführt,
         * um möglichst schnell nach dem Login alle Daten zu erhalten.
         * Der FutureMonitor überwacht dabei alle asynchronen Requests und auch die Promises beim Schreiben der Ergebnisse in den Storage.
         * Er startet Requests asynchron und führt Buch über die aktuell laufenden Anfragen, wenn alle beendet sind, informiert er den Observer.
         */
        const futureMonitor = new FutureMonitor(this.allInitialized, this.backendService);

        // Backendkonfiguration für das Frontend laden und in den Storage packen
        futureMonitor.addRequest('Config/initializeAll', (result: any) => {
            for (const [key, value] of Object.entries(result['data'])) {
                futureMonitor.addPromise(this.storageService.setItem('config|' + key, value));
            }
        });

        // Laden der konfig struktur
        const loadStructure = ['People', 'Institutions', 'PeopleInstitutions'];
        for (const schema of loadStructure) {
            futureMonitor.addRequest(`Config/getSchema/${schema}`, (result: any) => {
                futureMonitor.addPromise(this.storageService.setItem(schema + 'Schema', result.data));
            });
        }

        // Listentries laden
        futureMonitor.addRequest('Listentries/initializeAll', (result: any) => {
            // Geladene Listen nacheinander durchgehen
            for (const currentList in result['data']) {
                // https://eslint.org/docs/latest/rules/guard-for-in
                if (Object.prototype.hasOwnProperty.call(result['data'], currentList)) {
                    // Liste im Storage speichern
                    futureMonitor.addPromise(
                        this.storageService.setItem('listentries|' + currentList, result['data'][currentList]),
                    );

                    /*
                     *  Daten der Icons nachladen, geschieht im Hintergrund, Promises werden nicht beobachtet,
                     * da für den weiteren Aufbau der Komponenten nicht relevant
                     */
                    if (currentList === 'globalIcons') {
                        const icons = result['data'][currentList];
                        icons.forEach((icon: Listentry, index: number) => {
                            // JSON parsen
                            const iconData = JSON.parse(icon.list_data);
                            // SVG laden
                            if (iconData.icon_type === 'image') {
                                const promise = this.getIconContent(iconData.name);
                                promise
                                    .then((iconContent: string | SafeHtml) => {
                                        // Daten anhängen
                                        iconData.content = iconContent as string;
                                        // JSON wieder in Ursprungsform bringen
                                        result['data'][currentList][index]['list_data'] = JSON.stringify(iconData);
                                        // Daten in Storage überschreiben
                                        this.storageService.setItem(
                                            'listentries|' + currentList,
                                            result['data'][currentList],
                                        );
                                    })
                                    .catch((error: any) => {
                                        // Error verarbeiten
                                    });
                            }
                        });
                    }
                }
            }
        });

        // Kennzeichen laden
        futureMonitor.addRequest('Characteristics/initializeAll', (result: any) => {
            for (const characteristicGroupId in result['data']) {
                // https://eslint.org/docs/latest/rules/guard-for-in
                if (Object.prototype.hasOwnProperty.call(result['data'], characteristicGroupId)) {
                    // Kennzeichendaten im Storage speichern
                    futureMonitor.addPromise(
                        this.storageService.setItem(
                            'characteristicsForGroup|' + characteristicGroupId,
                            result['data'][characteristicGroupId]['characteristics'],
                        ),
                    );
                }
            }
        });

        // Kennzeichengruppen initialisieren
        futureMonitor.addRequest('Characteristics/initializeGroups', (result: any) => {
            // Kennzeichengruppendaten im Storage speichern
            futureMonitor.addPromise(this.storageService.setItem('characteristicGroups', result['data'][0]));
        });

        // Eigene Employee-Daten laden
        futureMonitor.addRequest('Employees/getOwnEmployeeData', (result: any) => {
            // Daten Storage speichern
            futureMonitor.addPromise(this.storageService.setItem('ownUser', result['data']));
        });

        futureMonitor.addRequest('Regions/getRegions/', (result: any) => {
            // Regionen im Storage speichern
            futureMonitor.addPromise(this.storageService.setItem('regions', result['data']));
        });

        // Selektionen laden
        futureMonitor.addRequest('Selections/initializeSavedUserSelections', (result: any) => {
            Object.keys(result['data']).forEach((element) => {
                // UserSelektionen und Selektionsmenü im Storage speichern
                futureMonitor.addPromise(this.storageService.setItem(element, result['data'][element]));
            });
        });

        // Muster laden
        futureMonitor.addRequest('Samples/getSamplesPerDivision', (result: any) => {
            futureMonitor.addPromise(this.storageService.setItem('sampleProducts', result['data']));
        });

        // Besprechungsprodukte laden
        futureMonitor.addRequest('Samples/getConversationTopicsPerDivision', (result: any) => {
            futureMonitor.addPromise(this.storageService.setItem('conversationTopics', result['data']));
        });
        // Produktgruppen laden
        futureMonitor.addRequest('Products/loadProductGroupsForFilter', (result: any) => {
            futureMonitor.addPromise(this.storageService.setItem('listentries|productGroups', result['data']));
        });

        // Sales config laden
        futureMonitor.addRequest('SalesAnalysisList/initializeSalesConfig', (result: any) => {
            futureMonitor.addPromise(this.storageService.setItem('salesValueTypes', result.data.valueTypes));
            futureMonitor.addPromise(
                this.storageService.setItem(
                    'salesDateSelectionData',
                    JSON.parse(JSON.stringify(result.data.dateSelection)),
                ),
            );
            futureMonitor.addPromise(this.storageService.setItem('salesProducts', result.data.products));
            futureMonitor.addPromise(this.storageService.setItem('salesMaxProductLevel', result.data.maxProductLevel));
        });

        // Nur produkte laden wenn OrderOverview2 sichtbar ist
        this.appCore.globalMenuChanged.pipe(takeUntil(this._componentDestroyed$)).subscribe((result) => {
            const isOrderOverview2visible = hasOwn(result.data.submodules, 'institutions')
              && hasOwn(result.data.submodules.institutions, 'orders2')
              && result.data.submodules.institutions.orders2 !== null
              && typeof result.data.submodules.institutions.orders2 === 'object';

            if (isOrderOverview2visible) {
                // Produktliste für Auftragserfassung laden
                futureMonitor.addRequest('Products/getProductListForOrderEntry', (result: any) => {
                    futureMonitor.addPromise(this.storageService.setItem('orderEntryProductList', result['data']));
                });
            }
        });

        // höre selbst auf das 'alles initialisiert' Event und setze das Flag, das von anderen Komponenten abgerufen werden kann
        this.allInitialized.subscribe((result) => (this.initialized = result));

        /*
         * nachdem alle dem Monitor übergebenen Requests und Promises beendet sind,
         * benachrichtigt er das oben übergebene Subjekt, also this.allInitialized.next(true)
         * Monitor wird aktiviert, erst dann benachrichtigt er die Subscriber, wenn alle Requests und Promises beendet sind
         */
        futureMonitor.startWatching();
    }

    /**
     * @brief   SVG laden
     * @param {string} iconName
     * @returns {any}
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getIconContent(iconName: string) {
        // Promise erzeugen
        const promise = new Promise((resolve, reject) => {
            // URL zusammenbauen
            const apiURL = 'assets/img/icons/' + iconName + '.svg';
            // Request auslösen und in Promise umwandeln
            this.http
                .get(apiURL, {responseType: 'text'})
                .toPromise()
                .then(
                    (result: any) => {
                        // Ergebnis zurückgeben
                        resolve(result);
                    },
                    (error: any) => {
                        // Error
                        reject(error);
                    },
                );
        });

        // promise zurückgeben
        return promise;
    }
}
