import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UpdateService} from '@global/services/update.service';
import {Observable, Subject, Observer, Subscription, takeUntil} from 'rxjs';

@Component({
    selector: 'phscw-global-update-dialog',
    templateUrl: './global-update-dialog.component.html',
    styleUrl: './global-update-dialog.component.scss',
})

export class GlobalUpdateDialogComponent implements OnInit, OnDestroy {
    protected _componentDestroyed$ = new Subject<void>();
    constructor(
        public dialogRef: MatDialogRef<GlobalUpdateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        protected updateService: UpdateService,
    ) {
    }

    ngOnInit(): void {
    }

    public updateSW() {
        this.updateService.applyUpdate();
        this.dialogRef.close('confirm');
    }

    public closeDialog() {
        this.updateService.postponeUpdate();
        this.dialogRef.close('cancel');
    }

    ngOnDestroy(): void {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    protected subscribeUntilDestroyed<T>(
        observable$: Observable<T> | Subject<T> | null | undefined,
        observerOrNext?: Partial<Observer<T>> | ((value: T) => void),
    ): Subscription | undefined {
        return observable$?.pipe(
            takeUntil(this._componentDestroyed$),
        ).subscribe(observerOrNext);
    }

    protected onFormValueChange<T>(fg: FormGroup, name: string, handler: (value: T) => void) {
        this.subscribeUntilDestroyed(
            fg.get(name)?.valueChanges,
            handler,
        );
    }
}

export interface DialogData {
    title: string;
    message: string;
    confirm?: string;
    cancel?: string;
}
