/**
 * @brief   Root-Komponente des Feature-Moduls "institutions"
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {UserSettingsService} from '@global/services/user-settings.service';
// Service des globalen Regionsfilters
import {GlobalRegionsfilterService} from '@global/components/global-regionsfilter/global-regionsfilter.service';
// Service dieses Feature-Moduls
import {InstitutionsService} from './../institutions.service';
// Shared Services
import {GridService} from '@shared/grid/grid.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Shared Components
import {PopupLoadingComponent} from '@shared/popups/popup-loading/popup-loading.component';
// Interfaces für Structured Objects einbinden
import {ActivatedRoute} from '@angular/router';
import {CWEvent} from '@shared/cw-event';
import {FilterData} from '@shared/filter-data';
import {MenuData} from '@shared/menu-data';
import {Person} from '@shared/person';
import {hasOwn} from '@shared/utils';
import {Router} from '@angular/router';
import {PeopleService} from './../../people/people.service';

@Component({
    selector: 'phscw-institutions',
    templateUrl: './institutions.component.html',
    styleUrls: ['./institutions.component.scss'],
})
export class InstitutionsComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    // Menüpunkte für "submodules-menu" (kommen nun auch übers Backend, Menüdaten des globalen Menüs!)
    public submoduleMenu: MenuData[] = [];

    /**
     * Flag definiert ob Submodul-Menü (rechte Sidebar) angezeigt wird
     * Wird in "initializeEventSubscriptions" durch folgende Events gesetzt:
     * - gridService.eventGridSelectionChanged              TRUE
     * - gridService.eventGridEmptySelection                FALSE
     */
    displaySubmoduleMenu = false;

    // Referenz auf Loading-Popup
    loadingPopupReference: MatDialogRef<PopupLoadingComponent>;

    routedId = 0;
    routedSubmodule = '';

    // Konstruktor (inkl. dependency injection)
    constructor(
        private appCore: AppCoreService,
        private regionsfilterService: GlobalRegionsfilterService,
        private userSettingsService: UserSettingsService,
        private gridService: GridService,
        public institutionsService: InstitutionsService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private toolbarService: ToolbarService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public peopleService: PeopleService,
    ) {}

    // Initialisierungen
    ngOnInit() {
        /**
         * Eventuell wurden die Menüdaten bereits zuvor im AppCore abgelegt.
         * Diese Daten werden benötigt um die Menüstruktur der Submodule zu
         * erstellen. Das notwendige Event wird hier manuell ausgelöst.
         */
        const eventData: CWEvent = {
            sender: 'institutions',
            target: 'institutions',
            data: {
                globalMenu: this.appCore.globalMenu,
                submodules: this.appCore.submodules,
            },
        };
        this.receiveAppCoreGlobalMenuChanged(eventData);

        // Events subscriben
        this.initializeEventSubscriptions();

        this.activatedRoute.params.subscribe((params) => {
            if (hasOwn(params, 'id')) {
                this.routedId = params['id'];
                this.displaySubmoduleMenu = true;
            } else {
                this.routedId = 0;
            }

            if (hasOwn(params, 'submodule')) {
                this.routedSubmodule = params['submodule'];
            }
        });
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Auf Änderung im globalen Menü reagieren
        this._subscriptions.add(
            this.appCore.globalMenuChanged.subscribe((result) => {
                const event: CWEvent = result;
                if (event.target == 'institutions') {
                    this.receiveAppCoreGlobalMenuChanged(event);
                }
            }),
        );

        // Wenn im Grid (E-Liste) ein Eintrag ausgewählt wurde...
        this._subscriptions.add(
            this.gridService.eventGridSelectionChanged.subscribe((result) => {
                // Abbruch, falls das Event nicht vom eigenen Grid (E-Liste) kam
                const event: CWEvent = result;
                if (event.sender != 'institutionsList') {
                    return;
                }
                // Submodul-Menü anzeigen
                this.displaySubmoduleMenu = true;
            }),
        );

        // Wenn im Grid (E-Liste) ein "Leer-Eintrag" ausgewählt werden soll...
        this._subscriptions.add(
            this.gridService.eventGridEmptySelection.subscribe((result) => {
                const event: CWEvent = result;
                if (event.sender == 'institutionsList') {
                    // Submodul-Menü ausblenden
                    this.displaySubmoduleMenu = false;
                }
            }),
        );

        // Datenquelle im Grid (E-Liste) wurde geändert
        this._subscriptions.add(
            this.gridService.eventGridSourceChanged.subscribe((result) => {
                if (result['target'] === 'institutionsList') {
                    // User-Setting speichern
                    this.userSettingsService.setValue(
                        'institutionsListSource',
                        result['data']['selectedId'],
                        true,
                        true,
                    );
                }
            }),
        );

        // Layout im Grid (E-Liste) wurde geändert
        this._subscriptions.add(
            this.gridService.eventGridLayoutChanged.subscribe((result) => {
                if (result['target'] === 'institutionsList') {
                    // User-Setting speichern
                    this.userSettingsService.setValue(
                        'institutionsListLayout',
                        result['data']['selectedId'],
                        true,
                        true,
                    );
                }
            }),
        );

        // Sortierung im Grid (E-Liste) wurde geändert
        this._subscriptions.add(
            this.gridService.eventGridSortChanged.subscribe((result) => {
                if (result['target'] === 'institutionsList') {
                    // User-Setting speichern
                    const sortValue: FilterData = result['data'];
                    const sortValueString: string = sortValue.key + '|' + sortValue.direction;
                    this.userSettingsService.setValue('institutionsListSort', sortValueString, true, true);
                }
            }),
        );

        // Wenn Regionsfilter geändert wird
        this._subscriptions.add(
            this.regionsfilterService.eventRegionsfilterChanged.subscribe((result) => {
                if (result['target'] === 'institutions') {
                    this.onEventRegionsfilterChanged(result);
                }
            }),
        );

        /**
         * In dem Grid einer Einrichtungskomponente (z.B. E-Liste oder
         * E-Ansprechpartner) wurde auf das Personen-Icon geklickt.
         * Dies löst eine Backend-Auswahl der Person(en), sowie einen Wechsel
         * zur P-Liste aus.
         */
        this._subscriptions.add(
            this.gridService.eventGridPersonIconClicked.subscribe((result) => {
                // Event-Daten
                const event: CWEvent = result;
                // In der E-Liste wurde auf das Personen-Icon geklickt
                if (event.sender == 'institutionsList') {
                    // Zu den Personen der gewählten Einrichtung navigieren
                    this.prepareNavigationToPeopleOfInstitution(event.data['id']);
                }
                // In E-Ansprechpartner wurde auf das Pesonen-Icon geklickt
                if (event.sender == 'institutionsPeople') {
                    // Zu ausgewählter Person wechseln
                    this.prepareNavigationToPerson(event.data['id']);
                }
                // Sender war nicht bekannt
            }),
        );

        /**
         * In Toolbar der Einrichtungs-Details oder im Modul E-Ansprechpartner
         * wurde "Person hinzufügen" angeklickt. Dies führt zu einer Selektion
         * der Personen einer Einrichtung und Wechsel zur P-Liste.
         */
        this._subscriptions.add(
            this.toolbarService.eventAddItem.subscribe((result) => {
                const event: CWEvent = result;
                if (
                    event.sender !== 'toolbar-add-child' &&
                    (event.target == 'institutionsDetails' || event.target == 'institutionsPeople')
                ) {
                    this.prepareNavigationAddPerson(event.data['addParameter']);
                }
            }),
        );
        this._subscriptions.add(
            this.toolbarService.eventAddShadowItem.subscribe((result) => {
                const event: CWEvent = result;
                if (
                    event.sender !== 'toolbar-add-child' &&
                    (event.target == 'institutionsDetails' || event.target == 'institutionsPeople')
                ) {
                    this.peopleService.isShadow = true;
                    this.prepareNavigationAddPerson(event.data['addParameter']);
                }
            }),
        );
    }

    // Reaktion auf geänderten Regionsfilter
    onEventRegionsfilterChanged(event: CWEvent) {
        /*
         * @todo: An dieser Stelle sollten dann zum Beispiel ein Reset der
         * Detail-Module erfolgen, da es sein kann dass die Details einer
         * angezeigten Einrichtung nicht mehr aufgrund des Regionsfilters
         * angezeigt werden sollten.
         *
         * Das Neuladen bzw. Filtern der E-Liste in Bezug auf Regionsfilter
         * wird in institutions-list bzw. im Grid geregelt.
         */
    }

    /**
     * @param event
     * @brief   Event "GlobalMenuChanged" vom "AppCore" erhalten und anhand der
     *          Daten das Menü für Einrichtungs-Submodule anpassen.
     * @details Hierbei handelt es sich lediglich um die angezeigten Menü-Elemente
     *          (Icon & Text) im rechten Detailmenü - und nicht etwas um das
     *          Laden der einzelnen Submodule.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    receiveAppCoreGlobalMenuChanged(event: CWEvent) {
        if (hasOwn(event.data, 'submodules') && hasOwn(event.data['submodules'], 'institutions')) {
            this.submoduleMenu = event.data['submodules']['institutions'];
        }
    }

    /**
     * @brief   Das Loading-Popup wird verwendet um weitere User-Eingaben
     *          zu verhindern, während Daten im Hintergrund vorbereitet werden
     * @param   show boolean    <true> Zeige Popup
     *                          <false> Schließe Popup
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    loadingPopup(show: boolean) {
        if (show === true) {
            // Popup öffnen
            this.loadingPopupReference = this.dialog.open(PopupLoadingComponent, {
                width: '250px',
                data: {},
            });
        } else {
            // Popup schließen
            this.loadingPopupReference.close();
        }
    }

    /**
     * @param institutionId
     * @brief   In E-Liste wurde das Personen-Icon angeklickt und es soll
     *          deshalb zu den Personen der jeweiligen Einrichtung gewechselt
     *          werden.
     * @details In dieser Funktion werden die User-Settings überschrieben und
     *          die Filterung im Backend angestoßen.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    prepareNavigationToPeopleOfInstitution(institutionId: number) {
        // Lade-Popup zeigen
        this.loadingPopup(true);

        /**
         * Datenquelle der P-Liste soll auf "Auswahl" (KEY = 40) geändert werden.
         * =====================================================================
         * User-Setting wird hier überschrieben. Falls die P-Liste noch nicht
         * zuvor initialisiert wurde, wird beim Wechsel zur P-Liste direkt
         * mit dem gewünschten / geänderten Setting initialisiert.
         */
        this.userSettingsService.setValue('peopleListSource', '40');

        /**
         * Einstellungen des Regionsfilters werden für P-Liste übernommen
         * @todo: Funktioniert leider noch nicht komplett, Regionsfilter zeigt
         * zwar korrektes Gebiet, der verbundene Gridfilter hat aber noch
         * alte Einstellungen
         */
        /*
         * this.userSettingsService.setValue('peopleRegionsfilterDivision', this.regionsfilterService.currentDivision);
         * this.userSettingsService.setValue('peopleRegionsfilterRegion', this.regionsfilterService.currentRegion);
         */

        // Starte Filterung im Backend
        const serviceRequest$ = this.institutionsService.executeFilterPeopleOfInstitution(institutionId);
        serviceRequest$.subscribe((result) => {
            this.executeNavigationToPeopleOfInstitution();
        });
    }

    /**
     * @brief   In E-Liste wurde das Personen-Icon angeklickt und es soll
     *          deshalb zu den Personen der jeweiligen Einrichtung gewechselt
     *          werden.
     * @details Diese Funktion wird aufgerufen, sobald die Filterung im Backend
     *          abgeschlossen wurde, damit nun abschließend die Navigation
     *          auf das gewünschte Modul stattfinden kann.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    executeNavigationToPeopleOfInstitution() {
        // Lade-Popup schließen
        this.loadingPopup(false);

        /**
         * Datenquelle der P-Liste soll auf "Auswahl" (KEY = 40) geändert werden.
         * =====================================================================
         * Falls die P-Liste bereits initialisiert wurde, muss die Veränderung
         * der Datenquelle über ein Event gesendet werden.
         */
        const eventData: CWEvent = {
            sender: 'institutions',
            target: 'peopleList',
            data: {
                listKey: '40',
                selectRowAfterFinished: 'first',
            },
        };
        this.appCore.changeGridSource.next(eventData);

        /**
         * Navigation zur P-Liste
         */
        // Navigation ausführen
        this.appCore.crossModuleNavigation('people', {});
    }

    /**
     * @param personId
     * @brief   In E-Ansprechpartner wurde auf ein Pesonen-Icon geklickt und
     *          es soll nun zu dieser ausgewählten Person in der P-Liste
     *          gewechselt werden.
     * @details In dieser Funktion werden die User-Settings überschrieben und
     *          die Filterung im Backend angestoßen.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    prepareNavigationToPerson(personId: number) {
        // Lade-Popup zeigen
        this.loadingPopup(true);

        // USERSETTING: Datenquelle der P-Liste auf "Auswahl" (KEY = 40) setzen
        this.userSettingsService.setValue('peopleListSource', '40');

        // Starte Filterung im Backend
        const serviceRequest$ = this.institutionsService.executeFilterPerson(personId);
        serviceRequest$.subscribe((result) => {
            this.executeNavigationToPerson();
        });
    }

    /**
     * @brief   In E-Ansprechpartner wurde auf ein Pesonen-Icon geklickt und
     *          es soll nun zu dieser ausgewählten Person in der P-Liste
     *          gewechselt werden.
     * @details Diese Funktion wird aufgerufen, sobald die Filterung im Backend
     *          abgeschlossen wurde, damit nun abschließend die Navigation
     *          auf das gewünschte Modul stattfinden kann.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    executeNavigationToPerson() {
        // Lade-Popup schließen
        this.loadingPopup(false);

        // EVENT: Datenquelle der P-Liste auf "Auswahl" (KEY = 40) setzen
        const eventData: CWEvent = {
            sender: 'institutions',
            target: 'peopleList',
            data: {
                listKey: '40',
                selectRowAfterFinished: 'first',
            },
        };
        this.appCore.changeGridSource.next(eventData);

        // Navigation zur P-Liste ausführen
        this.appCore.crossModuleNavigation('people', {});
    }

    /**
     * @param institutionId
     * @brief   Es soll eine neue Person in der gewählten Einrichtung
     *          angelegt werden. Dazu wird in die P-Liste gewechselt, welche
     *          zuvor auf die Personen der gewählten Einrichtung gefiltert
     *          wird.
     * @details In dieser Funktion werden die User-Settings überschrieben und
     *          die Filterung im Backend angestoßen.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    prepareNavigationAddPerson(institutionId: number) {
        // Lade-Popup zeigen
        this.loadingPopup(true);

        /**
         * Datenquelle der P-Liste soll auf "Auswahl" (KEY = 40) geändert werden.
         * =====================================================================
         * User-Setting wird hier überschrieben. Falls die P-Liste noch nicht
         * zuvor initialisiert wurde, wird beim Wechsel zur P-Liste direkt
         * mit dem gewünschten / geänderten Setting initialisiert.
         */
        this.userSettingsService.setValue('peopleListSource', '40');

        const serviceRequest$ = this.institutionsService.executeFilterPeopleOfInstitution(institutionId);
        serviceRequest$.subscribe((result) => {
            const routerLink = 'people/new/' + institutionId;
            this.router.navigate([routerLink]);
            this.loadingPopup(false);
        });
    }

    /**
     * @param institutionId
     * @brief   Es soll eine neue Person in der gewählten Einrichtung
     *          angelegt werden. Dazu wird in die P-Liste gewechselt, welche
     *          zuvor auf die Personen der gewählten Einrichtung gefiltert
     *          wird.
     * @details Diese Funktion wird aufgerufen, sobald die Filterung im Backend
     *          abgeschlossen wurde, damit nun abschließend die Navigation
     *          auf das gewünschte Modul stattfinden kann.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    executeNavigationAddPerson(institutionId: number) {
        // Lade-Popup schließen
        this.loadingPopup(false);

        /**
         * Datenquelle der P-Liste soll auf "Auswahl" (KEY = 40) geändert werden.
         * =====================================================================
         * Falls die P-Liste bereits initialisiert wurde, muss die Veränderung
         * der Datenquelle über ein Event gesendet werden.
         */
        const eventData: CWEvent = {
            sender: 'institutions',
            target: 'peopleList',
            data: {listKey: '40'},
        };
        this.appCore.changeGridSource.next(eventData);

        /**
         * Für die neu anzulegende Person wird bereits ein leeres Objekt
         * initialisiert und später bei "appCore.crossModuleNavigation" als
         * Parameter übergeben.
         *
         * Diese neue Objekt wird bei aktivem CrossModule-Flag innerhalb des
         * Grids bei abgeschlossener "loadData" ausgewählt.
         */
        const newPerson: Person = new Person(institutionId);

        /**
         * Navigation zur P-Liste
         */
        // Navigation ausführen
        this.appCore.crossModuleNavigation('people', {newItem: newPerson});
    }
}
