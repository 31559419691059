// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* Kontakt-Info */
.cw-grid-column-contact-info {
  max-width: 250px !important; /* erbt nowrap daher kein Zeilenumbruch, wie gewünscht */
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid/grid-contact-info/grid-contact-info.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB,iBAAA;AACA;EACI,2BAAA,EAAA,wDAAA;EACA,gBAAA;EACA,uBAAA;AAEJ","sourcesContent":["/* Kontakt-Info */\n.cw-grid-column-contact-info {\n    max-width: 250px!important; /* erbt nowrap daher kein Zeilenumbruch, wie gewünscht */\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
