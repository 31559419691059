/**
 * Beispiele
 *
 *     <phscw-input-playground></phscw-input-playground><hr>
 *     <phscw-input-playground [displayValue]="80"></phscw-input-playground><hr>
 *     <phscw-input-playground [calcValue]="0.75"></phscw-input-playground><hr>
 */

import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-input-playground',
    templateUrl: './input-playground.component.html',
    styleUrls: ['./input-playground.component.scss'],
})
export class InputPlaygroundComponent implements OnInit, OnDestroy {
    // Wert für Anzeige, z.B. 50%
    _displayValue = 0;
    get displayValue() {
        return this._displayValue;
    }

    @Input() set displayValue(value) {
        this._displayValue = value;
        this._calcValue = value / 100;
    }

    // Wert für Berechnungen, z.B. 0.5
    _calcValue = 0.0;
    get calcValue() {
        return this._calcValue;
    }

    @Input() set calcValue(value) {
        this._calcValue = value;
        this._displayValue = value * 100;
    }

    constructor() {}

    ngOnInit() {
        /*
         * Werte aus der DB --> initialisere calcValue
         * this.calcValue = 0.6;
         */
    }

    // Aufräumen
    ngOnDestroy() {}
}
