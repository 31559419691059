/* eslint-disable @typescript-eslint/no-explicit-any */
import {Characteristic} from './../../characteristic';
import {GridCellEditListentryComponent} from './../grid-cell-edit-listentry/grid-cell-edit-listentry.component';
// Angular-Module
import {animate, state, style, transition, trigger} from '@angular/animations';
import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    NgZone,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from '@angular/core';
// CDK & Material
import {SelectionModel} from '@angular/cdk/collections';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
// ReactiveX for JavaScript
import {Subject, Subscription, fromEvent} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {FileDownloadService} from '@global/services/file-download.service';
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';
// Service dieses Shared-Moduls
import {GridService} from './../grid.service';
// Services anderer Shared-Modules
import {InputNumberService} from './../../input/input-number/input-number.service';
import {ToolbarService} from './../../toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../../cw-event';
import {FilterData} from './../../filter-data';
import {Listentry} from './../../listentry';
import {SelectData} from './../../select-data';
// Environment einbinden
import {environment} from '@environment';

// Import Moment-Modul zur Datumsformatierung
// eslint-disable-next-line @stylistic/max-len
import {CharacteristicSingleEditPopupComponent} from '@shared/characteristics/characteristic-single-edit-popup/characteristic-single-edit-popup.component';
import {hasOwn, notEmpty} from '@shared/utils';
import {ContactsListComponent} from '@shared/contacts/contacts-list/contacts-list.component';
import * as _moment from 'moment';
import {GridSortService} from '../grid-sort/grid-sort.service';
import {CwIcon, IconType} from '@shared/cw-icon';

const moment = _moment;

@Component({
    selector: 'phscw-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    animations: [
        trigger('detailExpand', [
            state(
                'collapsed',
                style({
                    height: '0px',
                    minHeight: '0',
                }),
            ),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    #componentDestroyed$ = new Subject<void>();
    // Referenz auf die Subscription des LoadData-Request
    #loadDataSubscription = new Subscription();

    /**
     * *************************************************************************
     * Parameter, welche beim Einbinden der GridComponent gesetzt werden
     *************************************************************************
     */
    // ID des Grids
    @Input() gridId = '';
    // DataSource des Grids (Backend-Controller & Backend-Funktion)
    #gridBackendSource = '';
    get gridBackendSource() {
        return this.#gridBackendSource;
    }

    @Input() set gridBackendSource(value) {
        // Wert übernehmen
        this.#gridBackendSource = value;
        // Grid resetten und ggf. neu laden
        this.resetAndReloadGrid();
    }

    // Daten für Grid
    @Input() gridData: any[] = []; // von außen gesetzt, einfaches Array
    /*
     * @Input() gridData = new MatTableDataSource<any>();
     * Spaltendefinitionen für Grid (enthält alle möglichen Spalten)
     */
    #gridColumns: any;
    @Input() set gridColumns(value) {
        // Wert übernehmen
        this.#gridColumns = value;
        /*
         * this.#gridColumnsOriginal = JSON.parse(JSON.stringify(value));
         * Listentries ggf. neu laden
         */
        if (value) {
            this.initializeListentries();
        }
    }

    get gridColumns() {
        return this.#gridColumns;
    }
    /*
     * hält Kopie der gridColumns im Originalzustand, um z.B. die Header-Label zurücksetzen zu können
     * #gridColumnsOriginal: any;
     */

    // Simple-Grid (mit kleinerem Container und ohne Row-Click-Handler)
    @Input() simpleGrid = false;

    // Pagination deaktiviert
    @Input() paginationDisabled = false;

    // Einrichtung, zu welcher die Kindeinrichtungen angezeigt werden sollen
    expandedElement: any;
    // Soll eine Ladeanimation angezeigt werden?
    childrenLoading = false;

    // Flag definiert ob alle Reihen angezeigt werden sollen
    @Input() showAllRows = true;

    /**
     * *************************************************************************
     * Angezeigte Spalten im Grid
     * --------------------------
     * Durch Veränderungen des Arrays können Spalten ein- / ausgeblendet
     * sowie die Reihenfolge der Spalten geändert werden.
     * Todo: Die Spalteneinstellungen aus GridColumnsPanelComponent sollten
     *        hier rein.
     *************************************************************************
     */
    @Input() gridDisplayedColumns: any;

    /**
     * *************************************************************************
     * Ausgewähltes Layout
     * -------------------
     * Über GridLayoutComponent (selectbox) ausgewähltes Layout
     *************************************************************************
     */
    @Input() selectedLayout: number;

    /**
     * Steuert, ob das Grid darauf wartet, dass die Layout-Komponente es triggert,
     * bevor Daten geladen werden.
     * Wenn true, lädt das Grid die Daten bei der Initialisierung nicht selbst.
     * Default ist false, um das bisherige Verhalten in nicht angepassten Modulen zu bewahren.
     */
    @Input() selectableLayouts = false;

    /**
     * *************************************************************************
     * Datenquelle
     * -----------
     * ÜberGridSourceComponent (selectbox) ausgewählte Datenquelle mit GETTER & SETTER
     *************************************************************************
     */
    #selectedSource = 0;
    get selectedSource() {
        return this.#selectedSource;
    }

    @Input() set selectedSource(value) {
        // Wert übernehmen
        this.#selectedSource = value;
        // Grid resetten und ggf. neu laden
        this.resetAndReloadGrid();
    }

    /**
     * *************************************************************************
     * Regionsfilter
     * -------------
     * Array mit Filter-Informationen für Grid (z.B. Regionsfilter)
     *************************************************************************
     */
    #gridRegionsFilter: any[] = [];
    get gridRegionsFilter() {
        return this.#gridRegionsFilter;
    }

    @Input() set gridRegionsFilter(value) {
        // Wert übernehmen
        this.#gridRegionsFilter = value;
        // Grid resetten und ggf. neu laden
        this.resetAndReloadGrid();
    }

    /**
     * *************************************************************************
     * Filter
     * ------
     * Array mit Infos aus GridFilter-Component ("Schnell-Filter-Button")
     *************************************************************************
     */
    #gridFilter: FilterData;
    get gridFilter() {
        return this.#gridFilter;
    }

    @Input() set gridFilter(value) {
        // Wert übernehmen
        this.#gridFilter = value;
        // Grid resetten und ggf. neu laden
        this.resetAndReloadGrid();
    }

    /**
     * *************************************************************************
     * Selektion
     * ------
     * Array mit Infos aus GridSelection-Component
     *************************************************************************
     */
    #gridSelection: any[];
    get gridSelection() {
        return this.#gridSelection;
    }

    @Input() set gridSelection(value) {
        // Wert übernehmen
        this.#gridSelection = value;
        /*
         * Grid resetten und ggf. neu laden
         * this.resetAndReloadGrid();
         */
    }

    /**
     * *************************************************************************
     * Sortierung
     * ----------
     * Wird über GridSortComponent bei einer Änderung der Sortierung direkt
     * gesetzt (über gridConnection).
     *************************************************************************
     */
    #gridSort: FilterData;
    get gridSort() {
        return this.#gridSort;
    }

    @Input() set gridSort(value) {
        // Wert übernehmen
        this.#gridSort = value;
        // Grid resetten und ggf. neu laden
        this.resetAndReloadGrid();
    }

    /**
     * *************************************************************************
     * Spaltenauswahl
     * ----------
     * Wird über GridColumnPanelComponent bei einer Änderung der Spaltenauswahl direkt
     * gesetzt (über gridConnection).
     *************************************************************************
     */
    #gridColumnChoice: any[];
    get gridColumnChoice() {
        return this.#gridColumnChoice;
    }

    @Input() set gridColumnChoice(value) {
        // Wert übernehmen
        this.#gridColumnChoice = value;
        // Grid resetten und ggf. neu laden
        this.resetAndReloadGrid();
    }

    public sendColumnsWithLoadData = false;

    /**
     * *************************************************************************
     * Page-Counter
     * ------------
     * Wird im Standardfall komplett vom Grid selbständig verwaltet (durch
     * lazy loading beim Scrollen).
     *
     * Für die "Load more"-Funktion, kann der Page-Counter aber auch von
     * außen erhöht werden.
     *************************************************************************
     */
    // Initial soll die erste Seite geladen werden
    #gridPageCounter = 1;
    get gridPageCounter() {
        return this.#gridPageCounter;
    }

    @Input() set gridPageCounter(value) {
        this.#gridPageCounter = value;
    }

    /**
     * *************************************************************************
     * Interne Variablen
     *************************************************************************
     */
    // Grid initialisiert?
    public initialized = false;

    /**
     * Bei Bedarf kann eine Parent-Komponente (Liste) ihren eigenen Initialisierungsstatus an diesen Input binden,
     * um zu verhindern, dass das Grid schon Daten lädt, bevor z.B. alle Layouts und Kennzeichen asynchron geladen wurden.
     * Default ist true, da einige Grids auch autark funktionieren bzw. kompatibel zum bisherigen Verhalten bleiben sollen.
     */
    #listInitialized = true;

    get listInitialized(): boolean {
        return this.#listInitialized;
    }

    @Input() set listInitialized(value: boolean) {
        if (value && !this.#listInitialized) {
            // nur bei wechsel von false auf true wird die Initialisierung (einmalig) abgeschlossen
            this.#listInitialized = value;
            this.loadDataIfInitialized();
        } else if (!value) {
            this.#listInitialized = value;
        }
    }

    // Aktuell ausgewählte Datenzeile
    public currentRow: any = null;
    // Flag um nach erfolgreichem Laden, direkt die erste Zeile zu selektieren
    private flagSelectFirstRow = false;
    // Scrollposition um horizontalen Scroll beim Nachladen abzufangen
    private horizontalScroll = 0;
    // Scroll-Semaphore
    private scrollSemaphore = false;
    // Listentries die zur Darstellung benötigt werden. Um nicht mehr mit jedem input-select-Aufruf die listentries neu zu Laden
    public lists: any = {};
    // Listentries die zur Formatierung von Zahlen benöitgt werden
    private numberFormatTypes: Listentry[] = [];
    // Anzeige von Nachkommastellen bei Zahlen mit dem Format "currency"
    includeDecimalsSalesAndProductsAnalysis = false;
    // Format der Werte auf CHF umschalten
    applyChfCurrencyFormat = false;
    // Flag definiert ob gerade geladen wird (Anzeige Loading-Spinner)
    @Input() loading = false;
    // Flag definiert ob gerade gespeichert wird (Anzeige Loading-Spinner)
    @Input() saving = false;
    // Currency-Symbol für formatTemplate='currency' (wird in NgOnInit ggf. über Environment gesetzt / überschrieben)
    currency = '€';

    /**
     * *************************************************************************
     * Einrichtungs-Icons
     * ------------------
     * In C-World 3.x wurde beim Laden der Einrichtungen immer auch direkt
     * auf LIST gejoined um das anzuzeigende Einrichtungs-Icon zu ermitteln.
     *
     * In C-World 4 liegen die Listentries (darunter auch "institutionType1")
     * bereits nach Login in IndexedDB vor. Deshalb wird kein zusätzlicher
     * JOIN auf listentries benötigt. Das Grid lädt die notwendigen Daten im
     * Frontend über IndexedDB und erstellt die Key-Value-Pairs zur einfachen
     * Anzeige der Einrichtungs-Icons.
     *************************************************************************
     */
    @Input() useInstitutionsIcons = false;
    institutionsIcons: any[] = [];

    /**
     * *************************************************************************
     * Personen-Icons
     * ------------------
     *
     * In C-World 4 liegen die Listentries (darunter auch "personType1")
     * bereits nach Login in IndexedDB vor. Deshalb wird kein zusätzlicher
     * JOIN auf listentries benötigt. Das Grid lädt die notwendigen Daten im
     * Frontend über IndexedDB und erstellt die Key-Value-Pairs zur einfachen
     * Anzeige der Einrichtungs-Icons.
     *************************************************************************
     */
    @Input() usePeopleIcons = false;
    peopleIcons: any[] = [];

    /**
     * *************************************************************************
     * Checkbox-Selektion (ausgewählte Datenzeilen)
     *************************************************************************
     */
    /* Checkbox-Selektion der Datenzeilen */
    @Input() selection = new SelectionModel<any>(true, []);

    // Öffentliche Variablel die die Anzahl der Einträge der aktuellen Liste enthält
    public totalCount: number = null;

    /**
     * *************************************************************************
     * Header-Zeilen Ausblenden
     *************************************************************************
     */
    @Input() hideHeaderRow = false;

    /**
     * *************************************************************************
     * Wenn Mehrere Grids in einander geschachtelt sind (z.B.) zeigt dies
     * den Level des aktuellen Grids an
     *************************************************************************
     */
    @Input() level = 1;

    /**
     * *************************************************************************
     * Soll Links ein padding für ineinander geschachtelte Grids angezeigt
     * werden?
     *************************************************************************
     */
    @Input() childrenNoPadding = false;

    /**
     * ************************************************************************
     *
     ************************************************************************
     */
    @Input() sortableHeader = false;

    @Input() sortBackend = false;

    #optionalBackendValues: any;
    @Input() set optionalBackendValues(value) {
        // Wert übernehmen
        this.#optionalBackendValues = value;

        if (!this.hasNullValues(value) && value) {
            this.resetAndReloadGrid();
        }
    }

    get optionalBackendValues() {
        return this.#optionalBackendValues;
    }

    @ViewChild(MatSort) sortView: MatSort;

    @Output() scrolledToEnd = new EventEmitter<any>();
    @Output() gridDataChanged = new EventEmitter<any>();
    customWidths = {};

    resizing = false;
    resizingCol = null;
    startPosX = null;
    startPosY = null;
    @Input() enableResize = false;

    @Output() dataLoaded: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param {AppCoreService} appCore - Globale Services
     * @param {GridService} gridService - gridService
     * @param {StorageService} storageService - storageService
     * @param {FileDownloadService} fileDownloadService - fileDownloadService
     * @param {InitService} initService - initService
     * @param {ToolbarService} toolbarService - toolbarService
     * @param {InputNumberService} inputNumberService - inputNumberService
     * @param {MatDialog} dialog - dialog
     * @param {GridSortService} gridSortService - gridSortService
     * @param {NgZone} zone - Angular zone
     * @param {ChangeDetectorRef} changeDetector - changeDetector
     */
    constructor(
        private appCore: AppCoreService,
        private gridService: GridService,
        private storageService: StorageService,
        private fileDownloadService: FileDownloadService,
        private initService: InitService,
        private toolbarService: ToolbarService,
        private inputNumberService: InputNumberService,
        private dialog: MatDialog,
        private gridSortService: GridSortService,
        private zone: NgZone,
        private changeDetector: ChangeDetectorRef,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        this.addEventListeners();
        // Prüfe Anforderungen
        this.checkRequirements();
        // Setze Default-Werte
        this.setDefaultValues();
        // Events subscriben
        this.initializeEventSubscriptions();

        if (this.initService.initialized) {
            // Initialisierungen die abhängig davon sind, ob der InitService alles fertig geladen hat
            this.onAllInitialized();
        } else {
            // Darauf warten, dass alle listentries in der indexedDB gespeichert sind
            this.initService.allInitialized.pipe(takeUntil(this.#componentDestroyed$)).subscribe((result: boolean) => {
                // Abbruch, falls Anfrage erfolglos war
                if (result === false) {
                    return;
                }
                this.onAllInitialized();
            });
        }

        /*
         * Lade Daten erst nach der Initialisierung:
         * Je nach Verwendung des Grids wird das entweder extern über die LayoutComponent getriggert,
         * oder das Grid lädt die Daten, sobald es selbst (und die Parent-Liste) fertig initialisiert sind.
         */
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this.#componentDestroyed$.next();
        this.#componentDestroyed$.complete();
    }

    /**
     * Prüfe Anforderungen
     */
    checkRequirements(): void {
        // Grid-Layout benötigt gridLayouts
        if (!this.gridId) {
            // eslint-disable-next-line no-console
            console.error('Pharmakon - GridComponent bekam keine gridId zugewiesen!');
        }
    }

    /**
     * Setze Default-Werte
     */
    setDefaultValues(): void {
        // Währung
        if (typeof environment.defaultCurrency !== 'undefined') {
            this.currency = environment.defaultCurrency;
        }
        // Anzeige von Nachkommastellen
        if (typeof environment.includeDecimalsSalesAndProductsAnalysis !== 'undefined') {
            this.includeDecimalsSalesAndProductsAnalysis = environment.includeDecimalsSalesAndProductsAnalysis;
        }

        // Währungsformat für CHF aktiv?
        if (typeof environment.applyChfCurrencyFormat !== 'undefined') {
            this.applyChfCurrencyFormat = environment.applyChfCurrencyFormat;
        }
    }

    /**
     * Listentry-Daten wurden geladen
     * @param {string} listname - Name der Liste
     * @param {any} storageData - Daten aus dem Storage
     * @returns {void} - void
     */
    onGetListentriesFromStorage(listname: string, storageData: any): void {
        // Initialisierung dieser Komponente war schneller als das Laden der Listentries - nicht schön aber (leider auch nicht) selten
        if (storageData === null) {
            return;
        }

        // Daten aus Storage vorhanden
        if (storageData) {
            /*
             * Wenn listentries geladen werden sollen für die Anzeige von Listentry-Einträgen im Grid:
             * Schlüssele die Listen Daten in das SelectData-Format um, um diese dann an die input-Select-Komponenten zu übergeben.
             */
            const transcodedListData = [];
            for (const obj of storageData) {
                const newObject: SelectData = {
                    id: 0,
                    label: '',
                };
                newObject.id = obj.list_key;
                newObject.label = obj.list_value;
                transcodedListData.push(newObject);
            }
            // Lade Listen in ein Object mit dem Listennamen als Key
            this.lists[listname] = transcodedListData;
        }
        this.gridService.triggerGridChangeDetection();
    }

    /**
     * Die Listentries für Grideinträge mit Listen aus der IndexedDB laden.
     * @returns {Promise<void>[]} - Array mit Promises
     */
    initializeListentries(): Promise<void>[] {
        /*
         * Dies muss getan werden, um dass Laden in der input-select-Komponente zu verhindern
         * (Diese wird nämlich 1 mal pro Zeile und Listeneintrag aufgerufen => schnell sehr oft und deshalb langsam)
         */

        const allPromises: Promise<void>[] = [];

        for (const column of this.gridColumns) {
            if (typeof column['listentry'] === 'undefined') {
                continue;
            }
            if (Array.isArray(column['listentry'])) {
                allPromises.push(
                    ...column['listentry'].map((listName) =>
                        this.storageService
                            .getItem('listentries|' + listName)
                            .then((val) => this.onGetListentriesFromStorage(listName, val))),
                );
            } else {
                const promise = this.storageService.getItem('listentries|' + column['listentry']);
                allPromises.push(promise.then((val) => this.onGetListentriesFromStorage(column['listentry'], val)));
            }
        }

        // gib die Promises zurück, damit die Initialisierung auf die Fertigstellung warten kann
        return allPromises;
    }

    /**
     * @description Die Listentries für Grideinträge mit Listen aus der IndexedDB laden
     * @author Tobias Hannemann <t.hannemann@pharmakon.software>
     * @returns {Promise<void>}
     */
    initializeCharacteristicsFormatTypes(): Promise<void> {
        const promise = this.storageService.getItem('listentries|characteristicsFormatType');

        return promise.then((values: any) => {
            if (values !== null && typeof values !== 'undefined') {
                values.forEach((listentry: Listentry) => {
                    // eslint-disable-next-line no-param-reassign
                    listentry.list_data = JSON.parse(listentry.list_data);
                    this.numberFormatTypes.push(listentry);
                });
            }
        });
    }

    /**
     * Wird aufgerufen, wenn der InitService fertig ist, so dass Kennzeichen und Listentries schon im Storage liegen
     */
    onAllInitialized() {
        // Die Daten asynchron aus dem Storage in die Komponente laden
        Promise.all(this.initializeListentries().concat(this.initializeCharacteristicsFormatTypes())).then(() => {
            // Flag setzen, Grid ist fertig initialisiert
            this.initialized = true;
            this.loadDataIfInitialized();
        });
    }

    /**
     * Prüft, ob Liste und Grid schon fertig initialisiert sind und lädt nur dann die Daten
     * (sofern es ein Grid mit Datenquelle ist und nicht von außen über eine Layout-Auswahl gesteuert wird).
     */
    loadDataIfInitialized() {
        if (
            this.initialized &&
            this.listInitialized &&
            this.gridData.length === 0 &&
            this.simpleGrid === false &&
            !this.selectableLayouts
        ) {
            this.loadData();
        }
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Change-Detection auslösen, wenn Daten geändert wurden
        this.gridService.eventTriggerChangeDetection.subscribe(() => {
            this.changeDetector.markForCheck();
            // @Todo - detectChanges() sinnvoller?
        });

        /*
         * Falls es sich bei diesem Grid um "P-Liste", "E-Liste", "Admin User-Liste", "Admin Kennzeichen-Liste" oder "Admin Rollen-Liste"  handelt
         * Todo: Optimieren
         */
        if (
            this.gridId === 'peopleList' ||
            this.gridId === 'institutionsList' ||
            this.gridId === 'reportsOrdersOverviewList' ||
            this.gridId === 'eventsOverviewList' ||
            this.gridId === 'eventsPeopleList' ||
            this.gridId === 'adminUsersList' ||
            this.gridId === 'adminCharacteristicsList' ||
            this.gridId === 'adminCharacteristicGroupsList' ||
            this.gridId === 'adminRolesList' ||
            this.gridId === 'adminNotificationsList'
        ) {
            // Event "eventDataChanged" von "appEventsService"
            this.appCore.appDataChanged.pipe(takeUntil(this.#componentDestroyed$)).subscribe((result: any) => {
                this.updateDataItems(result['data']['changedItem']);
            });
        }

        // Falls erste Datenzeile ausgewählt werden soll
        this.gridService.eventGridSelectFirstRow
            .pipe(takeUntil(this.#componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls Anfrage erfolglos war
                if (event.sender !== this.gridId) {
                    return;
                }
                this.selectFirstRow(false);
            });

        // Falls neue Datenzeile ausgewählt werden soll
        this.gridService.eventGridSelectNewRow
            .pipe(takeUntil(this.#componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls Anfrage erfolglos war
                if (event.sender !== this.gridId) {
                    return;
                }
                this.selectNewRow(event['data']['newItem']);
            });

        // Falls die mittels CHECKBOX gewählten Datenzeilen abgewählt werden sollen
        this.gridService.eventUnsetGridCheckboxes
            .pipe(takeUntil(this.#componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls Anfrage erfolglos war
                if (event.sender !== this.gridId) {
                    return;
                }
                this.selection.clear();
                this.gridService.checkboxClicked(this.gridId, this.selection.selected);
            });

        // Falls die Liste neu geladen werden soll
        this.gridService.eventReloadGridData.pipe(takeUntil(this.#componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls Anfrage erfolglos war
            if (
                event.sender === this.gridId ||
                ((this.gridId.includes('Contacts') || this.gridId.includes('Tickets')) &&
                  event.sender === 'contacts-form')
            ) {
                this.selection.clear();
                this.resetAndReloadGrid();
            }
        });

        // Excel Export wurde ausgelöst
        this.toolbarService.eventExcelExportValidated
            .pipe(takeUntil(this.#componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls Anfrage erfolglos war
                if (
                    event.sender === 'toolbar' &&
                    'data' in event &&
                    'gridId' in event.data &&
                    event.data.gridId === this.gridId
                ) {
                    this.loadData(true, event.target, event.data.selectedIds);
                }
            });

        // Filter muss zurückgesetzt werden
        this.gridService.eventResetGridFilter.pipe(takeUntil(this.#componentDestroyed$)).subscribe((event: CWEvent) => {
            // Abbruch, falls Anfrage erfolglos war
            if (event.target !== this.gridId) {
                return;
            }
            // Filter zurücksetzen
            this.gridFilter = null;
        });

        // Sortierung muss zurückgesetzt werden
        this.gridService.eventResetGridSort.pipe(takeUntil(this.#componentDestroyed$)).subscribe((event: CWEvent) => {
            // Abbruch, falls Anfrage erfolglos war
            if (event.target !== this.gridId) {
                return;
            }
            // Sort zurücksetzen
            this.resetMatTableSort();
            this.gridSort = null;
        });

        // Sortierung wurde im Sort-Panel gesetzt
        this.gridService.eventGridSortChanged.pipe(takeUntil(this.#componentDestroyed$)).subscribe((event: CWEvent) => {
            if (event.target !== this.gridId) {
                return;
            }
            // deaktiviere Spalten-Header-Sortierung
            this.resetMatTableSort();
        });

        // Layout wurde gewechselt
        this.gridService.eventGridLayoutChanged
            .pipe(takeUntil(this.#componentDestroyed$))
            .subscribe((event: CWEvent) => {
                if (event.target !== this.gridId) {
                    return;
                }
                /*
                 * deaktiviere Spalten-Header-Sortierung, da aktive Sortier-Spalte evtl. nicht im neuen Layout enthalten ist
                 * TODO könnte noch verfeinert werden, indem geprüft wird, ob die Spalte weiterhin vorhanden ist,
                 * oder durch Speichern/Laden der aktiven Sortierung mit dem Layout
                 */
                this.resetMatTableSort();
            });

        // Auf Änderung eines Favorits reagieren
        this.gridService.eventGridFavoriteChanged
            .pipe(takeUntil(this.#componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls Anfrage erfolglos war
                if (event.sender !== this.gridId) {
                    return;
                }
                this.onEventGridFavoriteChanged(event);
            });

        // Auf Event der Toolbar zum Refresh der Liste reagieren
        this.toolbarService.eventReload.pipe(takeUntil(this.#componentDestroyed$)).subscribe((result: CWEvent) => {
            // Ziel des Events prüfen
            const event: CWEvent = result;
            // Abbruch, falls das Event nicht für die eigene Komponente ist
            if (event.sender === 'toolbar-reload' && event.target !== this.gridId) {
                return;
            }
            this.resetAndReloadGrid();
        });
        this.gridService.eventGridColumnsChanged.pipe(takeUntil(this.#componentDestroyed$)).subscribe((result) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls das Event nicht vom eigenen Grid kam
            if (event.target !== this.gridId || event.target === 'eventsList') {
                return;
            }

            for (const customCol of result.data.frontendArray) {
                if (hasOwn(customCol, 'customWidth')) {
                    this.customWidths[customCol.id] = customCol.customWidth;
                }
            }
        });
    }

    /**
     * Setzt die Spalten-Header-Sortierung zurück und entfernt die Icons.
     * Über den GridService wird auch dem Sort-Panel mitgeteilt, dass die Sortier-Buttons wieder aktiviert werden sollen.
     */
    resetMatTableSort() {
        // Spalten-Header Icons zurücksetzen
        this.gridSortService.resetColumnHeaders(this.gridColumns);
        if (this.sortView.active) {
            // MatSort Zustand zurücksetzen
            this.sortView.sort({
                id: null,
                start: 'desc',
                disableClear: false,
            });
            this.gridService.headerSortStateChanged(this.gridId, false);
        }
    }

    /**
     * Führt "resetGrid" und "loadData" aus
     */
    resetAndReloadGrid(): void {
        // Daten werden geladen, sobald die Datenquelle gesetzt wird bzw. sich ändert, aber nur wenn alles bereits initialisiert wurde
        if (this.initialized && this.listInitialized) {
            /*
             * && !this.loading
             * Grid resetten
             */
            this.resetGrid();
            this.loadData();
        }
    }

    /**
     * Grid leeren & Grid-Parameter zurücksetzen
     */
    resetGrid(): void {
        this.gridData = [];
        this.gridPageCounter = 1;
        this.selection = new SelectionModel<any>(true, []);
        this.gridService.checkboxUncheckAll(this.gridId);
    }

    /**
     * @description   Daten laden oder Excel-Generierung auslösen
     * @param   {boolean}         startExport     definiert ob Daten exportiert werden sollen
     * @param   {string}          exportTarget    Zuordnung des Exports zu korrekter Toolbar für Ladeanimation
     * @param   {Array<number>}   exportIds       Liste mit IDs der zu exportierenden Datensätze
     * @author  Massimo Feth <m.feth@pharmakon.software>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadData(startExport = false, exportTarget: string = null, exportIds = []): void {
        // Abbruch, falls Paramter fehlt oder Komponenten noch nicht fertig initialisiert
        if (!this.gridBackendSource || !this.initialized || !this.listInitialized) {
            // || this.loading
            return;
        }

        // Flag "loading" aktivieren
        this.loading = true;
        // Change Detection anstoßen, um Loading-Spinner anzuzeigen
        this.changeDetector.detectChanges();

        // Anzahl der Elemente beim ersten Laden zurücksetzen.
        if (this.gridPageCounter === 1) {
            this.totalCount = null;
        }

        // Die Column-Daten nur mitgeben wenn momentan neue Spalten mitgegeben werden sollen.
        const columnData = this.sendColumnsWithLoadData ? this.gridColumnChoice : [];
        // Grid-Optionen zum Senden ans Backend zusammenstellen
        const gridOptions: any = {
            regionsfilter: this.gridRegionsFilter,
            filter: this.gridFilter,
            selectionData: this.gridSelection,
            sort: this.gridSort,
            columnData,
            selectionlist: this.selectedSource,
            page: this.gridPageCounter,
            layout: this.selectedLayout,
        };

        if (this.optionalBackendValues) {
            gridOptions['optionalBackendValues'] = this.optionalBackendValues;
        }

        if (startExport) {
            // Flag "loading" des Grid deaktivieren
            this.loading = false;

            // Grid-Optionen zum Senden ans Backend erweitern für Export
            gridOptions['limit'] = 1000;
            gridOptions['page'] = 1;
            gridOptions['export'] = true;
            gridOptions['exportIds'] = exportIds;
        }

        if (gridOptions['export']) {
            // filename, example: 2022-04-25_13-37-07_InstitutionsList_index.xlsx
            const name = moment().format('YYYY-MM-DD_HH-mm-SS') + '_' + this.gridBackendSource.replace('/', '_');

            const serviceRequest$ = this.gridService.loadDataDocument(this.gridBackendSource, gridOptions);
            serviceRequest$.subscribe((result: Blob) => {
                // Dialog zum Speichern öffnen
                this.fileDownloadService.openSaveDialog(result, name);
                // Animation beenden
                this.toolbarService.loadingComplete(exportTarget, 'Excel');
            });
        } else {
            /*
             * Daten werden über GridService geladen
             * var serviceRequest$ = this.gridService.loadData(this.gridBackendSource, this.selectedSource, this.gridPageCounter);
             */
            const serviceRequest$ = this.gridService.loadData(this.gridBackendSource, gridOptions);
            // Bei erneutem Laufen, sollen noch evtl. noch laufende Requests and die entsprechende Liste unsubscribed werden...
            this.#loadDataSubscription.unsubscribe();
            // Die Subscription muss danach neu erstellt werden.
            this.#loadDataSubscription = new Subscription();
            this.#loadDataSubscription.add(
                serviceRequest$.subscribe((result: any) => {
                    // Die Result-Data kann in zwei unterschiedlichen Formaten ankommen. Dies hier abfangen.
                    let data = [];
                    if (typeof result['data']['list_data'] !== 'undefined') {
                        data = result['data']['list_data'];
                    } else if (typeof result['data'] !== 'undefined') {
                        data = result['data'];
                    }

                    // Falls Daten geladen wurden
                    if (data.length > 0) {
                        // Falls sich die vorhandenen Grid-Daten von den neu geladenen Daten unterscheiden...
                        if (JSON.stringify(this.gridData) !== JSON.stringify(data)) {
                            // Daten werden nun ggf. noch angereichert (z.B. E-Icons)
                            const enrichedData = this.enrichData(data);
                            // Vorhandene Grid-Daten mit neu geladenen Daten erweitern
                            this.gridData = this.gridData.concat(enrichedData);
                            this.gridDataChanged.emit(this.gridData);
                            this.gridService.triggerGridChangeDetection();
                        }
                        // Daten wurden (nach) geladen, Scroll-Semaphore deaktivieren, damit erneut nachgeladen werden kann
                        this.scrollSemaphore = false;
                        // Page erhöhen
                        this.gridPageCounter += 1;
                    }

                    // Soll nach dem Laden direkt die erste Zeile ausgewählt werden?
                    if (this.flagSelectFirstRow === true) {
                        this.selectFirstRow(true);
                        this.flagSelectFirstRow = false;
                    }

                    /**
                     * Falls eine Neuanlage stattfinden soll wird dies hier
                     * über AppCore-CrossModule & Parameter geregelt.
                     *
                     * Notwendig für Neuanlage einer Person, da diese über
                     * E-Details ausgelöst wird und die P-Liste ggf. noch gar
                     * nicht zuvor initialisiert wurde.
                     */
                    if (this.appCore.crossModuleActive === true && this.appCore.crossModuleParameter['newItem']) {
                        this.gridService.selectNewRow(this.gridId, this.appCore.crossModuleParameter['newItem']);
                        this.appCore.crossModuleReset();
                    }

                    /*
                     * Wenn eine Anzahl der ausgewählten Einträge mitgegeben worden ist diese in einer Öffentlichen Variable Speichern.
                     * Die grid-Source-Componente greift darauf dann direkt über die existierende Component-Connection zu
                     */
                    if (typeof result['data']['total_count'] !== 'undefined') {
                        this.totalCount = result['data']['total_count'];
                    }

                    // Flag "loading" deaktivieren
                    this.loading = false;

                    // Change Detection anstoßen
                    this.changeDetector.detectChanges();

                    // Emit the dataLoaded event to notify parent
                    this.dataLoaded.emit();
                }),
            );
        }
    }

    /**
     * @description   Eintreffende Daten (vom Backend) werden ggf. noch angereichert
     *  Hier werden z.B. Einrichtungs-Icons zugeordnet
     * @param {any} data - Eintreffende Daten
     * @returns {any} - Angereicherte Daten
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    enrichData(data: any): any {
        // Das Ergebnis entspricht zunächst den gelieferten Daten
        const resultData: any[] = data;
        const defaultInstitutionIcon: CwIcon = {
            iconName: 'icon-institution',
            iconType: IconType.IconFont,
        };

        const defaultPersonIcon: CwIcon = {
            iconName: 'icon-user',
            iconType: IconType.IconFont,
        };

        // Falls etwas angereichert werden soll
        if (this.useInstitutionsIcons || this.usePeopleIcons) {
            for (const resultItem of resultData) {
                if (this.useInstitutionsIcons) {
                    let searchKey = '';

                    // Liegt "type1" direkt im Datensatz vor (Einrichtung) oder verschachtelt (bei Personen)
                    if (
                        resultItem['institutions'] &&
                        resultItem['institutions'][0] &&
                        resultItem['institutions'][0]['type1']
                    ) {
                        searchKey = resultItem['institutions'][0]['type1'].toString();
                    } else if (resultItem['type1']) {
                        searchKey = resultItem['type1'].toString();
                    }

                    // Suche Key in Iconliste und ordne dies zu. Falls nicht gefunden --> Default-Icon
                    const institutionIcon = this.institutionsIcons.find((i) => i.key === searchKey) as CwIcon;

                    if (institutionIcon && institutionIcon.iconName) {
                        resultItem['institution_icon'] = institutionIcon;
                    } else {
                        resultItem['institution_icon'] = defaultInstitutionIcon;
                    }
                }

                if (this.usePeopleIcons) {
                    let searchKey = '';

                    /*
                     * In der E-Liste liegt "type1" in "people" verschachtelt
                     * Bei Personen liegt "type1" direkt im Datensatz
                     *
                     * TODO: people[] ist leer in E-Liste, obwohl Personen verknüpft -> es wird nach dem falschen type1 gesucht.
                     * Es wird in personType1 nach dem institutionType1 key gesucht -> falsches Icon
                     */
                    if (resultItem['people'] && resultItem['people'][0] && resultItem['people'][0]['type1']) {
                        searchKey = resultItem['people'][0]['type1'].toString();
                    } else if (resultItem['type1']) {
                        searchKey = resultItem['type1'].toString();
                    }

                    // Suche Key in Iconliste und ordne dies zu. Falls nicht gefunden --> Default-Icon
                    const personIcon = this.peopleIcons.find((i) => i.key === searchKey) as CwIcon;
                    if (personIcon && personIcon.iconName) {
                        resultItem['person_icon'] = personIcon;
                    } else {
                        resultItem['person_icon'] = defaultPersonIcon;
                    }
                }
            }
        }

        // Rückgabe
        return resultData;
    }

    /**
     * Mehrere Datensätze aktualisieren
     * @param {any} changedItem - Geänderte Daten
     */
    updateDataItems(changedItem: any): void {
        // Handelt es sich um ein Array von aktualisierten Einträgen
        if (typeof changedItem !== 'undefined' && Array.isArray(changedItem)) {
            for (const singleChangedItem of changedItem) {
                // Das zu aktualisierende Item (in gridRows) über ID des geänderten Items ermitteln
                const itemToBeChanged = this.gridData.find((item) => item.id === singleChangedItem['id']);
                this.updateDataItem(singleChangedItem, itemToBeChanged);
            }
        } else if (typeof changedItem !== 'undefined') {
            // Das zu aktualisierende Item (in gridRows) über ID des geänderten Items ermitteln
            const itemToBeChanged = this.gridData.find((item) => item.id === changedItem['id']);
            this.updateDataItem(changedItem, itemToBeChanged);
        } else {
            // Falls changedItem undefined ist, kann auch nichts aktualisiert werden
        }
    }

    /**
     * @description   Einzelnen Datensatz im Grid aktualisieren
     *  Wird benötigt um eine Datenzeile im Grid zu aktualisieren,
     *          falls sich die Daten z.B. im Stammdatenmodul geändert haben.
     * @param   {any} changedItem     Das geänderte Objekt mit neuen Daten
     * @param   {any} itemToBeChanged Grid-Item, welches aktualisiert werden soll
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    updateDataItem(changedItem: any, itemToBeChanged: any): void {
        /**
         * Das Item mit den neuen / geänderten Daten wird über AppCore als
         * Objekt übergeben. Dieses Objekt kann jederzeit verschwinden bzw.
         * sich erneut ändern. Damit die Informationen im Grid-Item aber
         * persistent übernommen werden muss changedItem über JSON-Umweg
         * zu String und zurück konvertiert werden. Andernfalls behält das zu
         * aktualisierende Grid-Item die Referenz auf changedItem.
         */
        // eslint-disable-next-line no-param-reassign
        changedItem = JSON.parse(JSON.stringify(changedItem));

        /*
         * Damit das Icon in der Einrichtungsliste nicht verschwindet wird,
         * muss das geänderte Item nochmal damit angereichert werden (Nur für Einrichtungen)
         * Es wird das changedItem als Array an enrichData übergeben, da diese Funktion ein Array erwartet.
         * Von dem Result-Array wird wieder das 0. Element genommen.
         */
        if (this.gridId === 'institutionsList') {
            // eslint-disable-next-line no-param-reassign
            changedItem = this.enrichData([changedItem])[0];
        }
        const excludedProperties = ['id', 'number_contacts', 'number_contacts_last_year'];

        if (typeof itemToBeChanged !== 'undefined') {
            // Alle Eigenschaften des geänderten Items durchlaufen
            for (const property in changedItem) {
                if (Object.prototype.hasOwnProperty.call(changedItem, property)) {
                    // ID nicht beachten
                    if (!excludedProperties.includes(property)) {
                        // Prüfe, ob das zu aktualisierende Item ebenfalls diese Eigenschaft aufweist
                        if (Object.prototype.hasOwnProperty.call(itemToBeChanged, property)) {
                            // Aktualisieren
                            // eslint-disable-next-line no-param-reassign
                            itemToBeChanged[property] = changedItem[property];
                        }
                    }
                }
            }
        }
    }

    /**
     * Erste Datenzeile auswählen
     * @param {boolean} dataLoaded - Wurden Daten geladen?
     */
    selectFirstRow(dataLoaded: boolean): void {
        // Falls bereits Daten vorliegen
        if (this.gridData.length > 0) {
            // Erste Zeile auswählen
            const row: any = this.gridData[0];
            this.handleRowClick(row);
        } else if (dataLoaded === false) {
            /*
             * Wurden Daten geladen?
             * Es werden (vermutlich) noch Daten geladen, daher Flag setzen
             */
            this.flagSelectFirstRow = true;
        } else {
            // Laden der Daten wurde abgeschlossen, es liegen aber keine Datenzeilen vor (z.B. bei Suche nach nicht existentem Datensatz)
            this.gridService.emptySelection(this.gridId);
        }
    }

    /**
     * Neue Datenzeile auswählen
     * @param {any} newItem - Neue Datenzeile
     */
    selectNewRow(newItem: any): void {
        this.handleNewRowClick(newItem);
    }

    /**
     * CLICK: Klick auf eine Datenzeile im Grid
     * @param {any} row - Datenzeile
     */
    handleRowClick(row: any): void {
        // Aktuelle Row speichern
        this.currentRow = row;
        // Wechsel der ausgewählten Datenzeile an Service weiterleiten
        this.gridService.selectionChanged(this.gridId, this.currentRow);
    }

    /**
     * Simuliert Klick auf eine NEUE Datenzeile
     * @param {any} newItem - Neue Datenzeile
     */
    handleNewRowClick(newItem: any): void {
        const newRow: any = newItem;
        this.gridService.selectionChanged(this.gridId, newRow);
    }

    /**
     * CONFIRM: Klick auf Confirm-Icon
     * @param {any} row - Datenzeile
     */
    handleRowConfirm(row: any): void {
        this.gridService.rowConfirmClicked(this.gridId, row);
    }

    /**
     * CHECKBOX: Prüft, ob alle Datenzeilen über die Checkboxes selektiert wurden
     * @returns {boolean} - Alle Datenzeilen selektiert?
     */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        // const numRows = this.gridData.data.length;
        const numRows = this.gridData.length;
        return numSelected === numRows;
    }

    /**
     * CHECKBOX: Selektiert alle Zeilen, falls sie nicht selektiert sind, oder löscht die Selektion
     */
    masterToggle(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.gridService.checkboxClicked(this.gridId, this.selection.selected);
        } else {
            // this.gridData.data.forEach(row => this.selection.select(row));
            this.gridData.forEach((row) => this.selection.select(row));
            this.gridService.checkboxClicked(this.gridId, this.selection.selected);
        }
    }

    /**
     * CHECKBOX: Handelt den Klick auf eine einzelne Checkbox
     * @param {any} row - Datenzeile
     * @param {MatCheckboxChange} event - Event
     */
    checkboxToggle(row: any, event: MatCheckboxChange): void {
        // Checkbox wechseln
        this.selection.toggle(row);
        // neuen Wert zwischenspeichern vor Übergabe an CW-Event
        // eslint-disable-next-line no-param-reassign
        row.checked = event.checked;
        // CW-Event auslösen für andere Komponenten
        this.gridService.checkboxClicked(this.gridId, this.selection.selected, row);
    }

    /**
     * RADIO: Handelt den Klick auf einen Radio Button
     * @param {any} row - Datenzeile
     */
    radioToggle(row: any): void {
        this.selection.clear();
        this.selection.select(row);
        this.gridService.radioClicked(this.gridId, this.selection.selected);
    }

    /**
     * SCROLL: Wenn an das Ende des Grids gescrollt wird, soll nachgeladen werden
     *
     * 2019-09-24, PhS(MFe): Wird nicht mehr nur durch Event "wheel", sondern
     * auch "touchmove" aufgerufen, damit das Nachladen der Daten auch auf
     * mobilen Geräten (z.B. iPad) funktioniert.
     * @param {any} event - Event
     */
    onScroll(event: WheelEvent | TouchEvent): void {
        // Nicht für SimpleGrid
        if (this.simpleGrid || this.paginationDisabled) {
            return;
        }

        // Event-Target Element
        const target = event.target as HTMLElement;

        // Container des Element für Scroll aus Event
        const element = target.closest('phscw-grid');

        if (element === null) {
            return;
        }

        // Horizontalen Scroll nicht beachten
        if (0 + element.scrollLeft !== this.horizontalScroll) {
            this.horizontalScroll = 0 + element.scrollLeft;
            return;
        }

        // Höhe des Elements
        const visibleHeight = element.clientHeight;
        // Abstand zum Ende des Elements, bis nachgeladen werden soll
        const threshold = 500;
        // Wie weit kann noch gescrollt werden
        const scrollableHeight = element.scrollHeight;
        // Wie viel verbirgt sich im nicht sichtbaren Bereich
        const hiddenContentHeight = scrollableHeight - visibleHeight;
        // Wenn nicht sichtbarer Bereich im Bereich liegt, bei dem nachgeladen werden soll, wird das Nachladen-Event abgeschickt
        if (hiddenContentHeight - element.scrollTop <= threshold) {
            this.scrolledToEnd.emit();
            // Wenn das Nachladen-Event abgeschickt wurde, wird das Semaphore gesetzt, damit nicht mehrfach nachgeladen wird
            if (this.scrollSemaphore === false) {
                /*
                 * Da die event listener (scroll, mousemove, mousewheel, etc.) außerhalb der Angular-Zone laufen, muss das
                 * Nachladen der Daten in der Angular-Zone laufen, damit die change detection greift und die Daten aktualisiert werden
                 */
                this.zone.run(() => {
                    this.scrollSemaphore = true;
                    this.loadData();
                });
            }
        }
    }

    /**
     * @param {any} val Wert, der geprüft werden soll
     * @returns {boolean} Gibt zurück, ob es sich um ein Array handelt
     */
    isArray(val: any): boolean {
        return Array.isArray(val);
    }

    /**
     * @description   Klick auf Wochentag-Button zum Kontakt anlegen
     * @param   {object}  row         Objekt in Grid
     * @param   {number}  contactId   Nummer des Kontakts
     * @param   {number}  day         Anzahl der Tage von Wochenstart bis geklicktem Tag (1 = MO, 2 = DI, 3 = MI, 4 = DO, 5 = FR)
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    handleQuickManageContact(row: any, contactId: number, day: number) {
        this.gridService.quickManageContactClicked(this.gridId, row, contactId, day);
    }

    /**
     * Hole das Label der Kennzeichen-Ausprägung
     * @param {any} options - Ausprägungen
     * @param {any} optionId - ID der Ausprägung
     * @returns {any} - Label der Ausprägung
     * @todo    Kommentare
     */
    getCharacteristicOptionLabel(options: any, optionId: any): string {
        if (typeof optionId === 'undefined' || optionId === null || optionId === 'null' || optionId[0] === null) {
            return '';
        }
        if (!Array.isArray(optionId)) {
            // ACHTUNG typ unsicherer Vergleich (==) essentiell da unter mssql die kennzeichen IDs string sind
            const option = options.find((val) => val.id == optionId);
            // ACHTUNG typ unsicherer Vergleich (==) essentiell da unter mssql die kennzeichen IDs string sind

            // Early-Return, falls diese nicht gefunden wird
            if (typeof option === 'undefined') {
                return '';
            }
            return option.label;
        }
        let label = '';
        for (const singleOptionId of optionId) {
            // ACHTUNG typ unsicherer Vergleich (==) essentiell da unter mssql die kennzeichen IDs string sind
            const option = options.find((val) => val.id == singleOptionId);
            // ACHTUNG typ unsicherer Vergleich (==) essentiell da unter mssql die kennzeichen IDs string sind

            // Continue, falls diese nicht gefunden wird
            if (typeof option === 'undefined') {
                continue;
            }
            label += option.label + ', ';
        }
        return label.slice(0, -2);
    }

    /**
     * Formatiere das Label des Dezimalkennzeichen
     * @param {any} options Optionen
     * @param {any} value  Wert
     * @returns {any} - Formatiertes Label
     */
    getCharacteristicDecimalLabel(options: any, value: any): string {
        // Init
        let returnValue = value;

        // Leere Werte nicht formatieren
        if (value === null || value === 'null' || typeof value === 'undefined' || value === '') {
            return '';
        }

        // Falls die Listentries noch nicht geladen wurden, den Wert unformatiert ausgeben
        if (this.numberFormatTypes.length === 0) {
            return returnValue;
        }

        // angegebene Formatierung finden
        const formatType = this.numberFormatTypes.find((format: Listentry) => format.list_key === options.formatType);
        // Formatierung anwenden
        if (formatType !== null && typeof formatType !== 'undefined') {
            returnValue = this.inputNumberService.applyCustomFormat(formatType.list_data, value);
        }

        // Währungszeichen anhängen
        if (options.formatType === 'currency') {
            returnValue += ' ' + this.currency;
        }

        // Wert zurückgeben
        return returnValue;
    }

    /*
     * @description   Wert der Direkteingabe Spalte setzen
     *
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    setFinalValue(element: any, value: any, label: any): void {
        // Element wird automatisch gemerged und kann nicht ausgewählt werden
        if (
            (Object.prototype.hasOwnProperty.call(element, 'automerge') && element.automerge === true) ||
            (Object.prototype.hasOwnProperty.call(element, 'disabled') && element.disabled === true)
        ) {
            return;
        }
        // eslint-disable-next-line no-param-reassign
        element.value_final = value || null;
        // eslint-disable-next-line no-param-reassign
        element.value_final_shownText = label || '';

        // Prüfe Sichtbarkeit
        this.checkHiddenAttribute(element);
    }

    /*
     * @description   Aktualisiere Sichtbarkeit der Reihe
     *
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkHiddenAttribute(element: any): void {
        // Prüfe ob Reihe angezeigt werden muss
        if (!(element.value_before === element.value_final && element.value_after === element.value_final)) {
            // eslint-disable-next-line no-param-reassign
            delete element.hidden;
        } else {
            // eslint-disable-next-line no-param-reassign
            element.hidden = true;
        }
    }

    /**
     * Hilfsfunktion zur Prüfung ob es sich bei einem Value um eine Zahl handelt
     * @param {any} value - Zu prüfender Wert
     * @returns {boolean} - Handelt es sich um eine Zahl?
     */
    isNumber(value: any): boolean {
        // Da Zahlenwerte auch als String ankommen können, wird versucht zu parsen
        const parsedValue = parseFloat(value);

        // Falls es sich direkt um eine Zahl handelt oder zu einer Zahl geparsed werden konnte
        if (typeof value === 'number' || !isNaN(parsedValue)) {
            return true;
        }
        return false;
    }

    /**
     * Informiert die Einbindende Komponente, dass die Daten sortiert werden sollen.
     * Kann später auch dazu verwendet werden direkt die Sort-Komponente zu ersetzen.
     * Die komplette MatSort-Funktionalität kann für die generischen Spalten
     * nicht genutzt werden da [innerHTML] MAT-Sort-Eigenschaften überschreibt.
     * @param {MatSort} event MatSort Event
     */
    sortData(event: MatSort): void {
        // Wenn der Header nicht sortierbar ist, kann der Klick ignoriert werden
        if (!this.sortableHeader || this.resizing) {
            return;
        }

        if (this.sortBackend) {
            /*
             * Wenn das Grid Daten direkt vom Backend lädt und auch im Backend sortiert werden soll (z.B. wegen Paging)
             * E- und P-Liste nutzen diesen Weg
             */

            // Ziel-Spalte finden und prüfen ob es die Spalte überhaupt gibt und sie aktuell angezeigt wird
            const newSortColumnName = this.gridDisplayedColumns.find((element) => element === event.active);
            const newSortColumn = this.gridColumns.find((element) => element.columnDef === event.active);
            let listentryName: string;

            if (typeof newSortColumnName === 'undefined') {
                /*
                 * Nicht passender Spaltenheader - sollte nie vorkommen
                 * console.error("Sort-Column not visible in grid: " + event.active);
                 */
                return;
            }
            if (typeof newSortColumn === 'undefined') {
                /*
                 * nicht alle Spalten sind in den Column-Definitionen enthalten
                 * einige Spezialspalten werden nur über die ID referenziert, das Backend muss hier selbst wissen, wonach sortiert wird
                 */
                listentryName = '';
            } else {
                /*
                 *  Listentry-Name wird an Backend übergeben, als Signal,
                 * dass nach den entsprechenden list_sort/value Feldern statt nach dem key sortiert wird
                 */
                listentryName = newSortColumn.listentry || '';
            }

            // Header der alten Sortierspalte zurücksetzen (besser pauschal alle header zurücksetzen, ist sicherer)
            if (typeof this.gridSort !== 'undefined' && this.gridSort !== null) {
                this.gridSortService.resetColumnHeaders(this.gridColumns);
            }

            if (event.direction !== '') {
                // Backend-konformes FilterData Objekt erstellen
                const sortObject = this.gridSortService.prepareBackendSort(
                    newSortColumnName,
                    listentryName,
                    event.direction,
                );
                // Header der neuen Sortierspalte mit Icon versehen
                this.gridSortService.markSortColumnHeader(newSortColumn, sortObject);

                /*
                 * GridService informieren (damit dort Event ausgelöst werden kann
                 * --> damit in übergeordneten Modulen z.B. die Settings gespeichert werden können)
                 * this.gridService.sortChanged(this.gridId, currentSortfield);
                 */

                // Über den Grid-Sort-Service mitteilen, dass die Sortierung nach Header aktiv ist, um z.B. andere Sortierbuttons zu deaktivieren
                this.gridService.headerSortStateChanged(this.gridId, true);

                // Info zur geänderten Sortierung setzen, setter löst den Reload aus
                this.gridSort = sortObject;
            } else {
                // über das Event wird das Sortier-Panel informiert, so dass es die zuvor aktive Button-Sortierung wiederherstellt
                this.gridService.headerSortStateChanged(this.gridId, false);
                /*
                 * this.gridSort wird hier nicht auf null gesetzt, da dies einen unnötigen zusätzlichen Reload auslösen würde
                 * @todo falls die Header-Sortierung auch in Listen ohne Sort-Panel genutzt werden soll,
                 * müsste das z.B. über einen Parameter aktivierbar gemacht werden
                 */
            }
        } else {
            // Informiere, die Komponente, die das Grid nutzt, über den Klick auf den Header, sie behandelt die Sortierung
            this.gridService.gridColumnHeaderClicked(this.gridId, event.active, event.direction);
        }
    }

    /**
     * Auf Änderung eines Favoriten reagieren
     * @param {CWEvent} event - Event
     */
    onEventGridFavoriteChanged(event: CWEvent): void {
        // Daten durchlaufen
        this.gridData.some((entity: any) => {
            // ID prüfen, um Entität zu finden
            if (entity.id === event.data.id) {
                // Flag setzen
                // eslint-disable-next-line no-param-reassign
                entity.favorite = event.data.isFavorite;
                // Durchlaufen abbrechen
                return true;
            }

            // Daten weiter durchlaufen
            return false;
        });
    }

    /**
     * Klick auf einzelne Spalte in Zeile, zum Öffnen der Veranstaltung
     * @param {any} click - Klick-Event
     * @param {number} eventId - ID der Veranstaltung
     * @returns {void}
     */
    selectEventParticipantsOverviewEvent(click: any, eventId: number | string): void {
        // Klick auf Reihe unterbinden
        click.stopPropagation();

        // Klick auf leere Spalte ignorieren
        // eslint-disable-next-line no-param-reassign
        if (eventId === null) {
            return;
        }

        /*
         * String zu Integer konvertieren, falls die
         * Veranstaltungs-ID als String aus dem Backend kommt
         */
        if (typeof eventId === 'string') {
            // eslint-disable-next-line no-param-reassign
            eventId = parseInt(eventId, 10);
        }

        // Klick auf Spalte auslösen und ID der Veranstaltung übergeben
        this.handleRowClick({
            id: eventId,
            showDetails: true,
        });
    }

    /**
     * @description   Entfernt Break-Lines aus dem Text
     *  Sollte ersetzt und generalisiert werden
     *          siehe: https://www.angularjswiki.com/material/tooltip/#adding-multiline-tooltip-using-mat-tooltip
     * @param {string} text - Text mit Break-Lines
     * @returns {string} - Text ohne Break-Lines
     */
    replaceBreaksForTooltip(text: string): string {
        return text.split('<br/>').join('\n');
    }

    /**
     * @description Erstellt ISO-String aus einem Datumsstring. Wird für z.B. Safari gebraucht
     * Todo: Evtl. komplett die Datepipe weglassen und direkt die Moment-
     *        Formatierungs-funktionen nutzen.
     * @param {string | null} date - Datum als String
     * @returns {string} - Datum als ISO-String
     */
    toIsoString(date: string | null): string {
        if (date === undefined || date === 'undefined' || date === null || date === '') {
            return '';
        }
        // Prüft, ob es sich um einen Timestamp handelt, oder einen richtigen Zeitstring
        if (/^-?\d+$/.test(date)) {
            // Timestamp muss erst konvertiert werden, da sonst moment.js nicht mit dem Format zurecht kommt.
            const timestamp: number = parseInt(date, 10);
            return moment(timestamp).toDate().toISOString();
        }
        return moment(date).toDate().toISOString();
    }

    /**
     * @description Formatiert das Backend-Datum in das ISO-Format, das besser weiterverarbeitet werden kann
     * @param {string} date Das Datum als String, wie er aus der DB kommt (z.B. 20211224 12:00:00)
     * @returns {string} Datum im ISO-Format (z.B. 2021-12-24 12:00:00.000)2021-12-09 14:31:12.000
     */
    formatDateFromBackend(date: string): string {
        if (date === undefined || date === null || date === '') {
            return '';
        }
        return date.replace(/^(\d{4})(\d{2})(\d{2})(.*)$/, '$1-$2-$3$4');
    }

    /**
     * @description Behandelt den Klick auf eine Zelle - falls es ein bearbeitbares Kennzeichen ist, wird ein Editor-Dialog geöffnet
     * @param {any} event - Klick-Event
     * @param {any} row - Zeile
     * @param {any} value - Wert
     * @param {any} column - Spalte
     * @param {any} cellRef - Referenz auf die Zelle
     */
    onCellClick(event, row, value, column, cellRef) {
        /*
         * let editedCell = event.target;
         * let editedCell;
         * if (cellRef === null) {
         *     editedCell = event.target;
         * } else {
         *     editedCell = cellRef;
         * }
         * editedCell.classList.add('cw-background-orange');
         * beim Klick auf eine Zelle prüfen, ob sie editierbar ist und den Editor öffnen
         */
        if (
            typeof column.columnDef !== 'undefined' &&
            /^characteristic_/.test(column.columnDef) &&
            typeof column.characteristicDefinition !== 'undefined'
        ) {
            /*
             * Bearbeitung eines Kennzeichens
             * Infos zum Kennzeichen wurden beim Aufbau des Grids in der Spalten-Info verpackt
             */
            const characteristicDefinition = column.characteristicDefinition;
            event.stopPropagation();

            /*
             * Lade die Kennzeichengruppen-Daten für die aktuelle Spalte aus der IndexedDB,
             * da die Column nur den frontendArray kennt, wo edit_right und option_value_type
             * nicht korrekt sind (z.B. immer combobox statt radiobutton)
             */
            const promise = this.storageService.getItem(
                'characteristicsForGroup|' + characteristicDefinition.columnGroup,
            );
            promise.then((val) => {
                if (val !== null && this.isArray(val)) {
                    const realCharacteristic = val.find(
                        (characteristic) => characteristic.id === characteristicDefinition.characteristic_id,
                    );
                    // Berechtigungen prüfen und nur dann Dialog öffnen
                    if (realCharacteristic.edit_right === 1) {
                        // row[column.columnDef] oder value ?
                        this.prepareCharacteristicEditDialog(
                            event,
                            row,
                            column,
                            realCharacteristic,
                            row[column.columnDef],
                            cellRef,
                        );
                        // TODO mit allen Typen testen
                    }
                }
            });
        } else if (
            column.formatTemplate === 'listentries' &&
            (column.columnField === 'event_person_role' || column.columnField === 'event_person_status')
        ) {
            /*
             * Bearbeitung eines List-Entry bei Veranstaltungsteilnehmern
             * TODO falls später für weitere Listentries benötigt, die Einschränkung auf Rolle/Status entfernen
             */

            // TODO Berechtigung für EventsPeople Felder?
            event.stopPropagation();
            this.prepareListentryEditDialog(event, row, column, value, cellRef);
        } else if (
            // Click im Monatsbericht
            column.columnTarget === 'monthlyreport'
        ) {
            // Summe-Zeilen und leere Zellen nicht anklickbar
            if (notEmpty(row.employee_id) && value != '') {
                this.clickContacts(column.columnData, column.columnDay, row);
            }
        }
    }

    /**
     * Klick an Kontaktenanzahl im Monatsbericht
     * @param data
     * @param day
     * @param row
     */
    clickContacts(data: any, day: any, row: any): void {
        data['day'] = day;
        data['employee_id'] = row.employee_id;
        const dialogConfig = {
            minWidth: '50vw',
            maxHeight: '80vh',
            maxWidth: '90vw',
            data: {
                title: row.region_name,
                eventData: data,
            },
        };
        // Dialog konfigurieren und öffnen
        this.dialog.open(ContactsListComponent, dialogConfig);
    }

    /**
     * @description Bereitet die Informationen zum bearbeiteten Listentry vor und startet den Popup-Editor
     * @param {any} event - Klick-Event
     * @param {any} row - Zeile
     * @param {any} column - Spalte
     * @param {any} currentValue - Aktueller Wert
     * @param {any} cellRef - Referenz auf die Zelle
     */
    prepareListentryEditDialog(event: any, row: any, column: any, currentValue: any, cellRef: any) {
        const dialogData = {
            entityId: row.person_id,
            baseEntityId: row.event_id,
            label: column.header,
            currentValue,
            listentry: column.listentry,
            fieldName: column.columnField,
            onChangeCallback: (result: any) => {
                this.updateListentryCellAfterEdit(event.target, row, column, result);
            },
        };

        this.openCellEditDialog(event, GridCellEditListentryComponent, dialogData, cellRef);
    }

    /**
     * @description Bereitet die Informationen zum bearbeiteten Kennzeichen vor und startet den Popup-Editor
     * @param {any} event - Klick-Event
     * @param {any} row - Zeile
     * @param {any} column - Spalte
     * @param {Characteristic} characteristicDefinition - Kennzeichen-Definition
     * @param {any} currentValue - Aktueller Wert
     * @param {any} cellRef - Referenz auf die Zelle
     */
    prepareCharacteristicEditDialog(
        event: any,
        row: any,
        column: any,
        characteristicDefinition: Characteristic,
        currentValue: any,
        cellRef: any,
    ) {
        let preparedValue;

        if (
            characteristicDefinition.value_type === 'options' &&
            characteristicDefinition.option_value_type === 'checkbox'
        ) {
            /*
             *  Datenrepräsentation für Checkboxen an Format anpassen,
             * wie es bei den Kennzeicheneditoren verwendet wird {"1": true, "3": true} statt [1, 3]
             */
            preparedValue = {};
            if (currentValue != null && typeof currentValue !== 'undefined') {
                if (this.isArray(currentValue)) {
                    for (const option of currentValue) {
                        if (option !== null) {
                            preparedValue[option.toString()] = true;
                        }
                    }
                } else {
                    // Einzelwert
                    preparedValue[currentValue.toString()] = true;
                }
            }
        } else if (
            characteristicDefinition.value_type === 'options' &&
            characteristicDefinition.option_value_type === 'radiobutton'
        ) {
            // Datenrepräsentation für Radiobuttons anpassen, muss Integer sein
            preparedValue = parseInt(currentValue, 10);
        } else {
            preparedValue = currentValue;
        }

        const dialogData = {
            characteristicDefinition,
            currentValue: preparedValue,
            onChangeCallback: (result: any) => {
                this.updateCharacteristicCellAfterEdit(event.target, row, column, result);
            },
        };
        // Entity-IDs ermitteln, sind leider bei EventsPeople mehrere/andere als bei den anderen Listen
        if (this.gridId === 'eventsPeopleList') {
            dialogData['entityId'] = row.person_id;
            dialogData['baseEntityId'] = row.event_id;
        } else {
            dialogData['entityId'] = row.id;
        }

        this.openCellEditDialog(event, CharacteristicSingleEditPopupComponent, dialogData, cellRef);
    }

    /**
     * @description Öffnet den Editor für einen Zellinhalt, leicht versetzt und markiert die Zelle farbig
     * @param {any} event - Klick-Event
     * @param {any} editorPopupClass - Klasse des Editors
     * @param {object} dialogData - Daten für den Dialog
     * @param {any} cellRef - Referenz auf die Zelle
     */
    openCellEditDialog(event: any, editorPopupClass: any, dialogData: object, cellRef: any) {
        /*
         * Referenz auf Zelle speichern, um beim Schließen des Dialogs die Markierung zu entfernen
         * da event.target bei Listentries je nach Klick-Position auf das innen liegende Input
         * statt auf die Zelle zeigt, wird die Referenz auf die Zelle mitgegeben
         * let editedCell = event.target;
         * während Bearbeitung Zelle orange markieren
         */
        cellRef.classList.add('cw-background-orange');
        // Position festlegen, etwas versetzt, damit markierte Zelle sichtbar bleibt und der Bezug von Dialog und Zelle erkennbar ist
        const element = event.target.getBoundingClientRect();
        // Top
        const dialogTop = element.top + element.height / 1.75;
        // Left
        const dialogWidth = 400;
        const clientWidth = document.body.clientWidth;
        let dialogLeft = element.left + element.width / 3;

        // Falls Dialog außerhalb des sichtbaren Bereichs ist ...
        if (dialogLeft + dialogWidth > clientWidth) {
            // ... die Position abhängig vom rechten Rand setzen
            dialogLeft = clientWidth - 20 - dialogWidth;
        }

        const dialogRef = this.dialog.open(editorPopupClass, {
            height: 'auto',
            maxHeight: 'calc(100vh - ' + dialogTop + 'px)',
            width: dialogWidth + 'px',
            position: {
                top: dialogTop + 'px',
                left: dialogLeft + 'px',
            },
            data: dialogData,
        });

        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe(() => {
            cellRef.classList.remove('cw-background-orange');
        });
    }

    /**
     * @description Callback-Funktion, wird aufgerufen, nachdem ein Kennzeichen im Single-Editor bearbeitet wurde. Aktualisiert die Zelle in der Liste.
     * @param {any} editedCell Referenz auf die bearbeitete Zelle
     * @param {any} row       Modell der Zeile mit den aktuellen Daten der Entity
     * @param {any} column    Spaltendefinition
     * @param {any} changedCharacteristic Vom Backend zurückgegebenes Ergebnis nach dem Speichern
     */
    updateCharacteristicCellAfterEdit(editedCell: any, row: any, column: any, changedCharacteristic: any) {
        /*
         * Rohwert im Modell der Zeile aktualisieren
         * und angezeigten Wert ermitteln, analog zur erstmaligen Darstellung im HTML
         * let renderedValue: string;
         */
        if (column.formatTemplate === 'characteristics') {
            if (this.isArray(changedCharacteristic)) {
                // Mehrfachkennzeichen/Checkboxen für Darstellung in Zelle wieder in einfachen Array wandeln
                const selectedOptions = [];
                for (const changedOption of changedCharacteristic) {
                    selectedOptions.push(changedOption.characteristic_option_id);
                }
                // eslint-disable-next-line no-param-reassign
                row[column.columnDef] = selectedOptions;
            } else {
                // Einfacher Optionswert
                // eslint-disable-next-line no-lonely-if
                if (typeof changedCharacteristic.characteristic_option_id === 'undefined') {
                    // bei leeren Auswahlen vermeiden, dass "undefined" in der Zelle steht
                    // eslint-disable-next-line no-param-reassign
                    row[column.columnDef] = '';
                } else {
                    // eslint-disable-next-line no-param-reassign
                    row[column.columnDef] = changedCharacteristic.characteristic_option_id;
                }
            }
            // renderedValue = this.getCharacteristicOptionLabel(column.options, column.cell(row));
        } else if (column.formatTemplate === 'characteristic_decimal') {
            if (typeof changedCharacteristic.value_decimal === 'undefined') {
                // bei leeren Auswahlen vermeiden, dass "undefined" in der Zelle steht
                // eslint-disable-next-line no-param-reassign
                row[column.columnDef] = '';
            } else {
                // eslint-disable-next-line no-param-reassign
                row[column.columnDef] = changedCharacteristic.value_decimal;
            }
            // renderedValue = this.getCharacteristicDecimalLabel(column.options, column.cell(row));
        } else if (column.characteristicDefinition.value_type === 'date') {
            // eslint-disable-next-line no-param-reassign
            row[column.columnDef] = this.formatDateFromBackend(changedCharacteristic.value_date || '');
            // renderedValue = (column.cell(row));
        } else {
            // nicht speziell formatiertes Kennzeichen, z.B. einfacher String
            // eslint-disable-next-line no-lonely-if
            if (typeof changedCharacteristic.value_string === 'undefined') {
                // bei leeren Auswahlen vermeiden, dass "undefined" in der Zelle steht
                // eslint-disable-next-line no-param-reassign
                row[column.columnDef] = '';
            } else {
                // eslint-disable-next-line no-param-reassign
                row[column.columnDef] = changedCharacteristic.value_string;
            }
            // renderedValue = column.cell(row);
        }

        // Zelle aktualisieren
        this.changeDetector.markForCheck();
    }

    /**
     * @description Callback-Funktion, wird aufgerufen, nachdem ein Listentry im Single-Editor bearbeitet wurde. Aktualisiert die Zelle in der Liste.
     * @param {any} editedCell Referenz auf die bearbeitete Zelle
     * @param {any} row        Modell der Zeile mit den aktuellen Daten der Entity
     * @param {any} column     Spaltendefinition
     * @param {any} data       Vom Backend zurückgegebenes Ergebnis nach dem Speichern
     */
    updateListentryCellAfterEdit(editedCell: any, row: any, column: any, data: any) {
        if (column.formatTemplate === 'listentries') {
            // Wert in row tauschen, dadurch wird automatisch der Zellinhalt aktualisiert
            // eslint-disable-next-line no-param-reassign
            row[column.columnField] = data[column.columnField];
        }
    }

    /**
     * @description   Fügt Event-Listener für das Resizen der Spalten hinzu
     *          Wichtig: Die Event-Listener müssen außerhalb der Angular-Zone laufen, da sonst die Performance leidet
     * @returns  {void}
     */
    addEventListeners(): void {
        const mousemove$ = fromEvent(document, 'mousemove').pipe(takeUntil(this.#componentDestroyed$));
        const mouseup$ = fromEvent(document, 'mouseup').pipe(takeUntil(this.#componentDestroyed$));
        const touchmove$ = fromEvent(document, 'touchmove').pipe(
            takeUntil(this.#componentDestroyed$),
            debounceTime(200),
        );
        const wheel$ = fromEvent(document, 'wheel').pipe(takeUntil(this.#componentDestroyed$), debounceTime(200));

        // Event-Listener außerhalb der Angular-Zone laufen lassen, damit die change detection nicht getriggert wird
        this.zone.runOutsideAngular(() => {
            mousemove$.subscribe((event: MouseEvent) => {
                if (this.resizing && this.resizingCol) {
                    this.updateWidthAndStartPos(event);
                }
            });

            mouseup$.subscribe((event: MouseEvent) => {
                if (this.resizing && this.resizingCol) {
                    this.finalizeResize(event);
                }
            });

            touchmove$.subscribe((event: TouchEvent) => {
                this.onScroll(event);
            });

            wheel$.subscribe((event: WheelEvent) => {
                this.onScroll(event);
            });
        });
    }

    // Helper method for updating width and start position
    private updateWidthAndStartPos(event: MouseEvent) {
        if (this.startPosX === null && this.startPosY === null) {
            this.startPosX = event.x;
            this.startPosY = event.y;
        }
        this.customWidths[this.resizingCol.columnDef] = this.calcWidth(event) + 'px';
        this.startPosX = event.x;
    }

    // Helper method for finalizing resize process
    private finalizeResize(event: MouseEvent) {
        this.customWidths[this.resizingCol.columnDef] = this.calcWidth(event) + 'px';

        const backendRequest$ = this.gridService.saveCustomWidth(
            this.selectedLayout,
            this.resizingCol.columnDef,
            this.customWidths[this.resizingCol.columnDef],
        );
        backendRequest$.subscribe(() => {});

        this.startPosX = null;
        this.startPosY = null;
        this.resizingCol = null;

        // Delay sorting logic for matSortChange
        setTimeout(() => {
            this.resizing = false;
        }, 100);
    }

    /**
     * @description   Setzt die Breite der Spalten
     *          wird im template verwendet
     * @param   {any} column - Spalte
     * @returns  {any} - Breite der Spalte
     */
    getWidth(column: any) {
        let result = '200px';

        if (column.formatWidth) {
            result = column.formatWidth;
        }
        if (this.customWidths && this.customWidths[column.columnDef]) {
            result = this.customWidths[column.columnDef];
        }

        return {
            width: result,
            'max-width': result,
        };
    }

    onResizeColumn(event, column) {
        // Set the resizing flag to true
        this.resizing = true;
        // Prevent text selection during dragging
        event.preventDefault();
        this.resizingCol = column;
    }

    calcWidth(event) {
        const movedDistance = event.x - this.startPosX;
        let currentSizeStr = '400px';
        if (this.customWidths[this.resizingCol.columnDef]) {
            currentSizeStr = this.customWidths[this.resizingCol.columnDef];
        } else if (this.resizingCol.formatWidth) {
            currentSizeStr = this.resizingCol.formatWidth;
        }
        const currentSize = parseInt(currentSizeStr.replace(/px/g, ''), 10);
        let currentSizeWithoutPx = movedDistance + currentSize;
        if (currentSizeWithoutPx < 40) {
            currentSizeWithoutPx = 40;
        }
        if (currentSizeWithoutPx > 1200) {
            currentSizeWithoutPx = 1200;
        }

        /**
         * Manuell die Änderung anstoßen, damit die Spalte sich direkt anpasst, sieht sonst komisch aus
         * Performance ist hier nicht so wichtig, da es nur beim Resizen passiert
         */
        this.changeDetector.detectChanges();
        return currentSizeWithoutPx;
    }

    /**
     * @description Gibt das Format für die number Pipeline zurück
     * @returns {string} - Format für die number Pipeline
     */
    getCurrencyFormat(): string {
        // In Umsatzanalyse und Produktanalyse
        if (this.gridId === 'salesAnalysis' || this.gridId === 'salesAnalysisProductView') {
            return this.includeDecimalsSalesAndProductsAnalysis ? '1.2-2' : '1.0-0';
        }

        return '1.2-2';
    }

    // Hilfsmethode um zu prüfen ob Null enthalten ist
    hasNullValues(obj) {
        if (!obj || typeof obj !== 'object') return false;
        return Object.values(obj).some((value) => value === null);
    }
}
