/**
 * @brief   Shared OutputModule zur Freigabe der Output-Components.
 * @details Um die Output-Components in Feature Modulen verwenden zu können,
 *          wird dieses Shared Module eingebunden.
 *          Die Einbindung erfolgt im Feature Module (z.B. people.module.ts)
 *          und nicht in den einzelnen Komponenten des Feature Moduls.
 *
 *          // Shared Modules einbinden
 *          import {OutputModule} from '../../shared/output/output.module';
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
// Komponenten
import {OutputTimestampComponent} from './output-timestamp/output-timestamp.component';
import {OutputAdditionalDataComponent} from './output-additional-data/output-additional-data.component';
import {GridModule} from '../../shared/grid/grid.module';

@NgModule({
    // Importierte Module
    imports: [CommonModule, GridModule],
    // Komponenten dieses Shared Modules
    declarations: [OutputTimestampComponent, OutputAdditionalDataComponent],
    // Komponenten, die dieses Shared Module freigeben soll
    exports: [OutputTimestampComponent, OutputAdditionalDataComponent],
})
export class OutputModule {}
