import {Component, Input, OnInit, OnDestroy} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Service der übergeordneten Komponente
import {ToolbarService} from '../toolbar.service';

@Component({
    selector: 'phscw-toolbar-send-report',
    templateUrl: './toolbar-send-report.component.html',
    styleUrls: ['./toolbar-send-report.component.scss'],
})
export class ToolbarSendReportComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;
    // Tooltip
    @Input() tooltip = '';
    // Icon
    @Input() icon = 'icon-envelope';
    // Parameter
    @Input() parameter: any;

    constructor(private toolbarService: ToolbarService) {}

    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /*
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     * @author  Min Hye Park <m.park@pharmakon.software>
     */
    initializeEventSubscriptions(): void {}

    /*
     * Event auslösen
     */
    clickSendReport() {
        this.toolbarService.sendReport(this.componentIdentifier, 'toolbar-send-report');
    }
}
