// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `phscw-grid ::ng-deep .mat-mdc-row:not(.hide-children) {
  height: 32px;
}

phscw-grid ::ng-deep .mat-mdc-row:not(.hide-children) .mat-mdc-cell {
  height: 30px;
  max-height: 32px;
}

phscw-grid ::ng-deep .mat-mdc-row:not(.hide-children) .mat-mdc-cell:last-of-type {
  padding-right: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/chart/chart-top-five-worst-five/chart-top-five-worst-five.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;EACA,gBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":["phscw-grid ::ng-deep .mat-mdc-row:not(.hide-children) {\n    height: 32px;\n}\n\nphscw-grid ::ng-deep .mat-mdc-row:not(.hide-children) .mat-mdc-cell {\n    height: 30px;\n    max-height: 32px;\n}\n\nphscw-grid ::ng-deep .mat-mdc-row:not(.hide-children) .mat-mdc-cell:last-of-type {\n    padding-right: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
