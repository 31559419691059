// Angular Module
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Interfaces für Structured Objects einbinden
import {MenuData} from '@shared/menu-data';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
import {hasOwn} from '@shared/utils';

@Component({
    selector: 'phscw-overlay-menu',
    templateUrl: './overlay-menu.component.html',
    styleUrls: ['./overlay-menu.component.scss'],
})
export class OverlayMenuComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('arrow-position') position = 'top';

    // Anzuzeigende Items von außen (@Input) deklariert. MenuData-Array.
    @Input() items: MenuData[] = [];
    // Component-Event-Binding für angeklickte Items
    @Output() itemClicked = new EventEmitter<any>();

    // @todo: Beschreibung
    chosenCategory = '';

    // Aktuelle Sprache
    langKeyReference = '';

    /**
     * Konstruktor (inkl. dependency injection)
     * @param translate
     */
    constructor(private translate: TranslateService) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // aktuelle Sprache laden
        this.langKeyReference = this.translate.currentLang;

        // Übersetzungen subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Event subscriben
     * @author  Min Hye Park <m.park@pharmakon.software>
     */
    initializeEventSubscriptions() {
        // Auf Ändern der Sprache reagieren
        this.translate.onLangChange.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: any) => {
            this.langKeyReference = result.lang;
        });
    }

    /**
     * @param item
     * @brief   Click-Handler für Items
     */
    clickItem(item: MenuData): void {
        // Event nicht auslösen, wenn der Menüpunkt deaktiviert ist
        if (hasOwn(item, 'disabled') && item.disabled === true) {
            return;
        }

        // Wenn aktive Kategorie nochmal angeklickt wird, blende sie wieder aus
        if (this.chosenCategory === item['name']) {
            this.chosenCategory = '';
        } else {
            this.chosenCategory = item['name'];
            // Wenn angeklickter Menüpunkt Unterpunkt einer Kategorie ist, löse ein Event aus
            if (item['clickable'] !== 'N') {
                this.itemClicked.emit(item);
            }
        }
    }
}
