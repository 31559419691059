// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}
:host ::ng-deep .mat-mdc-form-field-underline,
:host ::ng-deep .mat-mdc-form-field-subscript-wrapper,
:host ::ng-deep .mdc-line-ripple {
  display: none;
}
:host ::ng-deep .mat-mdc-select-span {
  vertical-align: sub;
}
:host ::ng-deep .mat-mdc-select-arrow-wrapper {
  visibility: hidden;
}
:host ::ng-deep .mat-mdc-select-value {
  font: normal normal 400 1.3rem/1 "Roboto", sans-serif;
}
:host ::ng-deep .mat-mdc-form-field-infix {
  min-height: 25px !important;
  padding: 0 !important;
}
:host ::ng-deep .mat-mdc-text-field-wrapper {
  padding: 0 !important;
}
:host ::ng-deep .mat-mdc-pseudo-checkbox-checked {
  background: #ffa726;
}
:host ::ng-deep .mat-mdc-pseudo-checkbox-checked::after {
  left: 0px;
  width: 11px;
  height: 5px;
}

.cw-label {
  margin: auto 5px;
}

.mat-mdc-form-field {
  width: 100%;
}

.cw-select {
  background-color: var(--color-default-light);
  width: 100%;
}

:host .cw-input-span {
  align-self: center;
}

.cw-select:disabled {
  background-color: rgb(235, 235, 228);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/input/input-multiselect/input-multiselect.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAEQ;;;EAGI,aAAA;AAAZ;AAGQ;EACI,mBAAA;AADZ;AAIQ;EACI,kBAAA;AAFZ;AAKQ;EACI,qDACI;AAJhB;AAQQ;EACI,2BAAA;EACA,qBAAA;AANZ;AASQ;EACI,qBAAA;AAPZ;AAUQ;EACI,mBAAA;AARZ;AAWQ;EACI,SAAA;EACA,WAAA;EACA,WAAA;AATZ;;AAcA;EACI,gBAAA;AAXJ;;AAcA;EACI,WAAA;AAXJ;;AAcA;EACI,4CAAA;EACA,WAAA;AAXJ;;AAcA;EACI,kBAAA;AAXJ;;AAcA;EACI,oCAAA;AAXJ","sourcesContent":[":host {\n    width: 100%;\n\n    ::ng-deep {\n        .mat-mdc-form-field-underline,\n        .mat-mdc-form-field-subscript-wrapper,\n        .mdc-line-ripple {\n            display: none;\n        }\n\n        .mat-mdc-select-span {\n            vertical-align: sub;\n        }\n\n        .mat-mdc-select-arrow-wrapper {\n            visibility: hidden;\n        }\n\n        .mat-mdc-select-value {\n            font:\n                normal normal 400 1.3rem / 1 'Roboto',\n                sans-serif;\n        }\n\n        .mat-mdc-form-field-infix {\n            min-height: 25px !important;\n            padding: 0 !important;\n        }\n\n        .mat-mdc-text-field-wrapper {\n            padding: 0 !important;\n        }\n\n        .mat-mdc-pseudo-checkbox-checked {\n            background: #ffa726;\n        }\n\n        .mat-mdc-pseudo-checkbox-checked::after {\n            left: 0px;\n            width: 11px;\n            height: 5px;\n        }\n    }\n}\n\n.cw-label {\n    margin: auto 5px;\n}\n\n.mat-mdc-form-field {\n    width: 100%;\n}\n\n.cw-select {\n    background-color: var(--color-default-light);\n    width: 100%;\n}\n\n:host .cw-input-span {\n    align-self: center;\n}\n\n.cw-select:disabled {\n    background-color: rgb(235, 235, 228);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
