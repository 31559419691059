import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-targets-employee',
    templateUrl: './chart-targets-employee.component.html',
    styleUrls: ['./chart-targets-employee.component.scss'],
})
export class ChartTargetsEmployeeComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
