// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
:host {
  width: 100%;
}

:host .cw-module-card {
  padding-bottom: 10px;
}

:host .cw-button-wrapper button {
  margin: 10px 0 0 10px;
  max-width: 200px;
  min-width: 185px;
  width: calc(25% - 10px);
}

:host .cw-button-wrapper button:nth-child(5n):nth-child(-n+4) {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid-sort/grid-sort.component.scss"],"names":[],"mappings":"AAAA;;;;;EAAA;AAOA;EACI,WAAA;AAAJ;;AAGA;EACI,oBAAA;AAAJ;;AAGA;EACI,qBAAA;EACA,gBAAA;EACA,gBAAA;EACA,uBAAA;AAAJ;;AAGA;EACI,aAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n */\n\n:host {\n    width: 100%;\n}\n\n:host .cw-module-card {\n    padding-bottom: 10px;\n}\n\n:host .cw-button-wrapper button {\n    margin: 10px 0 0 10px;\n    max-width: 200px;\n    min-width: 185px;\n    width: calc(25% - 10px);\n}\n\n:host .cw-button-wrapper button:nth-child(5n):nth-child(-n + 4) {\n    margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
