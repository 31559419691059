// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
import {StorageService} from '@global/services/storage.service';
// Interfaces für Structured Objects einbinden
import {Person} from '@shared/person';

@Injectable({providedIn: 'root'})
export class PeopleService {
    // Ausgewählte Person
    public selectedPerson: Person;

    public personIsInOwnRegion = false;
    public allowEditPerson = false;
    public allowEditForeignPerson = false;
    public allowEditCombined = false;
    // Subject (Observable) definieren "Es wurde eine andere Person ausgewählt"
    public selectionChanged = new Subject<number>();

    // Ob Formular benutzt werden soll, Änderungsanfragen abzuschicken
    public isShadow = false;

    // Konstruktor
    constructor(
        private storageService: StorageService,
        private appCore: AppCoreService,
        private backendService: BackendService,
    ) {}

    /*
     * @brief   Wird aufgerufen, falls eine andere Person ausgewählt werden soll
     */
    selectPerson(selectedPerson: Person) {
        // Ausgewählte Person zwischenspeichern
        this.selectedPerson = selectedPerson;
        // Event auslösen um andere Komponenten des Feature-Moduls zu informieren
        this.selectionChanged.next(this.selectedPerson.id);
    }

    /*
     * @brief   Wird aufgerufen, falls die Auswahl "geleert" werden soll
     */
    selectEmptyPerson() {
        this.selectedPerson = null;
        this.selectionChanged.next(-1);
    }

    /*
     * @brief   Wird aufgerufen, um Icons der Person zu aktualisieren
     */
    updatePersonIcons(iconString: string) {
        this.selectedPerson.icons = iconString;
    }

    /**
     * @param institutionId
     * @brief   Starte Filterung für Personen einer Einrichtung
     * @details Dies wird z.B. aufgerufen, wenn in der E-Liste das Personenicon
     *          angeklickt wurde um zu den Personen der Einrichtung zu
     *          wechseln ODER um nach der Neuanlage einer Person die Auswahl
     *          in der P-Liste (Alle Personen der Einrichtung) neu zu laden.
     * @see     Diese Filter-Funktion existiert auch in institutions.service.ts,
     *          da sowohl in E-Modulen als auch P-Modulen benötigt.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    executeFilterPeopleOfInstitution(institutionId: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'PeopleInstitutions/filterBy/institution/' + institutionId,
        );
        // Observable (an Komponente) zurückliefern
        return getRequest$;
    }

    /**
     * @param personId
     * @brief   Starte Filterung für Einrichtungen einer Person
     * @details Dies wird z.B. aufgerufen, wenn in der P-Liste das Einrichtungsicon
     *          angeklickt wurde um zu den Einrichtungen der Person zu
     *          wechseln.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    executeFilterInstitutionsOfPerson(personId: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'PeopleInstitutions/filterBy/person/' + personId,
        );
        // Observable (an Komponente) zurückliefern
        return getRequest$;
    }

    /**
     * @param institutionId
     * @param checkHierarchy
     * @brief   Starte Filterung auf eine ausgewählte Einrichtung
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    executeFilterInstitution(institutionId: number, checkHierarchy = false): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'PeopleInstitutions/select/institution/' + institutionId + '/' + checkHierarchy,
        );
        // Observable (an Komponente) zurückliefern
        return getRequest$;
    }

    /**
     * @param eventId
     * @brief   Starte Filterung für eine Veranstaltung einer Person
     * @details Dies wird z.B. aufgerufen, wenn in der P-Veranstaltung-Liste das
     *          Veranstaltungsicon angeklickt wurde um zu der Veranstaltung der
     *          Person zu wechseln.
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    executeFilterEvent(eventId: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('EventsPeople/select/event/' + eventId);
        // Observable (an Komponente) zurückliefern
        return getRequest$;
    }

    /**
     * @param data
     * @param allowEditPerson
     * @param allowEditForeignPerson
     * @param allowEditCombined
     * @param isB2C
     * @brief   Prüfen, ob der Mitarbeiter eine Person im eigenen Gebiet ausgewählt hat.
     * @author  Daniel Nita <d.nita@pharmakon.software>
     */
    checkPersonOwnRegion(
        data: any,
        allowEditPerson: boolean,
        allowEditForeignPerson: boolean,
        allowEditCombined: boolean,
        isB2C = false,
    ): any {
        this.allowEditPerson = allowEditPerson;
        this.allowEditForeignPerson = allowEditForeignPerson;
        const promise = this.storageService.getItem('ownUser');
        promise.then((userData: any) => {
            // Region zwischenspeichern
            const ownRegionId = userData.region_id;
            if (!data.institutions[0] || !data.institutions[0].regions) {
                this.personIsInOwnRegion = false;
                return;
            }
            // Prüfen, ob die Einrichtung im eigenen Gebiet ist
            let conditionResult = false;
            if (data.institutions && data.institutions.length > 0 && data.institutions[0].regions) {
                if (data.institutions[0].regions.some((region: any) => ownRegionId == region.id)) {
                    conditionResult = true;
                }
            }

            // Wert setzen
            this.personIsInOwnRegion = conditionResult;
            this.allowEditCombined = allowEditCombined;
        });
        if (isB2C) {
            this.allowEditCombined = true;
        }
        return promise;
    }
}
