// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Service dieses Shared-Moduls
import {ToolbarService} from '../toolbar.service';

@Component({
    selector: 'phscw-toolbar-switch-view',
    templateUrl: './toolbar-switch-view.component.html',
    styleUrls: ['./toolbar-switch-view.component.scss'],
})
export class ToolbarSwitchViewComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;
    // Tooltip
    @Input() tooltip: string = null;
    // Icon-Klasse für Button
    @Input() iconClass = 'icon-calendar';

    // Konstruktor
    constructor(private toolbarService: ToolbarService) {}

    // Initialisierungen
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    // Events subscriben
    initializeEventSubscriptions() {}

    // Ansicht wechseln
    switchView() {
        // Wechseln des Fullscreens über ToolbarService
        this.toolbarService.switchView(this.componentIdentifier);
    }
}
