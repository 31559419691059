import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-amount-characteristics',
    templateUrl: './chart-amount-characteristics.component.html',
    styleUrls: ['./chart-amount-characteristics.component.scss'],
})
export class ChartAmountCharacteristicsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
