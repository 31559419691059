// Oberklasse importieren
import {DialogData, OverlayInfoComponent} from './../overlay-info.component';
// Angular-Module
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// Service dieses Shared-Moduls
import {OverlayService} from './../../overlay.service';
// Services der Feature-Modulen (zu denen ggf. gewechselt werden soll)
import {SalesService} from '@modules/sales/sales.service';
// Eigener Service
import {OverlayInfoService} from './../overlay-info.service';
// Shared Components
import {GlobalComponentPopupComponent} from '@global/components/global-component-popup/global-component-popup.component';
import {InstitutionsPeopleComponent} from '@modules/institutions/institutions-people/institutions-people.component';
// Interfaces für Structured Objects einbinden
import {CWResult} from '@shared/cw-result';
import {LooseObject} from '@shared/loose-object';
// Environment einbinden
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

/*
 * @brief   Komponente um Einrichtungsdetails in einem
 *          Info-Popup anzuzeigen.
 *
 * @author  Sena Üner <s.uener@pharmakons.software>
 */
@Component({
    selector: 'phscw-overlay-info-institution',
    templateUrl: './overlay-info-institution.component.html',
    styleUrls: ['./../overlay-info.component.scss', './overlay-info-institution.component.scss'],
})
export class OverlayInfoInstitutionComponent extends OverlayInfoComponent implements OnInit, OnDestroy {
    // Darf vom EinrichtungsPopup auf die Produktanalyse gesprungen werden
    allowChangeToSalesAnalysisProductView = false;

    // Adresse der Einrichtung
    institutionAddress = '';

    /**
     * Konstruktor (inkl. dependency injection)
     * @param dialogRef
     * @param data
     * @param overlayService
     * @param dialog
     * @param overlayInfoService
     * @param salesService
     */
    constructor(
        protected dialogRef: MatDialogRef<OverlayInfoComponent>,
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        protected overlayService: OverlayService,
        private dialog: MatDialog,
        private overlayInfoService: OverlayInfoService,
        private salesService: SalesService,
    ) {
        // Konstruktor der vererbenden Komponente aufrufen
        super(dialogRef, data, overlayService);
    }

    /**
     * Initialisieren
     */
    ngOnInit() {
        // onInit der vererbenden Komponente aufrufen
        super.ngOnInit();

        // Environment-Variable laden
        if (hasOwn(environment, 'allowSalesAnalysisProductView')) {
            this.allowChangeToSalesAnalysisProductView = environment.allowSalesAnalysisProductView;
        }
    }

    /**
     * @brief   Daten laden
     * @author  Sena Üner <s.uener@pharmakon.software>
     */
    loadData(): void {
        // Flag "loading" aktivieren
        this.loading = true;
        this.success = false;

        // Daten der entsprechenden Entität laden
        const serviceRequest$ = this.overlayService.loadOverlayInformation(
            this.data.backendController,
            this.data.entityId,
        );
        serviceRequest$.subscribe(
            (result: CWResult) => {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zum angefragten Element passen. Durch asynchrone
                 * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                 * bereits das Element gewechselt wurde und die Antwort
                 * eines Requests verspätet eintrifft und dadurch die
                 * korrekten Daten wieder überschreibt.
                 */
                if (result.data === null || this.overlayService.entityId != result.data['id']) {
                    this.loading = false;
                    return;
                }

                if (result.success) {
                    // empfangene Daten zwischenspeichern
                    this.backendData = result.data;

                    // Titel setzen
                    this.setHeaderText();

                    // Adressblock zusammenbauen
                    this.institutionAddress = this.overlayInfoService.getInstitutionAddress(this.backendData);

                    // Flag "success" aktivieren
                    this.success = true;
                    // Flag "AdviceCheckbox" aktivieren
                    this.displayAdviceCheckbox = this.data.displayAdviceCheckbox;
                }

                // Flag "loading" deaktivieren
                this.loading = false;
            },
            (error: any) => {
                // Flag "loading" deaktivieren
                this.loading = false;
            },
        );
    }

    /**
     * @brief   Header-Text ändern
     * @author  Sena Üner <s.uener@pharmakon.software>
     */
    setHeaderText(): void {
        this.headerText = this.getFullInstitutionName();
    }

    /**
     * @brief   Einrichtung Name zusammenführen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getFullInstitutionName(): string {
        // Intialisiere
        let returnValue = '';

        // Name
        if (this.backendData && this.backendData.name1) {
            returnValue += this.backendData.name1;
        }

        // Return
        return returnValue;
    }

    /**
     * Spezifische Funktion für den Wechsel zur Produktanalyse. Anderes Vorgehen
     * als beim Wechsel zu Personen oder Einrichtungsliste.
     * @param target
     * @param institutionId
     * @author   Michael Schiffner <m.schiffner@pharmakon.software>
     */
    customSalesAnalysisProductViewNavigation(target: string, institutionId: number): void {
        // String für Einrichtungsname mit Adresse generieren
        let institutionName = '';
        if (this.backendData.name1) {
            institutionName += this.backendData.name1;
        }
        if (this.backendData.zipcode && this.backendData.city) {
            institutionName += ', ' + this.backendData.zipcode + ' ' + this.backendData.city;
        }

        // Falls das Modul schon initialisiert ist, löse ich das neu Laden der Daten über den Modul-Service aus.
        this.salesService.loadSalesAnalysisProductView('overlay-info', {
            id: institutionId,
            label: institutionName,
        });

        // Für den Fall, dass das Modul noch nicht initialisiert ist, gebe ich die Parameter als crossmoduleNavigationParameter mit
        this.overlayInfoService.executeNavigation(target, {
            id: institutionId,
            label: institutionName,
        });

        this.clickClose();
    }

    /**
     * @brief   Ansprechpartner-Modul in Dialog öffnen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openInstitutionsPeopleDialog(): void {
        // Init
        const formData: LooseObject = {};

        // Daten die an Komponente übergeben werden
        formData.executionOrder = ['institutionId', 'loadData'];
        formData.institutionId = this.backendData.id;
        formData.loadData = true;

        // Dialog konfigurieren und öffnen
        this.dialog.open(GlobalComponentPopupComponent, {
            height: 'auto',
            maxHeight: 'calc(100vh - 125px)',
            width: '900px',
            data: {
                component: InstitutionsPeopleComponent,
                data: formData,
            },
        });
    }

    /**
     * @brief   Navigiere zu einer Person oder Einrichtung
     * @param   target string   'people' oder 'institutions'
     * @param   id number       ID der Person / der Einrichtung
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    prepareNavigation(target: string, id: number): void {
        if (target === 'people') {
            this.overlayInfoService.preparePeopleNavigation(target, id);
        } else if (target === 'institutions') {
            this.overlayInfoService.prepareInstitutionsNavigation(target, id);
        } else {
            console.error('wrong parameter values');
        }
    }
}
