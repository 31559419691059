// Angular-Module
import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services einbinden
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';
// Interfaces für Structured Objects einbinden
import {SelectData} from '@shared/select-data';
// Pipes
import {MatSelectChange} from '@angular/material/select';
import {hasOwn, notEmpty} from '@shared/utils';
import {IsUndefinedPipe} from './../is-undefined.pipe';
import {GridService} from '@shared/grid/grid.service';

@Component({
    selector: 'phscw-input-select',
    templateUrl: './input-select.component.html',
    styleUrls: ['./input-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputSelectComponent),
            multi: true,
        },
    ],
})
export class InputSelectComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // EditMode aktiv?
    @Input() editMode = false;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string;
    // Soll das zu einer Selektionsliste gehörige list_data-Feld mitgegeben werden
    @Input() getListData = false;
    // Event-Emitter für die List-Data;
    @Output() sendListDataEvent = new EventEmitter<any>();
    // added onChange
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onChange = new EventEmitter<boolean>();
    /*
     * Optionen, die in Selectbox angezeigt werden.
     * Können von außen von der einbindenden Komponente bereits vorgegeben werden.
     * Falls "listentries" verwendet werden sollen, gibt die einzubindende
     * Komponente nur den Listennamen an und die InputSelectComponent füllt die
     * Optionen der Selectbox selbständig.
     */
    private _selectData: SelectData[] = [];
    @Input() set selectData(value: any) {
        if (this._selectData != value) {
            this._selectData = value;
            this.updateLabel();
            this.setAutomatic();
        }
    }

    get selectData() {
        return this._selectData;
    }

    // Model "myValue" mit GETTER & SETTER
    private _myValue = '';
    @Input() set myValue(value) {
        this._myValue = value;
        this.updateLabel();
        this.emitEvent(value);
        this.propagateChange(this._myValue);
    }

    get myValue() {
        return this._myValue;
    }

    // Flag definiert ob Readonly ausgewählt wurde
    selectedReadonly = false;
    // Flag definiert ob Readonly Werte verändert werden dürfen
    @Input() disableReadonly = false;

    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    propagateChange = (_: any) => {};
    // Attribut: required = Pflichtfeld (ja / nein)
    @Input() required = false;
    // Attribut: disabled = Gesperrt (ja / nein)
    @Input() disabled = false;
    // Soll der Löschbutton angezeigt werden
    @Input() showDeleteButton = false;
    // In Ansichtsmodus verstecken, wenn leer?
    @Input() hideEmpty = true;
    // Automatisch Eintrag auswählen, wenn nur eine Option besteht
    @Input() automaticSingleOption = true;
    // Wenn true übergeben wird, soll der erste Eintrag gewählt werden, beim init
    @Input() automaticFirstSelection = false;

    /*
     * Falls die Komponente Einträge aus "listentries" verwenden soll, wird hier
     * der Listenname angegeben. Beim Initialisieren wird dann selectData
     * über diese Liste gefüllt.
     */
    @Input() listentries = '';

    // Beschriftung / Label der aktuell ausgewählten Selectbox-Option
    selectedLabel: string;

    // Event-Emitter, falls der Wert gelöscht wurde
    @Output() deleteClicked = new EventEmitter<any>();

    @Output() openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
    // selection changed
    @Output() selectionChanged: EventEmitter<string> = new EventEmitter<string>();
    /**
     * Konstruktor (inkl. dependency injection)
     * @param initService
     * @param storageService
     * @param isUndefinedPipe
     * @param gridService
     */
    constructor(
        private initService: InitService,
        private storageService: StorageService,
        private isUndefinedPipe: IsUndefinedPipe,
        private gridService: GridService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Listentries aktualisieren
        this.initListentries();
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Darauf warten, dass alle listentries in der indexedDB gespeichert sind
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            // Abbruch, falls Anfrage erfolglos war
            if (result === false) {
                return;
            }
            this.initListentries();
        });
    }

    /**
     * Listentries initialisieren
     */
    initListentries(): void {
        // Falls der Name einer Liste hinterlegt wurde
        if (this.listentries != '') {
            // Daten über Service anfordern
            const promise = this.storageService.getItem('listentries|' + this.listentries);
            // let promise = this.storageService.getItemSlowly('listentries|' + this.listentries);
            promise.then((val) => this.onGetListentriesFromStorage(val));
        }
    }

    /**
     * Listentry-Daten wurden geladen
     * @param {any} value  geladene listentries
     */
    onGetListentriesFromStorage(value: any): void {
        // Initialisierung dieser Komponente war schneller als das Laden der Listentries
        if (value == null) {
            /*
             * ...Listentries nochmal über StorageService ermitteln
             * Wird nicht mehr gemacht, kann eine "nahezu"-Endlosschleife auslösen,
             * die nach Ausloggen und Einloggen C-World zur unbedienbarkeit verlangsamt
             * this.initListentries();
             */
            return;
        }

        // Initialisierung
        const tmpOption: SelectData = {
            id: 0,
            label: '',
            data: '',
        };
        const listentriesData: any[] = value;
        if (listentriesData) {
            // Werte der Liste (list_key & list_value) in Select-Optionen (id & label) übernehmen
            for (const entry of listentriesData) {
                tmpOption.id = entry['list_key'];
                tmpOption.label = entry['list_value'];
                if (listentriesData.length === 1) {
                    this.myValue = tmpOption.id;
                }
                // Wenn es list.data gibt diese zu einem JSON-Parsen und ebenfalls speichern.
                if (entry['list_data'] !== '' && entry['list_data'] !== null) {
                    tmpOption.data = JSON.parse(entry['list_data']);
                } else {
                    /*
                     * Wenn der List_data-Eintrag leer ist, muss die data der tmpOption auf null gesetzt werden.
                     * Kein JSON.parse um Fehler zu vermeiden.
                     */
                    tmpOption.data = null;
                }
                this.selectData.push({...tmpOption});
                /*
                 * Falls ein Default in den Options-Daten gesetzt ist, setze den Wert
                 * Falls es mehrere geben sollte, wird der letzte geladene genommen.
                 */
                if (
                    tmpOption.data !== null &&
                    Object.prototype.hasOwnProperty.call(tmpOption.data, 'default') &&
                    tmpOption.data['default'] &&
                    (this.myValue == null || this.myValue == '')
                ) {
                    this.myValue = tmpOption.id;
                }
            }
        }
        this.updateLabel();
        this.gridService.triggerGridChangeDetection();
    }

    /**
     * Label aktualisieren (Anzeige außerhalb Edit-Mode)
     */
    updateLabel(): void {
        // Falls Selectbox-Optionen existieren
        if (this.selectData !== undefined && this.selectData !== null) {
            /*
             * @todo: PhS(MS): Auskommentiert, weil ich ein String als id haben wollte. Hat dieses "+" bei "this.myValue" irgendeine Relevanz?
             * let selectedItem: any = this.selectData.find(item => item.id == +this.myValue);
             */
            const selectedItem: any = this.selectData.find((item) => item.id == this.myValue);
            if (selectedItem !== undefined) {
                // Label der aktuellen Option speichern
                this.selectedLabel = selectedItem.label;

                // zusätzliche Daten (list_data) prüfen auf 'readonly'
                if (
                    typeof selectedItem.data !== 'undefined' &&
                    selectedItem.data !== null &&
                    Object.prototype.hasOwnProperty.call(selectedItem.data, 'readonly') &&
                    selectedItem.data['readonly']
                ) {
                    // Flag 'selectedReadonly' aktivieren
                    this.selectedReadonly = true;
                    // Event auslösen, um in einbindender Komponente auf 'readonly' reagieren zu können
                    this.emitEvent(selectedItem.id);
                }
                /*
                 * @todo
                 * Funktioniert generell um die einzige vorhandene Option auszuwählen.
                 * Leider gibt es einige Stellen an denen diese Komponente verwendet wird und
                 * durch das automatisch setzen Folgefunktionalität in der einbindenden
                 * Komponente nicht richtig angestoßen wird
                 *            } else if (this.automaticSingleOption && this.selectData.length === 1) {
                 *                this.myValue = this.selectData[0].id;
                 */
            } else {
                // PhS(MS): Das muss hier rein, weil sonst, wenn myValue den Wert null annimmt, der selectedLabel-Wert bestehen bleibt.
                this.selectedLabel = '';
            }
        }
    }

    /**
     * Interface ControlValueAccessor: writeValue
     * @param {any} value  zu schreibender wert
     */
    writeValue(value: any): void {
        if (value !== undefined) {
            this.myValue = value;
        }
        this.updateLabel();
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param {any} fn  fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    emitSelectionChanged() {
        this.selectionChanged.emit(this.myValue);
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {
        return undefined;
    }

    /**
     * Lösche den Selection-Wert
     */
    deleteValue(): void {
        this.myValue = null;
        this.deleteClicked.emit();
    }

    /**
     * Event mit korrekten Daten auslösen
     * @param {any} value  ?
     */
    emitEvent(value: any): void {
        // Falls die List-Data, die zu einem gewählten Eintrag mitgeben werden soll und der Edit-Mode aktiv ist...
        if (this.getListData && this.editMode) {
            // ...die List-Data auslesen...
            const selectedItem: any = this.selectData.find((data) => data.id == value);
            let data = null;
            if (selectedItem !== undefined) {
                data = selectedItem.data;
            }
            // ...und als event an das einbindende Modul weitergeben.
            this.sendListDataEvent.emit(data);
        }
    }

    /**
     * @brief   Prüfe ob Option ausgewählt werden darf
     * @details Wird benötigt um Listentries, die nur extern gesetzt werden
     *          können sollen, nur anzuzeigen, wenn sie bereits gesetzt sind
     * @param {any} data  data
     * @param {any} value  value
     * @returns {boolean} isReadOnly
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    isReadOnly(data: any, value: any): boolean {
        let returnValue = false;

        // Wenn das Attribut readonly vorhanden ist und der Optionswert nicht bereits ausgewählt ist, darf er nicht ausgewählt werden
        if (
            !this.isUndefinedPipe.transform(data) &&
            Object.prototype.hasOwnProperty.call(data, 'readonly') &&
            data.readonly === true &&
            this.myValue !== value
        ) {
            returnValue = true;
        }

        return returnValue;
    }

    /**
     * Wenn automaticFirstSelection = true, soll der erste Eintrag gewählt werden, beim init
     *
     * Vorsicht: funktioniert nicht wirklich mit unserem Formular, aktuell nur mit selecfelder außerhalb eines forms
     */
    setAutomatic() {
        if (
            this.automaticFirstSelection &&
            !notEmpty(this._myValue) &&
            this._selectData != undefined &&
            this._selectData.length > 0 &&
            hasOwn(this._selectData[0], 'id')
        ) {
            this._myValue = this._selectData[0]['id'];
        }
    }
}
