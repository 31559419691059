// Angular-Module
import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service dieses Shared-Moduls
import {ToolbarService} from '../toolbar.service';
// Shared Services
import {GridService} from '@shared/grid/grid.service';
// Globale Klassen einbinden
import {CWEvent} from '@shared/cw-event';
import {FilterData} from '@shared/filter-data';
import {LooseObject} from '@shared/loose-object';
import {MenuData} from '@shared/menu-data';
import {AdminUsersService} from '@modules/admin/admin-users/admin-users.service';
import {CwIcon} from '@shared/cw-icon';

@Component({
    selector: 'phscw-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
    // CSS-Eigenschaft "justify-content" als Variable im TS ansprechbar machen
    @HostBinding('style.justify-content')
    justifyContent = 'flex-end';

    // Falls die Toolbar linksbündig ausgerichtet sein soll
    @Input() leftAlign = false;

    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;

    // Referenz auf verbundene Komponente
    @Input() componentConnection: Component;

    /*
     * **************************************************************************
     * Konfiguration der sichtbaren Buttons (Allgemein)
     */
    @Input() buttonPaste = false;
    @Input() buttonAdd = false;
    @Input() buttonAddShadow = false;
    @Input() buttonAddMenu = false;
    @Input() buttonClone = false;
    @Input() buttonMerge = false;
    @Input() buttonAddChild = false;
    @Input() buttonAddLink = false;
    @Input() buttonAddParent = false;
    @Input() buttonDelete = false;
    @Input() buttonEditmode = false;
    @Input() buttonShadowEditmode = false;
    @Input() buttonEditmodeFirst = false;
    @Input() buttonExcel = false;
    @Input() buttonDataExport = false; // Neue Version vom Excel export
    @Input() buttonFullscreen = false;
    @Input() buttonExpandCharacteristics = false;
    @Input() buttonPrint = false;
    @Input() buttonReport = false;
    @Input() buttonLock = false;
    @Input() buttonUnlock = false;
    @Input() buttonPreviousStatus = false;
    @Input() buttonNextStatus = false;
    @Input() buttonTransfer = false;
    @Input() buttonSampleLoss = false;
    @Input() buttonSampleReturn = false;
    @Input() buttonSendReport = false;
    @Input() buttonCharacteristics = false;
    @Input() buttonMultiContact = false;
    @Input() buttonMultiApprovedMails = false;
    @Input() buttonScheduling = false;
    @Input() buttonEventStatus = false;
    @Input() buttonNext = false;
    @Input() buttonCompleteProcess = false;
    @Input() buttonClose = false;
    @Input() buttonCancel = false;
    @Input() buttonSwitchView = false;
    @Input() buttonHistory = false;
    @Input() buttonGoogleMaps = false;
    @Input() buttonParkopedia = false;
    @Input() buttonRemoteEdetailerInvite = false;
    @Input() buttonRemoteEdetailerStart = false;
    @Input() buttonUserCheck = false;
    @Input() buttonReopen = false;
    @Input() buttonReload = false;
    @Input() buttonSearchDuplicate = false;
    @Input() buttonNavigateInstitutions = false;
    @Input() buttonUpload = false;
    @Input() buttonHierarchy = false;
    @Input() buttonLoadAll = false;
    @Input() buttonChat = false;
    @Input() buttonBackToList = false;
    @Input() copyUserLoginLinkButton = false;
    @Input() copyContent: string = null;
    // GRID-ERWEITERUNGEN: Konfiguration der sichtbaren Buttons
    @Input() buttonGridSort = false;
    @Input() buttonGridFilter = false;
    @Input() buttonFilterInvoices = false;
    @Input() buttonGridSelection = false;
    @Input() buttonGridColumns = false;
    @Input() buttonShowPeople = false;
    @Input() buttonEditEventsPeople = false;
    @Input() buttonAddCosts = false;
    @Input() buttonAddPlanCosts = false;
    // **************************************************************************

    // Weitere Einstellungen
    @Input() fullscreenSize = 100;
    @Input() fullscreenStatus = true;
    @Input() printContent = 'body';

    // Definiert, ob Menu-Items von "buttonAddMenu" angezeigt werden oder nicht
    @Input() buttonAddMenuItemsVisible = false;
    // Daten für "buttonAddMenu"
    @Input() buttonAddMenuData: MenuData[] = [];
    @Input() buttonHistoryMenuData: MenuData[] = [];

    // Frei definierbare Tooltips
    @Input() buttonPasteTooltip: string = null;
    @Input() buttonAddTooltip: string = null;
    @Input() buttonCloneTooltip: string = null;
    @Input() buttonMergeTooltip: string = null;
    @Input() buttonAddChildTooltip: string = null;
    @Input() buttonAddLinkTooltip: string = null;
    @Input() buttonAddParentTooltip: string = null;
    @Input() buttonDeleteTooltip: string = null;
    @Input() buttonEditModeTooltip: string = null;
    @Input() buttonCancelTooltip: string = null;
    @Input() buttonExportExcelTooltip: string = null;
    @Input() buttonDataExportTooltip: string = null;
    @Input() buttonPrintTooltip: string = null;
    @Input() buttonReportTooltip: string = null;
    @Input() buttonLockTooltip: string = null;
    @Input() buttonUnlockTooltip: string = null;
    @Input() buttonPreviousStatusTooltip: string = null;
    @Input() buttonNextStatusTooltip: string = null;
    @Input() buttonTransferTooltip: string = null;
    @Input() buttonSampleLossTooltip: string = null;
    @Input() buttonSampleReturnTooltip: string = null;
    @Input() buttonSendReportTooltip: string = null;
    @Input() buttonCharacteristicsTooltip: string = null;
    @Input() buttonMultiContactTooltip: string = null;
    @Input() buttonMultiApprovedMailsTooltip: string = null;
    @Input() buttonSchedulingTooltip: string = null;
    @Input() buttonEventStatusTooltip: string = null;
    @Input() buttonGridSortTooltip: string = null;
    @Input() buttonGridFilterTooltip: string = null;
    @Input() buttonGridSelectionTooltip: string = null;
    @Input() buttonGridColumnTooltip: string = null;
    @Input() buttonNextTooltip: string = null;
    @Input() buttonCompleteProcessTooltip: string = null;
    @Input() buttonFullscreenTooltip: string = null;
    @Input() buttonCloseTooltip: string = null;
    @Input() buttonHistoryTooltip: string = null;
    @Input() buttonUserCheckTooltip: string = null;
    @Input() buttonSwitchViewTooltip: string = null;
    @Input() buttonGoogleMapsTooltip: string = null;
    @Input() buttonParkopediaTooltip: string = null;
    @Input() buttonRemoteEdetailerInviteTooltip: string = null;
    @Input() buttonRemoteEdetailerStartTooltip: string = null;
    @Input() buttonReopenTooltip: string = null;
    @Input() buttonReloadTooltip: string = null;
    @Input() buttonSearchDuplicateTooltip: string = null;
    @Input() buttonNavigateInstitutionsTooltip: string = null;
    @Input() buttonEditEventsPeopleTooltip: string = null;
    @Input() buttonShowPeopleTooltip: string = null;
    @Input() buttonAddCostsTooltip: string = null;
    @Input() buttonAddPlanCostsTooltip: string = null;
    @Input() buttonUploadTooltip: string = null;
    @Input() buttonCollectiveContact = false;
    @Input() buttonChatTooltip: string = null;
    @Input() buttonIconTooltip: string = null;
    @Input() buttonBackToListTooltip = false;
    // dynamisch gesetzes icon fontawesome
    @Input() icon: string;

    // Frei definierbare Icons
    @Input() buttonPasteIcon = 'icon-clipboard';
    @Input() buttonSwitchViewIcon: string;
    @Input() buttonNavigateInstitutionsIcon: CwIcon;

    // Parameter für Css-Styling
    @Input() buttonLockClass = '';

    // Zusätzliche Parameter
    @Input() buttonAddParameter: number;
    @Input() buttonCloneParameter: number;
    @Input() buttonGridFilterParameter: boolean;
    @Input() buttonInvoicesFilterParameter: boolean;
    @Input() buttonMergeParameter: any;
    @Input() buttonSearchDuplicateParameter: any;
    @Input() buttonAddChildParameter: any;
    @Input() buttonAddLinkParameter: any;
    @Input() buttonAddParentParameter: any;
    @Input() buttonDeleteParameter: LooseObject;
    @Input() buttonTransferParameter: any;
    @Input() buttonSampleLossParameter: any;
    @Input() buttonSampleReturnParameter: any;
    @Input() buttonCharacteristicsParameter: any;
    @Input() buttonMultiContactParameter: any;
    @Input() buttonMultiApprovedMailsParameter: any;
    @Input() buttonSchedulingParameter: any;
    @Input() buttonEventStatusParameter: LooseObject;
    @Input() buttonCompleteProcessParameter: LooseObject;
    @Input() buttonIgnoreHierarchyParameter: boolean;
    @Input() buttonLoadAllParameter: boolean;
    @Input() buttonDataExportParameter: LooseObject;

    private _buttonRemoteEdetailerParameter: any;
    @Input() set buttonRemoteEdetailerParameter(value: any) {
        const eventData: CWEvent = {
            sender: 'toolbar',
            target: '',
            data: value,
        };
        this.toolbarService.eventDisabledEdetailerLinks.next(eventData);
        this._buttonRemoteEdetailerParameter = value;
    }

    get buttonRemoteEdetailerParameter() {
        return this._buttonRemoteEdetailerParameter;
    }

    // Flag definiert ob gerade geladen wird (Anzeige Loading-Spinner)
    buttonExcelLoading = false;
    buttonNextLoading = false;
    buttonCompleteProcessLoading = false;
    buttonLockLoading = false;
    buttonUnlockLoading = false;
    buttonPreviousStatusLoading = false;
    buttonNextStatusLoading = false;
    buttonSearchDuplicateLoading = false;
    buttonTransferLoading = false;
    buttonSampleLossLoading = false;
    buttonSampleReturnLoading = false;
    buttonInvoicesFilterActive = false;

    // Flag definiert ob gerade eine GridExtension geöffnet ist
    buttonGridSortActive = false;
    buttonGridFilterActive = false;
    buttonGridSelectionActive = false;
    buttonGridColumnsActive = false;

    // Flag definiert, ob sich diese Komponente auf angezeigte Daten auswirkt
    buttonGridFilterApplied = false;
    // Flag definiert, ob sich diese Komponente auf angezeigte Daten auswirkt
    buttonGridSelectionApplied = false;
    buttonInvoicesFilterApplied = false;
    buttonSendReportParameter: any;
    buttonExpandCharacteristicsTooltip: any;
    // Konstruktor
    constructor(
        private toolbarService: ToolbarService,
        private translateService: TranslateService,
        private gridService: GridService,
        private adminUsersService: AdminUsersService,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Prüfe Anforderungen
        this.checkRequirements();
        // Events subscriben
        this.initializeEventSubscriptions();
        // Falls linksbündige Ausrichtung gewünscht ist, muss CSS angepasst werden
        if (this.leftAlign === true) {
            this.justifyContent = 'flex-start';
        }
    }

    // Aufräumen
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    // Prüfe Anforderungen
    checkRequirements() {
        // Toolbar benötigt einen Identifier
        if (typeof this.componentIdentifier === 'undefined') {
            console.error(
                'Pharmakon - ToolbarComponent verfügt über keinen Identifier. Bitte beim Einbinden der Komponente [componentIdentifier] setzen!',
            );
        }
        // Toolbar benötigt eine verbundene Komponente
        if (typeof this.componentConnection === 'undefined') {
            console.error(
                'Pharmakon - ToolbarComponent verfügt über keine verbundene Komponente und kann deshalb nicht funktionieren. Bitte beim Einbinden der Komponente die [componentConnection] setzen!',
            );
        }
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Excel Export Button wurde in Toolbar geklickt und bestätigt
        this.toolbarService.eventExcelExportValidated
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                if (result.sender === 'toolbar' && result.target === this.componentIdentifier) {
                    this.toggleLoadingAnimation('Excel');
                }
            });

        // Excel Export wurde abgeschlossen
        this.toolbarService.eventLoadingComplete
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                if (
                    result.sender === 'toolbar' &&
                    result.target === this.componentIdentifier &&
                    'data' in result &&
                    'action' in result.data
                ) {
                    this.toggleLoadingAnimation(result.data.action, false);
                }
            });

        // Duplikat Suche Button wurde in Toolbar geklickt
        this.toolbarService.eventToolbarButtonClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                if (
                    result.target === this.componentIdentifier &&
                    Object.prototype.hasOwnProperty.call(result, 'data') &&
                    Object.prototype.hasOwnProperty.call(result.data, 'toggleLoading') &&
                    result.data.toggleLoading
                ) {
                    this.toggleLoadingAnimation(result.data.toggleLoading);
                }
            });

        // Setze die Anzeige ob der Filter aktiv ist anhand des übergebenen Filters
        this.gridService.eventGridFilterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent<FilterData>) => {
                // reagiert wenn man "Okay" bei filtern drückt
                if (
                    result.target === this.componentIdentifier &&
                    Object.prototype.hasOwnProperty.call(result, 'data') &&
                    Object.prototype.hasOwnProperty.call(result.data, 'key')
                ) {
                    this.buttonGridFilterApplied = result.data.key !== 'all';
                }
            });

        // Setze die Anzeige ob die Selektion aktiv ist anhand der übergebenen Selektion
        this.gridService.eventGridSourceChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                if (
                    result.target === this.componentIdentifier &&
                    Object.prototype.hasOwnProperty.call(result, 'data') &&
                    Object.prototype.hasOwnProperty.call(result.data, 'selectedId')
                ) {
                    this.buttonGridSelectionApplied = result.data.selectedId >= 1000 || result.data.selectedId == '30';
                }
            });
    }

    // EditMode wechseln
    toggleEditmode() {
        // EditMode der verbundenen Komponente wechseln
        this.componentConnection['editMode'] = !this.componentConnection['editMode'];
    }

    // Schatten editier modus
    clickShadowEditMode() {
        this.toolbarService.editShadowItem(this.componentIdentifier);
        this.toggleEditmode();
    }

    // openState von Kennzeichen wechseln
    toggleExpandCharacteristicsState() {
        this.componentConnection['characteristicsOpenState'] = !this.componentConnection['characteristicsOpenState'];
    }

    // Anzeige von MenuItems (von "buttonAddMenu") wechseln
    toggleAddMenu() {
        // Variable setzen
        this.buttonAddMenuItemsVisible = !this.buttonAddMenuItemsVisible;
    }

    /*
     * Drucken des Inhalts eines Moduls
     *
     * Wenn Style nicht passt, muss "@media print" für entsprechende
     * CSS-Klassen gesetzt werden
     */
    clickPrint() {
        /*
         * Node Collection der Styles aus generiertem HTML holen
         * (da keine Style-Datei mehr auf Server, wegen Angular)
         */
        const styles = document.getElementsByTagName('style');

        // Alle geholten Styles aus der Node Collection auslesen und in einen String packen
        const stylesCount = styles.length;
        let printStyles = '';
        for (let i = 0; i < stylesCount; i++) {
            printStyles += styles[i].outerHTML;
        }

        // Inhalt des zu druckenden Moduls holen
        const printModule = document.getElementById(this.printContent).innerHTML;

        // Print-Popup erzeugen
        const popupWin = window.open('', '_blank', 'width=800,height=600');

        // Container mit Inhalt und Styles erstellen
        const printAll: string =
            '<html>\n' +
            '<head>\n' +
            '<link rel="stylesheet" media="print" href="styles.css" />\n' +
            printStyles +
            '\n' +
            '</head>\n' +
            '<body onload="window.print()">\n' +
            printModule +
            '\n' +
            '</body>\n' +
            '</html>';

        // Erzeugtes Print-Popup öffnen
        popupWin.document.open();
        popupWin.document.write(printAll);
        popupWin.document.close();
    }

    /*
     * Drucken eines Berichtes
     */
    clickReport() {
        this.toolbarService.generateReport(this.componentIdentifier);
    }

    /*
     * Senden eines Berichtes
     */
    clickSendReport() {
        this.toolbarService.sendReport(this.componentIdentifier);
    }

    /*
     * Abschließen eines Berichts
     */
    clickLock() {
        if (!this.buttonLockLoading) {
            this.toolbarService.lockItem(this.componentIdentifier);
            this.toggleLoadingAnimation('Lock');
        }
    }

    /*
     * Freigeben eines Berichts
     */
    clickUnlock() {
        if (!this.buttonLockLoading) {
            this.toolbarService.unlockItem(this.componentIdentifier);
            this.toggleLoadingAnimation('Unlock');
        }
    }

    /*
     * Prüfung des Berichts zurücksetzen
     */
    clickPreviousStatus() {
        if (!this.buttonPreviousStatusLoading) {
            this.toolbarService.previousStatus(this.componentIdentifier);
            this.toggleLoadingAnimation('PreviousStatus');
        }
    }

    /*
     * Prüfung des Berichts bestätigen
     */
    clickNextStatus() {
        if (!this.buttonNextStatusLoading) {
            this.toolbarService.nextStatus(this.componentIdentifier);
            this.toggleLoadingAnimation('NextStatus');
        }
    }

    clickFilterInvoices(extension: string) {
        this.toolbarService.filterInvoices(this.componentIdentifier);

        // Korrekten Button hervorheben
        this.toggleButtonHighlight(extension);
    }

    /*
     * Daten einfügen
     */
    clickPaste() {
        this.toolbarService.pasteItem(this.componentIdentifier);
    }

    /*
     * Neuen Eintrag hinzufügen, da das "+" in der Toolbar angeklickt wurde
     */
    clickAdd() {
        this.toolbarService.addItem(this.componentIdentifier, this.buttonAddParameter);
    }

    /*
     * Neuen Änderungsanfrage hinzufügen, da das "+" in der Toolbar angeklickt wurde
     */
    clickAddShadow() {
        this.toolbarService.addShadowItem(this.componentIdentifier, this.buttonAddParameter);
    }

    /*
     * Menu-Eintrag in "buttonAddMenu" wurde angeklickt
     */
    clickAddMenu(menuItem: MenuData) {
        // Funktion in Service auslösen
        this.toolbarService.addSpecificItem(this.componentIdentifier, menuItem.name);
    }

    /*
     * @brief   Eintrag duplizieren
     */
    clickClone() {
        this.toolbarService.cloneItem(this.componentIdentifier, this.buttonCloneParameter);
    }

    /*
     * @brief   Einträge zusammenführen
     */
    clickMerge() {
        this.toolbarService.mergeItems(this.componentIdentifier, this.buttonMergeParameter);
    }

    /*
     * Eintrag löschen, da das "x" in der Toolbar angeklickt wurde
     */
    clickDelete() {
        this.toolbarService.deleteItem(this.componentIdentifier, this.buttonDeleteParameter);
    }

    /*
     * @brief   Einträge in Excel exportieren
     */
    clickExcel() {
        if (!this.buttonExcelLoading) {
            this.toolbarService.excelExport(this.componentIdentifier, this.componentConnection['gridId']);
        }
    }

    /*
     * @brief   Formular für Kontakte öffnen
     */
    clickContacts() {
        this.toolbarService.openContactForm(this.componentIdentifier);
    }

    /*
     * @brief   Formular für SammelKontakte öffnen
     */
    clickCollectiveEntryContacts() {
        this.toolbarService.openCollectiveEntryContactForm(this.componentIdentifier);
    }

    /*
     * @brief   Formular für freigebene Mails öffnen
     */
    clickApprovedMails() {
        this.toolbarService.openApprovedMailsForm(this.componentIdentifier);
    }

    /*
     * @brief   Terminplanung öffnen
     */
    clickScheduling() {
        this.toolbarService.openScheduling(this.componentIdentifier);
    }

    /*
     * @brief   Eine Grid-Extension ein-/ausblenden
     * @param   extension: string       Bezeichnung der Grid-Extension ('sort', 'filter', 'columns', 'selection')
     */
    toggleGridExtension(extension: string) {
        this.toolbarService.toggleGridExtension(this.componentIdentifier, extension);

        // Korrekten Button hervorheben
        this.toggleButtonHighlight(extension);
    }

    /**
     * @brief   Next-Button wurde angeklickt
     */
    clickNext() {
        if (!this.buttonNextLoading) {
            this.toolbarService.nextStep(this.componentIdentifier);
            this.toggleLoadingAnimation('Next');
        }
    }

    /**
     * @brief   CompleteProcess-Button wurde angeklickt
     */
    clickCompleteProcess() {
        if (!this.buttonCompleteProcessLoading) {
            this.toolbarService.completeProcess(this.componentIdentifier, this.buttonCompleteProcessParameter);
            this.toggleLoadingAnimation('CompleteProcess');
        }
    }

    /**
     * @brief   Close-Button wurde angeklickt
     */
    clickClose() {
        this.toolbarService.closeComponent(this.componentIdentifier);
    }

    /**
     * @brief   Reload-Button wurde angeklickt
     */
    clickReload() {
        this.toolbarService.reload(this.componentIdentifier);
    }

    /**
     * @brief   Ist-Kosten-Button (€) wurde gedrückt
     */
    clickAddCosts() {
        this.toolbarService.clickAddCosts(this.componentIdentifier);
    }

    /**
     * @brief   Plan-Kosten-Button wurde gedrückt
     */
    clickAddPlanCosts() {
        this.toolbarService.clickAddPlanCosts(this.componentIdentifier);
    }

    /*
     * @brief   Datei hochladen
     */
    clickUpload() {
        this.toolbarService.uploadFile(this.componentIdentifier);
    }

    clickHierarchy() {
        this.toolbarService.clickHierarchy(this.componentIdentifier);
    }

    clickLoadAll() {
        this.toolbarService.clickLoadAll(this.componentIdentifier);
    }

    /**
     * @param loadingVariable
     * @param loadingStatus
     * @brief   Lade-Animation starten/beenden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    toggleLoadingAnimation(loadingVariable: string, loadingStatus: boolean = null) {
        if (loadingStatus !== null) {
            this['button' + loadingVariable + 'Loading'] = loadingStatus;
        } else {
            this['button' + loadingVariable + 'Loading'] = !this['button' + loadingVariable + 'Loading'];
        }
    }

    /**
     * @param extension
     * @brief   Button hervorheben während er aktiv ist
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    toggleButtonHighlight(extension: string) {
        // Initialisiere
        const buttons = [
            'buttonGridSortActive',
            'buttonGridFilterActive',
            'buttonGridSelectionActive',
            'buttonGridColumnsActive',
        ];
        const regex = new RegExp(extension, 'gi');

        // Variablenname zusammenbauen
        buttons.forEach((button) => {
            if (button.search(regex) > -1) {
                this[button] = !this[button];
            } else {
                this[button] = false;
            }
        });
    }

    // AI-Chat Button wurde angeklickt
    clickStartChat() {
        this.toolbarService.clickStartChat(this.componentIdentifier);
    }

    clickIcon() {
        this.toolbarService.clickIcon(this.componentIdentifier);
    }

    clickBackToList() {
        this.toolbarService.clickToolbarButton(this.componentIdentifier, 'toolbar-back-to-list');
    }

    clickCopyUserLink() {
        this.toolbarService.clickToolbarButton(this.componentIdentifier, 'toolbar-paste');
    }

    clickDataExport() {
        this.toolbarService.clickToolbarButton(this.componentIdentifier, 'toolbar-data-export');
    }
}
