// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class PeopleNotesService {
    // Konstruktor (inkl. dependency injection)
    constructor(private backendService: BackendService) {}

    /**
     * @brief   Notizen einer Person laden
     * @param id
     * @param gridPage
     * @param   number  id      Person-ID
     * @returns  Observable<any>
     */
    loadData(id: number, gridPage: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('PeopleNotes/index/' + id + '/' + gridPage);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Einzelne Notiz laden
     * @param id
     * @param   number  id      Notiz-ID
     * @returns  Observable<any>
     */
    loadDetails(id: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('PeopleNotes/details/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @param id
     * @param formData
     * @brief   Speichert Notizen einer Person
     * @returns  Observable<any>
     */
    saveData(id: number, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('PeopleNotes/edit/' + id, formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param id
     * @param formData
     * @brief   Löschen einer Notiz
     * @returns  Observable<any>
     */
    deleteNoteData(id: number, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('PeopleNotes/delete/' + id, formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
