import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-inventory-demand',
    templateUrl: './chart-inventory-demand.component.html',
    styleUrls: ['./chart-inventory-demand.component.scss'],
})
export class ChartInventoryDemandComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
