// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Interfaces für Structured Objects einbinden
import {LooseObject} from '@shared/loose-object';
// Environment
import {environment} from '@environment';
// Moment-Modul zur Datums-Verarbeitung
import {StorageService} from '@global/services/storage.service';
import {Listentry} from '@shared/listentry';
import * as _moment from 'moment';

@Component({
    selector: 'phscw-contacts-items',
    templateUrl: './contacts-items.component.html',
    styleUrls: ['./contacts-items.component.scss'],
    // Um Daten an übergeordnete Form übergeben zu können (siehe https://stackblitz.com/edit/angular-ua5xcz?file=app/address.component.ts)
    viewProviders: [{
        provide: ControlContainer,
        useExisting: NgForm,
    }],
})
export class ContactsItemsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    #componentDestroyed$ = new Subject<void>();

    /**
     * Definition der möglichen ContactItems (Gesprächsthema, Musterabgabe),
     * sowie der zugeordneten Gesprächsthemen / Muster des aktuellen
     * Kontakts.
     */
    #contactItems: LooseObject[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() set contactItems(value: any) {
        if (!value) {
            return;
        }
        const moment = _moment;
        value.forEach((item) => {
            item.children.forEach((child) => {
                if (typeof child.batches !== 'undefined') {
                    child.batches.forEach((batch) => {
                        const durabilityOfCharge = moment(batch.valid_to).format('DD.MM.YYYY');
                        const chargeAndDate = batch.charge + ' (' + durabilityOfCharge + ')';
                        batch.label = chargeAndDate;
                    });
                }
            });
        });
        this.#contactItems = value;

        // Daten zurücksetzen und neu initialisieren
        this.conversationOrder = [];
        this.initializeConversationOrder(this.contactItems);
    }

    get contactItems() {
        return this.#contactItems;
    }

    // Flag definiert, ob bearbeitet werden darf
    @Input() readonly = false;
    // Edit-Modus aktiv?
    @Input() editMode = false;
    // Flag definiert, ob Muster abgegeben werden dürfen
    @Input() sampleAuthorization = false;

    // ob produkt reaktion angezeigt werden sollen
    showReactions = false;
    // aktuelle gepsiecherte reaktionen
    @Input() reactions = {};

    // Flag definiert, ob Chargen ausgewählt werden dürfen
    enableSelectBatch = false;
    // Flag definiert, ob die Reihenfolge der Besprechungsthemen angezeigt werden soll
    enableConversationOrder = false;

    // Name der ngModelGroup
    @Input() formModelGroup: string;

    // Aktuelle Gesetze contact methode im contact formular
    #currentContactMethod;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() set currentContactMethod(value: any) {
        this.#currentContactMethod = value;
        this.updateIgnoreBatchRequired(value);
    }

    get currentContactMethod() {
        return this.#currentContactMethod;
    }

    // Falls in der list_data no btach gesetzt ist, soll die Charge ignoriert werden
    ignoreBatchRequired = false;

    // Alle listentries "Contact_methods"
    allContactMethods: Listentry[] = [];

    // Array mit Produkt-IDs und Reihenfolge der Klicks
    conversationOrder: number[] = [];

    // Liste von Produkttypen (products.product_type) die auch bei nicht-musterberechtigten Personen angezeigt werden sollen
    @Input() alwaysAllowedProductTypes: string[] = [];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param {StorageService} storageService - StorageService
     */
    constructor(private storageService: StorageService) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Konfiguration prüfen
        if (Object.prototype.hasOwnProperty.call(environment, 'contactsSamplesSelectBatches')) {
            this.enableSelectBatch = environment.contactsSamplesSelectBatches;
        }
        if (Object.prototype.hasOwnProperty.call(environment, 'contactsUseItemsConversationOrder')) {
            this.enableConversationOrder = environment.contactsUseItemsConversationOrder;
        }

        // Initialises befüllen
        this.loadContactMethods();

        // laden der config ob produkt reaktion angezeigt werden sollen
        const promise = this.storageService.getItem('config|contactsUseItemsProductReaction');
        promise.then((value) => {
            this.showReactions = value;
        });

        // init der reactionen
        for (const [reactionKey, reactionValue] of Object.entries(this.reactions)) {
            this.contactItems.forEach((contactItemGroups) => {
                contactItemGroups.children.forEach((contactItem) => {
                    if (contactItem.product_id == reactionKey && !contactItem.contact_item_product_reaction) {
                        contactItem.contact_item_product_reaction = reactionValue;
                    }
                });
            });
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this.#componentDestroyed$.next();
        this.#componentDestroyed$.complete();
    }

    /**
     * @description Laden der Kontaktmethoden
     */
    loadContactMethods() {
        const promise = this.storageService.getItem('listentries|contactMethod');
        promise.then((listentries: Listentry[]) => {
            this.allContactMethods = listentries;
            this.updateIgnoreBatchRequired(this.currentContactMethod);
        });
    }

    /**
     * @description Prüfen ob no_batch in list_Data des contactMethods listentry gesetzt ist
     * @param {any} value gesetzter Listentry key
     * @returns {void} -
     */
    updateIgnoreBatchRequired(value) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const listentry: Listentry = this.allContactMethods.find((element: any) => element.list_key === value);

        // prüfen ob listentry gefunden wurde
        if (listentry && listentry.list_data) {
            const listData = JSON.parse(listentry.list_data);
            // nur setzen wenn wert gesetzt ist
            if (listData.no_batch) {
                this.ignoreBatchRequired = listData.no_batch;
                return;
            }
        }
        // standard ist das die Batches nicht ignoriert werden sollen
        this.ignoreBatchRequired = false;
    }

    /**
     * Falls kein Maximalwert in DB gesetzt wurde Eingabe nicht einschränken
     * @param {any} contactItemChild - Produkt
     * @returns {number} maximal mögliche Anzahl an Musterabgaben
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getMaxValue(contactItemChild: any): number {
        let returnValue: number;
        if (contactItemChild.max_amount_samples !== null) {
            returnValue = contactItemChild.max_amount_samples - contactItemChild.samples_account;
        }
        if (returnValue < 0) {
            returnValue = 0;
        }

        return returnValue;
    }

    /**
     * Reagiere auf Änderung der ausgewählten Besprechungsthemen
     * @param {boolean} newValue - Neuer Wert
     * @param {LooseObject} clickedItem - Angeklicktes Element
     */
    conversationChanged(newValue: boolean, clickedItem: LooseObject) {
        // Prüfen, ob ein Element hinzugefügt oder entfernt wurde
        if (newValue) {
            const foundTopicInConversation = this.conversationOrder.some((id: number) => id === clickedItem.product_id);
            if (!foundTopicInConversation) {
                this.conversationOrder.push(clickedItem.product_id);
                clickedItem.conversation_order = this.conversationOrder.length;
            }
        } else {
            const foundTopicInConversation = this.conversationOrder.some((id: number) => id === clickedItem.product_id);
            if (foundTopicInConversation) {
                this.conversationOrder = this.conversationOrder.filter(
                    (productId: number) => productId !== clickedItem.product_id,
                );
            }

            // Sortierung aktualisieren
            this.updateConversationOrder(this.contactItems);
        }
    }

    /**
     * Aktualisiere die Reihenfolge der Besprechungsthemen
     * @param {any} contactItems - ContactItems
     */
    private updateConversationOrder(contactItems: LooseObject[]) {
        // alle übergebenen Elemente prüfen
        contactItems.forEach((item: LooseObject) => {
            // Prüfen, ob über das Produkt gesprochen wurde
            const foundTopicIndex = this.conversationOrder.findIndex((topicId: number) => topicId === item.product_id);
            if (foundTopicIndex > -1) {
                // Reihenfolge abspeichern - beginnend mit 1
                item.conversation_order = foundTopicIndex + 1;
            } else {
                // Reihenfolge zurücksetzen
                item.conversation_order = null;
            }

            // Rekursiver Aufruf für untergeordnete Elemente
            if (Object.prototype.hasOwnProperty.call(item, 'children') && item.children.length > 0) {
                this.updateConversationOrder(item.children);
            }
        });
    }

    /**
     * Iniitalisiere die Reihenfolge der Besprechungsthemen
     * @param {any} contactItems - ContactItems
     */
    private initializeConversationOrder(contactItems: LooseObject[]): void {
        // alle übergebenen Elemente prüfen
        contactItems.forEach((item: LooseObject) => {
            // Prüfen, ob die Reihenfolge gesetzt ist und entsprechend zwischenspeichern
            if (
                Object.prototype.hasOwnProperty.call(item, 'conversation') &&
                item.conversation === true &&
                Object.prototype.hasOwnProperty.call(item, 'conversation_order') &&
                item.conversation_order !== null &&
                item.conversation_order > 0
            ) {
                // Index berechnen - Für die Reihenfolge wird ab 1 begonnen zu zählen
                const index = item.conversation_order - 1;
                this.conversationOrder.splice(index, 0, item.product_id);
            } else if (Object.prototype.hasOwnProperty.call(item, 'conversation') && item.conversation === true) {
                this.conversationOrder.push(item.product_id);
                item.conversation_order = this.conversationOrder.length;
            }

            // Rekursiver Aufruf für untergeordnete Elemente
            if (Object.prototype.hasOwnProperty.call(item, 'children') && item.children.length > 0) {
                this.initializeConversationOrder(item.children);
            }
        });
    }

    /**
     * Prüfen, ob die Produktgruppe angezeigt werden soll
     * @param {any} contactItem - Produktgruppe
     * @returns {boolean} true, wenn die Produktgruppe angezeigt werden soll
     */
    isProductGroupVisible(contactItem: LooseObject): boolean {
        const isVisible: boolean = contactItem.visible;
        const hasGivenSamples: boolean = contactItem.hasSamplesForContact;
        const hasGivenConversation: boolean = contactItem.children.some(
            (child: LooseObject) => child.conversation === true,
        );

        // Im readonly Modus sollen nur abgegebene und besprochene Produkte angezeigt werden
        if (this.readonly) {
            return hasGivenSamples || hasGivenConversation;
        }

        /*
         * Im Edit-Modus sollen sichtbare Produkte angezeigt werden
         *
         * Die Sichtbarkeit des Produkts wird in der contacts-form-contact Komponente basierend auf
         * der Autorisierung der ausgewählten Person und/oder den immer angezeigten Produkttypen festgelegt.
         */
        return isVisible;
    }

    /**
     * Überprüfen, ob das Produkt angezeigt werden soll
     * @returns {boolean} true, wenn das Produkt angezeigt werden soll
     * @param {any} contactItemChild - das zu überprüfende Produkt
     */
    isProductVisible(contactItemChild: LooseObject): boolean {
        // Im readonly Modus sollen alle Produkte angezeigt werden
        if (this.readonly) {
            return true;
        }

        /*
         * Die Sichtbarkeit des Produkts wird in der contacts-form-contact Komponente basierend
         * auf der Autorisierung der ausgewählten Person und/oder den immer angezeigten Produkttypen festgelegt.
         */
        const isVisible: boolean = contactItemChild.visible;
        return isVisible;
    }

    /**
     * Prüfen, ob das Produkt sichtbare Kind-Produkte hat, damit die Produktgruppe sich aufklappen lässt
     * @param {any} contactItem - Produktgruppe
     * @returns {boolean} true, wenn sichtbare Kind-Produkte vorhanden sind
     */
    hasVisibleChildren(contactItem: LooseObject): boolean {
        const hasChildren: boolean = contactItem.children.length > 0;
        const isReadonly: boolean = this.readonly;
        const hasGivenSamples: boolean = contactItem.hasSamplesForContact;
        const hasChildTopics: boolean = contactItem.children.some(
            (child: LooseObject) => child.topic_of_conversation === true,
        );
        const hasGivenConversation: boolean = contactItem.children.some(
            (child: LooseObject) => child.conversation === true,
        );

        return (
            (hasChildren && !isReadonly) ||
            (!isReadonly && hasChildTopics) ||
            (isReadonly && hasGivenConversation) ||
            hasGivenSamples
        );
    }

    /*
     * locked Batches ausblenden
     */
    filterBatches(batches) {
        if (batches === undefined || batches.length === 0) {
            return batches;
        }
        const result = batches.filter((batch) => !batch.locked);
        return result;
    }

    // händisches setzen der reaktionen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelectChange(event: any, contactItem): void {
        contactItem.contact_item_product_reaction = event.target.value;
    }
}
