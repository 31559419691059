import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
// Für Übersetzung benötigte Module
import {TranslateModule} from '@ngx-translate/core';
// Komponenten
import {DocumentsDisplayComponent} from './documents-display/documents-display.component';
import {DocumentsUploadComponent} from './documents-upload/documents-upload.component';
import {DocumentsComponent} from './documents/documents.component';
// Shared Modules einbinden
import {GridModule} from '@shared/grid/grid.module';
import {InputModule} from '@shared/input/input.module';
import {OutputModule} from '@shared/output/output.module';
import {ToolbarModule} from '@shared/toolbar/toolbar.module';

@NgModule({
    // Importierte Module
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        GridModule,
        ToolbarModule,
        InputModule,
        OutputModule,
        TranslateModule,
    ],
    // Komponenten dieses Shared Modules
    declarations: [DocumentsComponent, DocumentsUploadComponent, DocumentsDisplayComponent],
    // Komponenten, die dieses Shared Module freigeben soll
    exports: [DocumentsComponent, DocumentsUploadComponent, DocumentsDisplayComponent, TranslateModule],
})
export class DocumentsModule {}
