// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cw-label-detail {
  display: block;
  margin-bottom: 18px;
  font-weight: bold;
}

.cw-characteristic-info-container {
  margin: 1em 0;
}

.cw-button-wrapper {
  margin: 0;
}

.cw-flex-row.cw-button-wrapper-container {
  margin-top: 18px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid-cell-edit-listentry/grid-cell-edit-listentry.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,mBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,SAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":[".cw-label-detail {\n    display: block;\n    margin-bottom: 18px;\n    font-weight: bold;\n}\n\n.cw-characteristic-info-container {\n    margin: 1em 0;\n}\n\n.cw-button-wrapper {\n    margin: 0;\n}\n\n.cw-flex-row.cw-button-wrapper-container {\n    margin-top: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
