import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-top-products',
    templateUrl: './chart-top-products.component.html',
    styleUrls: ['./chart-top-products.component.scss'],
})
export class ChartTopProductsComponent implements OnInit {
    title: string = 'Top Produkte ' + new Date().getFullYear();
    constructor() {}

    ngOnInit() {}
}
