import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {OrderData} from '@shared/order-data';

/**
 * @brief   Service, der Funktionen für die Aufträge einer Einrichtung bietet.
 * @author  Michael Schiffner <m.schiffner@pharmakon.software>
 * @author  Sena Üner <s.uener@pharmakons.software>
 */
@Injectable({providedIn: 'root'})
export class InstitutionsOrdersService {
    // Ausgewählter Auftrag
    selectedOrder: OrderData;

    // Subject (Observable) definieren "Es wurde eine andere Einrichtung ausgewählt"
    public selectionChanged = new Subject<number>();

    // Konstruktor
    constructor(private backendService: BackendService) {}

    /*
     * @brief   Wird aufgerufen, falls eine andere Einrichtung ausgewählt werden soll
     */
    selectOrder(selectedOrder: OrderData) {
        // Ausgewählte Einrichtung zwischenspeichern
        this.selectedOrder = selectedOrder;
        // Event auslösen um andere Komponenten des Feature-Moduls zu informieren
        this.selectionChanged.next(this.selectedOrder.id);
    }

    /**
     * @param id
     * @param page
     * @param allowedDivisions
     * @brief   Auftragsdaten einer Einrichtung laden
     *  @returns  Observable<any>
     */
    loadData(id: number, page: number, allowedDivisions?: number[]): Observable<any> {
        let requestData = {};
        // Falls die Auftragserfassung nur in bestimmten Divisionen erlaubt ist.
        if (typeof allowedDivisions !== 'undefined') {
            requestData = {allowedDivisions};
        }
        // Post-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'InstitutionsOrders/index/' + id + '/' + page,
            requestData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
