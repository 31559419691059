// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/**
 * 1. Allgemein
 * ============
 *
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
*/
:host {
  /*margin: auto 0;*/
  margin-top: 5px; /* 2019-07-12, PhS(MFe): angepasst, damit Ausrichtung zu Toolbar-Änderungen passen */
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid-layout/grid-layout.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;;;CAAA;AAOA;EACI,kBAAA;EACA,eAAA,EAAA,oFAAA;AACJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n*/\n\n:host {\n    /*margin: auto 0;*/\n    margin-top: 5px; /* 2019-07-12, PhS(MFe): angepasst, damit Ausrichtung zu Toolbar-Änderungen passen */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
