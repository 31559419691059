import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-products-sales',
    templateUrl: './chart-products-sales.component.html',
    styleUrls: ['./chart-products-sales.component.scss'],
})
export class ChartProductsSalesComponent implements OnInit {
    // institutionId = 120163;
    institutionId = 0;
    @Input() chartLocation = 'dashboards';
    // @Input() backendOptions = {'institutionId' : this.institutionId, location : this.chartLocation};
    @Input() backendOptions;

    title: string = 'Umsatz je Präparat ' + new Date().getFullYear();

    constructor() {}

    ngOnInit() {}
}
