// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Highcharts
import {Chart} from 'angular-highcharts';
import * as Highcharts from 'highcharts';
// Service des übergeordneten Feature-Moduls
import {InstitutionsService} from '../institutions.service';
// Eigener Service
import {InstitutionsSalesService} from './institutions-sales.service';
// Interfaces für Structured Objects einbinden
import {hasOwn} from '@shared/utils';

// Environment
import {environment} from '@environment';

@Component({
    selector: 'phscw-institutions-sales',
    templateUrl: './institutions-sales.component.html',
    styleUrls: ['./institutions-sales.component.scss'],
})
export class InstitutionsSalesComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;

    // Modul-Daten (Umsätze der Einrichtung)
    data: any = {};

    // EditMode aktiv?
    @Input() editMode = false;

    // "Mehr laden..."
    loadMoreEnabled = false;
    loadMoreVisible = true;
    gridPage = 1;

    // Flag definiert ob gerade geladen wird
    loading = false;

    // Chart-Objekte für Umsatzdaten
    chart1: Chart;
    chart2: Chart;

    backendOptions: any;
    @Input() chartLocation = 'institutions';

    shownCharts: string[] = [];

    // Konstruktor (inkl. dependency injection)
    constructor(
        private institutionsService: InstitutionsService,
        private institutionsSalesService: InstitutionsSalesService,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Chart-Optionen initialisieren (Sprach-Einstellungen)
        this.initializeChartOptions();
        // Events subscriben
        this.initializeEventSubscriptions();
        // Charts initialisieren
        this.initializeCharts();
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    /**
     * Angular-Spracheinstellungen
     * @todo: Zentralisieren / Aufräumen
     */
    initializeChartOptions() {
        Highcharts.setOptions({
            lang: {
                decimalPoint: ',',
                thousandsSep: '.',
                loading: 'Daten werden geladen...',
                months: [
                    'Januar',
                    'Februar',
                    'März',
                    'April',
                    'Mai',
                    'Juni',
                    'Juli',
                    'August',
                    'September',
                    'Oktober',
                    'November',
                    'Dezember',
                ],
                weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                printChart: 'Drucken',
                rangeSelectorFrom: 'Von',
                rangeSelectorTo: 'Bis',
                rangeSelectorZoom: 'Zeitraum',
                downloadPNG: 'Download als PNG-Bild',
                downloadJPEG: 'Download als JPEG-Bild',
                downloadPDF: 'Download als PDF-Dokument',
                downloadSVG: 'Download als SVG-Bild',
                resetZoom: 'Zoom zurücksetzen',
                resetZoomTitle: 'Zoom zurücksetzen',
            },
        });
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // In der E-Liste wird eine Einrichtung ausgewählt
        this._subscriptions.add(
            this.institutionsService.selectionChanged.subscribe((result) => {
                this.onSelectionChanged(result);
            }),
        );
    }

    /*
     * Nützliche Hilfsfunktion um eine Farbe aufzuhellen
     * https://stackoverflow.com/questions/6443990/javascript-calculate-brighter-colour
     */
    colorIncreaseBrightness(hex: string, percent: number) {
        // strip the leading # if it's there
        hex = hex.replace(/^\s*#|\s*$/g, '');

        // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
        if (hex.length == 3) {
            hex = hex.replace(/(.)/g, '$1$1');
        }

        const r = parseInt(hex.substr(0, 2), 16);
        const g = parseInt(hex.substr(2, 2), 16);
        const b = parseInt(hex.substr(4, 2), 16);

        const brighterColor: string =
            '#' +
            (0 | ((1 << 8) + r + (256 - r) * percent)).toString(16).substr(1) +
            (0 | ((1 << 8) + g + (256 - g) * percent)).toString(16).substr(1) +
            (0 | ((1 << 8) + b + (256 - b) * percent)).toString(16).substr(1);

        return brighterColor;
    }

    // Charts initialisieren
    initializeCharts() {
        if (hasOwn(environment, 'chartInstitutionsSales')) {
            this.shownCharts = environment['chartInstitutionsSales'];
        } else {
            this.shownCharts = [];
        }

        this.chart1 = this.initializePiechart();
        this.chart2 = this.initializeLinechart();
    }

    // Liniendiagramm
    initializeLinechart() {
        /*
         * Konfiguration
         * let chartType: string = 'line';
         */
        const chartTitle = 'Umsatz 12 Monate';
        const chartSubtitle = '';
        const chartColors: string[] = ['#f0a762', '#7798bf', '#8fbc8f', '#9e9e9e'];
        const chartCategories: string[] = [
            'Jan',
            'Feb',
            'Mär',
            'Apr',
            'Mai',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Okt',
            'Nov',
            'Dez',
        ];
        const chartYAxisTitle = 'Umsatz in €';
        const chartLegend: any = {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
        };
        const chartSeries: any[] = [
            {
                type: 'line',
                name: 'Alpha',
                data: [311, 241, 241, 261, 268, 283, 258, 314, 281, 269, 323, 317],
            },
            {
                type: 'line',
                name: 'Beta',
                data: [105, 93, 116, 98, 86, 91, 121, 96, 85, 96, 111, 114],
            },
            {
                type: 'line',
                name: 'Gamma',
                data: [229, 179, 208, 216, 154, 241, 206, 235, 185, 200, 183, 235],
            },
        ];

        // Neues Chart-Objekt
        const newChart = new Chart({
            chart: {type: 'line'},
            title: {text: chartTitle},
            subtitle: {text: chartSubtitle},
            credits: {enabled: false},
            xAxis: {categories: chartCategories},
            yAxis: {title: {text: chartYAxisTitle}},
            legend: chartLegend,
            colors: chartColors,
            series: chartSeries,
            responsive: {
                rules: [
                    {
                        condition: {maxWidth: 500},
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom',
                            },
                        },
                    },
                ],
            },
        });

        // Rückgabe
        return newChart;
    }

    // Kreisdiagramm
    initializePiechart() {
        /*
         * Konfiguration
         * let chartType: string = 'pie';
         */
        const chartTitle = 'Umsatz nach Produkten';
        const chartSubtitle = '';
        const chartColors: string[] = ['#f0a762', '#7798bf', '#8fbc8f', '#9e9e9e'];

        // Einfache Datenserie für Piechart
        /*
         * let chartSeries: any[] = [{
         *    type: 'pie',
         *    name: 'Products',
         *    data: [{
         *        name: 'Alpha',
         *        y: 60.84,
         *        sliced: true,
         *        selected: true
         *    }, {
         *        name: 'Beta',
         *        y: 27.35
         *    }, {
         *        name: 'Gamma',
         *        y: 11.79
         *    }]
         * }];
         */

        // Holgers Daten aus (docx inkl. Drilldown)
        const chartCategories = ['Alpha', 'Beta', 'Gamma'];
        const data = [
            {
                y: 3367,
                color: chartColors[0],
                drilldown: {
                    name: chartCategories[0],
                    categories: ['Alpha 5ml', 'Alpha 10ml'],
                    data: [1243, 2124],
                },
            },
            {
                y: 1212,
                color: chartColors[1],
                drilldown: {
                    name: chartCategories[1],
                    categories: ['Beta 5ml'],
                    data: [1212],
                },
            },
            {
                y: 2471,
                color: chartColors[2],
                drilldown: {
                    name: chartCategories[2],
                    categories: ['Gamma 5ml', 'Gamma 10ml'],
                    data: [1820, 651],
                },
            },
        ];
        const dataLen = data.length;
        const productgroupData = [];
        const productData = [];
        let drillDataLen;

        // Datenarray bauen
        for (let i = 0; i < dataLen; i += 1) {
            // Daten der Produktgruppen
            productgroupData.push({
                name: chartCategories[i],
                y: data[i].y,
                color: data[i].color,
            });
            // Daten der Produkte
            drillDataLen = data[i].drilldown.data.length;
            for (let j = 0; j < drillDataLen; j += 1) {
                const brightness = 0.2 - j / drillDataLen / 5;
                productData.push({
                    name: data[i].drilldown.categories[j],
                    y: data[i].drilldown.data[j],
                    color: this.colorIncreaseBrightness(data[i].color, brightness),
                });
            }
        }
        // Neue Datenserie für Kreisdiagramm
        const chartSeries: any[] = [
            {
                name: 'Produktgruppe',
                data: productgroupData,
                size: '60%',
                dataLabels: {
                    formatter() {
                        return this.y > 5 ? this.point.name : null;
                    },
                    color: '#ffffff',
                    distance: -30,
                },
            },
            {
                name: 'Produkt',
                data: productData,
                size: '80%',
                innerSize: '60%',
                dataLabels: {
                    formatter() {
                        // display only if larger than 1
                        return this.y > 1 ? '<b>' + this.point.name + ':</b> ' + this.y + ' €' : null;
                    },
                },
                id: 'versions',
            },
        ];

        // Neues Chart-Objekt
        const newChart = new Chart({
            chart: {type: 'pie'},
            title: {text: chartTitle},
            subtitle: {text: chartSubtitle},
            credits: {enabled: false},
            colors: chartColors,
            series: chartSeries,
        });

        // Rückgabe
        return newChart;
    }

    // In der E-Liste wird eine Einrichtung ausgewählt
    onSelectionChanged(id: number) {
        // Bereits vorhandene Daten (einer anderen Einrichtung) entfernen
        this.resetData();
        // ID der aktuellen Einrichtung merken
        this.institutionId = id;
        this.backendOptions = {
            institutionId: this.institutionId,
            location: this.chartLocation,
        };
    }

    // Klick auf "Abbrechen"
    clickCancel(): void {
        // EditMode verlassen
        this.editMode = false;
    }

    // Daten zurücksetzen (z.B. wenn Einrichtung gewechselt wird)
    resetData(): void {
        this.loadMoreEnabled = false;
        this.loadMoreVisible = true;
        this.gridPage = 1;
        this.data = [];
    }
}
