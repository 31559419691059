import {Pipe, PipeTransform} from '@angular/core';
import {SelectData} from '@shared/select-data';

/*
 * Holt den Label des listentries aus einer SelectData-Liste anhand von dem list_key
 */
@Pipe({
    name: 'findLabel',
    pure: true, // Nur bei Input-Änderungen ausgeführt
})
export class FindLabelPipe implements PipeTransform {
    transform(list: SelectData[], listKey: string): string {
        if (!list || !listKey) return '';
        const item = list.find((entry) => entry.id === listKey);
        return item ? item.label : 'Unknown';
    }
}
