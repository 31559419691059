// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from './../../../services/backend.service';
// Interfaces für Structured Objects einbinden

@Injectable({providedIn: 'root'})
export class GlobalHelpModulesService {
    /**
     * Konstruktor (inkl. dependency injection)
     * @param backendService
     */
    constructor(private backendService: BackendService) {}

    /**
     * @brief   ???
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    loadModuleNames(): Observable<any> {
        // Modulnamen Laden
        const request$: Observable<any> = this.backendService.getRequest('HelpModules/index');
        // Eintrag zurückgeben
        return request$;
    }

    /**
     * @param id
     * @brief   ???
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    loadModules(id: number): Observable<any> {
        // Module Laden
        const request$: Observable<any> = this.backendService.getRequest('HelpModules/getModulesForEntry/' + id);
        // Eintrag zurückgeben
        return request$;
    }

    /**
     * @param enteryID
     * @param moduleID
     * @brief   ???
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    removeModule(enteryID: number, moduleID: number): Observable<any> {
        // Module Laden
        const request$: Observable<any> = this.backendService.postRequest(
            'HelpModules/delete/' + enteryID + '/' + moduleID,
            {},
        );
        // Eintrag zurückgeben
        return request$;
    }

    /**
     * @param enteryID
     * @param moduleID
     * @brief   ???
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    addModule(enteryID: number, moduleID: number): Observable<any> {
        // Module Laden
        const request$: Observable<any> = this.backendService.postRequest(
            'HelpModules/add/' + enteryID + '/' + moduleID,
            {},
        );
        // Eintrag zurückgeben
        return request$;
    }
}
