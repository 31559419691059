// Angular-Module
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
// Service für Übersetzungen über NGX-Translate
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services einbinden
import {StorageService} from '@global/services/storage.service';
// Eigener Service
import {GlobalSettingsChangepasswordService} from './global-settings-changepassword.service';
// Shared Interface
import {CWResult} from '@shared/cw-result';
import {LooseObject} from '@shared/loose-object';
// Levenshtein
import * as _levenshtein from 'js-levenshtein';

const levenshtein = _levenshtein;

interface ValidationRules {
    rule_name: string;
    pattern: string;
    rule_text_deu: string;
    rule_text_eng: string;
    rule_text_fra: string;
}

// Cross-Validator, zum Prüfen, ob das neue Passwort und das Bestätigungs-Feld übereinstimmen
export const passwordCrossValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.get('password_new');
    const passwordConfirm = control.get('password_new_confirm');

    return newPassword.value === passwordConfirm.value ? null : {passwordsDontMatch: true};
};

// Cross-Validator, zum Prüfen, ob das alte und neue Passwort unterschiedlich genug sind
/**
 *
 * @param allowedDistance
 */
export function createLevenshteinValidator(allowedDistance: number): ValidatorFn {
    return function passwordLevenshteinValidator(control: AbstractControl): ValidationErrors | null {
        const oldPasswordControl = control.get('password_old');
        const newPasswordControl = control.get('password_new');
        const levenshteinDistance = levenshtein(oldPasswordControl.value, newPasswordControl.value);

        return levenshteinDistance >= allowedDistance ? null : {newPasswordTooSimilar: true};
    };
}

// Custom Validator für Passwörter
/**
 *
 * @param rules
 */
export function createCustomPasswordValidator(rules: ValidationRules): ValidatorFn {
    return function validatePassword(c: AbstractControl): ValidationErrors | null {
        const error: any = {};
        error[rules.rule_name] = true;
        return new RegExp(rules.pattern).test(c.value) ? null : error;
    };
}

@Component({
    selector: 'phscw-global-settings-changepassword',
    templateUrl: './global-settings-changepassword.component.html',
    styleUrls: ['./global-settings-changepassword.component.scss'],
})
export class GlobalSettingsChangepasswordComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf Form
    private changePasswordForm: UntypedFormGroup;

    // Wurde Passwort geändert? -> Blende entsprechende Container ein/ aus
    public passwordChanged = false;

    // Fehlercodes aus dem Backend
    backendErrorCodes: LooseObject = {
        rulesViolated: false,
        saltedMatch: false,
        noReuse: false,
    };

    // Passwort-Sicherheit
    validationRules: ValidationRules[] = [];
    minimumLength = 0;
    minimumEditDistance = 4;

    // ausgewählte Sprache
    currentLanguage = 'deu';
    // Schließen/Abbrechen deaktiviert?
    @Input() disableClose = false;

    // Flag definiert, ob gerade geladen wird
    loading = false;
    // Flag definiert ob gerade gespeichert wird
    saving = false;

    // Component-Event-Binding fürs Schließen
    @Output() buttonCloseClicked = new EventEmitter<any>();

    // Konstruktor
    constructor(
        private storageService: StorageService,
        private globalSettingsChangepasswordService: GlobalSettingsChangepasswordService,
        private translateService: TranslateService,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
        // Lade Validation-Regeln
        this.loadValidationRules();
        // Sprache beim initialisieren setzen
        this.currentLanguage = this.translateService.currentLang;
    }

    // Aufräumen
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeEventSubscriptions(): void {
        // Button in Toolbar wurde angeklickt...
        this.translateService.onLangChange
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: LangChangeEvent) => {
                this.currentLanguage = event.lang;
            });
    }

    /**
     * Speichern geklickt
     */
    clickSubmit(): void {
        // Eigentlich überflüssig, aber zur Sicherheit, prüfen, ob das Formular valide ist
        if (!this.changePasswordForm.valid) {
            return;
        }

        // Fehler zurücksetzen
        Object.keys(this.backendErrorCodes).forEach((code: string) => (this.backendErrorCodes[code] = false));

        // Formular speichern
        this.changePassword();
    }

    /**
     * Speichert das Passwort in der Datenbank
     */
    changePassword(): void {
        // Flag "saving" aktivieren
        this.saving = true;

        // Sende "Passwort ändern" - Request
        const serviceRequest$ = this.globalSettingsChangepasswordService.saveNewPassword(this.changePasswordForm.value);
        serviceRequest$.subscribe((result: CWResult) => {
            // Prüfe Ergebnis des Results
            if (result.success) {
                // Falls das Passwort erfolgreich gespeichert wurde, gib eine Erfolgsmeldung aus
                this.passwordChanged = true;
                // Flag zurücksetzen, damit das Popup geschlossen werden kann
                this.disableClose = false;
                // Daten im Speicher setzen
                this.updateLocalData();
            } else {
                // Fehler "altes Passwort stimmt nicht"
                if (
                    Object.prototype.hasOwnProperty.call(result.data, 'password_old') &&
                    Object.prototype.hasOwnProperty.call(result.data.password_old, 'saltedMatch')
                ) {
                    this.backendErrorCodes.saltedMatch = true;
                    this.backendErrorCodes.rulesViolated = true;
                }

                // Fehler "neues Passwort ist enthalten in den letzten Passwörtern"
                if (
                    Object.prototype.hasOwnProperty.call(result.data, 'password_new') &&
                    Object.prototype.hasOwnProperty.call(result.data.password_new, 'no_reuse')
                ) {
                    this.backendErrorCodes.noReuse = true;
                    this.backendErrorCodes.rulesViolated = true;
                }
                // Irgendein anderer Fehler gefunden
                if (
                    (Object.prototype.hasOwnProperty.call(result.data, 'password_old') &&
                      Object.keys(result.data.password_old).length > 0) ||
                      (Object.prototype.hasOwnProperty.call(result.data, 'password_new') &&
                        Object.keys(result.data.password_new).length > 0)
                ) {
                    this.backendErrorCodes.rulesViolated = true;
                }
            }

            // Flag "saving" deaktivieren im Erfolgs- UND Fehlerfall
            this.saving = false;
        });
    }

    /**
     * Popup schliessen
     */
    clickAndClose(): void {
        // Schließen verhindern
        if (this.disableClose) {
            return;
        }
        // Parent-Komponente über Event informieren, dass Schließen-Button geklickt wurde
        this.buttonCloseClicked.emit();
    }

    /**
     * ???
     */
    loadValidationRules(): void {
        // Flag "saving" aktivieren
        this.loading = true;
        const serviceRequest$ = this.globalSettingsChangepasswordService.getValidationRules();
        serviceRequest$.subscribe((result: CWResult) => {
            // Prüfe Ergebnis des Results
            if (result.success) {
                // Falls das Passwort erfolgreich gespeichert wurde, gib eine Erfolgsmeldung aus
                this.validationRules = result.data['rules'];
                this.minimumLength = result.data['minLength'];
                this.minimumEditDistance = result.data['minimumEditDistance'];
                // Initialize FormGroup
                this.initializeFormGroup();
            }
            // Flag "loading" deaktivieren im Erfolgs- UND Fehlerfall
            this.loading = false;
        });
    }

    /**
     * Initialisiere das Formular mit den Validierungs-REgeln
     */
    initializeFormGroup(): void {
        /*
         * Form-Gruppe mit den drei Passwörtern und einem Crossvalidator zum
         * Vergleich des neuen Passworts mit der Wiederholung.
         */
        this.changePasswordForm = new UntypedFormGroup(
            {
                password_old: new UntypedFormControl('', [Validators.required]),
                password_new: new UntypedFormControl(''),
                password_new_confirm: new UntypedFormControl('', [Validators.required]),
            },
            {validators: [passwordCrossValidator, createLevenshteinValidator(this.minimumEditDistance)]},
        );

        const validators: ValidatorFn[] = [];
        for (const rule of this.validationRules) {
            // Startenden und nachfolgenden Slash "/" entfernen, da es sonst bei Problemen bei der Regex-Erstellung kommt
            rule.pattern = rule.pattern.slice(1, -1);
            validators.push(createCustomPasswordValidator(rule));
        }

        // Pushe noch required und die minLength auf das Validator-Funktions-Array
        validators.push(Validators.required);
        validators.push(Validators.minLength(this.minimumLength));

        this.changePasswordForm.controls['password_new'].setValidators(validators);
    }

    /**
     * @brief   Daten im Speicher aktualisieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private updateLocalData(): void {
        // Daten über Service anfordern
        const promise = this.storageService.getItem('ownUser');
        promise.then((val: LooseObject) => {
            // Flag aktualisieren
            val['force_password_change'] = false;
            // Daten im Speicher überschreiben
            this.storageService.setItem('ownUser', val);
        });
    }
}
