// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cw-toolbar-icon {
  color: var(--color-primary-dark);
  font-size: 1.8rem;
  margin: 0;
}

.cw-toolbar-icon:active {
  color: var(--color-default-light);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid/grid-dailyreport-approval/grid-dailyreport-approval.component.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,iBAAA;EACA,SAAA;AACJ;;AAEA;EACI,iCAAA;AACJ","sourcesContent":[".cw-toolbar-icon {\n    color: var(--color-primary-dark);\n    font-size: 1.8rem;\n    margin: 0;\n}\n\n.cw-toolbar-icon:active {\n    color: var(--color-default-light);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
