/**
 * @brief   Core-Modul / Root-Modul C-World 4 Frontend
 * @details Hier werden folgende globale Bestandteile eingebunden:
 *          - globale Komponenten (global/components)
 *            Die globalen Komponenten werden in der Root-Komponente
 *            der Anwendung verwendet.
 *          - globale Layouts (global/layouts)
 *            Die zwei globalen Layouts (Login & Main) werden durch das Routing
 *            entsprechend gewechselt.
 *          - globale Services (global/services)
 *            Bei globalen Services handelt es sich um Singletons, die durch
 *            andere Komponenten verwendet werden können.
 * @see     Angular-Split       https://www.npmjs.com/package/angular-split
 *          ngForage            https://www.npmjs.com/package/ngforage
 *          Angular-Highcharts  https://www.npmjs.com/package/angular-highcharts
 *          Angular-Calendar    https://www.npmjs.com/package/angular-calendar
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router, RouteReuseStrategy} from '@angular/router';

// https://angular.io/guide/i18n#i18n-pipes
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';

// Angular-Material
import {MatBadgeModule} from '@angular/material/badge'; // bisher nur für global-notifcations
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card'; // bisher nur für global-notifcations
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider'; // bisher nur für global-notifcations
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list'; // bisher nur für global-notifcations
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle'; // bisher nur für Darkmode
import {MatTreeModule} from '@angular/material/tree';

// Angular-Split
import {AngularSplitModule} from 'angular-split';
// ngForage (localForage)
import {DEFAULT_CONFIG, Driver, NgForageOptions} from 'ngforage';
// Angular-Highcharts
import {ChartModule, HIGHCHARTS_MODULES} from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
// Angular Calendar
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
// Für Übersetzung benötigte Module
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CustomTranslateHttpLoader} from './custom-http-loader';
// WYSIWYG-Editor
import {QuillModule} from 'ngx-quill';
// Haupt-Komponente der Anwendung
import {AppComponent} from './app.component';
// Haupt-Routing-Modul
import {AppRoutingModule} from './app-routing.module';
import {CustomRouteReuseStrategy} from './custom-route-reuse-strategy';
// Globale Komponenten einbinden
import {GlobalAccountinfoPopupComponent} from './global/components/global-accountinfo-popup/global-accountinfo-popup.component';
import {GlobalAccountinfoComponent} from './global/components/global-accountinfo/global-accountinfo.component';
import {GlobalComponentPopupComponent} from './global/components/global-component-popup/global-component-popup.component';
import {GlobalHeaderComponent} from './global/components/global-header/global-header.component';
import {GlobalHelpButtonsComponent} from './global/components/global-help/global-help-buttons/global-help-buttons.component';
import {GlobalHelpDetailsComponent} from './global/components/global-help/global-help-details/global-help-details.component';
import {GlobalHelpDocumentsComponent} from './global/components/global-help/global-help-documents/global-help-documents.component';
import {GlobalHelpIconComponent} from './global/components/global-help/global-help-icon/global-help-icon.component';
import {GlobalHelpListsComponent} from './global/components/global-help/global-help-lists/global-help-lists.component';
import {GlobalHelpMenuComponent} from './global/components/global-help/global-help-menu/global-help-menu.component';
import {GlobalHelpModulesComponent} from './global/components/global-help/global-help-modules/global-help-modules.component';
import {GlobalHelpNavComponent} from './global/components/global-help/global-help-nav/global-help-nav.component';
import {GlobalHelpPopupComponent} from './global/components/global-help/global-help-popup/global-help-popup.component';
import {GlobalHelpRolesComponent} from './global/components/global-help/global-help-roles/global-help-roles.component';
import {GlobalHelpSearchComponent} from './global/components/global-help/global-help-search/global-help-search.component';
import {GlobalHelpTextComponent} from './global/components/global-help/global-help-text/global-help-text.component';
import {GlobalHelpToolbarComponent} from './global/components/global-help/global-help-toolbar/global-help-toolbar.component';
import {GlobalLoginComponent} from './global/components/global-login/global-login.component';
import {GlobalLogoutComponent} from './global/components/global-logout/global-logout.component';
import {GlobalMenuComponent} from './global/components/global-menu/global-menu.component';
import {GlobalNotificationsComponent} from './global/components/global-notifications/global-notifications.component';
import {GlobalPageNotFoundComponent} from './global/components/global-page-not-found/global-page-not-found.component';
import {GlobalRegionsfilterComponent} from './global/components/global-regionsfilter/global-regionsfilter.component';
import {GlobalSearchComponent} from './global/components/global-search/global-search.component';
import {GlobalSettingsChangecolorthemeComponent} from './global/components/global-settings-changecolortheme/global-settings-changecolortheme.component';
import {GlobalSettingsChangelanguageComponent} from './global/components/global-settings-changelanguage/global-settings-changelanguage.component';
import {GlobalSettingsChangepasswordComponent} from './global/components/global-settings-changepassword/global-settings-changepassword.component';
import {GlobalSettingsPopupComponent} from './global/components/global-settings-popup/global-settings-popup.component';
import {GlobalErrorPopupComponent} from './global/components/global-error-popup/global-error-popup.component';
import {GlobalSettingsComponent} from './global/components/global-settings/global-settings.component';
// Globale Layouts einbinden (im Grunde auch globale Komponenten)
import {LayoutLoginComponent} from './global/layouts/layout-login/layout-login.component';
import {LayoutMainComponent} from './global/layouts/layout-main/layout-main.component';
// Globale Services einbinden
import {RequestInterceptorService} from './global/services/request-interceptor.service';
// Module einbinden
import {InstitutionsModule} from '@modules/institutions/institutions.module';
// Shared Modules einbinden
import {DocumentsModule} from './shared/documents/documents.module';
import {GridModule} from './shared/grid/grid.module';
import {InputModule} from './shared/input/input.module';
import {OutputModule} from './shared/output/output.module';
import {OverlayModule} from './shared/overlay/overlay.module';
import {PipesModule} from './shared/pipes/pipes.module';
import {DirectivesModule} from './shared/directives/directives.module';
import {SplitterModule} from './shared/splitter/splitter.module';
import {ToolbarModule} from './shared/toolbar/toolbar.module';
import {ClipboardModule} from '@angular/cdk/clipboard';

// Direktiven einbinden
import {DynamicInsertionDirective} from './global/components/global-component-popup/dynamic-insertion.directive';

/*
 * HammerJS für Material Slider (siehe https://github.com/angular/components/issues/17420)
 * @todo entfernen sobald Upgrade auf Material v9 durchgeführt wurde
 * import 'hammerjs';
 */
import {GlobalSettingsSelectDeputyComponent} from './global/components/global-settings-select-deputy/global-settings-select-deputy.component';

import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {ServiceWorkerModule} from '@angular/service-worker';
import {UpdateService} from '@global/services/update.service';
import {GlobalUpdateDialogComponent} from '@global/components/global-update-dialog/global-update-dialog.component';
import {environment} from '@environment';
import {DefaultReactiveComponent} from '@shared/default-reactive/default-reactive.component';
// the second parameter 'de' is optional
registerLocaleData(localeDe, 'de');

// NGX-Translate für Angular < 4.3 --- AoT requires an exported function for factories
/**
 *
 * @param http
 */
export function HttpLoaderFactory(http: HttpClient) {
    return new CustomTranslateHttpLoader(http, [
        {
            prefix: './assets/i18n/default/',
            suffix: '.json',
        },
        {
            prefix: './assets/i18n/customer/',
            suffix: '.json',
        },
    ]);
}

// Änderungen für ngforage Version 4.0.1 um Probleme beim AOT-Build zu beheben
const ngfRootOptions: NgForageOptions = {
    name: 'cw4',
    driver: [
        // defaults to indexedDB -> webSQL -> localStorage -> sessionStorage
        Driver.INDEXED_DB,
        Driver.LOCAL_STORAGE,
    ],
};

@NgModule({
    // Module importieren
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        // Angular Material
        MatDialogModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatIconModule,
        MatTreeModule,
        MatMenuModule,
        MatCardModule,
        MatBadgeModule,
        MatDividerModule,
        MatListModule,
        MatExpansionModule,
        ReactiveFormsModule,

        // Angular-Split
        AngularSplitModule,
        // Module einbinden
        InstitutionsModule,
        // Shared Modules einbinden
        InputModule,
        OutputModule,
        OverlayModule,
        SplitterModule,
        ToolbarModule,
        GridModule,
        DocumentsModule,
        PipesModule,
        DirectivesModule,
        ClipboardModule,
        /*
         * ngforage Version 3.4.0 - forRoot() macht Probleme beim AOT-Build
         * Optional in Angular 6 and up
         * NgForageModule.forRoot(),
         * Optional configuration as an alternative to what's below in Angular 6+
         */
        /*
         * NgForageModule.forRoot({
         *      name: 'cw4',
         *      driver: [ // defaults to indexedDB -> webSQL -> localStorage -> sessionStorage
         *          NgForageConfig.DRIVER_INDEXEDDB,
         *          NgForageConfig.DRIVER_LOCALSTORAGE
         *      ]
         *  }),
         */
        // NGX-Translate für Angular < 4.3 --- NGX-Translate für Übersetzungen
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        /*
         * TranslateModule.forRoot()
         * WYSIWYG-Editor
         */
        QuillModule.forRoot(),
        // Angular-Highcharts-Module
        ChartModule,
        // Angular-Calendar
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        FontAwesomeModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.enableNgSw, // wir haben scheinbar keine prod builds ¯\_(ツ)_/¯
            registrationStrategy: 'registerImmediately', // Register the ServiceWorker as soon as possible
        }),
    ],
    // Komponenten (lokal im Template verfügbar / private)
    declarations: [
        AppComponent,
        GlobalAccountinfoComponent,
        GlobalAccountinfoPopupComponent,
        GlobalHeaderComponent,
        GlobalLoginComponent,
        GlobalLogoutComponent,
        GlobalMenuComponent,
        GlobalNotificationsComponent,
        GlobalPageNotFoundComponent,
        GlobalRegionsfilterComponent,
        GlobalSearchComponent,
        GlobalSettingsComponent,
        GlobalSettingsPopupComponent,
        GlobalErrorPopupComponent,
        GlobalSettingsChangepasswordComponent,
        GlobalSettingsChangelanguageComponent,
        GlobalSettingsChangecolorthemeComponent,
        GlobalNotificationsComponent,
        GlobalHelpIconComponent,
        GlobalHelpPopupComponent,
        GlobalHelpNavComponent,
        GlobalHelpSearchComponent,
        GlobalHelpButtonsComponent,
        GlobalHelpListsComponent,
        GlobalHelpDetailsComponent,
        GlobalHelpToolbarComponent,
        GlobalHelpTextComponent,
        GlobalHelpMenuComponent,
        GlobalHelpDocumentsComponent,
        GlobalHelpModulesComponent,
        GlobalComponentPopupComponent,
        GlobalUpdateDialogComponent,
        LayoutLoginComponent,
        LayoutMainComponent,
        DynamicInsertionDirective,
        GlobalHelpRolesComponent,
        GlobalSettingsSelectDeputyComponent,
        DefaultReactiveComponent,
    ],
    // Services (Single shared instances | Singletons, sind seit Angular 6 global verfügbar und müssen nicht mehr separat "provided" werden)
    providers: [
        // Bei jedem HTTP-Request wird der Interceptor-Service aufgerufen
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptorService,
            multi: true,
        },
        {
            provide: DEFAULT_CONFIG,
            useValue: ngfRootOptions,
        },
        // Eigene Route-Reuse-Strategy verwenden
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy,
        },
        // Sprache global setzen
        {
            provide: LOCALE_ID,
            useValue: 'de-DE',
        },
        // Highchart-Modules (added as factory to províders)
        {
            provide: HIGHCHARTS_MODULES,
            useFactory: () => [more, exporting],
        },
        UpdateService,
    ],
    // Initialisierung
    bootstrap: [AppComponent],
    // Test MFE
    exports: [TranslateModule],
})
export class AppModule {
    // Diagnostic only: inspect router configuration
    constructor(
        router: Router,
        library: FaIconLibrary,
        // ngfConfig: NgForageConfig
    ) {
        library.addIconPacks(fas, far);
        // ngforage Version 3.4.0 - forRoot() macht Probleme beim AOT-Build
        /*
         * ngfConfig.configure({
         *      name: 'cw4',
         *      driver: [ // defaults to indexedDB -> webSQL -> localStorage -> sessionStorage
         *          NgForageConfig.DRIVER_INDEXEDDB,
         *          NgForageConfig.DRIVER_LOCALSTORAGE
         *      ]
         *  });
         */
    }
}
