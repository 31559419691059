export class Ticket {
    id = 0;
    contact_id = 0;
    multi_ticket_id?: number = null;

    firstname?: string = '';
    lastname?: string = '';
    phone?: string = '';
    street?: string = '';
    zipcode?: string = '';
    city?: string = '';
    country?: string = '';
    institution_name?: string = '';

    mail?: string = '';
    person_type = '';

    product_flag = '';
    ticket_source = '';
    ticket_product?: string = '';
    ticket_category = '';
    ticket_category_other?: string = '';
    ticket_subcategory?: string = '';
    question = '';
    response?: string = '';
    ticket_resolution?: string = '';
    ticket_state = '';
    ticket_state_reason?: string = '';
    ticket_priority = '';
    ticket_priority_reason?: string = '';
    received_date: Date;

    ticket_group?: string = '';
    create_date?: Date;
    create_user?: string = '';
    edit_date?: Date;
    edit_user?: string = '';
    delete_date?: Date;
    delete_user?: string = '';
    deleted = 'N';

    // Mitarbeiterinfos (über employee_id aus Tabelle Users)
    employee_firstname?: string = '';
    employee_lastname?: string = '';
    responsible_employee_firstname?: string = '';
    responsible_employee_lastname?: string = '';

    // Konstruktor
    constructor() {
        this.id = 0;
        this.create_date = new Date();
        this.contact_id = 0;
        this.person_type = '';
        this.product_flag = '';
        this.ticket_category = '';
        this.question = '';
        this.ticket_source = '';
        this.ticket_state = '';
        this.ticket_priority = '';
        this.received_date = new Date();
    }

    /*
     * Date-Convert (da Datum, welche übers PHP-Backend kommen als String erkannt werden)
     * 2018-07-11, PhS(MFe): Wird das nun doch nicht mehr benötigt?
     */
    public dateConvert() {
        if (this.create_date) {
            if (typeof this.create_date === 'string') {
                this.create_date = new Date(this.create_date);
            }
        }
    }
}
