// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
.cw-person-name {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid/grid-person-title-name/grid-person-title-name.component.scss"],"names":[],"mappings":"AAAA;;;;;EAAA;AAOA;EACI,YAAA;EACA,gBAAA;EACA,uBAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n */\n\n.cw-person-name {\n    flex-grow: 1;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
