export class EventPermissions {
    event_id = 0;
    // Permissions-Liste indiziert nach Permission-Name
    permissions: Record<string, EventPermissionEntry> = {};
}

class EventPermissionEntry {
    id: number;
    value: number | boolean | string;
}
