import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

@Pipe({name: 'businessYear'})
export class BusinessYearPipe implements PipeTransform {
    transform(startYear: number): string {
        if (
            hasOwn(environment, 'businessYearStart') &&
            environment.businessYearStart > 1 &&
            environment.businessYearStart <= 12
        ) {
            return String(startYear) + '/' + String(Number(startYear) + 1);
        }
        return String(startYear);
    }
}
