// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/**
 * Allgemein
 * =========
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
*/
:host {
  margin: 0 0 20px;
  width: 100%;
}

.mat-expansion-panel {
  border: 1px solid var(--color-accent);
  border-radius: 0;
  box-shadow: none;
}

.cw-button-wrapper {
  justify-content: space-between;
}

.cw-button-wrapper button {
  margin: 0;
  width: calc(50% - 5px);
  white-space: normal;
}

.cw-button-wrapper button {
  margin: 0 0 10px;
}

.cw-button-wrapper button:last-of-type,
.cw-button-wrapper button:nth-last-of-type(2):not(:nth-child(even)) {
  margin: 0;
}

/* @todo bessere Lösung finden */
/* Farbe zurücksetzen für deaktivierten Teil */
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: rgba(0, 0, 0, 0.87); /* Standard Angular Material Farbe für expansion panel */
}`, "",{"version":3,"sources":["webpack://./src/app/shared/clearing/clearing-duplicates-panel/clearing-duplicates-panel.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;;;CAAA;AAOA;EACI,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,qCAAA;EACA,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;;AAEA;EACI,SAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;;EAEI,SAAA;AACJ;;AAEA,gCAAA;AACA,8CAAA;AACA;;EAEI,0BAAA,EAAA,wDAAA;AACJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n*/\n\n:host {\n    margin: 0 0 20px;\n    width: 100%;\n}\n\n.mat-expansion-panel {\n    border: 1px solid var(--color-accent);\n    border-radius: 0;\n    box-shadow: none;\n}\n\n.cw-button-wrapper {\n    justify-content: space-between;\n}\n\n.cw-button-wrapper button {\n    margin: 0;\n    width: calc(50% - 5px);\n    white-space: normal;\n}\n\n.cw-button-wrapper button {\n    margin: 0 0 10px;\n}\n\n.cw-button-wrapper button:last-of-type,\n.cw-button-wrapper button:nth-last-of-type(2):not(:nth-child(even)) {\n    margin: 0;\n}\n\n/* @todo bessere Lösung finden */\n/* Farbe zurücksetzen für deaktivierten Teil */\n.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title,\n.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {\n    color: rgba(0, 0, 0, 0.87); /* Standard Angular Material Farbe für expansion panel */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
