// Angular-Module
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service des übergeordneten Feature-Moduls
import {InstitutionsService} from '../institutions.service';
// Eigener Service
import {InstitutionsOpeninghoursService} from './institutions-openinghours.service';
// Interfaces für Structured Objects einbinden
import {OpeninghoursData} from '@shared/openinghours-data';
// Shared Services importieren
import {InputDateService} from '@shared/input/input-date/input-date.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
// Environment
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

@Component({
    selector: 'phscw-institutions-openinghours',
    templateUrl: './institutions-openinghours.component.html',
    styleUrls: ['./institutions-openinghours.component.scss'],
})
export class InstitutionsOpeninghoursComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf Formular
    @ViewChild('institutionsOpeninghoursForm', {static: false}) institutionsOpeninghoursForm: NgForm;

    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;
    // Modul-Daten (Öffnungszeiten der Einrichtung)
    data: OpeninghoursData;
    // Da Cancel doch nicht so funktioniert wie gedacht, hier die schnelle Variante mit eigener Datenkopie vor Benutzeränderungen
    originalData: OpeninghoursData;
    // EditMode aktiv?
    @Input() editMode = false;

    // Flag definiert ob gerade geladen wird
    loading = false;
    // Flag definiert ob gerade gespeichert wird
    saving = false;
    // Flag definiert, ob Uhrzeiten angezeigt werden sollen
    enableTimeInput = false;
    enableVacations = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param institutionsService
     * @param institutionsOpeninghoursService
     * @param toolbarService
     * @param inputDateService
     */
    constructor(
        private institutionsService: InstitutionsService,
        private institutionsOpeninghoursService: InstitutionsOpeninghoursService,
        private toolbarService: ToolbarService,
        private inputDateService: InputDateService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Konfiguration laden
        this.getEnvironmentConfigurations();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // In der E-Liste wird eine Einrichtung angeklickt
        this.institutionsService.selectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: number) => {
                this.onSelectionChanged(result);
            });

        // Wenn der Klon-Button der Toolbar ausgelöst wurde Zeiten übertragen.
        this.toolbarService.eventCloneItem.pipe(takeUntil(this._componentDestroyed$)).subscribe((event: CWEvent) => {
            // Abbruch, falls das Event nicht von der eigenen Komponente kam
            if (event.target !== 'institutions-openinghours') {
                return;
            }
            this.cloneOpeningtimes();
        });

        // Wenn der Cancel-Button der Toolbar ausgelöst wurde Abbrechen auslösen.
        this.toolbarService.eventAddItem.pipe(takeUntil(this._componentDestroyed$)).subscribe((event: CWEvent) => {
            // Abbruch, falls das Event nicht von der eigenen Komponente kam
            if (event.target !== 'institutions-openinghours') {
                return;
            }
            this.clickCancel();
        });
    }

    /**
     * @brief   Auf geänderte Auswahl reagieren
     * @param id
     * @param   int  id
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onSelectionChanged(id: number): void {
        // ID der aktuellen Einrichtung merken
        this.institutionId = id;

        // Daten für neu ausgewählten Datensatz laden
        this.loadData();
    }

    /**
     * @brief   Daten laden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadData(): void {
        // Flag "loading" aktivieren
        this.loading = true;

        const serviceRequest$ = this.institutionsOpeninghoursService.loadData(this.institutionId);
        serviceRequest$.subscribe((result: CWResult) => {
            /**
             * Prüfe, ob die Daten des eintreffenden Requests auch
             * zur aktuell ausgewählten Einrichtung passen. Durch
             * asynchrone Abfragen kann es nämlich passieren, dass
             * zwischenzeitlich bereits die Einrichtung gewechselt wurde
             * und die Antwort eines Requests verspätet eintrifft und
             * dadurch die korrekten Daten wieder überschreibt.
             */
            if (this.institutionsService.selectedInstitution && result['data']) {
                if (this.institutionsService.selectedInstitution.id != result['data']['institution_id']) {
                    return;
                }
            }

            // Geladene Daten als <Institution> in Component speichern
            this.data = {...result['data']};

            // Kopie der Daten als "originalData" speichern
            this.originalData = <OpeninghoursData>JSON.parse(JSON.stringify(this.data));

            // Flag "loading" deaktivieren
            this.loading = false;
        });
    }

    /**
     * @brief   Klick auf "Speichern" (Form-Submit)
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickSubmit(): void {
        // Nur gültige Formulare werden submitted
        if (this.institutionsOpeninghoursForm.form.valid) {
            // Flag "saving" aktivieren
            this.saving = true;

            // Datum (im Frontend "MOMENT") für Backend-Speicherung konvertieren
            this.convertDateForSumbit();

            // Submit der Formular-Daten über InstitutionsDataService
            const serviceRequest$ = this.institutionsOpeninghoursService.saveData(
                this.data.id,
                this.institutionsOpeninghoursForm.form.value,
            );
            serviceRequest$.subscribe((result: CWResult) => {
                // Falls es sich um eine Neuanlage handelte...
                if (this.data['id'] == 0) {
                    // ...wird die neue ID übernommen
                    this.data['id'] = result['data']['id'];
                }

                /*
                 * Daten wurden erfolgreich geändert --> Event über Service auslösen
                 * this.institutionsOpeninghoursService.dataChanged(this.data);
                 */

                // Kopie der geänderten Daten als neue "originalData" speichern
                this.originalData = <OpeninghoursData>JSON.parse(JSON.stringify(this.data));

                // EditMode verlassen
                this.editMode = false;
                // Flag "saving" deaktivieren
                this.saving = false;
            });
        }
    }

    /**
     * @brief   Montags-Zeiten übertragen auf alle Arbeitstage
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    cloneOpeningtimes(): void {
        // Initialisiere
        const days = ['tuesday', 'wednesday', 'thursday', 'friday'];
        const timeStart1 = this.data.monday_start1;
        const timeStart2 = this.data.monday_start2;
        const timeEnd1 = this.data.monday_end1;
        const timeEnd2 = this.data.monday_end2;
        const timePreferred = this.data.monday_preferred_time;

        // Für jeden Tag Zeiten setzen
        days.forEach((day) => {
            this.data[day + '_start1'] = timeStart1;
            this.data[day + '_start2'] = timeStart2;
            this.data[day + '_end1'] = timeEnd1;
            this.data[day + '_end2'] = timeEnd2;
            this.data[day + '_preferred_time'] = timePreferred;
        });
    }

    /**
     * @brief   Klick auf "Abbrechen"
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickCancel(): void {
        /*
         * Da Cancel doch nicht so funktioniert wie gedacht, hier die schnelle Variante mit eigener Datenkopie vor Benutzeränderungen
         * this.data= Object.assign({}, this.originalData);
         */
        this.data = <OpeninghoursData>JSON.parse(JSON.stringify(this.originalData));

        // EditMode verlassen
        this.editMode = false;
    }

    /**
     * @param dateNumber
     * @brief   End-Datum korrigieren falls vor Start-Datum
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    correctContactEndDate(dateNumber: any): void {
        if (
            this.data['vacation_start' + dateNumber] &&
            typeof this.data['vacation_start' + dateNumber] !== 'undefined' &&
            this.data['vacation_start' + dateNumber].isAfter(this.data['vacation_end' + dateNumber], 'day')
        ) {
            this.data['vacation_end' + dateNumber] = this.data['vacation_start' + dateNumber];
        }
    }

    /**
     * @brief   Datum konvertieren und zwischenspeichern
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    convertDateForSumbit(): void {
        if (this.institutionsOpeninghoursForm.form.value['vacation_start1']) {
            this.institutionsOpeninghoursForm.form.value['vacation_start1_backendsave'] =
                this.inputDateService.getDateValueForSave(
                    this.institutionsOpeninghoursForm.form.value['vacation_start1'],
                );
        }

        if (this.institutionsOpeninghoursForm.form.value['vacation_end1']) {
            this.institutionsOpeninghoursForm.form.value['vacation_end1_backendsave'] =
                this.inputDateService.getDateValueForSave(
                    this.institutionsOpeninghoursForm.form.value['vacation_end1'],
                );
        }

        if (this.institutionsOpeninghoursForm.form.value['vacation_start2']) {
            this.institutionsOpeninghoursForm.form.value['vacation_start2_backendsave'] =
                this.inputDateService.getDateValueForSave(
                    this.institutionsOpeninghoursForm.form.value['vacation_start2'],
                );
        }

        if (this.institutionsOpeninghoursForm.form.value['vacation_end2']) {
            this.institutionsOpeninghoursForm.form.value['vacation_end2_backendsave'] =
                this.inputDateService.getDateValueForSave(
                    this.institutionsOpeninghoursForm.form.value['vacation_end2'],
                );
        }

        if (this.institutionsOpeninghoursForm.form.value['vacation_start3']) {
            this.institutionsOpeninghoursForm.form.value['vacation_start3_backendsave'] =
                this.inputDateService.getDateValueForSave(
                    this.institutionsOpeninghoursForm.form.value['vacation_start3'],
                );
        }

        if (this.institutionsOpeninghoursForm.form.value['vacation_end3']) {
            this.institutionsOpeninghoursForm.form.value['vacation_end3_backendsave'] =
                this.inputDateService.getDateValueForSave(
                    this.institutionsOpeninghoursForm.form.value['vacation_end3'],
                );
        }
    }

    /**
     * @brief   Einstellung aus Environment übernehmen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getEnvironmentConfigurations(): void {
        // Uhrzeit anzeigen?
        if (hasOwn(environment, 'enableInstitutionOpeninghoursTimeInput')) {
            this.enableTimeInput = environment.enableInstitutionOpeninghoursTimeInput;
        }

        // Urlaub anzeigen?
        if (hasOwn(environment, 'enableInstitutionOpeninghoursVacations')) {
            this.enableVacations = environment.enableInstitutionOpeninghoursVacations;
        }
    }
}
