// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/**
 * Allgemein
 * =========
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
*/
:host ::ng-deep .cdk-column-document-description,
:host ::ng-deep .mat-column-document-description {
  text-overflow: ellipsis;
}

/* @todo: evtl in parent component css übernehmen */
:host phscw-grid ::ng-deep .cw-input-span {
  align-self: center;
}

:host phscw-grid ::ng-deep .cw-input {
  margin: 0;
}

:host ::ng-deep .cdk-column-document-name:not(.mat-mdc-header-cell) {
  color: var(--color-accent);
  cursor: pointer;
}

:host ::ng-deep .cdk-column-document-person:not(.mat-mdc-header-cell) {
  font-weight: bold;
}

.button-download {
  margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/documents/documents-display/documents-display.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;;;CAAA;AAOA;;EAEI,uBAAA;AACJ;;AAEA,mDAAA;AACA;EACI,kBAAA;AACJ;;AAEA;EACI,SAAA;AACJ;;AAGA;EACI,0BAAA;EACA,eAAA;AAAJ;;AAIA;EACI,iBAAA;AADJ;;AAIA;EACI,kBAAA;AADJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n*/\n\n:host ::ng-deep .cdk-column-document-description,\n:host ::ng-deep .mat-column-document-description {\n    text-overflow: ellipsis;\n}\n\n/* @todo: evtl in parent component css übernehmen */\n:host phscw-grid ::ng-deep .cw-input-span {\n    align-self: center;\n}\n\n:host phscw-grid ::ng-deep .cw-input {\n    margin: 0;\n}\n\n// Gleicher Style wie 'cw-link', nur auf die Zellen der Tabelle angewendet\n:host ::ng-deep .cdk-column-document-name:not(.mat-mdc-header-cell) {\n    color: var(--color-accent);\n    cursor: pointer;\n}\n\n// Personen-Name fett\n:host ::ng-deep .cdk-column-document-person:not(.mat-mdc-header-cell) {\n    font-weight: bold;\n}\n\n.button-download {\n    margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
