// Angular-Module
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service des übergeordneten Feature-Moduls
import {InstitutionsService} from '../institutions.service';
// Eigener Service
import {PeopleNotesService} from './../../people/people-notes/people-notes.service';
import {InstitutionsNotesService} from './institutions-notes.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {LooseObject} from '@shared/loose-object';
// Service von Shared Modules
import {GridService} from '@shared/grid/grid.service';
import {OverlayService} from '@shared/overlay/overlay.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Shared Components
import {OverlayInfoInstitutionComponent} from '@shared/overlay/overlay-info/overlay-info-institution/overlay-info-institution.component';
import {OverlayInfoPersonComponent} from '@shared/overlay/overlay-info/overlay-info-person/overlay-info-person.component';
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';
// Environment einbinden
import {environment} from '@environment';

class DataItem {
    id?: any;
    person_id?: any;
    person_lastname?: any;
    person_firstname?: any;
    institution_id?: any;
    title?: any;
    note?: any;
    employee_id?: any;
    employee_lastname?: any;
    employee_firstname?: any;
    create_date?: any;
    create_user?: any;
    edit_date?: any;
    edit_user?: any;
}
@Component({
    selector: 'phscw-institutions-notes',
    templateUrl: './institutions-notes.component.html',
    styleUrls: ['./institutions-notes.component.scss'],
})
export class InstitutionsNotesComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf Form
    @ViewChild('institutionsNotesForm', {static: false}) institutionsNotesForm: NgForm;

    // ID und Name der aktuell ausgewählten Einrichtung
    institutionId: number;
    institutionName: string;
    // ID der aktuell ausgewählten Notiz
    noteId: number;
    // Modul-Daten (Notizen der Einrichtung)
    data: any[] = [];
    // Einzelne Notiz der Einrichtung
    dataItem: DataItem = new DataItem();
    // EditMode aktiv?
    @Input() editMode = false;

    // "Mehr laden..."
    loadMoreEnabled = false;
    loadMoreVisible = true;
    gridPage = 1;

    // Spaltendefinitionen für Grid
    gridColumns = [
        {
            columnDef: 'create_date',
            header: 'Datum',
            cell: (element: any) => `${element.create_date}`,
            formatTemplate: 'date',
        },
        {
            columnDef: 'create_user',
            header: 'Von',
            cell: (element: any) => `${element.create_user}`,
            formatWidth: '125px',
        },
        {
            columnDef: 'notes-institution',
            header: 'Einrichtung',
            cell: (element: any) => `${element.institution_name1 || ''}`,
            formatWidth: '125px',
        },
        {
            columnDef: 'title',
            header: 'Titel',
            cell: (element: any) => `${element.title}`,
            formatWidth: '125px',
        },
        {
            columnDef: 'note',
            header: 'Notiz',
            cell: (element: any) => `${element.note}`,
            tooltip: true,
        },
        {
            columnDef: 'note-person',
            header: 'Person',
            cell: (element: any) => {
                if (element.person_firstname) {
                    return `<strong>${element.person_lastname || ''}</strong>, ${element.person_firstname || ''} `;
                }
                return `<strong>${element.person_lastname || ''}</strong>`;
            },
            formatWidth: '125px',
        },
        {
            columnDef: 'note-employee',
            header: 'Mitarbeiter',
            cell: (element: any) => {
                if (element.employee_firstname) {
                    return `<strong>${element.employee_lastname || ''}</strong>, ${element.employee_firstname || ''} `;
                }
                return `<strong>${element.employee_lastname || ''}</strong>`;
            },
            formatWidth: '125px',
        },
    ];

    // Anzuzeigende Spalten für Grid
    gridDisplayedColumns = [
        'create_date',
        'notes-institution',
        'note-person',
        'note-employee',
        'title',
        'note',
        'delete',
    ];

    // Flag definiert ob gerade geladen wird
    loading = false;
    // Flag definiert ob gerade gespeichert wird
    saving = false;
    // Flag definiert ob der Titel angezeigt wird
    titleVisible = true;
    // Flag definiert ob der Einrichtungsname angezeigt wird
    institutionNameVisible = true;
    // Flag definiert ob der Einrichtungsname angezeigt wird
    employeeVisible = true;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param gridService
     * @param institutionsService
     * @param institutionsNotesService
     * @param peopleNotesService
     * @param toolbarService
     * @param overlayService
     * @param dialog
     * @param translateService
     */
    constructor(
        private gridService: GridService,
        private institutionsService: InstitutionsService,
        private institutionsNotesService: InstitutionsNotesService,
        private peopleNotesService: PeopleNotesService,
        private toolbarService: ToolbarService,
        private overlayService: OverlayService,
        private dialog: MatDialog,
        private translateService: TranslateService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Anzuzeigende Grid-Spalten prüfen
        if (
            Object.prototype.hasOwnProperty.call(environment, 'institutionNotesDisplayedColumns') &&
            typeof environment['institutionNotesDisplayedColumns'] !== 'undefined'
        ) {
            this.gridDisplayedColumns = environment.institutionNotesDisplayedColumns;
        }
        // Prüfe ob der Titel angezeigt werden soll
        if (
            Object.prototype.hasOwnProperty.call(environment, 'showInstitutionNotesTitle') &&
            typeof environment['showInstitutionNotesTitle'] !== 'undefined'
        ) {
            this.titleVisible = environment.showInstitutionNotesTitle;
        }
        // Prüfe ob der Einrichtungsname angezeigt werden soll
        if (
            Object.prototype.hasOwnProperty.call(environment, 'showInstitutionNotesInstitutionName') &&
            typeof environment['showInstitutionNotesInstitutionName'] !== 'undefined'
        ) {
            this.institutionNameVisible = environment.showInstitutionNotesInstitutionName;
        }
        // Prüfe ob der Mitarbeiter angezeigt werden soll
        if (
            Object.prototype.hasOwnProperty.call(environment, 'showInstitutionNotesEmployee') &&
            typeof environment['showInstitutionNotesEmployee'] !== 'undefined'
        ) {
            this.employeeVisible = environment.showInstitutionNotesEmployee;
        }

        // Titel-Spalte ausblenden, falls kein Titel angezeigt wird
        if (!this.employeeVisible) {
            this.gridDisplayedColumns = this.gridDisplayedColumns.filter((column) => column !== 'note-employee');
        }
        // Titel-Spalte ausblenden, falls kein Titel angezeigt wird
        if (!this.titleVisible) {
            this.gridDisplayedColumns = this.gridDisplayedColumns.filter((column) => column !== 'title');
        }
        // Titel-Spalte ausblenden, falls kein Titel angezeigt wird
        if (!this.institutionNameVisible) {
            this.gridDisplayedColumns = this.gridDisplayedColumns.filter((column) => column !== 'notes-institution');
        }

        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     * @author  Min Hye Park     <m.park@pharmakon.software>
     */
    initializeTranslateSubscriptions() {
        this.translateService
            .stream([
                'GENERAL.DATE',
                'GENERAL.FROM',
                'GENERAL.INSTITUTION',
                'GENERAL.TITLE',
                'GENERAL.NOTE',
                'GENERAL.PERSON',
                'GENERAL.EMPLOYEE',
            ])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: any) => {
                this.gridColumns.find((column: any) => column.columnDef === 'create_date').header =
                    translation['GENERAL.DATE'];
                this.gridColumns.find((column: any) => column.columnDef === 'create_user').header =
                    translation['GENERAL.FROM'];
                this.gridColumns.find((column: any) => column.columnDef === 'notes-institution').header =
                    translation['GENERAL.INSTITUTION'];
                this.gridColumns.find((column: any) => column.columnDef === 'title').header =
                    translation['GENERAL.TITLE'];
                this.gridColumns.find((column: any) => column.columnDef === 'note').header =
                    translation['GENERAL.NOTE'];
                this.gridColumns.find((column: any) => column.columnDef === 'note-person').header =
                    translation['GENERAL.PERSON'];
                this.gridColumns.find((column: any) => column.columnDef === 'note-employee').header =
                    translation['GENERAL.EMPLOYEE'];
            });
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // In der E-Liste wird eine Einrichtung angeklickt
        this.institutionsService.selectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: number) => {
                this.onSelectionChanged(result);
            });

        // In der Notizliste wird eine Notiz angeklickt
        this.gridService.eventGridSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht von der eigenen Komponente kam
                if (event.sender !== 'institutionsNotes') {
                    return;
                }
                this.onEventGridSelectionChanged(event);
            });

        // Wenn eine neue Notiz (über Toolbar) angelegt werden soll...
        this.toolbarService.eventAddItem.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls das Event nicht von der eigenen Komponente kam
            if (event.target !== 'institutions-notes') {
                return;
            }
            this.onEventAddItem();
        });

        // Wenn eine Notiz gelöscht werden soll
        this.gridService.eventGridRowDeleteClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht von der eigenen Komponente kam
                if (event.sender !== 'institutionsNotes') {
                    return;
                }
                this.onEventGridRowDeleteClicked(event);
            });

        // Es wurde auf "Mehr laden..." geklickt
        this.gridService.eventGridPageCounterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht von der eigenen Komponente kam
                if (event.target !== 'institutionsNotes') {
                    return;
                }
                this.onEventGridPageCounterChanged(result);
            });
    }

    /**
     * Auf geänderte Auswahl (Einrichtung) reagieren
     * @param id
     */
    onSelectionChanged(id: number): void {
        // Falls sich evtl. eine Notiz im editMode befindet, wird dies abgebrochen
        this.clickCancel();
        // Bereits vorhandene Daten (einer anderen Einrichtung) entfernen
        this.resetData();
        // ID der aktuellen Einrichtung merken
        this.institutionId = id;
        // Falls eine existente Einrichtung ausgewählt wurde
        if (this.institutionId > 0) {
            // ... Name der aktuellen Einrichtung merken
            this.institutionName = this.institutionsService.selectedInstitution.name1;
            // ... Daten laden
            this.loadData();
        }
    }

    /**
     * Auf Event "eventGridSelectionChanged" von "gridService" reagieren
     * @param event
     */
    onEventGridSelectionChanged(event: CWEvent): void {
        this.noteId = event.data['selectedRow']['id'];

        if (event.data['selectedRow']['institution_id'] === null) {
            this.loadDetails('person');
        } else if (event.data['selectedRow']['person_id'] === null) {
            this.loadDetails('institution');
        }
    }

    /**
     * Auf Toolbar-Klick reagieren (Notiz hizufügen)
     */
    onEventAddItem(): void {
        this.noteId = 0;
        this.loadDetails();
    }

    /**
     * Auf Toolbar-Klick reagieren (Notiz löschen)
     * @param event
     */
    onEventGridRowDeleteClicked(event: CWEvent): void {
        this.noteId = event.data['selectedRow']['id'];
        this.openDeleteDialog(event.data['selectedRow']);
    }

    /**
     * Es wurde auf "Mehr laden..." geklickt
     * @param event
     */
    onEventGridPageCounterChanged(event: CWEvent): void {
        this.gridPage = event.data['gridPageCounter'];
        this.loadData();
    }

    /**
     * Notiz löschen
     * @param result
     */
    deleteNote(result: LooseObject): void {
        // Wenn in Comfirm-Dialog Button "Ja" geklickt wurde
        if (result.answer == 'yes') {
            // Submit der Formular-Daten über PeopleDataService (keine Institution-ID angeben, erster Paramter entspricht eindeutiger Note-ID!)
            let serviceRequest$ = null;
            if (result['person_id']) {
                serviceRequest$ = this.peopleNotesService.deleteNoteData(this.noteId, {});
            } else {
                serviceRequest$ = this.institutionsNotesService.deleteNoteData(this.noteId, {});
            }

            serviceRequest$.subscribe((result: CWResult) => {
                // Daten zurücksetzen
                this.resetData();
                // Neu laden
                this.loadData();
            });
        }
    }

    /**
     * Alle Notizen der Einrichtung laden
     */
    loadData(): void {
        // Flag "loading" aktivieren
        this.loading = true;
        // Funktion "Mehr laden..." wird wieder deaktiviert
        this.loadMoreEnabled = false;

        // Daten laden
        const serviceRequest$ = this.institutionsNotesService.loadData(this.institutionId, this.gridPage);
        serviceRequest$.subscribe(
            (result: CWResult) => {
                // Falls Daten geladen wurden
                if (result.data['data'].length > 0) {
                    /**
                     * Prüfe, ob die Daten des eintreffenden Requests auch
                     * zur aktuell ausgewählten Einrichtung passen. Durch asynchrone
                     * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                     * bereits die Einrichtung gewechselt wurde und die Antwort
                     * eines Requests verspätet eintrifft und dadurch die
                     * korrekten Daten wieder überschreibt.
                     */
                    if (this.institutionsService.selectedInstitution.id != result.data['id']) {
                        return;
                    }
                    // Falls sich die vorhandenen Grid-Daten von den neu geladenen Daten unterscheiden...
                    if (JSON.stringify(this.data) != JSON.stringify(result.data['data'])) {
                        // Vorhandene Grid-Daten mit neu geladenen Daten erweitern
                        this.data = this.data.concat(result.data['data']);
                    }
                    // Funktion "Mehr laden..." wird wieder aktiviert
                    this.loadMoreEnabled = true;
                } else {
                    // Funktion "Mehr laden..." wird ausgeblendet, da es keine weiteren Daten mehr gibt
                    this.loadMoreVisible = false;
                }

                // Nachdem Daten geladen wurden befindet sich das Modul immer in der normalen Ansicht
                this.editMode = false;
                // Flag "loading" deaktivieren
                this.loading = false;
            },
            (error: any) => {
                // Nachdem Daten geladen wurden befindet sich das Modul immer in der normalen Ansicht
                this.editMode = false;
                // Flag "loading" deaktivieren
                this.loading = false;
                // Funktion "Mehr laden..." wird wieder aktiviert
                this.loadMoreEnabled = true;
            },
        );
    }

    /**
     * Einelne Notiz laden
     * @param noteType
     */
    loadDetails(noteType: string = null): void {
        this.editMode = true;

        let serviceRequest$ = null;
        if (noteType === 'person') {
            serviceRequest$ = this.peopleNotesService.loadDetails(this.noteId);
        } else {
            serviceRequest$ = this.institutionsNotesService.loadDetails(this.noteId);
        }

        serviceRequest$.subscribe((result: CWResult) => {
            this.dataItem = result.data;
        });
    }

    /**
     * Klick auf "Speichern" (Form-Submit)
     */
    clickSubmit(): void {
        // Nur gültige Formulare werden submitted
        if (this.institutionsNotesForm.form.valid) {
            // Flag "saving" aktivieren
            this.saving = true;

            // Submit der Formular-Daten über InstitutionsDataService (keine Institution-ID angeben, erster Parameter entspricht eindeutiger Note-ID!)
            let serviceRequest$ = null;
            if (this.dataItem['person_id']) {
                serviceRequest$ = this.peopleNotesService.saveData(this.noteId, this.institutionsNotesForm.form.value);
            } else {
                serviceRequest$ = this.institutionsNotesService.saveData(
                    this.noteId,
                    this.institutionsNotesForm.form.value,
                );
            }

            serviceRequest$.subscribe((result: CWResult) => {
                // EditMode verlassen
                this.editMode = false;
                // Flag "saving" deaktivieren
                this.saving = false;
                // Daten zurücksetzen
                this.resetData();
                // Neu laden
                this.loadData();
            });
        }
    }

    /**
     * Klick auf "Abbrechen"
     */
    clickCancel(): void {
        // EditMode verlassen
        this.editMode = false;
        // Daten zurücksetzen
        this.dataItem = new DataItem();
    }

    /**
     * Daten zurücksetzen (z.B. wenn Einrichtung gewechselt wird)
     */
    resetData(): void {
        this.loadMoreEnabled = false;
        this.loadMoreVisible = true;
        this.gridPage = 1;
        this.data = [];
        this.dataItem = new DataItem();
    }

    /**
     * Dialog öffnen
     * @param selectedRow
     */
    openDeleteDialog(selectedRow: any): void {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('GENERAL.DELETENOTE'),
                message: this.translateService.instant('GENERAL.DELETENOTEQUESTION'),
                institution_id: selectedRow['institution_id'],
                person_id: selectedRow['person_id'],
            },
        });

        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result) => {
            this.deleteNote(result);
        });
    }

    /*
     * @brief   Overlay-Info in Dialog anzeigen für Personen oder Einrichtungen
     *
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openOverlayInfoDialog(event: any, backendController: string, entityId: number): void {
        // Dialog öffnen
        let dialogRef = null;
        if (backendController === 'PeopleData') {
            dialogRef = this.overlayService.openOverlayInfoDialog(
                event.target,
                OverlayInfoPersonComponent,
                backendController,
                'details',
                entityId,
            );
        } else if (backendController === 'InstitutionsData') {
            dialogRef = this.overlayService.openOverlayInfoDialog(
                event.target,
                OverlayInfoInstitutionComponent,
                backendController,
                'details',
                entityId,
            );
        } else {
            console.error('backendController unknown');
            return;
        }

        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe();
    }
}
