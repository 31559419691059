// grid-new.module.ts
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GridNewComponent} from './grid-new.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MaterialModule} from '@shared/material/material.module';
import {CdkDrag, CdkDropList} from '@angular/cdk/drag-drop';
import {DirectivesModule} from '@shared/directives/directives.module';
import {TableVirtualScrollModule} from 'ng-table-virtual-scroll';
import {PipesModule} from '@shared/pipes/pipes.module';
import {MatChipsModule} from '@angular/material/chips';
import {RouterModule} from '@angular/router';
import {InputModule} from '@shared/input/input.module';
import {OverlayModule} from '@angular/cdk/overlay';

@NgModule({
    declarations: [GridNewComponent],
    imports: [
        CommonModule,
        MaterialModule,
        FontAwesomeModule,
        CdkDrag,
        CdkDropList,
        DirectivesModule,
        TableVirtualScrollModule,
        PipesModule,
        MatChipsModule,
        RouterModule,
        InputModule,
        OverlayModule,
        PipesModule,
    ],
    exports: [GridNewComponent], // Export the component so it can be used elsewhere
})
export class GridNewModule {}
