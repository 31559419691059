// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Massimo Feth <m.feth@pharmakon.software>
*/
:host .cw-load-more-container {
  justify-content: space-between;
}

:host .cw-link {
  padding-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid/grid-load-more/grid-load-more.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,8BAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Massimo Feth <m.feth@pharmakon.software>\n*/\n\n:host .cw-load-more-container {\n    justify-content: space-between;\n}\n\n:host .cw-link {\n    padding-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
