// Angular-Module
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service dieses Shared-Moduls
import {OverlayService} from './../overlay.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';

// Interface für Dialogdaten
export interface DialogData {
    backendController: string;
    backendMethod: string;
    entityId: number;
    additionalEntityId: number;
    top: number;
    left: number;
    linkHeight: number;
    additionalData: any;
    displayAdviceCheckbox?: boolean;
    adviceCheckboxChecked?: boolean;
}

/*
 * @brief   Abstrakte Komponente um Details einer Entität in einem
 *          Info-Popup anzuzeigen.
 *
 * @author  Sena Üner <s.uener@pharmakons.software>
 * @author  Tobias Hannemann <t.hannemann@pharmakons.software>
 */
@Component({template: ''})
export abstract class OverlayInfoComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Überschrift des Overlay's
    headerText = 'Information';
    // anzuzeigende Daten
    private _backendData: any;
    get backendData() {
        // Update Position abhängig von Inhalt
        this.setDialogPosition();

        return this._backendData;
    }

    set backendData(value: any) {
        this._backendData = value;
    }

    // Flag definiert ob gerade geladen wird
    loading = false;
    // Flag definiert ob Daten gefunden wurden
    success = false;
    // Flag definiert ob Avis-Checkbox angezeigt wird
    displayAdviceCheckbox = false;

    // Dialog
    dialogElement: any;
    dialogHeight = 0;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param dialogRef
     * @param data
     * @param overlayService
     */
    constructor(
        protected dialogRef: MatDialogRef<OverlayInfoComponent>,
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        protected overlayService: OverlayService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Referenz auf HTML Element setzen
        this.dialogElement = this.dialogRef['_containerInstance']['_elementRef'].nativeElement;

        // Daten aus aus Backend laden
        this.loadData();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Anderer Dialog dieser Art wurde geöffnet
        this.overlayService.eventOverlayInfoDialogOpened
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: CWEvent) => {
                // Abbruch, falls das Event nicht für den Dialog gedacht ist
                if (event.target !== 'overlayInfoDialog') {
                    return;
                }
                // offenen Dialog schließen
                this.clickClose();
            });

        // Anderer Dialog dieser Art wurde geöffnet
        this.overlayService.eventOverlayInfoDialogClose
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: CWEvent) => {
                // Abbruch, falls das Event nicht für den Dialog gedacht ist
                if (event.sender !== 'overlay-info') {
                    return;
                }
                // offenen Dialog schließen
                this.clickClose();
            });
    }

    /**
     * @brief   Dialog-Position aktualisieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    setDialogPosition(): void {
        // Element Boundaries
        const boundaries = this.dialogElement.getBoundingClientRect();

        // Prüfe ob Position angepasst werden muss
        if (boundaries.height !== this.dialogHeight) {
            // aktuelle Höhe zwischenspeichern
            this.dialogHeight = boundaries.height;

            // Höhe ermitteln
            const elementHeight = this.dialogElement.firstElementChild.firstElementChild.offsetHeight;
            const newTop = this.data.top - elementHeight / 2 + this.data.linkHeight / 2;
            const newLeft = this.data.left;

            // Position aktualisieren
            this.dialogRef.updatePosition({
                top: newTop + 'px',
                left: newLeft + 'px',
            });
        }
    }

    /**
     * @brief   Dialog schließen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickClose(): void {
        this.dialogRef.close();
    }

    /**
     * @brief   Daten laden
     * @author  Sena Üner <s.uener@pharmakon.software>
     */
    abstract loadData(): void;

    /**
     * @param event
     * @brief   Event auslösen, um Wert der Checkbox zu broadcasten
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    toggleAdviceCheckbox(event: MatCheckboxChange): void {
        // Checkbox setzen
        this.data.adviceCheckboxChecked = event.checked;

        // Daten übergeben
        const formData = this.data.additionalData;
        formData.checked = event.checked;

        // Event auslösen
        this.overlayService.checkboxToggled(formData);
    }
}
