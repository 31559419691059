// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
*/
:host {
  width: 100%;
}

:host .cw-input-label {
  align-self: initial;
}

:host .cw-input-span,
:host .cw-input-span pre {
  white-space: pre-line;
}

:host textarea {
  padding-bottom: 5px;
  padding-top: 5px;
}

textarea[required]:invalid {
  border-color: var(--color-accent);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/input/input-textarea/input-textarea.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,WAAA;AAAJ;;AAGA;EACI,mBAAA;AAAJ;;AAGA;;EAEI,qBAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,iCAAA;AAAJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n*/\n\n:host {\n    width: 100%;\n}\n\n:host .cw-input-label {\n    align-self: initial;\n}\n\n:host .cw-input-span,\n:host .cw-input-span pre {\n    white-space: pre-line;\n}\n\n:host textarea {\n    padding-bottom: 5px;\n    padding-top: 5px;\n}\n\ntextarea[required]:invalid {\n    border-color: var(--color-accent);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
