// Angular-Module
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Services
import {GridService} from '@shared/grid/grid.service';
import {SelectData} from '@shared/select-data';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
import {GlobalHelpService} from './../global-help.service';
import {GlobalHelpModulesService} from './global-help-modules.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
// Shared Components
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';

class ModuleDataItem {
    id: any;
}
@Component({
    selector: 'phscw-global-help-modules',
    templateUrl: './global-help-modules.component.html',
    styleUrls: ['./global-help-modules.component.scss'],
})
export class GlobalHelpModulesComponent implements OnInit, OnDestroy {
    // Formular
    @ViewChild('globalHelpModulesForm', {static: false}) globalHelpModulesForm: NgForm;

    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Spaltendeffinition für Grid
    gridColumns: any = [
        {
            columnDef: 'id',
            header: 'ID',
            cell: (element: any) => `${element.id}`,
        },
        {
            columnDef: 'label',
            header: 'Modul',
            cell: (element: any) => `${element.label}`,
            formatWidth: '100%',
        },
    ];

    // Anzuzeigende Spalten für Grid Permissions
    gridDisplayedColumns: any = ['label', 'delete'];

    // id des im Popup angezeigten Hilfseintrags
    helpentryId: number;
    // Module, zu denen der Hilfseintrag zugeordnet ist
    data: any[] = [];

    // ID der aktuell ausgewählten Rolle
    roleId: number;
    // Einzelne Rolle des Benutzers
    modulesDataItem: ModuleDataItem = {id: -1};
    // Modul-Daten (Rechte des Benutzers)
    selectModule: SelectData[] = [];

    // Edit-Mode
    @Input() editMode = false;
    // Flag definiert, ob gerade geladen wird
    loading = true;
    saving = true;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param globalHelpService
     * @param globalHelpModulesService
     * @param toolbarService
     * @param gridService
     * @param dialog
     * @param translateService
     */
    constructor(
        private globalHelpService: GlobalHelpService,
        private globalHelpModulesService: GlobalHelpModulesService,
        private toolbarService: ToolbarService,
        private gridService: GridService,
        private dialog: MatDialog,
        private translateService: TranslateService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        this.initializeEventSubscriptions();
        this.initializeData();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   EventSubscriptons
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    initializeEventSubscriptions(): void {
        // Auf änderung des Angezeigten Hilfeeintrags reagieren
        this.globalHelpService.eventHelpSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                if (result.data.id !== this.helpentryId || typeof this.helpentryId === 'undefined') {
                    // Liste leeren
                    this.data = [];
                    // Liste neu laden
                    this.loadData(result.data.id);
                }
            });

        // Auf klick des 'Add'-Buttons in der Toolbar hören
        this.toolbarService.eventAddItem.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            if (result.target !== 'global-help-modules') {
                return;
            }
            this.editMode = true;
        });

        //
        this.gridService.eventGridRowDeleteClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                if (result.sender !== 'globalHelpModules') {
                    return;
                }
                this.openDeleteDialog(result['data']['selectedRow']['id'], result['data']['selectedRow']['label']);
            });
    }

    /**
     * @brief   Modulnamen laden
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    initializeData(): void {
        const request$ = this.globalHelpModulesService.loadModuleNames();
        request$.subscribe((result: CWResult) => {
            this.selectModule = result['data'];
        });
    }

    /**
     * @param id
     * @brief   Modulliste neu laden
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    loadData(id: number): void {
        // Flag setzen
        this.loading = true;

        // ID des Eintrags zwischenspeichern
        this.helpentryId = id;

        // Module, mit denen der Eintrag verbunden ist, laden
        const request$ = this.globalHelpModulesService.loadModules(this.helpentryId);
        request$.subscribe(
            (result: CWResult) => {
                this.data = result['data'];
                this.editMode = false;
                this.loading = false;
            },
            (error) => {
                this.loading = false;
            },
        );
    }

    /**
     * @brief   Änderungen speichern
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    clickSubmit(): void {
        this.globalHelpModulesService.addModule(this.helpentryId, this.modulesDataItem['id']);
        this.loadData(this.helpentryId);
    }

    /**
     * @brief   Editmode beenden
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    clickCancel(): void {
        this.editMode = false;
    }

    /**
     * @param moduleId
     * @param moduleName
     * @brief       Dialog zum Löschen öffnen
     * @author      Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openDeleteDialog(moduleId: number, moduleName: string): void {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('GLOBAL.HELP.MODULES.DELETEMODULE'),
                message: this.translateService.instant('GLOBAL.HELP.MODULES.DELETEMODULEQUESTION', {name: moduleName}),
                id: moduleId,
            },
        });

        // auf Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result) => this.deleteModule(result.answer, result.id));
    }

    /**
     * @param answer
     * @param id
     * @brief   Dialog zum Löschen öffnen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    deleteModule(answer: string, id: number): void {
        // Wenn in Confirm-Dialog Button "Ja" geklickt wurde
        if (answer === 'yes') {
            // Submit der Formular-Daten über globalHelpTextService
            const serviceRequest$ = this.globalHelpModulesService.removeModule(this.helpentryId, id);
            serviceRequest$.subscribe(
                (result) => {
                    // Neu laden
                    this.loadData(this.helpentryId);
                },
                (error) => {},
            );
        }
    }
}
