// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// GridComponent
import {GridComponent} from './../../grid/grid.component';
import {GridSelectionSavePopupComponent} from './../grid-selection-save-popup/grid-selection-save-popup.component';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {SelectionFilter} from '@shared/selection-filter';
import {SelectionOperator} from '@shared/selection-operator';
// Eigenen Service einbinden
import {GridService} from './../../grid.service';
import {GridSelectionService} from './../grid-selection.service';
// Shared Components
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Globale Services einbinden
import {AppCoreService} from '@global/services/app-core.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';

@Component({
    selector: 'phscw-grid-selection-panel',
    templateUrl: './grid-selection-panel.component.html',
    styleUrls: ['./grid-selection-panel.component.scss'],
})
export class GridSelectionPanelComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    /**
     * *************************************************************************
     * Parameter, welche beim Einbinden der Komponente gesetzt werden
     *************************************************************************
     */
    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;
    // ID des (verbundenen) Grids
    @Input() gridId = '';
    // Ist diese Grid-Extension sichtbar?
    @Input() visible = false;
    // Für Filterung benötigte Daten
    @Input() frontendArray: any[] = [];
    @Input() transferArray: any[] = [];

    oldList = '';
    /*
     * Ursprünglich dafür gedacht den Selektion Ausführen, und Selektion speichern Button zu deaktivieren,
     * falls sich die Spalten nicht geändert haben. Wird nun jedoch nur noch während des Speicherns ausgegraut
     */
    allowSave = true;
    // Darf die Selektion gelöscht werden
    allowDeletion = false;

    // Informationen für den SpeichernButton, ob gerade gespeichert wird
    buttonSavingInProgress = false;
    // Information für den SpeichernButton, ob das Speichern erfolgreich war
    buttonSavedSuccessful = false;

    // Darf die Selektion beim Speichern für andere Rollen freigegeben werden.
    allowClearanceSelections = false;
    // Welche Rollen gibt es für die Zusätzlich Daten gespeichert werden können
    roles: any[] = [];

    // Konstruktor
    constructor(
        private gridSelectionService: GridSelectionService,
        private appCore: AppCoreService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private userPermissionsService: UserPermissionsService,
        private gridService: GridService,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
        // Prüfe Anforderungen
        this.checkRequirements();
        // Prüfe ob die Selektion mehreren Rollen zugeordnet werden darf
        this.checkAllowClearanceSelections();
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Wenn Filter erstellt wird
        this._subscriptions.add(
            this.gridSelectionService.eventFilterCreated.subscribe((result) => {
                if (
                    this.gridId.toLowerCase().includes(result['sender']) ||
                    (this.gridId == 'peopleList' && result['sender'] == 'b2c')
                ) {
                    this.onEventFilterCreated(result);
                }
            }),
        );

        // Wenn Filter geändert wird
        this._subscriptions.add(
            this.gridSelectionService.eventFilterEdited.subscribe((result) => {
                if (
                    this.gridId.toLowerCase().includes(result['sender']) ||
                    (this.gridId == 'peopleList' && result['sender'] == 'b2c')
                ) {
                    this.onEventFilterEdited();
                }
            }),
        );

        // Wenn Filter gelöscht wurde
        this._subscriptions.add(
            this.gridSelectionService.eventFilterDeleted.subscribe((result) => {
                if (
                    this.gridId.toLowerCase().includes(result['sender']) ||
                    (this.gridId == 'peopleList' && result['sender'] == 'b2c')
                ) {
                    this.onEventFilterDeleted(result);
                }
            }),
        );

        // Wenn Operator geändert wird
        this._subscriptions.add(
            this.gridSelectionService.eventOperatorChanged.subscribe((result) => {
                if (
                    this.gridId.toLowerCase().includes(result['sender']) ||
                    (this.gridId == 'peopleList' && result['sender'] == 'b2c')
                ) {
                    this.onEventOperatorChanged(result);
                }
            }),
        );

        this._subscriptions.add(
            this.gridSelectionService.eventOpenSelection.subscribe((result) => {
                this.frontendArray = result['data']['frontend_array'];
                this.allowDeletion = result['data']['allow_deletion'];
                this.setTransferArray();
                this.gridConnection.gridSelection = this.transferArray;
            }),
        );

        this._subscriptions.add(
            this.gridSelectionService.eventResetSelection.subscribe((result) => {
                this.frontendArray = [];
                this.transferArray = [];
                this.gridConnection.gridSelection = [];
            }),
        );

        // Auf Änderung im globalen Menü reagieren
        this._subscriptions.add(
            this.appCore.globalMenuChanged.subscribe((result) => {
                // Speichere den Filter in der aktuellen Liste ab wenn neue Liste gewählt wird
                if (this.frontendArray.length > 0) {
                    this.gridSelectionService.saveData({
                        oldList: JSON.parse(JSON.stringify(this.oldList)),
                        frontend: JSON.parse(JSON.stringify(this.frontendArray)),
                        transfer: JSON.parse(JSON.stringify(this.transferArray)),
                    });

                    this.oldList = '';
                    this.frontendArray.length = 0;
                    this.transferArray.length = 0;
                }
                this.oldList = result['target'];
            }),
        );

        // Auf das Event zum start des Speichern der Selektion reagieren
        this._subscriptions.add(
            this.gridSelectionService.eventSelectionSaveStarted.subscribe((result) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid (E-Liste) kam
                if (event.target != this.gridId) {
                    return;
                }
                this.buttonSavingInProgress = true;
            }),
        );

        // Auf das Event, dass Erfolg oder Fehlschlag des Speicherns mitgibt hören
        this._subscriptions.add(
            this.gridSelectionService.eventSelectionSaved.subscribe((result) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid (E-Liste) kam
                if (event.target != this.gridId) {
                    return;
                }
                this.buttonSavingInProgress = false;
                // Abfrage, ob das Speichern erfolgreich war
                if (event.data === true) {
                    this.allowSave = true;
                    this.buttonSavedSuccessful = true;
                    // Ausblenden nach kurzer Zeit
                    setTimeout(() => (this.buttonSavedSuccessful = false), 2000);
                } else {
                    this.allowSave = true;
                    // Bei Fehlschlag Fehlerdialog öffnen
                    this.openFailedSaveDialog();
                }
            }),
        );
    }

    // Prüfe Anforderungen
    checkRequirements() {
        // Diese Grid-Erweiterung benötigt ein verbundenes Grid
        if (typeof this.gridConnection === 'undefined') {
            console.error(
                'Pharmakon - GridFilterComponent verfügt über kein verbundenes Grid und kann deshalb nicht funktionieren. Bitte beim Einbinden der Komponente die [gridConnection] setzen!',
            );
        }
    }

    // Reaktion auf erstellten Filter
    onEventFilterCreated(event: CWEvent) {
        this.setOperator();
        // Filter zum Filter-Array hinzufügen
        this.frontendArray.push(event.data);

        this.setTransferArray();
    }

    // Operator hinzufügen, falls mehr als ein Filter aktiv ist
    setOperator() {
        if (this.frontendArray.length >= 1) {
            this.frontendArray.push(new SelectionOperator());
        }
    }

    // Array für die Übergabe ans Backend erstellen
    setTransferArray() {
        const tempArray = [];
        this.transferArray.length = 0;

        this.checkNegations();

        // Wenn nur ein Filter aktiv ist, speichere diesen im tempArray
        if (this.frontendArray.length === 1) {
            tempArray.push(this.checkFilter(this.frontendArray[0]));
        }

        /*
         * Da 'AND' stärker bindet als 'OR' suche alle Filter die durch 'AND' verbunden sind
         * und speichere sie als Kinder eines 'AND'-Operators ab.
         */
        for (let i = 0; i < this.frontendArray.length; i++) {
            if (this.frontendArray[i]['type'] === 'AND') {
                const operator = new SelectionOperator();
                operator['type'] = 'AND';
                operator['children'].push(this.checkFilter(this.frontendArray[i - 1]));
                while (i + 2 < this.frontendArray.length && this.frontendArray[i + 2]['type'] === 'AND') {
                    operator['children'].push(this.checkFilter(this.frontendArray[i + 1]));
                    i += 2;
                }
                operator['children'].push(this.checkFilter(this.frontendArray[i + 1]));
                tempArray.push(operator);
            }
        }

        // Sonderfälle: Wenn erster oder letzter Filter mit 'OR' verbunden, speichere ihn im tempArray
        if (this.frontendArray.length > 1 && this.frontendArray[1]['type'] === 'OR') {
            tempArray.push(this.checkFilter(this.frontendArray[0]));
        }
        if (this.frontendArray.length > 1 && this.frontendArray[this.frontendArray.length - 2]['type'] === 'OR') {
            tempArray.push(this.checkFilter(this.frontendArray[this.frontendArray.length - 1]));
        }

        // Suche nach Filtern die nur durch 'OR' verbunden sind und speichere diese im tempArray
        for (let i = 0; i + 2 < this.frontendArray.length; i++) {
            if (this.frontendArray[i]['type'] === 'OR' && this.frontendArray[i + 2]['type'] === 'OR') {
                tempArray.push(this.checkFilter(this.frontendArray[i + 1]));
            }
        }

        // Verbinde alle Einträge des tempArrays mit einem 'OR' und speichere sie im transferArray
        if (tempArray.length > 1) {
            const orOperator = new SelectionOperator();
            orOperator['type'] = 'OR';
            for (const entry of tempArray) {
                orOperator['children'].push(entry);
            }
            this.transferArray.push(orOperator);
        } else {
            this.transferArray.push(tempArray[0]);
        }
    }

    // Prüfe, ob ein AND/ OR NOT verwendet wird und setze gegebenenfalls das negation-Flag
    checkNegations() {
        for (let i = 0; i < this.frontendArray.length; i++) {
            this.frontendArray[0]['negation'] = false;
            if (
                typeof this.frontendArray[i]['frontendName'] !== 'undefined' &&
                this.frontendArray[i]['frontendName'].includes('UND NICHT')
            ) {
                this.frontendArray[i + 1]['negation'] = true;
                this.frontendArray[i]['type'] = 'AND';
            } else if (
                typeof this.frontendArray[i]['frontendName'] !== 'undefined' &&
                this.frontendArray[i]['frontendName'].includes('ODER NICHT')
            ) {
                this.frontendArray[i + 1]['negation'] = true;
                this.frontendArray[i]['type'] = 'ODER';
            } else if (
                typeof this.frontendArray[i]['frontendName'] !== 'undefined' &&
                !this.frontendArray[i]['frontendName'].includes('NICHT')
            ) {
                this.frontendArray[i + 1]['negation'] = false;
            }
        }
    }

    // Prüfe, ob der Filter angepasst werden muss, bevor er ans Backend geschickt wird.
    checkFilter(filter: SelectionFilter) {
        /*
         * Bei Filterung auf bestimmtes Datum ändere den Vergleich fürs Backend
         * auf "between", um das erwartete Ergebnis zu erhalten.
         */
        if (filter['datatype'] === 'date' && filter['comparison'] === 'equals') {
            const newFilter = JSON.parse(JSON.stringify(filter));
            newFilter['comparison'] = 'between';
            return newFilter;
        }

        /*
         * Prüfe, ob per multiselect mehrere Werte gewählt wurden. Fasse diese Werte gegebenenfalls
         * als einzelne Filter unter einem ODER zusammen und gib diesen Operator zurück.
         */
        if (filter['multiOptions'].length === 0) {
            return filter;
        } if (filter['multiOptions'].length === 1) {
            // eslint-disable-next-line @stylistic/max-statements-per-line, no-multi-assign
            filter['characteristic_option_id'] = filter['value'] = String(filter['multiOptions'][0]);
            return filter;
        }
        const newOperator = new SelectionOperator();
        let negation = filter['negation'] || false;
        /*
         * Wenn es sich um einen ausschließenden Vergleich handelt sollen
         * muss der Verbindende Operator umgedreht werden.
         */
        if (filter['comparison'].includes('NOT')) {
            negation = !negation;
        }
        if (negation) {
            newOperator['type'] = 'AND';
        } else {
            newOperator['type'] = 'OR';
        }
        for (let j = 0; j < filter['multiOptions'].length; j++) {
            let newFilter = new SelectionFilter();
            newFilter = filter;
            // eslint-disable-next-line @stylistic/max-statements-per-line, no-multi-assign
            newFilter['characteristic_option_id'] = newFilter['value'] = String(filter['multiOptions'][j]);
            newOperator['children'].push(JSON.parse(JSON.stringify(newFilter)));
        }
        return newOperator;
    }

    // Reaktion auf geänderten Filter
    onEventFilterEdited() {
        this.setTransferArray();
    }

    // Reaktion auf geänderten Operator
    onEventOperatorChanged(event: CWEvent) {
        switch (event['data']['frontendName']) {
            case 'UND': {
                event['data']['type'] = 'OR';
                event['data']['frontendName'] = 'ODER';
                break;
            }
            case 'ODER': {
                event['data']['type'] = 'AND NOT';
                event['data']['frontendName'] = 'UND NICHT';
                break;
            }
            case 'UND NICHT': {
                event['data']['type'] = 'OR NOT';
                event['data']['frontendName'] = 'ODER NICHT';
                break;
            }
            case 'ODER NICHT': {
                event['data']['type'] = 'AND';
                event['data']['frontendName'] = 'UND';
                break;
            }
            default:
                break;
        }

        this.setTransferArray();
    }

    // Reaktion auf gelöschten Filter
    onEventFilterDeleted(event: CWEvent) {
        const indexFilter = this.frontendArray.indexOf(event['data']);
        const indexOperatorBefore = indexFilter - 1;
        const indexOperatorAfter = indexFilter + 1;

        // Sonderfälle: Wenn erster oder letzter Filter gelöscht wird, kann Operator einfach mitgelöscht werden
        if (indexFilter === 0) {
            this.frontendArray.splice(0, 2);
        } else if (indexFilter === this.frontendArray.length - 1) {
            this.frontendArray.splice(this.frontendArray.length - 2, 2);
            // Falls zu löschender Filter zwei gleiche Operatoren verbindet, lösche den linken Operator
        } else if (this.frontendArray[indexOperatorBefore]['type'] === this.frontendArray[indexOperatorAfter]['type']) {
            this.frontendArray.splice(indexOperatorBefore, 2);
            // Falls zu löschender Filter unterschiedliche Operatoren verbindet, lösche den 'AND'-Operator
        } else if (this.frontendArray[indexOperatorBefore]['type'] === 'OR') {
            this.frontendArray.splice(indexFilter, 2);
        } else if (this.frontendArray[indexOperatorAfter]['type'] === 'OR') {
            this.frontendArray.splice(indexOperatorBefore, 2);
        }

        this.setTransferArray();
    }

    startSelection() {
        // Buttons für den Verlauf des Speicherns deaktivieren
        this.allowSave = false;
        // Beim Ausführen der Selektion den Löschbutton ausblenden.
        this.allowDeletion = false;
        this.gridConnection.gridSelection = this.transferArray;

        this.gridSelectionService.startSelection(this.gridConnection.gridId);

        const saved = this.gridSelectionService.saveSelection({
            title: 'lastSelection',
            refresh: false,
            frontend_array: this.frontendArray,
            transfer_array: this.transferArray,
            grid_id: this.gridId,
        });
        saved.subscribe((result: any) => {
            // Wenn die Selektion erfolgreich gespeichert wurde, speichere Sie in das Storage
            if (result['success'] && typeof result['data'][0] !== 'undefined') {
                this.gridSelectionService.onSuccessfulSelectionSave(result['data'][0]);
                this.allowSave = true;
            } else {
                this.allowSave = true;
                // @todo Fehlerbehandlung
            }
        });
    }

    openSaveSelectionPopup() {
        const dialogConfig = new MatDialogConfig();

        // Daten des gewählten Kriteriums dem Dialog übergeben
        dialogConfig.data = {
            frontendArray: this.frontendArray,
            transferArray: this.transferArray,
            gridId: this.gridId,
            allowClearanceSelections: this.allowClearanceSelections,
            roles: this.roles,
        };

        // Dialog öffnen
        this.dialog.open(GridSelectionSavePopupComponent, dialogConfig);
    }

    // Öffenen eines Message-Dialogs, Falls das Speichern fehlgeschlagen ist
    openFailedSaveDialog() {
        // Dialog konfigurieren und öffnen
        this.dialog.open(PopupMessageComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.SAVEFILTER'),
                message: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.UNSUCCESFULSAVE'),
            },
        });
    }

    // Berechtigung "allowClearanceSelections" prüfen
    checkAllowClearanceSelections() {
        const permissionAllowClearanceSelections: boolean =
            this.userPermissionsService.getPermissionValue('allowClearanceSelections');
        this.allowClearanceSelections = permissionAllowClearanceSelections;

        // Falls die Berechtigung gesetzt ist außerdem die Rollen aus dem Backend laden.
        if (this.allowClearanceSelections) {
            const $roleRequest = this.gridSelectionService.getRoles();
            $roleRequest.subscribe((result: any) => {
                if (result['success'] && result['data'] !== null) {
                    this.roles = result['data'];
                }
            });
        } else {
            this.roles = [];
        }
    }

    // Auf das Klicken des Löschbuttons eines Elements reagieren
    clickDelete() {
        this.gridService.gridSelectionDeleted('gridSelectionComponent', this.gridId);
    }
}
