// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.cw-label {
  margin: auto 5px;
}

:host mat-checkbox {
  align-self: center;
}

/* Styles für ein spezifiziertes Icon als Checkmark */
:host {
  /* Standard-Wert als Fallback */
  --custom-content: "";
}

:host ::ng-deep .cw-custom-checkmark.mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background:before {
  content: var(--custom-content); /* Variable wird in Component überschrieben mit dem entsprechenden Zeichen */
  font-size: 12px;
  font: 700 1.2rem/1.6rem Roboto, "sans-serif";
  line-height: 16px;
  position: absolute;
  text-align: center;
}

:host ::ng-deep .cw-custom-checkmark.mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background svg {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/input/input-single-checkbox/input-single-checkbox.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,gBAAA;AAEJ;;AACA;EACI,kBAAA;AAEJ;;AACA,qDAAA;AACA;EACI,+BAAA;EACA,oBAAA;AAEJ;;AAEA;EACI,8BAAA,EAAA,4EAAA;EACA,eAAA;EACA,4CACI;EAEJ,iBAAA;EACA,kBAAA;EACA,kBAAA;AADJ;;AAKA;EACI,aAAA;AAFJ","sourcesContent":[".cw-label {\n    margin: auto 5px;\n}\n\n:host mat-checkbox {\n    align-self: center;\n}\n\n/* Styles für ein spezifiziertes Icon als Checkmark */\n:host {\n    /* Standard-Wert als Fallback */\n    --custom-content: '';\n}\n\n// Setzt den Inhalt des custom Checkmarks\n:host ::ng-deep .cw-custom-checkmark.mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background:before {\n    content: var(--custom-content); /* Variable wird in Component überschrieben mit dem entsprechenden Zeichen */\n    font-size: 12px;\n    font:\n        700 1.2rem / 1.6rem Roboto,\n        'sans-serif';\n    line-height: 16px;\n    position: absolute;\n    text-align: center;\n}\n\n// Entfernt das default Checkmark-Icon, wenn ein custom Checkmark gesetzt ist\n:host ::ng-deep .cw-custom-checkmark.mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background svg {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
