/**
 * @brief   Shared InputModule zur Freigabe der Splitter-Components.
 * @details Um die Splitter-Components in Feature Modulen verwenden zu können,
 *          wird dieses Shared Module eingebunden.
 *          Die Einbindung erfolgt im Feature Module (z.B. people.module.ts)
 *          und nicht in den einzelnen Komponenten des Feature Moduls.
 *
 *          // Shared Modules einbinden
 *          import {SplitterModule} from '../../shared/splitter/splitter.module';
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
// Angular-Split
import {AngularSplitModule} from 'angular-split';
// Komponenten
import {SplitterComponent} from './splitter/splitter.component';

@NgModule({
    // Importierte Module
    imports: [CommonModule, AngularSplitModule],
    // Komponenten dieses Shared Modules
    declarations: [SplitterComponent],
    // Komponenten, die dieses Shared Module freigeben soll
    exports: [SplitterComponent],
})
export class SplitterModule {}
