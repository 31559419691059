// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class InstitutionsSalesService {
    // Konstruktor (inkl. dependency injection)
    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
    ) {}

    /**
     * @param id
     * @param regionsFilter
     * @brief   Umsatzdaten einer Einrichtugn laden
     * @returns  Observable<any>
     */
    loadData(id: number, regionsFilter: object): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('InstitutionsSales/details/', {
            orderErpNumber: id,
            regionsFilter,
        });
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
