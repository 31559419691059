/*
 *Sammlung von Helper-Methods, die an vielen Stellen benutzt werden können
 */

/**
 *
 * @param value
 * @param index
 * @param self
 */
export function distinctValues(value, index, self) {
    return self.indexOf(value) === index;
}

/**
 *
 * @param item
 */
export function notEmpty(item) {
    return typeof item !== 'undefined' && item !== null && item !== '';
}

/**
 *
 * @param item
 */
export function notEmptyObject(item) {
    return typeof item !== 'undefined' && item !== null;
}

/**
 * @brief Helper für die bequemere Nutzung des neuen hasOwnProperty Patterns, da obj.hasOwnProperty(prop) unerwünscht ist
 * @param obj Objekt, das geprüft werden soll
 * @param prop Name der Property, nach der im Objekt gesucht wird
 * @returns boolean true, wenn das Objekt die benannte Eigenschaft hat
 */
export function hasOwn(obj, prop): boolean {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}

export const toSum = (a, b) => a + b;

/**
 *
 * @param start
 * @param end
 */
export function* rangeGenerator(start: number, end: number) {
    yield start;
    if (start === end) return;
    yield * rangeGenerator(start + 1, end);
}

/**
 *
 * @param start
 * @param end
 */
export function numberRange(start: number, end: number): number[] {
    return [...rangeGenerator(start, end)];
}
/**
 *
 * @param str
 */
export function extractFirstWord(str) {
    const match = str.match(/^\/?(\w+)/);
    return match ? match[1] : null;
}
