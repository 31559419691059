/* eslint-disable no-console */
// single-icon-renderer.component.ts

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CwIcon, IconType} from '@shared/cw-icon';

/**
 * @description
 * Komponente zur Darstellung von Icons basierend auf dem Icon-Typ
 * @see IconType
 */
@Component({
    selector: 'phscw-single-icon-renderer',
    templateUrl: './single-icon-renderer.component.html',
    styleUrls: ['./single-icon-renderer.component.scss'],
})
export class SingleIconRendererComponent {
    @Input({required: true}) icon: CwIcon;

    /**
     * Der Tooltip-Text, der beim Hover über das icon angezeigt wird.
     */
    @Input() tooltip: string;

    /**
     * Zusätzliche CSS-Klassen für das icon
     */
    @Input() iconClass: string = '';

    /**
     * Gibt an, ob das Element erweitert ist oder nicht
     */
    @Input() isExpanded: boolean;

    /**
     * Bedingung, die angibt, ob das Expandieren-Icon angezeigt werden soll
     */
    @Input() isExpandable: boolean;

    /**
     * Event-Emitter, der ausgelöst wird, wenn das Icon angeklickt wird.
     */
    @Output() iconClicked: EventEmitter<Event> = new EventEmitter<Event>();

    /**
     * Event-Emitter, der ausgelöst wird, wenn das Expandieren-Icon angeklickt wird.
     */
    @Output() expandToggled: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Für die Verwendung im Template
     */
    readonly IconType = IconType;

    /**
     * Der Standardpfad zu den SVG-Icons
     */
    defaultAssetsIconPath: string = 'assets/img/icons/';

    /**
     * Konstruktiert den Pfad zum SVG-Icon
     * Wenn ein eigener Pfad mitgegeben wird, wird dieser benutzt
     * @returns {string} Pfad zum SVG-Icon
     */
    get iconPath(): string {
        try {
            if (!this.icon) {
                console.error('Icon-Input fehlt', this.icon);
            }

            if (!this.icon.iconName) {
                console.error('Icon-Eigenschaft "iconName" fehlt', this.icon);
            }

            if (this.icon.iconPath) {
                return `${this.icon.iconPath}${this.icon.iconName}.svg`;
            }

            return `${this.defaultAssetsIconPath}${this.icon.iconName}.svg`;
        } catch (error) {
            console.error('Fehler beim Erstellen des Icon-Pfads:', error);
            // Fallback auf default icon
            return `${this.defaultAssetsIconPath}notdef.svg`;
        }
    }

    /**
     * Behandelt den Klick auf das Icon
     * @param {Event} event Das Klick-Event
     */
    handleClick(event: Event): void {
        event.stopPropagation();
        this.iconClicked.emit();
    }

    /**
     * Behandelt den Klick auf das Expandieren-Icon
     * @param {Event} event Das Klick-Event
     */
    toggleExpand(event: Event): void {
        this.isExpanded = !this.isExpanded;
        event.stopPropagation();
        this.expandToggled.emit();
    }
}
