import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {TagData} from '@shared/tag-data';

/*
 * Diese Direktive sollte auf das <mat-chip> Element eines Tags angewendet werden, um die Hintergrundfarbe und Textfarbe zu setzen
 * Beispiel: <mat-chip phscwTagTheme [listKey]="listKey" [listName]="listName">
 *
 * Die Farben werden anhand der list_data des listentry gesetzt:
 * list_data: { 'backgroundColor': '#12345', 'textColor': '#54321' }
 *
 * Die Textfarbe sollte eine verdunkelte Version der Hintergrundfarbe sein. (70% dunkler)
 * @see https://mdigi.tools/darken-color
 */
@Directive({selector: '[phscwTagTheme]'})
export class TagThemeDirective implements OnInit {
    @Input() listName: string | undefined;
    @Input() listKey: string | undefined;
    @Input() listData: TagData | undefined;

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        // Defaults
        const defaultBackgroundColor = '#efefef';
        const defaultTextColor = '#000';
        const el = this.el.nativeElement;

        // Hintergrundfarbe und Textfarbe des Tags (<mat-chip>) anhand der list_data setzen
        const backgroundColor = this.listData?.backgroundColor || defaultBackgroundColor;
        const textColor = this.listData?.textColor || defaultTextColor;

        // Ermittelte Farben setzen
        el.style.backgroundColor = backgroundColor;
        el.style.outline = `1px solid ${textColor}`;
        el.style.setProperty('--mdc-chip-label-text-color', textColor);
    }
}
