// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Globale Services
import {UserPermissionsService} from '@global/services/user-permissions.service';
// Interfaces für Structured Objects einbinden
import {SupplementaryNumber} from '@shared/supplementary-number';

@Component({
    selector: 'phscw-institutions-data-supplementary-numbers',
    templateUrl: './institutions-data-supplementary-numbers.component.html',
    styleUrls: ['./institutions-data-supplementary-numbers.component.scss'],
    // Um Daten an übergeordnete Form übergeben zu können (siehe https://stackblitz.com/edit/angular-ua5xcz?file=app/address.component.ts)
    viewProviders: [{
        provide: ControlContainer,
        useExisting: NgForm,
    }],
})
export class InstitutionsDataSupplementaryNumbersComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID der Einrichtung
    @Input() institutionId = 0;

    // EditMode aktiv?
    @Input() editMode = false;
    // ID des Inputfelds
    @Input() inputId: string;
    // Name der ngModelGroup
    @Input() formModelGroup: string;
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string = null;
    // In Ansichtsmodus verstecken, wenn leer?
    @Input() hideEmpty = true;

    // bereits vorhandene Daten
    @Input() data: SupplementaryNumber[] = [];

    // Flags definieren, welche Aktionen ausgeführt werden dürfen
    allowNewNumber = false;
    allowEditNumber = false;
    allowDeleteNumber = false;
    allowEditNumberValue = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param userPermissionsService
     */
    constructor(private userPermissionsService: UserPermissionsService) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        this.checkPermissions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * leere Nummer initialisieren
     * @todo    Nummerntyp auswählbar oder konfigurierbar definieren
     * @todo    Methode verallgemeinern, falls in anderen Komponenten benötigt
     */
    addElement(): void {
        // Objekt erzeugt
        const newNumber: SupplementaryNumber = {
            entity_id: this.institutionId,
            entity_type: 'institutions',
            number_type: 'erp',
            supplementary_number: '',
        };

        // Objekt an Daten anhängen
        this.data.push(newNumber);
    }

    /**
     * ausgewählte Nummer entfernen
     * @param index
     * @todo    Methode verallgemeinern, falls in anderen Komponenten benötigt
     */
    removeElement(index: number): void {
        // Fehlwerte abfangen
        if (index < this.data.length && index >= 0) {
            // Gelöscht Flag setzen, damit beim Speichern die Daten korrekt überschrieben werden
            this.data[index].deleted = 'Y';

            // Eintrag löschen, wenn keine Daten hinterlegt sind
            if (this.data[index].supplementary_number.length === 0) {
                this.data.splice(index, 1);
            }
        }
    }

    /**
     * Berechtigungen prüfen
     */
    private checkPermissions(): void {
        const permissionAllowNewInstitutionSupplementaryNumber: boolean =
            this.userPermissionsService.getPermissionValue('allowNewInstitutionSupplementaryNumber');
        this.allowNewNumber = permissionAllowNewInstitutionSupplementaryNumber;

        const permissionAllowEditInstitutionSupplementaryNumber: boolean =
            this.userPermissionsService.getPermissionValue('allowEditInstitutionSupplementaryNumber');
        this.allowEditNumber = permissionAllowEditInstitutionSupplementaryNumber;

        const permissionAllowEditInstitutionSupplementaryNumberValue: boolean =
            this.userPermissionsService.getPermissionValue('allowDeleteInstitutionSupplementaryNumber');
        this.allowDeleteNumber = permissionAllowEditInstitutionSupplementaryNumberValue;

        const permissionAllowDeleteInstitutionSupplementaryNumber: boolean =
            this.userPermissionsService.getPermissionValue('allowEditInstitutionSupplementaryNumberValue');
        this.allowEditNumberValue = permissionAllowDeleteInstitutionSupplementaryNumber;
    }
}
