// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Julian Roller <j.roller@pharmakon.software>
*/
:host .cw-flex-row {
  flex-wrap: wrap-reverse;
}

:host .cw-flex-row * {
  flex: 1;
  height: 44px;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-help/global-help-nav/global-help-nav.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,uBAAA;AAAJ;;AAGA;EACI,OAAA;EACA,YAAA;AAAJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Julian Roller <j.roller@pharmakon.software>\n*/\n\n:host .cw-flex-row {\n    flex-wrap: wrap-reverse;\n}\n\n:host .cw-flex-row * {\n    flex: 1;\n    height: 44px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
