// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#additional-data ::ng-deep .cw-grid-container {
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/institutions/institutions-additional-data/institutions-additional-data.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ","sourcesContent":["#additional-data ::ng-deep .cw-grid-container {\n    overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
