// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class GlobalSettingsChangepasswordService {
    constructor(private backendService: BackendService) {}

    /**
     * @param formData
     * @brief   Speichert das neue Passwort
     * @returns  Observable<any>
     */
    saveNewPassword(formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('users/changePassword', formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Holt die Regeln zum Ändern eines Passworts
     */
    getValidationRules() {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('users/getValidationRules');
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }
}
