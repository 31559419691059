// Angular-Module
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
import {StorageService} from '@global/services/storage.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {SelectionFilter} from '@shared/selection-filter';
import {SelectionOperator} from '@shared/selection-operator';
import {extractFirstWord} from '@shared/utils';

@Injectable({providedIn: 'root'})
export class GridSelectionService {
    public eventFilterCreated = new Subject<CWEvent>();
    public eventFilterEdited = new Subject<CWEvent>();
    public eventOperatorChanged = new Subject<CWEvent>();
    public eventFilterDeleted = new Subject<CWEvent>();
    public eventReopenPopup = new Subject<CWEvent>();
    public eventSaveData = new Subject<CWEvent>();
    public eventSaveSelection = new Subject<CWEvent>();
    public eventOpenSelection = new Subject<CWEvent>();
    public eventResetSelection = new Subject<CWEvent>();
    public eventReloadSavedSelections = new Subject<CWEvent>();
    public eventStartSelection = new Subject<CWEvent>();
    public eventSelectionSaveStarted = new Subject<CWEvent>();
    public eventSelectionSaved = new Subject<CWEvent>();

    // Objekt, dass für das Speichern von Selektionen in den Storage benötigt wird.
    private newObject: any = {};
    // Variable für den Key der aktuell gewählten Liste
    private selectionListKey = '';
    // Variable für die ausgewählte Liste
    private selectionList = '';

    /**
     * Konstruktor (inkl. dependency injection)
     * @param backendService
     * @param storageService
     * @param router
     */
    constructor(
        private backendService: BackendService,
        private storageService: StorageService,
        private router: Router,
    ) {}

    /**
     * @param createdFilter
     * @brief       Event auslösen um Daten des Filters weiterzugeben
     * @author      Dominik Treutle <d.treutle@pharmakon.software>
     */
    filterCreated(createdFilter: SelectionFilter): void {
        // Target ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);

        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target: 'filter',
            data: createdFilter,
        };

        // Event auslösen
        this.eventFilterCreated.next(eventData);
    }

    /**
     * @brief       Event auslösen wenn Filter geändert wurde
     * @author      Dominik Treutle <d.treutle@pharmakon.software>
     */
    filterEdited(): void {
        // Target ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);

        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target: 'filter',
        };
        // Event auslösen
        this.eventFilterEdited.next(eventData);
    }

    /**
     * @param changedOperator
     * @brief       Event auslösen wenn ein Operator geändert wurde
     * @author      Dominik Treutle <d.treutle@pharmakon.software>
     */
    operatorChanged(changedOperator: SelectionOperator): void {
        // Target ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);

        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target: 'filter',
            data: changedOperator,
        };
        // Event auslösen
        this.eventOperatorChanged.next(eventData);
    }

    /**
     * @param deletedFilter
     * @brief       Event auslösen wenn ein Filter gelöscht wurde
     * @author      Dominik Treutle <d.treutle@pharmakon.software>
     */
    filterDeleted(deletedFilter: SelectionFilter): void {
        // Target ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);

        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target: 'filter',
            data: deletedFilter,
        };
        // Event auslösen
        this.eventFilterDeleted.next(eventData);
    }

    /**
     * @param selectedFilter
     * @brief       Event auslösen wenn Popup mit Klick auf Filter wieder geöffnet werden soll
     * @author      Dominik Treutle <d.treutle@pharmakon.software>
     */
    reopenPopup(selectedFilter: SelectionFilter): void {
        // Target ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);

        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target: 'filter',
            data: selectedFilter,
        };
        // Event auslösen
        this.eventReopenPopup.next(eventData);
    }

    /**
     * @param data
     * @brief       Filterung in alter Liste speichern wenn neue Liste ausgewählt wird
     * @author      Dominik Treutle <d.treutle@pharmakon.software>
     */
    saveData(data: any): void {
        // Target ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);

        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target: 'filter',
            data,
        };
        // Event auslösen
        this.eventSaveData.next(eventData);
    }

    /**
     * @param selection
     * @brief   Speichert eine Filterung in der DB
     * @returns  Observable<any>
     */
    saveSelection(selection: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('Selections/saveSelection', selection);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param data
     * @brief       Öffne einen gespeicherten Filter im Frontend
     * @author      Dominik Treutle <d.treutle@pharmakon.software>
     */
    openSelection(data: any): void {
        // Target ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);

        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target: 'filter',
            data,
        };
        // Event auslösen
        this.eventOpenSelection.next(eventData);
    }

    /**
     * @brief       ???
     * @author      Dominik Treutle <d.treutle@pharmakon.software>
     */
    resetSelection(): void {
        // Target ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);

        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target: 'filter',
        };
        // Event auslösen
        this.eventResetSelection.next(eventData);
    }

    /**
     * Funktion triggert einen Reload der gespeicherten Selektionen (z.B. für die Grid-Source)
     * @param target
     */
    reloadSavedSelections(target: string): void {
        // Sender ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);

        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target,
            data: {
                key: this.newObject['list_key'],
                value: this.newObject['list_value'],
            },
        };
        // Event auslösen
        this.eventReloadSavedSelections.next(eventData);
    }

    /**
     * Funktion holt beim Speichern von Selektionsdaten die Daten aus der entsprechenden
     * Selektionsliste und löst das Speichern der Selektionsdaten in den Storage aus.
     * @param data
     * @param clearedSelections
     * @param allowDeletion
     */
    onSuccessfulSelectionSave(data: any[], clearedSelections = false, allowDeletion = false): void {
        // Objekt-Daten speichern
        this.newObject['list_key'] = data['id'];
        this.newObject['list_value'] = data['title'];
        this.newObject['list_data'] = {
            frontend_array: data['frontend_array'],
            allow_deletion: '0',
            cleared_selection: '0',
        };
        if (clearedSelections) {
            this.newObject['list_data']['cleared_selection'] = '1';
        }
        if (allowDeletion) {
            this.newObject['list_data']['allow_deletion'] = '1';
        }
        // SelektionsListe und Key speichern und entsprechendes Item aus dem StorageService holen.
        this.selectionList = data['list'] + 'List';
        this.selectionListKey = data['list'] + 'ListSavedSelections';
        this.storageService.getItem(this.selectionListKey).then((val) => this.saveToStorage(val));
    }

    /**
     * Speichere einen neue Selektion in den Storage und informiere die entsprechende
     * Komponente, dass die Einträge für die grid-Source (Selektionen) erneut geladen
     * werden sollen.
     * @param existingSelections
     */
    saveToStorage(existingSelections: object[]): void {
        // Sollte es noch keinen Eintrag für die gewählte Liste im Storage geben, erstelle einen neuen
        if (typeof existingSelections === 'undefined' || existingSelections === null) {
            existingSelections = [];
        }

        // Wenn es kein nicht leeres neues Object gibt -> early-Return
        if (this.newObject.constructor === Object && Object.keys(this.newObject).length === 0) {
            return;
        }

        const index = existingSelections.findIndex((obj) => obj['list_key'] === this.newObject['list_key']);
        if (index !== -1) {
            // Wenn die Selektion schon im Storage existiert ersetze nur die list_data ...
            existingSelections[index]['list_data'] = this.newObject['list_data'];
        } else {
            // Sonst hänge die neue Selektion an die existierenden an...
            existingSelections.push(this.newObject);
        }

        // ... und speichere sie im Storage
        const saveToStorage = this.storageService.setItem(this.selectionListKey, existingSelections);
        /*
         * Informiere die Liste, dass die Daten für die Grid-Source sich verändert haben erneut geladen werden müssen.
         * Außerdem Informiere das GridSelectionPanel von erfolgreichem Speichern. Wird momentan dadurch auch beim Ausführen einer Selektion durchgeführt. Evtl nach grid-Selection-save-popup verschieben.
         */
        saveToStorage.then((val) => {
            this.reloadSavedSelections(this.selectionList);
            this.notifyGridSelectionPanelOfSaveResult(this.selectionList, true);
        });
    }

    /**
     * Benachrichtigt das GridSelectionPanel, ob die Selektion erfolgreich gespeichert wurde
     * @param target
     * @param data
     */
    notifyGridSelectionPanelOfSaveResult(target: string, data: boolean): void {
        // Target ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);
        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target,
            data,
        };
        // Event auslösen
        this.eventSelectionSaved.next(eventData);
    }

    /**
     * Benachrichtigt das GridSelectionPanel vom Start einer Selektion
     * @param target
     */
    notifyGridSelectionPanelOfSaveStart(target: string): void {
        // Target ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);

        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target,
            data: '',
        };
        // Event auslösen
        this.eventSelectionSaveStarted.next(eventData);
    }

    /**
     * Ursprünglich nur für das Ausführen der Selektion. Wird jetzt auch für das
     * speichern der Selektion genutzt dafür als data der Key der Source mitgegeben
     * @param target
     * @param key
     */
    startSelection(target: string, key: any = undefined): void {
        // Target ergibt sich aus aktueller Route
        const sender: string = extractFirstWord(this.router.url);

        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target,
            data: key,
        };
        // Event auslösen
        this.eventStartSelection.next(eventData);
    }

    /**
     * Hole alle Rollen.
     */
    getRoles(): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequestAsync('Selections/getRolesForSelection');
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }
}
