import {Identifiable} from '@shared/types';
import {Predicate} from '@angular/core';

/*
 * Sammlung von allen Grid-Event-Typen, um viele subjects im service zu verhindern
 * Doku
 */
export enum GridEventType {
    SelectionChanged = 'SELECTION_CHANGED', // Neue Zeile wurde ausgewählt
    SortChanged = 'SORT_CHANGED', // Sortierung des Grids geändert - unsicher ob benötigt
    FilterChanged = 'FILTER_CHANGED', // Filter des Grids geändert - unsicher ob benötigt
    EmptySelection = 'EMPTY_SELECTION', // Leere Auswahl
    ReloadData = 'RELOAD_DATA', // Daten sollen neu geladen werden
    DataChanged = 'DATA_CHANGED', // Daten wurden geändert
    DataDeleted = 'DATA_DELETED', // Daten wurden gelöscht
    CheckboxSelectionChanged = 'CHECKBOX_SELECTION_CHANGED', // Eine oder mehrere Checkboxen wurden angeklickt
    ClearCheckboxSelection = 'CLEAR_CHECKBOX_SELECTION', // Checkbox-Auswahl leeren
    CheckboxSelectionAction = 'CHECKBOX_SELECTION_ACTION',
}

export interface GridEvent<T extends Identifiable> {
    sender: string;
    target: string;
    type: GridEventType;
    entity?: T;
    checkboxSelection?: T[];
    data?: unknown;
}

/**
 * Typ von GridEvent.data bei Event-Typ CheckboxSelectionAction, transportiert die Information, um Checkboxen zu setzen
 */
export interface GridCheckboxSelectionAction<T extends Identifiable> {
    predicate: Predicate<T>;
    state: 'on' | 'off' | 'toggle';
    emitEvent: boolean;
}
