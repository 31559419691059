// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Service der übergeordneten Komponente
import {ToolbarService} from '../toolbar.service';

@Component({
    selector: 'phscw-toolbar-user-check',
    templateUrl: './toolbar-user-check.component.html',
    styleUrls: ['./toolbar-user-check.component.scss'],
})
export class ToolbarUserCheckComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;
    // Tooltip
    @Input() tooltip: string = null;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param toolbarService
     */
    constructor(private toolbarService: ToolbarService) {}

    ngOnInit(): void {}

    /*
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Button-Klick an Service weiterleiten
     *
     * author  Massimo Feth <m.feth@pharmakon.software>
     */
    clickUserCheck() {
        this.toolbarService.clickUserCheck(this.componentIdentifier);
    }
}
