// Angular-Module
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatSnackBarRef, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
    selector: 'phscw-snackbar-default',
    templateUrl: './snackbar-default.component.html',
    styleUrls: ['./snackbar-default.component.scss'],
})
export class SnackbarDefaultComponent implements OnInit, OnDestroy {
    /**
     * Konstruktor (inkl. dependency injection)
     * @param data
     * @param snackBarRef
     */
    constructor(
        // eslint-disable-next-line new-cap
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public snackBarRef: MatSnackBarRef<SnackbarDefaultComponent>,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * Snackbar schließen nach Klick auf das Icon
     */
    onClickIcon(): void {
        this.snackBarRef.dismiss();
    }
}
