// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Eigener Service
import {ContactsItemsOverviewService} from './contacts-items-overview.service';
/*
 * Interfaces für Structured Objects einbinden
 * import {ContactItem} from '@shared/contact-item';
 */

@Component({
    selector: 'phscw-contacts-items-overview',
    templateUrl: './contacts-items-overview.component.html',
    styleUrls: ['./contacts-items-overview.component.scss'],
})
export class ContactsItemsOverviewComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    // ID der aktuell ausgewählten Lieferung
    private _personId: number;
    @Input() set personId(value: number) {
        this._personId = value;

        if (value > 0) {
            this.loadData(value);
        }
    }

    get personId() {
        return this._personId;
    }

    // Modul-Daten (Stammdaten der Lieferung)
    data: any[];

    // EditMode aktiv?
    @Input() editMode = true;
    // Flag definiert ob gerade geladen wird
    loading = false;
    // Flag definiert ob gerade gespeichert wird
    saving = false;

    // Konstruktor (inkl. dependency injection)
    constructor(private contactsItemsOverviewService: ContactsItemsOverviewService) {}

    // Initialisierungen
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    // Events subscriben
    initializeEventSubscriptions() {}

    // Daten laden
    loadData(id: number): void {
        // Flag "loading" aktivieren
        this.loading = true;

        const serviceRequest$ = this.contactsItemsOverviewService.loadItems(id);
        serviceRequest$.subscribe(
            (result) => {
                // Bei Erfolg
                if (result['data']) {
                    // Geladene Daten in Component speichern
                    this.data = <any[]>JSON.parse(JSON.stringify(result['data']));
                }

                // Flag "loading" deaktivieren
                this.loading = false;
            },
            (error) => {
                // Flag "loading" deaktivieren
                this.loading = false;
            },
        );
    }
}
