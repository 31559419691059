// Angular-Module
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service für allgemeine Kennzeichendaten
import {ToolbarService} from '@shared/toolbar/toolbar.service';
import {CharacteristicsService} from '../characteristics.service';
/*
 * Interfaces für Structured Objects einbinden
 * Interface für Kennzeichengruppe einbinden
 */
import {environment} from '@environment';
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {LooseObject} from '@shared/loose-object';

@Component({
    selector: 'phscw-characteristic-groups-list',
    templateUrl: './characteristic-groups-list.component.html',
    styleUrls: ['./characteristic-groups-list.component.scss'],
})
export class CharacteristicGroupsListComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID der aktuell ausgewählten Entität
    private _entityId: number;
    @Input() set entityId(value: number) {
        this._entityId = value;

        // Nur Daten laden, wenn Typ vorhanden ist
        if (this.characteristicType !== undefined) {
            // Flag zurücksetzen, damit Daten neu geladen werde
            this.loading = false;
            // Daten laden
            this.loadData();
        }
    }

    get entityId() {
        return this._entityId;
    }

    @Input() selectedEntities: any;

    // Kennzeichen-Typ
    private _characteristicType: string;
    @Input() set characteristicType(value: string) {
        this._characteristicType = value;

        // Nur Daten laden, wenn ID vorhanden ist
        if (this.entityId !== undefined) {
            this.loadData();
        }
    }

    get characteristicType() {
        return this._characteristicType;
    }

    // Kennzeichengruppen-Array
    userCharacteristicsGroups: Array<any>;
    // Erlaubt das gleichzeitige Aufklappen mehrere Expansion
    @Input() multi = true;
    // Bearbeitungsmodus
    @Input() editMode = false;
    // Kennzeichen Auf-/Zuklappstatus
    @Input() characteristicsOpenState = false;
    // Kennzeichen Anzahl
    @Input() showNumberCharacteristics = false;
    // Erlaubt das Anzeigen des Bearbeitungsbutton
    @Input() showEditButton = false;
    // Erlaubt das Anzeigen des Speichernbuttons (um bei Kontaktkennzeichen die Buttons auszublenden und per Event das Speichern auszulösen)
    @Input() showSaveButton = true;
    // Flag definiert ob Kennzeichen, die nicht bearbeitet werden können, auch angezeigt werden sollen
    @Input() showReadonly = true;
    // Flag definiert ob Bestätigungsdialoge angezeigt werden sollen
    @Input() showConfirmationDialog = false;
    // Flag definiert ob Löschbutton angezeigt werden sollen
    @Input() showGlobalDeleteButton = false;
    // Flag definiert ob Checkbox angezeigt werden sollen (zum Löschen in Popup)
    @Input() showCheckbox = false;

    // Variable, die anzeigen soll, ob das Modul zum ersten mal aufgerufen wird.
    initialized = false;
    // Flag definiert, ob gerade geladen wird
    loading = true;
    // Flag, definiert ob erster Panel geöffnet sein soll
    @Input() expandFirstPanelOnInit = false;
    // Flag, definiert ob der erste Panel bereits geöffnet wurde
    firstPanelExpanded = false;

    // geladenen Daten
    characteristicsCountData: LooseObject[] = [];

    // Event das bei Validierung der Form ausgelöst wird.
    @Output() formValid = new EventEmitter<boolean>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param toolbarService
     * @param characteristicsService
     */
    constructor(
        private toolbarService: ToolbarService,
        private characteristicsService: CharacteristicsService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        if (typeof environment.showNumberCharacteristics !== 'undefined') {
            this.showNumberCharacteristics = environment.showNumberCharacteristics;
        }
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Wenn die Daten neu geladen werden sollen
        this.characteristicsService.eventReloadCharacteristicsGroupsList
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Daten laden
                this.loadData();
            });

        // Wenn die Kennzeichen auf-/zuklappen sollen
        this.characteristicsService.eventExpandCharacteristicsGroupsList
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Daten laden
                this.onExpandCharacteristics(result.data['openState']);
            });
    }

    /**
     * @param characteristicsOpenState
     * @brief Alle Kennzeichen Panels öffnen
     */
    onExpandCharacteristics(characteristicsOpenState: boolean): void {
        this.userCharacteristicsGroups.forEach((group: any) => {
            group['panelOpenState'] = characteristicsOpenState;
        });
    }

    /**
     * Kennzeichengruppen laden
     */
    loadData(): void {
        // Falls noch nicht geladen wird
        if (!this.loading || !this.initialized) {
            // Flag setzen
            this.loading = true;

            // Beim ersten Aufruf sollen die Kennzeichengruppendaten geladen werden.
            if (!this.initialized) {
                // Kennzeichengruppen aus dem Storage holen
                this.characteristicsService
                    .getUserCharacteristicGroups()
                    .then((val) => this.setCharacteristicsGroupsCallback(val));
            }

            // Hole die Anzahl der gefüllten Kennzeichen und aller Kennzeichen pro Kennzeichengruppe
            const serviceRequest$ = this.characteristicsService.getCharacteristicGroupCounts(
                this.characteristicType,
                this.entityId,
            );
            serviceRequest$.subscribe(
                (result: CWResult) => {
                    /**
                     * Prüfe, ob die Daten des eintreffenden Requests auch
                     * zur aktuell ausgewählten Entität passen. Durch asynchrone
                     * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                     * bereits die Entität gewechselt wurde und die Antwort
                     * eines Requests verspätet eintrifft und dadurch die
                     * korrekten Daten wieder überschreibt.
                     */
                    if (result.data === null || this.entityId != result.data['id']) {
                        return;
                    }

                    // Bei Erfolg Daten zwischenspeichern
                    if (result.success === true) {
                        this.characteristicsCountData = result.data['groupcount'];
                        if (this.initialized) {
                            this.organizeCharacteristicGroups();
                        }
                    }

                    // ersten Panel aufklappen
                    if (this.expandFirstPanelOnInit && this.initialized) {
                        this.checkInitialPanelState();
                    }

                    // Flag setzen
                    this.loading = false;
                },
                (error: any) => {
                    /*
                     * Keine Antwort vom Backend erhalten (Servererror)
                     * Flag setzen
                     */
                    this.loading = false;
                },
            );
        }
    }

    /**
     * @brief   Zusammenbauen der Gruppen-Anzeige und Laden der Kennzeichen
     */
    organizeCharacteristicGroups(): void {
        for (const data of this.characteristicsCountData) {
            const characteristicsTotal = data.number_of_total_characteristics;
            const characteristicsSet = data.number_of_set_characteristics;
            const index = this.userCharacteristicsGroups.findIndex((group) => group['id'] === data.id);
            if (typeof this.userCharacteristicsGroups[index] !== 'undefined') {
                if (typeof characteristicsSet !== 'undefined') {
                    this.userCharacteristicsGroups[index]['setNumber'] = characteristicsSet;
                }
                if (typeof characteristicsTotal !== 'undefined') {
                    this.userCharacteristicsGroups[index]['totalNumber'] = characteristicsTotal;
                }
            }
        }
    }

    /**
     * @param value
     * @brief
     * @todo    Beschreibung fehlt
     */
    setCharacteristicsGroupsCallback(value: any): void {
        if (!this.isUndefined(value)) {
            // setze die Daten aus dem Storage auf lokale Variable
            this.userCharacteristicsGroups = value;
            // Gib jeder einelnen Kennzeichengruppe einen eigenen panelOpenState. (Ist der Panel geöffnet oder geschlossen)
            for (const characteristicGroup of this.userCharacteristicsGroups) {
                characteristicGroup['panelOpenState'] = false;
            }

            // Kennzeichen anordnen
            this.initialized = true;
            this.organizeCharacteristicGroups();
            // ersten Panel aufklappen
            if (this.expandFirstPanelOnInit) {
                this.checkInitialPanelState();
            }
        } else {
            this.userCharacteristicsGroups = null;
        }
    }

    /**
     * @brief Ersten Panel öffnen bei Init
     */
    checkInitialPanelState(): void {
        // Alle Gruppen prüfen
        this.userCharacteristicsGroups.forEach((group: any) => {
            // nur die erste Gruppe aufklappen
            if (this.firstPanelExpanded === false && group.characteristic_group_type === this.characteristicType) {
                this.firstPanelExpanded = true;
                group['panelOpenState'] = true;
            }
        });
    }

    /**
     * @param valid
     * @brief   Gibt die Validierung einer Gruppe weiter
     * @todo    alle Gruppen prüfen und Ergebnis davon weiterleiten
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    sendFormValid(valid: boolean): void {
        this.formValid.next(valid);
    }

    /**
     * @param value
     * @brief   Überprüft ob ein Wert undefined ist. Wird zum Abfangen von Fehlern bei leeren Kennzeichengruppen gebraucht.
     */
    isUndefined(value: any): boolean {
        return (
            typeof value === 'undefined' ||
            value === null ||
            (Object.keys(value).length === 0 && value.constructor === Object)
        );
    }
}
