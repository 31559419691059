// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../cw-event';
import {LooseObject} from './../loose-object';

@Injectable({providedIn: 'root'})
export class ClearingService {
    // Definiert, ob erlaubt ist, Entitäten zusammenzuführen
    public allowMergeEntities: boolean = null;

    // Clearing-Daten (Status, Duplikate vereinen) werden aktualisiert
    public eventUpdateEntityData = new Subject<CWEvent>();
    // Aktualisierung abgeschlossen
    public eventUpdateEntityDataComplete = new Subject<CWEvent>();
    // Änderungen werden zurückgesetzt
    public eventRevertChanges = new Subject<CWEvent>();
    // Aktualisierung abgeschlossen
    public eventRevertChangesComplete = new Subject<CWEvent>();

    // Auswahl der anzuzeigenden Liste ändern
    public eventChangeClearingSource = new Subject<CWEvent>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param backendService
     * @param userPermissions
     */
    constructor(
        private backendService: BackendService,
        private userPermissions: UserPermissionsService,
    ) {}

    /**
     * @param gridId
     * @param formData
     * @brief   Angepasste Daten aus Clearing an einbindende Komponente zurückgeben
     */
    updateEntityData(gridId: string, formData: any) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: formData,
        };
        // Event auslösen
        this.eventUpdateEntityData.next(eventData);
    }

    /**
     * @param gridId
     * @brief   Aktualisierung der Daten durch Update abgeschlossen
     */
    updateEntityDataComplete(gridId: string) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {},
        };
        // Event auslösen
        this.eventUpdateEntityDataComplete.next(eventData);
    }

    /**
     * @param gridId
     * @param formData
     * @brief   Zurückzusetzende Daten an einbindende Komponente zurückgeben
     */
    revertChanges(gridId: string, formData: any) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: formData,
        };
        // Event auslösen
        this.eventRevertChanges.next(eventData);
    }

    /**
     * @param gridId
     * @brief   Zurücksetzen der Daten abgeschlossen
     */
    revertChangesComplete(gridId: string) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: gridId,
            target: '',
            data: {},
        };
        // Event auslösen
        this.eventRevertChangesComplete.next(eventData);
    }

    /**
     * @param sender
     * @param target
     * @param entityType
     * @brief   Auswahl der anzuzeigenden Liste ändern
     */
    changeClearingSource(sender: string, target: string, entityType: string) {
        // Informationen für Event
        const eventData: CWEvent = {
            sender,
            target,
            data: {entityType},
        };
        // Event auslösen
        this.eventChangeClearingSource.next(eventData);
    }

    /**
     * @param formData
     * @brief   Kennzeichen laden
     * @returns  Observable<any>
     */
    getCharacteristicsData(formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'Clearing/getCharacteristicsData/',
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param formData
     * @brief   Kennzeichen-Daten laden
     * @returns  Observable<any>
     */
    getIndividualCharacteristic(formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'Clearing/getIndividualCharacteristicData/',
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param formData
     * @brief   Zusammengeführte Daten an Backend senden
     * @returns  Observable<any>
     */
    mergeEntityData(formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('Clearing/merge/', formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Berechtigung "allowMergeEntities" prüfen
     * @returns  boolean
     */
    checkAllowMergeEntities() {
        const permissionAllowMergeEntities: boolean = this.userPermissions.getPermissionValue('allowMergeEntities');
        this.allowMergeEntities = permissionAllowMergeEntities;
    }

    /**
     * @param data
     * @brief   Selektiere bestimmte Entität
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    executeFilterEntityByIds(data: LooseObject): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('ClearingList/filterByIds/', data);
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }
}
