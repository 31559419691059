/**
 * @brief   Shared OverlayModule zur Freigabe der OverlayPanel- und OverlayMenuComponent.
 * @details // Shared Module einbinden
 *          import {OverlayModule} from '../../shared/overlay/overlay.module';
 *
 *          //Einbindungs Beispiel (html), wobei "menu" ein Array mit Daten strukturiert
 *          //nach '../../shared/menu-data' enthält
 *          <phscw-overlay-menu arrow-position="left" [items]="menu"></phscw-overlay-menu>
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
 */

// Angular-Module
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
// Komponenten
import {OverlayInfoEmployeeComponent} from './overlay-info/overlay-info-employee/overlay-info-employee.component';
import {OverlayInfoInstitutionComponent} from './overlay-info/overlay-info-institution/overlay-info-institution.component';
import {OverlayInfoPersonComponent} from './overlay-info/overlay-info-person/overlay-info-person.component';
import {OverlayMenuComponent} from './overlay-menu/overlay-menu.component';
import {OverlayPanelComponent} from './overlay-panel/overlay-panel.component';
import {OverlayTriggerLinkComponent} from './overlay-trigger-link/overlay-trigger-link.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
// Shared Modules einbinden
import {InputModule} from '../../shared/input/input.module';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatButtonModule,
        MatTableModule,
        CommonModule,
        InputModule,
        DragDropModule,
        FontAwesomeModule,
    ],
    declarations: [
        OverlayMenuComponent,
        OverlayPanelComponent,
        OverlayTriggerLinkComponent,
        OverlayInfoPersonComponent,
        OverlayInfoInstitutionComponent,
        OverlayInfoEmployeeComponent,
    ],
    exports: [OverlayMenuComponent, OverlayPanelComponent, OverlayTriggerLinkComponent],
})
export class OverlayModule {}
