// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host h2 {
  margin-top: 0;
}

.cw-horizontalline {
  border-color: var(--color-accent);
  height: 2px;
}

:host .mat-mdc-dialog-actions {
  padding-bottom: 24px;
}

:host .cw-popup-form {
  margin-top: 10px;
}

.date-range-container {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/popups/popup-formular/popup-formular.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,iCAAA;EACA,WAAA;AACJ;;AAEA;EACI,oBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AACA;EACI,aAAA;AAEJ","sourcesContent":[":host h2 {\n    margin-top: 0;\n}\n\n.cw-horizontalline {\n    border-color: var(--color-accent);\n    height: 2px;\n}\n\n:host .mat-mdc-dialog-actions {\n    padding-bottom: 24px;\n}\n\n:host .cw-popup-form {\n    margin-top: 10px;\n}\n.date-range-container {\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
