/**
 * @brief   Listen-Komponente für Clearing
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */

// Angular-Module
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// ngx-translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Eigener Service
import {ClearingService} from './../clearing.service';
// Shared Services importieren
import {GridService} from './../../grid/grid.service';
import {ToolbarService} from './../../toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {hasOwn} from '@shared/utils';
import {CWEvent} from './../../cw-event';
import {CWResult} from './../../cw-result';

// Interface für Dialogdaten
export interface DialogData {
    title: string;
    selectedEntities: any[];
    comparableColumnsConfig: any[];
    entityType: string;
    entityGridId: string;
}

@Component({
    selector: 'phscw-clearing-merge-popup',
    templateUrl: './clearing-merge-popup.component.html',
    styleUrls: ['./clearing-merge-popup.component.scss'],
})
export class ClearingMergePopupComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Überschrift des Popups
    headerText = '';

    // ID des Grids
    gridId = 'clearingMerge';

    // Typ der Entität
    entityType = '';

    // Anzuzeigende Spalten für Grid
    gridDisplayedColumns = ['changed-field', 'clearing-merge-left', 'clearing-merge-right', 'clearing-edit', 'empty'];

    // Ausgewählte Einträge
    dataLeft: any = {};
    dataRight: any = {};

    // Konfig der Reihen
    comparableColumnsConfig: any;

    // Flag definiert ob Speichern fehlgeschlagen ist
    savingFailed = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param data
     * @param dialogRef
     * @param toolbarService
     * @param gridService
     * @param clearingService
     * @param translateService
     */
    constructor(
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private dialogRef: MatDialogRef<ClearingMergePopupComponent>,
        private toolbarService: ToolbarService,
        private gridService: GridService,
        private clearingService: ClearingService,
        private translateService: TranslateService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Ausgewählte Datensätze zwischenspeichern
        this.orderSelectedEntities();

        // Konfiguration zum Vergleichen der Daten
        this.comparableColumnsConfig = this.data.comparableColumnsConfig;
        // Typ der Entität setzen
        this.entityType = this.data.entityType;

        this.setHeaderText();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Event der Toolbar zum Schließen des Popups
        this.toolbarService.eventCloseComponent
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                if (event.target !== this.gridId) {
                    return;
                }
                this.clickClose();
            });

        // Event der ClearingListe zum Zusammenführen der Daten
        this.clearingService.eventUpdateEntityData
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                if (event.sender !== this.gridId) {
                    return;
                }
                this.onEventMergeEntityData(event);
            });
    }

    /**
     * Auf Event "eventUpdateEntityData" von "clearingService" reagieren
     * @param event
     */
    onEventMergeEntityData(event: CWEvent): void {
        // vorherige Fehler ausblenden
        this.savingFailed = false;

        const serviceRequest$ = this.clearingService.mergeEntityData(event.data);
        serviceRequest$.subscribe((result: CWResult) => {
            // Bei Erfolg ...
            if (result['success']) {
                // ... Liste neu laden
                this.gridService.reloadGridData(this.data.entityGridId);
                // ... verbliebene Entität auswählen
                this.gridService.selectionChanged(this.data.entityGridId, result['data']);

                // Dialog schließen
                this.dialogRef.close();
            } else {
                // Fehlernachricht anzeigen
                this.savingFailed = true;
            }

            // Merge abgeschlossen
            this.clearingService.updateEntityDataComplete(this.gridId);
        });
    }

    /**
     * @brief   Sortierung der ausgewählten Entitäten durchführen
     * @details Der ältere Eintrag soll immer in der rechten Spalte auftauchen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    orderSelectedEntities(): void {
        // Prüfen ob die Werte gesetzt sind
        if (
            hasOwn(this.data.selectedEntities[0], 'create_date') &&
            hasOwn(this.data.selectedEntities[1], 'create_date')
        ) {
            // Datum prüfen
            if (
                this.data.selectedEntities[0]['create_date'] < this.data.selectedEntities[1]['create_date'] ||
                this.data.selectedEntities[0]['id'] < this.data.selectedEntities[1]['id']
            ) {
                this.dataLeft = this.data.selectedEntities[1];
                this.dataRight = this.data.selectedEntities[0];
            } else {
                this.dataLeft = this.data.selectedEntities[0];
                this.dataRight = this.data.selectedEntities[1];
            }
        } else if (hasOwn(this.data.selectedEntities[0], 'create_date')) {
            this.dataLeft = this.data.selectedEntities[1];
            this.dataRight = this.data.selectedEntities[0];
        } else if (hasOwn(this.data.selectedEntities[1], 'create_date')) {
            this.dataLeft = this.data.selectedEntities[0];
            this.dataRight = this.data.selectedEntities[1];
        }
    }

    /**
     * @brief   Header-Text ändern
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    setHeaderText(): void {
        // Initialisiere
        const translationString = 'GENERAL.' + this.entityType.toUpperCase();
        const entityTranslation = this.translateService.instant(translationString);

        // Übersetzung laden
        this.headerText = this.translateService.instant('SHARED.CLEARING.POPUP.HEADER', {entity: entityTranslation});
    }

    /**
     * @brief   Dialog schließen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickClose(): void {
        this.dialogRef.close();
    }
}
