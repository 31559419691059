// Angular-Module
import {Component, Input, OnInit} from '@angular/core';
// Interfaces für Structured Objects einbinden
import {SelectionOperator} from '@shared/selection-operator';
// Eigenen Service einbinden
import {GridSelectionService} from './../../grid-selection.service';

@Component({
    selector: 'phscw-grid-selection-operatorbutton',
    templateUrl: './grid-selection-operatorbutton.component.html',
    styleUrls: ['./grid-selection-operatorbutton.component.scss'],
})
export class GridSelectionOperatorbuttonComponent implements OnInit {
    @Input() filterOperator: SelectionOperator;

    constructor(private gridSelectionService: GridSelectionService) {}

    ngOnInit() {}

    changeOperator() {
        this.gridSelectionService.operatorChanged(this.filterOperator);
    }
}
