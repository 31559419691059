/**
 * @brief   Root-Routing der Anwendung zwischen Public Views (Login-Seite) und
 *          Secure-Views (C-World 4 Module).
 * @details Regelt Routing zu Hauptmodulen (lazy loaded), sowie Standard-
 *          Pfad und anzuzeigende Komponente für 404-Fehler.
 *          Durch CustomReuseStrategy werden Routen zu Hauptmodulen beim erneuten
 *          Aufruf wiederverwendet und nicht komplett neu initialisiert.
 *          Damit die Route eines (lazy loaded) Moduls gespeichert werden kann,
 *          muss in '%%%-routing.module.ts' des jeweiligen Moduls ein Key mit
 *          dessen Modulnamen hinterlegt werden.
 *          Beispiel (anhand Datei people-routing.module.ts):
 *          const routes: Routes = [
 *              {
 *                  // Default-Route dieses Moduls (bereits als Child unter dem Kontext "people\")
 *                  path: '',
 *                  component: PeopleComponent,
 *                  data: {key: 'people'},
 *              }
 *          ];
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Globale Services
import {AuthGuardService} from './global/services/auth-guard.service';
// Shared Modules (und ggf. Komponenten) einbinden
import {PopupMessageComponent} from './shared/popups/popup-message/popup-message.component';
import {PopupsModule} from './shared/popups/popups.module';

/*
 * Routing-Konstanten für Haupt-Module, die per Lazy-Loading geladen werden
 * export const lazyDashboards = 'app/modules/dashboards/dashboards.module#DashboardsModule';
 * export const lazyInstitutions = 'app/modules/institutions/institutions.module#InstitutionsModule';
 * export const lazyPeople = 'app/modules/people/people.module#PeopleModule';
 * export const lazyAdmin = 'app/modules/admin/admin.module#AdminModule';
 */

import {LayoutLoginComponent, PUBLIC_ROUTES} from './global/layouts/layout-login';
import {LayoutMainComponent, SECURE_ROUTES} from './global/layouts/layout-main';

// Konstante zur Defintion des Routings auf Hauptmodule
const routes: Routes = [
    // Default (Weiterleitung zur Login-Seite)
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full' as const,
    },
    // Public-Routes (Login-Seite)
    {
        path: '',
        component: LayoutLoginComponent,
        data: {
            key: 'Public Views',
            reuse: false,
        },
        children: PUBLIC_ROUTES,
    },
    // Secure-Routes (User muss erfolgreich authentifiziert sein)
    {
        path: '',
        component: LayoutMainComponent,
        // canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        data: {key: 'Secure Views'},
        children: SECURE_ROUTES,
    },
    // Secondary Routes für Router-Outlet "popup"
    {
        path: 'popup-message',
        component: PopupMessageComponent,
        outlet: 'popup',
    },
    // Wildcard ==> Login-Seite
    {
        path: '**',
        redirectTo: '/login',
        // component: GlobalPageNotFoundComponent
    },
];

@NgModule({
    imports: [PopupsModule, RouterModule.forRoot(routes, {bindToComponentInputs: true})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
