// Angular-Module
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service dieses Shared-Moduls
import {ContactsService} from './../../contacts.service';
// Globalen Service einbinden
import {StorageService} from '@global/services/storage.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
// Services anderer Shared-Modules
import {GridService} from '@shared/grid/grid.service';
import {InputDateService} from '@shared/input/input-date/input-date.service';
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {LooseObject} from '@shared/loose-object';
import {Ticket} from '@shared/ticket';
// Environment einbinden
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

// Moment-Modul zur Datums-Verarbeitung
import * as _moment from 'moment';

const moment = _moment;

@Component({
    selector: 'phscw-contacts-form-ticket',
    templateUrl: './contacts-form-ticket.component.html',
    styleUrls: ['./contacts-form-ticket.component.scss'],
})
export class ContactsFormTicketComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf Form
    @ViewChild('contactsForm', {static: false}) contactsForm: NgForm;

    // Name des Backend-Controllers, welcher angesteuert werden soll
    @Input() controllerName = '';

    // ID des aktuellen Kontakts - Über GETTER / SETTER, da bei Änderung ein Neuladen erfolgen soll
    _contactId = 0;
    get contactId() {
        return this._contactId;
    }

    @Input() set contactId(value) {
        // Wert übernehmen
        this._contactId = value;

        // Details laden (falls es sich NICHT um eine Neuanlage handelt - z.B. in Kontaktübersicht)
        if (this.contactId >= 0) {
            this.loadDetails(this.contactId);
        }
    }

    // ID des übergeordneten Kontakts
    @Input() parentContactId: number = null;
    // Typ der Verlinkung zu anderem Kontakt
    @Input() contactLinkType: string = null;

    // Für Neuanlage eines Kontakts muss der gewünschte Typ (contact_type) an die shared component übergeben werden
    @Input() newContactType = '';

    // FK für Neuanalge
    @Input() institutionId: number;
    @Input() personId: number;
    @Input() patientId: number;
    multiContactId: any = null;

    @Input() participants: any[] = [];

    // Daten des aktuellen Kontakts
    data: any = {};
    originalData: any = {};
    existingTicket = false;

    // Definiert, ob die Zeit und die Dauer angezeigt oder ausgeblendet werden soll
    contactsShowTime = true;

    // EditMode aktiv?
    _editMode = false;
    @Input() set editMode(value: boolean) {
        this._editMode = value;
        // Gib die Infos über den geänderten EditMode an die parent-Komponente weiter
        this.changeEditMode.emit(value);
        if (value) {
            this.showTicketForwardMail = false;
        }
    }

    get editMode() {
        return this._editMode;
    }

    // Definiert, ob der Kontakt schreibgeschützt ist
    @Input() readonly = true;
    // Definiert, ob der Readonly, von der Contacts-Form-Komponente verändert werden darf.
    @Input() readonlyImmutable = false;
    // Definiert, ob gar keine Buttons angezeigt werden sollen
    @Input() hideButtons = false;

    // Flag definiert ob gerade geladen wird
    loading = false;
    // Flag definiert ob gerade gespeichert wird
    saving = false;
    // Komponente intialisiert?
    initialized = false;

    // Flag um zu entscheiden, ob erweiterte Felder angezeigt werden sollen. Abhängig von ticketShowExtendedFields-Permission
    otherFields = false;

    // Erste Gruppe der ticketOwnGroups-Permission
    mainGroup = '';

    // Physician, Nurse, Other HCP, Pharmacist
    institutionRequiredPersonTypes: string[] = ['6', '7', '9', '10'];

    // Event-Emitter der die übergeordnete Komponente benachrichtigt
    @Output() changeEditMode = new EventEmitter<boolean>();
    // Event-Emitter der die übergeordnete Komponente benachrichtigt
    @Output() contactTypeChanged = new EventEmitter<string>();

    // Eigene User-Daten (aus Storage)
    ownUser: any = {};

    // Infotext für Ticketnummer, wenn DSGVO = false
    ticketIdText = '';

    // Soll Bereich "Ticket per Mail weiterleiten" gezeigt werden?
    showTicketForwardMail = false;
    // Daten für "Ticket per Mail weiterleiten"
    forwardmail: any = {
        receiver: '',
        subject: '',
    };

    // Flag zum Signalisieren, dass Ticket per Mail weitergeleitet wurde
    mailSentHint = false;

    // Employee-Object für AutoComplete-Component, damit nicht immer zwischen String und Object gesprungen wird
    responsibleEmployee: any = {
        id: null,
        label: null,
    };

    // heutiges Datum
    currentDate: any = new Date();
    currentTime: any = this.currentDate.getHours() + ':' + this.currentDate.getMinutes().toString().padStart(2, '0');

    // Countdown
    timeUntilDueDate: LooseObject = {
        days: -1,
        hours: -1,
        minutes: -1,
        seconds: -1,
    };

    disableSave = false;

    allowTicketForwardMail = false;

    checkLock = false;

    // Event-Emitter, ob das Ticket gesperrt ist.
    @Output() isLockedChanged = new EventEmitter<boolean>();

    // new properties
    contactsUseDuration?: boolean = false;
    minimumStartDate?: any;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param translateService
     * @param toolbarService
     * @param contactsService
     * @param inputDateService
     * @param gridService
     * @param userPermissionsService
     * @param storageService
     * @param dialog
     */
    constructor(
        private translateService: TranslateService,
        private toolbarService: ToolbarService,
        private contactsService: ContactsService,
        private inputDateService: InputDateService,
        private gridService: GridService,
        private userPermissionsService: UserPermissionsService,
        private storageService: StorageService,
        private dialog: MatDialog,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();

        if (this.contactId === 0) {
            this.loadDetails(this.contactId);
        }

        // Daten über Service anfordern
        const promise = this.storageService.getItem('ownUser');
        promise.then((val) => (this.ownUser = val));

        this.allowTicketForwardMail = this.userPermissionsService.getPermissionValue('allowTicketForwardMail');

        if (hasOwn(environment, 'lockTickets')) {
            this.checkLock = environment.lockTickets;
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeTranslateSubscriptions(): void {
        this.translateService
            .stream(['SHARED.CONTACTS.FORM.TICKET.TICKETIDTEXT'])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: any) => {
                this.ticketIdText = translation['SHARED.CONTACTS.FORM.TICKET.TICKETIDTEXT'];
            });
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Timer für Fälligkeitsdatum
        const source = timer(0, 1000);
        source.pipe(takeUntil(this._componentDestroyed$)).subscribe((value: number) => {
            // Timer anzeigen
            this.updateTimeUntilDueDate();
        });

        // Wenn der Cancel-Button der Toolbar ausgelöst wurde. Abbrechen auslösen.
        this.toolbarService.eventCloseComponent
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht für Komponente gesendet wurde
                if (event.target !== 'contacts-form') {
                    return;
                }
                this.clickCancel();
            });

        // Wenn (ein generalisierter) Button in Toolbar geklickt wird
        this.toolbarService.eventToolbarButtonClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                this.onToolbarButtonClicked(result);
            });
    }

    /**
     * Details eines Tickets laden
     * @param id
     */
    loadDetails(id: number): void {
        // Flag "loading" aktivieren
        this.loading = true;
        // Flag "Ticket per Mail weiterleiten" deaktivieren
        this.showTicketForwardMail = false;

        // Falls es sich um einen neuen Kontakt handelt, werden "newContactOptions" gesetzt
        let newContactOptions: any = {};

        const timezoneOffset = new Date().getTimezoneOffset();

        // @todo: Muster-Logik in Environment konfigurierbar machen
        if (id === 0) {
            newContactOptions = {
                contactType: this.newContactType,
                personId: this.personId,
                patientId: this.patientId,
                institutionId: this.institutionId,
                showItems: false,
                timezoneOffset,
            };
        } else {
            newContactOptions = {
                showItems: false,
                timezoneOffset,
            };
        }

        // Falls es sich um einen neuen Kontakt handelt, wird auch der gewünschte Kontakttyp mitgesendet
        const serviceRequest$ = this.contactsService.loadDetails(this.controllerName, id, newContactOptions);
        serviceRequest$.subscribe((details: CWEvent) => {
            // Lade die Gruppeninformationen aus den Permissions
            this.otherFields = this.userPermissionsService.getPermissionValue('ticketShowExtendedFields');
            const groupString: string = this.userPermissionsService.getPermissionValue('ticketOwnGroups');
            this.mainGroup = groupString.split('|')[0];

            /**
             * Prüfe, ob die Daten des eintreffenden Requests auch
             * zum angefragten Element passen. Durch asynchrone
             * Abfragen kann es nämlich passieren, dass zwischenzeitlich
             * bereits das Element gewechselt wurde und die Antwort
             * eines Requests verspätet eintrifft und dadurch die
             * korrekten Daten wieder überschreibt.
             */
            if (this.contactsService.contactId !== details['data']['id']) {
                return;
            }
            // Geladene Daten in Modul übernehmen
            this.data = Object.assign(details['data']);

            // Falls kein Ticket-Objekt an dem erhaltenen Kontakt angeschlossen ist, ein leeres Ticket anfügen:
            if (typeof this.data.ticket === 'undefined' || this.data.ticket === null) {
                this.existingTicket = false;
                this.data.ticket = new Ticket();
            } else {
                // Zeige die Stammdaten aus dem Ticket in den Input-Feldern an
                this.existingTicket = true;
            }

            // Zeige immer unveränderte Daten von verknüpften Personen / Einrichtungen in dem Infoblock an
            this.data.firstname = this.data.person_firstname;
            this.data.lastname = this.data.person_lastname;
            this.data.name1 = this.data.institution_name1;

            // NUR falls noch keine Teilnehmer an @Input übergeben wurden, Variable zuweisen - @Input darf auf keinen Fall zurückgesetzt werden!
            if ((this.participants.length === 0 || this.initialized) && 'participants' in details['data']) {
                this.participants = Object.assign(details['data']['participants']);
            } else if (this.participants.length === 0 || this.initialized) {
                this.participants = [Object.assign(details['data'])];
            }

            // Prüfen, ob das Ticket gesperrt ist.
            if (typeof this.data.locked !== 'undefined') {
                this.isLockedChanged.emit(this.data.locked);
                delete this.data.locked;
            } else {
                this.isLockedChanged.emit(false);
            }

            /**
             * 2020-09-04, PhS(MFe):
             * Aus den einzelnen geladenen Daten (responsible_employee_id und
             * responsible_employee_fullname) wird ein Objekt erstellt, welches
             * dann an Autocomplete-Component übergeben wird.
             * Auf diese Weise wird nicht mehr zwischen String und Object
             * wild hin und her gesprungen.
             */
            this.responsibleEmployee = {
                id: this.data.responsible_employee_id,
                label: this.data.responsible_employee_fullname,
            };
            /**
             * 2020-09-07, PhS(MFe):
             * Prüfe, ob es sich um ein eigenes oder fremdes Ticket handelt
             */
            this.checkTicketResponsibility();

            // Daten aus dem übergeorndeten Kontakt ziehen
            if (
                this.parentContactId !== null &&
                typeof this.parentContactId !== 'undefined' &&
                this.parentContactId > 0
            ) {
                this.transferParentData();
            }

            if (this.contactsService.contactId === 0) {
                this.data.ticket.received_date_time = new Date().toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                });
            }

            // In Abhängigkeit der Ticket Kategorie adresse und subject überschreiben
            this.forwardmail = this.data.ticket.forwardMail;

            // Kopie der Daten als "originalData" speichern
            this.originalData = JSON.parse(JSON.stringify(this.data));

            // Flag "loading" deaktivieren
            this.loading = false;

            // Flag setzen
            this.initialized = true;
        });
    }

    /**
     * Klick auf "Speichern" (Form-Submit)
     */
    clickSubmit(): void {
        // Nur gültige Formulare werden submitted
        if (this.contactsForm.form.valid) {
            // Flag "saving" aktivieren
            this.saving = true;

            /*
             * Bereite ein Ticket-Objekt in den FormData vor, welches durch die Assoziation direkt in der DB gespeichert werden kann.
             * TODO: Wahrscheinlich eleganter zu lösen mittels ngModelGroup
             * Elegant* im Controller gelöst
             */
            this.contactsForm.form.value['ticket.received_date'] = this.glueDateAndTime(
                this.contactsForm.form.value['ticket.received_date'],
                this.contactsForm.form.value['ticket.received_date_time'],
            );
            this.contactsForm.form.value['contact_end'] = this.glueDateAndTime(
                this.contactsForm.form.value['contact_end_date'],
                this.contactsForm.form.value['contact_end_time'],
            );

            const datefields = [
                'expiry_date',
                'booster_expiry_date',
                'consent_date_sent',
                'patient_birthday',
                'start_date_reaction',
                'end_date_reaction',
                'pregnancy_estimated_delivery',
                'authority_notified_date',
                'received_date',
                'contact_end',
                'initial_vaccination_date',
                'booster_date',
            ];

            for (const i in datefields) {
                if (hasOwn(datefields, i)) {
                    let datefield = 'ticket.' + datefields[i];
                    if (datefields[i] === 'contact_end') {
                        datefield = 'contact_end';
                    }

                    const oldDate = this.contactsForm.form.value[datefield];

                    if (oldDate === undefined) {
                        continue;
                    }

                    if (datefield !== 'ticket.received_date' && datefield !== 'contact_end') {
                        oldDate.hour(12);
                        oldDate.minute(0);
                    }

                    if (oldDate) {
                        this.contactsForm.form.value[datefield] =
                            this.inputDateService.getDateValueForSaveWithTimezone(oldDate);
                    }
                }
            }

            // Submit der Formular-Daten über contactsDataService
            const serviceRequest$ = this.contactsService.saveDetails(
                this.controllerName,
                this.contactId,
                this.contactsForm.form.value,
                null,
                this.participants,
                this.checkLock,
            );
            serviceRequest$.subscribe((result: CWResult) => {
                if (!result['success']) {
                    // Flag "saving" deaktivieren
                    this.saving = false;
                    return;
                }

                // Falls es sich um eine Neuanlage handelte...
                if (this.data['id'] === 0) {
                    // ...wird die neue ID übernommen
                    this.data['id'] = result['data']['id'];

                    this.contactId = result['data']['id'];
                    this.gridService.reloadGridData('contacts-form');
                }

                // Daten wurden erfolgreich geändert --> Event über Service auslösen
                this.contactsService.dataChanged('contacts-form', this.data);

                // Kopie der geänderten Daten als neue "originalData" speichern
                this.originalData = JSON.parse(JSON.stringify(this.data));

                // EditMode verlassen (und auch nach außen emitten)
                this.editMode = false;
                // Flag "saving" deaktivieren
                this.saving = false;

                // Ticket neu laden
                this.loadDetails(this.contactId);

                // Event auslösen um Mehrfachkontakt-Popup zu schließen
                this.toolbarService.closeComponent('contacts-form-popup');
            });
        }
    }

    /**
     * Klick auf "Abbrechen"
     */
    clickCancel(): void {
        /*
         * Da Cancel doch nicht so funktioniert wie gedacht, hier die schnelle Variante mit eigener Datenkopie vor Benutzeränderungen
         * 2020-08-31, PhS(LB): Scheint so nicht zu funktionieren, data ändert sich zwar, aber im Form wird dennoch der alte Wert angezeigt. Verknüpfung zu ngModel scheint verloren zu gehen.
         */
        this.data = JSON.parse(JSON.stringify(this.originalData));

        // Daten zurücksetzen
        this.contactLinkType = null;
        this.parentContactId = null;
        this.timeUntilDueDate = {
            days: -1,
            hours: -1,
            minutes: -1,
            seconds: -1,
        };

        // 2020-08-31, PhS(LB): Um nicht nicht-gespeicherte Daten anzuzeigen: Details neu laden, da das Überschreiben mittels originalData nicht funktioniert
        this.loadDetails(this.contactId);

        // EditMode verlassen (und auch nach außen emitten)
        this.editMode = false;

        if (this.checkLock) {
            // Entsperre den Kontakt / das Ticket
            this.contactsService.lockEntity(this.newContactType, this.contactId, false);
        }

        if (this.contactId === 0) {
            this.toolbarService.toggleFullscreen('reportsTicketsOverviewList', 100);
        }

        // Event auslösen um Mehrfachkontakt-Popup zu schließen
        this.toolbarService.closeComponent('contacts-form-popup');
    }

    /**
     * Klick auf "Ticket übernehmen"
     *
     * Employee-Daten des Tickets werden daraufhin mit eigenen Employee-Daten
     * überschrieben.
     *
     * Wird aktuell nicht mehr verwendet, da die Funktion aus dem Formular
     * heraus in die Toolbar gewandert ist.
     */
    clickTicketTakeover(): void {
        // Eigenen Mitarbeiternamen + Employee-ID übernehmen
        this.data.responsible_employee_fullname = this.ownUser.lastname + ', ' + this.ownUser.firstname;
        this.data.responsible_employee_id = this.ownUser.employee_id;

        // Für AutoComplete wieder ein tolles Object erzeugen
        this.responsibleEmployee = {
            id: this.data.responsible_employee_id,
            label: this.data.responsible_employee_fullname,
        };
    }

    /**
     * Klick auf "Ticket per E-Mail weiterleiten" um entsprechende
     * Mail-Optionen angezeigt zu bekommen.
     *
     * Schaltet zwischen sichtbar / verdeckt um.
     */
    toggleDisplayForwardTicketMail(): void {
        this.showTicketForwardMail = !this.showTicketForwardMail;
    }

    /**
     * Klick auf "E-Mail senden"
     * Es wird ein Hinweistext angezeigt, der nach kurzer Zeit ausgeblendet wird.
     */
    clickSendTicketMail(): void {
        // Service aufrufen
        const serviceRequest$ = this.contactsService.forwardMail(
            this.controllerName,
            this.forwardmail.receiver,
            this.forwardmail.subject,
            this.contactId,
        );
        serviceRequest$.subscribe((result: CWResult) => {
            if (result['success']) {
                // Flag setzen
                this.mailSentHint = true;
                // Hinweis nach kurzer Zeit ausblenden, d.h. mailSent wieder zurücksetzen
                setTimeout(() => {
                    this.mailSentHint = false;
                    this.loadDetails(this.contactId);
                }, 1000);
            }
        });
    }

    /**
     * Prüft, ob es sich um ein eigenes oder fremdes Ticket handelt.
     * Die Information wird über contactsService weitergereicht, damit z. B. die
     * Toolbar darauf reagieren kann.
     *
     * Wird nach dem Laden der Details eines Tickets geprüft.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    checkTicketResponsibility(): void {
        let ownTicket = false;
        if (this.ownUser.employee_id === this.data.responsible_employee_id) {
            ownTicket = true;
        }
        this.contactsService.checkedTicketResponsibility(this.contactId, ownTicket, this.data.ticket.ticket_state);
    }

    /**
     * Button in Toolbar wurde geklickt.
     * Prüfen, ob es sich um "Ticket übernehmen" handelt.
     * @param result
     */
    onToolbarButtonClicked(result: CWEvent): void {
        // Sender und Target prüfen
        if (result.target != 'contacts-form') {
            return;
        }

        if (result.sender === 'toolbar-user-check') {
            /**
             * Ticketübernahme speichern bzw. im Backend die Übernahme auslösen.
             * Funktion "clickTicketTakeover" muss nicht vorher ausgelöst werden, da
             * dies nur für die Frontend-Login innerhalb des EditModes implementiert
             * wurde.
             */
            this.saveTakeover();
        }

        if (result.sender === 'toolbar-reopen') {
            // Ticketwiedereröffnung auslösen
            this.saveReopen();
        }
    }

    /**
     * Ticketübernahme im Backend auslösen
     * Es muss keine Employee-ID gesendet werden, da das Ticket im Backend
     * automatisch dem aktiven Benutzer zugeordnet wird.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    saveTakeover(): void {
        const serviceRequest$ = this.contactsService.saveTakeover(this.controllerName, this.contactId, this.checkLock);
        serviceRequest$.subscribe((result: CWResult) => {
            if (result.success === true) {
                // Daten wurden erfolgreich geändert --> Event über Service auslösen
                this.contactsService.dataChanged('contacts-form', this.data);
                // Ticket neu laden
                this.loadDetails(this.contactId);
            } else if (result.message === 'Sperrung wegen Bearbeitung') {
                this.openMessagePopup(result.data.employeename);
            } else {
                this.openErrorMessagePopup(result.message);
            }
        });
    }

    /**
     * @brief   Ticketwiederöffnung im Backend auslösen
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    saveReopen(): void {
        const serviceRequest$ = this.contactsService.saveReopen(this.controllerName, this.contactId);
        serviceRequest$.subscribe((result: CWResult) => {
            if (result.success === true) {
                // Daten wurden erfolgreich geändert --> Event über Service auslösen
                this.contactsService.dataChanged('contacts-form', this.data);
                // Ticket neu laden
                this.loadDetails(this.contactId);
            }
        });
    }

    /**
     * @param key
     * @brief   Priorität setzen
     * @details Priorität auf "urgent" setzen, wenn PQ (Listentry 29 und 30) als Kategorie ausgewählt wurde
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    updatePriority(key: string): void {
        // Wert prüfen
        if (key === '27' || key === '28' || key === '29' || key === '30') {
            this.data.ticket.ticket_priority = 'urgent';
        }
    }

    /**
     * @brief   Zeit bis Fälligkeitsdatum berechnen
     * @details Differenze zwischen JETZT und DUE_DATE + DUE_DATE_TIME mit
     *          Hilfe von momentJS berechnet und zwischengespeichert für
     *          Anzeige in Template
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    updateTimeUntilDueDate(): void {
        // Abbrechen, wenn Form noch nicht initialisiert ist
        if (typeof this.contactsForm === 'undefined' || this.contactsForm.form.value['contact_end_date'] == null) {
            return;
        }
        // Datum zusammensetzen und prüfen
        const datetime = this.glueDateAndTime(
            this.contactsForm.form.value['contact_end_date'],
            this.contactsForm.form.value['contact_end_time'],
        );
        if (datetime === null || typeof datetime === 'undefined' || !datetime) {
            return;
        }

        // Differenz berechnen
        const today = moment();
        const diff = moment.duration(datetime.diff(today));

        // Werte zwischenspeichern
        this.timeUntilDueDate.days = diff.get('days');
        this.timeUntilDueDate.hours = diff.get('hours');
        this.timeUntilDueDate.minutes = diff.get('minutes');
        this.timeUntilDueDate.seconds = diff.get('seconds');
    }

    /**
     * @param date
     * @param time
     * @brief   klebt Datum und Zeit zusammen
     * @author  Eric Häusel <e.hauesel@pharmakon.software>
     */
    private glueDateAndTime(date: any, time: any) {
        if (!date) {
            return null;
        }

        if (time) {
            const splitted = time.split(':');
            date.hour(splitted[0]);
            date.minute(splitted[1]);
        } else {
            date.hour(0);
            date.minute(0);
        }

        return date;
    }

    /**
     * @brief   Daten aus übergeordnetem Ticket überführen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private transferParentData(): void {
        // Felder definiert
        const copyFields: string[] = [
            'institution_id',
            'ticket.consent_given',
            'ticket.consent_reference',
            'ticket.firstname',
            'ticket.lastname',
            'ticket.phone',
            'ticket.mail',
            'ticket.street',
            'ticket.zipcode',
            'ticket.city',
            'ticket.country',
            'ticket.institution_name',
            'ticket.person_type',
            'ticket.product_flag',
            'ticket.ticket_product',
            'ticket.ticket_source',
            'ticket.question',
            'ticket.ticket_category',
            'title',
        ];

        const exceptionFields: LooseObject = {
            'ticket.firstname': 'person_firstname',
            'ticket.lastname': 'person_lastname',
            'ticket.phone': 'phone',
            'ticket.mail': 'person_mail',
            'ticket.street': 'institution_street',
            'ticket.zipcode': 'institution_zipcode',
            'ticket.city': 'institution_city',
            'ticket.institution_name': 'institution_name1',
        };

        // falls beim canceln original Data zurückgesetzt wurde, early return
        if (Object.keys(this.originalData).length === 0) {
            return;
        }

        // Werte übertragen
        copyFields.forEach((field: string) => {
            // Prüfe, ob der Wert in einem Sub-Array ist
            if (field.includes('.')) {
                // Bezeichnung auftrennen
                const subfields = field.split('.');

                // Prüfen, ob es sich um eine Ausnahme handelt
                if (Object.keys(exceptionFields).includes(field)) {
                    if (hasOwn(this.originalData, subfields[0])) {
                        this.data[exceptionFields[field]] = this.originalData[subfields[0]][subfields[1]];
                    } else {
                        this.data[exceptionFields[field]][subfields[1]] = null;
                    }
                } else if (hasOwn(this.originalData, subfields[0])) {
                    this.data[subfields[0]][subfields[1]] = this.originalData[subfields[0]][subfields[1]];
                } else {
                    this.data[subfields[0]][subfields[1]] = null;
                }
            } else {
                this.data[field] = this.originalData[field];
            }
        });
    }

    /**
     * @brief   Aktualisiert Fälligkeitsdatum anhand der Kategorie
     * @author  Eric Häußel <e.haeusel@pharmakon.software>
     */

    updateNewDueDateTime(): void {
        const ticketCategory = this.data.ticket.ticket_category;
        const ticketPriority = this.data.ticket.ticket_priority;

        // glueDateTime aufrufen @todo
        let receivedDateTimeString = this.data.ticket.received_date.format('YYYY-MM-DD');
        if (this.data.ticket.received_date_time) {
            receivedDateTimeString += ' ' + this.data.ticket.received_date_time;
        } else {
            receivedDateTimeString += ' ' + new Date().toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            });
        }

        const selectedDate = moment(receivedDateTimeString);

        this.disableSave = selectedDate > moment();

        let tobeAddedHours = null;
        /*
         *"26"listEntry"general"
         *"28"listEntry"AE Escalated to MI"
         *"29"listEntry"PQC"
         *"30"listEntry"PQC Escalated to MI"
         *"31"listEntry"Medical Information"
         *"32"listEntry"Medical Information escalated"
         *"27"listEntry"AE"
         */
        if (ticketPriority == 'urgent') {
            tobeAddedHours = 24;
        } else if (ticketPriority == 'normal') {
            if (['27', '28', '29', '30'].includes(ticketCategory)) {
                tobeAddedHours = 24;
            } else if (['26', '31', '32'].includes(ticketCategory)) {
                tobeAddedHours = 240;
            }
        }

        if (tobeAddedHours != null) {
            this.data.contact_end_date = moment(receivedDateTimeString).add(tobeAddedHours, 'hours');
            // Zeit setzen
            this.data.contact_end_time = this.data.contact_end_date.format('HH:mm');
        } else {
            this.data.contact_end_date = null;
            // Zeit setzen
            this.data.contact_end_time = '';
        }
    }

    /**
     * Dialog anzeigen, falls das Ticket gerade für die Bearbeitung gesperrt ist.
     * @param name
     */
    openMessagePopup(name: string): void {
        // Dialog konfigurieren und öffnen
        this.dialog.open(PopupMessageComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('SHARED.CONTACTS.FORM.TICKET.LOCKEDTICKETTITLE'),
                message: this.translateService.instant('SHARED.CONTACTS.FORM.TICKET.LOCKEDTICKETMESSAGE', {name}),
            },
        });
    }

    /**
     * Dialog anzeigen, falls es zu einem anderen generischen Fehler gekommen ist
     * @param message
     */
    openErrorMessagePopup(message: string): void {
        // Dialog konfigurieren und öffnen
        this.dialog.open(PopupMessageComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('GENERAL.ERROR'),
                message,
            },
        });
    }
}
