// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .cw-time-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  align-self: flex-end;
}

:host .cw-time-input {
  min-width: 90px;
  width: 90px;
}

:host button span[class^=icon] {
  font-size: 22px;
}

:host ::ng-deep .mat-mdc-icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

:host ::ng-deep .flatpickr-calendar.static.open {
  border-radius: 0;
  min-width: 85px;
}

:host .cw-delete {
  line-height: 25px;
  text-align: center;
  width: 25px;
  z-index: 9;
}

:host ::ng-deep .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 25px !important;
  padding: 0;
}

:host ::ng-deep .mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: var(--mdc-icon-button-state-layer-size);
  width: var(--mdc-icon-button-state-layer-size);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/input/input-time/input-time.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,oBAAA;AACJ;;AAEA;EACI,eAAA;EACA,WAAA;AACJ;;AAGA;EACI,eAAA;AAAJ;;AAIA;EACI,oBAAA;EACA,uBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,eAAA;AADJ;;AAIA;EACI,iBAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;AADJ;;AAIA;EACI,mDAAA;EACA,UAAA;AADJ;;AAIA;EACI,+CAAA;EACA,8CAAA;AADJ","sourcesContent":[":host .cw-time-input-wrapper {\n    display: flex;\n    align-items: center;\n    position: relative;\n    align-self: flex-end;\n}\n\n:host .cw-time-input {\n    min-width: 90px;\n    width: 90px;\n}\n\n// Icon größe\n:host button span[class^='icon'] {\n    font-size: 22px;\n}\n\n// Icon zentrieren\n:host ::ng-deep .mat-mdc-icon-button {\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n}\n\n:host ::ng-deep .flatpickr-calendar.static.open {\n    border-radius: 0;\n    min-width: 85px;\n}\n\n:host .cw-delete {\n    line-height: 25px;\n    text-align: center;\n    width: 25px;\n    z-index: 9;\n}\n\n:host ::ng-deep .mat-mdc-icon-button.mat-mdc-button-base {\n    --mdc-icon-button-state-layer-size: 25px !important;\n    padding: 0;\n}\n\n:host ::ng-deep .mat-mdc-icon-button .mat-mdc-button-touch-target {\n    height: var(--mdc-icon-button-state-layer-size);\n    width: var(--mdc-icon-button-state-layer-size);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
