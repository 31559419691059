import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core'; // Import TranslateModule
import {EntityAffiliationComponent} from './entity-affiliation.component';
import {GridModule} from '@shared/grid/grid.module';
import {ToolbarModule} from '@shared/toolbar/toolbar.module';
import {EntityAffiliationFormComponent} from './entity-affiliation-form/entity-affiliation-form.component';
import {InputModule} from '../input/input.module';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ToolbarModule,
        GridModule,
        InputModule,
        ReactiveFormsModule,
        MatButtonModule,
    ],
    declarations: [
        EntityAffiliationComponent,
        EntityAffiliationFormComponent,
    ],
    // Komponenten freigeben
    exports: [
        EntityAffiliationComponent,
        EntityAffiliationFormComponent,
    ],
    providers: [],
})
export class EntityAffiliationModule {}
