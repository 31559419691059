// Angular-Module
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatCellDef, MatColumnDef, MatHeaderCellDef, MatTable} from '@angular/material/table';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Service dieses Shared-Moduls
import {GridService} from './../../grid.service';
// GridComponent
import {GridComponent} from './../grid.component';

@Component({
    selector: 'phscw-grid-dailyreport-file',
    templateUrl: './grid-dailyreport-file.component.html',
    styleUrls: ['./grid-dailyreport-file.component.scss', './../grid.component.scss'],
})
export class GridDailyreportFileComponent implements OnInit, OnDestroy {
    // Referenz auf Spalte
    @ViewChild(MatColumnDef, {static: true}) columnDef: MatColumnDef;
    @ViewChild(MatHeaderCellDef, {static: true}) headerCellDef: MatHeaderCellDef;
    @ViewChild(MatCellDef, {static: true}) cellDef: MatCellDef;

    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;

    // Spalten-Name der auf die Spalte verweist
    private _name: string;
    @Input()
    set name(name: string) {
        this._name = name;
        if (this.columnDef) {
            this.columnDef.name = name;
        }
    }

    get name(): string {
        return this._name;
    }

    /**
     * Konstruktor (inkl. dependency injection)
     * @param table
     * @param gridService
     */
    constructor(
        private table: MatTable<any>,
        private gridService: GridService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        if (this.table) {
            this.columnDef.name = this._name;
            this.columnDef.headerCell = this.headerCellDef;
            this.columnDef.cell = this.cellDef;
            this.table.addColumnDef(this.columnDef);
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        if (this.table) {
            this.table.removeColumnDef(this.columnDef);
        }

        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Trigger generation of report
     * @param employeeId
     */
    clickGenerateAndDownloadReport(employeeId: number): void {
        this.gridService.dailyreportDownloadClicked(this.gridConnection.gridId, employeeId);
    }
}
