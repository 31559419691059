// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class InstitutionsOpeninghoursService {
    // Konstruktor (inkl. dependency injection)
    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
    ) {}

    /**
     * @param id
     * @brief   Öffnungszeiten einer Einrichtung laden
     * @returns  Observable<any>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadData(id: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('InstitutionsOpeninghours/details/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @param id
     * @param formData
     * @brief   Speichert Öffnungszeiten einer Einrichtung
     * @returns  Observable<any>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    saveData(id: number, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'InstitutionsOpeninghours/edit/' + id,
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
