// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services
import {FileDownloadService} from '@global/services/file-download.service';
// Eigener Service
import {InstitutionsService} from './../institutions.service';
import {InstitutionsDocumentsService} from './institutions-documents.service';
// Shared Services
import {GridService} from '@shared/grid/grid.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {Institution} from '@shared/institution';
import {UserPermissionsService} from '@global/services/user-permissions.service';

@Component({
    selector: 'phscw-institutions-documents',
    templateUrl: './institutions-documents.component.html',
    styleUrls: ['./institutions-documents.component.scss'],
})
export class InstitutionsDocumentsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID des Grids
    displayGridId = 'institutionsDocumentsList';

    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;
    // Modul-Daten (Stammdaten der Einrichtung)
    data: Institution;

    // EditMode aktiv?
    @Input() editMode = false;

    // Ausgelagertes Flag um die Berechtigung zu prüfen ob Daten hochgeladen werden dürfen
    allowFileUpload = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param fileDownloadService
     * @param gridService
     * @param institutionsService
     * @param institutionsDocumentsService
     * @param userPermissionsService
     */
    constructor(
        private fileDownloadService: FileDownloadService,
        private gridService: GridService,
        public institutionsService: InstitutionsService,
        private institutionsDocumentsService: InstitutionsDocumentsService,
        private userPermissionsService: UserPermissionsService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   EventSubscriptons
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeEventSubscriptions(): void {
        // Event "selectionChanged" von "institutionsService"
        this.institutionsService.selectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: number) => {
                // ID des Eintrags zwischenspeichern
                this.onSelectionChanged(result);
            });

        // Event "eventGridSelectionChanged" von "gridService"
        this.gridService.eventGridSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: any) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid (E-Liste) kam
                if (event.sender !== this.displayGridId) {
                    return;
                }
                this.onEventGridSelectionChanged(event);
            });

        // prüfen ob man dateien hochladen darf
        this.checkAllowFileUpload();
    }

    /**
     * @param id
     * @brief   Auf Wechsel der Einrichtung reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onSelectionChanged(id: number): void {
        // ID der aktuellen Person merken
        this.institutionId = id;
    }

    /**
     * @param event
     * @brief   Auf Klick einer Reihe im Grid reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventGridSelectionChanged(event: CWEvent): void {
        const documentId: number = event.data.selectedRow.id;
        const documentName: string = event.data.selectedRow.document_name;
        this.downloadFile(documentId, documentName);
    }

    /**
     * @param id
     * @param name
     * @brief   Dokument herunterladen
     * @todo    Fehleranzeige
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    downloadFile(id: number, name: string): void {
        // Request auslösen
        const serviceRequest$ = this.institutionsDocumentsService.getDocument(id);
        serviceRequest$.subscribe(
            (result: Blob) => {
                // Dialog zum Speichern öffnen
                this.fileDownloadService.openSaveDialog(result, name);
            },
            (error: any) => {
                // Error Handling
            },
        );
    }

    /**
     * Berechtigung "allowEditAllDocuments" prüfen
     * @todo erweitern durch https://pharmakon-software.atlassian.net/browse/CWS23S-25
     */
    checkAllowFileUpload(): void {
        // Berechtigung laden
        this.allowFileUpload = this.userPermissionsService.getPermissionValue('allowEditAllDocuments');
    }
}
