import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LooseObject} from '@shared/loose-object';
// Eigener Service
import {OrderFormService} from '../../order-form.service';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'phscw-order-form-footer',
    templateUrl: './order-form-footer.component.html',
    styleUrls: ['./order-form-footer.component.scss'],
})
export class OrderFormFooterComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();
    @Input() editMode = false;

    // Zahlenformat für Preise/Rabatte
    customCurrencyFormat = {
        decimal_separator: ',',
        thousands_separator: '.',
        decimal_places: 2,
    };

    // Auftragsdaten
    @Input() set orderData(order: LooseObject) {
        // Prüfe ob orderData nicht null/undefined oder empty object ist
        if (!(order && Object.keys(order).length === 0 && Object.getPrototypeOf(order) === Object.prototype)) {
            this.orderDataCopy = Object.assign(order);
            this.initializeFormControls(this.orderDataCopy);
        }
    }

    // Zwischenspeichern der Auftragsdaten
    orderDataCopy: LooseObject;

    // init. Reactive Form
    orderFooterFormGroup: UntypedFormGroup = new UntypedFormGroup({
        order_discount_text: new UntypedFormControl(null),
        order_amount_total: new UntypedFormControl(null),
        order_sum: new UntypedFormControl(null),
        shipping_costs: new UntypedFormControl(null),
        order_total: new UntypedFormControl(null),
        order_discount_mean: new UntypedFormControl(null),
        order_discount_sum: new UntypedFormControl(null),
    });

    /**
     * Konstruktor (inkl. dependency injection)
     * @param orderFormService
     */
    constructor(public orderFormService: OrderFormService) {}

    /**
     * @brief   initialize Form Data
     * @param   {LooseObject} order Auftragsdaten
     */
    initializeFormControls(order: LooseObject) {
        // ReactiveForm zurücksetzen
        this.orderFooterFormGroup.reset();

        // im Auftragskopf wird (normalerweise) kein Rabatt abgespeichert, für den Footer den Wert berechnen
        const calculatedDiscount =
            order.order_sum !== null && order.order_sum > 0 ?
                (order.order_sum - order.order_total) / order.order_sum :
                0;

        // übergebene Werte in ReactiveForm setzen
        this.orderFooterFormGroup.patchValue({
            order_amount_total: order.order_items.map((item) => item.amount ?? 0).reduce((a, b) => a + b, 0),
            order_sum: order.order_sum,
            shipping_costs: order.shipping_costs,
            order_total: order.order_total,
            order_discount_mean: calculatedDiscount,
            order_discount_sum: calculatedDiscount * (order.order_total ?? 0),
        });
    }

    /**
     * Initialize
     */
    ngOnInit() {
        // Auf das Event hören, wenn OrderType gewechselt wird
        this.orderFormService.selectedOrderType
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: string) => {
                for (const [key, value] of Object.entries(this.orderFormService.orderAccessRightsExplicit)) {
                    if (!Object.prototype.hasOwnProperty.call(this.orderFooterFormGroup.controls, key)) {
                        continue;
                    }
                    if (value['required']) {
                        this.orderFooterFormGroup.controls[key].setValidators([Validators.required]);
                    } else {
                        this.orderFooterFormGroup.controls[key].setValidators(null);
                    }
                    this.orderFooterFormGroup.controls[key].updateValueAndValidity();
                }
            });
    }

    /**
     * Cleanup
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }
}
