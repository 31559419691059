// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
*/
:host {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/institutions/institutions/institutions.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,WAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n*/\n\n:host {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
