import {Component, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Services
import {ToolbarService} from '@shared/toolbar/toolbar.service';
import {UserPermissionsService} from './../../../services/user-permissions.service';
import {GlobalHelpPopupService} from './../global-help-popup/global-help-popup.service';
import {GlobalHelpService} from './../global-help.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';

@Component({
    selector: 'phscw-global-help-toolbar',
    templateUrl: './global-help-toolbar.component.html',
    styleUrls: ['./global-help-toolbar.component.scss'],
})
export class GlobalHelpToolbarComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Wird bei Komponenteninitialisierung aus Userpermissions überschrieben
    allowNewHelpentry = false;

    // Initialer Fullscreen-Status (nur für das erstmalige Setzen des richtigen Fullscreen-Icons)
    fullscreenStatus = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param toolbarService
     * @param globalHelpPopupService
     * @param globalHelpService
     * @param userPermissionsService
     */
    constructor(
        private toolbarService: ToolbarService,
        private globalHelpPopupService: GlobalHelpPopupService,
        private globalHelpService: GlobalHelpService,
        private userPermissionsService: UserPermissionsService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        this.initializeEventSubscriptions();
        // Initialisiere allowNewHelpEntry aus Userpermissions
        this.checkAllowNewHelpentry();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Event "eventFullscreenToggle" von "toolbarService"
        this.toolbarService.eventFullscreenToggle
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                if (result.target === 'global-help') {
                    this.globalHelpPopupService.toggleFullscreen();
                }
            });

        // Event "eventAddItem" von "toolbarService"
        this.toolbarService.eventAddItem.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            if (result.target === 'global-help') {
                this.globalHelpService.newEntry();
            }
        });
    }

    /**
     * @brief   Setze allowNewHelpentry-Variable aus den Userpermissions
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    checkAllowNewHelpentry(): void {
        this.allowNewHelpentry = this.userPermissionsService.getPermissionValue('allowNewHelpentry');
    }
}
