// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Service dieses Shared-Moduls
import {ToolbarService} from '../toolbar.service';

@Component({
    selector: 'phscw-toolbar-google-maps',
    templateUrl: './toolbar-google-maps.component.html',
    styleUrls: ['./toolbar-google-maps.component.scss'],
})
export class ToolbarGoogleMapsComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;
    // Tooltip
    @Input() tooltip: string = null;

    // Konstruktor
    constructor(private toolbarService: ToolbarService) {}

    // Initialisierungen
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    // Events subscriben
    initializeEventSubscriptions() {}

    // Google-Maps anzeigen
    showGoogleMaps() {
        this.toolbarService.showGoogleMaps(this.componentIdentifier);
    }
}
