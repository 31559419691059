// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
// Globale Services
import {BackendService} from './../../services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';

@Injectable({providedIn: 'root'})
export class GlobalHelpService {
    // Event: löst Änderung der angezeigten Hilfe-Liste aus
    public eventHelpListChanged = new Subject<CWEvent>();
    // Event: löst Änderung des angezeigten Hilfstext an
    public eventHelpSelectionChanged = new Subject<CWEvent>();
    // Event: wird ausgelöst, wenn ein eintrag editiert wurde
    public eventHelpEntryChanged = new Subject<CWEvent>();
    // Event: Neuen Eintrag anlegen
    public eventNewEntry = new Subject<CWEvent>();

    // Event: Neuen Eintrag anlegen
    public eventOpenPopup = new Subject<CWEvent>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param router
     * @param backendService
     */
    constructor(
        private router: Router,
        private backendService: BackendService,
    ) {}

    /**
     * @brief   Löst Event aus, wenn ein neuer Helpentry geladen werden soll
     * @param   selectionId : number    die ID des neu gewählten Helpentries
     * @param   sender      : string    Sender des Events
     * @param   target      : string    Optional, Empfänger. Wenn nicht übergeben wird das Event zum Broadcast
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    selectionChange(selectionId: number, sender: string, target?: string): void {
        // Eventdaten erstellen
        const eventData: CWEvent = {
            sender,
            target: target || '',
            data: {id: selectionId},
        };

        // Event abschicken
        this.eventHelpSelectionChanged.next(eventData);
    }

    /**
     * @brief   Löst Event aus, wenn ein neuer Helpentry geladen werden soll
     * @param   selectionId : number    die ID des neu gewählten Helpentries
     * @param   sender      : string    Sender des Events
     * @param name
     * @param   target      : string    Optional, Empfänger. Wenn nicht übergeben wird das Event zum Broadcast
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    entryUpdate(sender: string, selectionId: number, name: string, target?: string): void {
        // Eventdaten erstellen
        const eventData: CWEvent = {
            sender,
            target: target || '',
            data: {
                entry_name: name,
                id: selectionId,
            },
        };

        // Event abschicken
        this.eventHelpEntryChanged.next(eventData);
    }

    /**
     * @brief   Löst Event aus, wenn eine neue HelpList geladen werden soll
     * @details Lädt Listendaten aus dem Backend und übergibt sie im Event. Gibt den
     *          Request zurück, damit festgestellt werden kann, wann die Suche beendet ist
     * @param   senderName  : string    gibt den Absender an. Abnhängig vom Absender wird der anzuzeigende Listentyp gewählt
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    listChange(senderName: string): void {
        // URL des Controllers und der Funktion festlegen
        let requestURL = 'HelpList/index';
        let listType = '';

        // Anfrage anhand des Absenders zusammenstellen
        switch (senderName) {
            case 'global-help-lists-init':
            case 'help-buttons-context':
                /*
                 * Kontext laden
                 * Angezeigtes Module ermitteln
                 */
                // URL vervollständigen
                requestURL += '/context/' + this.router.url.replace(/^\/+/g, '').replace('/', '-');
                // Typ setzen
                listType = 'context';
                break;
            case 'global-help-lists':
            case 'help-buttons-hierarchy':
                /*
                 * Hierarchie laden
                 * URL vervollständigen
                 */
                requestURL += '/tree';
                // Typ setzen
                listType = 'hierarchy';
                break;
            default:
                // Nichts zu tun, HelplistsController.index wird ohne Parameter aufgerufen
                break;
        }

        // Request an Backend
        const request = this.backendService.getRequest(requestURL);
        request.subscribe((result: CWResult) => {
            // 2020-01-20 PhS(LB): Falls bei init ein leerer Schnellzugriff geladen wird: Wechsel auf Hierarchie
            if (senderName === 'global-help-lists-init' && result.data.length == 0) {
                this.listChange('help-buttons-hierarchy');
            } else {
                // Eventdaten Zusammenstellen
                const eventData: CWEvent = {
                    sender: senderName,
                    target: '',
                    data: {
                        list: result.data,
                        listtype: listType,
                    },
                };

                // Event abschicken
                this.eventHelpListChanged.next(eventData);
            }
        });
    }

    /**
     * @brief   Löst Event aus, wenn ein neue HelpList gesucht werden soll
     * @details Lädt Listendaten aus dem Backend und Übergibt sie im Event. Gibt den Request zurück, damit festgestellt werden kann, wann die Suche beendet ist
     * @param   searchterm  : string der Text, der gesucht werden soll
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    search(searchterm: string) {
        // Anfrage an Backend stellen
        const request = this.backendService.getRequest('HelpList/search/' + searchterm);
        request.subscribe((result: CWResult) => {
            // Eventdaten zusammenstellem
            const eventData: CWEvent = {
                sender: 'search',
                target: '',
                data: {
                    list: result.data,
                    listtype: 'search',
                },
            };

            // Event abschicken
            this.eventHelpListChanged.next(eventData);
        });

        // request zurückgeben
        return request;
    }

    /**
     * @brief   Bereitet einen neuen Eintrag vor
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    newEntry(): void {
        const eventData: CWEvent = {
            sender: 'global-help',
            target: 'global-help',
            data: '',
        };

        // Event abschicken
        this.eventNewEntry.next(eventData);
    }

    /**
     * @brief   Lädt Versionsdaten aus dem Backend
     * @param id
     * @param   number  id  ID des Dokuments
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getDocumentHistory(id: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest: Observable<any> = this.backendService.getRequest('Documents/getHistory/' + id + '/helpentry');
        // Observable (an Komponente) zurücklieferen
        return getRequest;
    }

    /**
     * @brief   Lädt Dokument aus dem Backend
     * @param id
     * @param   number  id  ID des Dokuments
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getDocument(id: number): Observable<Blob> {
        // GET-Request über BackendService senden
        const getRequest: Observable<Blob> = this.backendService.getFile('Documents/download/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest;
    }

    /**
     * @param contactId
     * @param helpentryId
     * @param versionId
     * @param versionNumber
     * @brief   ???
     */
    openPopup(contactId, helpentryId, versionId, versionNumber): void {
        const eventData: CWEvent = {
            sender: 'global-help',
            target: 'global-help',
            data: {
                contactId,
                helpentryId,
                versionId,
                versionNumber,
            },
        };
        // Event abschicken
        this.eventOpenPopup.next(eventData);
    }
}
