// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  margin-top: 20px;
}

::ng-deep .icon-delete.cw-delete {
  margin-left: 16px;
}

form .cw-flex-row .cw-toolbar-icon {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--color-primary-dark);
  font-size: 15px;
  height: 25px;
  margin-left: 2px;
  width: 25px;
}

form .cw-flex-row .cw-toolbar-icon:hover {
  border-color: var(--color-primary-dark);
}

form .cw-flex-row .cw-toolbar-icon:active {
  background-color: var(--color-primary-dark);
  color: var(--color-default-light);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/order-form/order-form/order-form-header/order-form-header.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,6BAAA;EACA,6BAAA;EACA,gCAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,uCAAA;AACJ;;AAEA;EACI,2CAAA;EACA,iCAAA;AACJ","sourcesContent":[".header {\n    margin-top: 20px;\n}\n\n::ng-deep .icon-delete.cw-delete {\n    margin-left: 16px;\n}\n\nform .cw-flex-row .cw-toolbar-icon {\n    background-color: transparent;\n    border: 1px solid transparent;\n    color: var(--color-primary-dark);\n    font-size: 15px;\n    height: 25px;\n    margin-left: 2px;\n    width: 25px;\n}\n\nform .cw-flex-row .cw-toolbar-icon:hover {\n    border-color: var(--color-primary-dark);\n}\n\nform .cw-flex-row .cw-toolbar-icon:active {\n    background-color: var(--color-primary-dark);\n    color: var(--color-default-light);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
