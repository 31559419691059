// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-dialog-title {
  margin: 0;
  font-size: 10pt;
}

.mat-mdc-dialog-actions {
  justify-content: center;
  margin: 5pt;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-update-dialog/global-update-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,eAAA;AACJ;;AAEA;EACI,uBAAA;EACA,WAAA;AACJ","sourcesContent":[".mat-mdc-dialog-title {\n    margin: 0;\n    font-size: 10pt;\n  }\n\n.mat-mdc-dialog-actions {\n    justify-content: center;\n    margin: 5pt;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
