import {Injectable} from '@angular/core';
import {BackendService} from '@global/services/backend.service';
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {EventPermissions} from '@shared/event-permissions';
import {hasOwn} from '@shared/utils';
import {Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class EventsPermissionsService {
    /**
     * Event, welches neue EventPermissions emitted, falls neue Permissions geladen wurden.
     */
    public permissionsChanged = new Subject<CWEvent<EventPermissions>>();

    private eventPermissions: EventPermissions = new EventPermissions();
    private loading = false;

    constructor(private backendService: BackendService) {}

    /**
     * Aktualisiert die Veranstaltungsberechtigungen nach einem Wechsel der Veranstaltung oder des Veranstaltungsstatus
     * @param eventId
     * @author Lennart Bentz <l.bentz@pharmakon.software>
     */
    public selectionChanged(eventId: number) {
        this.loading = true;

        // Als erstes Permissions resetten, damit nicht Permissions einer vorherigen Veranstaltung gültig bleiben
        this.eventPermissions = new EventPermissions();

        const getRequest$ = this.backendService.getRequest(
            'eventsWorkflow/getUserPermissionsByEvent/' + eventId,
        ) as Observable<CWResult<EventPermissions>>;

        getRequest$.subscribe((result: CWResult<EventPermissions>) => this.onBackendResult(result));
    }

    /**
     * Gibt den aktuellen Wert einer Veranstaltungsberechtigung aus.
     * @param {number} eventId              Event-ID für die die Permission gültig sein muss
     * @param {string} permissionName       Permission-ID die für die gewählte Veranstaltung geladen werden soll
     * @returns {string|number|boolean|null} Wert der Permission, null falls noch keine passenden Permissions vorliegen
     * @author Lennart Bentz <l.bentz@pharmakon.software>
     */
    public getPermissionValue(eventId: number, permissionName: string): string | number | boolean | null {
        if (
            !this.loading &&
            this.eventPermissions.event_id === eventId &&
            hasOwn(this.eventPermissions.permissions, permissionName)
        ) {
            return this.eventPermissions.permissions[permissionName].value;
        }
        return null;
    }

    /**
     * Lade alle geladenen Permissions, prüfe durch Angabe einer Event-ID, dass die aktuellen Permissions die gewünschten sind
     * @details Eine leere Antwort kann bedeuten, dass die Permissions noch geladen werden. Es muss auf das
     *          permissionsLoaded-Event subscribed werden um eine nachträgliche Aktualisierung der Permissions zu
     *          erhalten!
     * @param   {number} eventId
     * @returns  {EventPermissions} Permissions, leere Permissions wenn keine Permissions für diese ID vorliegen
     * @author Lennart Bentz <l.bentz@pharmakon.software>
     */
    public getAllCurrentPermissions(eventId: number): EventPermissions {
        if (!this.loading && this.eventPermissions.event_id === eventId) {
            return this.eventPermissions;
        }
        return new EventPermissions();
    }

    /**
     * Erzeuge einen Request um die Permissions eines nicht ausgewählten Events zu laden.
     * @param {number} eventId
     * @returns {Observable<CWResult<EventPermissions>>} Ein Observable, welches das Ergebnis des Requests zurückgeben wird
     * @author Lennart Bentz <l.bentz@pharmakon.software>
     */
    public getAllPermissionsRequest(eventId: number): Observable<CWResult<EventPermissions>> {
        return this.backendService.getRequest('eventsWorkflow/getUserPermissionsByEvent/' + eventId) as Observable<
            CWResult<EventPermissions>
        >;
    }

    private onBackendResult(result: CWResult<EventPermissions>) {
        if (result.success) {
            this.eventPermissions = result.data;
            this.loading = false;
            this.permissionsChanged.next({
                sender: 'events-permissions',
                target: '',
                data: this.eventPermissions,
            });
        }
    }
}
