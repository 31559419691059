// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
// Für Übersetzung benötigte Module
import {TranslateModule} from '@ngx-translate/core';
/*
 * Den Service des Moduls einbinden
 * Komponenten Einbinden
 */
import {CharacteristicGroupComponent} from './characteristic-group/characteristic-group.component';
import {CharacteristicGroupsListComponent} from './characteristic-groups-list/characteristic-groups-list.component';
// Shared Modules einbinden
import {InputModule} from '../../shared/input/input.module';
import {ToolbarModule} from '../../shared/toolbar/toolbar.module';
import {CharacteristicGroupsListPopupComponent} from './characteristic-groups-list-popup/characteristic-groups-list-popup.component';
import {CharacteristicSingleEditPopupComponent} from './characteristic-single-edit-popup/characteristic-single-edit-popup.component';
import {CharacteristicSingleEditComponent} from './characteristic-single-edit/characteristic-single-edit.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        InputModule,
        ToolbarModule,
        MatButtonModule,
        MatExpansionModule,
        MatCheckboxModule,
        TranslateModule,
    ],
    declarations: [
        CharacteristicGroupComponent,
        CharacteristicGroupsListComponent,
        CharacteristicGroupsListPopupComponent,
        CharacteristicSingleEditComponent,
        CharacteristicSingleEditPopupComponent,
    ],
    exports: [
        CharacteristicGroupComponent,
        CharacteristicGroupsListComponent,
        CharacteristicGroupsListPopupComponent,
        CharacteristicSingleEditComponent,
        CharacteristicSingleEditPopupComponent,
        TranslateModule,
    ],
})
export class CharacteristicsModule {}
