// Service für Übersetzungen über NGX-Translate
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
// // Angular-Module
import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// Interfaces für Structured Objects einbinden
import {LooseObject} from '@shared/loose-object';

// Interface für Dialogdaten
export interface DialogData {
    title: string;
    message: string;
    answer: string;
    listName?: string;
    listKey?: number;
    textArea?: boolean;
    buttonLabelYes?: string;
    buttonLabelNo?: string;
    textAreaRequired?: boolean;
    deleteComment?: any;
}

@Component({
    selector: 'phscw-popup-confirmation',
    templateUrl: './popup-confirmation.component.html',
    styleUrls: ['./popup-confirmation.component.scss'],
})
export class PopupConfirmationComponent implements OnInit, OnDestroy {
    // Referenz auf Form
    @ViewChild('popupConfirmForm', {static: false}) popupConfirmForm: NgForm;

    // Combobox nicht anzeigen
    allowListDisplay = false;
    // Textfeld nicht anzeigen
    allowTextareaDisplay = false;
    // Button "Ja" aktiv
    confirmButtonDisabled = false;
    // Anzuzeigender Button-Text
    buttonLabelYes = '';
    buttonLabelNo = '';

    // Daten der Eingabefelder
    formData: LooseObject = {};
    /**
     * Konstruktor (inkl. dependency injection)
     * @param dialogRef
     * @param data
     * @param translateService
     */
    constructor(
        public dialogRef: MatDialogRef<PopupConfirmationComponent>,
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private translateService: TranslateService,
    ) {
        dialogRef.disableClose = true;
    }

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Übersetzungen subscriben
        this.initializeTranslations();

        // Combobox anzeigen
        if (typeof this.data.listName !== 'undefined' && this.data.listName !== '') {
            // Combobox anzeigen
            this.allowListDisplay = true;
        }

        // Textfeld anzeigen
        if (typeof this.data.textArea !== 'undefined' && this.data.textArea !== false) {
            // Textfeld anzeigen
            this.allowTextareaDisplay = true;
        }

        // Falls Texte mitgegeben wurden, die anstatt "Ja" und "Nein" angezeigt werden sollen, verwende diese.
        if (typeof this.data.buttonLabelYes !== 'undefined' && typeof this.data.buttonLabelNo !== 'undefined') {
            this.buttonLabelYes = this.data.buttonLabelYes;
            this.buttonLabelNo = this.data.buttonLabelNo;
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * @brief   Übersetzungen laden
     * @author  Dominik Treutle <d.treutle@pharmakon.software>
     */
    initializeTranslations(): void {
        // Übersetzungen laden
        const translations = {
            yes: this.translateService.instant('GENERAL.YES'),
            no: this.translateService.instant('GENERAL.NO'),
        };

        // Übersetzungen zuweisen
        this.buttonLabelYes = translations.yes;
        this.buttonLabelNo = translations.no;
    }

    /**
     * Button im Dialog wurde angeklickt, Antwort merken und Dialog schließen
     * @param answer
     */
    clickAndClose(answer: string): void {
        // Antwort übernehmen
        this.data.answer = answer;
        // Dialog (über Referenz) schließen und Dialog-Ergebnis mitsenden
        this.dialogRef.close(this.data);
    }
}
