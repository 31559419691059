/**
 * @brief   Popup für Einzelbearbeitung eines Kennzeichens, z.B. auf der E-/P-Liste
 * @author  Tristan Krakau <t.krakau@pharmakon.software>
 */

// Angular-Module
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Shared Services importieren
import {ToolbarService} from '@shared/toolbar/toolbar.service';
/*
 * Service für das Kennzeichen-Modul
 * Interfaces für Structured Objects einbinden
 */
import {Characteristic} from '@shared/characteristic';
import {CWEvent} from '@shared/cw-event';

// Interface für Dialogdaten
export interface DialogData {
    entityId: number;
    baseEntityId?: number;
    characteristicId: number;
    characteristicType: string;
    characteristicLabel: string;
    characteristicDefinition: Characteristic;
    currentValue: any;
    onChangeCallback: any;
}

@Component({
    selector: 'phscw-characteristic-single-edit-popup',
    templateUrl: './characteristic-single-edit-popup.component.html',
    styleUrls: ['./characteristic-single-edit-popup.component.scss'],
})
export class CharacteristicSingleEditPopupComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // für Kennzeichenbearbeitung ausgewählte Entität
    entityId: number;
    baseEntityId: number;

    // Kennzeichen Definition und Wert, enthält alle Infos, auch Label, ID und Datentyp
    entityCharacteristics: Characteristic;

    // aktueller Wert des Kennzeichens, falls im weiteren Verlauf für Vergleich o.ä. benötigt
    originalValue: any;

    // Callback-Funktion, die nach dem Speichern aufgerufen wird, um Daten in Liste zu aktualisieren
    onChangeCallback: any;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param data
     * @param dialogRef
     * @param toolbarService
     */
    constructor(
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private dialogRef: MatDialogRef<CharacteristicSingleEditPopupComponent>,
        private toolbarService: ToolbarService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        /*
         * Daten für Template zwischenspeichern
         * IDs werden später zum Speichern der Änderungen benötigt
         */
        this.entityId = this.data.entityId;
        this.baseEntityId = this.data.baseEntityId;

        // Kennzeichen-Definition und aktuellen Wert zusammenbauen für Edit-Komponente
        this.entityCharacteristics = this.data.characteristicDefinition;
        this.originalValue = this.data.currentValue;
        this.entityCharacteristics.value = this.originalValue;
        this.onChangeCallback = this.data.onChangeCallback;
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions() {
        // Wenn der Close-Button der Toolbar ausgelöst wurde, Abbrechen auslösen.
        this.toolbarService.eventCloseComponent
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht für die eigene Komponente ist
                if (event.target !== 'characteristic-single-edit-popup') {
                    return;
                }
                this.clickClose();
            });
    }

    /**
     * @brief   Dialog schließen
     */
    clickClose(): void {
        this.dialogRef.close();
    }
}
