/*
 * export interface Institution {
 *
 *    id: number;
 *
 *    name1: string;
 *    name2?: string;
 *    name3?: string;
 *    name4?: string;
 *
 *    street?: string;
 *    street_number_from?: string;
 *    street_number_to?: string;
 *    zipcode?: string;
 *    city?: string;
 *    district?: string;
 *    country?: string;
 *
 *    phone1?: string;
 *    phone2?: string;
 *    phone3?: string;
 *    fax?: string;
 *    mail?: string;
 *    internet?: string;
 *
 *    erp_number?: string;
 *
 * }
 */

/*
 * export interface IInstitution {
 *    id: number;
 *    name1: string;
 * }
 */
import {ParentInstitution} from './parent-institution';

export class Institution {
    id = 0;
    icons = '';

    name1 = '';
    name2 = '';
    name3 = '';
    name4 = '';

    type1?: string = '';
    type2?: string = '';

    street?: string = '';
    street_number_from?: string = '';
    street_number_to?: string = '';
    zipcode?: string = '';
    city?: string = '';
    district?: string = '';
    country?: string = '';
    county?: string = '';
    phone1?: string = '';
    phone2?: string = '';
    phone3?: string = '';
    fax?: string = '';
    mail?: string = '';
    internet?: string = '';

    erp_number?: string = '';
    bga_number?: string = '';
    onekey_number?: string = '';
    permament_establishment_number?: string = '';
    health_insurance_association?: string = '';

    last_contact?: string = null;
    last_contact_method?: string = null;
    number_contacts?: number = null;
    number_contacts_last_year?: number = null;

    create_date?: Date;
    create_user?: string = '';
    edit_date?: Date;
    edit_user?: string = '';
    delete_date?: Date;
    delete_user?: string = '';
    deleted = 'N';

    // Institution-Icon (wird aktuell über Grid gesetzt)
    institution_icon?: string = '';

    parent_id?: number = null;
    parent?: ParentInstitution[] = [];

    /*
     * aktuelles Level in der Hierarchie und maximal erlaubtes Level, damit
     * Neuanlage von Abteilung mit zu tiefer Verschachtelung unterbunden werden kann
     */
    current_hierarchy_level?: number;
    max_hierarchy_level?: number;

    // Gebietszuordnungen
    regions?: any;
    // Sonder-Gebietszuordnungen
    manualregions?: any;

    // Segment-Daten
    segment_id1?: number;
    segment_name1?: string;
    segment_id2?: number;
    segment_name_2?: string;

    // Zusätzliche Emailadressen
    additionalemails?: any;

    external_customer_id1?: string;
    external_customer_id2?: string;
    // added for compiling reasons
    supplementary_numbers?: any;
    suppliers?: any;

    // Konstruktor
    constructor(id = 0) {
        this.id = id;
        this.name1 = '';
        this.name2 = '';
        this.name3 = '';
        this.name4 = '';
        this.manualregions = [];
    }

    /*
     * Date-Convert (da Datum, welche übers PHP-Backend kommen als String erkannt werden)
     * 2018-07-11, PhS(MFe): Wird das nun doch nicht mehr benötigt?
     */
    public dateConvert() {
        if (this.create_date) {
            if (typeof this.create_date === 'string') {
                this.create_date = new Date(this.create_date);
            }
        }
    }
}
