import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class InstitutionsProductAnalysisService {
    constructor(private backendService: BackendService) {}

    /**
     * Produkt-Umsatz-Daten der Einrichtung laden
     * @param id
     */
    loadData(id: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'InstitutionsProductAnalysis/details/' + id,
        );
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }
}
