export class SelectionFilter {
    characteristic_group_id: number;
    characteristic_id: number;
    characteristic_option_id: any;
    characteristic_type: string;
    comparison: string;
    comparison_name: string;
    datatype: string;
    db_field: string;
    frontendValue: any[];
    headerText: string;
    icon: string;
    importantCharacteristics: string;
    listkey: string;
    listname: string;
    multiOptions: any[];
    name: string;
    negation: boolean;
    parentName: string;
    target: string;
    value: any;
    value_type: string;
    number_type?: string; // needed for supplementary numbers

    // Konstruktor
    constructor() {
        this.characteristic_group_id = 0;
        this.characteristic_id = 0;
        this.characteristic_option_id = '';
        this.characteristic_type = '';
        this.comparison = '';
        this.comparison_name = '';
        this.datatype = '';
        this.db_field = '';
        this.frontendValue = [];
        this.headerText = '';
        this.icon = '';
        this.importantCharacteristics = '';
        this.listkey = '';
        this.listname = '';
        this.multiOptions = [];
        this.name = '';
        this.negation = false;
        this.parentName = '';
        this.target = '';
        this.value = {};
        this.value_type = '';
        this.number_type = '';
    }
}
