import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
// Service der übergeordneten Komponente
import {CharacteristicsService} from '@shared/characteristics/characteristics.service';
import {ToolbarService} from '../toolbar.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';

@Component({
    selector: 'phscw-toolbar-expand-characteristics',
    templateUrl: './toolbar-expand-characteristics.component.html',
    styleUrls: ['./toolbar-expand-characteristics.component.scss'],
})
export class ToolbarExpandCharacteristicsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;

    // Tooltip
    @Input() tooltip: string = null;
    // Icon
    @Input() icon = 'icon-shield';
    // Parameter
    @Input() parameter: any;

    // Flag ob Kennzeich auf- oder zugeklappt sind
    characteristicsOpenState = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param router
     * @param toolbarService
     * @param characteristicsService
     */
    constructor(
        private router: Router,
        private toolbarService: ToolbarService,
        private characteristicsService: CharacteristicsService,
    ) {}

    /*
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /*
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeEventSubscriptions(): void {
        // Auf Routenänderungen reagieren
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._componentDestroyed$),
            )
            .subscribe((route: NavigationEnd) => {
                // Daten zurücksetzen
                this.toggleExpand(false);
            });

        // Wenn die Daten neu geladen werden sollen
        this.characteristicsService.eventReloadCharacteristicsGroupsList
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Daten zurücksetzen
                this.toggleExpand(false);
            });
    }

    /*
     * Kennzeichen auf- und zuklappen
     */
    toggleExpand(resetValue: boolean = null) {
        if (resetValue !== null) {
            this.characteristicsOpenState = resetValue;
        } else {
            this.characteristicsOpenState = !this.characteristicsOpenState;
        }
        // Event auslösen
        this.toolbarService.toggleCharacteristics(this.componentIdentifier, this.characteristicsOpenState);
    }
}
