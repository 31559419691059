/**
 * @brief   Shared ContactsModule zur Freigabe der übergreifenden
 *          Kontakt-Komponenten.
 * @details Kontaktformular wurde in dieses Shared-Module ausgelagert,
 *          da das Kontaktformular z.B. bei Personen, Einrichtungen oder der
 *          Kontaktübersicht aufgerufen werden kann.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
// Für Übersetzung benötigte Module
import {TranslateModule} from '@ngx-translate/core';
// Service dieses Shared-Modules
import {ContactsService} from './contacts.service';
// Komponenten
import {ContactsDocumentsComponent} from './contacts-documents/contacts-documents.component';
import {ContactsFormPopupComponent} from './contacts-form-popup/contacts-form-popup.component';
import {ContactsFormContactComponent} from './contacts-form/contacts-form-contact/contacts-form-contact.component';
import {ContactsFormTicketComponent} from './contacts-form/contacts-form-ticket/contacts-form-ticket.component';
import {ContactsFormComponent} from './contacts-form/contacts-form.component';
import {ContactsHelpComponent} from './contacts-help/contacts-help.component';
import {ContactsHistoryComponent} from './contacts-history/contacts-history.component';
import {ContactsItemsOverviewComponent} from './contacts-items-overview/contacts-items-overview.component';
import {ContactsItemsComponent} from './contacts-items/contacts-items.component';
import {ContactsLinksComponent} from './contacts-links/contacts-links.component';
// Shared Modules einbinden
import {DocumentsModule} from '@shared/documents/documents.module';
import {GridModule} from '@shared/grid/grid.module';
import {CharacteristicsModule} from '../../shared/characteristics/characteristics.module';
import {InputModule} from '../../shared/input/input.module';
import {OutputModule} from '../../shared/output/output.module';
import {OverlayModule} from '../../shared/overlay/overlay.module';
import {ToolbarModule} from '../../shared/toolbar/toolbar.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatExpansionModule,
        InputModule,
        OverlayModule,
        ToolbarModule,
        OutputModule,
        TranslateModule,
        GridModule,
        DocumentsModule,
        CharacteristicsModule,
    ],
    declarations: [
        ContactsFormComponent,
        ContactsFormPopupComponent,
        ContactsItemsComponent,
        ContactsItemsOverviewComponent,
        ContactsFormContactComponent,
        ContactsFormTicketComponent,
        ContactsHistoryComponent,
        ContactsDocumentsComponent,
        ContactsLinksComponent,
        ContactsHelpComponent,
    ],
    // Komponenten freigeben
    exports: [
        ContactsFormComponent,
        ContactsFormPopupComponent,
        ContactsItemsOverviewComponent,
        ContactsHistoryComponent,
        ContactsDocumentsComponent,
        ContactsLinksComponent,
        ContactsHelpComponent,
        TranslateModule,
    ],
    providers: [ContactsService],
})
export class ContactsModule {}
