// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .mat-mdc-list {
  font-size: 1.4rem;
  padding: 0;
}

:host > div {
  background-color: inherit;
  box-shadow: none;
}

::ng-deep .accordion-toolbar-icon {
  cursor: pointer;
  margin: 0 2px 0 2px;
  font-size: 20px;
  color: #b0b0b0;
}

::ng-deep .accordion-toolbar-icon:hover {
  color: var(--color-accent);
}

::ng-deep .accordion-toolbar {
  margin-left: auto;
  margin-right: 10px;
}

::ng-deep .hide-icon {
  visibility: hidden;
}

::ng-deep .mat-expansion-panel-body {
  padding-bottom: 5px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/chart/chart-accordion/chart-accordion.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,UAAA;AACJ;;AAEA;EACI,yBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,eAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,8BAAA;AACJ","sourcesContent":[":host .mat-mdc-list {\n    font-size: 1.4rem;\n    padding: 0;\n}\n\n:host > div {\n    background-color: inherit;\n    box-shadow: none;\n}\n\n::ng-deep .accordion-toolbar-icon {\n    cursor: pointer;\n    margin: 0 2px 0 2px;\n    font-size: 20px;\n    color: #b0b0b0;\n}\n\n::ng-deep .accordion-toolbar-icon:hover {\n    color: var(--color-accent);\n}\n\n::ng-deep .accordion-toolbar {\n    margin-left: auto;\n    margin-right: 10px;\n}\n\n::ng-deep .hide-icon {\n    visibility: hidden;\n}\n\n::ng-deep .mat-expansion-panel-body {\n    padding-bottom: 5px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
