import {Directive, ElementRef, OnDestroy, OnInit, Renderer2, NgZone, Input} from '@angular/core';
import {Subject, fromEvent, switchMap, take, takeUntil, tap} from 'rxjs';

/**
 * Direktive, die auf das <th> Element einer Tabelle angewendet wird.
 * Ermöglicht das Ändern der Spaltenbreite durch Ziehen.
 * @example
 * <mat-table>
 *     ...
 *     <mat-header-cell *matHeaderCellDef phscwGridNewResizeableColumn>
 *         Spaltenkopf
 *     </mat-header-cell>
 *     ...
 * </table>
 */
@Directive({selector: '[phscwGridNewResizeableColumn]'})
export class GridNewResizeableColumnDirective implements OnInit, OnDestroy {
    /**
     * Optionales Input zur Angabe einer spezifischen Tabelle.
     */
    @Input() resizableTable: HTMLElement | null = null;

    /**
     * Optionales Input zur Steuerung, ob die Spalte resizable sein soll.
     * Standardmäßig ist die Spalte resizable
     */
    @Input() resizable: boolean = true;

    /**
     * Flag, das angibt, ob aktuell eine Größenänderung stattfindet.
     */
    private isResizing = false;

    /**
     * Startposition der Maus auf der X-Achse.
     */
    private startX!: number;

    /**
     * Anfangsbreite der Spalte in Pixel.
     */
    private startWidth!: number;

    /**
     * Referenz auf die betroffene Spalte (<th> Element).
     */
    private column: HTMLElement;

    /**
     * Referenz auf die übergeordnete Tabelle (<table> Element).
     */
    private table: HTMLElement | null = null;

    /**
     * Das Resizer-Element, das zum Ziehen verwendet wird.
     */
    private resizer!: HTMLElement;

    private destroy$ = new Subject<void>();

    /**
     * Mindestgröße der Spalte
     */
    private minWidth = 40;

    /**
     * Signalisiert das Ende der verschiebung
     */
    private resizeEnd$ = new Subject<void>();

    /**
     * Speichert die ID des geplanten requestAnimationFrame-Aufrufs.
     */
    private animationFrameId: number | null = null;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private zone: NgZone,
    ) {
        this.column = this.el.nativeElement;
    }

    ngOnInit() {
        if (this.resizable) {
            // Klasse hinzufügen für Spalten, die resizable sind
            this.renderer.addClass(this.column, 'resizable-column');

            this.table = this.resizableTable || this.findParentTable(this.column);
            if (!this.table) {
                console.error('Parent table not found for resizable columns');
                return;
            }

            this.createResizer();
            this.initializeResizeListener();
        } else {
            // Optionale Klasse für nicht resizable Spalten
            this.renderer.addClass(this.column, 'non-resizable-column');
        }
    }

    /**
     * Erstellt ein resize Element (div), dass rechts vom Spaltenkopf angezeigt wird
     * Zusätzlich werden Stile hinzugefügt.
     */
    private createResizer() {
        this.resizer = this.renderer.createElement('div');
        this.renderer.addClass(this.resizer, 'column-resizer');
        this.renderer.appendChild(this.column, this.resizer);
    }

    /**
     * Initialisiert die Listener fürs Resizing.
     * Die Listener werden außerhalb der Angular-Zone registriert, um die Change Detection nicht zu triggern
     */
    private initializeResizeListener() {
        this.zone.runOutsideAngular(() => {
            fromEvent(this.resizer, 'mousedown')
                .pipe(takeUntil(this.destroy$))
                .subscribe((e: Event) => this.onMouseDown(e as MouseEvent));
            fromEvent(document, 'mousemove')
                .pipe(takeUntil(this.destroy$))
                .subscribe((e: Event) => this.onMouseMove(e as MouseEvent));
            fromEvent(document, 'mouseup')
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => this.onMouseUp());
            /*
             * Listener, der nach dem Resizing das nächste Click-Event abfängt,
             * damit die sortierung o.Ä. nicht getriggert wird
             */
            this.resizeEnd$
                .pipe(
                    takeUntil(this.destroy$),
                    switchMap(() =>
                        fromEvent(document, 'click', {capture: true}).pipe(
                            take(1),
                            tap((event: Event) => {
                                event.preventDefault();
                                event.stopPropagation();
                            }),
                        )),
                )
                .subscribe();
        });
    }

    private onMouseDown(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();

        this.isResizing = true;
        this.startX = e.pageX;
        this.startWidth = this.column.offsetWidth;
        this.renderer.addClass(this.column, 'resizing');
        if (this.table) {
            this.renderer.addClass(this.table, 'resizing');
        }
    }

    private onMouseMove(e: MouseEvent) {
        if (!this.isResizing) return;
        e.preventDefault();

        if (this.animationFrameId) {
            // Wenn eine Animation schon läuft, wird die neue Anfrage ignoriert
            return;
        }

        this.animationFrameId = requestAnimationFrame(() => {
            // Veränderung der Mausposition entlang der X-Achse seit dem Start des Resizings
            const deltaX = e.pageX - this.startX;
            // Neue Breite der Spalte berechnen
            const newWidth = this.startWidth + deltaX;

            if (newWidth >= this.minWidth) {
                this.renderer.setStyle(this.column, 'width', `${newWidth}px`);
            } else {
                // Mindestbreite der Spalte setzen
                this.renderer.setStyle(this.column, 'width', `${this.minWidth}px`);
            }

            this.animationFrameId = null;
        });
    }

    private onMouseUp() {
        if (!this.isResizing) return;
        this.isResizing = false;

        // Ressourcen freigeben um die Animation flüssiger zu machen
        if (this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
            this.animationFrameId = null;
        }

        this.renderer.removeClass(this.column, 'resizing');
        if (this.table) {
            this.renderer.removeClass(this.table, 'resizing');
        }

        this.resizeEnd$.next();
    }

    /**
     * Sucht die übergeordnete Tabelle (<table> Element) des gegebenen Elements.
     * @param {HTMLElement} element - Das Start-Element.
     * @returns {HTMLElement | null} Die gefundene Tabelle oder null, falls keine gefunden wurde.
     */
    private findParentTable(element: HTMLElement): HTMLElement | null {
        let currentElement: HTMLElement | null = element;

        while (currentElement && currentElement.tagName !== 'TABLE') {
            currentElement = currentElement.parentElement;
            if (!currentElement) return null;
        }
        return currentElement;
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
