// Angular-Module
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Komponenten einbinden
import {GlobalAccountinfoPopupComponent} from './../global-accountinfo-popup/global-accountinfo-popup.component';
// Globale Services einbinden
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';

@Component({
    selector: 'phscw-global-accountinfo',
    templateUrl: './global-accountinfo.component.html',
    styleUrls: ['./global-accountinfo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalAccountinfoComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Pfad zum Usericon (wird durch loadUserIcon() überschrieben)
    userIcon = 'assets/img/icons/usericon_default.png';

    // Konstruktor
    constructor(
        private appCore: AppCoreService,
        private dialog: MatDialog,
        private backendService: BackendService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    // Initialisierung der Komponente
    ngOnInit() {
        // Custom User-Icon oder geschlechtsspezifisches Icon laden
        this.loadUserIcon();

        // Accountinfo-Popup bei Event "appLogout" schließen
        this.appCore.appLogout.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: any) => {
            this.dialog.closeAll();
        });
    }

    // Aufräumen
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    // Account-Info als Popup anzeigen
    showAccountinfo() {
        // Dialog initialisieren
        const dialogConfig = new MatDialogConfig();

        /*
         * dialogConfig.hasBackdrop = true;
         * dialogConfig.disableClose = false;
         * Nichts automatisch fokusieren, sonst wird Button "Einstellungen" fokusiert dargestellt
         */
        dialogConfig.autoFocus = false;

        // Dialog öffnen
        this.dialog.open(GlobalAccountinfoPopupComponent, dialogConfig);
    }

    /**
     * @brief   Zeige das entsprechende User-Icon an
     * @details Falls im entsprechenden Verzeichnis ein Icon gefunden wurde, dieses laden, ansonsten geschlechtsspezifisches Icon anzeigen.
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    loadUserIcon() {
        const serviceRequest$ = this.backendService.getRequestAsync('Accountinfo/getIcon');
        serviceRequest$.subscribe(
            (result) => {
                if ('sex' in result['data'] && typeof result['data']['sex'] === 'string') {
                    // Es wurde kein Employee-Icon gefunden, Unterscheidung auf Grundlage des Geschlechts
                    switch (result['data']['sex']) {
                        case '1':
                            this.userIcon = 'assets/img/icons/usericon_male.png';
                            break;
                        case '2':
                            this.userIcon = 'assets/img/icons/usericon_female.png';
                            break;
                        default:
                            this.userIcon = 'assets/img/icons/usericon_default.png';
                    }
                }
                // Change-Detection manuell auslösen
                this.changeDetector.detectChanges();
            },
            (error) => {
                // Keine Antwort vom Backend erhalten (Servererror)
            },
        );
    }
}
