/**
 * @brief   Prüfung auf Duplikate für Clearing
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */

// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Eigener Service
import {ClearingDuplicatesPanelService} from './../clearing-duplicates-panel/clearing-duplicates-panel.service';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {UserSettingsService} from '@global/services/user-settings.service';
// Services der Feature-Modulen (zu denen ggf. gewechselt werden soll)
import {InstitutionsService} from '@modules/institutions/institutions.service';
import {PeopleService} from '@modules/people/people.service';
// Shared Services
import {GridService} from '@shared/grid/grid.service';
// Shared Components
import {PopupLoadingComponent} from '@shared/popups/popup-loading/popup-loading.component';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {Institution} from '@shared/institution';
import {Person} from '@shared/person';
// Environment
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

@Component({
    selector: 'phscw-clearing-duplicates-panel',
    templateUrl: './clearing-duplicates-panel.component.html',
    styleUrls: ['./clearing-duplicates-panel.component.scss'],
})
export class ClearingDuplicatesPanelComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Entität
    @Input() entity: any = null;
    // Name des anzusprechenden Controllers
    @Input() backendController: string;
    // Übersetzungen der Buttons
    duplicateMessage = '';
    buttonCancel = '';
    buttonSelect = '';
    buttonMove = '';
    buttonCopy = '';
    buttonMerge = '';

    // Hinweis anzeigen
    possibleDuplicatesFound = false;
    // Mögliche Duplikate
    possibleDuplicatesData: any[] = [];
    // Ausgewählter Eintrag
    selectedDuplicate: any = null;

    // Grid ID (muss mit ID der Liste übereinstimmen)
    private _gridId = '';
    @Input()
    set gridId(value: string) {
        this._gridId = value;

        // Grid konfigurieren
        this.setGridConfiguration(value);
    }

    get gridId() {
        return this._gridId;
    }

    // Grid Konfiguration
    gridColumns = [];
    gridDisplayedColumns = [];

    // Spaltendefinitionen für Grid
    gridColumnsPeople = [
        {
            columnDef: 'person-institution-name',
            header: 'Einrichtung',
            cell: (element: Person) => {
                if (element.institutions && element.institutions[0]) {
                    if (element.institutions[0].name2) {
                        return `${element.institutions[0].name1 || ''}<br>${element.institutions[0].name2 || ''}`;
                    }
                    return `${element.institutions[0].name1 || ''}`;
                }
                return '';
            },
            formatWidth: '250px',
        },
        {
            columnDef: 'person-institution-address',
            header: 'Adresse',
            cell: (element: Person) => {
                if (element.institutions && element.institutions[0]) {
                    return `${element.institutions[0].street || ''} ${element.institutions[0].street_number_from || ''} ${
                        element.institutions[0].street_number_to || ''
                    }<br>${element.institutions[0].zipcode || ''} ${element.institutions[0].city || ''}`;
                }
                return '';
            },
            formatWidth: '200px',
        },
    ];

    gridColumnsInstitutions = [
        {
            columnDef: 'institution-address',
            header: 'Adresse',
            cell: (element: Institution) => `${element.street || ''} ${element.street_number_from || ''} ${element.street_number_to || ''}<br>${element.zipcode || ''} ${
                element.city || ''
            }`,
            formatWidth: '200px',
        },
        {
            columnDef: 'institution-communication',
            header: 'Telefon',
            headerSecondRow: 'E-Mail',
            cell: (element: Institution) => `${element.phone1 || ''}<br>${element.mail || ''}`,
            formatWidth: '200px',
        },
        {
            columnDef: 'institution-erp-number',
            header: 'Kundennummer',
            cell: (element: Institution) => `${element.erp_number || ''}`,
            formatWidth: '65px',
        },
    ];

    // Anzuzeigende Spalten für Grid
    gridDisplayedColumnsPeople = [
        'radio',
        'person-title-name',
        'person-institution-name',
        'person-institution-address',
    ];

    gridDisplayedColumnsInstitutions = [
        'radio',
        'institution-name',
        'institution-address',
        'institution-communication',
        'institution-erp-number',
    ];

    // Flag definiert ob Button angezeigt wird
    moveButtonVisible = false;
    copyButtonVisible = false;
    // Flag definiert ob gerade gespeichert wird
    saving = false;
    // Flag definiert, ob eine Suche durchgeführt wurde
    searchComplete = false;

    // Referenz auf Loading-Popup
    loadingPopupReference: MatDialogRef<PopupLoadingComponent>;

    // Hinweis auf kein gefundenes Duplikat aktivieren?
    enableUniquemessage: boolean = environment.enableDuplicateCheckUniqueMessage || false;
    uniqueMessage = '';

    /**
     * Konstruktor (inkl. dependency injection)
     * @param appCore
     * @param clearingDuplicatesPanelService
     * @param gridService
     * @param userSettingsService
     * @param institutionsService
     * @param peopleService
     * @param loadingDialog
     * @param translateService
     */
    constructor(
        private appCore: AppCoreService,
        private clearingDuplicatesPanelService: ClearingDuplicatesPanelService,
        private gridService: GridService,
        private userSettingsService: UserSettingsService,
        private institutionsService: InstitutionsService,
        private peopleService: PeopleService,
        private loadingDialog: MatDialog,
        private translateService: TranslateService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();

        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();

        // Daten zurücksetzen
        this.resetData();
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeTranslateSubscriptions(): void {
        this.translateService
            .stream(['GENERAL.INSTITUTION', 'GENERAL.ADDRESS', 'GENERAL.PHONE', 'GENERAL.MAIL', 'GENERAL.ERP'])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: string[]) => {
                this.gridColumnsPeople.find((column: any) => column.columnDef === 'person-institution-name').header =
                    translation['GENERAL.INSTITUTION'];
                this.gridColumnsPeople.find((column: any) => column.columnDef === 'person-institution-address').header =
                    translation['GENERAL.ADDRESS'];

                this.gridColumnsInstitutions.find((column: any) => column.columnDef === 'institution-address').header =
                    translation['GENERAL.ADDRESS'];
                this.gridColumnsInstitutions.find(
                    (column: any) => column.columnDef === 'institution-communication',
                ).header = translation['GENERAL.PHONE'];
                this.gridColumnsInstitutions.find(
                    (column: any) => column.columnDef === 'institution-communication',
                ).headerSecondRow = translation['GENERAL.MAIL'];
                this.gridColumnsInstitutions.find(
                    (column: any) => column.columnDef === 'institution-erp-number',
                ).header = translation['GENERAL.ERP'];
            });
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Daten wurden geändert und müssen auf Duplikate geprüft werden
        this.clearingDuplicatesPanelService.eventDuplicatesCheckRequired
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                if (event.sender !== this.entity.id) {
                    return;
                }
                this.onEventDuplicatesCheckRequired(event);
            });

        // Event des Grid bei Klick einer Checkbox
        this.gridService.eventGridRadioClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                if (event.sender !== this.gridId) {
                    return;
                }
                this.onEventGridRadioClicked(event);
            });

        // Event "eventGridSelectionChanged" von "gridService"
        this.gridService.eventGridSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                if (!event.sender.includes('Duplicate') && event.sender.endsWith('List')) {
                    this.onSelectionChanged();
                }
            });
    }

    /**
     * @param event
     * @brief   Auf Event "eventDuplicatesCheckRequired" von "clearingDuplicatesPanelService" reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventDuplicatesCheckRequired(event: CWEvent): void {
        // Flag zurücksetzen
        this.searchComplete = false;

        // Daten
        const formData = event.data;

        // Namen aktualisieren
        let updateTranslations = null;
        if (this.selectedDuplicate !== null) {
            updateTranslations = 'text';
        }
        this.setTranslations(this.entity, updateTranslations);

        // Duplikate finden
        const serviceRequest$ = this.clearingDuplicatesPanelService.loadData(this.backendController, formData);
        serviceRequest$.subscribe(
            (result: CWResult) => {
                // Flag setzen
                this.searchComplete = true;

                // Nur bei Erfolg
                if (result['success'] && result['data'].length > 0) {
                    // Hinweis einblenden
                    this.possibleDuplicatesFound = true;

                    // Daten zwischenspeichern
                    this.possibleDuplicatesData = JSON.parse(JSON.stringify(result['data']));
                } else {
                    // Hinweis einblenden
                    this.possibleDuplicatesFound = false;
                }

                // Sender mitteilen, dass die Prüfung abgeschlossen wurde
                this.clearingDuplicatesPanelService.duplicatesCheckComplete(event.sender, {module: event.data.table});
            },
            (error: any) => {
                // Sender mitteilen, dass die Prüfung abgeschlossen wurde
                this.clearingDuplicatesPanelService.duplicatesCheckComplete(event.sender, {module: event.data.table});
            },
        );
    }

    /**
     * @param event
     * @brief   Auf Event "eventGridRadioClicked" von "gridService" reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventGridRadioClicked(event: CWEvent): void {
        // Die geklickte Reihe in Variable speichern.
        this.selectedDuplicate = event.data.selection[0];

        // Namen aktualisieren
        this.setTranslations(this.selectedDuplicate, 'radio');
    }

    /**
     * @brief   Auf geänderte Auswahl reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onSelectionChanged(): void {
        // Daten zurücksetzen
        this.resetData();
    }

    /**
     * @param listname
     * @brief   Grid konfigurieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    setGridConfiguration(listname: string): void {
        switch (listname) {
            case 'peopleDuplicateList':
                this.gridColumns = this.gridColumnsPeople;
                if (hasOwn(environment, 'duplicatePanelPeopleDisplayedColumns')) {
                    this.gridDisplayedColumns = environment.duplicatePanelPeopleDisplayedColumns;
                } else {
                    this.gridDisplayedColumns = this.gridDisplayedColumnsPeople;
                }
                this.moveButtonVisible = environment.enableDuplicateCheckPeopleMove;
                this.copyButtonVisible = environment.enableDuplicateCheckPeopleCopy;
                break;

            case 'institutionsDuplicateList':
                this.gridColumns = this.gridColumnsInstitutions;
                if (hasOwn(environment, 'duplicatePanelInstitutionsDisplayedColumns')) {
                    this.gridDisplayedColumns = environment.duplicatePanelInstitutionsDisplayedColumns;
                } else {
                    this.gridDisplayedColumns = this.gridDisplayedColumnsInstitutions;
                }
                break;

            default:
                break;
        }
    }

    /**
     * @brief   Daten zurücksetzen (z.B. wenn Person gewechselt wird)
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    resetData(): void {
        this.possibleDuplicatesFound = false;
        this.possibleDuplicatesData = [];
        this.selectedDuplicate = null;
        this.searchComplete = false;
    }

    /**
     * @brief   Duplikat-Anzeige schließen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickCancel(): void {
        this.resetData();
    }

    /**
     * @brief   Duplikat öffnen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickSelect(): void {
        // Auswahl-Liste herausfinden und anzeigen
        const target = this.gridId.replace('DuplicateList', '');
        this.prepareNavigation(target, this.selectedDuplicate['id']);
    }

    /**
     * @brief   Personen Datensatz bearbeiten
     * @param   action      'move_main_institution_id' oder 'add_secondary_institution_id' für Backend
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickEdit(action: string): void {
        // Flag "saving" aktivieren
        this.saving = true;

        const formData = this.setJoinData(action);

        // Neue Zuordnung für Person("Duplikat") speichern
        const serviceRequest$ = this.clearingDuplicatesPanelService.saveData(formData.id, formData);
        serviceRequest$.subscribe((result: CWResult) => {
            // Flag "saving" deaktivieren
            this.saving = false;

            // Nach erfolgreichem Speichern Datensatz laden
            if (result['success']) {
                // Auswahl-Liste herausfinden und anzeigen
                const target = this.gridId.replace('DuplicateList', '');
                this.prepareNavigation(target, this.selectedDuplicate['id']);
            }
        });
    }

    /**
     * @brief   Bearbeitete und ausgewählte Entität zusammenführen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickMerge(): void {
        // Prüfen, ob ein Duplikat ausgewählt wurde
        if (this.selectedDuplicate === null) {
            return;
        }

        // Daten aufbereiten
        const formData = {
            selectedEntities: [this.entity, this.selectedDuplicate],
            entityType: this.gridId.replace('DuplicateList', ''), // Teil des Strings entfernen, damit people oder institutions übergeben wird
        };

        // Event auslösen, um Daten an andere Komponente zum Öffnen des Merge-Popup zu übergeben
        this.clearingDuplicatesPanelService.mergeSelectedDuplicate(formData);
    }

    /**
     * @brief   Das Loading-Popup wird verwendet um weitere User-Eingaben
     *          zu verhindern, während Daten im Hintergrund vorbereitet werden
     * @param   show boolean    <true> Zeige Popup
     *                          <false> Schließe Popup
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    loadingPopup(show: boolean): void {
        if (show === true) {
            // Popup öffnen
            this.loadingPopupReference = this.loadingDialog.open(PopupLoadingComponent, {
                width: '250px',
                data: {},
            });
        } else {
            // Popup schließen
            this.loadingPopupReference.close();
        }
    }

    /**
     * @brief   Navigiere zu einer Person oder Einrichtung
     * @param   target string   'people' oder 'institutions'
     * @param   id number       ID der Person / der Einrichtung
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    prepareNavigation(target: string, id: number): void {
        // Lade-Popup zeigen
        this.loadingPopup(true);

        // USERSETTING: Datenquelle der Ziel-Liste auf "Auswahl" (KEY = 40) setzen
        this.userSettingsService.setValue(target + 'ListSource', '40');

        // Filter-Funktion ist abhängig vom Ziel
        let serviceRequest$: Observable<any>;
        if (target === 'people') {
            serviceRequest$ = this.institutionsService.executeFilterPerson(id);
        } else if (target === 'institutions') {
            serviceRequest$ = this.peopleService.executeFilterInstitution(id, true);
        } else {
            this.loadingPopup(false);
            return;
        }
        // Starte Filterung im Backend
        serviceRequest$.subscribe((result: CWResult) => {
            this.executeNavigation(target);
        });
    }

    /**
     * @brief   Vorbereitungen sind abgeschlossen, Navigation wird durchgeführt
     * @param   target string   'people' oder 'institutions'
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    executeNavigation(target: string): void {
        // Lade-Popup schließen
        this.loadingPopup(false);

        // EVENT: Datenquelle der Ziel-Liste auf "Auswahl" (KEY = 40) setzen
        const eventData: CWEvent = {
            sender: 'clearing-duplicates-panel',
            target: target + 'List',
            data: {
                listKey: '40',
                selectRowAfterFinished: 'first',
            },
        };
        this.appCore.changeGridSource.next(eventData);

        // Navigation zur P-Liste ausführen
        this.appCore.crossModuleNavigation(target, {});
    }

    /**
     * @param assignmentType
     * @brief   Einrichtungsspezifische Daten für Personen setzen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private setJoinData(assignmentType: string): any {
        // bestehende Daten als Startpunkt verwenden
        const entityData = JSON.parse(JSON.stringify(this.selectedDuplicate));

        // ID für neue Verbindung hinzufügen
        entityData[assignmentType] = this.entity.institutions[0]['id'];

        // allgemeine Daten hinzufügen
        if (hasOwn(this.entity.institutions[0], '_joinData')) {
            entityData['joinData__person_function'] = this.entity.institutions[0]._joinData.person_function;
            entityData['joinData__phone1'] = this.entity.institutions[0]._joinData.phone1;
            entityData['joinData__phone2'] = this.entity.institutions[0]._joinData.phone2;
            entityData['joinData__phone3'] = this.entity.institutions[0]._joinData.phone3;
            entityData['joinData__fax'] = this.entity.institutions[0]._joinData.fax;
            entityData['joinData__mail'] = this.entity.institutions[0]._joinData.mail;
            entityData['joinData__internet'] = this.entity.institutions[0]._joinData.internet;
            if (
                this.entity.institutions[0]._joinData.flagAutomatic !== null &&
                typeof this.entity.institutions[0]._joinData.flagAutomatic !== 'undefined' &&
                this.entity.institutions[0]._joinData.flagAutomatic === false
            ) {
                entityData['joinData__flagAutomatic'] = false;
                entityData['joinData__addressfield'] = this.entity.institutions[0]._joinData.addressfield;
            }
        }

        // notwendige Daten hinzufügen
        if (this.selectedDuplicate.institutions.length > 0) {
            entityData['main_institution_id'] = this.selectedDuplicate.institutions[0]['id'];
        } else {
            entityData['main_institution_id'] = this.entity.institutions[0]['id'];
        }

        // remove institutions data to just add new relations on save
        delete entityData['institutions'];

        // angepasste Daten zurückgeben
        return entityData;
    }

    /**
     * @param data
     * @param changedInputType
     * @brief   Anzeigenamen setzen
     * @todo    null auf button
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private setTranslations(data: any, changedInputType: string = null): void {
        // Namen für Übersetzungen
        const names = {
            entity: '',
            institution: '',
            current: '',
        };

        // TODO null auf button
        switch (this.gridId) {
            case 'peopleDuplicateList':
                names.entity = '"' + ((data.firstname || '') + ' ' + (data.lastname || '')).trim() + '"';
                names.current = '"' + ((this.entity.firstname || '') + ' ' + (this.entity.lastname || '')).trim() + '"';
                if (hasOwn(this.entity, 'institutions') && this.entity.institutions.length > 0) {
                    names.institution = '"' + (this.entity.institutions[0].name1 || '') + '"';
                }
                break;

            case 'institutionsDuplicateList':
                names.entity = '"' + ((data.name1 || '') + ' ' + (data.name2 || '')).trim() + '"';
                names.current = '"' + ((this.entity.name1 || '') + ' ' + (this.entity.name2 || '')).trim() + '"';
                break;

            default:
                break;
        }

        // Texte übersetzen
        const translation = this.translateService.instant(
            [
                'SHARED.CLEARING.DUPLICATESPANEL.DUPLICATESFOUND',
                'SHARED.CLEARING.DUPLICATESPANEL.NODUPLICATESFOUND',
                'SHARED.CLEARING.DUPLICATESPANEL.CANCEL',
                'SHARED.CLEARING.DUPLICATESPANEL.SELECT',
                'SHARED.CLEARING.DUPLICATESPANEL.SELECTINITIAL',
                'SHARED.CLEARING.DUPLICATESPANEL.MOVE',
                'SHARED.CLEARING.DUPLICATESPANEL.COPY',
                'SHARED.CLEARING.DUPLICATESPANEL.MERGE',
            ],
            names,
        );
        this.duplicateMessage = translation['SHARED.CLEARING.DUPLICATESPANEL.DUPLICATESFOUND'];
        this.uniqueMessage = translation['SHARED.CLEARING.DUPLICATESPANEL.NODUPLICATESFOUND'];

        // Namen in den Buttons aktualisieren
        if (changedInputType === 'text') {
            this.buttonCancel = translation['SHARED.CLEARING.DUPLICATESPANEL.CANCEL'];
        } else if (changedInputType === 'radio') {
            this.buttonSelect = translation['SHARED.CLEARING.DUPLICATESPANEL.SELECT'];
            this.buttonMove = translation['SHARED.CLEARING.DUPLICATESPANEL.MOVE'];
            this.buttonCopy = translation['SHARED.CLEARING.DUPLICATESPANEL.COPY'];
            this.buttonMerge = translation['SHARED.CLEARING.DUPLICATESPANEL.MERGE'];
        } else {
            this.buttonCancel = translation['SHARED.CLEARING.DUPLICATESPANEL.CANCEL'];
            this.buttonSelect = translation['SHARED.CLEARING.DUPLICATESPANEL.SELECTINITIAL'];
            this.buttonMove = translation['SHARED.CLEARING.DUPLICATESPANEL.SELECTINITIAL'];
            this.buttonCopy = translation['SHARED.CLEARING.DUPLICATESPANEL.SELECTINITIAL'];
            this.buttonMerge = translation['SHARED.CLEARING.DUPLICATESPANEL.SELECTINITIAL'];
        }
    }
}
