// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  margin: 1rem;
}

:host,
:host phscw-clearing-display,
:host phscw-clearing-display ::ng-deep phscw-grid {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
}

:host .cw-merge-error {
  color: var(--color-accent);
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/clearing/clearing-merge-popup/clearing-merge-popup.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;AACJ;;AAEA;;;EAGI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;AACJ;;AAEA;EACI,0BAAA;EACA,iBAAA;AACJ","sourcesContent":[":host {\n    height: 100%;\n    margin: 1rem;\n}\n\n:host,\n:host phscw-clearing-display,\n:host phscw-clearing-display ::ng-deep phscw-grid {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    min-height: 0;\n}\n\n:host .cw-merge-error {\n    color: var(--color-accent);\n    text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
