// Angular-Module
import {Component, OnDestroy, OnInit} from '@angular/core';
// Globale Services einbinden
import {UserSettingsService} from '@global/services/user-settings.service';

@Component({
    selector: 'phscw-global-settings-changecolortheme',
    templateUrl: './global-settings-changecolortheme.component.html',
    styleUrls: ['./global-settings-changecolortheme.component.scss'],
})
export class GlobalSettingsChangecolorthemeComponent implements OnInit, OnDestroy {
    // Definiert, ob Darkmode aktiv ist
    settingsDarkmodeChecked = false;
    // Definiert Farbwerte des Theme
    settingsColorValuePrimary = '';
    settingsColorValueAccent = '';

    // Konstruktor (inkl. dependency injection)
    constructor(private userSettingsService: UserSettingsService) {}

    // Initialisierung der Komponente
    ngOnInit() {
        // Einstellungen laden
        this.loadUserSettings();
    }

    // Aufräumen
    ngOnDestroy() {}

    // Darkmode wechseln
    toggleDarkmode() {
        // Wert wechseln
        this.settingsDarkmodeChecked = !this.settingsDarkmodeChecked;

        // Referenz auf Element laden und Klasse hinzufügen
        const body = document.body;
        body.classList.toggle('darkmode');

        // Einstellung speichern
        this.saveUserSettings();
    }

    // Einstellungen des Benutzers aus DB laden
    loadUserSettings() {
        this.settingsDarkmodeChecked = this.userSettingsService.getValue('themeDarkmodeEnabled');
        /*
         * this.settingsColorValuePrimary = this.userSettingsService.getValue('themeColorValuePrimary');
         * this.settingsColorValueAccent = this.userSettingsService.getValue('themeColorValueAccent');
         */
    }

    // Einstellungen des Benutzers in DB speichern
    saveUserSettings() {
        this.userSettingsService.setValue<boolean>('themeDarkmodeEnabled', this.settingsDarkmodeChecked);
        /*
         * this.userSettingsService.setValue<boolean>('themeColorValuePrimary', this.settingsColorValuePrimary);
         * this.userSettingsService.setValue<boolean>('themeColorValueAccent', this.settingsColorValueAccent);
         */
    }
}
