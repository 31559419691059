/**
 * Ausgelagerte Grid-Unterkomponente zur Anzeige der Namensspalte in der Umsatzanalyse
 * @author Michael Schiffner <m.schiffner@pharmakon.software>
 */

// Angular-Module
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatCellDef, MatColumnDef, MatHeaderCellDef, MatTable} from '@angular/material/table';
// Services anderer Shared-Modules
import {OverlayService} from './../../../overlay/overlay.service';
// GridComponent
import {GridComponent} from './../grid.component';
// Shared Components
import {OverlayInfoInstitutionComponent} from '@shared/overlay/overlay-info/overlay-info-institution/overlay-info-institution.component';

@Component({
    selector: 'phscw-grid-sales-analysis-name',
    templateUrl: './grid-sales-analysis-name.component.html',
    styleUrls: ['./grid-sales-analysis-name.component.scss', './../grid.component.scss'],
})
export class GridSalesAnalysisNameComponent implements OnInit, OnDestroy {
    // Referenz auf verbundene Grid-Komponente, da diese Grid-Erweiterung nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;

    // Spalten-Name der auf die Spalte verweist
    @Input()
    get name(): string {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
        if (this.columnDef) {
            this.columnDef.name = name;
        }
    }

    _name: string;

    @ViewChild(MatColumnDef, {static: true}) columnDef: MatColumnDef;
    @ViewChild(MatHeaderCellDef, {static: true}) headerCellDef: MatHeaderCellDef;
    @ViewChild(MatCellDef, {static: true}) cellDef: MatCellDef;

    constructor(
        private overlayService: OverlayService,
        public table: MatTable<any>,
    ) {}

    // Initialisieren
    ngOnInit() {
        if (this.table) {
            this.columnDef.name = this._name;
            this.columnDef.headerCell = this.headerCellDef;
            this.columnDef.cell = this.cellDef;
            this.table.addColumnDef(this.columnDef);
        }
    }

    // Aufräumen
    ngOnDestroy() {
        if (this.table) {
            this.table.removeColumnDef(this.columnDef);
        }
    }

    /**
     * Overlay-Info in Dialog anzeigen für Personen oder Einrichtungen
     * @param event
     * @param backendController
     * @param entityId
     * @author  Sena Üner <s.uener@pharmakon.software>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openOverlayInfoDialog(event: any, backendController: string, entityId: number) {
        // Dialog öffnen
        const dialogRef = this.overlayService.openOverlayInfoDialog(
            event.target,
            OverlayInfoInstitutionComponent,
            backendController,
            'details',
            entityId,
        );

        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe();
    }
}
