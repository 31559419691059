import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Interfaces für Structured Objects einbinden
import {GlobalRegionsfilterService} from '@global/components/global-regionsfilter/global-regionsfilter.service';
import {CWEvent} from '@shared/cw-event';

import {ChartService} from './../chart.service';

@Component({
    selector: 'phscw-chart-top-five-worst-five',
    templateUrl: './chart-top-five-worst-five.component.html',
    styleUrls: ['./chart-top-five-worst-five.component.scss'],
})
export class ChartTopFiveWorstFiveComponent implements OnInit {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    public activeRouteName: string;

    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    top: any[] = [];
    worst: any[] = [];
    complete: any[] = [];
    loading = false;
    dataExist = true;

    // Spaltendefinitionen für Grid
    gridColumns = [
        {
            columnDef: 'institution-id',
            header: 'ID',
            cell: (element: any) => `${element.institution_id}`,
        },
        {
            columnDef: 'growth',
            header: 'Abweichung',
            headerSecondRow: 'Jahr %',
            cell: (element: any) => `${element.growth}`,
            formatTemplate: 'percent',
            formatWidth: '65px',
        },
        {
            columnDef: 'absolute',
            header: 'Abweichung',
            headerSecondRow: 'Jahr absolut',
            cell: (element: any) => `${element.absolute}`,
            formatTemplate: 'currency',
            formatWidth: '65px',
        },
        {
            columnDef: 'monthly-growth',
            header: 'Abweichung',
            headerSecondRow: 'Monat %',
            cell: (element: any) => `${element.monthlyGrowth}`,
            formatTemplate: 'percent',
            formatWidth: '65px',
        },
        {
            columnDef: 'monthly-absolute',
            header: 'Abweichung',
            headerSecondRow: 'Monat absolut',
            cell: (element: any) => `${element.monthlyAbsolute}`,
            formatTemplate: 'currency',
            formatWidth: '65px',
        },
    ];

    // Anzuzeigende Spalten für Grid
    gridDisplayedColumns = ['institution-name', 'monthly-growth', 'monthly-absolute', 'growth', 'absolute'];

    constructor(
        private chartService: ChartService,
        private regionsfilterService: GlobalRegionsfilterService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.loading = true;
        // Events subscriben
        this.initializeEventSubscriptions();
        this.loadData(undefined);
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Wenn Regionsfilter geändert wird
        this._subscriptions.add(
            this.regionsfilterService.eventRegionsfilterChanged.subscribe((result: any) => {
                this.onEventRegionsfilterChanged(result);
            }),
        );

        // Auf Routenänderungen reagieren
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._componentDestroyed$),
            )
            .subscribe((route: NavigationEnd) => {
                // Aktive Route manuell updaten (für visuelle Hervorherbung im Menü, noch bevor gespeichert wurde)
                this.activeRouteName = route.url.toString().replace('/', '');
                // eventuelle Child-route finden und entfernen
                this.activeRouteName = this.activeRouteName.replace(/\/.*/, '');

                if (route.url.toString() == '/dashboards') {
                    this.loadData(undefined);
                }
            });
    }

    onEventRegionsfilterChanged(result) {
        const event: CWEvent = result;

        if (event.target == 'dashboards' && event.data['refreshGrid']) {
            this.loadData(result);
        }
    }

    loadData(result: any) {
        this.loading = true;

        let completeChartOptions = null;
        if (result !== undefined) {
            completeChartOptions = {
                // session storage gesetzte division region auslesen
                regions: result['data'],
            };
        } else {
            completeChartOptions = {regions: undefined};
        }

        const serviceRequest$ = this.chartService.loadData('ChartSales/getTopFiveWorstFive/', completeChartOptions);
        serviceRequest$.subscribe((result: any) => {
            this.top = result['data']['top'];

            this.worst = result['data']['last'];

            this.complete = this.top.concat(this.worst);

            // falls keine daten vorhanden sind
            if (this.complete === undefined || this.complete.length == 0) {
                this.dataExist = false;
            } else {
                this.dataExist = true;
            }

            this.loading = false;
        });
    }
}
