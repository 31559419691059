import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectData} from '@shared/select-data';
import {Subject} from 'rxjs';

export interface SelectDataGroup {
    disabled?: boolean;
    name: string;
    selectData: SelectData[];
}

/**
 * @brief   MatSelect-basiertes Dropdown, das gruppierte Auswahllisten nutzt
 * @details Nutzung angelehnt am Standard input-select, momentan noch rudimentär und auf Verwendung im Tour-Editor zugeschnitten.
 * @todo    Für generellen Einsatz in anderen Bereichen erweitern, z.B. non-editMode, auch flache Listen ohne Gruppen unterstützen, ...
 * @author Tristan Krakau <t.krakau@pharmakon.software>
 */
@Component({
    selector: 'phscw-input-select-groups',
    templateUrl: './input-select-groups.component.html',
    styleUrls: ['./input-select-groups.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputSelectGroupsComponent),
            multi: true,
        },
    ],
})
export class InputSelectGroupsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // EditMode aktiv?
    @Input() editMode = false;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string;

    // Model "myValue" mit GETTER & SETTER
    private _myValue = '';
    @Input() get myValue() {
        return this._myValue;
    }

    set myValue(value) {
        this._myValue = value;
        this.propagateChange(this._myValue);
    }

    // Flag definiert ob Readonly ausgewählt wurde
    selectedReadonly = false;
    // Flag definiert ob Readonly Werte verändert werden dürfen
    @Input() disableReadonly = false;

    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    propagateChange = (_: any) => {};
    // Attribut: required = Pflichtfeld (ja / nein)
    @Input() required = false;
    // Attribut: disabled = Gesperrt (ja / nein)
    @Input() disabled = false;
    // Soll der Löschbutton angezeigt werden
    @Input() showDeleteButton = false;
    // In Ansichtsmodus verstecken, wenn leer?
    @Input() hideEmpty = true;

    // Beschriftung / Label der aktuell ausgewählten Selectbox-Option
    selectedLabel: string;

    // Event-Emitter, falls der Wert gelöscht wurde
    @Output() deleteClicked = new EventEmitter<any>();

    // pokemonControl = new FormControl();
    @Input() selectDataGroups: SelectDataGroup[] = [];

    constructor() {}

    ngOnInit() {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Interface ControlValueAccessor: writeValue
     * @param value
     */
    writeValue(value: any): void {
        if (value !== undefined) {
            this.myValue = value;
        }
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}

    /**
     * Lösche den Selection-Wert
     */
    deleteValue(): void {
        this.myValue = null;
        this.deleteClicked.emit();
    }
}
