// Angular-Module
import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-output-timestamp',
    templateUrl: './output-timestamp.component.html',
    styleUrls: ['./output-timestamp.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutputTimestampComponent implements OnInit, OnDestroy {
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string;
    // Timestamp
    @Input() timestamp: Date;
    // Additional
    @Input() additional = '';

    // Konstruktor
    constructor() {}

    // Initialisierungen
    ngOnInit() {}

    // Aufräumen
    ngOnDestroy() {}
}
