import {Injectable} from '@angular/core';
import {BackendService} from '@global/services/backend.service';
import {firstValueFrom, Observable, Subject} from 'rxjs';
import {GridNewOptions} from '@shared/grid-new-options';
import {GridEvent, GridEventType} from './grid-new-event-types';
import {TableColumn} from '@shared/table-column';
import {
    COLUMN_ACTION,
    COLUMN_CURRENCY,
    COLUMN_DATE,
    COLUMN_DECIMAL,
    COLUMN_INTEGER,
    COLUMN_LISTENTRY,
    COLUMN_PERCENTAGE,
    COLUMN_TAG,
    TableColumnMode,
} from './grid-new-modes';
import {FilterData} from '@shared/filter-data';
import {notEmpty, notEmptyObject} from '@shared/utils';

import {ExtractorFunctionRegistry} from '@shared/models/transformer/extractor';
import {DataLoadService} from '@global/services/data-load.service';
import {Sort} from '@angular/material/sort';

@Injectable({providedIn: 'root'})
export class GridNewService<T> {
    constructor(
        private backendService: BackendService,
        private dataLoadService: DataLoadService,
    ) {}

    /*
     * Generelles Event-Subject, damit andere Komponenten darauf reagieren können.
     * @TODO: doku
     */
    private gridEvent = new Subject<GridEvent<T>>();
    public events$ = this.gridEvent.asObservable();
    private baseRoute = 'crud/'; // TODO: algemeine route

    /**
     * Löst ein Event aus, damit andere Komponenten darauf reagieren können.
     * @param {GridEvent} event Event
     */
    emitEvent(event: GridEvent<T>): void {
        this.gridEvent.next(event);
    }

    // Checkbox-Auswahl leeren
    clearCheckboxSelection(target: string) {
        const type = GridEventType.ClearCheckboxSelection;
        const sender = '';

        const event: GridEvent<T> = {
            sender,
            type,
            target,
        };

        // Event auslösen
        this.gridEvent.next(event);
    }

    /**
     * Lade die Daten fürs Grid
     * @param {string} gridDataBackendUrl Request URL
     * @param {GridNewOptions} gridNewOptions Zusätzliche Grid-Optionen fürs Backend
     * @param {boolean} batchLoadAll true = Lade alle Daten in mehreren Batches (sinnvoll bei großen Datenmengen)
     * @returns {Observable<unknown>} Observable mit den geladenen Daten
     */
    loadData(
        gridDataBackendUrl: string,
        gridNewOptions: GridNewOptions,
        batchLoadAll: boolean = false,
    ): Observable<unknown> {
        // POST-Request über BackendService senden
        return batchLoadAll && notEmptyObject(gridNewOptions.pagination)
            ? this.dataLoadService.getEntireList<T>(gridDataBackendUrl, gridNewOptions) // load big data in batches
            : this.backendService.postRequest(gridDataBackendUrl, gridNewOptions);
    }

    /**
     * Ein Datensatz des Grids wurde geändert.
     * @template T Datentyp
     * Ein Event wird ausgelöst, damit andere Komponenten darauf reagieren können.
     * @param {string} sender Sender-Komponente
     * @param {string} target Target-Komponente
     * @param {T} entity Geänderte Entity
     */
    dataChanged(sender: string, target: string, entity: T) {
        const type = GridEventType.DataChanged;

        const event: GridEvent<T> = {
            sender,
            type,
            target,
            entity,
        };

        // Event auslösen
        this.gridEvent.next(event);
    }

    /**
     * Ein Datensatz oder mehrere Datensätze des Grids sollen gelöscht werden.
     * Ein Event wird ausgelöst, damit andere Komponenten darauf reagieren können.
     * @template T Entität
     * @param {string} sender Sender-Komponente
     * @param {string} target Target-Komponente
     * @param {T|T[]} entitiesToDelete  Entitäten, die gelöscht werden sollen.
     */
    dataDeleted(sender: string, target: string, entitiesToDelete: T | T[]) {
        const type = GridEventType.DataDeleted;

        // entitiesToDelete immer als Array vorbereiten, auch wenn es nur eine einzelne Entität ist
        const checkboxSelection: T[] = Array.isArray(entitiesToDelete) ? entitiesToDelete : [entitiesToDelete];

        // Konstruiere das Ereignis mit beiden: entity und checkboxSelection
        const event: GridEvent<T> = {
            sender,
            type,
            target,
            checkboxSelection,
        };

        // Ereignis auslösen
        this.gridEvent.next(event);
    }

    // Neue Zeile wurde angeklickt
    selectionChanged(sender: string, entity: T, target: string = '') {
        const type = GridEventType.SelectionChanged;

        const event: GridEvent<T> = {
            sender,
            type,
            entity,
            target,
        };

        // Event auslösen
        this.gridEvent.next(event);
    }

    // Filter wurde geändert
    filterChanged(sender: string, target: string, data: FilterData) {
        const type = GridEventType.FilterChanged;

        if (data?.formular) {
            // workaround für filterung mit crud -> null werte entfernen
            Object.keys(data.formular).forEach((key) => {
                if (data.formular[key] === null) {
                    delete data.formular[key];
                }
            });
        }

        const event: GridEvent<T> = {
            sender,
            target,
            type,
            data,
        };

        // Event auslösen
        this.gridEvent.next(event);
    }

    loadScheme(route: string): Observable<any> {
        return this.backendService.getRequest(`${this.baseRoute}${route}/columnDefinition`);
    }

    loadFields(route: string): Observable<any> {
        return this.backendService.getRequest(`${this.baseRoute}${route}/displayColumns`);
    }

    async loadConfigFromScheme(route: string): Promise<TableColumn<T>[]> {
        const schema = await firstValueFrom(this.loadScheme(route));

        const tableColumns: TableColumn<T>[] = [];

        Object.keys(schema).forEach((columnDef) => {
            const tblcolumn: TableColumn<T> = {
                columnDef,
                header: schema[columnDef].header,
                cell: (entity: T) => entity[columnDef],
            };
            // grab all default if existing
            Object.keys(schema[columnDef]).forEach((key) => {
                tblcolumn[key] = schema[columnDef][key];
            });

            // process data (mode and cell)
            let mode: TableColumnMode;
            switch (schema[columnDef].type) {
                case 'integer':
                    mode = COLUMN_INTEGER;
                    break;
                case 'decimal':
                    mode = COLUMN_DECIMAL;
                    break;
                case 'text':
                case 'string':
                    break;
                case 'date':
                    mode = COLUMN_DATE;
                    break;

                default:
                    break;
            }

            // TODO aufräumen, wenn mode im backend gesetzt ist direkt evaluieren und reinschreiben
            if (schema[columnDef].mode === 'COLUMN_TAG') {
                mode = COLUMN_TAG;
            }
            if (schema[columnDef].mode === 'COLUMN_CURRENCY') {
                mode = COLUMN_CURRENCY;
            }
            if (schema[columnDef].mode === 'COLUMN_PERCENTAGE') {
                mode = COLUMN_PERCENTAGE;
            }
            if (schema[columnDef].mode === 'COLUMN_LISTENTRY') {
                mode = COLUMN_LISTENTRY;
            }
            if (schema[columnDef].mode === 'COLUMN_DATE') {
                mode = COLUMN_DATE;
            }
            if (schema[columnDef].mode === 'COLUMN_ACTION') {
                mode = COLUMN_ACTION;
            }

            /*
             * Falls eine 'cell'-Definition vorhanden ist, wird eine Funktion erstellt, die
             * den Zellinhalt aus der Entity extrahiert.
             * Unterstützt auch mehrere Felder als Array und kombiniert diese mit einem Leerzeichen.
             * TODO: separator konfigurieren
             */
            if (schema[columnDef].cell !== undefined) {
                if (Array.isArray(schema[columnDef].cell)) {
                    // Wenn 'cell' ein Array ist, werden alle angegebenen Pfade kombiniert
                    const paths: string[] = schema[columnDef].cell;
                    tblcolumn.cell = (entity: T): unknown =>
                        paths.map((path) => this.getNestedValue(entity, path)).join(' ');
                } else {
                    // Standard: ein einzelner Pfad als String
                    const path = schema[columnDef].cell;
                    tblcolumn.cell = (entity: T): unknown => this.getNestedValue(entity, path);
                }
            } else if (typeof this.extractorFunctionRegistry[columnDef] === 'function') {
                // a custom frontend-function, if one is registered for this column-key
                tblcolumn.cell = this.extractorFunctionRegistry[columnDef];
            }

            if (notEmpty(tblcolumn.ngClass)) {
                tblcolumn.cellNgClassExtractor = this.extractorFunctionRegistry[tblcolumn.ngClass];
            }

            if (notEmpty(tblcolumn.action)) {
                tblcolumn.actionFn = this.extractorFunctionRegistry[tblcolumn.action];
            }

            if (typeof schema[columnDef].listName === 'string') {
                tblcolumn.listName = schema[columnDef].listName;
            }
            tblcolumn.mode = mode;
            tableColumns.push(tblcolumn);
        });

        return tableColumns;
    }

    async loadDisplayedColumns(route: string): Promise<string[]> {
        const schema = await firstValueFrom(this.loadFields(route));

        const displayedColumns: string[] = [];

        Object.keys(schema).forEach((key) => {
            displayedColumns.push(key);
        });

        return displayedColumns;
    }

    private getNestedValue(obj: any, path: string): any {
        return path.split('.').reduce((acc, key) => acc && acc[key], obj);
    }

    // TODO: implementieren
    public saveDisplayedColumns(gridName: string, displayedColumns: string[]) {}

    public checkboxSelectionChanged(sender: string, checkboxSelection: T[], entity: T = null, target: string = '') {
        const type = GridEventType.CheckboxSelectionChanged;
        const event: GridEvent<T> = {
            sender,
            type,
            checkboxSelection,
            entity,
            target,
        };

        // Event auslösen
        this.gridEvent.next(event);
    }

    public checkboxSelectionAction(sender: string, data: unknown, target: string = '') {
        const type = GridEventType.CheckboxSelectionAction;

        const event: GridEvent<T> = {
            sender,
            type,
            target,
            data,
        };

        // Event auslösen
        this.gridEvent.next(event);
    }

    private extractorFunctionRegistry: ExtractorFunctionRegistry<T> = {};

    /**
     * Erlaubt das Registrieren eigener cell()-Funktionen (Daten-Extraktoren), für weitergehende Evaluierungslogik.
     * Wichtig: Da der Service ein Singleton ist, werden die Funktionen GLOBAL registriert und sind für alle nutzenden
     * Komponenten gültig, daher müssen die Namen der Spalten/Extraktoren auch global eindeutig sein.
     * @param extractorFunctions Dictionary von Name: Funktion
     */
    public registerExtractorFunctions(extractorFunctions: ExtractorFunctionRegistry<T>) {
        this.extractorFunctionRegistry = {
            ...this.extractorFunctionRegistry,
            ...extractorFunctions,
        };
    }

    /**
     * Extrahiert einen Wert mit der genannten Extraktor-Funktion
     * @param row
     * @param extractorName
     */
    extractValue(row: T, extractorName: string) {
        return this.extractorFunctionRegistry[extractorName]?.(row) ?? undefined;
    }

    sortChanged(sender: string, target: string, data: Sort) {
        const type = GridEventType.SortChanged;

        const event: GridEvent<T> = {
            sender,
            target,
            type,
            data,
        };

        // Event auslösen
        this.gridEvent.next(event);
    }
}
