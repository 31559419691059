/**
 * @brief   Globaler Service zum Abfangen von HTTP-Requests um z.B.
 *          das Authentifizierungstoken im Header anzuhängen.
 * @details Ergänzt bei jedem HTTP-Request das JWT Access-Token im HTTP-Header
 *          im Authorization-Feld als Bearer-Token.
 * @autor   Massimo Feth <m.feth@pharmakon.software>
 */

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError, BehaviorSubject} from 'rxjs';
import {TokenStorageService} from './token-storage.service';
import {catchError, switchMap, filter, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from '@global/services/auth.service';
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class RequestInterceptorService implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private tokenStorageService: TokenStorageService,
        private appCore: AppCoreService,
        private authService: AuthService,
        private router: Router,
        private backendService: BackendService,
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        if (this.tokenStorageService.token) {
            request = this.addTokenHeader(request, this.tokenStorageService.token);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 403) {
                    return this.handle403Error(request, next);
                }
                return throwError(() => error);
            }),
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({setHeaders: {Authorization: `Bearer ${token}`}});
    }

    private handle403Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.refreshToken().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    this.tokenStorageService.token = token.data.token.tokenId;
                    this.refreshTokenSubject.next(token.data.token.tokenId);
                    return next.handle(this.addTokenHeader(request, token.data.token.tokenId));
                }),
                catchError((err) => {
                    console.error('Error refreshing token', err);
                    this.isRefreshing = false;

                    // Perform logout
                    this.authService.logout();
                    // Navigate to login page
                    this.router.navigate(['/login']);

                    this.appCore.appLogout.next(true);
                    return throwError(() => new Error('Token refresh failed'));
                }),
            );
        }
        return this.refreshTokenSubject.pipe(
            filter((token) => token != null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token))),
        );
    }

    private refreshToken(): Observable<any> {
        console.log('Requesting new refresh token...');
        const refreshToken = this.tokenStorageService.refreshToken;
        const username = this.tokenStorageService.username;

        return this.backendService.postRequestAsync('users/refreshToken', {
            refreshToken,
            username,
        }).pipe(
            catchError((error) => {
                console.error('Refresh token request failed', error);
                return throwError(() => error);
            }),
        );
    }
}
