// Angular-Core
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services einbinden
import {UserPermissionsService} from './../../../services/user-permissions.service';
// Services
import {GlobalHelpTextService} from './../global-help-text/global-help-text.service';
import {GlobalHelpService} from './../global-help.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
// Shared Components
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';

@Component({
    selector: 'phscw-global-help-lists',
    templateUrl: './global-help-lists.component.html',
    styleUrls: ['./global-help-lists.component.scss'],
})
export class GlobalHelpListsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Berechtigung zum Löschen
    allowDeleteHelpentry = false;

    // Liste der geladenen Einträge
    data: any[] = [];
    // @todo: Beschreibung fehlt
    childMap: any[] = [];

    // Flag definiert, ob gerade geladen wird
    loading = true;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param userPermissions
     * @param globalHelpService
     * @param globalHelpTextService
     * @param dialog
     * @param translateService
     */
    constructor(
        private userPermissions: UserPermissionsService,
        private globalHelpService: GlobalHelpService,
        private globalHelpTextService: GlobalHelpTextService,
        private dialog: MatDialog,
        private translateService: TranslateService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        this.initializeEventSubscriptions();
        this.checkAllowDeleteHelpentry();
        this.globalHelpService.listChange('global-help-lists-init');
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   EventSubscriptons
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    initializeEventSubscriptions(): void {
        // Event "eventHelpListChanged" von "globalHelpService" - Daten werden geladen
        this.globalHelpService.eventHelpListChanged.pipe(takeUntil(this._componentDestroyed$)).subscribe(
            (result: CWEvent) => {
                this.loadChildMap(result.data.list);
                this.data = result.data.list;
                if (result.data.list.length > 0) {
                    this.entrySelected(result.data.list[0].version_id);
                }

                // Flag setzen
                this.loading = false;
            },
            (error: any) => {
                // Flag setzen
                this.loading = false;
            },
        );

        // Event "eventHelpEntryChanged" von "globalHelpService"
        this.globalHelpService.eventHelpEntryChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                const changedElement = this.findEntry(this.data, result.data.id);
                if (changedElement != null) {
                    changedElement.entry_name = result.data.entry_name;
                } else {
                    this.globalHelpService.listChange('global-help-lists');
                }
            });
    }

    /**
     * @param id
     * @brief   Wechsel des Hilfeeintrags auslösen
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    entrySelected(id: number): void {
        this.globalHelpService.selectionChange(id, 'global-help-lists');
    }

    /**
     * @param id
     * @brief   ???
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    disablePanel(id: number): boolean {
        return !this.childMap[id];
    }

    /**
     * @param data
     * @brief   ???
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    loadChildMap(data: any): void {
        for (let i = 0; i < data.length; i++) {
            if (typeof data[i].children !== 'undefined') {
                this.childMap[data[i].id] = data[i].children.length !== 0;
            } else {
                this.childMap[data[i].id] = false;
            }

            if (this.childMap[data[i].id]) {
                this.loadChildMap(data[i].children);
            }
        }
    }

    /**
     * @param data
     * @param id
     * @brief   ???
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    findEntry(data: any, id: number): any {
        for (let i = 0; i < data.length; i++) {
            if (data[i].id == id) {
                return data[i];
            }

            let child = null;

            if (typeof data[i].children !== 'undefined') {
                child = this.findEntry(data[i].children, id);
            }

            if (child != null) {
                return child;
            }
        }
        return null;
    }

    /**
     * @param helpentryId
     * @param helpentryName
     * @brief       Dialog zum Löschen öffnen
     * @author      Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openDeleteDialog(helpentryId: number, helpentryName: string): void {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('GLOBAL.HELP.LIST.DELETEHELPENTRY'),
                message: this.translateService.instant('GLOBAL.HELP.LIST.DELETEHELPENTRYQUESTION', {name: helpentryName}),
                id: helpentryId,
            },
        });

        // auf Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result) => this.deleteHelpentry(result.answer, result.id));
    }

    /**
     * @param answer
     * @param id
     * @brief       Dialog zum Löschen öffnen
     * @author      Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    deleteHelpentry(answer: string, id: number): void {
        // Wenn in Confirm-Dialog Button "Ja" geklickt wurde
        if (answer === 'yes') {
            const formData: any = {id};

            // Submit der Formular-Daten über globalHelpTextService
            const serviceRequest$ = this.globalHelpTextService.deleteEntry(formData);
            serviceRequest$.subscribe(
                (result: CWResult) => {
                    // Neu laden
                    this.globalHelpService.listChange('global-help-lists');
                },
                (error: any) => {},
            );
        }
    }

    /**
     * @brief   Berechtigung zum Löschen prüfen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkAllowDeleteHelpentry(): void {
        const permissionAllowDeleteHelpentry: boolean = this.userPermissions.getPermissionValue('allowDeleteHelpentry');
        this.allowDeleteHelpentry = permissionAllowDeleteHelpentry;
    }
}
