// Angular-Module
import {Component, Inject, OnInit} from '@angular/core';
// Angular-Material
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
// Interfaces für Structured Objects einbinden
import {LooseObject} from '@shared/loose-object';
import {StorageService} from '@global/services/storage.service';
import {User} from '@shared/user';

@Component({
    selector: 'phscw-global-error-popup',
    templateUrl: './global-error-popup.component.html',
    styleUrls: ['./global-error-popup.component.scss'],
})
export class GlobalErrorPopupComponent implements OnInit {
    mailAddress = 'support@pharmakon.software';
    showDetails = false;
    currentUser = null;
    errorCopied = false;

    // Konstruktor
    constructor(
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: LooseObject,
        public dialogRef: MatDialogRef<GlobalErrorPopupComponent>,
        private storageService: StorageService,
    ) {}

    // Initialisierung der Komponente
    ngOnInit() {
        // Daten über Service anfordern
        const promise = this.storageService.getItem<User | null>('ownUser');
        promise.then((userData) => {
            // Falls Daten des Users vorhanden sind
            if (userData) {
                // Daten zwischenspeichern
                this.currentUser = userData;
            }
        });
    }

    getClipboardMessage() {
        return {
            url: this.data.error.url,
            statusCode: this.data.error.status,
            payload: this.data.payload,
            message: this.data.error.error.message,
            currentUser: this.currentUser,
        };
    }

    closeDialog() {
        this.dialogRef.close();
    }

    reloadPage() {
        window.location.reload();
    }

    // Zustand des Buttons ändern
    onCopyToClipboard() {
        this.errorCopied = true;
    }

    // Mailversand initiieren
    onContactSupport() {
        window.location.href = `mailto:${this.mailAddress}?subject=Support&title=Support`;
    }
}
