// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services einbinden
import {FileDownloadService} from './../../../services/file-download.service';
import {UserPermissionsService} from './../../../services/user-permissions.service';
// Shared Services einbinden
import {DocumentsService} from '@shared/documents/documents.service';
import {GridService} from '@shared/grid/grid.service';
// Services
import {GlobalHelpService} from './../global-help.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';

@Component({
    selector: 'phscw-global-help-documents',
    templateUrl: './global-help-documents.component.html',
    styleUrls: ['./global-help-documents.component.scss'],
})
export class GlobalHelpDocumentsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID des Grids
    displayGridId = 'globalHelpDocumentsList';

    // ID des Eintrags
    helpentryId: number;

    // EditMode aktiv?
    @Input() editMode = false;

    // Flag definiert, ob Eintrag bearbeitete werden darf
    allowEditHelpentry = false;

    // Anzuzeigene Spalten bei Hochladen eines Dokuments
    uploadColumns: string[] = ['document-name', 'delete'];

    // Anzuzeigene Spalten bei Anzeigen der Liste der Dokuments
    displayColumns: string[] = ['document-name', 'history'];

    // Dateiname des versionierten Dokument
    currentHistoryFileName = '';

    /**
     * Konstruktor (inkl. dependency injection)
     * @param globalHelpService
     * @param userPermissions
     * @param fileDownloadService
     * @param gridService
     * @param documentsService
     */
    constructor(
        private globalHelpService: GlobalHelpService,
        private userPermissions: UserPermissionsService,
        private fileDownloadService: FileDownloadService,
        private gridService: GridService,
        public documentsService: DocumentsService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        this.initializeEventSubscriptions();
        this.checkAllowEditHelpentry();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   EventSubscriptons
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    initializeEventSubscriptions(): void {
        // Event "eventHelpSelectionChanged" von "globalHelpService"
        this.globalHelpService.eventHelpSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // ID des Eintrags zwischenspeichern
                this.helpentryId = result.data.id;
            });

        // Event "eventGridSelectionChanged" von "gridService"
        this.gridService.eventGridSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid (E-Liste) kam
                if (event.sender !== this.displayGridId) {
                    return;
                }
                this.onEventGridSelectionChanged(event);
            });

        // Event "eventGridHistoryIconClicked" von "gridService"
        this.gridService.eventGridHistoryIconClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbrechen, wenn nicht von Dokumenten-Grid
                if (event.sender !== this.displayGridId) {
                    return;
                }
                this.onEventHistoryIconClicked(event);
            });

        // Event "eventGridHistoryMenuItemClicked" von "gridService"
        this.gridService.eventGridHistoryMenuItemClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbrechen, wenn nicht von Dokumenten-Grid
                if (event.sender !== this.displayGridId) {
                    return;
                }
                this.onEventHistoryMenuItemClicked(event);
            });
    }

    /**
     * @brief   Setze allowEditHelpentry-Variable aus den Userpermissions
     * @author  Julian Roller <j.roller@pharmakon.software>
     */
    checkAllowEditHelpentry(): void {
        const permissionAllowEditHelpentry: boolean = this.userPermissions.getPermissionValue('allowEditHelpentry');
        this.allowEditHelpentry = permissionAllowEditHelpentry;

        // Löschen-Spalte anzeigen
        if (permissionAllowEditHelpentry) {
            this.displayColumns.push('delete');
        }
    }

    /**
     * @param event
     * @brief   Lädt alle Versionen des angeklickten Elements
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventHistoryIconClicked(event: CWEvent): void {
        // Request auslösen
        const serviceRequest$ = this.globalHelpService.getDocumentHistory(event.data.id);
        serviceRequest$.subscribe(
            (result: CWResult) => {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zum aktuell ausgewählten Eintrag passen. Durch asynchrone
                 * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                 * bereits der Eintrag gewechselt wurde und die Antwort
                 * eines Requests verspätet eintrifft und dadurch die
                 * korrekten Daten wieder überschreibt.
                 */
                if (result.data.length > 0 && this.helpentryId != result.data[0]['entity_id']) {
                    return;
                }

                // Dateinamen zwischenspeichern für Download
                this.currentHistoryFileName = result.data[0].document_name;

                this.gridService.historyDataInitialized('globalHelpDocuments', this.displayGridId, result.data);
            },
            (error: any) => {
                // Error Handling
            },
        );
    }

    /**
     * @param event
     * @brief   Auf Klick einer Version reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventHistoryMenuItemClicked(event: CWEvent): void {
        const documentId: number = parseInt(event.data.name, 10);
        this.downloadFile(documentId, this.currentHistoryFileName);
    }

    /**
     * @param event
     * @brief   Auf Klick einer Reihe im Grid reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventGridSelectionChanged(event: CWEvent): void {
        const documentId: number = event.data.selectedRow.id;
        const documentName: string = event.data.selectedRow.document_name;
        this.downloadFile(documentId, documentName);
    }

    /**
     * @param id
     * @param name
     * @brief   Dokument herunterladen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    downloadFile(id: number, name: string): void {
        // Request auslösen
        const serviceRequest$ = this.globalHelpService.getDocument(id);
        serviceRequest$.subscribe(
            (result: any) => {
                // Dialog zum Speichern öffnen
                this.fileDownloadService.openSaveDialog(result, name);
            },
            (error: any) => {
                // Error Handling
            },
        );
    }
}
