// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Service der übergeordneten Komponente
import {ToolbarService} from '../toolbar.service';
import {CwIcon, IconType} from '@shared/cw-icon';

@Component({
    selector: 'phscw-toolbar-navigate-institutions',
    templateUrl: './toolbar-navigate-institutions.component.html',
    styleUrls: ['./toolbar-navigate-institutions.component.scss'],
})
export class ToolbarNavigateInstitutionsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;
    // Tooltip
    @Input() tooltip: string = null;
    // Parameter
    @Input() parameter: any;

    @Input() icon: CwIcon = {
        iconName: 'icon-institution',
        iconType: IconType.IconFont,
    };

    /**
     * Konstruktor (inkl. dependency injection)
     * @param toolbarService
     */
    constructor(private toolbarService: ToolbarService) {}

    /*
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /*
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeEventSubscriptions(): void {}

    /*
     * @brief   Navigation zu Einrichtungen auslösen
     *
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickNavigateInstitutions() {
        this.toolbarService.navigateToInstitutions(this.componentIdentifier);
    }
}
