// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .cw-flex-column > .cw-flex-row:not(:last-of-type) mat-checkbox {
  margin-bottom: 2px;
}

:host .cw-checkbox {
  line-height: 1;
  margin-left: 5px;
}

:host .cw-delete {
  margin: 8px 5px;
  line-height: 2.5rem;
}

:host[required]:not(.ng-valid) ::ng-deep .mdc-checkbox__background {
  border-color: var(--color-accent) !important;
}

.centered {
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/input/input-checkbox/input-checkbox.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,cAAA;EACA,gBAAA;AACJ;;AAEA;EACI,eAAA;EACA,mBAAA;AACJ;;AAGA;EACI,4CAAA;AAAJ;;AAGA;EACI,mBAAA;AAAJ","sourcesContent":[":host .cw-flex-column > .cw-flex-row:not(:last-of-type) mat-checkbox {\n    margin-bottom: 2px;\n}\n\n:host .cw-checkbox {\n    line-height: 1;\n    margin-left: 5px;\n}\n\n:host .cw-delete {\n    margin: 8px 5px;\n    line-height: 2.5rem;\n}\n\n// Farbe der Checkbox ändern, wenn diese required ist und keinen Wert hat\n:host[required]:not(.ng-valid) ::ng-deep .mdc-checkbox__background {\n    border-color: var(--color-accent) !important;\n}\n\n.centered {\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
