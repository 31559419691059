import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class InstitutionsClienttemplatesService {
    constructor(private backendService: BackendService) {}

    /**
     * @brief   Nachfragen, ob der Pricing-Button bei der Einrichtung angezeigt werden soll
     * @param formData
     * @param   Object  formData
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     */
    showPricingExcelButton(formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'InstitutionsClienttemplates/showPricingExcelButton/',
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Konditionen der Einrichtung exportieren
     * @param formData
     * @param   Object  formData
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     */
    loadPricingExcel(formData: any) {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.getFile(
            'InstitutionsClienttemplates/loadPricingExcel/' + formData['institutionId'],
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Senden der Kundenpreisliste auslösen
     * @param formData
     * @param   Object  formData
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    requestCustomerPricingList(formData: any) {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'InstitutionsClienttemplates/requestCustomerPricingList/',
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
