// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */
:host {
  max-width: 100%;
}

.cw-region-assignments > p:not(:last-of-type) {
  margin: 0 0 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/institutions/institutions-data/institutions-data.component.scss"],"names":[],"mappings":"AAAA;;;;;;EAAA;AAQA;EACI,eAAA;AAAJ;;AAGA;EACI,eAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n * @author  Massimo Feth <m.feth@pharmakon.software>\n */\n\n:host {\n    max-width: 100%;\n}\n\n.cw-region-assignments > p:not(:last-of-type) {\n    margin: 0 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
