/**
 * @brief   Globaler Service zur Bereitstellung von User-Informationen
 * @author  Tristan Krakau <t.krakau@pharmakon.software>
 */

// Angular-Module
import {Injectable} from '@angular/core';
// Globale Services
import {StorageService} from '@global/services/storage.service';
import {User} from '@shared/user';
import {BackendService} from './backend.service';

@Injectable({
    // Root-Injector (app.module.ts) ist verantwortlich für das Instanziieren dieses globalen Services
    providedIn: 'root',
})
export class UserDataService {
    private _currentUser?: User = null;

    public loadUserData(): Promise<User> {
        if (this._currentUser !== null && typeof this._currentUser !== 'undefined') {
            return Promise.resolve<User>(this._currentUser);
        }
        // aus Storage holen, z.B. nach einem Page-Reload wird der Service neu instanziiert
        return this.storageService.getItem('ownUser');
    }

    // Konstruktor (inkl. dependency injection)
    constructor(
        private backendService: BackendService,
        private storageService: StorageService,
    ) {}
}
