// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Julian Roller <j.roller@pharmakon.software>
*/
:host .cw-flex-column {
  height: 100%;
}

:host .lists {
  flex: 1;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-help/global-help-menu/global-help-menu.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,YAAA;AAAJ;;AAGA;EACI,OAAA;EACA,gBAAA;AAAJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Julian Roller <j.roller@pharmakon.software>\n*/\n\n:host .cw-flex-column {\n    height: 100%;\n}\n\n:host .lists {\n    flex: 1;\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
