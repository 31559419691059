import {Injectable} from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ChatComponent} from './chat/chat.component';

@Injectable({providedIn: 'root'})
export class ArtificialIntelligenceService {
    constructor(private bottomSheet: MatBottomSheet) {}

    startChat(entityId: number) {
        this.bottomSheet.open(ChatComponent, {
            data: entityId,
            panelClass: 'ai-chat-panel',
        });
    }
}
