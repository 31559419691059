import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-chart-sales-per-month',
    templateUrl: './chart-sales-per-month.component.html',
    styleUrls: ['./chart-sales-per-month.component.scss'],
})
export class ChartSalesPerMonthComponent implements OnInit {
    @Input() chartLocation = 'dashboards';
    // @Input() backendOptions = {'institutionId' : this.institutionId, location : this.chartLocation};
    @Input() backendOptions;
    constructor() {}

    ngOnInit() {}
}
