// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';

@Injectable({providedIn: 'root'})
export class InstitutionsDocumentsService {
    /**
     * Konstruktor (inkl. dependency injection)
     * @param backendService
     */
    constructor(private backendService: BackendService) {}

    /**
     * @brief   Lädt Dokument aus dem Backend
     * @param id
     * @param   number  id  ID des Dokuments
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getDocument(id: number): Observable<Blob> {
        // GET-Request über BackendService senden
        const getRequest: Observable<Blob> = this.backendService.getFile('Documents/download/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest;
    }

    /**
     * @brief   Lädt Versionsdaten aus dem Backend
     * @param id
     * @param   number  id  ID des Dokuments
     * @todo    muss noch im Backend implementiert werden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getDocumentHistory(id: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest: Observable<any> = this.backendService.getRequest(
            'Documents/getHistory/' + id + '/institution',
        );
        // Observable (an Komponente) zurücklieferen
        return getRequest;
    }
}
