import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'phscw-global-page-not-found',
    templateUrl: './global-page-not-found.component.html',
    styleUrls: ['./global-page-not-found.component.scss'],
})
export class GlobalPageNotFoundComponent implements OnInit, OnDestroy {
    constructor() {}

    ngOnInit() {}

    // Aufräumen
    ngOnDestroy() {}
}
