// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
*/
:host,
:host > .cw-flex-row {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/global/layouts/layout-main/layout-main.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;;EAEI,YAAA;AAAJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n*/\n\n:host,\n:host > .cw-flex-row {\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
