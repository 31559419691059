// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
*/
:host .cw-toolbar-icon ::ng-deep .icon-check {
  color: var(--color-accent);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/toolbar/toolbar-history/toolbar-history.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;EACI,0BAAA;AAAJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n*/\n\n:host .cw-toolbar-icon ::ng-deep .icon-check {\n    color: var(--color-accent);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
