// Angular-Module
import {AfterContentChecked, Component, Inject, OnDestroy, OnInit} from '@angular/core';

import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

// import * as CanvasJS from '@angular/canvas';

// Interface für Dialogdaten
export interface DialogData {
    title: string;
    message: string;
    answer: string;
}

@Component({
    selector: 'phscw-popup-signature',
    templateUrl: './popup-signature.component.html',
    styleUrls: ['./popup-signature.component.scss'],
})
export class PopupSignatureComponent implements OnInit, OnDestroy, AfterContentChecked {
    public signatureSVG: SafeHtml;

    constructor(
        public dialogRef: MatDialogRef<PopupSignatureComponent>,
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: DialogData,

        private sanitizer: DomSanitizer,
    ) {}

    ngOnInit() {}

    // Aufräumen
    ngOnDestroy() {}

    /**
     * Verhindere ExpressionChangedAfterItHasBeenChecked
     */
    ngAfterContentChecked() {
        // Input Sanitization für das übergebene SVG deaktivieren, da Angular sonst die Darstellung verweigert
        if (this.data.message !== undefined && this.data.message !== null) {
            this.signatureSVG = this.sanitizer.bypassSecurityTrustHtml(this.data.message as string) as SafeHtml;
        }
    }

    // Button im Dialog wurde angeklickt, Antwort merken und Dialog schließen
    clickAndClose(answer: string) {
        // Antwort übernehmen
        this.data.answer = answer;
        // Dialog (über Referenz) schließen und Dialog-Ergebnis mitsenden
        this.dialogRef.close(this.data);
    }

    /**
     * 2020-08-07, PhS(LB): Die Auswertung der Unterschrift geschieht nun im Backend. Die folgenden Methoden dienen nur zur Referenz
     */

    /**
     * Übersetzt den String in coordinaten und malt Anhand dieser dann
     * die Unterschrift ins Canvas
     *
     * Zerlegt den übergebenen string in hex paare und konvertiert
     * die entsprechenden Teilstrings in Integer-Koordinaten
     * Beispiel 3a5b358a2b44
     * 1. x Koordinate  ^^= 58
     * 1. y Koordinate    ^^= 91
     * 2. x Koordinate      ^^= 53
     * 2. y Koordinate        ^^= 138
     * 3. x Koordinate          ^^= 43
     * 3. y Koordinate            ^^= 68
     * Dieser Kurvenverlauf wird dann in dem Zeichenbereich ausgegeben
     * @param {string} string
     */
    /*
     * getCanvasCoordinatesFromString(hexString: string) {
     *    let x;
     *    let y;
     *    this.canvasCoordinates = [];
     *
     *    // Jeweils 4 Hex-Zeichen bilden ein x,y Koordinaten-Paar ab.
     *    for (let i = 0; i < hexString.length; i += 4) {
     *        // Koordinaten aus Hexadezimal String umwandeln
     *        // @todo: Scalierung nachprüfen (mit PharmaPilot Logik abgleichen)
     *        x = Math.round(parseInt(hexString.substring(i    , i + 2), 16)*1.15);
     *        y = Math.round(parseInt(hexString.substring(i + 2, i + 4), 16)*255/450);
     *
     *        // Umgewandelte Koordinaten übernehmen
     *        this.canvasCoordinates.push({"x":x, "y":y});
     * }
     *
     *    // Die Linien auf das canvas zeichnen
     *    for (let i = 1; i < this.canvasCoordinates.length; i++) {
     *        this.drawSubscritionsCanvas(this.canvasCoordinates[i-1]['x'], this.canvasCoordinates[i-1]['y'], this.canvasCoordinates[i]['x'], this.canvasCoordinates[i]['y']);
     *    }
     * }
     */

    /**
     * Male die Linien anhand der übergebenen Koordinaten auf das Canvas
     */
    /*
     * drawSubscritionsCanvas(startX: number, startY: number, endX: number, endY: number) {
     *
     *    // Nur Zeichnen, falls der Punkt nicht bei 0,0 beginnt.
     *    // Diese Koordinate wird verwendet um eine Linienunterbrechung zu
     *    // signalisieren
     *    if ((startX != 0 || startY != 0) && (endX != 0 || endY != 0)) {
     *        let context2D = this.canvas.nativeElement.getContext("2d");
     *        context2D.beginPath();
     *        context2D.moveTo(startX, startY);
     *        context2D.lineTo(endX, endY);
     *        context2D.stroke();
     *    }
     *
     * }
     */
}
