import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {
    MatLargeIconButtonDirective,
    MatMediumIconButtonDirective,
    MatSmallIconButtonDirective,
    MatTinyIconButtonDirective,
} from './mat-icon-button-sizes/mat-icon-button-sizes.directive';
import {GridNewResizeableColumnDirective} from './grid-new-resize-columns/grid-new-resize-columns.directive';
import {TagThemeDirective} from './tag-theme/tag-theme.directive';
import {PersonAvatarDirective} from './person-avatar/person-avatar.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        MatLargeIconButtonDirective,
        MatMediumIconButtonDirective,
        MatSmallIconButtonDirective,
        MatTinyIconButtonDirective,
        GridNewResizeableColumnDirective,
        TagThemeDirective,
        PersonAvatarDirective,
    ],
    exports: [
        MatLargeIconButtonDirective,
        MatMediumIconButtonDirective,
        MatSmallIconButtonDirective,
        MatTinyIconButtonDirective,
        GridNewResizeableColumnDirective,
        TagThemeDirective,
        PersonAvatarDirective,
    ],
})
export class DirectivesModule {}
