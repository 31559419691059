export class ParentInstitution {
    id = 0;

    name1?: string = '';
    name2?: string = '';
    name3?: string = '';
    name4?: string = '';

    type1?: string = '';
    type2?: string = '';

    street?: string = '';
    street_number_from?: string = '';
    street_number_to?: string = '';
    zipcode?: string = '';
    city?: string = '';
    district?: string = '';
    country?: string = '';

    phone1?: string = '';
    phone2?: string = '';
    phone3?: string = '';
    fax?: string = '';
    mail?: string = '';
    internet?: string = '';

    erp_number?: string = '';

    create_date?: Date;
    create_user?: string = '';
    edit_date?: Date;
    edit_user?: string = '';
    delete_date?: Date;
    delete_user?: string = '';
    deleted?: string = 'N';

    // Institution-Icon (wird aktuell über Grid gesetzt)
    institution_icon?: string = '';

    label?: string = '';

    // Konstruktor
    constructor(id = 0) {
        this.id = id;
    }
}
