import {Injectable} from '@angular/core';
import {BackendService} from '@global/services/backend.service';
import {mergeMap, Observable, of, Subject, toArray} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {GridNewOptions} from '@shared/grid-new-options';

@Injectable({providedIn: 'root'})
export class DataLoadService {
    private concurrency = 4;

    constructor(
        private backend: BackendService,
    ) {
    }

    public getEntireList<T = any>(backendPath: string, requestFilter?: GridNewOptions): Observable<T[]> {
        let page = 0;
        const body = requestFilter ?? {};
        const pages$ = new Subject<any>();
        let pendingRequests = 0;
        if (body.pagination == null) {
            // if no pagination has been set with the seeds assume loading everything at once
            console.debug(`loading entire ${backendPath} without Pagination`);
            return this.backend.postRequestAsync(backendPath, body);
        }

        // anonymous function, will always call the next page
        const fetchPage = (): Observable<T[]> => {
            body.pagination.page = ++page;
            console.debug(`loading page ${body.pagination.page} of ${backendPath}`);
            return this.backend.postRequestAsync(backendPath, body).pipe(
                catchError((error) => {
                    // place for custom logic on failure?
                    console.error(`catching error: ${error}`);
                    // Skip failing pages by returning an null observable
                    return of(null);
                }),
            );
        };

        // anytime a request still returns values (non empty array) call next page
        pages$.pipe(
            mergeMap((_page) => {
                pendingRequests++;
                return fetchPage();
            }, this.concurrency),
        ).subscribe({
            next: (data) => {
                pendingRequests--;
                const isPageEmtpy = Array.isArray(data) && data.length === 0;
                if (isPageEmtpy) {
                    // empty array response indicates completion of backend request
                    if (pendingRequests === 0) {
                        pages$.complete();
                    }
                } else {
                    pages$.next(data);
                }
            },
            error: (error) => {
                console.error(`unhandled error ${backendPath}, ABORTING`, error);
            },
            complete: () => {
                console.log(`done loading ${backendPath}`);
            },
        });

        // have x chains of page calls running
        for (let i = 0; i < this.concurrency; i++) {
            pages$.next(null);
        }

        // pages$.next(null)

        // collect results and combine into single array
        return pages$.pipe(
            toArray(),
            map((data) => data
                .flat()
                .filter((data) => data !== null && data !== undefined)),
        );
    }
}
