import {Component, Input, OnInit} from '@angular/core';
import {hasOwn} from '@shared/utils';
import {ChartService} from './../chart.service';

// Environment
import {environment} from '@environment';

@Component({
    selector: 'phscw-chart-yearly-sales',
    templateUrl: './chart-yearly-sales.component.html',
    styleUrls: ['./chart-yearly-sales.component.scss'],
})
export class ChartYearlySalesComponent implements OnInit {
    @Input() products: any = [];
    @Input() currentProduct = 0;
    @Input() backendOptions: any = {selectedProduct: 0};
    @Input() chartType = 'bar';
    @Input() overlap = false;
    xAxisTitle = String(new Date().getFullYear());
    yAxisTitle: string = undefined;

    constructor(private chartService: ChartService) {}

    ngOnInit() {
        const serviceRequest$ = this.chartService.getProducts();
        serviceRequest$.subscribe((result: any) => {
            this.products = result['data'];
        });
        if (hasOwn(environment, 'yearlySalesChartType')) {
            // this.chartType = environment.yearlySalesChartType;
            this.chartType = 'column';
            this.overlap = true;
            this.xAxisTitle = undefined;
            this.yAxisTitle = String(new Date().getFullYear());
        }
    }

    /*
     * setzt das gewählte produkt
     */
    selectProducts(selectedProduct) {
        this.backendOptions = {selectedProduct};
    }
}
