// Angular-Module
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Globale Services
import {FileDownloadService} from '@global/services/file-download.service';
// Service des übergeordneten Feature-Moduls
import {InstitutionsService} from '../institutions.service';
// Environment einbinden
import {environment} from '@environment';
// Service dieser Komponente
import {InstitutionsClienttemplatesService} from './institutions-clienttemplates.service';
// Shared Components
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
// Interfaces für Structured Objects einbinden
import {Institution} from '@shared/institution';
import {hasOwn} from '@shared/utils';
import * as _moment from 'moment';

const moment = _moment;

@Component({
    selector: 'phscw-institutions-clienttemplates',
    templateUrl: './institutions-clienttemplates.component.html',
    styleUrls: ['./institutions-clienttemplates.component.scss'],
})
export class InstitutionsClienttemplatesComponent implements OnInit, OnDestroy {
    /**
     * *************************************************************************
     * ALLGEMEIN
     *************************************************************************
     */
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();
    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;
    // ERP-Nummer der aktuell ausgewählten Einrichtung
    erpNumber: string;
    // Flag definiert ob gerade geladen wird (Anzeige Loading-Spinner für Tagesbericht)
    loading = false;
    // Fehlermeldung falls Request an Server fehlschlägt.
    private _errorMessage = 'Es ist ein Fehler bei der Anfrage aufgetreten.';

    /**
     * *************************************************************************
     * PRICING / KONDITIONENFORMULAR
     *************************************************************************
     */
    // Der Pricing-Button soll nur für bestimmte Divisionen angezeigt werden
    allowedPricingDivisions: number[];
    // Soll Pricing-Button angezeigt werden?
    showPricingButton = false;

    /**
     * *************************************************************************
     * WSG-Liste (QlikView)
     *************************************************************************
     */
    // Definiert Sichtbarkeit des Buttons "WSG-Liste"
    showWSGQlikView = false;

    /**
     * *************************************************************************
     * BEANSTANDUNGEN (Babtec)
     *************************************************************************
     */
    // Beanstandungs-Button wird nur angezeigt, wenn SAP-Nummer vorhanden ist
    showComplaintsButton = false;

    // Konstruktor
    constructor(
        private fileDownloadService: FileDownloadService,
        private institutionsService: InstitutionsService,
        private institutionsClienttemplatesService: InstitutionsClienttemplatesService,
        private dialog: MatDialog,
        private translateService: TranslateService,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();

        // Events subscriben
        this.initializeEventSubscriptions();

        // Einstellung aus Environment übernehmen (Pricing nur für bestimmte Divisions)
        if (hasOwn(environment, 'ivfAllowedPricingDivisions')) {
            this.allowedPricingDivisions = environment['ivfAllowedPricingDivisions'];
        }
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeTranslateSubscriptions() {
        this._subscriptions.add(
            this.translateService.stream(['MODULES.INSTITUTIONS.CLIENTTEMPLATES.ERROR']).subscribe((translation) => {
                this._errorMessage = translation['MODULES.INSTITUTIONS.CLIENTTEMPLATES.ERROR'];
            }),
        );
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Event "selectionChanged" von "institutionsService"
        this._subscriptions.add(
            this.institutionsService.selectionChanged.subscribe((result) => {
                this.onSelectionChanged(result);
            }),
        );
    }

    // Auf geänderte Auswahl reagieren
    onSelectionChanged(id: number) {
        // Flag zurücksetzen
        this.loading = false;
        // Den Pricing-Button zuerst ausblenden
        this.showPricingButton = false;
        // ID der aktuellen Einrichtung merken
        this.institutionId = id;
        // Prüfen, ob der Pricing-Button angezeigt werden soll
        this.showPricingExcelButton();
        // Manche Buttons / Schnittstellen sind nur für Einrichtungen mit ERP-Nummer verfügbar
        this.checkAvailabilityErpInterfaces();
    }

    /**
     * @brief   Button "Pricing / Konditionenformular" wird nur bei der Einrichtungen aus Division VK (DI = 25) einblenden
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     */
    showPricingExcelButton() {
        // Formdaten für POST setzen
        const formData: any = {
            institutionId: this.institutionId,
            allowedPricingDivisions: this.allowedPricingDivisions,
        };

        // API aufrufen
        const serviceRequest$ = this.institutionsClienttemplatesService.showPricingExcelButton(formData);
        serviceRequest$.subscribe((result) => {
            /**
             * Prüfe, ob die Daten des eintreffenden Requests auch
             * zur aktuell ausgewählten Einrichtung passen. Durch
             * asynchrone Abfragen kann es nämlich passieren, dass
             * zwischenzeitlich bereits die Einrichtung gewechselt wurde
             * und die Antwort eines Requests verspätet eintrifft und
             * dadurch die korrekten Daten wieder überschreibt.
             */
            if (
                'id' in result['data'] &&
                typeof this.institutionsService.selectedInstitution !== 'undefined' &&
                this.institutionsService.selectedInstitution !== null &&
                result['data']['id'] != this.institutionsService.selectedInstitution.id
            ) {
                return;
            }

            if (result['success']) {
                this.showPricingButton = true;
            } else {
                this.showPricingButton = false;
            }
        });
    }

    /**
     * @brief   Manche Buttons / Schnittstellen sind nur für Einrichtungen mit
     *          ERP-Nummer verfügbar.
     * @details - Button "WSG-Liste"
     *          - Button "Beanstandungen"
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    checkAvailabilityErpInterfaces() {
        const selectedInstitution: Institution = this.institutionsService.selectedInstitution;
        if (selectedInstitution && hasOwn(selectedInstitution, 'erp_number')) {
            // Falls die vorhandene Property "erp_number" auch gesetzt wurde
            if (selectedInstitution.erp_number) {
                // ERP-Nummer merken
                this.erpNumber = selectedInstitution.erp_number;
                // Buttons aktivieren
                this.showWSGQlikView = true;
                this.showComplaintsButton = true;
                // Rückgabe
                return;
            }
        }

        // Default: Keine ERP-Nummer & Buttons ausgeblendet
        this.erpNumber = '';
        this.showWSGQlikView = false;
        this.showComplaintsButton = false;
    }

    /**
     * @brief   Button "Pricing / Konditionenformular" wurde geklickt
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     */
    clickPricingExcel() {
        // Init
        const message = this.translateService.instant('_IVF.CLIENTTEMPLATES.EXPORTFILECREATENOTICE');

        // Dialog öffnen
        this.openInformationDialog(message);

        // Flag "loading" aktivieren
        this.loading = true;

        // Daten übergeben
        const formData: any = {
            institutionId: this.institutionId,
            url: environment['backendUrl'],
        };

        const lastYear = moment().subtract(1, 'year').format('YYYY');
        const formattedDate = moment().format('YYYYMMDD');
        const formattedTime = moment().format('HHmmss');

        const fileName = `Konditionenformular_VK_${lastYear}_${formattedDate}_${formattedTime}_${this.erpNumber}.xls`;

        // API aufrufen
        const serviceRequest$ = this.institutionsClienttemplatesService.loadPricingExcel(formData);
        serviceRequest$.subscribe(
            (result: any) => {
                this.dialog.closeAll();

                // Dialog zum Speichern öffnen
                this.fileDownloadService.openSaveDialog(result, fileName);
            },
            (error: any) => {
                // Error Handling
                this.dialog.closeAll();
            },
        );
    }

    /**
     * @brief   Button "Kundenpreisliste" wurde geklickt
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickCustomerPricingList() {
        // Flag "loading" aktivieren
        this.loading = true;

        const formData: any = {
            institutionId: this.institutionId,
            url: environment['backendUrl'],
        };

        // API aufrufen
        const serviceRequest$ = this.institutionsClienttemplatesService.requestCustomerPricingList(formData);
        serviceRequest$.subscribe(
            (result) => {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zur aktuell ausgewählten Einrichtung passen. Durch
                 * asynchrone Abfragen kann es nämlich passieren, dass
                 * zwischenzeitlich bereits die Einrichtung gewechselt wurde
                 * und die Antwort eines Requests verspätet eintrifft und
                 * dadurch die korrekten Daten wieder überschreibt.
                 */
                if (
                    'id' in result['data'] &&
                    typeof this.institutionsService.selectedInstitution !== 'undefined' &&
                    this.institutionsService.selectedInstitution !== null &&
                    result['data']['id'] != this.institutionsService.selectedInstitution.id
                ) {
                    return;
                }

                // Init
                let message = '';

                // Nachricht für Benutzer
                if (result['success']) {
                    message = this.translateService.instant('_IVF.CLIENTTEMPLATES.LISTSENDNOTICE');
                } else {
                    message = result['message'];
                }

                // Dialog öffnen
                this.openInformationDialog(message);
            },
            // onError
            (error) => {
                /*
                 * Keine Antwort vom Backend erhalten (Servererror)
                 * Dialog öffnen
                 */
                this.openInformationDialog(this._errorMessage);
            },
        );
    }

    /**
     * @brief   Öffnet Qlik-View Dashboard in neuem Tab
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    clickWSGQlikView() {
        // Prüfe, ob ERP-Nummer gesetzt wurde (auch Leerstring wird hier abgefangen)
        if (!this.erpNumber) {
            return;
        }
        // URL zum QlikView-Dashboard inkl. Parameter ERP-Nummer
        const url: string =
            'https://dash.ivf.ch/QvAJAXZfc/opendoc.htm?document=project%20vertriebsstatistik%20public%5Cvertriebsstatistik.qvw&lang=en-US&host=QVS%40ivf-das01&select=LB18,' +
            this.erpNumber +
            '&select=lboxColumns,WSG,REF&select=LB17,(Umsatz)&select=LB43,Vertrieb';
        // Link in neuem Tab öffnen
        window.open(url, '_blank');
    }

    /**
     * @brief   Dialog öffnen um z.B. Servererror des Backends zu zeigen
     * @param message
     * @param   string  message
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openInformationDialog(message: string) {
        // Flag "loading" deaktivieren
        this.loading = false;

        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupMessageComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('_IVF.CLIENTTEMPLATES.REQUESTRECEIVED'),
                message,
            },
        });

        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe();
    }

    /**
     * @brief   Rufe den Link für die Beanstandungen auf
     * @author  Michael Schiffner <m.schiffner@pharmakon.software>
     */
    clickComplaints() {
        // Prüfe, ob ERP-Nummer gesetzt wurde (auch Leerstring wird hier abgefangen)
        if (!this.erpNumber) {
            return;
        }

        // URL zum zu den Beandstandungen inkl. Parameter ERP-Nummer
        const url: string =
            'http://ch-neu-sql13.hg.dir/Reports/Pages/Report.aspx?ItemPath=%2fBabtec%2fBerichte%2fR6+IVF_Beanstandungen_CRM&ADR_NR=' +
            this.erpNumber;
        // Link in neuem Tab öffnen
        window.open(url, '_blank');
    }
}
