// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * 1. Allgemein
 * ============
 *
 * @author  Julian Roller <j.roller@pharmakon.software>
*/
:host ::ng-deep .mat-column-document-name,
:host .global-help-documents ::ng-deep phscw-documents-upload ::ng-deep .cw-selected-file .cw-document-name {
  margin: 0;
  width: 100%;
}

:host .hidden {
  height: 0;
  margin: 0;
  padding: 0;
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/global/components/global-help/global-help-documents/global-help-documents.component.scss"],"names":[],"mappings":"AAAA;;;;;CAAA;AAOA;;EAEI,SAAA;EACA,WAAA;AAAJ;;AAGA;EACI,SAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;AAAJ","sourcesContent":["/**\n * 1. Allgemein\n * ============\n *\n * @author  Julian Roller <j.roller@pharmakon.software>\n*/\n\n:host ::ng-deep .mat-column-document-name,\n:host .global-help-documents ::ng-deep phscw-documents-upload ::ng-deep .cw-selected-file .cw-document-name {\n    margin: 0;\n    width: 100%;\n}\n\n:host .hidden {\n    height: 0;\n    margin: 0;\n    padding: 0;\n    visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
