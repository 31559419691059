/**
 * @brief   Allgemeiner und abstrahierender Service zum Zugriff auf
 *          zwischengespeicherte (Offline-) Informationen
 * @details Verwendet die JS-Library "localForage" mit entsprechendem Treiber
 *          für Angular 5+ "ngForage":
 *          https://github.com/localForage/localForage
 *          https://github.com/Alorel/ngforage/
 */

// Angular-Module
import {Injectable} from '@angular/core';
// ngForage (localForage)
import {CachedItem, Driver, NgForage, NgForageCache} from 'ngforage';
// Globale Services einbinden
import {AppCoreService} from './app-core.service';

@Injectable({providedIn: 'root'})
export class StorageService {
    // Konstruktor
    constructor(
        private readonly ngf: NgForage,
        private readonly cache: NgForageCache,
        private appCore: AppCoreService,
    ) {}

    // Initialisierungen
    public initialize() {
        // NGF-LocalStorage konfigurieren
        this.ngf.name = 'C-World 4';
        // this.cache.driver = NgForageConfig.DRIVER_SESSIONSTORAGE;
        this.cache.driver = Driver.LOCAL_STORAGE;
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Zentrales "Logout"-Event erhalten --> Datenbank resetten
        this.appCore.appLogout.subscribe((result) => {
            this.ngf.clear();
        });
    }

    // Item aus Storage lesen
    public getItem<T = any>(key: string): Promise<T> {
        return this.ngf.getItem<T>(key);
    }

    // Nicht verwendet
    public getCachedItem<T = any>(key: string): Promise<T | null> {
        return this.cache.getCached<T>(key).then((r: CachedItem<T>) => {
            if (!r.hasData || r.expired) {
                return null;
            }
            return r.data;
        });
    }

    // setItem<T>(key: string, data: T): Promise<T>;
    public setItem<T = any>(key: string, data: T): Promise<T> {
        return this.ngf.setItem<any>(key, data);
    }
}
