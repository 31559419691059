import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';

/**
 * @brief   Service, der Funktionen für die Aufträge einer Einrichtung bietet.
 * @author  Xiao-Ou Wang <x.wang@pharmakons.software>
 * @author  Tristan Krakau <t.krakau@pharmakon.software>
 */
@Injectable({providedIn: 'root'})
export class InstitutionsOrdersService2 {
    // Konstruktor
    constructor(private backendService: BackendService) {}

    /**
     * @brief   Alle Aufträge einer Einrichtung laden für Grid-List
     * @param   {number} id Einrichtungs-ID
     * @param   {number} page
     * @param   {number[]} allowedDivisions
     * @returns  {Observable<unknown>}
     */
    loadData(id: number, page: number, allowedDivisions?: number[]): Observable<unknown> {
        let requestData = {};
        // Falls die Auftragserfassung nur in bestimmten Divisionen erlaubt ist.
        if (typeof allowedDivisions !== 'undefined') {
            requestData = {allowedDivisions};
        }
        // Post-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            'InstitutionsOrders/index/' + id + '/' + page,
            requestData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
