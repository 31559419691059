/**
 * @brief   Listen-Komponente für Clearing
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */

// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ngx-translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Serivces importieren
import {StorageService} from '@global/services/storage.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
// Eigener Service
import {ClearingService} from './../clearing.service';
// Shared Services importieren
import {GridService} from './../../grid/grid.service';
import {ToolbarService} from './../../toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../../cw-event';
import {CWResult} from './../../cw-result';
import {LooseObject} from './../../loose-object';
// Environment
import {environment} from '@environment';

@Component({
    selector: 'phscw-clearing-display',
    templateUrl: './clearing-display.component.html',
    styleUrls: ['./clearing-display.component.scss'],
})
export class ClearingDisplayComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Id des Grids
    @Input() gridId = 'clearingDisplay';
    // Typ der Entität
    @Input() entityType = '';

    // Spaltendefinitionen für Grid
    gridColumns = [
        {
            columnDef: 'create_date',
            header: 'Datum',
            cell: (element: LooseObject) => `${element.create_date}`,
            formatTemplate: 'datetime',
        },
        {
            columnDef: 'create_user',
            header: 'Von',
            cell: (element: LooseObject) => `${element.create_user}`,
        }, // , formatWidth: '125px'
        {
            columnDef: 'before',
            header: 'Vorher',
            cell: (element: LooseObject) => {
                if (element.value_before_shownText || element.value_before_shownText === '') {
                    return `${element.value_before_shownText}`;
                } if (element.value_before) {
                    return `${element.value_before}`;
                }
                return '';
            },
            formatTemplate: 'textarea',
        },
        {
            columnDef: 'after',
            header: 'Nachher',
            cell: (element: LooseObject) => {
                if (element.value_after_shownText || element.value_after_shownText === '') {
                    return `${element.value_after_shownText}`;
                } if (element.value_after) {
                    return `${element.value_after}`;
                }
                return '';
            },
            formatTemplate: 'textarea',
        },
        // Sonderspalte für Netzwerke
        {
            columnDef: 'relationship-type',
            header: 'Beziehungstyp',
            columnField: 'entity_key3',
            formatTemplate: 'listentries',
            listentry: ['institutionsRelationshipType'],
        },
    ];

    // anzuzeigende Spalten
    @Input() gridDisplayedColumns: string[] = [];

    // zu prüfende Daten
    @Input() dataLeft: any = {};
    @Input() dataRight: any = {};
    @Input() gridData: any[] = [];
    originalGridData: any[] = [];
    characteristicsLeft: any = {};
    characteristicsRight: any = {};

    // "Mehr laden..."
    @Input() loadMoreEnabled = false;
    @Input() loadMoreVisible = true;
    @Input() gridPage = 1;

    // Attribute, die bei Vergleich übersprungen werden sollen (Merge)
    @Input() comparableColumns: string[] = [];
    // Flag definiert ob Spalten verglichen werden müssen
    private _compareColumns = false;
    @Input()
    set compareColumns(value: boolean) {
        // Flag setzen
        this._compareColumns = value;
        // Grid vorbereiten
    }

    get compareColumns() {
        return this._compareColumns;
    }

    // Flag definiert ob alle Reihen angezeigt werden sollen (Merge)
    showAllRows = false;
    // Flag definiert ob gerade geladen wird
    loading = false;
    // Flag definiert ob gerade gespeichert wird
    saving = false;

    // Mit Checkbox ausgewählte Reihen (Changelogs)
    checkedRows: any[] = [];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param storageService
     * @param userPermissionsService
     * @param gridService
     * @param toolbarService
     * @param clearingService
     * @param translateService
     */
    constructor(
        private storageService: StorageService,
        private userPermissionsService: UserPermissionsService,
        private gridService: GridService,
        private toolbarService: ToolbarService,
        private clearingService: ClearingService,
        private translateService: TranslateService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();

        this.prepareGridData();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Events subscriben
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeEventSubscriptions(): void {
        // Checkbox im Grid wurde angeklickt... (Changelogs, falls mehrere Changelogs auf einmal akzeptiert) - nicht in Verwendung
        this.gridService.eventGridCheckboxClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls Anfrage erfolglos war
                if (event.sender == this.gridId) {
                    return;
                }
                this.onEventGridCheckboxClicked(event);
            });

        // Event der Toolbar zum Ändern des Clearing-Status (Changelogs) - nicht in Verwendung
        this.toolbarService.eventNext.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls Anfrage erfolglos war
            if (event.sender == this.gridId) {
                return;
            }
            this.updateChangelogsClearingStatus();
        });

        // Event der Toolbar zum Abschließen des Clearing-Status (Changelogs)
        this.toolbarService.eventCompleteProcess
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls Anfrage erfolglos war
                if (event.target !== this.gridId) {
                    return;
                }
                this.updateChangelogsClearingStatus(true);
            });

        // Event der Toolbar zum Zurücksetzen der Änderung und Abschließen des Clearing-Status (Changelogs)
        this.toolbarService.eventDeleteItem.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls Anfrage erfolglos war
            if (event.target !== this.gridId) {
                return;
            }
            this.declineChangelogs();
        });

        // Event des Clearing zum Beenden der Animation (Merge)
        this.clearingService.eventUpdateEntityDataComplete
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls Anfrage erfolglos war
                if (event.sender == this.gridId) {
                    return;
                }
                this.onEventUpdateEntityDataComplete();
            });
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     * @author  Min Hye Park <m.park@pharmakon.software>
     */
    initializeTranslateSubscriptions(): void {
        this.translateService
            .stream(['GENERAL.DATE', 'GENERAL.FROM', 'GENERAL.CHANGE', 'GENERAL.BEFORE', 'GENERAL.AFTER'])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: any) => {
                this.gridColumns.find((column: any) => column.columnDef === 'create_date').header =
                    translation['GENERAL.DATE'];
                this.gridColumns.find((column: any) => column.columnDef === 'create_user').header =
                    translation['GENERAL.FROM'];
                this.gridColumns.find((column: any) => column.columnDef === 'before').header =
                    translation['GENERAL.BEFORE'];
                this.gridColumns.find((column: any) => column.columnDef === 'after').header =
                    translation['GENERAL.AFTER'];
            });
    }

    /**
     * @param event
     * @brief   Auf Event "eventGridCheckboxClicked" von "gridService" reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventGridCheckboxClicked(event: CWEvent): void {
        // Die Angehakten Reihen in Variable speichern.
        this.checkedRows = event.data['selection'];
    }

    /**
     * @brief   Ausgewählte Entitäten zurücksetzen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    declineChangelogs(): void {
        // Informationen bereitstellen für einbindende Komponente
        const formData = {selectedEntities: this.checkedRows};

        // Daten ändern
        this.clearingService.revertChanges(this.gridId, formData);
    }

    /**
     * @param done
     * @brief   Clearing-Status für ausgewählte Entitäten aktualisieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    updateChangelogsClearingStatus(done = false): void {
        // Informationen bereitstellen für einbindende Komponente
        const formData = {
            selectedEntities: this.checkedRows,
            done,
        };

        // Daten ändern
        this.clearingService.updateEntityData(this.gridId, formData);
    }

    /**
     * @brief   Auf Ende des Merge reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventUpdateEntityDataComplete(): void {
        // Flag "saving" deaktivieren
        this.saving = false;
    }

    /**
     * @param value
     * @param listKey
     * @brief   Mögliche Auswahlmöglichkeiten für Selects aus Listentries empfangen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onGetListentries(value: any, listKey: any): string {
        // Initialisiere
        let listValue = '';

        // Falls Eintrag aus "listentries" vorhanden ist
        if (value) {
            // Listentries durchlaufen
            for (const source of value) {
                if (source['list_key'] == listKey) {
                    listValue = source['list_value'];
                    break;
                }
            }
        }

        // Ergebnis zurückgeben
        return listValue;
    }

    /**
     * @brief   Zum Zusammenführen von Duplikaten übergebene Daten neu anordnen für Anzeige in Grid
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    prepareGridData(): void {
        // Initialisiere
        this.originalGridData = [];
        this.loading = true;

        // Attribute der Entitäten in Reihen aufteilen
        this.reorganizeGridData(this.dataLeft, this.dataRight);
    }

    /**
     * @brief   Gleiche Werte in Grid verstecken
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    filterRows(): void {
        // Anzuzeigende Reihen filtern
        this.originalGridData.forEach((row) => {
            if (row['value_before'] == row['value_after']) {
                row['hidden'] = true;
            }
        });

        // Grid-Data füllen
        this.gridData = this.originalGridData;
    }

    /**
     * @brief   Zeige alle Reihen oder verstecke ausgewählte Reihen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    toggleRowsVisibility(): void {
        this.showAllRows = !this.showAllRows;
    }

    /**
     * @brief   Zusammenführen von Entitäten durchführen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickSubmit(): void {
        // Initialisere
        let mergedEntity: any = {};

        // Animation starten
        this.saving = true;

        // neue Entität zusammenführen
        this.gridData.forEach((row: any) => {
            if (row.rowSeparation) {
                // leere Zeile überspringen

            } else if (row.parentKey.length === 0 || row.changed_field === 'main_institution') {
                // Prüfen ob eine Einrichtung ausgewählt, wurde und somit ID vorliegt -> ignorieren, wenn String ('N' oder 'Y') vorliegt
                if (row.changed_field === 'main_institution' && typeof row.value_final !== 'number') {
                    // nichts tun
                } else {
                    // Daten ohne parentKey direkt übernehmen
                    mergedEntity[row.changed_field] = row.value_final;
                }
            } else {
                // Parent Hierarchy übergeben an Methode
                const arrayHierarchy = row.parentKey.split('.');

                // ursprüngliche Struktur zusammenbauen
                mergedEntity = this.rebuildEntityData(
                    mergedEntity,
                    arrayHierarchy,
                    row,
                    row.changed_field,
                    row.value_final,
                );
            }
        });

        // Daten aufbereiten
        const formData = {
            mergedEntity,
            firstEntity: this.dataLeft,
            secondEntity: this.dataRight,
            entityType: this.entityType,
        };

        // Event mit Daten der neuen Entität auslösen
        this.clearingService.updateEntityData(this.gridId, formData);
    }

    /**
     * @brief   Zusammenführen von Entitäten abbrechen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickCancel(): void {
        // Event auslösen, damit Popup-Komponente Dialog schließen kann
        this.toolbarService.closeComponent(this.gridId);
    }

    /**
     * @param dataLeft
     * @param dataRight
     * @param parentKey
     * @param iteration
     * @brief   Zum Zusammenführen von Duplikaten übergebene Daten neu anordnen für Anzeige in Grid
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private reorganizeGridData(dataLeft: any, dataRight: any, parentKey: any = '', iteration = 0): void {
        // Keys der Attribute der Entitäten zwischenspeichern
        const properties = Object.keys(dataLeft);

        // Daten prüfen für jeden Key
        properties.forEach((key: any, index: number) => {
            // Prüfe Config ob Attribut angezeigt werden soll
            const entityAttribute: any = this.getAttributeFromPassedConfig(parentKey, key);

            // Nur bestimmte Daten anzeigen
            if (
                this.hasOnlyDigits(parentKey.substring(parentKey.lastIndexOf('.') + 1)) &&
                typeof dataLeft[key] !== 'object'
            ) {
                /**
                 * Iteration überspringen wenn der letzte Teil des parentKey eine
                 * Zahl ist die Daten für diese Iteration kein Objekt sind
                 *
                 * Überspringen notwendig weil an dieser Stelle in Daten eines
                 * anderen Typs gegangen wird die für das Zusammenführen nicht
                 * beachtet werden sollen (z.B. Einrichtungs-Array bei Personen)
                 */

            } else if (typeof dataLeft[key] === 'object' && dataLeft[key] !== null) {
                /**
                 * ParentKey zusammensetzen und Methode erneut aufrufen mit neuen
                 * Daten wenn die Daten für diese Iteration ein Objekt sind
                 */
                // Zuordnung Join-Data
                if (parentKey.length > 0 && this.hasOnlyDigits(parentKey.substr(-1)) && this.hasOnlyDigits(key)) {
                    // Daten in richtigen Array Format anhängen - wird nicht verwendet
                    const splitString = parentKey.split('.');
                    splitString[splitString.length - 1] = key;
                    parentKey = splitString.join('.');

                    // Nebeneinrichtung -> überspringen
                    return;
                } if (parentKey.length > 0) {
                    parentKey += '.' + key;
                } else {
                    parentKey = key;
                }

                // Rekursiver Aufruf mit Sub-Array
                if (Object.prototype.hasOwnProperty.call(dataRight, key)) {
                    this.reorganizeGridData(dataLeft[key], dataRight[key], parentKey, iteration + 1);
                } else {
                    this.reorganizeGridData(dataLeft[key], {}, parentKey, iteration + 1);
                }
            } else if (entityAttribute !== undefined) {
                /**
                 * Wenn es sich bei den Daten für diese Iteration nicht um ein
                 * Objekt handelt und das Attribut des Objekts in den Stammdaten
                 * angezeigt wird (environment Konfiguration) werden die Daten
                 * in eine Reihe gespeichert und an das Array zum vorbereiten der
                 * GridDaten angehängt
                 */

                // Initialisiere
                const newRow: any = {};

                // Daten zwischenspeichern
                newRow['field_id'] = index;
                newRow['parentKey'] = parentKey;
                newRow['changed_field'] = key;
                newRow['value_before'] = dataLeft[key];
                newRow['value_after'] = dataRight[key];

                // Daten aus Config laden
                if (entityAttribute !== undefined) {
                    newRow['changed_field_shownText'] = this.comparableColumns[entityAttribute]['label'];
                    newRow['cwInputType'] = this.comparableColumns[entityAttribute]['cwInputType'];
                    newRow['disabled_input'] = this.comparableColumns[entityAttribute]['disabled'];
                } else {
                    newRow['changed_field_shownText'] = this.comparableColumns[key]['label'];
                    newRow['cwInputType'] = this.comparableColumns[key]['cwInputType'];
                    newRow['disabled_input'] = this.comparableColumns[key]['disabled'];
                }

                // vorgeschlagenen End-Wert setzen
                this.setLastColumnValues(newRow);
                // Spezielle Spalten anpassen
                this.setSpecificRowData(newRow, parentKey, key, entityAttribute, dataLeft, dataRight);

                // neue Reihe zwischenspeichern
                this.originalGridData.push(newRow);
            }
        });

        // Kennzeichen nur für bestimmte Entitäten laden
        if (iteration === 0 && (this.entityType === 'People' || this.entityType === 'Institutions')) {
            // Initialisiere
            const formData = {
                entityType: this.entityType,
                entityIds: [dataLeft.id, dataRight.id],
            };

            // Kennzeichen aus Datenbank laden
            const serviceRequest$ = this.clearingService.getCharacteristicsData(formData);
            serviceRequest$.subscribe(
                (result: CWResult) => {
                    // Kennzeichen zuordnen
                    this.reorganizeCharacteristicsData(result['data'][0], 'before', dataLeft.id);
                    this.reorganizeCharacteristicsData(result['data'][1], 'after', dataRight.id);

                    // Animation beenden
                    this.loading = false;
                },
                (error: any) => {
                    // Animation beenden
                    this.loading = false;
                },
            );
        } else {
            // Animation beenden
            this.loading = false;
        }
    }

    /**
     * @param parentKey
     * @param key
     * @brief   Prüfe, ob Wert angezeigt werden soll
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private getAttributeFromPassedConfig(parentKey: string, key: string): string {
        // Initialisiere
        let returnValue = '';

        // Zu findenden Key definieren
        let checkKey = '';
        if (parentKey.length > 0) {
            checkKey = parentKey + '__' + key;
        } else {
            checkKey = key;
        }

        // Prüfe ob erlaubte Spalten als untergeordnetes Array vorliegen (z.B. joinData)
        returnValue = Object.keys(this.comparableColumns).find((columnKey: string) => {
            const split = columnKey.split('__');

            if (!this.comparableColumns[columnKey]['visible']) {
                return false;
            } if (split.length > 1 && parentKey.endsWith(split[0])) {
                return checkKey.endsWith('._' + columnKey);
            } if (split.length === 1) {
                return checkKey === columnKey;
            }

            return false;
        });

        // Schlüssel zurückgeben
        return returnValue;
    }

    /**
     * @param row
     * @param parentKey
     * @param key
     * @param entityAttribute
     * @param dataLeft
     * @param dataRight
     * @brief   Bestimmte Daten spezifisch behandeln
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private setSpecificRowData(
        row: any,
        parentKey: string,
        key: string,
        entityAttribute: any,
        dataLeft: any,
        dataRight: any,
    ): void {
        // Init
        const lockedId = environment.enableClearingDisplayLockedId ? environment.enableClearingDisplayLockedId : false;

        // Prüfe wie die Reihe angepasst werden muss
        if (key === 'id' && lockedId) {
            // Auswahl sperren
            row['disabled_input'] = true;
            row['disabled'] = true;
        } else if (key === 'id' && !lockedId) {
            // immer die ältere ID verwenden
            if (dataLeft[key] < dataRight[key]) {
                row['value_final'] = dataLeft[key];
            } else {
                row['value_final'] = dataRight[key];
            }
            row['disabled_input'] = true;
        } else if (key === 'main_institution') {
            // für Haupteinrichtung lesbaren Namen anzeigen und als Wert die ID setzen
            row['value_before'] = dataLeft['institution_id'];
            row['value_after'] = dataRight['institution_id'];
            const subKeys = parentKey.split('.');
            // prüfen, ob Wert existiert, um Fehler in Konsole zu verhindern

            const dataLeftName1Exists =
                Object.prototype.hasOwnProperty.call(this.dataLeft, 'institutions') &&
                Object.prototype.hasOwnProperty.call(this.dataLeft['institutions'], [subKeys[subKeys.length - 2]]) &&
                Object.prototype.hasOwnProperty.call(
                    this.dataLeft['institutions'][subKeys[subKeys.length - 2]],
                    'name1',
                );
            const dataRightName1Exists =
                Object.prototype.hasOwnProperty.call(this.dataRight, 'institutions') &&
                Object.prototype.hasOwnProperty.call(this.dataRight['institutions'], [subKeys[subKeys.length - 2]]) &&
                Object.prototype.hasOwnProperty.call(
                    this.dataRight['institutions'][subKeys[subKeys.length - 2]],
                    'name1',
                );
            if (dataLeftName1Exists) {
                row['value_before_shownText'] = this.dataLeft['institutions'][subKeys[subKeys.length - 2]]['name1'];
            } else {
                row['value_before_shownText'] = '';
            }
            if (dataRightName1Exists) {
                row['value_after_shownText'] = this.dataRight['institutions'][subKeys[subKeys.length - 2]]['name1'];
            } else {
                row['value_after_shownText'] = '';
            }

            // Prüfe ob in beiden Array eine Einrichtung existiert
            if (
                !lockedId &&
                typeof dataLeft['institution_id'] !== 'undefined' &&
                typeof dataRight['institution_id'] !== 'undefined'
            ) {
                // immer die ältere Einrichtung verwenden für finalen Wert, falls vorhanden
                if (this.dataLeft['id'] <= this.dataRight['id']) {
                    row['value_final'] = dataLeft['institution_id'];
                    row['value_final_shownText'] =
                        this.dataLeft['institutions'][subKeys[subKeys.length - 2]]['name1'] || '';
                } else if (this.dataLeft['id'] > this.dataRight['id']) {
                    row['value_final'] = dataRight['institution_id'];
                    row['value_final_shownText'] =
                        this.dataRight['institutions'][subKeys[subKeys.length - 2]]['name1'] || '';
                }
            } else if (!lockedId && typeof dataLeft['institution_id'] !== 'undefined') {
                row['value_final'] = dataLeft['institution_id'];
                row['value_final_shownText'] =
                    this.dataLeft['institutions'][subKeys[subKeys.length - 2]]['name1'] || '';
            } else if (typeof dataRight['institution_id'] !== 'undefined') {
                row['value_final'] = dataRight['institution_id'];
                row['value_final_shownText'] =
                    this.dataRight['institutions'][subKeys[subKeys.length - 2]]['name1'] || '';
            }

            // Direkteingabe deaktivieren
            row['disabled_input'] = true;

            // Einrichtung verlinken
            if (lockedId) {
                row['entityOverlayController'] = 'InstitutionsData';
            }
        } else if (
            key === 'erp_number' &&
            !Object.prototype.hasOwnProperty.call(this.comparableColumns[key], 'disabled')
        ) {
            // Berechtigung abfragen
            const allowEditInstitutionErpNumber: boolean = this.userPermissionsService.getPermissionValue(
                'allowEditInstitutionErpNumber',
            );

            // Falls keine Berechtigung erteilt wurde, Direkteingabe deaktivieren
            if ((dataLeft.id > 0 || dataRight.id > 0) && !allowEditInstitutionErpNumber) {
                row['disabled_input'] = true;
            } else {
                row['disabled_input'] = false;
            }
        } else if (key === 'addressfield') {
            const leftJoinHasFlagAutomatic = Object.prototype.hasOwnProperty.call(
                this.dataLeft.institutions[0]._joinData,
                'flagAutomatic',
            );
            const leftMainHasFlagAutomatic = Object.prototype.hasOwnProperty.call(dataLeft, 'flagAutomatic');
            // Linke Spalte auf manuelle Adressdaten prüfen
            if (
                leftJoinHasFlagAutomatic === false ||
                (leftJoinHasFlagAutomatic && this.dataLeft.institutions[0]._joinData.flagAutomatic === true) ||
                leftMainHasFlagAutomatic === false ||
                (leftMainHasFlagAutomatic && dataLeft.flagAutomatic === true)
            ) {
                row['value_before'] = null;
                row['value_before_shownText'] = '';
            }
            // Rechte Spalte auf manuelle Adressdaten prüfen

            const rightJoinHasFlagAutomatic = Object.prototype.hasOwnProperty.call(
                this.dataRight.institutions[0]._joinData,
                'flagAutomatic',
            );
            const rightMainHasFlagAutomatic = Object.prototype.hasOwnProperty.call(dataRight, 'flagAutomatic');
            if (
                rightJoinHasFlagAutomatic === false ||
                (rightJoinHasFlagAutomatic && this.dataRight.institutions[0]._joinData.flagAutomatic === true) ||
                rightMainHasFlagAutomatic === false ||
                (rightMainHasFlagAutomatic && dataRight.flagAutomatic === true)
            ) {
                row['value_after'] = null;
                row['value_after_shownText'] = '';
            }

            // Finale Splte korrigieren falls notwendig
            if (row['value_before'] === null && row['value_after'] === null) {
                row['value_final'] = null;
                row['value_final_shownText'] = '';
            }
        } else if (
            entityAttribute.startsWith('joinData__') &&
            Object.prototype.hasOwnProperty.call(this.comparableColumns[entityAttribute], 'cwInputType') &&
            this.comparableColumns[entityAttribute]['cwInputType'] === 'select'
        ) {
            // Listentries mit lesbaren Daten anzeigen
            const promise = this.storageService.getItem(
                'listentries|' + this.comparableColumns[entityAttribute]['cwListentries'],
            );
            promise.then((val) => {
                row['value_before_shownText'] = this.onGetListentries(val, dataLeft[key]);
                row['value_after_shownText'] = this.onGetListentries(val, dataRight[key]);
            });

            // Select mit korrekter Liste anzeigen
            row['cwListentries'] = this.comparableColumns[entityAttribute]['cwListentries'];
        } else if (
            Object.prototype.hasOwnProperty.call(this.comparableColumns[entityAttribute], 'cwInputType') &&
            this.comparableColumns[entityAttribute]['cwInputType'] === 'select'
        ) {
            // Listentries mit lesbaren Daten anzeigen
            const promise = this.storageService.getItem(
                'listentries|' + this.comparableColumns[entityAttribute]['cwListentries'],
            );
            promise.then((val) => {
                row['value_before_shownText'] = this.onGetListentries(val, dataLeft[entityAttribute]);
                row['value_after_shownText'] = this.onGetListentries(val, dataRight[entityAttribute]);
            });

            // Select mit korrekter Liste anzeigen
            row['cwListentries'] = this.comparableColumns[entityAttribute]['cwListentries'];
        }
    }

    /**
     * @param row
     * @param attributeSuffix
     * @brief   Werte und Label der letzen Spalte für Kennzeichen setzen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private setLastColumnValues(row: any, attributeSuffix = ''): void {
        // vorgeschlagene End-Werte setzen
        if (row['value_after']) {
            row['value_final' + attributeSuffix] = row['value_after' + attributeSuffix];
        } else if (row['value_before']) {
            row['value_final' + attributeSuffix] = row['value_before' + attributeSuffix];
        } else {
            row['value_final'] = null;
        }

        // Change detection im Grid auslösen
        this.gridService.triggerGridChangeDetection();
    }

    /**
     * @param data
     * @param valueSuffix
     * @param entityId
     * @brief   Zum Zusammenführen von Duplikaten übergebene Kennzeichen neu anordnen für Anzeige in Grid
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private reorganizeCharacteristicsData(data: any, valueSuffix: string, entityId: number): void {
        // Schlüssel der Kennzeichen zwischenspeichern
        const characteristics = Object.keys(data);
        characteristics.forEach((key, index) => {
            // Visuelle Trennung zwischen Stammdaten und Kennzeichen
            const seperatorExists = this.originalGridData.some((row) => row['rowSeparation'] === true);
            if (!seperatorExists && index === 0) {
                const newRow: any = {};
                newRow['rowSeparation'] = true;
                newRow['changed_field'] = this.translateService.instant('GENERAL.CHARACTERISTICS');
                newRow['disabled_input'] = true;

                // leere Reihe einfügen
                this.originalGridData.push(newRow);
            }

            // Kennzeichen-Daten in Reihe einfügen
            const row = this.originalGridData.filter((row) => row['changed_field'] == key);
            if (row.length > 0) {
                // Reihe erweitern
                row[0]['value_' + valueSuffix] = data[key]['value'];
                this.getCharacteristicOptionValues(row[0], Number(key), data[key]['group_id'], valueSuffix, entityId);
            } else {
                // neue Reihe anlegen
                const newRow: any = {};
                newRow['parentKey'] = 'characteristics';
                newRow['changed_field'] = key;
                newRow['value_' + valueSuffix] = data[key]['value'];

                // Kennzeichen Optionen Daten zuordnen
                this.getCharacteristicOptionValues(newRow, Number(key), data[key]['group_id'], valueSuffix, entityId);

                // neue Reihe zwischenspeichern
                this.originalGridData.push(newRow);
            }
        });

        // Prüfe welche Reihen angezeigt werden müssen (gleicher Wert bei beiden Entitäten kann ausgeblendet werden)
        this.filterRows();
    }

    /**
     * @param row
     * @param characteristicId
     * @param characteristicGroupId
     * @param valueSuffix
     * @param entityId
     * @brief   Daten für Kennzeichen Optionen laden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private getCharacteristicOptionValues(
        row: any,
        characteristicId: number,
        characteristicGroupId: number,
        valueSuffix: string,
        entityId: number,
    ): void {
        // Kennzeichen in Gruppe finden
        const promise = this.storageService.getItem('characteristicsForGroup|' + characteristicGroupId);
        promise.then((characteristicGroup: any) => {
            // Initialisere
            let initializedValue = null;
            let valueType = null;
            let optionValueType = null;

            // Prüfen ob Kennzeichengruppe gefunden wurde
            if (characteristicGroup !== null) {
                initializedValue = characteristicGroup.filter(
                    (characteristic: any) => characteristic['id'] == characteristicId,
                );
                valueType = initializedValue[0]['value_type'];
                optionValueType = initializedValue[0]['option_value_type'];

                // Daten zwischenspeichern
                row['changed_field_shownText'] = initializedValue[0]['label'];
                row['option_value_type'] = optionValueType;
                row['value_type'] = valueType;

                // Sonderfall Mehrfachkennzeichen
                if (
                    row['parentKey'] === 'characteristics' &&
                    row['value_type'] === 'options' &&
                    row['option_value_type'] === 'checkbox'
                ) {
                    // Mehrfachkennzeichen zusammenführen
                    row['value_final'] = {
                        ...row['value_before'],
                        ...row['value_after'],
                    };
                    // Flag setzen für Anzeige
                    row['automerge'] = true;
                }

                // Labels setzen
                this.setCharacteristicOptionLabel(
                    row,
                    initializedValue[0].options,
                    valueType,
                    optionValueType,
                    valueSuffix,
                );
            } else {
                // Initialisiere
                const formData = {
                    entityId,
                    characteristicId,
                    entityType: this.entityType,
                };

                // Daten aus Backend laden
                const serviceRequest$ = this.clearingService.getIndividualCharacteristic(formData);
                serviceRequest$.subscribe((result: CWResult) => {
                    // Werte zwischenspeichern
                    const data = result['data'][0];
                    valueType = data['value_type'];
                    optionValueType = data['characteristic_option_value_type'];

                    // Daten setzen
                    row['changed_field_shownText'] = data['characteristic_label'];
                    row['option_value_type'] = optionValueType;
                    row['value_type'] = valueType;
                    row['value_' + valueSuffix + '_shownText'] = data['CharacteristicOptions']['label'];

                    // Zwischen Mehrfachkennzeichen und normalen Kennzeichen unterscheiden
                    if (
                        row['parentKey'] === 'characteristics' &&
                        row['value_type'] === 'options' &&
                        row['option_value_type'] === 'checkbox'
                    ) {
                        // Mehrfachkennzeichen zusammenführen
                        row['value_final'] = {
                            ...row['value_before'],
                            ...row['value_after'],
                        };
                        // Flag setzen für Anzeige
                        row['automerge'] = true;
                        // in finales Ergebnis Text zusätzlich schreiben
                        this.setLastColumnMultiCharacteristicOptionLabel(row, data['CharacteristicOptions']['label']);
                    } else {
                        // Anzeigetext der letzten Spalte setzen
                        this.setLastColumnValues(row, '_shownText');
                    }
                });
            }
        });

        // vorgeschlagenen End-Wert setzen
        this.setLastColumnValues(row);
    }

    /**
     * @param row
     * @param options
     * @param valueType
     * @param optionValueType
     * @param valueSuffix
     * @brief   Kennzeichen Optionen vernünftig darstellen abhängig von der Art der Daten
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private setCharacteristicOptionLabel(
        row: any,
        options: any,
        valueType: string,
        optionValueType: string,
        valueSuffix: string,
    ): void {
        // Lesbare Daten vorbereiten
        if (valueType === 'options' && (optionValueType === 'combobox' || optionValueType === 'radiobutton')) {
            // Kennzeichen Wert laden
            const characteristicOption = options.filter((option: any) => option['id'] == row['value_' + valueSuffix]);
            row['value_' + valueSuffix + '_shownText'] = characteristicOption[0]['label'];
        } else if (valueType === 'options' && optionValueType === 'checkbox') {
            // Initialisiere
            row['value_' + valueSuffix + '_shownText'] = '';

            // ID's sammeln
            const selectedOptionIds = Object.keys(row['value_' + valueSuffix]);
            // Kennzeichen Werte laden
            const selectedCharacteristicOptions = options.filter((option: any) => selectedOptionIds.includes(String(option['id'])));
            selectedCharacteristicOptions.forEach((option: any, index: number) => {
                // Zeilenumbrüche
                if (index !== 0) {
                    row['value_' + valueSuffix + '_shownText'] += '\r\n';
                }
                // Wert anhängen
                row['value_' + valueSuffix + '_shownText'] += option['label'];

                // in letzte Spalte Text zusätzlich schreiben
                this.setLastColumnMultiCharacteristicOptionLabel(row, option['label']);
            });
        }

        // vorgeschlagenes End-Wert Label setzen wenn es sich nicht um ein Mehrfachkennzeichen handelt
        if (
            !(
                row['parentKey'] === 'characteristics' &&
                row['value_type'] === 'options' &&
                row['option_value_type'] === 'checkbox'
            )
        ) {
            this.setLastColumnValues(row, '_shownText');
        }
    }

    /**
     * @param row
     * @param optionLabel
     * @brief   angezeigten Text in letzter Spalte von Mehrfachkennzeichen zusammenbauen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private setLastColumnMultiCharacteristicOptionLabel(row: any, optionLabel: string): void {
        if (row['value_final_shownText'] && row['value_final_shownText'].includes(optionLabel) === false) {
            if (row['value_final_shownText'].length > 0) {
                row['value_final_shownText'] += '\r\n';
            }
            row['value_final_shownText'] += optionLabel;
        } else if (!row['value_final_shownText']) {
            row['value_final_shownText'] = optionLabel;
        }
    }

    /**
     * @param entity
     * @param keys
     * @param row
     * @param field
     * @param value
     * @param index
     * @param characteristics
     * @brief   Datenstruktur einer Entität wiederherstellen (analog zu reorganizeGridData)
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private rebuildEntityData(
        entity: any,
        keys: any,
        row: any,
        field: any,
        value: any,
        index = 0,
        characteristics: any = false,
    ): any {
        if (keys[index] === 'characteristics') {
            characteristics = true;
        }

        if (index < keys.length) {
            if (typeof entity[keys[index]] !== 'object') {
                entity[keys[index]] = {};
            }

            // Rekursiver Aufruf
            entity[keys[index]] = this.rebuildEntityData(
                entity[keys[index]],
                keys,
                row,
                field,
                value,
                index + 1,
                characteristics,
            );
        } else {
            entity[field] = value;

            if (characteristics) {
                entity[field] = {
                    value,
                    value_type: row.value_type,
                };
            }
        }

        return entity;
    }

    /**
     * @param value
     * @brief   Prüfe ob eine Variable nur aus Zahlen besteht
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    hasOnlyDigits(value: any): boolean {
        return /^\d+$/.test(value);
    }
}
