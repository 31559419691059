/**
 * @brief   Popup für Kontaktformular
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */

// Angular-Module
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service des Kontakt-Moduls
import {ContactsService} from './../contacts.service';
// Shared Services importieren
import {GridService} from '@shared/grid/grid.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';

// Moment-Modul zur Datums-Verarbeitung
import * as _moment from 'moment';
import {hasOwn} from '@shared/utils';

const moment = _moment;

// Interface für Dialogdaten
export interface ContactsPopupDialogData {
    contactId?: number;
    customContactType?: string;
    autoChangeContactType?: string;
    selectedEntities?: any[];
    personId?: number;
    enablePersonSelection?: boolean;
    resetPersonSelection?: boolean;
    patientId?: number;
    institutionId?: number;
    opportunityId?: number;
    isInstitutionContact?: boolean;
    sampleAuthorization?: boolean;
    displayInstitution?: boolean;
    contactStartDay?: any;
    contactStartTime?: any;
    collectiveEntry?: any;
    allowChangeInstitutionSelection?: boolean;
    parentGridName?: string;
}

@Component({
    selector: 'phscw-contacts-form-popup',
    templateUrl: './contacts-form-popup.component.html',
    styleUrls: ['./contacts-form-popup.component.scss'],
})
export class ContactsFormPopupComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    backendController = 'PeopleContacts';

    // ID des Kontakts
    contactId = 0;

    // für Kontakt ausgewählte Entitäten
    selectedEntities: any[] = [];
    // ID's für Kontakte
    personId: number;
    institutionId: number;
    opportunityId: number;

    // Berechtigung für Muster
    sampleAuthorization = false;
    // Flag definiert, ob die Person auswählbar sein soll
    enablePersonSelection = false;
    // Flag definiert, ob die Einrichtung angezeigt werden soll
    displayInstitution = false;
    // Flag definiert, ob die Personenauswahl zurückgesetzt werden soll
    resetPersonSelection = false;
    // Flag definiert, ob die Einrichtung bei bestehenden Kontakten auch noch geändert werden kann
    allowChangeInstitutionSelection = false;
    // Flag definiert, ob es sich um einen Einrichtungskontakt handelt
    isInstitutionContact = false;

    // Benutzerdefiniertes Startdatum und Uhrzeit
    customStartDate: _moment.Moment = null;
    customStartTime: string = null;
    // Benutzerdefinierter Kontakttyp
    customContactType: string = null;
    // Nach dem Laden kann der Typ eines bestehenden Kontakts automatisch umgewandelt werden
    autoChangeContactType: string = null;

    participantsHaveEmailSet = false;

    collectiveEntry = false;

    /*
     * Name der einbindenden Komponente
     * Um leichtere Kommunikation mit der parent-Komponente zu ermöglichen
     *
     * z.B. wenn sich Daten ändern, kann in der Parent-Komponente das Grid aktualisiert werden
     */
    parentGridName: string | null = null;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param data
     * @param dialogRef
     * @param toolbarService
     * @param gridService
     * @param contactsService
     */
    constructor(
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) private data: ContactsPopupDialogData,
        private dialogRef: MatDialogRef<ContactsFormPopupComponent>,
        private toolbarService: ToolbarService,
        private gridService: GridService,
        private contactsService: ContactsService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Daten für Template zwischenspeichern
        this.setTemplateData();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions() {
        // Wenn der Close-Button der Toolbar ausgelöst wurde, Abbrechen auslösen.
        this.toolbarService.eventCloseComponent
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht für Komponente gesendet wurde
                if (event.target !== 'contacts-form-popup') {
                    return;
                }
                this.clickClose();
            });
    }

    /**
     * Daten für Kontaktformular zwischenspeichern
     */
    setTemplateData(): void {
        if (Object.prototype.hasOwnProperty.call(this.data, 'selectedEntities')) {
            this.selectedEntities = this.data.selectedEntities;
        }

        if (Object.prototype.hasOwnProperty.call(this.data, 'personId')) {
            this.personId = this.data.personId;
        }

        if (Object.prototype.hasOwnProperty.call(this.data, 'institutionId')) {
            this.institutionId = this.data.institutionId;
        }

        if (Object.prototype.hasOwnProperty.call(this.data, 'sampleAuthorization')) {
            this.sampleAuthorization = this.data.sampleAuthorization;
        }

        if (Object.prototype.hasOwnProperty.call(this.data, 'contactStartDay')) {
            this.customStartDate = moment(this.data.contactStartDay);
        }

        if (Object.prototype.hasOwnProperty.call(this.data, 'contactStartTime')) {
            this.customStartTime = this.data.contactStartTime;
        }

        if (Object.prototype.hasOwnProperty.call(this.data, 'customContactType')) {
            this.customContactType = this.data.customContactType;
        }

        if (Object.prototype.hasOwnProperty.call(this.data, 'autoChangeContactType')) {
            this.autoChangeContactType = this.data.autoChangeContactType;
        }

        if (Object.prototype.hasOwnProperty.call(this.data, 'enablePersonSelection')) {
            this.enablePersonSelection = this.data.enablePersonSelection;
        }

        if (Object.prototype.hasOwnProperty.call(this.data, 'resetPersonSelection')) {
            this.resetPersonSelection = this.data.resetPersonSelection;
        }

        if (Object.prototype.hasOwnProperty.call(this.data, 'displayInstitution')) {
            this.displayInstitution = this.data.displayInstitution;
        }

        if (Object.prototype.hasOwnProperty.call(this.data, 'allowChangeInstitutionSelection')) {
            this.allowChangeInstitutionSelection = this.data.allowChangeInstitutionSelection;
        }

        if (Object.prototype.hasOwnProperty.call(this.data, 'collectiveEntry')) {
            this.collectiveEntry = this.data.collectiveEntry;
        }

        if (Object.prototype.hasOwnProperty.call(this.data, 'isInstitutionContact')) {
            this.isInstitutionContact = this.data.isInstitutionContact;
        }

        if (hasOwn(this.data, 'opportunityId')) {
            this.opportunityId = this.data.opportunityId;
        }

        if (hasOwn(this.data, 'parentGridName')) {
            this.parentGridName = this.data.parentGridName;
        }

        this.participantsHaveEmailSet = true;
        for (const item of this.selectedEntities) {
            if (!item['institutions'] || item['institutions'].length === 0) {
                this.participantsHaveEmailSet = false;
            } else if (
                item['institutions'][0]['_joinData']['mail'] === '' ||
                item['institutions'][0]['_joinData']['mail'] === null
            ) {
                this.participantsHaveEmailSet = false;
            }
        }
        if (Object.prototype.hasOwnProperty.call(this.data, 'contactId')) {
            this.contactId = this.data.contactId;
        } else {
            this.contactId = 0;
        }
    }

    /**
     * @brief   Dialog schließen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickClose(): void {
        this.dialogRef.close();
        // Daten in Kennzeichenmodul neu laden
        this.contactsService.dataChanged('contacts-form-popup', {});
    }
}
