// Angular-Module
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Übergeordneter Service
import {GlobalHelpService} from './../global-help.service';
// Eigener Service
import {GlobalHelpRolesService} from './global-help-roles.service';
// Service von Shared Modules
import {GridService} from '@shared/grid/grid.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {LooseObject} from '@shared/loose-object';
import {LooseObjectUser} from '@shared/loose-object-user';
// Shared Components
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';

@Component({
    selector: 'phscw-global-help-roles',
    templateUrl: './global-help-roles.component.html',
    styleUrls: ['./global-help-roles.component.scss'],
})
export class GlobalHelpRolesComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf Form
    @ViewChild('globalHelpRolesForm', {static: false}) globalHelpRolesForm: NgForm;

    // ID des aktuellen Eintrags
    helpentryId: number;
    // ID des Grids
    gridId = 'globalHelpRoles';

    // Spaltendefinitionen für Grid
    gridColumns = [
        {
            columnDef: 'id',
            header: 'ID',
            cell: (element: any) => `${element.role_id}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'label',
            header: 'Bezeichnung',
            cell: (element: any) => `${element.label}`,
        },
        {
            columnDef: 'display_right',
            header: 'Lesen',
            cell: (element: any) => {
                if (typeof element.display_right === 'boolean') {
                    if (element.display_right === true) {
                        return '✔️';
                    } if (element.display_right === false) {
                        return '❌';
                    }
                    return `${element.display_right || ''}`;
                }
                return `${element.display_right || ''}`;
            },
        },
    ];

    // Anzuzeigende Spalten für Grid
    gridDisplayedColumns = ['id', 'label', 'display_right', 'delete'];

    // "Mehr laden..."
    loadMoreEnabled = false;
    loadMoreVisible = true;
    gridPage = 1;

    // Modul-Daten
    data: LooseObject[] = [];
    // Nicht zugeordnete Rollen.
    unusedRoles: LooseObject[] = [];

    // Edit-Mode
    editMode = false;
    // Einzelne Rollenzuordnungs-daten
    dataItem: LooseObjectUser = new LooseObjectUser();
    // ID der ausgewählten Rolle
    roleId: number;
    // Name der aktuell gewählten Rolle
    roleName = '';
    // Flag definiert, ob eine neue Rolle hinzugefügt wird
    newRole = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param dialog
     * @param translateService
     * @param gridService
     * @param toolbarService
     * @param globalHelpService
     * @param globalHelpRolesService
     */
    constructor(
        private dialog: MatDialog,
        private translateService: TranslateService,
        private gridService: GridService,
        private toolbarService: ToolbarService,
        private globalHelpService: GlobalHelpService,
        private globalHelpRolesService: GlobalHelpRolesService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeTranslateSubscriptions(): void {
        this.translateService
            .stream(['GENERAL.ID', 'GENERAL.LABEL', 'GENERAL.READ'])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: string[]) => {
                this.gridColumns.find((column: any) => column.columnDef === 'id').header = translation['GENERAL.ID'];
                this.gridColumns.find((column: any) => column.columnDef === 'label').header =
                    translation['GENERAL.LABEL'];
                this.gridColumns.find((column: any) => column.columnDef === 'display_right').header =
                    translation['GENERAL.READ'];
            });
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Event "eventHelpSelectionChanged" von "globalHelpService"
        this.globalHelpService.eventHelpSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                this.onSelectionChanged(result);
            });

        // Es wurde auf "Mehr laden..." geklickt
        this.gridService.eventGridPageCounterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                const event: CWEvent = result;
                if (event.target !== 'globalHelpRoles') {
                    return;
                }
                this.onEventGridPageCounterChanged(result);
            });

        // Wenn eine neue Rollenzuordnung (über Toolbar) angelegt werden soll...
        this.toolbarService.eventAddItem.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            const event: CWEvent = result;
            if (event.target !== 'globalHelpRoles') {
                return;
            }
            this.onEventAddItem();
        });

        // Event "eventGridSelectionChanged" von "gridService"
        this.gridService.eventGridSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                const event: CWEvent = result;
                if (event.sender !== 'globalHelpRoles') {
                    return;
                }
                this.onEventGridSelectionChanged(event);
            });

        // Wenn eine Rollenzuordnung gelöscht werden soll
        this.gridService.eventGridRowDeleteClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                const event: CWEvent = result;
                if (event.sender !== 'globalHelpRoles') {
                    return;
                }
                this.onEventGridRowDeleteClicked(result);
            });
    }

    /**
     * Auf geänderte Auswahl reagieren
     * @param event
     */
    onSelectionChanged(event: CWEvent): void {
        // Bereits vorhandene Daten entfernen
        this.resetData();

        // ID des aktuellen Eintrags merken
        this.helpentryId = event.data.id;
        // Falls sich das Modul noch im Edit-Mode befindet diesen zurücksetzen
        this.editMode = false;

        // Daten laden
        this.loadData();
    }

    /**
     * Daten für Grid nachladen
     * @param event
     */
    onEventGridPageCounterChanged(event: CWEvent): void {
        this.gridPage = event.data['gridPageCounter'];
        this.loadData();
    }

    /**
     * Auf Toolbar-Klick reagieren (Rollenzuordnung hizufügen)
     */
    onEventAddItem(): void {
        // Daten setzen
        this.roleId = 0;
        this.newRole = true;
        this.dataItem['display_right'] = true;
        this.getUnusedRoles();
    }

    /**
     * Auf Event "eventGridSelectionChanged" von "gridService" reagieren
     * @param event
     */
    onEventGridSelectionChanged(event: CWEvent): void {
        this.roleId = event.data['selectedRow']['role_id'];
        this.roleName = event.data['selectedRow']['label'];
        this.loadDetails(this.roleId);
    }

    /**
     * Auf Toolbar-Klick reagieren (Rollenzuordnung löschen)
     * @param event
     */
    onEventGridRowDeleteClicked(event: CWEvent): void {
        this.roleId = event.data['selectedRow']['role_id'];
        this.openDeleteDialog();
    }

    /**
     * Daten zurücksetzen
     */
    resetData(): void {
        this.loadMoreEnabled = false;
        this.loadMoreVisible = true;
        this.gridPage = 1;
        this.data = [];
    }

    /**
     * Alle Zugeordenten Rollen des Eintrags laden
     */
    loadData(): void {
        // Funktion "Mehr laden..." wird wieder deaktiviert
        this.loadMoreEnabled = false;

        // Request auslösen
        const serviceRequest$ = this.globalHelpRolesService.loadData(this.helpentryId, this.gridPage);
        serviceRequest$.subscribe(
            (result: CWResult) => {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zum aktuell ausgewählten Eintrag passen. Durch asynchrone
                 * Abfragen kann es nämlich passieren, dass zwischenzeitlich
                 * bereits der Eintrag gewechselt wurde und die Antwort
                 * eines Requests verspätet eintrifft und dadurch die
                 * korrekten Daten wieder überschreibt.
                 */
                if (result.data.length > 0 && this.helpentryId != result.data[0]['helpentry_id']) {
                    return;
                }

                if (result.data.length > 0) {
                    // Falls sich die vorhandenen Grid-Daten von den neu geladenen Daten unterscheiden...
                    if (JSON.stringify(this.data) != JSON.stringify(result.data)) {
                        // Vorhandene Grid-Daten mit neu geladenen Daten erweitern
                        this.data = this.data.concat(result.data);
                    }
                    this.loadMoreEnabled = true;
                } else {
                    this.loadMoreVisible = false;
                }
            },
            (error: any) => {
                this.loadMoreVisible = true;
                this.loadMoreEnabled = true;
            },
        );
    }

    /**
     * Einelne Rollenzuordnung mit Rechten laden
     * @param id
     */
    loadDetails(id: number): void {
        // Flag setzen
        this.editMode = true;

        // Daten laden
        const serviceRequest$ = this.globalHelpRolesService.loadDetails(this.helpentryId, id);
        serviceRequest$.subscribe((result: CWResult) => {
            // Daten zwischenspeichern
            this.dataItem = result.data;
        });
    }

    /**
     * Unbenutzte Rollen laden
     */
    getUnusedRoles(): void {
        const serviceRequest$ = this.globalHelpRolesService.getUnusedRoles(this.helpentryId);
        serviceRequest$.subscribe((result: CWResult) => {
            this.unusedRoles = result.data;
            this.editMode = true;
        });
    }

    /**
     * Klick auf "Speichern" (Form-Submit)
     */
    clickSubmit(): void {
        // Nur gültige Formulare werden submitted
        if (this.globalHelpRolesForm.form.valid) {
            // Submit der Formular-Daten über adminCharacteristicGroupsRolesService
            const serviceRequest$ = this.globalHelpRolesService.saveData(
                this.helpentryId,
                this.roleId,
                this.globalHelpRolesForm.form.value,
            );
            serviceRequest$.subscribe(
                (result: CWResult) => {
                    // Bei Erfolg den Editmode zurücksetzen und neu laden
                    if (result.success) {
                        // EditMode verlassen
                        this.editMode = false;
                        this.newRole = false;
                        this.resetData();

                        // Daten neu laden
                        this.loadData();
                    }
                },
                (error: any) => {
                    // Fehlermeldung anzeigen
                },
            );
        }
    }

    /**
     * Klick auf "Abbrechen"
     */
    clickCancel(): void {
        // EditMode verlassen
        this.editMode = false;
        this.newRole = false;

        // Daten zurücksetzen
        this.dataItem = new LooseObjectUser();
    }

    /**
     * Lösch-Dialog öffnen
     */
    openDeleteDialog(): void {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('GLOBAL.HELP.ROLES.DELETEASSIGNMENT'),
                message: this.translateService.instant('GLOBAL.HELP.ROLES.DELETEASSIGNMENTQUESTION'),
            },
        });
        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result: any) => {
            this.deleteHelpentryRole(result.answer);
        });
    }

    // Rollen-zuordnung löschen
    deleteHelpentryRole(answer: string): void {
        // Wenn in Comfirm-Dialog Button "Ja" geklicktwurde
        if (answer == 'yes') {
            // Submit der Formular-Daten über adminCharacteristicGroupsRolesService
            const serviceRequest$ = this.globalHelpRolesService.deleteData(this.helpentryId, this.roleId);
            serviceRequest$.subscribe((result: CWResult) => {
                // Reset vorm neu laden
                this.resetData();

                // Neu laden
                this.loadData();
            });
        }
    }
}
