// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Service dieses Shared-Moduls
import {ToolbarService} from '../toolbar.service';

@Component({
    selector: 'phscw-toolbar-remote-edetailer-start',
    templateUrl: './toolbar-remote-edetailer-start.component.html',
    styleUrls: ['./toolbar-remote-edetailer-start.component.scss'],
})
export class ToolbarRemoteEdetailerStartComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;
    // Tooltip
    @Input() tooltip: string = null;
    // Parameter
    @Input() parameter: any = {};

    // Konstruktor
    constructor(private toolbarService: ToolbarService) {}

    ngOnInit(): void {}

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /*
     * Remote E-Detailer Link öffnen
     * @todo Link nicht fest im Code behalten
     */
    startRemoteEdetailerPresentation() {
        this.toolbarService.startEdetailer(this.componentIdentifier);
        /*
         * let webLink = document.createElement('a');
         * webLink.target = '_blank';
         * webLink.href = 'https://pharmacrm.info/eDetailer-ADM?code=798202682859';
         * webLink.click();
         * webLink.remove();
         */
    }
}
