/**
 * @brief   Globaler Service für Regionsfilter.
 * @author  Olga Salomatina <o.salomatina@pharmakon.software>
 */

// Angular-Module
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {extractFirstWord} from '@shared/utils';

@Injectable({
    // Root-Injector (app.module.ts) ist verantwortlich für das Instanziieren dieses globalen Services
    providedIn: 'root',
})
export class GlobalRegionsfilterService {
    /*
     * Subject (Observable) definieren "Regionen-Filter geändert"
     * @Output() eventRegionsfilterChanged: EventEmitter<CWEvent> = new EventEmitter();
     */
    public eventRegionsfilterChanged = new Subject<CWEvent>();

    /**
     * Im Service werden immer die aktuellen Regionsfilter-Einstellungen
     * öffentlich verfügbar gemacht.
     */
    public currentDivision = 0;
    public currentRegion = 0;

    // Konstruktor
    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
        private router: Router,
    ) {}

    /**
     * @brief       Daten für Regionen-Filter laden.
     * @returns      Observable<any>
     * @author      Olga Salomatina <o.salomatina@pharmakon.software>
     */
    loadData(): Observable<any> {
        /*
         * @todo: Überlegen, evtl. laden wir die Regionen bereits in InitService und legen sie in IndexedDb ab.
         * Gebietsinfos sind z.B. auch für "Betreuender AD", etc. wichtig und müssten dann nicht nochmal neu geladen werden
         */

        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequestAsync('Regions/index');
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @param division
     * @param region
     * @param regionLevel
     * @param refreshGrid
     * @brief       Event auslösen um Daten des Regionsfilters weiterzugeben
     * @author      Olga Salomatina <o.salomatina@pharmakon.software>
     * @todo        Alle Slashes aus der Route entfernen und dann die
     *              EventListener anpassen, damit wir ein einheitliches
     *              Vorgehen haben beim Verwenden der Route als Event-Target
     *              if(this.gridId.toLowerCase().includes(event.target))
     */
    regionsfilterChanged(division: number, region: number, regionLevel: number, refreshGrid: boolean) {
        // Target ergibt sich aus aktueller Route
        const filterTarget: string = extractFirstWord(this.router.url);

        /*
         * Informationen für Event
         * let eventData: CWEvent = {sender: 'regionsfilter', target: filterTarget, data: {'division': division, 'region': region}};
         */
        const eventData: CWEvent = {
            sender: 'regionsfilter',
            target: filterTarget,
            data: {
                division,
                region,
                regionLevel,
                refreshGrid,
                routerUrl: this.router.url,
            },
        };
        // Event auslösen
        this.eventRegionsfilterChanged.next(eventData);
    }
}
