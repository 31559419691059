// Angular-Module
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Globale Services einbinden
import {BackendService} from './../../services/backend.service';
// Environment einbinden
import {environment} from '@environment';

@Component({
    selector: 'phscw-global-accountinfo-popup',
    templateUrl: './global-accountinfo-popup.component.html',
    styleUrls: ['./global-accountinfo-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalAccountinfoPopupComponent implements OnInit, OnDestroy {
    // Subscriptions hält Referenzen aller Subscriptions
    private _subscriptions = new Subscription();

    // Accountinfos
    data: any[] = [];
    // Flag definiert, ob gerade geladen wird
    loading = false;
    // Support-Mail-Adresse
    mailAddress = '';
    // Backend-URL
    backendUrl = '';

    // Konstruktor
    constructor(
        private backendService: BackendService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    // Initialisierung der Komponente
    ngOnInit() {
        // Account-Infos laden
        this.loadData();
        if (typeof environment.supportMailAddress !== 'undefined') {
            this.mailAddress = environment.supportMailAddress;
        }

        // 2020-01-15, PhS(LB): Um Backend-URL ergänzt, Prüfung sollte nicht nötig sein.
        if (typeof environment.backendUrl !== 'undefined') {
            this.backendUrl = environment.backendUrl;
        }
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    // Account-Infos laden
    loadData() {
        // Flag setzen
        this.loading = true;
        // Daten vom Backend laden
        const serviceRequest$ = this.backendService.getRequest('Accountinfo/index');
        serviceRequest$.subscribe(
            (result) => {
                // Antwort vom Backend erhalten - Flag ausschalten
                this.data = result['data'];
                this.data['frontendTag'] = environment.frontendTag;
                this.loading = false;
                // Change-Detection manuell auslösen
                this.changeDetector.markForCheck();
            },
            (error) => {
                // Keine Antwort vom Backend erhalten (Servererror)
                this.loading = false;
            },
        );
    }
}
