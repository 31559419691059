// Angular-Module
import {Component, OnInit} from '@angular/core';

import {NavigationEnd, Router} from '@angular/router';

import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Interfaces für Structured Objects einbinden
import {GlobalRegionsfilterService} from '@global/components/global-regionsfilter/global-regionsfilter.service';
import {CWEvent} from '@shared/cw-event';

import {ChartService} from './../chart.service';

@Component({
    selector: 'phscw-chart-conducted-trainings',
    templateUrl: './chart-conducted-trainings.component.html',
    styleUrls: ['./chart-conducted-trainings.component.scss'],
})
export class ChartConductedTrainingsComponent implements OnInit {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    public activeRouteName: string;

    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    loading = false;
    dataExist = true;
    trainingsIndividualsCount = 0;
    trainingsInstitutionsCount = 0;

    constructor(
        private chartService: ChartService,
        private regionsfilterService: GlobalRegionsfilterService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.loading = true;
        // Events subscriben
        this.initializeEventSubscriptions();
        this.loadData(undefined);
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Wenn Regionsfilter geändert wird
        this._subscriptions.add(
            this.regionsfilterService.eventRegionsfilterChanged.subscribe((result: any) => {
                this.onEventRegionsfilterChanged(result);
            }),
        );

        // Auf Routenänderungen reagieren
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._componentDestroyed$),
            )
            .subscribe((route: NavigationEnd) => {
                // Aktive Route manuell updaten (für visuelle Hervorherbung im Menü, noch bevor gespeichert wurde)
                this.activeRouteName = route.url.toString().replace('/', '');
                // eventuelle Child-route finden und entfernen
                this.activeRouteName = this.activeRouteName.replace(/\/.*/, '');
            });
    }

    onEventRegionsfilterChanged(result) {
        const event: CWEvent = result;
        if (event.target.startsWith('dashboards')) {
            this.loadData(result);
        }
    }

    loadData(result: any) {
        this.loading = true;

        let completeChartOptions = null;
        if (result !== undefined) {
            completeChartOptions = {
                // session storage gesetzte division region auslesen
                regions: result['data'],
            };
        } else {
            completeChartOptions = {regions: undefined};
        }

        const serviceRequest$ = this.chartService.loadData(
            'ChartContacts/trainingContactsAmounts/',
            completeChartOptions,
        );
        serviceRequest$.subscribe((result: any) => {
            this.trainingsIndividualsCount = result['data']['trainingsPeople'];
            this.trainingsInstitutionsCount = result['data']['trainingsInstitutions'];
            this.loading = false;
        });
    }
}
