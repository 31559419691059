/**
 * @description   Root-Komponente der Anwendung
 *                Beinhaltet unnamed <router-outlet> für Hauptmodule, sowie
 *          <router-outlet name="popup"> für die übergeordnete Anzeige von
 *          Popups.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {Component, OnDestroy, OnInit} from '@angular/core';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Globale Services einbinden
import {StorageService} from './global/services/storage.service';

// Moment.js
import * as _moment from 'moment';
import {UpdateService} from '@global/services/update.service';
import {TitleService} from '@global/services/title.service';

const moment = _moment;

@Component({
    selector: 'phscw-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    // Konstruktor
    constructor(
        private storageService: StorageService,
        private translate: TranslateService,
        private upDateService: UpdateService,
        private titleService: TitleService,
    ) {
        // Standardsprache falls gewählte Sprache nicht gefunden wird
        this.translate.setDefaultLang('deu');
        this.translate.use('deu');
    }

    // Initialisierungen
    ngOnInit() {
        /*
         * Prüfe ob es sich um ein Apple Device handelt und setzte eine Klasse für iOS-spezfische Regeln
         * ebenfalls Macintosh abfragen - siehe https://forums.developer.apple.com/thread/119186 (ziemlich beschissene Lösung - ändern sobald man korrekt zwischen iPad und Macintosh unterscheiden kann)
         */
        if (window.navigator && window.navigator.userAgent.match(/iPhone|iPad|iPod|Macintosh|MacIntel/i)) {
            document.body.classList.add('ios-device');
        }

        // Storage Service initialisieren
        this.storageService.initialize();

        // Sprache global setzen für moment.js
        moment.locale('de');

        this.upDateService.init();

        // Wenn der User bereits eingeloggt ist, den Seiten-Titel setzen
        this.titleService.setWindowTitle();
    }

    // Aufräumen
    ngOnDestroy() {}
}
