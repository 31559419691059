// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>
 */
button {
  width: 25px;
}

.cw-counter {
  margin: auto 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/input/input-counter/input-counter.component.scss"],"names":[],"mappings":"AAAA;;;;;EAAA;AAOA;EACI,WAAA;AAAJ;;AAGA;EACI,cAAA;EACA,WAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Eva Wolkwitz <e.wolkwitz@pharmakon.software>\n */\n\nbutton {\n    width: 25px;\n}\n\n.cw-counter {\n    margin: auto 0;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
