import { Component, ElementRef, HostBinding, Inject, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatFabButton } from '@angular/material/button';
import { BackendService } from '@global/services/backend.service';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
    selector: 'phscw-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {
    @HostBinding('class.large-screen') isLargeScreen = false;
    @ViewChild('input') input!: ElementRef;
    @ViewChild('button') button!: MatFabButton;

    chatStarted = false;
    chatFinished = false;
    messages: { text: string; isUser: boolean; icon: string; }[] = [];

    suggestions: string[] = [
        'Wann war mein letzter Besuch bei dieser Einrichtung?',
        'Wann ist mein nächster Termin?',
        'Wie viele Kontakte gab es insgesamt?',
    ];

    selectedModel: string = 'gpt-4o';

    private _componentDestroyed$ = new Subject<void>();

    // Browser speech API
    synthesis = window.speechSynthesis;
    debugMode = false;
    ovewriteMode = false;

    constructor(
        private router: Router,
        private bottomSheetRef: MatBottomSheetRef<ChatComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public institutionId: number,
        private backendService: BackendService,
        private observer: BreakpointObserver,
    ) { }

    ngOnInit(): void {
        this.initializeEventSubscriptions();
    }

    ngAfterViewInit(): void {
        // Focus on the input element once the view is initialized
        if (this.input) {
            this.input.nativeElement.focus();
        }
    }

    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    private initializeEventSubscriptions() {
        this.observer
            .observe(['(min-width: 960px)'])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((state) => {
                this.isLargeScreen = state.matches;
            });

        // Listen to the current URL and update suggestions accordingly
        this.updateSuggestionsBasedOnRoute(this.router.url);

        // Optionally, subscribe to router events to handle route changes dynamically
        this.router.events.subscribe(() => {
            this.updateSuggestionsBasedOnRoute(this.router.url);
        });
    }

    send(input: string) {
        if (!input.trim()) return; // Prevent sending empty messages

        this.chatStarted = true;

        // Add the user's message
        this.messages.push({
            text: input,
            isUser: true,
            icon: 'user',
        });

        // Add a placeholder response to indicate "processing"
        this.messages.push({
            text: '...',
            isUser: false,
            icon: 'spinner',
        });

        // Clears the input field after sending
        this.input.nativeElement.value = '';

        // So you can directly write in the text field without clicking it
        this.input.nativeElement.focus();

        // Get response
        this.getResponse(input);

        // Scroll to the sent message
        setTimeout(() => {
            this.scrollToMessage();
        }, 100); // Delay so the message can render
    }

    private scrollToMessage() {
        const messageContainer = document.getElementById('scrollToMessage');
        if (messageContainer) {
            // Scroll to the bottom of the message container
            messageContainer.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    private getResponse(input: string) {
        this.backendService
            .postRequestAsync('ArtificialIntelligence/read', {
                prompt: input,
                context: { route: this.router.url },
                debug: this.debugMode,
                overwrite: this.ovewriteMode,
                gptmodel: this.selectedModel,
            })
            .pipe(map((response) => response.data))
            .subscribe((responseMessage) => {
                // remove leading newlines
                responseMessage = responseMessage.replace(/^\n+/, '');
                // remove placeholder message
                this.messages = this.messages.filter((message) => message.text !== '...');
                // add response to the messages using typewriter effect
                this.displayTypewriterEffect(responseMessage);
            });
    }

    // Typewriter effect for incoming chat messages
    private displayTypewriterEffect(message: string) {
        const response = {
            text: '',
            isUser: false,
            icon: 'atom-simple',
        };
        this.messages.push(response);

        // If debugMode is true, display the message instantly
        if (this.debugMode) {
            response.text = message;
            this.chatFinished = true; // Mark the chat as finished
        } else {
            // Typewriter effect when debugMode is false
            let index = 0;
            const interval = setInterval(() => {
                if (index < message.length) {
                    response.text += message.charAt(index);
                    index++;
                } else {
                    clearInterval(interval);
                    this.chatFinished = true;
                }
            }, 10); // Typewriter effect speed
        }
    }

    // Text to speech
    speakMessage(message: string) {
        const textToSpeech = new SpeechSynthesisUtterance(message);
        const voice = speechSynthesis.getVoices().filter((voice) => voice.name === 'Google Deutsch')[0];
        textToSpeech.lang = 'de-DE';
        textToSpeech.voice = voice;
        this.synthesis.speak(textToSpeech);
    }

    closeChat() {
        this.bottomSheetRef.dismiss();
    }

    // Restart chat after one question
    restart() {
        this.chatStarted = false;
        this.chatFinished = false;
        this.messages = [];
    }

    disableInput() {
        this.input.nativeElement.disabled = true;
        this.button._elementRef.nativeElement.disabled = true;
    }

    openLink(event: MouseEvent): void {
        this.bottomSheetRef.dismiss();
        event.preventDefault();
    }

    private updateSuggestionsBasedOnRoute(url: string): void {
        if (url.includes('institutions')) {
            this.suggestions = [
                'Wann war mein letzter Besuch bei dieser Einrichtung?',
                'Wie viele Kontakte gab es insgesamt?',
                'Welche Dienstleistungen werden angeboten?',
            ];
        } else if (url.includes('people') || url.includes('b2c')) {
            this.suggestions = [
                'Wann ist mein nächster Termin?',
                'Wie viele Personen sind registriert?',
                'Wie erreiche ich diese Person?',
            ];
        } else {
            this.suggestions = [
                'missing 1',
                'missing 2',
                'missing 3',
            ];
        }
    }

    autoResize(textarea: HTMLTextAreaElement): void {
        // Get the line-height of the textarea
        const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight || '0', 10);
        // Define the maximum number of rows
        const maxRows = 15;

        // Reset height to calculate the actual scroll height
        textarea.style.height = 'auto';

        // Calculate the maximum height based on the max rows and line height
        const maxHeight = lineHeight * maxRows;

        // Set the textarea's height to either its scrollHeight or the maximum height (whichever is smaller)
        textarea.style.height = Math.min(textarea.scrollHeight, maxHeight) + 'px';
    }
}
