// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * @author  Michael Schiffner <m.schiffner@pharmakon.software>
 */
:host {
  width: 100%;
}

.cw-autocomplete-search-icon {
  cursor: pointer;
  font-size: 1.2rem;
  width: 12px;
  line-height: 1;
  margin: 6px;
}

.cw-autocomplete-input {
  min-width: 60px !important;
}

:host .cw-input {
  max-height: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/input/input-autocomplete/input-autocomplete.component.scss"],"names":[],"mappings":"AAAA;;EAAA;AAGA;EACI,WAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;EACA,WAAA;EACA,cAAA;EACA,WAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":["/*\n * @author  Michael Schiffner <m.schiffner@pharmakon.software>\n */\n:host {\n    width: 100%;\n}\n\n.cw-autocomplete-search-icon {\n    cursor: pointer;\n    font-size: 1.2rem;\n    width: 12px;\n    line-height: 1;\n    margin: 6px;\n}\n\n.cw-autocomplete-input {\n    min-width: 60px !important;\n}\n\n:host .cw-input {\n    max-height: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
