// Angular-Module
import {Injectable, Type} from '@angular/core';
// Globale Services
import {BackendService} from '@global/services/backend.service';
// Komponenten einbinden
import {OverlayInfoEmployeeComponent} from '@shared/overlay/overlay-info/overlay-info-employee/overlay-info-employee.component';
import {OverlayInfoInstitutionComponent} from '@shared/overlay/overlay-info/overlay-info-institution/overlay-info-institution.component';
import {OverlayInfoPersonComponent} from '@shared/overlay/overlay-info/overlay-info-person/overlay-info-person.component';
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';
import {PopupFormularComponent} from '@shared/popups/popup-formular/popup-formular.component';
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';

@Injectable({providedIn: 'root'})
export class OverlayTriggerLinkService {
    /**
     * Konstruktor (inkl. dependency injection)
     * @param backendService
     */
    constructor(private backendService: BackendService) {}

    /**
     * Komponente zurückgeben anhand des Namens
     * @param name
     * @todo setzen der Komponente dynamisch ermöglichen, damit nicht jedes Popup hier eingebunden sein muss
     */
    getComponentByName(name: string): Type<any> {
        // Name prüfen
        switch (name) {
            case 'OverlayInfoPerson':
                return OverlayInfoPersonComponent;
                break;
            case 'OverlayInfoInstitution':
                return OverlayInfoInstitutionComponent;
                break;
            case 'OverlayInfoEmployee':
                return OverlayInfoEmployeeComponent;
                break;
            case 'PopupConfirmation':
                return PopupConfirmationComponent;
                break;
            case 'PopupMessage':
                return PopupMessageComponent;
                break;
            case 'PopupFormular':
                return PopupFormularComponent;
                break;
            default:
                return null;
        }
    }
}
