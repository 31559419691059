// Angular-Module
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Service dieses Shared-Moduls
import {ToolbarService} from '../toolbar.service';
// Shared Services
import {GlobalHelpPopupService} from '@global/components/global-help/global-help-popup/global-help-popup.service';
import {SplitterService} from './../../splitter/splitter.service';

@Component({
    selector: 'phscw-toolbar-fullscreen',
    templateUrl: './toolbar-fullscreen.component.html',
    styleUrls: ['./toolbar-fullscreen.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarFullscreenComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    // ID / Bezeichner der verbundenen Komponente
    @Input() componentIdentifier: string;
    // Definiert, wie groß (%) Fullscreen sein soll
    @Input() fullscreenSize = 100;
    // Fullscreen-Status (für wechselndes Icon FullscreenToggle)
    @Input() fullscreenStatus = false;
    // Tooltip
    @Input() tooltip: string = null;

    // Konstruktor
    constructor(
        private toolbarService: ToolbarService,
        private splitterService: SplitterService,
        private globalHelpPopupService: GlobalHelpPopupService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Fullscreen-Status überwachen (über Splitter gesendet)
        this._subscriptions.add(
            this.splitterService.eventFullscreenStatusChanged.subscribe((result) => {
                if (result.sender === 'splitter' && result.target === this.componentIdentifier) {
                    this.fullscreenStatus = result.data['status'];
                    this.changeDetector.markForCheck();
                }
            }),
        );
        // Fullscreen-Status überwachen (über globale Hilfe gesendet)
        this._subscriptions.add(
            this.globalHelpPopupService.eventFullscreenStatusChanged.subscribe((result) => {
                if (result.sender === 'global-help-toolbar' && result.target === this.componentIdentifier) {
                    this.fullscreenStatus = result.data['status'];
                    this.changeDetector.markForCheck();
                }
            }),
        );
    }

    // Fullscreen wechseln
    toggleFullscreen() {
        // Wechseln des Fullscreens über ToolbarService
        this.toolbarService.toggleFullscreen(this.componentIdentifier, this.fullscreenSize);
    }
}
